import './styles.scss';

import {
  Select,
  TextInput,
  Toaster as toaster,
  useInvalidations,
} from '@kandji-inc/bumblebee';
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';

import isEqual from 'lodash/isEqual';

import { Card, Header, Settings } from 'features/library-items/template';

import { i18n } from 'i18n';
import Actions from './actions';
import { initialPage, useInitialForm } from './initial';
import {
  findOption,
  optionsCountRange,
  optionsIndustryFactory,
  optionsLocaleFactory,
} from './options';
import useCompanySettings from './use-company-settings';
import { requiredField } from './validation';

export default ({ children }) => {
  const history = useHistory();

  const { ownerId, currentUserId } = useSelector((state: any) => ({
    ownerId: state.account?.company?.owner,
    currentUserId: state.account?.user?.id,
  }));

  const isOwner = ownerId === currentUserId;
  const showTenantLocale = i18n.isEnabled();

  const optionsLocale = optionsLocaleFactory();
  const optionsIndustry = optionsIndustryFactory();

  const { invalidations, onInvalidate } = useInvalidations({ inputs: 1 });
  const { initialForm, setInitialForm } = useInitialForm();
  const { updateCompanySettings } = useCompanySettings();

  const [page, setPage] = useState(initialPage);
  const [form, setForm] = useState(initialForm);

  const { isEditing, isSaving } = page;
  const {
    company_name,
    company_industry,
    company_size,
    device_count_estimate,
    locale,
  } = form;

  const updatePage = (k, v) => setPage((p) => ({ ...p, [k]: v }));
  const updateForm = (k, v) => setForm((p) => ({ ...p, [k]: v }));

  const isDisabled = !isEditing || isSaving;

  const onBack = () => history.push('/devices');

  const onCancel = () => {
    updatePage('isEditing', false);
    setForm(initialForm);
  };

  const onEdit = () => updatePage('isEditing', true);

  const onSave = () => {
    updatePage('isSaving', true);
    updatePage('isEditing', false);
    // if locale has changed, this will trigger a locale provider update
    updateCompanySettings(form);
    setInitialForm(form);
    updatePage('isSaving', false);
    toaster(i18n.t('Company settings saved.'));
  };

  useEffect(() => {
    updatePage(
      'isValid',
      !isEqual(initialForm, form) && !invalidations.some(Boolean),
    );
  }, [initialForm, form, invalidations]);

  return (
    <Settings className="k-company-settings">
      <Card>
        <Header>
          <h3 className="b-h3">{i18n.t('Company overview')}</h3>
        </Header>
        <div className="b-flex-col fields">
          <div className="b-mb2">
            <p className="b-txt b-mb-tiny">{i18n.t('Company name')}</p>
            <TextInput
              placeholder={i18n.t('Kandji')}
              value={company_name}
              disabled={isDisabled}
              validator={requiredField(i18n.t('Company name is required'))}
              onInvalidate={onInvalidate(0)}
              onChange={(e) => updateForm('company_name', e.target.value)}
              maxLength={100}
              aria-label="company-name"
              hideMaxLength
              compact
            />
          </div>
          {
            /* istanbul ignore next */ isOwner && showTenantLocale && (
              <div className="b-mb2">
                <p className="b-txt b-mb-tiny">{i18n.$t('Company Language')}</p>
                <Select
                  placeholder={i18n.t('Select language')}
                  options={optionsLocale}
                  value={findOption(locale, optionsLocale)}
                  disabled={isDisabled}
                  onChange={(v) => updateForm('locale', v.value)}
                  aria-label="locale"
                  compact
                />
              </div>
            )
          }
          <div className="b-mb2">
            <p className="b-txt b-mb-tiny">
              {i18n.$t('Device count {optional}', {
                optional: (
                  <span className="b-txt-light">({i18n.t('optional')})</span>
                ),
              })}
            </p>
            <Select
              placeholder={i18n.t('Select the estimated number of devices')}
              options={optionsCountRange}
              value={findOption(device_count_estimate, optionsCountRange)}
              disabled={isDisabled}
              onChange={(v) => updateForm('device_count_estimate', v.value)}
              aria-label="device-count"
              compact
            />
          </div>
          <div className="b-mb2">
            <p className="b-txt b-mb-tiny">
              {i18n.$t('Industry {optional}', {
                optional: (
                  <span className="b-txt-light">({i18n.t('optional')})</span>
                ),
              })}
            </p>
            <Select
              placeholder={i18n.t('Select industry')}
              options={optionsIndustry}
              value={findOption(company_industry, optionsIndustry)}
              disabled={isDisabled}
              onChange={(v) => updateForm('company_industry', v.value)}
              aria-label="industry"
              compact
            />
          </div>
          <div>
            <p className="b-txt b-mb-tiny">
              {i18n.$t('Company size {optional}', {
                optional: (
                  <span className="b-txt-light">({i18n.t('optional')})</span>
                ),
              })}
            </p>
            <Select
              placeholder={i18n.t('Select company size')}
              options={optionsCountRange}
              value={findOption(company_size, optionsCountRange)}
              disabled={isDisabled}
              onChange={(v) => updateForm('company_size', v.value)}
              aria-label="company-size"
              compact
            />
          </div>
        </div>
      </Card>

      {children}

      <Actions
        page={page}
        onBack={onBack}
        onCancel={onCancel}
        onEdit={onEdit}
        onSave={onSave}
      />
    </Settings>
  );
};
