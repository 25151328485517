import { Button, Toaster as toaster } from '@kandji-inc/bumblebee';
import React, { useEffect, useState, useRef, useContext } from 'react';
import { elementScrollIntoView } from 'seamless-scroll-polyfill';
import Loader from '../../../../theme/components/atoms/Loader';
import { notesService } from '../../../library-items/data-service/notes/notes.service';
import BlueprintContext from '../blueprint.context';
import './blueprint-notes.css';
import NoteEditor from './editor';
import SingleNote from './single-note';

const BlueprintNotes = () => {
  const { blueprint } = useContext(BlueprintContext);
  const [notes, setNotes] = useState();
  const [err, setErr] = useState(false);
  const [isAdding, setIsAdding] = useState(false);
  const [activeEdit, setActiveEdit] = useState();
  const notesContainer = useRef();

  const getAllNotes = () =>
    notesService.find({ id: blueprint.id }).then((r) => setNotes(r));

  const onCreateNote = (content) => {
    notesService
      .create({ blueprint_id: blueprint.id, content })
      .then(() => {
        toaster(`Successfully created Note.`);
        return getAllNotes().catch(() =>
          toaster('An error occurred retrieving the updated Blueprint.'),
        );
      })
      .catch(() => toaster('Failed to create Note.'))
      .finally(() => setIsAdding(false));
  };

  const onUpdateNote = (note, content) => {
    notesService
      .patch(note.id, { content })
      .then(() => {
        toaster(`Successfully updated note.`);
        setActiveEdit();
        return getAllNotes().catch(() =>
          toaster('An error occurred retrieving the updated Blueprint.'),
        );
      })
      .catch(() => toaster('Failed to update note.'));
  };

  const onDelete = (note) =>
    notesService
      .delete(note.id)
      .then(() => {
        toaster(`Successfully deleted note.`);
        return getAllNotes().catch(() =>
          toaster('An error occurred retrieving the updated Blueprint.'),
        );
      })
      .catch(() => toaster(`Failed to delete note`));

  useEffect(() => {
    getAllNotes().catch(() => setErr(true));
  }, []);

  if (err) {
    return <div>Error</div>;
  }

  if (!notes) {
    return <Loader type={Loader.types.LINE} />;
  }

  return (
    <div className="bl-notes-page">
      <div className="b-flex-vc b-flex-btw bl-notes-page__header">
        <h1 className="b-h1 b-mt3 b-mb3">Notes</h1>
        <Button
          disabled={isAdding}
          onClick={() => {
            setIsAdding(true);
            setActiveEdit();
            elementScrollIntoView(document.body, {
              behavior: 'smooth',
              block: 'start',
            });
          }}
          icon="plus"
        >
          Create new note
        </Button>
      </div>
      <div ref={notesContainer} className="b-flex-vg1">
        {isAdding && (
          <NoteEditor
            content=""
            onCancel={() => setIsAdding(false)}
            onSave={onCreateNote}
          />
        )}
        <div className="bl-notes">
          {notes.map((note) => {
            if (activeEdit?.id === note.id) {
              return (
                <NoteEditor
                  key={note.id}
                  content={note.content}
                  onCancel={() => {
                    setIsAdding(false);
                    setActiveEdit();
                  }}
                  onSave={(content) => onUpdateNote(note, content)}
                />
              );
            }

            return (
              <SingleNote
                key={note.id}
                note={note}
                onDelete={onDelete}
                onEdit={() => {
                  setActiveEdit(note);
                  setIsAdding(false);
                }}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default BlueprintNotes;
