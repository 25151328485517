import React, { useState } from 'react';
import styled from 'styled-components';
import FooterButtons from '../../../components/footer-buttons';
import {
  Container,
  ModalContent,
  StyledHeaderImage,
  StyledSlideHeader,
} from '../../../components/styled-components';
import useModal from '../../../hooks/use-modal';
import iosIcon from '../assets/ios-icon.svg';
import macOsIcon from '../assets/mac-os-icon.svg';
import ConfigurationOption from '../components/configuration-option';
import oktaIcon from './assets/okta.svg';
import { oktaSlideMap } from './okta-slide-map';

const ConfigurationOptions = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: var(--b-gap2);
  margin-top: var(--b-gap2);
`;

type DeviceSetupState = {
  macOsDevices: boolean;
  iosDevices: boolean;
};

const initialDeviceSetupState = {
  macOsDevices: false,
  iosDevices: false,
};
const AddDevicePlatforms = () => {
  const [deviceSetup, setDeviceSetup] = useState<DeviceSetupState>(
    initialDeviceSetupState,
  );
  const { nextModal } = useModal();
  const onClose = () => nextModal(oktaSlideMap.cancelConfirm);

  const handleNext = () => {
    const { iosDevices, macOsDevices } = deviceSetup;
    if ((iosDevices && macOsDevices) || macOsDevices) {
      const skipIos = !iosDevices;
      nextModal(oktaSlideMap.addMacOS({ skipIos }));
    }
    if (iosDevices && !macOsDevices) {
      const skipMacOs = !macOsDevices;
      nextModal(oktaSlideMap.addIOS({ skipMacOs }));
    }
  };

  const handleChange = (key: keyof DeviceSetupState) => {
    setDeviceSetup((prevValue) => ({
      ...prevValue,
      [key]: !prevValue[key],
    }));
  };

  return (
    <Container>
      <ModalContent>
        <StyledHeaderImage src={oktaIcon} alt="okta-header-icon" />
        <StyledSlideHeader>Configure device platforms</StyledSlideHeader>
        <p className="b-txt-light">
          Choose to configure Okta Device Trust for both macOS and iOS devices,
          or select a single device platform. This selection should reflect
          which device management providers you added in the Okta console in
          previous steps.{' '}
          <a
            href="https://support.kandji.io/support/solutions/articles/72000580346#Configure-device-platforms-in-Okta"
            rel="noopener noreferrer"
            target="_blank"
            className="b-alink"
          >
            Learn More
          </a>
        </p>
        <ConfigurationOptions>
          <ConfigurationOption
            label="macOS devices"
            icon={macOsIcon}
            altText="mac-os-icon"
            checked={deviceSetup.macOsDevices}
            onChange={() => handleChange('macOsDevices')}
          />
          <ConfigurationOption
            label="iOS devices"
            icon={iosIcon}
            altText="ios-icon"
            checked={deviceSetup.iosDevices}
            onChange={() => handleChange('iosDevices')}
          />
        </ConfigurationOptions>
      </ModalContent>
      <FooterButtons
        onClose={onClose}
        handleNext={handleNext}
        cancelButtonText="Cancel setup"
        showStepper
        stepperIdx={3}
        stepsAmount={6}
        nextButtonDisabled={!Object.values(deviceSetup).some(Boolean)}
      />
    </Container>
  );
};

export default AddDevicePlatforms;
