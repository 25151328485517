import styled from 'styled-components';

const Description = styled('span')`
  font-family: var(--font-family-primary);
  font-size: 14px;
  line-height: 22px;

  color: #1a1d25;
  max-width: 665px;
`;

const SubDescription = styled(Description)`
  font-family: var(--font-family-primary);
  font-size: 14px;
  line-height: 20px;

  max-width: 603px;
`;

Description.SubDescription = SubDescription;
export default Description;
