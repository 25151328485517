import React from 'react';

import FooterButtons from '../../../components/footer-buttons';
import {
  Container,
  ModalContent,
  StyledSlideHeader,
} from '../../../components/styled-components';
import useModal from '../../../hooks/use-modal';
import warningTriangle from '../assets/warning-triangle.svg';

const CancelConfirm = () => {
  const { closeModal, previousModal } = useModal();

  const handleNext = () => closeModal();

  const handleBack = () => previousModal();

  return (
    <Container>
      <ModalContent>
        <img src={warningTriangle} className="b-mb2" alt="header-icons" />

        <StyledSlideHeader>
          Are you sure you want cancel setup?
        </StyledSlideHeader>
        <p className="b-txt-light">
          By canceling the integration setup, all work to this point will be
          lost and will need to be configured again.
        </p>
      </ModalContent>
      <FooterButtons
        hasCancelButton={false}
        handleNext={handleNext}
        backOnClick={handleBack}
        nextButtonText="Exit setup"
        theme="danger"
        showBackButton
        backButtonText="Back to setup"
      />
    </Container>
  );
};

export default CancelConfirm;
