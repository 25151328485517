import { useMutation } from '@tanstack/react-query';
import { newIntegrationsService } from 'src/features/integrations/data-service/new-integrations-service';
import type { NotificationData } from '../slack.types';

type CreateNotificationParams = {
  notificationData: NotificationData;
};

const useCreateNotification = () =>
  useMutation({
    mutationFn: (data: CreateNotificationParams) =>
      newIntegrationsService.createNotification(data.notificationData),
  });

export default useCreateNotification;
