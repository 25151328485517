import {
  Box,
  Chip,
  Flex,
  Icon,
  Text,
  Tooltip,
  TooltipProvider,
} from '@kandji-inc/nectar-ui';
import type { DropdownMenuOption } from '@kandji-inc/nectar-ui';
import type { CellContext, ColumnDef } from '@tanstack/react-table';
import { createColumnHelper } from '@tanstack/react-table';
import { i18n } from 'i18n';
import { useState } from 'react';
import EDRCopyButton from 'src/features/edr/common/components/EDRCopyButton';
import { getTimeBetween } from 'src/features/edr/common/util/getTimeBetween';
import { highlightedText } from 'src/pages/ADEListView/utils/highlightText';
import TableActionsDropdown from '../../../threat/common/components/ThreatListTable/TableActionsDropdown';
import { HighlightedTextWithTooltip } from '../../components/highlighted-text-with-tooltip';
import { SeverityProgressChips } from '../../components/severity-progress-chips';
import { SeverityTooltip } from '../../components/severity-tooltip';
import { translatedSeverity } from '../../constants';
import { displayAndTruncateList, formatDate } from '../../helpers';
import useVulnerability, { type VulnerabilityState } from '../../store';
import { Severity, type Vulnerability } from '../../vulnerability.types';

const getVulnerabilityIdCell = (
  info: CellContext<Vulnerability, string>,
  filter:
    | VulnerabilityState['allVulnerabilitiesFilter']
    | VulnerabilityState['deviceVulnerabilitiesFilter'],
) => {
  const { cve_id } = info.row.original;

  const [isHovering, setIsHovering] = useState(false);

  return (
    <Flex
      flow="row"
      gap="xs"
      alignItems="center"
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
    >
      {highlightedText(cve_id, filter.term)}
      {isHovering && <EDRCopyButton value={cve_id} />}
    </Flex>
  );
};

const getApplicationCell = (
  info: CellContext<Vulnerability, string>,
  filter:
    | VulnerabilityState['allVulnerabilitiesFilter']
    | VulnerabilityState['deviceVulnerabilitiesFilter'],
) => {
  const { applications, app_name } = info?.row?.original;

  if (app_name) {
    return (
      <HighlightedTextWithTooltip text={app_name} searchTerm={filter.term} />
    );
  }

  if (applications?.length) {
    const { displayed, hidden } = displayAndTruncateList(applications, 190);

    return (
      <Flex>
        {displayed.map((app_name, idx) => (
          <Flex pr1>
            {highlightedText(app_name, filter.term)}
            {(idx !== displayed.length - 1 || hidden.length > 0) && ', '}
          </Flex>
        ))}
        {hidden.length > 0 && (
          <Tooltip
            side="bottom"
            theme="light"
            interactive
            content={
              <Flex flow="column" css={{ gap: '6px' }}>
                {hidden.map((item) => (
                  <Text size="2">{highlightedText(item, filter.term)}</Text>
                ))}
              </Flex>
            }
            css={{ zIndex: 2, padding: '$3 $4' }}
          >
            <Box>
              <Text>+{hidden.length}</Text>
            </Box>
          </Tooltip>
        )}
      </Flex>
    );
  }

  return null;
};

const getVersionsHeader = () => (
  <TooltipProvider>
    <Flex gap="xs" alignItems="center">
      <Text css={{ fontWeight: '$medium' }}>{i18n.t('Version')}</Text>
      <Tooltip
        side="top"
        content={i18n.t('Latest version')}
        css={{ zIndex: 2 }}
      >
        <Box>
          <Icon name="circle-info" size="sm" color="var(--color-neutral80)" />
        </Box>
      </Tooltip>
    </Flex>
  </TooltipProvider>
);

const getSeverityCell = (info: CellContext<Vulnerability, string>) => {
  const { severity } = info?.row?.original;

  /* istanbul ignore next */
  if (!severity || severity === Severity.undefined) {
    return (
      <Text variant="secondary" css={{ fontStyle: 'italic' }}>
        {i18n.t('Pending')}
      </Text>
    );
  }

  return (
    <Flex>
      <SeverityTooltip severity={severity}>
        <Flex>
          <SeverityProgressChips severity={severity} />
          <Text>{translatedSeverity[severity]()}</Text>
        </Flex>
      </SeverityTooltip>
    </Flex>
  );
};

const getCVSSScoreCell = (info: CellContext<Vulnerability, string>) => {
  const { cvss_score } = info?.row?.original;

  /* istanbul ignore next */
  if (!cvss_score) {
    return (
      <Text variant="secondary" css={{ fontStyle: 'italic' }}>
        {i18n.t('Pending')}
      </Text>
    );
  }

  const numbericCvssScore = parseFloat(cvss_score);

  return <Text>{i18n.formatNumber(numbericCvssScore)}</Text>;
};

const getAgeHeader = () => (
  <TooltipProvider>
    <Flex gap="xs" alignItems="center">
      <Text css={{ fontWeight: '$medium' }}>{i18n.t('Age')}</Text>
      <Tooltip
        side="top"
        content={i18n.t('The amount of time since first detected')}
        css={{ zIndex: 2 }}
      >
        <Box>
          <Icon name="circle-info" size="sm" color="var(--color-neutral80)" />
        </Box>
      </Tooltip>
    </Flex>
  </TooltipProvider>
);

const getAgeCell =
  /* istanbul ignore next - output changes based on today's date */ (
    date: string,
  ) => <Text>{getTimeBetween(new Date(date), new Date())}</Text>;

const getDetectionDateCell = (date: string) => (
  <Text title={formatDate({ date, showTimestamp: true })}>
    {formatDate({ date })}
  </Text>
);

const getDevicesHeader = () => (
  <TooltipProvider>
    <Flex gap="xs" alignItems="center">
      <Text css={{ fontWeight: '$medium' }}>{i18n.t('Devices')}</Text>
      <Tooltip
        side="top"
        content={i18n.t('Devices impacted')}
        css={{ zIndex: 2 }}
      >
        <Box>
          <Icon name="circle-info" size="sm" color="var(--color-neutral80)" />
        </Box>
      </Tooltip>
    </Flex>
  </TooltipProvider>
);

const getDevicesCell = (info: CellContext<Vulnerability, string>) => {
  const { device_count } = info?.row?.original;

  /* istanbul ignore next */
  if (!device_count) {
    return null;
  }

  return (
    <Chip
      title={i18n.t('Devices impacted')}
      color="unfilled"
      iconLeft={{ icon: 'sf-desktop-computer', size: 'sm' }}
      label={device_count}
    />
  );
};

const getActionsDropdownCell = (info: CellContext<Vulnerability, string>) => {
  const { cve_link } = info?.row?.original;

  /* istanbul ignore next */
  if (!cve_link) {
    return null;
  }

  const options: DropdownMenuOption[] = [
    {
      label: i18n.t('Search NVD'),
      icon: 'nvd',
      onClick: (e) => {
        e.stopPropagation();
        e.preventDefault();
        window.open(cve_link, '_blank');
      },
    },
  ];

  return <TableActionsDropdown options={options} testId="actions-dropdown" />;
};

export const getColumns = (
  props: { isOnDeviceRecord: boolean } = { isOnDeviceRecord: false },
) => {
  const { isOnDeviceRecord } = props;
  const columnHelper = createColumnHelper<Vulnerability>();

  const filterType = isOnDeviceRecord
    ? 'deviceVulnerabilitiesFilter'
    : 'allVulnerabilitiesFilter';
  const filter = useVulnerability((state) => state[filterType]);

  const columns: ColumnDef<Vulnerability>[] = [
    columnHelper.accessor('cve_id', {
      id: 'cve_id',
      cell: (row) => getVulnerabilityIdCell(row, filter),
      header: () => i18n.t('Vulnerability ID'),
      minSize: 180,
    }),
    columnHelper.accessor('applications', {
      id: 'applications',
      cell: (row) => getApplicationCell(row, filter),
      header: () => i18n.t('Application'),
      meta: {
        hideCellTitle: true,
      },
      minSize: 190,
    }),
    ...(isOnDeviceRecord
      ? [
          columnHelper.accessor('app_version', {
            id: 'app_version',
            cell: (row) => row.getValue(),
            header: getVersionsHeader,
            enableSorting: false,
            meta: {
              hideCellTitle: true,
            },
            minSize: 220,
          }),
        ]
      : []),
    columnHelper.accessor('severity', {
      id: 'severity',
      cell: getSeverityCell,
      header: () => i18n.t('Severity'),
      meta: {
        hideCellTitle: true,
      },
      minSize: 180,
    }),
    columnHelper.accessor('cvss_score', {
      id: 'cvss_score',
      cell: getCVSSScoreCell,
      header: () => i18n.t('CVSS score'),
      maxSize: 90,
    }),
    ...(!isOnDeviceRecord
      ? [
          columnHelper.accessor('first_detection_date', {
            id: 'first_detection_date',
            cell: (row) => getAgeCell(row.getValue()),
            header: getAgeHeader,
            meta: {
              hideCellTitle: true,
            },
            maxSize: 100,
          }),
        ]
      : []),
    ...(!isOnDeviceRecord
      ? [
          columnHelper.accessor('latest_detection_date', {
            id: 'latest_detection_date',
            cell: (row) => getDetectionDateCell(row.getValue()),
            header: () => i18n.t('Last detected'),
            maxSize: 120,
          }),
        ]
      : []),
    ...(!isOnDeviceRecord
      ? [
          columnHelper.accessor('device_count', {
            id: 'device_count',
            cell: getDevicesCell,
            header: getDevicesHeader,
            // @ts-ignore
            title: i18n.t('Devices impacted'),
            maxSize: 120,
          }),
        ]
      : []),
    ...(isOnDeviceRecord
      ? [
          columnHelper.accessor('detection_datetime', {
            id: 'detection_datetime',
            cell: (row) => getDetectionDateCell(row.getValue()),
            header: () => i18n.t('Detection date'),
          }),
        ]
      : []),
    columnHelper.accessor((row) => row, {
      id: 'actions',
      cell: getActionsDropdownCell,
      enableSorting: false,
      maxSize: 40,
    }),
  ];

  return columns;
};
