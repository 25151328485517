/* istanbul ignore file */
import type { ActiveCategory } from './parameter.types';

export const isPartiallyVisibleInViewport = (
  element: Element,
  viewport: Element,
  margin = 200,
) => {
  const bound = element.getBoundingClientRect();
  const bound2 = viewport.getBoundingClientRect();

  return (
    bound.bottom - margin > bound2.top && bound.top + margin < bound2.bottom
  );
};

export const onParameterScroll = (
  parameters: any[],
  scrollableContainer: Element,
  onVisibleCategory: ({ category, subCategory }: ActiveCategory) => void,
) => {
  for (const { id, subcategories } of parameters) {
    for (const { id: subCatId, name: subCatName } of subcategories) {
      const subCategory = document.querySelector(
        `[id='${subCatName}-${subCatId}']`,
      );
      if (subCategory) {
        if (isPartiallyVisibleInViewport(subCategory, scrollableContainer)) {
          onVisibleCategory({
            category: id,
            subCategory: subCatId,
          });
          return;
        }
      }
    }
  }
};

export const onScrollToParameter = (
  id: string,
  scrollableContainer: Element,
  margin = 80,
) => {
  const getOffsetTop = (element, container, paddingOffsetTop) => {
    const getElOffset = (el) => el?.offsetTop || 0;
    const elementOffsetTop = getElOffset(element);
    const containerOffsetTop = getElOffset(container);

    const zeroIfNegative = (num) => (num < 0 ? 0 : num);
    const getTopDiff = (elOffsetTop, extraOffsetTop) =>
      zeroIfNegative(elOffsetTop - extraOffsetTop);

    return getTopDiff(elementOffsetTop, containerOffsetTop + paddingOffsetTop);
  };

  const scrollTo = (node, scrollOpts) => node?.scrollTo(scrollOpts);

  const el = document.querySelector(`[id='${id}']`);

  if (el) {
    scrollTo(scrollableContainer, {
      top: getOffsetTop(el, scrollableContainer, margin),
      behavior: 'smooth',
    });
  }
};
