import {
  type AllowedSelectValue,
  Badge,
  Box,
  Button,
  DropdownMenuPrimitives as DropdownMenu,
  FilterButton,
  Flex,
  MultiSelect,
  type MultiSelectValue,
  ScrollArea,
  type SelectOptionMemberList,
  Text,
  TextField,
  styled,
} from '@kandji-inc/nectar-ui';
import { useRef, useState } from 'react';
import type { Blueprint } from '../../blueprint-flow.types';
import { pluralizeWord } from '../../helpers';

export const SideNavigation = styled(Box, {
  width: '280px',
  minWidth: '280px',
  paddingTop: '20px',
  paddingRight: '14px',
  borderRight: '1px solid $neutral30',
  overflow: 'auto',
});

export const ParametersNavItem = styled('button', {
  display: 'flex',
  gap: '$2',
  padding: '$2 42px',
  fontSize: '$2',
  textAlign: 'left',
  borderRadius: '$1',
  width: '100%',
  margin: '0',
  border: 'none',
  cursor: 'pointer',
  alignItems: 'center',

  '&:hover': {
    background: 'rgba(170, 202, 255, 0.24)',
  },

  variants: {
    isHeader: {
      true: {
        padding: '$2 $4',
      },
    },
    selected: {
      true: {
        background: 'rgba(170, 202, 255, 0.36)',
        color: '$blue50',
        fontWeight: '$medium',
        '&:hover': {
          background: 'rgba(170, 202, 255, 0.36)',
        },
        '&:disabled': {
          background: 'none',
        },
      },
      false: {
        background: 'transparent',
        color: '$neutral90',
        fontWeight: '$regular',
      },
    },
  },

  compoundVariants: [
    {
      selected: true,
      isHeader: true,
      css: {
        background: 'transparent',
        color: '$blue50',
        fontWeight: '$medium',
        '&:hover': {
          background: 'rgba(170, 202, 255, 0.36)',
        },
      },
    },
  ],
});

type ParameterDropdownFilter = {
  name: string;
  options: SelectOptionMemberList<AllowedSelectValue, 'value', 'label'>;
  value: MultiSelectValue<string>;
  onChange: (value: any) => void;
  onClear: () => void;
  filtersSelectedCount: number;
};

export const ParameterDropdownFilter = (props: ParameterDropdownFilter) => {
  const { name, options, value, onChange, onClear, filtersSelectedCount } =
    props;

  return (
    <MultiSelect
      multi
      options={options}
      value={value}
      onChange={onChange}
      footer={{
        showClear: true,
        clearLabel: 'Clear',
        handleClear: onClear,
      }}
    >
      <FilterButton
        filtersSelected={Boolean(filtersSelectedCount)}
        showRemove={false}
        aria-label="frameworks"
      >
        <Flex flow="row" alignItems="center">
          <Text
            css={{
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
          >
            {name}
          </Text>
          {filtersSelectedCount === options.length && (
            <Flex flow="row" gap="xs">
              <Text>: </Text>
              <Text css={{ fontWeight: '$medium' }}>{' All'}</Text>
            </Flex>
          )}
          {filtersSelectedCount !== options.length &&
            filtersSelectedCount > 0 && (
              <Flex flow="row" gap="xs" alignItems="center">
                <Text>: </Text>
                <Text css={{ fontWeight: 'bold' }}>{value[0]}</Text>
                {filtersSelectedCount > 1 && (
                  <Badge
                    compact
                    css={{
                      color: '$neutral0',
                      backgroundColor: '$blue50',
                    }}
                  >{`+${filtersSelectedCount - 1}`}</Badge>
                )}
              </Flex>
            )}
        </Flex>
      </FilterButton>
    </MultiSelect>
  );
};

export const ParameterImportButton = (props: {
  blueprints: Array<Blueprint>;
  disabled: boolean;
  onImport: (blueprint: Blueprint) => void;
}) => {
  const { blueprints, disabled, onImport } = props;
  const [term, setTerm] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const [selected, setSelected] = useState<Blueprint>();
  const searchRef = useRef<HTMLInputElement>(null);

  const handleDropdownOpenChange = (open: boolean) => {
    setIsOpen(open);
    if (open) {
      setTimeout(() => searchRef.current?.focus(), 0);
    } else {
      setTerm('');
      setSelected(null);
    }
  };

  return (
    <DropdownMenu.Root
      open={isOpen}
      onOpenChange={handleDropdownOpenChange}
      modal={false}
    >
      <DropdownMenu.Trigger asChild>
        <Button
          variant="subtle"
          disabled={disabled}
          icon={{ name: 'fa-angle-down-small', position: 'right' }}
          css={
            isOpen && {
              backgroundColor: '#505e713d',
            }
          }
          data-testid="import-parameters-button"
        >
          Import from existing Blueprint
        </Button>
      </DropdownMenu.Trigger>
      <DropdownMenu.Content
        css={{
          zIndex: 3,
          width: '376px',
        }}
        align="start"
        side="bottom"
      >
        <DropdownMenu.Label
          css={{
            padding: '6px 12px',
            textTransform: 'none',
            letterSpacing: 'normal',
          }}
        >
          Select a Blueprint to import its Parameters.
        </DropdownMenu.Label>

        <Box css={{ padding: '6px 12px' }}>
          <TextField
            ref={searchRef}
            css={{
              '& svg': {
                width: '20px',
                height: '20px',
              },
            }}
            compact
            iconLeft
            icon="magnifying-glass"
            placeholder="Search Blueprints"
            value={term}
            onChange={(e) => setTerm(e.target.value)}
            data-testid="search-blueprint-import"
          />
        </Box>
        <ScrollArea
          css={{
            height: '200px',
          }}
          scrollbarPosition="right"
          compact
        >
          {blueprints
            .filter(({ name }) =>
              name.toLowerCase().includes(term.toLowerCase()),
            )
            .map((blueprint) => (
              <DropdownMenu.Item
                key={blueprint.id}
                onClick={() => setSelected(blueprint)}
                css={{
                  padding: '6px 12px',
                  color: selected?.id === blueprint.id && '$blue50',
                  '&, &[data-highlighted]': {
                    backgroundColor: selected?.id === blueprint.id && '$blue10',
                  },
                  '&:hover': {
                    background: '$blue10',
                  },
                }}
                // @ts-expect-error -- To avoid focus loss on text input
                textValue={{
                  /* istanbul ignore next */
                  toLowerCase: () => ({
                    startsWith: () => false,
                  }),
                }}
                onPointerLeave={
                  /* istanbul ignore next */
                  (e) => e.preventDefault()
                }
                onPointerMove={
                  /* istanbul ignore next */
                  (e) => e.preventDefault()
                }
                onSelect={(e) => e.preventDefault()}
                data-testid={blueprint.name}
              >
                <Box
                  css={{
                    display: 'grid',
                    gridTemplateColumns: '1fr minmax(auto, 100px)',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    flex: '1',
                  }}
                >
                  <Text>{blueprint.name}</Text>
                  <Badge
                    color="blue"
                    css={{
                      padding: '2px 6px',
                      fontSize: '$1',
                      alignSelf: 'center',
                      justifySelf: 'end',
                      width: 'min-content',
                      whiteSpace: 'nowrap',
                    }}
                  >
                    {Object.keys(blueprint.params).length}{' '}
                    {pluralizeWord(
                      'Parameter',
                      Object.keys(blueprint.params).length,
                    )}
                  </Badge>
                </Box>
              </DropdownMenu.Item>
            ))}
        </ScrollArea>
        <Flex justifyContent="end" css={{ padding: '$2 $3' }} gap="xs">
          <Button
            compact
            variant="subtle"
            onClick={() => handleDropdownOpenChange(false)}
            data-testid="cancel-import-button"
          >
            Cancel
          </Button>
          <Button
            compact
            variant="primary"
            disabled={!selected}
            onClick={() => {
              onImport(selected);
              handleDropdownOpenChange(false);
            }}
            data-testid="execute-import-button"
          >
            Import Parameters
          </Button>
        </Flex>
      </DropdownMenu.Content>
    </DropdownMenu.Root>
  );
};
