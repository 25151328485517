import { TextInput } from '@kandji-inc/bumblebee';
import { func, oneOf, shape, string } from 'prop-types';
import React from 'react';
import { requiredField } from '../../input-validations';
import { STATUSES } from '../constants';
import CategoryIcon from './category-icon';
import IconSearchSelect from './icon-search-select';

const EditAllInputs = ({
  addEditCategory,
  update,
  checkDisableSave,
  status,
}) => (
  <>
    <TextInput
      value={addEditCategory.name}
      onChange={(e) => update({ key: 'name', value: e.target.value })}
      fieldsGrid
      label="Name"
      maxLength={64}
      onBlur={() => checkDisableSave()}
      validator={requiredField}
      runValidationOn={[status]}
    />
    <TextInput
      value={addEditCategory.description}
      onChange={(e) => update({ key: 'description', value: e.target.value })}
      textArea
      fieldsGrid
      label="Description"
      isOptional
      maxLength={120}
    />
    <div>
      <CategoryIcon iconImg={addEditCategory.iconImg} />
      <IconSearchSelect selectedIcon={addEditCategory.icon} update={update} />
    </div>
  </>
);

EditAllInputs.propTypes = {
  addEditCategory: shape({
    description: string,
  }),
  update: func,
  checkDisableSave: func,
  status: oneOf(Object.values(STATUSES)),
};

EditAllInputs.defaultProps = {
  addEditCategory: {
    description: '',
  },
  update: () => {},
  checkDisableSave: () => {},
  status: STATUSES.IDLE,
};

export default EditAllInputs;
