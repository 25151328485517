import { TextInput } from '@kandji-inc/bumblebee';
import { bool, func, oneOf, shape, string } from 'prop-types';
import React, { useRef, useState, useEffect } from 'react';

import { requiredFieldCustom } from '../../input-validations';
import {
  EDIT_RECOMMENDED_CATEGORY_NAMES,
  EDIT_RECOMMENDED_CATEGORY_NAME_OPTIONS,
  STATUSES,
} from '../constants';
import CategoryIcon from './category-icon';
import CategoryNameSelect from './category-name-select';
import IconSearchSelect from './icon-search-select';

const EditRecommendedInputs = ({
  addEditCategory,
  status,
  checkDisableSave,
  update,
}) => {
  const isCustomNameInputMounted = useRef(false);
  const [nameSelect, setNameSelect] = useState(null);
  const isCustomSelected =
    nameSelect?.value === EDIT_RECOMMENDED_CATEGORY_NAMES.CUSTOM.value;

  const handleNameSelect = (value) => {
    setNameSelect(value);
  };

  useEffect(() => {
    if (!isCustomSelected && addEditCategory.name) {
      const initialNameSelect = EDIT_RECOMMENDED_CATEGORY_NAME_OPTIONS.find(
        ({ label }) => label === addEditCategory.name,
      );

      const customOption = {
        label: EDIT_RECOMMENDED_CATEGORY_NAMES.CUSTOM.label,
        value: EDIT_RECOMMENDED_CATEGORY_NAMES.CUSTOM.value,
      };

      if (!initialNameSelect) {
        update({ key: 'customName', value: addEditCategory.name });
      }

      setNameSelect(initialNameSelect || customOption);
    }
  }, [addEditCategory.name]);

  useEffect(() => {
    if (nameSelect && isCustomSelected) {
      isCustomNameInputMounted.current = true;
      update({ key: 'isCustomNameSelected', value: true });
    } else if (nameSelect) {
      update({
        batchKeyValues: {
          name: nameSelect.label,
          isCustomNameSelected: false,
        },
      });
    }
  }, [nameSelect]);

  useEffect(() => {
    if (!isCustomSelected) {
      checkDisableSave(addEditCategory.name);
    }
  }, [addEditCategory.name, isCustomSelected]);

  useEffect(() => {
    if (isCustomNameInputMounted.current === true) {
      isCustomNameInputMounted.current = false;
    }
  }, [addEditCategory.customName]);

  return (
    <>
      <div>
        <h3 className="b-txt b-mb-micro">Name</h3>
        <p className="b-txt-light">
          Select a display name for this category or create a custom one.
        </p>
      </div>
      <CategoryNameSelect
        nameSelect={nameSelect}
        setNameSelect={handleNameSelect}
      />
      {isCustomSelected && (
        <TextInput
          value={addEditCategory.customName}
          placeholder="Add custom name"
          onChange={(e) => update({ key: 'customName', value: e.target.value })}
          maxLength={32}
          onBlur={() => checkDisableSave(addEditCategory.customName)}
          validator={requiredFieldCustom(
            isCustomNameInputMounted.current,
            status === STATUSES.DISABLED,
          )}
          runValidationOn={[status]}
        />
      )}
      <TextInput
        value={addEditCategory.description}
        onChange={(e) => update({ key: 'description', value: e.target.value })}
        textArea
        fieldsGrid
        label="Description"
        isOptional
        maxLength={240}
      />
      <div>
        <CategoryIcon iconImg={addEditCategory.iconImg} />
        <IconSearchSelect selectedIcon={addEditCategory.icon} update={update} />
      </div>
    </>
  );
};

EditRecommendedInputs.propTypes = {
  addEditCategory: shape({
    customName: string,
    isCustomNameSelected: bool,
    description: string,
    icon: string,
    iconImg: string,
    name: string,
  }),
  status: oneOf(Object.values(STATUSES)),
  checkDisableSave: func,
  update: func,
};

EditRecommendedInputs.defaultProps = {
  addEditCategory: {
    customName: '',
    isCustomNameSelected: false,
    description: '',
    icon: '',
    iconImg: '',
    name: '',
  },
  status: STATUSES.IDLE,
  checkDisableSave: () => {},
  update: () => {},
};

export default EditRecommendedInputs;
