/* istanbul ignore file */

import React, { useState, useEffect } from 'react';
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom';

import ActivityTabNew from 'app/components/ActivityTabNew';

import Loader from 'theme/components/atoms/Loader';

import { useConnector } from '../../hooks';

const Content = () => {
  const history = useHistory();
  const location = useLocation();
  const match = useRouteMatch();

  const router = {
    history,
    location,
    match,
  };

  const { connectorList, fetchConnectorList, clearConnectorList } =
    useConnector();

  const [checked, setChecked] = useState(false);

  /* istanbul ignore next */
  const check = async () => {
    setChecked(false);
    await fetchConnectorList();
    setChecked(true);
  };

  /* istanbul ignore next */
  const reset = () => {
    clearConnectorList();
    setChecked(false);
  };

  useEffect(() => {
    check();

    return () => {
      reset();
    };
  }, []);

  return checked ? (
    <ActivityTabNew
      activityTabType="adcs"
      connectorList={connectorList}
      {...router}
    />
  ) : (
    <Loader type="line" />
  );
};

export default Content;
