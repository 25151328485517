import { Paragraph } from '@kandji-inc/nectar-ui';
/* istanbul ignore file */
import React from 'react';

import useOktaDeviceTrustPlatform from 'features/integrations/components-for-specific-types/okta/hooks/use-okta-device-trust-platform';
import exclamationTriangle from 'features/integrations/components-for-specific-types/okta/okta-details/assets/exclamation-triangle.svg';
import type { DeleteIntegrationProps } from 'features/integrations/components-for-specific-types/okta/types';
import FooterButtons from 'features/integrations/components/footer-buttons';
import type { LibraryItem } from 'features/integrations/data-service/types/library-item-service.types';
import { useHistory } from 'react-router-dom';
import { i18n } from 'src/i18n';
import styled from 'styled-components';
import oktaVerify from '../assets/okta-verify.svg';

type CannotDeleteIntegrationProps = DeleteIntegrationProps & {
  oktaLicenseData: LibraryItem;
};

const StyledTextInputContainer = styled.div`
  text-align: left;
`;

export const StyledContainer = styled.div`
  width: 560px;
  height: 268px;
`;

const StyledHeader = styled.h1`
  text-align: left;
  margin-bottom: 12px;
  margin-left: 32px;
  margin-top: 24px;
`;

const StyledWarningContainer = styled.div`
  width: 496px;
  display: flex;
  margin-left: 32px;
  margin-top: 24px;
  background-color: #fffae6;
`;

const StyledLibraryItemContainer = styled.div`
  width: 496px;
  height: 56px;
  display: flex;
  align-items: center;
  margin-left: 32px;
  margin-top: 24px;
  background-color: #1a1d2505;
`;

const FooterButtonContainer = styled.div`
  margin-top: 66px;
  padding-top: 46px;
`;

const StyledImage = styled.img`
  margin-left: 12px;
`;

const StyledOktaVerifyImage = styled.img`
  margin-left: 12px;
  height: 24px;
  width: 24px;
`;

const StyledWarning = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  width: 399px;
  margin-left: 8px;
`;

const CannotDeleteIntegration = (props: CannotDeleteIntegrationProps) => {
  const { onClose, oktaLicenseData } = props;
  const { isLoading } = useOktaDeviceTrustPlatform();
  const history = useHistory();

  const { id } = oktaLicenseData;

  const handleNext = async () => {
    history.push(`/library/app-store-apps/${id}`);
    onClose();
  };

  const closeModal = async () => {
    onClose();
  };

  return (
    <>
      <StyledContainer>
        <StyledTextInputContainer>
          <StyledHeader>
            {i18n.t('Unable to delete Okta Device Trust')}
          </StyledHeader>
          <StyledWarningContainer>
            <StyledImage
              src={exclamationTriangle}
              alt={i18n.t('exclamation triangle icon')}
            />
            <StyledWarning>
              {i18n.t(
                'Integration cannot be deleted because of a Library items conflict.',
              )}
            </StyledWarning>
          </StyledWarningContainer>
          <Paragraph
            css={{
              paddingLeft: '32px',
              paddingTop: '16px',
              paddingRight: '32px',
            }}
          >
            {i18n.t(
              'In order to delete the integration with Okta Device Trust, the following Library item will need to be modified. Navigate to the Library item to turn off the Okta Device Trust integration under settings.',
            )}
            '
          </Paragraph>
          <Paragraph
            css={{
              paddingLeft: '32px',
              paddingTop: '8px',
              paddingRight: '32px',
            }}
          >
            {i18n.t(
              'The following Library item has the Okta Device Trust integration turned on in the configuration settings:',
            )}
          </Paragraph>
          <StyledLibraryItemContainer>
            <StyledOktaVerifyImage src={oktaVerify} alt={i18n.t('okta icon')} />
            <Paragraph
              css={{ paddingLeft: '8px', fontWeight: '500', color: '#0057DA' }}
            >
              {i18n.t('Okta Verify')}
            </Paragraph>
          </StyledLibraryItemContainer>
        </StyledTextInputContainer>
      </StyledContainer>
      <FooterButtonContainer>
        <FooterButtons
          onClose={closeModal}
          handleNext={handleNext}
          cancelButtonText={i18n.t('Close')}
          nextButtonText={i18n.t('Go to Library item')}
          showBackButton={false}
          showStepper={false}
          stepperIdx={4}
          stepsAmount={6}
          isWorking={isLoading}
        />
      </FooterButtonContainer>
    </>
  );
};

export default CannotDeleteIntegration;
