import { useMutation } from '@tanstack/react-query';
import { newIntegrationsService } from '../../../data-service/new-integrations-service';

type EventNotificationData = {
  name?: string;
  events?: string[];
};

type Data = {
  notificationId: string;
  data: EventNotificationData;
};

const useEventNotifications = () =>
  useMutation({
    mutationFn: (data: Data) =>
      newIntegrationsService.updateNotificationDetails(
        data.notificationId,
        data.data,
      ),
  });

export default useEventNotifications;
