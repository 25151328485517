import { useQuery } from '@tanstack/react-query';
import { newIntegrationsService } from 'src/features/integrations/data-service/new-integrations-service';

const useGetChannels = (integrationId: number) =>
  useQuery({
    queryKey: ['slackChannels', integrationId],
    queryFn: () => newIntegrationsService.getChannels(integrationId),
    enabled: !!integrationId,
  });

export default useGetChannels;
