import {
  arrayOf,
  elementType,
  number,
  object,
  oneOfType,
  string,
} from 'prop-types';
import React, { useEffect, useState } from 'react';

function DefaultPlaceholder({ style, className, height, width }) {
  return (
    <div
      style={{
        background: 'var(--color-neutral-10)',
        height,
        width,
        ...style,
      }}
      className={className}
    />
  );
}

DefaultPlaceholder.propTypes = {
  style: object,
  className: string,
  height: oneOfType([string, number]),
  width: oneOfType([string, number]),
};

DefaultPlaceholder.defaultProps = {
  style: {},
  className: '',
  height: undefined,
  width: undefined,
};

// https://developer.mozilla.org/en-US/docs/Web/API/HTMLImageElement/srcset
// Does the same trick, but is not supported in some browser versions
function ImgWithAlt({ src, PlaceholderComponent, alt, ...otherProps }) {
  const [suitableSrc, setSuitableSrc] = useState();

  useEffect(() => {
    const chooseSrc = async () => {
      const srcSet = Array.isArray(src) ? src : [src];

      for (const srcToTry of srcSet) {
        const bestSrc = await new Promise((resolve) => {
          const testImg = new Image();
          testImg.src = srcToTry;
          testImg.onload = () => {
            resolve(srcToTry);
          };
          testImg.onerror = () => {
            resolve();
          };
        });
        if (bestSrc) {
          setSuitableSrc(bestSrc);
          return;
        }
      }

      setSuitableSrc(null);
    };

    chooseSrc();
  }, [src]);

  return suitableSrc === undefined ? (
    <PlaceholderComponent {...otherProps} />
  ) : (
    <img alt={alt} src={suitableSrc} {...otherProps} />
  );
}

ImgWithAlt.propTypes = {
  src: oneOfType([string, arrayOf(string)]).isRequired,
  alt: string.isRequired,
  PlaceholderComponent: elementType,
};

ImgWithAlt.defaultProps = {
  PlaceholderComponent: DefaultPlaceholder,
};

export { ImgWithAlt, DefaultPlaceholder };
