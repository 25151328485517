import { Chip, setClass } from '@kandji-inc/bumblebee';
import Tippy from '@tippyjs/react';
import { AccountContext } from 'contexts/account';
import { EnvironmentContext } from 'contexts/environment';
import React, {
  createRef,
  forwardRef,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Link } from 'react-router-dom';
import HoverTippy from 'src/features/util/components/hover-tippy';
import PreviewHoverTippy from 'src/features/util/components/preview-hover-tippy';
import Loading from '../../../../../assets/img/loading_sm.svg';
import {
  devicesData as defaultDevicesData,
  devices as devicesMap,
  getItemConfigDevices,
} from '../../../data-service/library-item/devices';
import {
  displayDeviceType,
  installTypeIcons,
  installTypes,
  updateOnlyIconName,
} from '../../common';
import IconRules from '../assets/icon_rules.svg';
import SelfServiceBee from '../assets/self_service_bee.svg';
// import 'tippy.js/dist/tippy.css';
import './library-item-card.css';
import { Box, Icon } from '@kandji-inc/nectar-ui';
import { i18n } from 'i18n';

// display label `iPhone` when runs_on_ipod
const devicesData = {
  ...defaultDevicesData,
  [devicesMap.IPOD]: {
    ...defaultDevicesData[devicesMap.IPOD],
    label: 'iPhone',
  },
};

export const getCardDevices = (item) => () => {
  const devicesFromConfig = getItemConfigDevices(item.type, item.devices);
  if (devicesFromConfig) {
    return devicesFromConfig;
  }

  const dedupedRunsOnDevices = new Set();
  Object.values(devicesData).forEach(({ label, commonKey }) => {
    const hasRunsOnDevice = item[commonKey];
    if (hasRunsOnDevice && label /* filters out watch which has no label */) {
      dedupedRunsOnDevices.add(label);
    }
  });

  return [...dedupedRunsOnDevices];
};

const LibraryItemCard: React.FC<LibraryItemCardProps> = ({
  ...props
}: LibraryItemCardProps) => {
  const { item, bodyRef, dimensions } = props;
  const {
    isSelfServiceEnabled,
    selfServiceInstallType,
    name,
    formattedItemName,
    licenses,
    active,
    blueprints,
    is_all_blueprints: isAllBlueprints,
    excluded_blueprints: excludedBlueprints,
    counts,
    icon,
    shouldShowStatuses,
    isLoadingCounts,
    defaultConfiguration,
    instance_name: instanceName,
    is_update_only: isUpdateOnly,
    app_version: appVersion,
  } = item;

  const devices = useMemo(getCardDevices(item), []);
  const account = useContext(AccountContext);
  const environment = useContext(EnvironmentContext);
  const [hiddenBlueprints, setHiddenBlueprints] = useState([]);
  const [blueprintRefs, setBlueprintRefs] = useState([]);
  const [hiddenExcludedBlueprints, setHiddenExcludedBlueprints] = useState([]);
  const [excludedBlueprintRefs, setExcludedBlueprintRefs] = useState([]);
  const [iconImg, setIconImg] = useState(icon);

  useEffect(() => {
    setBlueprintRefs((elRefs) =>
      Array(blueprints.length)
        .fill()
        .map((_, i) => elRefs[i] || createRef()),
    );
  }, [blueprints]);

  useEffect(() => {
    if (dimensions && blueprintRefs.length) {
      const m = blueprints.reduce(
        (a, c, idx) => {
          const tagWidth =
            blueprintRefs[idx].current?.offsetWidth || 10 * c.name.length;
          if (a.remaining - tagWidth < 80) {
            a.hidden.push(c);
          } else {
            a.remaining -= tagWidth;
          }
          return a;
        },
        { hidden: [], remaining: dimensions.width },
      );
      setHiddenBlueprints(m.hidden);
    }
  }, [blueprintRefs, dimensions]);

  useEffect(() => {
    setExcludedBlueprintRefs((elRefs) =>
      Array(excludedBlueprints?.length)
        .fill()
        .map((_, i) => elRefs[i] || createRef()),
    );
  }, [excludedBlueprints]);

  useEffect(() => {
    if (dimensions && excludedBlueprintRefs.length) {
      const m = excludedBlueprints?.reduce(
        (a, c, idx) => {
          const tagWidth =
            excludedBlueprintRefs[idx].current?.offsetWidth ||
            10 * c.name.length;
          if (a.remaining - tagWidth < 80) {
            a.hidden.push(c);
          } else {
            a.remaining -= tagWidth;
          }
          return a;
        },
        // Subtract 115 to adjust for the approximate width of the "All Blueprints chip"
        { hidden: [], remaining: dimensions.width - 130 },
      );
      setHiddenExcludedBlueprints(m?.hidden);
    }
  }, [excludedBlueprintRefs, dimensions]);

  const getLicenseClass = (licenseInfo) => {
    if (!licenseInfo) {
      return 'li-primary-marengo-dark';
    }
    const per = licenseInfo.used / licenseInfo.total;
    const threshold = 0.8;
    if (per < threshold) {
      return 'li-primary-marengo-dark';
    }
    if (per >= threshold && per < 1) {
      return 'li-system-orange-dark';
    }
    return 'li-system-red-dark';
  };

  const getSubtitle = () => {
    if (licenses || formattedItemName || instanceName || appVersion) {
      return (
        <div
          className={`b-txt-ctrl9 li-item-card__subtitle ${getLicenseClass(
            licenses,
          )}`}
        >
          {licenses &&
            i18n.t(`{count} of {total} licenses used`, {
              count: licenses.used,
              total: licenses.total,
            })}
          {!licenses && (formattedItemName || instanceName)}
          {appVersion != null && <>Version {appVersion}</>}
        </div>
      );
    }

    return '';
  };

  const getBlueprints = () => {
    const blueprintsList = (
      <>
        {blueprints.length === 0 && (
          <Chip kind="tertiary-lighter" text={i18n.t('Not Assigned')} />
        )}
        {blueprints.map((blueprint, idx) => {
          const WithRefChip = forwardRef((forwardedProps, ref) => (
            <Chip {...forwardedProps} chipRef={ref} />
          ));
          return (
            <WithRefChip
              ref={(r) => {
                if (blueprintRefs[idx]) {
                  blueprintRefs[idx].current = r;
                }
              }}
              key={`${blueprint}_${idx}`}
              className={`li-mr-tiny ${
                hiddenBlueprints.includes(blueprint)
                  ? 'li-item-card__blueprint-hidden'
                  : ''
              }`}
              text={blueprint.name}
            />
          );
        })}
        {hiddenBlueprints.length > 0 && (
          <Tippy
            content={
              <div className="li-item-card__hidden-blueprints">
                {hiddenBlueprints.map((bp, idx) => (
                  <Chip key={`${bp.name}_${idx}`} text={bp.name} />
                ))}
              </div>
            }
            theme="li-self-service"
            interactive
            appendTo={document.body}
          >
            <div>
              <Chip text={`+${hiddenBlueprints.length}`} />
            </div>
          </Tippy>
        )}
      </>
    );

    const isAllBlueprintsList = (
      <div className="li-item-card__all-blueprints b-flex">
        <Chip
          kind="info"
          text={i18n.t('All Classic Blueprints')}
          iconLeft="kandji-blueprint"
          className="b-mr-tiny"
        />
        {excludedBlueprints?.length > 0 && (
          <p className="b-txt-light">
            {i18n.t('excluding ')}
            {excludedBlueprints?.map((excludedBlueprint, idx) => (
              <span
                ref={(r) => {
                  if (excludedBlueprintRefs[idx]) {
                    excludedBlueprintRefs[idx].current = r;
                  }
                }}
                key={`${excludedBlueprint}_${idx}`}
                className={setClass(
                  hiddenExcludedBlueprints?.includes(excludedBlueprint)
                    ? 'li-item-card__blueprint-hidden'
                    : '',
                )}
              >
                {/* Add a comma between each item */}
                {idx !== 0 ? ', ' : ''}
                {excludedBlueprint.name}
              </span>
            ))}
            {hiddenExcludedBlueprints?.length > 0 && (
              <span>
                {/* Only show '+' if at least one blueprint name was displayed, otherwise just display the number */}
                {excludedBlueprints.length !== hiddenExcludedBlueprints.length
                  ? ' +'
                  : ' '}
                {`${hiddenExcludedBlueprints.length}`}
              </span>
            )}
          </p>
        )}
      </div>
    );

    return isAllBlueprints ? isAllBlueprintsList : blueprintsList;
  };

  const hasRules = item?.has_rules;
  const isPreviewChip = item?.is_preview_chip;

  const CountsBar = ({ isLoadingCounts }) =>
    useMemo(
      () => (
        <div
          className={`li-item-card__counts ${
            !shouldShowStatuses ? '--hide-counts' : ''
          }`}
        >
          {shouldShowStatuses &&
            ['success', 'failed', 'pending'].map((status) => (
              <div key={status} className="b-flex-vc b-flex-hc">
                <div
                  className={`li-item-card__count --${status} li-mr-micro`}
                />
                <p className="b-txt-bold li-item-card__count-number">
                  {isLoadingCounts ? (
                    <img
                      src={Loading}
                      width="12px"
                      height="12px"
                      alt="Loading"
                    />
                  ) : (
                    counts[status]
                  )}
                </p>
              </div>
            ))}
        </div>
      ),
      [isLoadingCounts],
    );

  return (
    <Link
      className={`li-item-card decorate-off ${
        !active ? 'li-item-card__inactive' : ''
      }`}
      to={item.getUrl({ id: item.id, account, environment })}
      data-testid={`${name}_${item.instance_name}`}
    >
      <div className="li-item-card__icons b-flex-gtiny b-flex-align-center">
        {isPreviewChip && <PreviewHoverTippy />}
        {hasRules && (
          <HoverTippy text={i18n.t('Assignment Rules')} icon={IconRules} />
        )}
        {isSelfServiceEnabled && (
          <HoverTippy text={i18n.t('Self Service')} icon={SelfServiceBee} />
        )}
        {installTypeIcons[selfServiceInstallType] && (
          <HoverTippy
            text={
              isUpdateOnly
                ? i18n.t('Update Only')
                : selfServiceInstallType === installTypes.ONCE
                  ? i18n.t('Install Once')
                  : i18n.t('Continuously Enforce')
            }
            icon={!isUpdateOnly && installTypeIcons[selfServiceInstallType]}
          >
            {isUpdateOnly && (
              <Box
                css={{
                  '& svg': {
                    color: 'rgb(77, 90, 121)',
                    width: '14px',
                    height: '11px',
                    scale: '1.3',
                  },
                }}
              >
                <Icon className="tippy-svg" name={updateOnlyIconName} />
              </Box>
            )}
          </HoverTippy>
        )}
      </div>
      <div className="li-item-card__content">
        <div className="li-item-card__header">
          <img
            src={iconImg}
            onError={() => setIconImg(defaultConfiguration.icon)}
            className="li-item-card__header-img"
            alt="Library Item Icon"
          />
          <div className="li-item-card__header-info">
            <h3 className="b-h3 li-item-card__header-title">{name}</h3>
            {getSubtitle()}
          </div>
        </div>
        <div className="li-item-card__body" ref={bodyRef}>
          <div className="li-item-card__blueprints li-mb-tiny b-flex-vc">
            {getBlueprints()}
          </div>
          <div className="li-item-card__tags b-flex">
            {devices.length ? (
              devices.map((device) => (
                <Chip
                  key={device}
                  className="li-mr-tiny"
                  text={displayDeviceType(device)}
                  kind="secondary"
                />
              ))
            ) : (
              <Chip text={i18n.t('Mac')} />
            )}
          </div>
        </div>
      </div>
      {active ? (
        <CountsBar isLoadingCounts={isLoadingCounts} />
      ) : (
        <div className="b-txt-bold b-flex-vc b-flex-hc li-item-card__footer-inactive">
          {i18n.t('INACTIVE')}
        </div>
      )}
    </Link>
  );
};

interface LibraryItemCardProps {
  item: {
    isSelfServiceEnabled: boolean;
    selfServiceInstallType: string;
    name: string;
    formattedItemName: string;
    licenses: string[];
    active: boolean;
    blueprints: string[];
    is_all_blueprints: boolean;
    excluded_blueprints: string[];
    counts: number;
    icon: string;
    shouldShowStatuses: boolean;
    isLoadingCounts: boolean;
    defaultConfiguration: string;
    instance_name: string;
    is_update_only: boolean;
  };
  bodyRef: React.Ref<HTMLDivElement>;
  dimensions: { width: number; height: number };
  name?: string;
  licenses?: any;
  blueprints?: any[];
  devices?: any[];
  counts?: {
    pending: number;
    success: number;
    failed: number;
  };
}

LibraryItemCard.defaultProps = {
  name: '',
  licenses: null,
  blueprints: [],
  devices: [],
  counts: { pending: 0, success: 0, failed: 0 },
};

export default React.forwardRef((props, ref) => (
  <LibraryItemCard {...props} bodyRef={ref} />
));
