import { Button } from '@kandji-inc/nectar-ui';
import { i18n } from 'i18n';
import { func, object } from 'prop-types';
/* istanbul ignore file */
import React from 'react';
import { useHistory } from 'react-router-dom';
import featureFlags from 'src/config/feature-flags';
import { INT_TYPES } from 'src/features/integrations/constants';
import cardConfigurations from '../../generic-cards-view/cards-config';

import { useModal } from '../../components-for-specific-types/adcs/hooks';
import './marketplace-integration-card.styles.scss';

function MarketplaceIntegrationCard({ integrationData, setWelcomeModal }) {
  const history = useHistory();
  const { type } = integrationData;
  const { text, title, img } = cardConfigurations[type];
  const { setModal } = useModal();

  const handleClick = () => {
    history.push(`/integrations/new?type=${type}`);
  };

  const oktaIntegratorFlow =
    type === INT_TYPES.okta && featureFlags.getFlag('integrator-flow-poc');
  const adcsIntegratorFlow =
    type === INT_TYPES.adcs &&
    featureFlags.getFlag('paint-adcs-integrator-flow');
  const servicenowIntegratorFlow = type === INT_TYPES.servicenow;
  const msDeviceComplianceFlow = type === INT_TYPES.msDeviceCompliance;

  const showIntegratorFlow =
    oktaIntegratorFlow ||
    servicenowIntegratorFlow ||
    adcsIntegratorFlow ||
    msDeviceComplianceFlow;

  return (
    <>
      {/* !!! Migration to Integrator flow !!! */}
      {!showIntegratorFlow && (
        <div className="int-card_wrapper">
          <img
            className="int-card_icon"
            alt="int-card_wrapper__icon"
            src={img}
          />
          <div className="int-card_detail">
            <h3 className="b-h3 b-ml1">{title()}</h3>
            <p className="b-txt-light int-card_detail__description">{text()}</p>
            <Button
              icon={{ name: 'circle-plus' }}
              variant="subtle"
              onClick={(event) => {
                event.stopPropagation();
                setWelcomeModal(integrationData.type);
                // this sets the new welcome modal controller
                setModal(integrationData.type);
              }}
            >
              {i18n.t('Add and configure')}
            </Button>
          </div>
        </div>
      )}

      {showIntegratorFlow && (
        <div className="int-card_wrapper ribbon-box">
          <img
            className="int-card_icon"
            alt="int-card_wrapper__icon"
            src={img}
          />
          <div className="int-card_detail">
            <h3 className="b-h3 b-ml1">{title()}</h3>
            <p className="b-txt-light int-card_detail__description">{text()}</p>
            <Button
              icon={{ name: 'circle-plus' }}
              variant="subtle"
              onClick={() => handleClick()}
            >
              {i18n.t('Add and configure')}
            </Button>
          </div>
        </div>
      )}
    </>
  );
}

MarketplaceIntegrationCard.propTypes = {
  integrationData: object.isRequired,
  setWelcomeModal: func.isRequired,
};

export default MarketplaceIntegrationCard;
