import type { Dispatch, SetStateAction } from 'react';
import React, { memo } from 'react';
import { createPortal } from 'react-dom';

import { Button, Flex, Heading, styled } from '@kandji-inc/nectar-ui';

import { GRAPH_CONTAINER_ID } from '../constants';

const Backdrop = styled('div', {
  position: 'absolute',
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
  height: '100%',
  width: '100%',
  backgroundColor: '$neutral900',
  zIndex: 2,
});

const ModalContainer = styled(Flex, {
  position: 'sticky',
  flexDirection: 'column',
  gap: '$3',
  left: 0,
  right: 0,
  bottom: 0,
  zIndex: 2000,
  padding: '$5',
  backgroundColor: '$neutral0',
});

function DeleteModal(props: {
  title: string;
  content: string;
  isOpen: boolean;
  toggle: (arg0: boolean) => void;
  runDelete: () => void;
  setIsHovering?: Dispatch<SetStateAction<boolean>>;
}) {
  const { title, content, isOpen, toggle, runDelete, setIsHovering } = props;

  const closeModal = () => {
    toggle(false);

    setIsHovering?.(false);
  };

  const handleCancel = () => closeModal();
  const handleDelete = () => {
    closeModal();

    // Since this modal is mounted within the node component, the modal is
    // forced to unmount when the node is deleted, so to prevent a log
    // warning, `setTimeout` is used here to offset the `runDelete` call to
    // be after the modal actually closes.
    setTimeout(() => runDelete());
  };

  const Footer = (
    <Flex gap="md" justifyContent="end" wrap="wrap">
      <Button
        variant="subtle"
        onClick={handleCancel}
        data-testid="delete-modal-cancel-btn"
      >
        Cancel
      </Button>

      <Button
        variant="danger"
        onClick={handleDelete}
        data-testid="delete-modal-confirm-btn"
      >
        Yes, delete
      </Button>
    </Flex>
  );
  if (!isOpen) {
    return null;
  }

  return createPortal(
    <ModalContainer>
      <Button
        compact
        variant="subtle"
        icon={{ name: 'xmark' }}
        onClick={handleCancel}
        css={{ position: 'absolute', top: 20, right: 20 }}
      />
      <Heading size="3">{title}</Heading>
      {content}
      {Footer}
    </ModalContainer>,
    document.getElementById(GRAPH_CONTAINER_ID) as HTMLElement,
  );
}

export default memo(DeleteModal);
