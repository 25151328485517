import React from 'react';
import styled from 'styled-components';
import UniversalAlert from './UniversalAlert';

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const Text = () => (
  <TextWrapper>
    <div>Apple Business Manager terms and conditions have been updated.</div>
    <div>
      Automated Device Enrollment functionality will be limited until new terms
      are accepted.
    </div>
  </TextWrapper>
);

const ABMExpiredBanner = () => (
  <UniversalAlert
    type="warning"
    text={<Text />}
    button={{
      text: 'Review',
      onClick: () => window.open('https://business.apple.com/'),
    }}
    secondButton={{
      text: 'Dismiss',
      closeOnClick: true,
    }}
  />
);

export default ABMExpiredBanner;
