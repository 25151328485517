/* istanbul ignore file */

import type { Edge, Node } from 'reactflow';

import { v4 as uuid } from 'uuid';

import { EDGE_TYPES, NODE_TYPES } from '../constants';
import { initialAssignmentData } from '../initial-state';

/**
 * Creates a new Conditional node
 * @param id - the ID of the new Conditional node
 * @param path - the "line" the Conditional node is on, "root" or "branch"
 * @returns a new object of a Conditional node
 */
const createConditionalNode = ({
  id,
  path,
}: {
  id: Node['id'];
  path: string;
}) => ({
  id,
  type: NODE_TYPES.conditional,
  position: { x: 0, y: 0 },
  data: {
    path,
  },
});

/**
 * Creates a new Assignment node
 * @param parentNodeId - the ID of the parent node
 * @returns a new object of an Assignment node
 */
const createAssignmentNode = ({
  parentNodeId,
}: {
  parentNodeId: Node['id'];
}) => ({
  id: uuid(),
  parentNode: parentNodeId,
  type: NODE_TYPES.assignment,
  position: { x: 0, y: 0 },
  data: {
    ...initialAssignmentData,
  },
});

/**
 * Creates a new Router node
 * @param id - the ID of the new Router node
 * @param targetNode - the object of the target node
 * @returns a new object of a Router node
 */
const createRouterNode = ({
  id,
  targetNode,
}: {
  id: Node['id'];
  targetNode: Node;
}) => ({
  id,
  type: NODE_TYPES.router,

  // Place the Router node at the current position of the target node
  // (prevents "jumping" when animated)
  position: {
    x: targetNode.position.x,
    y: targetNode.position.y,
  },
});

/**
 * Creates a new Root edge
 * @param source - the ID of the source node
 * @param target - the ID of the target node
 * @returns a new object of a Root edge
 */
const createRootEdge = ({
  source,
  target,
}: {
  source: Edge['source'];
  target: Edge['target'];
}) => ({
  id: `${source}->${target}`,
  type: EDGE_TYPES.root,
  source,
  target,
});

/**
 * Creates a new Branch edge which connects Assignment node -> Router or Conditional node
 * @param source - the ID of the Conditional node
 * @param target - the ID of the target node (this can be either a Router or Conditional node)
 * @returns a new object of a Branch edge
 */
const createBranchEdge = ({
  source,
  target,
}: {
  source: Edge['source'];
  target: Edge['target'];
}) => ({
  id: `${source}->${target}`,
  type: EDGE_TYPES.branch,
  source,
  target,
});

export {
  createConditionalNode,
  createAssignmentNode,
  createRouterNode,
  createRootEdge,
  createBranchEdge,
};
