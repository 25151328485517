import { useState } from 'react';
import { getNextSortState } from 'src/features/visibility/prism/contexts/utils/sortUtils';

type SortState = 'desc' | 'none' | 'asc';
type SortFn = (id?: string, state?: SortState) => void;

export type TSort = {
  id: string;
  state: SortState;
};

export const getSortParam = (id: string, state: SortState) => {
  if (state === 'asc') {
    return id;
  }

  return `-${id}`;
};

const useSort = (
  defaultSort: { id: string; state: 'asc' | 'desc' } = {
    id: 'name',
    state: 'asc',
  },
): [TSort, SortFn] => {
  const [sort, setSort] = useState<TSort>(defaultSort);

  const onSort = (id?: string, state?: SortState) =>
    setSort((prev) => ({
      id: id || prev.id,
      state: state || getNextSortState(sort.state),
    }));

  return [sort, onSort];
};

export default useSort;
