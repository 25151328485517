import { Box, Button, Flex } from '@kandji-inc/nectar-ui';
import React from 'react';

import ActivityTypeFilter from './ActivityTypeFilter';
import DateFilter from './DateFilter';

export type Filter = {
  type: Array<string>;
  period: {
    enumValue: string;
    dateRange: {
      from: Date | null;
      to: Date | null;
    };
  };
};

export const defaultFilter: Filter = {
  type: [],
  period: {
    enumValue: 'ALL',
    dateRange: {
      from: null,
      to: null,
    },
  },
};

export type FilterBarProps = {
  filter: Filter;
  onChange: <K extends keyof Filter>(field: K, value: Filter[K]) => void;
  isFilterActive: boolean;
  onClearAll: () => void;
};

const FilterBar = (props: FilterBarProps) => {
  const { filter, onChange, onClearAll, isFilterActive } = props;

  return (
    <Box pl5 pr5>
      <Flex alignItems="center" pt3 pb3 gap="md">
        {/* <TextField
          compact
          placeholder="Search devices"
          icon="magnifying-glass"
          value={filter.term}
          showClearButton={Boolean(filter.term)}
          onClear={() => onChange('term', '')}
          onChange={(e) => onChange('term', e.target.value)}
          css={{ width: '240px' }}
          data-testid="am-activity-search"
        /> */}
        <ActivityTypeFilter
          name="Activity type"
          value={filter.type}
          onChange={(selected) => onChange('type', selected)}
          onClear={() => onChange('type', [])}
          filtersSelectedCount={filter.type.length}
        />
        <DateFilter
          onChange={(updatedValue) => onChange('period', updatedValue)}
          period={filter.period}
          defaultFilter={defaultFilter.period}
        />
        {isFilterActive && (
          <Button variant="subtle" compact onClick={onClearAll}>
            Clear all
          </Button>
        )}
      </Flex>
    </Box>
  );
};

export default FilterBar;
