import React, { memo } from 'react';

import { Flex, Icon, Text } from '@kandji-inc/nectar-ui';

function ErrorMessage(props: { message: string }) {
  const { message } = props;

  return (
    <Flex alignItems="center" gap="xs">
      <Icon name="octagon-exclamation" color="var(--colors-red50)" size="xs" />

      <Text variant="danger" size="1">
        {message}
      </Text>
    </Flex>
  );
}

export default memo(ErrorMessage);
