import PropTypes from 'prop-types';
import React, { memo } from 'react';

const Field = memo(({ isDisplay, component: Component, props }) => {
  if (isDisplay) {
    return <Component {...props} />;
  }
  return null;
});

Field.propTypes = {
  isDisplay: PropTypes.bool,
  component: PropTypes.func.isRequired,
  props: PropTypes.objectOf(PropTypes.any).isRequired,
};

Field.defaultProps = {
  isDisplay: true,
};

export default Field;
