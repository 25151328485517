import type { QueryObserverResult } from '@tanstack/react-query';
import * as React from 'react';
import { api } from 'src/app/api/base';
import type { Device } from '../ADEListViewTable/ADEListViewTableColumns';
import { useADEListViewStore } from '../store/ADEListViewStore';
import { isWithinLastMinute } from './useGetADELastSync';

export function useADEFetchDevices(
  lastSync: number,
  sync: () => Promise<QueryObserverResult<Device[], Error>>,
  refetchDevices: () => Promise<void>,
) {
  const [currentTime, setCurrentTime] = React.useState<number>(Date.now());
  const [isFetching, setIsFetching] = React.useState<boolean>(false);
  const { setIsSyncing } = useADEListViewStore((state) => ({
    setIsSyncing: state.setIsSyncing,
  }));

  React.useEffect(() => {
    /* istanbul ignore next */
    if (isWithinLastMinute(lastSync)) {
      setIsSyncing(false);
    }

    const interval = setInterval(
      /* istanbul ignore next */
      () => {
        setCurrentTime(Date.now());
      },
      60 * 1000,
    );

    return () => clearInterval(interval);
  }, [lastSync, setCurrentTime]);

  const handleSync = React.useCallback(
    async function handleSync() {
      try {
        setIsSyncing(true);
        setIsFetching(true);
        await api('dep/devices/sync/').post();
        await sync();
        await refetchDevices();
        setIsFetching(false);
      } catch {}
    },
    [refetchDevices, sync, setIsSyncing, setIsFetching],
  );

  return {
    handleSync,
    currentTime,
    isFetching,
  };
}
