import React from 'react';

import Content from './content';

const Tab = () => (
  <div className="k-tab hubspot-buffer-bottom">
    <Content />
  </div>
);

export default Tab;
