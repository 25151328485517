import './style.css';

import React, { useState } from 'react';

import { Button } from '@kandji-inc/bumblebee';

import Modal from './modal';

export default () => {
  const [isAddingUser, setIsAddingUser] = useState(false);

  return (
    <div className="k-invite-user">
      <Button
        icon="plus"
        iconPlacement="right"
        onClick={() => setIsAddingUser(true)}
      >
        New User
      </Button>
      <Modal isOpen={isAddingUser} onClose={() => setIsAddingUser(false)} />
    </div>
  );
};
