import React from 'react';

import { node, string } from 'prop-types';

import { Flex } from '@kandji-inc/bumblebee';

const Item = ({ icon, children }) => (
  <div className="k-adcs-panel-item">
    <Flex align="center">
      <img src={icon} alt="icon" />
      <p className="b-txt">{children}</p>
    </Flex>
  </div>
);

Item.propTypes = {
  icon: string.isRequired,
  children: node.isRequired,
};

export default Item;
