import React from 'react';

import { i18n } from 'i18n';

import {
  i18nAdcsTicketTextMap,
  i18nAdcsTicketTitleMap,
} from '../../../../../localization';
import Ticket from '../../../../generic/welcome-modal/ticket';
import pendingSetIcon from '../../../assets/pending-set-icon.svg';
import { TICKETS } from '../constants';

const ConnectionPendingSlide = () => (
  <div className="welcome-modal_first-slide">
    <img src={pendingSetIcon} className="b-mb2" alt="header-icons" />
    <h1 className="b-h1 b-mb1">Connection pending...</h1>
    <p className="b-txt-light">
      {i18n.$t('{element}', {
        element: (
          <>
            Perform the following steps below to finalize the integration with
            <br />
            Active Directory Certificate Services
          </>
        ),
      })}
    </p>
    {TICKETS.map((ticket) => (
      <Ticket
        key={ticket.key}
        icon={ticket.icon}
        text={
          typeof ticket.text === 'string'
            ? i18nAdcsTicketTextMap(ticket.text)
            : ticket.text
        }
        title={i18nAdcsTicketTitleMap(ticket.title)}
        link={ticket.link}
      />
    ))}
  </div>
);

export default ConnectionPendingSlide;
