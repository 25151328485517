import { styled } from '@kandji-inc/nectar-ui';
import React from 'react';
import { StyledContainer } from '../../../components/styled-components/main';
import type { CommonProps, PageTicketProps } from '../types/integrator.types';
import ViewHeader from './partials/view-header';
import ViewTickets from './partials/view-tickets';

const LayoutContainer = styled(StyledContainer, {});

export interface OneColumnInfoFullProps<InputType, StateType>
  extends CommonProps<InputType, StateType> {
  layout: 'one-column-info-full';
  header: {
    title: string;
    description: string;
    link?: {
      text: string;
      url: string;
    };
  };
  tickets: {
    title: string;
    description?: string;
    list: Array<PageTicketProps>;
  };
  isProcessing?: boolean;
}

const OneColumnInfoFull = <InputType, StateType>({
  logo,
  header,
  tickets,
}: OneColumnInfoFullProps<InputType, StateType>) => (
  <LayoutContainer>
    <ViewHeader data={{ logo, ...header }} />
    {tickets && <ViewTickets data={tickets} />}
  </LayoutContainer>
);
export default OneColumnInfoFull;
