import React from 'react';
import { Handle, Position } from 'reactflow';

import { Flex } from '@kandji-inc/nectar-ui';
import useBlueprintFlow from '../store';
import { getDevicePathStyle } from '../theme';

const handleStyle = { opacity: 1 };

function RouterNode(props: { xPos: number; yPos: number }) {
  const { xPos, yPos } = props;
  const [isEditingAssignments, { devicePath }] = useBlueprintFlow((state) => [
    state.isEditingAssignments,
    state.selectedDevice,
  ]);
  const isAwaitingPosition = xPos === 0 && yPos === 0;

  const isShowingDevicePath = !isEditingAssignments && devicePath;
  const isOnDevicePath = isShowingDevicePath && true;

  return (
    <Flex
      className="nodrag nopan"
      data-testid="router-node"
      css={{ visibility: isAwaitingPosition ? 'hidden' : 'visible' }}
    >
      <Flex
        alignItems="center"
        justifyContent="center"
        css={{
          width: '$1',
          height: '$1',
        }}
      >
        <Flex
          css={{
            width: '0',
            height: '0',
            position: 'relative',
          }}
        >
          <Handle
            type="target"
            position={Position.Left}
            isConnectable={false}
            style={{
              ...handleStyle,
              left: '-3px',

              ...(isShowingDevicePath &&
                getDevicePathStyle(isOnDevicePath, false).router),
            }}
          />

          <Handle
            type="source"
            position={Position.Right}
            isConnectable={false}
            style={{
              ...handleStyle,
              right: '-3px',

              ...(isShowingDevicePath &&
                getDevicePathStyle(isOnDevicePath, false).router),
            }}
          />
        </Flex>
      </Flex>
    </Flex>
  );
}

export default RouterNode;
