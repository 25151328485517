import {
  DropdownIndicator,
  Select,
  TextInput,
  setClass,
} from '@kandji-inc/bumblebee';
import { bool, func, object } from 'prop-types';
import React from 'react';
import { components } from 'react-select';
import {
  ALL_INTEGRATIONS_FILTER_OPTION,
  FILTERS_SHAPE,
  INT_CATEGORIES,
  i18nIntTypeCategoryMapping,
} from '../constants';
import './integrations-filters.styles.scss';

const getIntegrationOptions = () => [
  {
    label: i18nIntTypeCategoryMapping(ALL_INTEGRATIONS_FILTER_OPTION.label),
    value: ALL_INTEGRATIONS_FILTER_OPTION.value,
  },
  ...Object.values(INT_CATEGORIES)
    .sort()
    .map((cat) => ({
      label: i18nIntTypeCategoryMapping(cat),
      value: cat,
    })),
];

function getCount(data, counts) {
  const countKey =
    data.value === ALL_INTEGRATIONS_FILTER_OPTION.value
      ? ALL_INTEGRATIONS_FILTER_OPTION.label
      : data.value;
  return counts[countKey] || 0;
}

const Control = ({ children, ...props }) => {
  const { selectProps } = props;
  const { counts, value } = selectProps;
  const count = getCount(value, counts);
  return (
    <components.Control {...props}>
      {children}
      {!!count && (
        <div className="integration-filter__value-container-count">{count}</div>
      )}
    </components.Control>
  );
};

const Option = (props) => {
  const {
    data,
    isSelected,
    selectProps: { counts },
  } = props;
  const count = getCount(data, counts);
  return (
    <components.Option {...props}>
      <div className="integration-filter__option">
        <div>{data.label}</div>
        <div
          className={setClass(
            'integration-filter__option-count',
            isSelected && 'integration-filter__option-count--selected',
          )}
        >
          {count}
        </div>
      </div>
    </components.Option>
  );
};

function IntegrationsFilters({ setFilters, filters, counts, showSearch }) {
  return (
    <div className="top-nav-bar">
      <div className="int-filters_wrapper">
        <Select
          options={getIntegrationOptions().filter(
            (option) => getCount(option, counts) > 0,
          )}
          value={getIntegrationOptions().find(
            (opt) => opt.value === filters.category,
          )}
          onChange={(selectedOption) =>
            setFilters((curr) => ({
              ...curr,
              category: selectedOption.value,
            }))
          }
          components={{ DropdownIndicator, Control, Option }}
          counts={counts}
        />
        {showSearch && (
          <TextInput
            aria-label="Search Filter"
            placeholder="Search"
            onChange={(event) => {
              event.persist();
              setFilters((curr) => ({
                ...curr,
                search: event.target.value,
              }));
            }}
            icon="magnifying-glass"
            value={filters.search}
          />
        )}
      </div>
    </div>
  );
}

IntegrationsFilters.propTypes = {
  filters: FILTERS_SHAPE.isRequired,
  setFilters: func.isRequired,
  counts: object.isRequired,
  showSearch: bool.isRequired,
};

export default IntegrationsFilters;
