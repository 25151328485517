/* istanbul ignore file */
const Slack = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="60"
    viewBox="0 0 25.8008 25.459"
    style={{ minWidth: '60px' }}
  >
    <g>
      <rect height="25.459" opacity="0" width="25.8008" x="0" y="0" />
      <path
        d="M12.7148 25.4395C19.6777 25.4395 25.4395 19.6777 25.4395 12.7246C25.4395 5.76172 19.668 0 12.7051 0C5.75195 0 0 5.76172 0 12.7246C0 19.6777 5.76172 25.4395 12.7148 25.4395ZM12.7148 23.623C6.67969 23.623 1.82617 18.7598 1.82617 12.7246C1.82617 6.67969 6.66992 1.82617 12.7051 1.82617C18.75 1.82617 23.6133 6.67969 23.6133 12.7246C23.6133 18.7598 18.7598 23.623 12.7148 23.623Z"
        fill="#98989d"
      />
      <path
        d="M9.84375 11.416L9.84375 14.0332L8.75 14.0332C7.32422 14.0332 6.16211 15.1855 6.16211 16.6016C6.16211 18.0371 7.32422 19.1992 8.75 19.1992C10.1758 19.1992 11.3379 18.0273 11.3379 16.6016L11.3379 15.5078L13.9258 15.5078L13.9258 16.6016C13.9258 18.0273 15.0879 19.1992 16.5137 19.1992C17.9395 19.1992 19.1016 18.0371 19.1016 16.6016C19.1016 15.1855 17.9395 14.0332 16.5137 14.0332L15.4199 14.0332L15.4199 11.416L16.5137 11.416C17.9395 11.416 19.1016 10.2637 19.1016 8.84766C19.1016 7.41211 17.9395 6.25977 16.5137 6.25977C15.0879 6.25977 13.9258 7.42188 13.9258 8.84766L13.9258 9.95117L11.3379 9.95117L11.3379 8.84766C11.3379 7.42188 10.1758 6.25977 8.75 6.25977C7.32422 6.25977 6.16211 7.41211 6.16211 8.84766C6.16211 10.2637 7.32422 11.416 8.75 11.416ZM11.3379 14.043L11.3379 11.4062L13.9258 11.4062L13.9258 14.043ZM8.75 9.96094C8.13477 9.96094 7.64648 9.46289 7.64648 8.84766C7.64648 8.24219 8.14453 7.73438 8.75 7.73438C9.35547 7.73438 9.84375 8.25195 9.84375 8.84766L9.84375 9.96094ZM16.5137 9.96094L15.4199 9.96094L15.4199 8.84766C15.4199 8.25195 15.918 7.73438 16.5137 7.73438C17.1191 7.73438 17.627 8.24219 17.627 8.84766C17.627 9.46289 17.1289 9.96094 16.5137 9.96094ZM8.75 15.4883L9.84375 15.4883L9.84375 16.5918C9.84375 17.207 9.35547 17.7051 8.75 17.7051C8.13477 17.7051 7.64648 17.207 7.64648 16.5918C7.64648 15.9961 8.14453 15.4883 8.75 15.4883ZM16.5137 15.4883C17.1191 15.4883 17.627 15.9961 17.627 16.5918C17.627 17.207 17.1289 17.7051 16.5137 17.7051C15.918 17.7051 15.4199 17.207 15.4199 16.5918L15.4199 15.4883Z"
        fill="#98989d"
      />
    </g>
  </svg>
);

export default Slack;
