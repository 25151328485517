import { i18n } from 'i18n';
import { INT_TYPES } from '../constants';
import adcs from './assets/adcs.svg';
import azure from './assets/azure.svg';
import googleWorkspace from './assets/google-workspace.svg';
import msDeviceCompliance from './assets/microsoft-device-compliance.svg';
import microsoftTeams from './assets/microsoft-teams.svg';
import okta from './assets/okta.svg';
import scim from './assets/scim.svg';
import servicenow from './assets/servicenow.svg';
import slack from './assets/slack.svg';

const cardConfigurations = {
  [INT_TYPES.slack]: {
    text: () =>
      i18n.t('Add a Slack workspace and configure event-driven notifications.'),
    title: () => i18n.t('Slack'),
    img: slack,
  },
  [INT_TYPES.msTeams]: {
    text: () =>
      i18n.t(
        'Add a Microsoft Teams account and configure event-driven notifications.',
      ),
    title: () => i18n.t('Microsoft Teams'),
    img: microsoftTeams,
  },
  [INT_TYPES.office365]: {
    text: () =>
      i18n.t(
        'Import a user directory from Microsoft Entra ID and assign devices to your users.',
      ),
    title: () => i18n.t('Microsoft Entra ID'),
    img: azure,
  },
  [INT_TYPES.gsuite]: {
    text: () =>
      i18n.t(
        'Import a user directory from Google Workspace and assign devices to your users.',
      ),
    title: () => i18n.t('Google Workspace'),
    img: googleWorkspace,
  },
  [INT_TYPES.scim]: {
    text: () =>
      i18n.t(
        'Import a user directory through the SCIM protocol and assign devices to your users.',
      ),
    title: () => i18n.t('SCIM protocol'),
    img: scim,
  },
  [INT_TYPES.adcs]: {
    text: () =>
      i18n.t(
        'Allows you to issue certificates from an Active Directory Certificate Authority.',
      ),
    title: () => i18n.t('Active Directory Certificate Services'),
    img: adcs,
  },
  [INT_TYPES.okta]: {
    text: () =>
      i18n.t(
        'Protect corporate resources by ensuring only compliant devices are allowed to access applications through Okta.',
      ),
    title: () => i18n.t('Okta Device Trust'),
    img: okta,
  },
  [INT_TYPES.servicenow]: {
    text: () =>
      i18n.t(
        'Add an integration with ServiceNow for hardware asset management with Kandji.',
      ),
    title: () => i18n.t('ServiceNow'),
    img: servicenow,
  },
  [INT_TYPES.msDeviceCompliance]: {
    text: () =>
      i18n.t(
        'Protect corporate resources by ensuring only compliant devices are allowed to access applications through Microsoft.',
      ),
    title: () => i18n.t('Microsoft Device Compliance'),
    img: msDeviceCompliance,
  },
};

export default cardConfigurations;
