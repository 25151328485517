/* istanbul ignore file */
const Training = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="60"
    viewBox="0 0 34.1016 34.7565"
    style={{ minWidth: '60px' }}
  >
    <g>
      <rect height="34.7565" opacity="0" width="34.1016" x="0" y="0" />
      <path
        d="M22.2735 20.4802C20.9329 20.9953 20.3809 22.0871 20.3809 23.3597L20.3809 27.9007C20.3809 28.1588 20.3927 28.4038 20.4214 28.6332L8.35938 28.6332C7.08008 28.6332 6.29883 28.0179 6.29883 27.0218C6.29883 23.7796 10.3516 19.307 16.8652 19.307C18.9341 19.307 20.7548 19.7582 22.2735 20.4802ZM21.8848 11.5042C21.8848 14.6292 19.5996 17.0511 16.875 17.0511C14.1602 17.0511 11.875 14.6292 11.875 11.5238C11.875 8.48667 14.1797 6.11362 16.875 6.11362C19.5801 6.11362 21.8848 8.44761 21.8848 11.5042Z"
        fill="#98989d"
      />
      <path
        d="M27.832 20.7621C28.6328 21.0648 30.7129 21.807 31.5527 22.1488C32.2852 22.4417 32.5488 22.8812 32.5488 23.6039L32.5488 28.1546C32.5488 30.4691 31.2207 31.3089 27.793 33.3109C27.4219 33.516 26.9629 33.4769 26.6797 33.3109C23.2617 31.3187 21.9336 30.4691 21.9336 28.1546L21.9336 23.6039C21.9336 22.8812 22.1973 22.4417 22.9297 22.1488C23.7598 21.807 25.8398 21.0843 26.5723 20.8011C27.002 20.6351 27.4512 20.6449 27.832 20.7621ZM29.5215 24.1605L26.4453 28.4183L24.8828 26.7289C24.7559 26.5921 24.5801 26.475 24.3262 26.475C23.9258 26.475 23.6035 26.7777 23.6035 27.1976C23.6035 27.3636 23.6621 27.5687 23.7988 27.725L25.8984 30.0296C26.0547 30.1957 26.2891 30.2835 26.4844 30.2835C26.7383 30.2835 26.9629 30.1664 27.0898 30.0003L30.6934 25.0003C30.8008 24.8539 30.8594 24.6976 30.8594 24.5609C30.8594 24.1507 30.5176 23.848 30.127 23.848C29.873 23.848 29.6582 23.975 29.5215 24.1605Z"
        fill="#98989d"
      />
    </g>
  </svg>
);

export default Training;
