import { Tr } from '@kandji-inc/nectar-ui';
import React from 'react';
import { PrismTableCell } from 'src/features/visibility/prism/components/PrismTable/components/PrismTableBody';
import { useADEListViewStore } from '../store/ADEListViewStore';

export const ADEListViewTableRow = function ADEListViewTableRow({ row }) {
  const { selectedDevices, selectAllDevices } = useADEListViewStore(
    /* istanbul ignore next */
    (state) => ({
      selectedDevices: state.selectedDevices,
      selectAllDevices: state.selectAllDevices,
    }),
  );

  return (
    <Tr
      key={row.id}
      selected={selectedDevices.includes(row.original.id) || selectAllDevices}
      css={{
        'td:first-of-type': {
          paddingLeft: '$5',
        },
      }}
    >
      {row.getVisibleCells().map(
        /*istanbul ignore next */
        (cell) => (
          <PrismTableCell cell={cell} />
        ),
      )}
    </Tr>
  );
};
