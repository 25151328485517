import { useContext, useState } from 'react';

import { AccountContext } from 'contexts/account';

import { transformForUI } from './transformer';

const initialPage = {
  isEditing: false,
  isSaving: false,
  isValid: true,
};

const useInitialForm = () => {
  const { currentCompany: data } = useContext(AccountContext);

  const [initialForm, setInitialForm] = useState(transformForUI(data));

  return { initialForm, setInitialForm };
};

export { initialPage, useInitialForm };
