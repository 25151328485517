import { setClass } from '@kandji-inc/bumblebee';
import { number, string } from 'prop-types';
import React from 'react';
import './stepper.styles.scss';

function Stepper({ currentStepIdx, stepsAmount, className }) {
  return (
    <div className={setClass(['stepper_wrapper', className])}>
      {[...Array(stepsAmount)].map((_, idx) => (
        <div
          key={idx}
          className={setClass([
            'stepper_circle',
            currentStepIdx === idx && 'stepper_circle--active',
          ])}
        />
      ))}
    </div>
  );
}

Stepper.propTypes = {
  currentStepIdx: number.isRequired,
  stepsAmount: number.isRequired,
  className: string,
};

Stepper.defaultProps = {
  className: '',
};

export default Stepper;
