/* istanbul ignore file */
import type { Node } from 'reactflow';
import { useReactFlow } from 'reactflow';

import { DESCENDANT_TYPES, EDGE_TYPES, NODE_TYPES } from '../constants';

import useBlueprintFlow from '../store';
import { createBranchEdge, createRootEdge } from './data';
import useAncestry from './use-ancestry';
import useLayout from './use-layout';

/**
 * Hook for updating the data of Conditional nodes
 * @param id - the ID of the Conditional node
 * @param router - the ID of the paired Router node
 * @param assignments - the list of Assignments
 * @returns utilities for updating the data of a Conditional node
 */
function useConditional({ id, path }: { id: Node['id']; path: string }) {
  const setModel = useBlueprintFlow((state) => state.setBlueprintModel);
  const { setNodes, getEdges, setEdges } = useReactFlow();
  const { runLayout } = useLayout();
  const { getUniqueDescendantsUpToRouter } = useAncestry();

  const deleteConditional = () => {
    const edges = getEdges();

    // Get the parent edge and node of the deleted Conditional node
    const parentEdge = edges.find((edge) => edge.target === id);
    const parentNodeId = parentEdge.source;

    // Get all node and edge descendants
    const descendants = getUniqueDescendantsUpToRouter(id);

    // Filter for the non-Router node descendant ids
    const nodeDescendants = descendants
      .filter(
        (descendant) =>
          ![DESCENDANT_TYPES.edge, DESCENDANT_TYPES.router].includes(
            descendant.descendantType,
          ),
      )
      .map(({ id }) => id);

    // Filter for the edge descendant ids
    const edgeDescendants = descendants
      .filter(
        (descendant) => descendant.descendantType === DESCENDANT_TYPES.edge,
      )
      .map(({ id }) => id);

    // Get the id of the Router node descendant
    const routerDescendantId = descendants.find(
      (descendant) => descendant.descendantType === NODE_TYPES.router,
    ).id;

    // If the path type of the Conditional node is `branch`,
    // the router node it connects to should not be removed
    if (path === EDGE_TYPES.branch) {
      const nodesToDelete = [id, ...nodeDescendants];
      const edgesToDelete = [parentEdge.id, ...edgeDescendants];

      // Create a new edge to connect the deleted Conditional node's
      // parent and its router descendant
      const newBranchEdge = createBranchEdge({
        source: parentNodeId,
        target: routerDescendantId,
      });

      // Remove all node descendants, except the Router
      setNodes((prevNodes) => {
        const updatedNodes = prevNodes.filter(
          (node) => !nodesToDelete.includes(node.id),
        );

        // Update the model with the new node data:
        setModel((prev) => ({ ...prev, nodes: updatedNodes }));

        return updatedNodes;
      });

      // Remove all edge descendants and add the new branch edge
      setEdges((prevEdges) =>
        prevEdges
          .filter((edge) => !edgesToDelete.includes(edge.id))
          .concat(newBranchEdge),
      );
    }

    // If the path type of the Conditional node is `root`,
    // the router node it connects to should be removed
    if (path === EDGE_TYPES.root) {
      const routerDescendantEdge = edges.find(
        (edge) => edge.source === routerDescendantId,
      );
      const routerDescendantTarget = routerDescendantEdge.target;

      const nodesToDelete = [id, ...nodeDescendants, routerDescendantId];
      const edgesToDelete = [
        parentEdge.id,
        ...edgeDescendants,
        routerDescendantEdge.id,
      ];

      // Create a new edge to connect the delete Conditional node's
      // parent and the target of the Router node being deleted
      const newRootEdge = createRootEdge({
        source: parentNodeId,
        target: routerDescendantTarget,
      });

      // Remove all node descendants, including the Router
      setNodes((prevNodes) =>
        prevNodes.filter((node) => !nodesToDelete.includes(node.id)),
      );

      // Remove all edge descendants and add the new branch edge
      setEdges((prevEdges) =>
        prevEdges
          .filter((edge) => !edgesToDelete.includes(edge.id))
          .concat(newRootEdge),
      );
    }

    runLayout();
  };

  return {
    deleteConditional,
  };
}

export default useConditional;
