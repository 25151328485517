import { Icon } from '@kandji-inc/bumblebee';
import React, {
  createRef,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { elementScrollIntoView } from 'seamless-scroll-polyfill';
import './use-sync-validations.css';

const USE_SYNC_VALIDATIONS_NS_PREFIX = '_use-sync-validations';

const useSyncValidations = ({
  namespace: validationNamespace = 'use-sync-validations-common',
  fieldsToValidate = [],
  triggerOn,
  displayOn,
}) => {
  const [liveValidationElements, setLiveValidationElements] = useState([]);
  const [triggerToken, setTriggerToken] = useState(
    Symbol('useSyncValidations.init'),
  );
  const [renderToken, setRenderToken] = useState(
    Symbol('useSyncValidations.render'),
  );
  const validationRootRef = useRef();
  const isDisplayError = displayOn();

  const refreshDeps = useCallback(
    () => setRenderToken(Symbol('useSyncValidations.rerender')),
    [],
  );
  const findValidationElement = (
    dataNameValue,
    transformer = (el) => el,
    defaultReturn = undefined,
  ) => {
    const element = Array.prototype.find.call(
      liveValidationElements,
      (el) =>
        el.getAttribute(
          `data-sync-validation-${validationNamespace.toLowerCase()}-ns-${dataNameValue.toLowerCase()}`,
        ) === dataNameValue,
    );

    if (element) {
      return transformer(element);
    }

    return defaultReturn;
  };

  const getValidationDataAttrs = (
    isInvalid,
    fieldName,
    key,
    transformer = (attrs) => attrs,
  ) => {
    const [IS_INVALID_TRUE, IS_INVALID_FALSE] = ['1', '0'];
    const dataNameValue = key ? `${fieldName}-${key}` : fieldName;
    const dataInvalidValue = isInvalid ? IS_INVALID_TRUE : IS_INVALID_FALSE;
    const dataNameKey = `data-sync-validation-${validationNamespace.toLowerCase()}-ns-${dataNameValue.toLowerCase()}`;
    const dataInvalidKey = `data-sync-validation-${validationNamespace.toLowerCase()}-ns-invalid`;

    return transformer(
      {
        [dataNameKey]: dataNameValue,
        [dataInvalidKey]: dataInvalidValue,
      },
      [
        [dataNameKey, dataNameValue],
        [dataInvalidKey, dataInvalidValue],
      ],
    );
  };

  const registerValidationElementRaf = (
    dataNameValue,
    className,
    { isInvalid, dataAttrsTuples, imperativeHandleElement } = {},
  ) => {
    const MAX_RAF_DURATION_MS = 500;
    let start;
    let cancelId;

    const registerElementByClassName = (timestamp) => {
      start = start ?? timestamp;
      const elapsed = timestamp - start;
      const queryRoot = validationRootRef.current ?? document;
      const element = queryRoot.querySelector(
        `[data-sync-validation-${validationNamespace.toLowerCase()}-ns-${dataNameValue.toLowerCase()}="${dataNameValue}"]`,
      );
      const isOverMaxDuration = elapsed >= MAX_RAF_DURATION_MS;

      if (isOverMaxDuration) {
        cancelAnimationFrame(cancelId);
      } else if (!element && !isOverMaxDuration) {
        cancelId = requestAnimationFrame(registerElementByClassName);
      } else {
        element.classList.add(USE_SYNC_VALIDATIONS_NS_PREFIX, className);
        imperativeHandleElement?.(element, {
          isInvalid,
          isDisplayError,
          dataAttrsTuples,
          isNewRegistered: true,
          validationRootRef: queryRoot,
          validationNamespace,
        });
      }
    };

    const [isElementRegistered, element] = findValidationElement(
      dataNameValue,
      (el) => [
        el.classList.contains(USE_SYNC_VALIDATIONS_NS_PREFIX, className),
        el,
      ],
      [false, undefined],
    );

    if (isElementRegistered) {
      imperativeHandleElement?.(element, {
        isInvalid,
        isDisplayError,
        dataAttrsTuples,
        isNewRegistered: false,
        validationRootRef: validationRootRef.current ?? document,
        validationNamespace,
      });

      return;
    }

    cancelId = requestAnimationFrame(registerElementByClassName);
  };

  const validationActions = (fieldName) => {
    const createStableRefHandlers = () => {
      const onChangeRef = createRef();
      const onUnmountRef = createRef();
      const stableRefCb = (refNode) => {
        if (refNode === null) {
          onUnmountRef
            .current(refNode)
            .then((result) => {
              onUnmountRef.current = result ?? null;
            })
            .catch(() => {
              onUnmountRef.current = null;
            });
        } else {
          onChangeRef
            .current(refNode)
            .then((result) => {
              onChangeRef.current = result ?? null;
            })
            .catch(() => {
              onChangeRef.current = null;
            });
        }
      };

      return ({ onChange = () => {}, onUnmount = () => {} } = {}) => {
        onChangeRef.current = (refNode) =>
          new Promise((resolve) => resolve(onChange(refNode)));
        onUnmountRef.current = (refNode) =>
          new Promise((resolve) => resolve(onUnmount(refNode)));
        return stableRefCb;
      };
    };

    const createOnRefChange = createStableRefHandlers();

    return {
      syncInvalid: (
        isInvalid,
        { key, style, imperativeHandleElement } = {},
      ) => {
        const [dataAttrs, dataNameTuple, dataInvalidTuple] =
          getValidationDataAttrs(
            isInvalid,
            fieldName,
            key,
            (attrs, [nameTuple, invalidTuple]) => [
              attrs,
              nameTuple,
              invalidTuple,
            ],
          );

        const [, dataNameValue] = dataNameTuple;

        registerValidationElementRaf(
          dataNameValue,
          `${USE_SYNC_VALIDATIONS_NS_PREFIX}-${validationNamespace}`,
          {
            isInvalid,
            dataAttrsTuples: [dataNameTuple, dataInvalidTuple],
            imperativeHandleElement,
          },
        );

        if (typeof style === 'function') {
          return Object.assign(
            dataAttrs,
            style({
              isInvalid,
              isDisplayError,
              dataAttrsTuples: [dataNameTuple, dataInvalidTuple],
            }),
          );
        }

        return style ? Object.assign(dataAttrs, style) : dataAttrs;
      },

      displayInvalid: (
        isInvalid,
        {
          key,
          message = 'Required',
          className = '',
          styles = { container: {}, message: {}, icon: {} },
          imperativeHandleElement,
          imperativeOnElementUnmount,
        } = {},
      ) => {
        if (!isInvalid || !isDisplayError) {
          return null;
        }

        const dataAttrsTuples = getValidationDataAttrs(
          isInvalid,
          fieldName,
          key,
          (_attrs, dataTuples) => dataTuples,
        );

        const [
          [dataNameKey, dataNameValue],
          [dataInvalidKey, dataInvalidValue],
        ] = dataAttrsTuples;

        const onRef = () =>
          createOnRefChange({
            onChange: (refNode) =>
              imperativeHandleElement(refNode, {
                isInvalid,
                isDisplayError,
                dataAttrsTuples,
                validationRootRef: validationRootRef.current ?? document,
                validationNamespace,
              }),
            onUnmount: (refNode) =>
              imperativeOnElementUnmount(refNode, {
                isInvalid,
                isDisplayError,
                dataAttrsTuples,
                validationRootRef: validationRootRef.current ?? document,
                validationNamespace,
              }),
          });

        return (
          <div
            ref={onRef()}
            className={`${USE_SYNC_VALIDATIONS_NS_PREFIX} ${USE_SYNC_VALIDATIONS_NS_PREFIX}-${validationNamespace} ${USE_SYNC_VALIDATIONS_NS_PREFIX}__err-msg ${className}`}
            style={styles.container}
            {...{
              [dataNameKey]: dataNameValue,
              [dataInvalidKey]: dataInvalidValue,
            }}
          >
            <Icon
              name="octagon-exclamation"
              className={`${USE_SYNC_VALIDATIONS_NS_PREFIX}__err-msg__icon b-txt-input__err-icon`}
              style={styles.icon}
            />
            <p
              className={`b-txt b-txt--error ${USE_SYNC_VALIDATIONS_NS_PREFIX}__err-msg__txt`}
              style={styles.message}
            >
              {message}
            </p>
          </div>
        );
      },
    };
  };

  const validations = useMemo(
    () =>
      fieldsToValidate.reduce((fieldsValidations, fieldName) => {
        const acc = fieldsValidations;
        acc[fieldName] = validationActions(fieldName);
        return acc;
      }, {}),
    [fieldsToValidate, triggerToken, renderToken],
  );

  const createValidationTrigger = useCallback(
    (updateCb) => (updateValue) => {
      updateCb(updateValue);

      const isTriggerAllowed = triggerOn(updateValue);
      if (isTriggerAllowed) {
        return setTriggerToken(Symbol('useSyncValidations.trigger'));
      }
      return refreshDeps();
    },
    [refreshDeps, triggerOn],
  );

  useEffect(() => {
    function initLiveValidationElements() {
      const elements = document.getElementsByClassName(
        `${USE_SYNC_VALIDATIONS_NS_PREFIX} ${USE_SYNC_VALIDATIONS_NS_PREFIX}-${validationNamespace}`,
      );

      setLiveValidationElements(elements);
    }

    initLiveValidationElements();
  }, []);

  useEffect(() => {
    function triggerValidations() {
      if (!liveValidationElements.length) {
        return;
      }

      const effects = {
        INPUT: (element) => {
          element.select();
          element.blur();
        },
        TEXTAREA: (element) => {
          element.select();
          element.blur();
        },
      };
      const noop = () => {};
      const triggerInvalidEffectByTag = (element) => {
        const triggerInstructions = effects[element.tagName] ?? noop;
        triggerInstructions(element);
      };

      const filterInvalidElements = (element) => {
        const dataAttrInvalid = element.getAttribute(
          `data-sync-validation-${validationNamespace.toLowerCase()}-ns-invalid`,
        );
        const isInvalid = Boolean(Number(dataAttrInvalid));
        return isInvalid;
      };

      const invalidElements = Array.prototype.filter.call(
        liveValidationElements,
        filterInvalidElements,
      );

      const triggerValidationsRaf = (elements = []) => {
        if (!elements.length) {
          return;
        }

        const [firstInvalidElement] = elements;

        const scrollToElement = () => {
          elementScrollIntoView(firstInvalidElement, {
            block: 'center',
            behavior: 'smooth',
          });
        };

        const displayErrors = () => {
          elements.forEach((e) => {
            triggerInvalidEffectByTag(e);
            return true;
          });

          requestAnimationFrame(scrollToElement);
        };

        requestAnimationFrame(displayErrors);
      };

      triggerValidationsRaf(invalidElements);
    }

    triggerValidations();
  }, [triggerToken]);

  return { validations, validationRootRef, createValidationTrigger };
};

export default useSyncValidations;
