/* istanbul ignore file - the majority of this code was provided by React Flow Pro with some modifications */
import type { Edge, Node } from 'reactflow';
import { useReactFlow } from 'reactflow';

import { v4 as uuid } from 'uuid';

import { DESCENDANT_TYPES, EDGE_TYPES } from '../constants';
import { getAssignmentTypeAndPeers } from '../helpers';

import {
  createAssignmentNode,
  createBranchEdge,
  createConditionalNode,
} from './data';
import useAncestry from './use-ancestry';

function useBranchEdgeClick(id: Edge['id']) {
  const { getNode, getNodes, setNodes, getEdge, setEdges } = useReactFlow();
  const { getUniqueDescendantsUpToRouter } = useAncestry();

  const handleBranchEdgeClick = () => {
    // Retrieve the edge
    const clickedEdge: Edge = getEdge(id);

    if (!clickedEdge) {
      return;
    }

    // Retrieve the target node and source
    const clickedEdgeSourceNode: Node = getNode(clickedEdge.source);
    const clickedEdgeTargetNode: Node = getNode(clickedEdge.target);
    const clickedEdgeSourceRouterNodeId: Node['id'] =
      getUniqueDescendantsUpToRouter(clickedEdgeSourceNode.id).find(
        (descendant) => descendant.descendantType === DESCENDANT_TYPES.router,
      )?.id;

    const { peers: clickedEdgeSourceNodePeers } = getAssignmentTypeAndPeers(
      getNodes(),
      clickedEdgeSourceNode,
    );

    const insertConditionalNodeId: Node['id'] = uuid();

    // This is the new Conditional node object that will be added to the graph
    const insertConditionalNode: Node = createConditionalNode({
      id: insertConditionalNodeId,
      path: EDGE_TYPES.branch,
    });

    // Create the standard Assignment nodes for the new Conditional node
    const insertAssignmentIfNode: Node = createAssignmentNode({
      parentNodeId: insertConditionalNodeId,
    });

    const insertAssignmentElseNode: Node = createAssignmentNode({
      parentNodeId: insertConditionalNodeId,
    });

    const edgesToAdd: Edge[] = [];
    const edgesToDelete: Edge['id'][] = [];

    // Create a new Branch edge to connect the clicked edge's source to the
    // newly inserted Conditional node
    const newSourceEdge: Edge = createBranchEdge({
      source: clickedEdge.source,
      target: insertConditionalNodeId,
    });

    // Create a branch edge from the new 'if' Assignment node to the clicked edge's target
    const ifBranchEdge = createBranchEdge({
      source: insertAssignmentIfNode.id,
      target: clickedEdgeTargetNode.id,
    });

    // Create a branch edge from the new 'else' Assignment node to the clicked edge source's Router node
    const elseBranchEdge = createBranchEdge({
      source: insertAssignmentElseNode.id,
      target: clickedEdgeSourceRouterNodeId,
    });

    // Create two new Branch edges to connect the new Conditional node's
    // Assignment node to the clicked edge's original target
    const newBranchEdges: Edge[] = [ifBranchEdge, elseBranchEdge];

    edgesToAdd.push(newSourceEdge, ...newBranchEdges);

    edgesToDelete.push(id);

    setNodes((prevNodes) => {
      const lastPeerIndex: number = prevNodes.findIndex(
        (node) => node.id === clickedEdgeSourceNodePeers.at(-1).id,
      );

      // Insert the new Conditional node and standard Assignment nodes after the
      // Conditional node the action originated from
      const updatedNodes: Node[] = [
        ...prevNodes.slice(0, lastPeerIndex + 1),
        insertConditionalNode,
        insertAssignmentIfNode,
        insertAssignmentElseNode,
        ...prevNodes.slice(lastPeerIndex + 1, prevNodes.length),
      ];

      return updatedNodes;
    });

    setEdges((prevEdges) =>
      prevEdges
        ?.filter((prevEdge) => !edgesToDelete.includes(prevEdge.id))
        ?.concat(edgesToAdd),
    );
  };

  return { handleBranchEdgeClick };
}

export default useBranchEdgeClick;
