import { Toaster } from '@kandji-inc/bumblebee';
import {
  Box,
  Button,
  Flex,
  TextField,
  useToast_UNSTABLE as useToast,
} from '@kandji-inc/nectar-ui';
import React, { useContext } from 'react';

import { InterfaceContext } from 'src/contexts/interface';
import useComputersExport from '../../services/use-computers-export';
import { FlowTippy, TippyContainer } from '../../theme';

export type Filter = {
  term: string;
};
export const defaultFilter: Filter = {
  term: '',
};

export type FilterBarProps = {
  filter: Filter;
  onChange: <K extends keyof Filter>(field: K, value: Filter[K]) => void;
  blueprintId: string;
};

const FilterBar = (props: FilterBarProps) => {
  const { filter, onChange, blueprintId } = props;

  const SIDEBAR_DOCKED_OFFSET = 256;
  const SIDEBAR_CLOSE_OFFSET = 78;
  const { sidebarDocked } = useContext(InterfaceContext);
  const { exportComputers, isPendingExport } = useComputersExport();
  const { toast } = useToast();

  /* istanbul ignore next */
  const handleExportComputers = () => {
    exportComputers(blueprintId).then(() => {
      toast({
        title: 'File was exported',
        variant: 'success',
        style: {
          left: /* istanbul ignore next */ sidebarDocked
            ? `${SIDEBAR_DOCKED_OFFSET + 12}px`
            : `${SIDEBAR_CLOSE_OFFSET + 12}px`,
          bottom: '12px',
          position: 'absolute',
        },
      });
    });
  };

  return (
    <Box pl5 pr5>
      <Flex pt3 pb3 justifyContent="space-between" alignItems="center">
        <TextField
          compact
          placeholder="Search devices"
          icon="magnifying-glass"
          value={filter.term}
          showClearButton={Boolean(filter.term)}
          onClear={() => onChange('term', '')}
          onChange={(e) => onChange('term', e.target.value)}
          css={{ width: '240px' }}
          data-testid="am-device-search"
        />
        <FlowTippy
          content={<TippyContainer>Export</TippyContainer>}
          popperOptions={{ strategy: 'fixed' }}
        >
          <Button
            icon={{ name: 'file-arrow-down' }}
            variant="subtle"
            onClick={handleExportComputers}
            loading={isPendingExport}
            data-testid="export-devices-button"
          />
        </FlowTippy>
      </Flex>
    </Box>
  );
};

export default FilterBar;
