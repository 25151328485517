/* istanbul ignore file - the majority of this code was provided by React Flow Pro with some modifications */

import type { Edge, Node } from 'reactflow';
import { useReactFlow } from 'reactflow';

import { v4 as uuid } from 'uuid';

import { EDGE_TYPES } from '../constants';
import {
  createAssignmentNode,
  createBranchEdge,
  createConditionalNode,
  createRootEdge,
  createRouterNode,
} from './data';

// this hook implements the logic for clicking the button on a Root edge
function useRootEdgeClick(id: Edge['id']) {
  const { getNode, setNodes, getEdge, setEdges } = useReactFlow();

  // on Root edge click: create a Conditional node and Router node in between
  // the two nodes that are connected by the edge
  const handleRootEdgeClick = () => {
    // Retrieve the edge object to get the source and target ID
    const edge: Edge = getEdge(id);

    if (!edge) {
      return;
    }

    // Retrieve the target node
    const targetNode: Node = getNode(edge.target);

    if (!targetNode) {
      return;
    }

    // Create a unique ID for newly added nodes
    const insertConditionalNodeId: Node['id'] = uuid();
    const insertRouterNodeId: Node['id'] = uuid();

    // Create the Conditional node object that will be added in between
    // source and the new Router node
    const insertConditionalNode: Node = createConditionalNode({
      id: insertConditionalNodeId,
      path: EDGE_TYPES.root,
    });

    // Create the standard Assignment nodes for the new Conditional node
    const insertAssignmentIfNode: Node = createAssignmentNode({
      parentNodeId: insertConditionalNodeId,
    });

    const insertAssignmentElseNode: Node = createAssignmentNode({
      parentNodeId: insertConditionalNodeId,
    });

    // Create the Router node object that will be added in between the new
    // Conditional node and target node
    const insertRouterNode: Node = createRouterNode({
      id: insertRouterNodeId,
      targetNode,
    });

    // Create a new connection from source to new Conditional node
    const sourceEdge: Edge = createRootEdge({
      source: edge.source,
      target: insertConditionalNodeId,
    });

    // Create connections from the new if/else Assignment nodes to the new Router node
    const ifBranchEdge = createBranchEdge({
      source: insertAssignmentIfNode.id,
      target: insertRouterNodeId,
    });

    const elseBranchEdge = createBranchEdge({
      source: insertAssignmentElseNode.id,
      target: insertRouterNodeId,
    });

    // Create a new connection from new Router node to target
    const targetEdge: Edge = createRootEdge({
      source: insertRouterNodeId,
      target: edge.target,
    });

    // Insert the new Conditional node, standard Assignment nodes, and Router node
    // between the node the action was taken on and its previous source node
    setNodes((prevNodes) => {
      const targetNodeIndex: number = prevNodes.findIndex(
        (node) => node.id === edge.target,
      );

      return [
        ...prevNodes.slice(0, targetNodeIndex),
        insertConditionalNode,
        insertAssignmentIfNode,
        insertAssignmentElseNode,
        insertRouterNode,
        ...prevNodes.slice(targetNodeIndex, prevNodes.length),
      ];
    });

    // Remove the edge that was clicked as we have connections with the new nodes to replace it
    setEdges((prevEdges) =>
      prevEdges
        .filter((prevEdge) => prevEdge.id !== id)
        .concat([sourceEdge, ifBranchEdge, elseBranchEdge, targetEdge]),
    );
  };

  return { handleRootEdgeClick };
}

export default useRootEdgeClick;
