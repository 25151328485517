import {
  Button,
  modal as Modal,
  Toaster as toaster,
} from '@kandji-inc/bumblebee';
import { bool, func, number, string } from 'prop-types';
import React from 'react';

import { useQueryClient } from '@tanstack/react-query';
import SemiBold from 'features/util/components/semi-bold';
import { i18n } from 'i18n';
import useDeleteNotification from '../../../../slack/hooks/use-delete-notification';

function DeleteNotificationModal({ isOpen, onClose, id, name }) {
  const queryClient = useQueryClient();

  const deleteNotificationMutation = useDeleteNotification();

  function onDeleteClick() {
    deleteNotificationMutation
      .mutateAsync(id)
      .then(() => {
        toaster(i18n.t('{name} has been deleted.', { name }));
        queryClient.invalidateQueries({ queryKey: ['slackNotifications'] });
        onClose();
      })
      .catch((e) => {
        console.log(e);
        toaster(i18n.common.error());
      });
  }

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      withoutCloseButton
      parts={{
        header: { text: i18n.t('Delete event notification') },
        content: {
          children: (
            <>
              {i18n.$t(
                'Are you sure you would like to delete {name}? This action cannot be undone.',
                { name: <SemiBold>{name}</SemiBold> },
              )}
            </>
          ),
        },
        footer: {
          children: (
            <>
              <Button kind="outline" onClick={onClose}>
                {i18n.t('Cancel')}
              </Button>
              <Button
                className="b-ml"
                theme="error"
                onClick={onDeleteClick}
                disabled={deleteNotificationMutation.isLoading}
                isProgress={deleteNotificationMutation.isLoading}
              >
                {i18n.t('Delete')}
              </Button>
            </>
          ),
        },
      }}
    />
  );
}

DeleteNotificationModal.propTypes = {
  isOpen: bool.isRequired,
  onClose: func.isRequired,
  name: string,
  id: number,
};

DeleteNotificationModal.defaultProps = {
  name: null,
  id: null,
};

export default DeleteNotificationModal;
