import type { FIXME_Any } from '@kandji-inc/nectar-ui';
import { Button } from '@kandji-inc/nectar-ui';
import type { Action } from 'history';
import { i18n } from 'i18n';
import type React from 'react';
import { useCallback, useEffect, useState } from 'react';
import { callChannelRefresh } from '../utils/call-channel-refresh';

type IntegrationsResponseData = {
  display_name: string;
  icon: string;
  id: number;
  name: string;
  type: string;
  email: string;
};

type NewIntegrationData =
  | {
      id: string;
    }
  | undefined;
export type GetChannelsForNewIntegration = {
  integrationResponse: IntegrationsResponseData[];
  newIntegrationData: NewIntegrationData;
  historyAction: Action;
  toastRef: React.MutableRefObject<FIXME_Any>;
  openEventNotificationEditor: (
    existingNotificationData: { [key: string]: unknown } | null,
    integrationData: { id: number | undefined },
  ) => void;
};

type ToastManager = 'success' | 'loading' | 'complete' | 'error' | '';

/**
 *
 *
 * @param {GetChannelsForNewIntegration} {
 *   integrationResponse -- all available integration,
 *   newIntegrationData -- newest integration. Returned from useHistory's state object,
 *   historyAction -- 'REPLACE, POP, etc',
 *   toastRef,
 *   openEventNotificationEditor -- used to open the sidebar for the correct integration ,
 * }
 * @return {*}
 */
const useGetChannelsForNewIntegrationWorkspace = ({
  integrationResponse,
  newIntegrationData,
  historyAction,
  toastRef,
  openEventNotificationEditor,
}: GetChannelsForNewIntegration) => {
  const [isChannelLoadingComplete, setIsChannelLoadingComplete] =
    useState(false);
  const [toastManager, setToastManager] = useState<ToastManager>();

  const hasSuccessfulIntegration =
    newIntegrationData?.id && historyAction === 'REPLACE';

  const newlyAddedIntegrationData =
    newIntegrationData?.id &&
    integrationResponse?.find(
      (integration) => integration.id === +newIntegrationData.id,
    );

  const { id: newlyAddedIntegrationId, display_name: displayName } =
    newlyAddedIntegrationData || {};

  const setCloseToast = () => {
    setToastManager('');
    toastRef.current.updateToast({});
  };

  const getChannelsStatus = useCallback(async (newIntegrationId: number) => {
    setToastManager('loading');
    try {
      await callChannelRefresh(newIntegrationId);
      setToastManager('complete');
      setIsChannelLoadingComplete(true);
    } catch (e) {
      setToastManager('error');
      toastRef.current?.updateToast({
        open: true,
        duration: 10000,
        variant: 'error',
        title: i18n.common.error(),
        content: '',
        action: '',
      });
      setToastManager('error');
    }
  }, []);

  useEffect(() => {
    if (hasSuccessfulIntegration) {
      setToastManager('success');
    }
  }, [hasSuccessfulIntegration]);

  useEffect(() => {
    let timeoutId: NodeJS.Timeout;
    if (hasSuccessfulIntegration && newlyAddedIntegrationId) {
      timeoutId = setTimeout(() => {
        getChannelsStatus(newlyAddedIntegrationId);
      }, 5000);
    }
    return () => clearTimeout(timeoutId);
  }, [getChannelsStatus, hasSuccessfulIntegration, newlyAddedIntegrationId]);

  if (toastManager === 'error') {
    toastRef.current?.updateToast({
      open: true,
      duration: 10000,
      variant: 'error',
      title: i18n.common.error(),
      content: '',
      action: '',
    });
  }

  if (toastManager === 'success') {
    toastRef.current?.updateToast({
      open: toastManager === 'success',
      duration: 0,
      variant: 'success',
      title: i18n.t('Success'),
      content: i18n.t(
        '{displayName} has been added as a new Slack integration.',
        { displayName },
      ),
      action: '',
    });
  }

  if (toastManager === 'loading') {
    toastRef.current?.updateToast({
      open: true,
      duration: 0,
      variant: 'progress-indeterminate',
      title: i18n.t('Loading Slack channels'),
      content: i18n.t(
        'Waiting for Slack to return channels for {displayName}. Once this is completed, you will be able to add event notifications.',
        { displayName },
      ),
      action: '',
    });
  }
  if (toastManager === 'complete') {
    toastRef.current?.updateToast({
      open: true,
      duration: 10000,
      variant: 'success',
      title: i18n.t('Slack channels available'),
      content: i18n.t(
        'Slack has finished returning channels for {displayName}. You may now create event notifications.',
        { displayName },
      ),
      action: (
        <div>
          <Button
            variant="subtle"
            css={{
              justifySelf: 'left',
            }}
            compact
            onClick={() => {
              setToastManager('');
              openEventNotificationEditor(null, {
                id: newlyAddedIntegrationId,
              });
              toastRef.current.updateToast({});
            }}
          >
            {i18n.t('Add event notification')}
          </Button>
        </div>
      ),
    });
  }

  return {
    newlyAddedIntegrationId,
    isChannelLoadingComplete,
    toastManager,
    setCloseToast,
  };
};

export default useGetChannelsForNewIntegrationWorkspace;
