import {
  Button,
  Flex,
  Icon,
  SelectV2Primitives as Select,
  TextField,
} from '@kandji-inc/nectar-ui';
import * as React from 'react';
import useDebouncedState from 'src/features/compliance/Policy/Devices/useDebouncedState';
import { useIsVisible } from 'src/hooks/useIsVisible';
import { SearchableText, UserSelections } from '../ADEListViewTableColumns';
import {
  NotAssigned,
  ScrollArea,
  SearchContainer,
  SelectItemsContainer,
  StyledContent,
  StyledIcon,
  StyledTrigger,
  TriggerContainer,
  TriggerValue,
  ValueContainer,
  ValueName,
} from './ADECellSelect.styles';

export function handleIntesection(
  entries: IntersectionObserverEntry[],
  hasNextPage: boolean,
  isFetching: boolean,
  fetchNextPage: () => void,
) {
  if (entries[0].isIntersecting && hasNextPage && !isFetching) {
    fetchNextPage();
  }
}

interface ADECellSelectProps {
  value: string;
  defaultValue: string;
  onValueChange: (value: any) => void;
  triggerTitle: string;
  triggerValue: string;
  triggerIcon: React.ReactNode;
  triggerStyle?: any;
  ariaLabel: string;
  valueIsNullable?: boolean;
  useGetData: any;
  buildItemIcon?: (item: any) => React.ReactNode;
  isCompact?: boolean;
  placeholder?: string;
  searchable?: boolean;
}

export function ADECellSelect({
  value,
  defaultValue,
  onValueChange,
  triggerTitle,
  triggerValue,
  triggerIcon,
  triggerStyle,
  ariaLabel,
  valueIsNullable = false,
  useGetData,
  buildItemIcon,
  isCompact = true,
  placeholder = '',
  searchable = true,
}: ADECellSelectProps) {
  const ref = React.useRef(null);
  const isVisible = useIsVisible(ref, '0px');
  const [debouncedSearch, setSearch, search] = useDebouncedState<string>(
    '',
    750,
  );
  const { data, fetchNextPage, hasNextPage, isFetching, isLoading } =
    useGetData(debouncedSearch);

  const items = data?.pages?.reduce((acc, curr) => [...acc, ...curr], []) ?? [];

  const [isOpen, setIsOpen] = React.useState<boolean>(false);

  const inputRef = React.useRef(null);

  function handleOnOpen(open: boolean) {
    setSearch('');
    setIsOpen(open);
  }

  function handleOnValueChange(value: string) {
    setIsOpen(false);
    onValueChange(getFromId(value));
  }

  function getFromId(value: string | UserSelections) {
    if (value === UserSelections.NOT_ASSIGNED) {
      return UserSelections.NOT_ASSIGNED;
    }
    return items.find((item: any) => item.id === value);
  }

  const observer = React.useRef<IntersectionObserver>();

  const lastElementRef = React.useCallback(
    (node: HTMLDivElement) => {
      if (isLoading) return;

      if (observer.current) observer.current.disconnect();

      observer.current = new IntersectionObserver((entries) => {
        handleIntesection(entries, hasNextPage, isFetching, fetchNextPage);
      });

      if (node) observer.current.observe(node);
    },
    [fetchNextPage, hasNextPage, isFetching, isLoading],
  );

  React.useEffect(() => {
    const focusTimeout = setTimeout(() => {
      inputRef?.current?.focus();
    }, 20);

    return () => {
      clearTimeout(focusTimeout);
    };
  }, [items, isOpen]);

  return (
    <div ref={ref}>
      {/**
       * If the select is not visible we want to render an identical
       * button element instead. This has huge performance boosts when
       * rendering a high number of rows. We still want the 'fake' button
       * so when the user is scrolling we dont see empty space.
       */}
      {!isVisible ? (
        <Button compact variant="input" css={{ width: '100%' }}>
          {triggerIcon}
          {triggerValue}
          <Icon
            name="fa-angle-down-small"
            style={{
              marginLeft: 'auto',
              color: '$neutral90',
              width: 20,
              height: 20,
            }}
          />
        </Button>
      ) : (
        <Select.Root
          value={value}
          onOpenChange={handleOnOpen}
          defaultValue={defaultValue}
          onValueChange={handleOnValueChange}
        >
          <StyledTrigger
            aria-label={ariaLabel}
            title={triggerTitle}
            variant="input"
            compact={isCompact}
          >
            <Select.Value asChild>
              <TriggerContainer
                style={value == null && { fontWeight: 400, color: '#A1ADC4' }}
              >
                {triggerIcon}
                <TriggerValue style={triggerStyle}>
                  {searchable ? (
                    <SearchableText>{triggerValue}</SearchableText>
                  ) : (
                    triggerValue
                  )}
                </TriggerValue>
              </TriggerContainer>
            </Select.Value>
            <StyledIcon>
              <Icon
                name="fa-angle-down-small"
                style={{ color: '$neutral90', width: 20, height: 20 }}
              />
            </StyledIcon>
          </StyledTrigger>
          <Select.Portal>
            <StyledContent position="popper">
              <SearchContainer>
                <TextField
                  ref={inputRef}
                  autoComplete="off"
                  icon="magnifying-glass"
                  placeholder={placeholder}
                  compact
                  value={search}
                  onChange={(e) => {
                    setSearch(e.target.value);
                  }}
                />
              </SearchContainer>
              <SelectItemsContainer>
                <ScrollArea.Root type="auto">
                  <Select.Viewport asChild style={{ position: 'unset' }}>
                    <ScrollArea.Viewport css={{ paddingBottom: 12 }}>
                      {isLoading ? null : (
                        <>
                          {valueIsNullable && search.length < 1 && (
                            <Select.Item value={UserSelections.NOT_ASSIGNED}>
                              <ValueContainer>
                                {buildItemIcon != null &&
                                  buildItemIcon(UserSelections.NOT_ASSIGNED)}
                                <NotAssigned>Not assigned</NotAssigned>
                              </ValueContainer>
                            </Select.Item>
                          )}
                          {items.map((option: { id: string; name: string }) => {
                            return (
                              <Select.Item value={option.id} key={option.id}>
                                <ValueContainer>
                                  {buildItemIcon != null &&
                                    buildItemIcon(option)}
                                  <ValueName>{option?.name}</ValueName>
                                  {/* istanbul ignore next */}
                                  {value === option.id && (
                                    <Flex
                                      justifyContent="end"
                                      css={{
                                        flexGrow: 1,
                                        color: '$blue50',
                                      }}
                                    >
                                      <Icon size="sm" name="fa-check-14px" />
                                    </Flex>
                                  )}
                                </ValueContainer>
                              </Select.Item>
                            );
                          })}
                        </>
                      )}
                      <div ref={lastElementRef} />
                    </ScrollArea.Viewport>
                  </Select.Viewport>
                  <ScrollArea.Scrollbar orientation="vertical">
                    <ScrollArea.Thumb />
                  </ScrollArea.Scrollbar>
                </ScrollArea.Root>
              </SelectItemsContainer>
            </StyledContent>
          </Select.Portal>
        </Select.Root>
      )}
    </div>
  );
}
