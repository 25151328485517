import { Button, Icon } from '@kandji-inc/bumblebee';
import classNames from 'classnames';
import get from 'lodash/get';
import React, { useState, useLayoutEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import styled from 'styled-components';
import { fetchAccount as callFetchAccount } from '../_actions/account';
import history from '../router/history';
import { moment } from './common/helpers';
import { InfoBanner } from './good/banners';
import { uploadVPPsToken } from './integrations/Apple/api';
import { H2 } from './interface/Typography';

const BaseBlock = styled('div')`
  background-color: white;
  margin: 20px 0 30px 0;
  padding: 20px 27px 20px 33px;
  display: grid;
  grid-gap: 15px;
  border-radius: 4px;
`;

const Title = styled(H2)`
  text-transform: none;
  font-size: var(--font-headings-m-size);
  line-height: var(--font-headings-m-line-height);
  font-weight: var(--font-headings-m-weight);
  letter-spacing: var(--font-headings-m-letter-spacing);
  color: #1d212a;
`;

const Notification = styled('section')`
  background: #ffeecd;
  border-radius: 4px;
  height: 40px;
  padding: 11px 16px;

  font-family: var(--font-body-xs-family);
  font-weight: var(--font-body-xs-weight);
  font-size: var(--font-body-xs-size);
  line-height: var(--font-body-xs-line-height);
  letter-spacing: var(--font-body-xs-letter-spacing);

  color: #d98017;
`;

const NotificationIcon = styled(Icon)`
  font-size: 18px;
  color: #d98017;
  margin-right: 10px;
`;

const Card = styled('section')`
  background-color: ${(props) =>
    props.cardColor ? props.cardColor : '#F6F7F9'};
  border-radius: 4px;
  padding-right: 20px;
  display: grid;
  grid-template-areas: 'icon header' 'icon content';
  grid-template-columns: 86px auto;
  grid-template-rows: auto auto;
  color: #1a1d25;
  &.instruction {
    //grid-template-areas: "icon header" "icon content" "footer footer";
    //grid-template-columns: 86px auto;
    //grid-template-rows: auto auto 50px;
  }
  &.info {
    background: #eef0f6;
    color: #4d5a79;
  }
`;

const CardIcon = styled('div')`
  grid-area: icon;
  width: 33px;
  height: 33px;
  border-radius: 100%;
  background: #1a1d25;
  margin: 31px 13px auto 40px;

  font-family: var(--font-heading-s-family);
  font-weight: var(--font-heading-s-weight);
  font-size: var(--font-heading-s-size);
  line-height: var(--font-heading-s-line-height);
  letter-spacing: var(--font-heading-s-letter-spacing);
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const CardHeader = styled('div')`
  grid-area: header;
  margin: 35px 0 10px;
  font-family: var(--font-heading-m-family);
  font-weight: var(--font-heading-m-weight);
  font-size: var(--font-heading-m-size);
  line-height: var(--font-heading-m-line-height);
  letter-spacing: var(--font-heading-m-letter-spacing);
`;

const CardContent = styled('div')`
  grid-area: content;
  margin-bottom: 34px;

  font-family: var(--font-body-xs-family);
  font-weight: var(--font-body-xs-weight);
  font-size: var(--font-body-xs-size);
  line-height: var(--font-body-xs-line-height);
  letter-spacing: var(--font-body-xs-letter-spacing);
`;

const Text = styled('div')`
  max-width: ${(props) => (props.fullWidthText ? '100%' : '603px')};
`;

const BoldText = styled('b')`
  font-weight: 600;
`;

const Action = styled('div')`
  margin: 24px 0;
`;

const Instruction = styled('img')`
  margin: 24px 0;
  max-width: 600px;
`;

const ButtonsWrapper = styled('div')`
  display: grid;
  grid-template-columns: auto min-content min-content;
  grid-template-areas: '. button1 button2';
  grid-gap: 10px;
`;

const UploadAreaWrapper = styled('section')`
  min-height: 185px;
  margin-right: 10px;
  width: 100%;
  background: #ffffff;
  border-radius: 4px;
  padding: 20px;
`;

const DashedArea = styled('div')`
  border: 2px dashed #d7dbe8;
  box-sizing: border-box;
  border-radius: 4px;
  min-height: 145px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  &.highlight {
    border: 2px dashed #d98017;
  }
`;

const UploadAreaIcon = styled('i')`
  font-size: 35px;
  color: #7d8dbd;
  margin-bottom: 19px;
`;

const UploadAreaText = styled('div')`
  font-family: var(--font-body-xs-family);
  font-weight: var(--font-body-xs-weight);
  font-size: var(--font-body-xs-size);
  line-height: var(--font-body-xs-line-height);
  letter-spacing: var(--font-body-xs-letter-spacing);
  text-align: center;

  color: #1a1d25;
`;

const StepCard = (props) => {
  const {
    children,
    icon,
    title,
    instruction,
    action,
    fullWidthText,
    cardColor,
  } = props;
  return (
    <Card className={classNames({ instruction })} cardColor={cardColor}>
      <CardIcon>{icon}</CardIcon>
      <CardHeader>{title}</CardHeader>
      <CardContent>
        <Text fullWidthText={fullWidthText}>{children}</Text>
        {action && <Action>{action}</Action>}
        {instruction &&
          instruction.map((element) => (
            <Instruction src={element} alt={title} />
          ))}
      </CardContent>
    </Card>
  );
};

const UploadArea = (props) => {
  const { uploadError, setUploadError, file, setFile } = props;
  const fileInput = useRef(null);
  const validateFile = (dfile) => {
    if (dfile) {
      if (!dfile.name.endsWith('.vpptoken')) {
        setFile(null);
        setUploadError("This doesn't appear to be a valid certificate.");
      } else {
        setFile(dfile);
        setUploadError(null);
      }
    }
  };

  useLayoutEffect(() => {
    const dropArea = document.getElementById('drop-area');
    const handleDrop = (e) => validateFile(e.dataTransfer.files[0]);
    const highlight = () => dropArea.classList.add('highlight');
    const unhighlight = () => dropArea.classList.remove('highlight');
    const preventDefaults = (e) => {
      e.preventDefault();
      e.stopPropagation();
    };
    ['dragenter', 'dragover', 'dragleave', 'drop'].forEach((eventName) => {
      dropArea.addEventListener(eventName, preventDefaults, false);
    });
    ['dragenter', 'dragover'].forEach((eventName) => {
      dropArea.addEventListener(eventName, highlight, false);
    });
    ['dragleave', 'drop'].forEach((eventName) => {
      dropArea.addEventListener(eventName, unhighlight, false);
    });
    dropArea.addEventListener('drop', handleDrop, false);
  }, []);

  const handleFiles = (e) => validateFile(e.target.files[0]);
  const handleClick = () => fileInput.current && fileInput.current.click();
  return (
    <>
      <UploadAreaWrapper>
        <input
          hidden
          ref={fileInput}
          type="file"
          accept=".vpptoken"
          onChange={handleFiles}
        />
        <DashedArea onClick={handleClick} id="drop-area">
          <UploadAreaIcon className="fal fa-file-alt" />
          <UploadAreaText>
            {get(file, 'name') || 'Drag here or click to upload'}
          </UploadAreaText>
        </DashedArea>
      </UploadAreaWrapper>
      {uploadError && (
        <Notification
          style={{ marginTop: 10, display: 'flex', justifyContent: 'center' }}
        >
          <NotificationIcon name="octagon-exclamation" />
          {uploadError}
        </Notification>
      )}
    </>
  );
};

const VPPIntegrationPage = (props) => {
  const { vppInfo, fetchAccount } = props;
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [file, setFile] = useState(null);
  const [uploadError, setUploadError] = useState(null);

  const handleSubmit = () => {
    setIsSubmitting(true);
    const formData = new FormData();

    formData.append('hostname', location.hostname);
    formData.append('sToken', file);
    return uploadVPPsToken(formData)
      .then(() => history.push('/my-company/integrations'))
      .catch(() => {
        setFile(null);
        setUploadError("This doesn't appear to be a valid token.");
      })
      .finally(() => {
        setIsSubmitting(false);
        fetchAccount();
      });
  };

  return (
    <>
      <BaseBlock>
        {!vppInfo.is_configured ? (
          <Title>Apps and Books initial configuration</Title>
        ) : (
          <>
            <Title>Apps and Books Renewal</Title>
            <Notification>
              <NotificationIcon name="circle-info" />
              {`Your Apps and Books token is expiring ${moment(
                vppInfo.token_expiration,
              ).fromNow()}`}
            </Notification>
          </>
        )}
        <StepCard
          icon="1"
          title="Sign in to Apple Business Manager or Apple School Manager"
        >
          Visit{' '}
          <a
            href="https://business.apple.com"
            style={{ color: '#618FE8', fontWeight: '500' }}
            rel="noopener noreferrer"
            target="_blank"
          >
            <BoldText>
              <u>business.apple.com</u>
            </BoldText>
          </a>{' '}
          and sign in to Apple Business Manager.
          <br />
          Visit{' '}
          <a
            href="https://school.apple.com/"
            style={{ color: '#618FE8', fontWeight: '500' }}
            rel="noopener noreferrer"
            target="_blank"
          >
            <BoldText>
              <u>school.apple.com</u>
            </BoldText>
          </a>{' '}
          and sign in to Apple School Manager.
        </StepCard>
        <StepCard icon="2" title="Download your Apps and Books token">
          <ol>
            <li>
              Click on your name at the bottom of the sidebar, click{' '}
              <BoldText>Preferences</BoldText>, then click{' '}
              <BoldText>Payments and Billing</BoldText>.
            </li>
            <li>
              Under <BoldText>Content Tokens</BoldText>, click the token name
              where the MDM server is assigned. The token is downloaded to your
              Downloads folder.
            </li>
          </ol>
        </StepCard>
        <StepCard
          icon="3"
          title="Upload your token to Kandji"
          action={
            <UploadArea
              uploadError={uploadError}
              setUploadError={setUploadError}
              file={file}
              setFile={setFile}
            />
          }
          fullWidthText
        >
          Upload the token that you just downloaded. The filename ends in{' '}
          <BoldText>.vpptoken</BoldText>
          <InfoBanner
            text="By providing us with your token file, Kandji will begin to manage
          your Apps and Books for the associated location. If you have used this token with
          another service provider, your previous license assignments will be revoked,
          and you will have to re-assign those licenses with Kandji."
          />
        </StepCard>
      </BaseBlock>
      <ButtonsWrapper>
        <Button
          style={{ gridArea: 'button1' }}
          theme="dark"
          kind="outline"
          onClick={() => history.push('/my-company/integrations')}
        >
          Cancel
        </Button>
        <Button
          className="pendo-complete-apps-books"
          disabled={isSubmitting || !file || uploadError}
          style={{ gridArea: 'button2' }}
          theme="dark"
          onClick={handleSubmit}
        >
          {isSubmitting
            ? 'Completing Apps and Books setup...'
            : 'Complete Apps and Books setup'}
        </Button>
      </ButtonsWrapper>
    </>
  );
};

const mapStateToProps = (state) => ({
  vppInfo: state.account.company.vpp_info,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchAccount: callFetchAccount,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(VPPIntegrationPage);
