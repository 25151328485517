import { i18n } from 'i18n';
import { get } from 'lodash';
import _cloneDeep from 'lodash/fp/cloneDeep';
import _flow from 'lodash/fp/flow';
import _omit from 'lodash/fp/omit';
import React, { Fragment, useEffect, useContext } from 'react';

import { Button } from 'theme/components/molecules/Action';
import {
  FieldPassword,
  FieldText,
  Form,
} from 'theme/components/molecules/Form';
import useScroll from '../../hooks/useScroll';
import Banner from '../../theme/components/atoms/Banner';
import Icon from '../../theme/components/atoms/Icon';
import Link from '../../theme/components/atoms/Link';
import Loader from '../../theme/components/atoms/Loader';

import ButtonCancel from './button-cancel';
import ButtonSave from './button-save';
import { Context } from './context';
import slug from './slug';
import strategy from './strategy';

const { WAAD: vendor } = slug;

const initial = {
  display_name: strategy[vendor].label,
  is_enabled: false,
};

const buildData = _flow(
  _cloneDeep,
  (raw) => {
    const optionKey = (key, defaultValue) =>
      get(raw, `options.${key}`, defaultValue);

    // ------------------------------------------------------------------------
    // Calculated state of the form inputs including their default values
    // ------------------------------------------------------------------------

    const data = {
      ...raw,
      options_domain: optionKey('domain', ''),
      options_client_id: optionKey('client_id', ''),
      options_client_secret: optionKey('client_secret', ''),
    };

    // ------------------------------------------------------------------------
    // Return
    // ------------------------------------------------------------------------

    return data;
  },
  _omit(['options']),
);

const deserialize = (raw) => buildData(raw);

const mapData = _flow(_cloneDeep, (form) => ({
  strategy: form.strategy,
  display_name: form.display_name,
  is_enabled: form.is_enabled,
  options: {
    domain: form.options_domain,
    client_id: form.options_client_id,
    client_secret: form.options_client_secret,
  },
}));

const processData = (form, dataMapped) => {
  const data = _cloneDeep(dataMapped);

  // --------------------------------------------------------------------------
  // Transformations
  // --------------------------------------------------------------------------

  // transformed settings

  // --------------------------------------------------------------------------
  // Deletions
  // --------------------------------------------------------------------------

  // deleted settings

  // --------------------------------------------------------------------------
  // Return
  // --------------------------------------------------------------------------

  return data;
};

const serialize = (form) => {
  const dataMapped = mapData(form);
  const data = processData(form, dataMapped);
  return data;
};

export default () => {
  const {
    data,
    fieldValidation,
    isFetching,
    initializeData,
    resetData,
    handleSave,
    onWizardClose,
  } = useContext(Context);

  const initialValues = data ? deserialize(data) : initial;
  // console.log('Deserialized:', initialValues); // delete

  const { label, icon } = strategy[vendor];
  const [bodyScroll, bodyRef] = useScroll();

  const onClose = () => onWizardClose(false);
  const onSubmit = (form) => {
    const serialized = serialize(form);
    handleSave(serialized);
  };

  useEffect(() => {
    initializeData(initial);
    // console.log('Effect:', initial); // delete
    return () => resetData();
  }, []);

  const linkProvisioningTicketURL = get(data, 'provisioning_ticket_url');

  const textAuthorization = (
    <>
      <p>{i18n.t('Please authorize your connector with Microsoft')}</p>
      <p>
        <Link
          text={i18n.t('Click here to start the authorization process')}
          href={linkProvisioningTicketURL}
        />
      </p>
      <p>
        {i18n.t(
          'You must receive an authorization success message from Microsoft before turning on your connector.',
        )}
      </p>
    </>
  );

  return (
    <div className="paper">
      <div className="theme-form-aside">
        <div className="node">
          <Button kind="close" tier="two" icon="xmark" onClick={onClose} />
        </div>
      </div>
      <div className={`theme-form-header ${bodyScroll ? 'is-sticky' : ''}`}>
        <div className="node">
          <h3 className="theme-form-title paper-title strategy">
            <Icon icon={icon} />
            <span className="label">{label}</span>
          </h3>
          <p className="theme-form-description paper-description">
            {i18n.t('Configure SSO with Microsoft Entra ID.')}{' '}
            <Link href="https://support.kandji.io/support/solutions/articles/72000560299" />
          </p>
          {linkProvisioningTicketURL && (
            <Banner type="warning" text={textAuthorization} />
          )}
        </div>
      </div>
      {data && !isFetching ? (
        <Form
          initialValues={initialValues}
          onSubmit={onSubmit}
          fieldValidation={fieldValidation}
        >
          <div ref={bodyRef} className="theme-form-body">
            <div className="node hubspot-buffer-bottom">
              <div className="theme-form-block">
                <FieldText
                  name="display_name"
                  label={i18n.t('Name')}
                  detail={i18n.t(
                    'Provide a name for the SSO connection. Cannot exceed 32 characters.',
                  )}
                  required
                  maxLength={32}
                  validateAfterSubmit
                />
              </div>
              <div className="theme-form-block">
                <FieldText
                  name="options_domain"
                  label={i18n.t('Microsoft Entra ID Domain')}
                  detail={i18n.t(
                    'The domain of your Microsoft Entra ID tenant.',
                  )}
                  required
                  maxLength={128}
                  validateAfterSubmit
                />
              </div>
              <div className="theme-form-block">
                <FieldText
                  name="options_client_id"
                  label={i18n.t('Client ID')}
                  detail={i18n.t(
                    'The unique identifier for your registered Microsoft Entra ID application.',
                  )}
                  required
                  maxLength={128}
                  validateAfterSubmit
                />
              </div>
              <div className="theme-form-block">
                <FieldPassword
                  name="options_client_secret"
                  label={i18n.t('Client Secret')}
                  detail={i18n.t(
                    'The client secret for the Microsoft Entra ID application.',
                  )}
                  required
                  maxLength={128}
                  validateAfterSubmit
                />
              </div>
            </div>
          </div>
          <div className="theme-form-footer">
            <div className="node">
              <ButtonCancel />
              <ButtonSave />
            </div>
          </div>
        </Form>
      ) : (
        <div ref={bodyRef} className="theme-form-body">
          <div className="node node-height-full">
            <Loader type="simple" />
          </div>
        </div>
      )}
    </div>
  );
};
