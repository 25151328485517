/* istanbul ignore file */
import { i18n } from 'i18n';
import { INT_TYPES } from '../../../../constants';
import Setup from './custom-layouts/setup';

import type { IntegratorViewConfig } from '../../types/integrator.types';

import type {
  MsDeviceComplianceInputs,
  MsDeviceComplianceState,
} from '../../types/ms-device-compliance.types';

// Icons
import msDeviceComplianceLogo from '../../../../generic-cards-view/assets/microsoft-device-compliance.svg';
import iosIcon from '../../../ms-device-compliance/assets/ios-icon.svg';
import macOsIcon from '../../../ms-device-compliance/assets/mac-os-icon.svg';
import microsoftLogo from '../../../ms-device-compliance/assets/microsoft-logo.png';

// Helpers
import {
  configTickets,
  handleInitialLoad,
  handleSetGroupPolicies,
  handleSignIn,
} from './helper';

import SetupComplete from './custom-layouts/setup-complete';

const MSDC_KB_URL =
  'https://support.kandji.io/support/solutions/articles/72000630314-microsoft-device-compliance-integration';

// Config
const config: IntegratorViewConfig<
  MsDeviceComplianceInputs,
  MsDeviceComplianceState,
  object
> = {
  type: INT_TYPES.msDeviceCompliance,
  get name() {
    return i18n.t('Microsoft Device Compliance');
  },
  logo: msDeviceComplianceLogo,
  initialLoad: handleInitialLoad,

  pages: [
    {
      name: 'msComplianceConfiguration',
      layout: 'one-column-checkbox-full',
      header: {
        get title() {
          return i18n.t('Microsoft Device Compliance');
        },
        get description() {
          return i18n.t(
            'Protect corporate resources by ensuring only compliant devices are allowed to access applications protected by Microsoft conditional access policies. ',
          );
        },
        link: {
          get text() {
            return i18n.t('Learn more');
          },
          url: MSDC_KB_URL,
        },
      },
      body: {
        get title() {
          return i18n.t(
            'Configure Microsoft Entra ID user directory integration in Kandji',
          );
        },
        get description() {
          return i18n.t(
            'Access to your user directory through Entra ID is required for user group lookup and must be configured prior to setting up Microsoft Device Compliance.',
          );
        },
        options: [
          {
            name: 'directoryConfigured',
            get label() {
              return i18n.t(
                'I have read and completed the steps listed above.',
              );
            },
            validations: {
              required: {
                value: true,
                get message() {
                  return i18n.t('Required');
                },
              },
            },
          },
        ],
      },
      tickets: {
        get title() {
          return i18n.t('Before you begin...');
        },
        get description() {
          return i18n.t(
            'Make sure to complete the following steps in your Microsoft Intune instance.',
          );
        },
        list: configTickets,
      },
      get cancelButtonText() {
        return i18n.t('Cancel');
      },
    },

    {
      name: 'msComplianceAuthentication',
      layout: 'one-column-input-full',
      header: {
        get title() {
          return i18n.t('Microsoft Device Compliance');
        },
        get description() {
          return i18n.t(
            'Protect corporate resources by ensuring only compliant devices are allowed to access applications protected by Microsoft conditional access policies. ',
          );
        },
        link: {
          get text() {
            return i18n.t('Learn more');
          },
          url: MSDC_KB_URL,
        },
      },
      body: {
        get title() {
          return i18n.t('Microsoft Domain');
        },
        get description() {
          return i18n.t(
            'The Microsoft Primary Domain URL can be found on the Entra ID Overview screen. ',
          );
        },
        inputs: [
          {
            name: 'msPrimaryDomain',
            type: 'text',
            get label() {
              return i18n.t('Microsoft Primary Domain');
            },
            placeholder: 'domain.onmicrosoft.com',
            validations: {
              required: {
                value: true,
                get message() {
                  return i18n.t('Required');
                },
              },
              maxLength: {
                value: 256,
                get message() {
                  return i18n.t('Must be 256 characters or fewer');
                },
              },
            },
          },
        ],
      },
      handleNext: handleSignIn,
      nextButtonIcon: microsoftLogo,
      get nextButtonText() {
        return i18n.t('Sign in with Microsoft');
      },
    },

    {
      name: 'msComplianceSetup',
      layout: 'custom-layout',
      header: {
        get title() {
          return i18n.t('Microsoft Device Compliance');
        },
        get description() {
          return i18n.t(
            'Protect corporate resources by ensuring only compliant devices are allowed to access applications protected by Microsoft conditional access policies. ',
          );
        },
        link: {
          get text() {
            return i18n.t('Learn more');
          },
          url: MSDC_KB_URL,
        },
      },
      get cancelDialogContent() {
        return i18n.t(
          'By navigating away from this page you can add desired device platforms later by going to the MSDC integration details page in the Integrations module.',
        );
      },
      Component: Setup,
      data: {
        platformInfo: {
          get title() {
            return i18n.t('Device platforms');
          },
          get description() {
            return i18n.t(
              'All data from selected platforms will be sent to Intune to determine conditional access.',
            );
          },
          get validationMessage() {
            return i18n.t('Please select at least one device platform');
          },
          list: [
            {
              name: 'macosDevices',
              get label() {
                return i18n.t('macOS devices');
              },
              icon: macOsIcon,
              altText: 'macOS icon',
            },
            {
              name: 'iosDevices',
              get label() {
                return i18n.t('iOS devices');
              },
              icon: iosIcon,
              altText: 'iOS icon',
            },
          ],
        },
      },
      handleNext: handleSetGroupPolicies,
      get cancelButtonText() {
        return i18n.t('Set up later');
      },
      handleClose: /* istanbul ignore next */ ({ history }) =>
        history.push('/integrations/ms_compliance'),
    },

    // Hold Off on this for now

    // {
    //   name: 'msComplianceSetupComplete',
    //   layout: 'custom-layout',
    //   header: {
    //     title: 'Microsoft Device Compliance',
    //     description:
    //       'Integrate device data managed by Kandji alongside all other managed devices within Intune.',
    //     link: {
    //       text: 'Learn more',
    //       url: '#',
    //     },
    //   },
    //   Component: SetupComplete,
    // },
  ],
};

export default config;
