import styled from 'styled-components';
import { withBorder } from '../../../../components/styled-components';

export const PanelTitle = styled.div`
  ${withBorder}
  padding: var(--b-gap) var(--b-gap3);
  display: flex;
  align-items: center;
  justify-content: space-between;

  & > img {
    width: 32px;
  }
`;

export const PanelSection = styled.div`
  & > div {
    display: grid;
    gap: 24px;
  }
`;

export const PanelButtonContainer = styled.div`
  margin-top: auto;
  box-shadow: 0px -4px 5px rgba(0, 0, 0, 0.04);
  display: flex;
  flex-direction: row;
  padding: var(--b-gap) var(--b-gap3);

  & > div {
    display: flex;
    flex-direction: row;
    margin-left: auto;
    gap: var(--b-gap);
  }
`;
