/* istanbul ignore file */

import { shape, string } from 'prop-types';

const CONFIG_SHAPE = shape({
  icons: shape({
    summary: string.isRequired,
  }).isRequired,
  title: string.isRequired,
  description: string.isRequired,
});

const findOption = (value, options) => options.find((v) => v.value === value);

const getOptionsFromObject = (object) =>
  Object.entries(object).map(([value, data]) => ({ value, data }));

// includeByProperty - returns a new array containing all the matching objects or an empty array if none match the property
const includeByProperty = (array, name, value) =>
  array.filter((object) => object[name] === value);

// excludeByProperty - returns a new array containing all the original objects except for any objects matching the property (used to remove an object from an array)
const excludeByProperty = (array, name, value) =>
  array.filter((obj) => obj[name] !== value);

export default CONFIG_SHAPE;
export {
  findOption,
  getOptionsFromObject,
  includeByProperty,
  excludeByProperty,
};
