import React, { useState } from 'react';
import { useReactFlow, useStore } from 'reactflow';

import { Flex, Icon, Text, TextField, styled } from '@kandji-inc/nectar-ui';

import { MAX_ZOOM, MIN_ZOOM } from '../constants';
import useBlueprintFlow from '../store';

const Control = styled(Flex, {
  alignItems: 'center',
  borderRadius: '$1',
  background: '$neutral0',
  boxShadow: '$elevation1',
  userSelect: 'none',
  overflow: 'hidden',

  '& > button': {
    height: '$6',
    width: '$6',
    padding: '5px',
  },

  '& svg': {
    height: '$6',
    width: '$6',
    padding: '5px',
  },

  variants: {
    bordered: {
      true: {
        '& > *': {
          borderRight: '0.5px solid $neutral20',
        },
        '& > *:last-child': {
          borderRight: 'inherit',
        },
      },
    },
  },
});

const Trigger = styled(Flex, {
  cursor: 'pointer',

  '&:hover': {
    backgroundColor: '#E1E8F1',
  },
});

const ControlText = styled(Text, {
  padding: '$1',
  paddingRight: '$2',

  variants: {
    uniform: {
      true: {
        paddingRight: '$1',
      },
    },
  },
});

const ZoomInput = styled(TextField, {
  padding: '0 1px',
  borderRadius: '2px',
  border: '1px solid $blue50',
  width: '33px',

  '&:focus': {
    boxShadow: 'unset',
  },
});

const zoomToPercentage = (zoom) => Math.floor(zoom * 100);
const percentageToZoom = (percentage, min = MIN_ZOOM, max = MAX_ZOOM) => {
  const value = percentage / 100;
  return Math.min(Math.max(value, min), max);
};

const FlowControls = () => {
  const setGraphItemsExpansion = useBlueprintFlow(
    (state) => state.setGraphItemsExpansion,
  );
  const { zoomIn, zoomOut, fitView } = useReactFlow();
  const zoom = useStore((state) => state.transform[2]);
  const [isSettingZoom, setIsSettingZoom] = useState({
    is: false,
    value: zoomToPercentage(zoom),
  });

  return (
    <Flex gap="md" css={{ position: 'absolute', bottom: '14px', left: '14px' }}>
      <Control>
        <Trigger
          onClick={() => {
            zoomIn();
          }}
          data-testid="zoom-in"
        >
          <Icon name="plus" />
        </Trigger>
        {isSettingZoom.is ? (
          <Flex alignItems="center">
            <ZoomInput
              autoFocus
              type="number"
              value={isSettingZoom.value}
              onBlur={() => {
                setIsSettingZoom((prev) => ({ ...prev, is: false }));
                fitView({
                  minZoom: percentageToZoom(isSettingZoom.value),
                  maxZoom: percentageToZoom(isSettingZoom.value),
                });
              }}
              onChange={(e) => {
                setIsSettingZoom((prev) => ({
                  ...prev,
                  value: parseInt(e.target.value, 10) || null,
                }));
              }}
              onKeyDown={(e) =>
                e.key === 'Enter' && (e.target as HTMLElement).blur()
              }
              data-testid="zoom-input"
            />
            <Text>%</Text>
          </Flex>
        ) : (
          <Trigger alignItems="center">
            <ControlText
              uniform
              onClick={() =>
                setIsSettingZoom({ is: true, value: zoomToPercentage(zoom) })
              }
              data-testid="zoom-control-text"
            >
              {`${zoomToPercentage(zoom)}%`}
            </ControlText>
          </Trigger>
        )}
        <Trigger
          onClick={() => {
            zoomOut();
          }}
          data-testid="zoom-out"
        >
          <Icon name="minus" />
        </Trigger>
      </Control>
      <Control>
        <Trigger
          alignItems="center"
          onClick={() => setGraphItemsExpansion('expand')}
          data-testid="expand"
        >
          <Icon name="up-right-and-down-left-from-center" />
          <ControlText>Expand all</ControlText>
        </Trigger>
      </Control>
      <Control>
        <Trigger
          alignItems="center"
          onClick={() => setGraphItemsExpansion('collapse')}
          data-testid="collapse"
        >
          <Icon name="down-left-and-up-right-to-center" />
          <ControlText>Collapse all</ControlText>
        </Trigger>
      </Control>
    </Flex>
  );
};

export default FlowControls;
