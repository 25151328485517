import { useMutation, useQueryClient } from '@tanstack/react-query';
import { servicenowService } from 'src/features/integrations/components-for-specific-types/servicenow/services';
import type { ServicenowUpdateParams } from '../services';
import { servicenowKeys } from './query-key-factory';

type DataConfigParams = {
  id: string;
  client_id: string;
  servicenow_tenant: string;
  username: string;
};

type DataParams = {
  id: string;
  type: string;
  servicenow_configs: DataConfigParams[];
};

export const useUpdateServicenowIntegration = () => {
  const queryClient = useQueryClient();

  const updateConfig = (oldData: DataParams, data: DataConfigParams) => ({
    ...oldData,
    servicenow_configs: oldData.servicenow_configs.map(
      (config: DataConfigParams) =>
        config.id === data.id ? { ...data } : config,
    ),
  });

  return useMutation({
    mutationFn: (details: ServicenowUpdateParams) =>
      servicenowService.updateIntegration({
        integrationId: details.integrationId,
        configId: details.configId,
        data: details.data,
      }),
    onSuccess: (data, variables) => {
      // update the existing query with the new data
      queryClient.setQueryData(
        servicenowKeys.details(variables.integrationId),
        (oldData: DataParams | undefined) =>
          oldData ? updateConfig(oldData, data as DataConfigParams) : oldData,
      );
    },
  });
};
