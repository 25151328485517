import { z } from 'zod';

const statusSchema = z
  .array(
    z.union([
      z.literal('CREDENTIAL_REFRESH_FAILED'),
      z.literal('APP_NOT_INSTALLED'),
      z.literal('APP_UPDATE_AVAILABLE'),
      z.never(),
    ]),
  )
  .or(z.string());

const servicenowConfigSchema = z
  .object({
    id: z.string().uuid(),
    client_id: z.string(),
    servicenow_tenant: z.string(),
    username: z.string().optional(),
    app_version: z.string().optional(),
    app_environment: z.string().optional(),
    app_latest_version: z.string().optional(),
    status: statusSchema.nullable().optional(),
  })
  .passthrough();

const servicenowIntegrationSchema = z.object({
  id: z.string().uuid(),
  type: z.string(),
  updated_at: z.string(),
  created_at: z.string(),
  servicenow_configs: z.array(servicenowConfigSchema),
});

const servicenowVersionSchema = z.object({
  version: z.string().nullable(),
});

export {
  servicenowConfigSchema,
  servicenowIntegrationSchema,
  servicenowVersionSchema,
};
