import { Paragraph, styled } from '@kandji-inc/nectar-ui';
import React from 'react';

import {
  StyledHeaderImage,
  StyledSlideHeader,
} from '../../../../components/styled-components';

const StyledDescription = styled(Paragraph, {
  textAlign: 'center',
});

interface Props {
  data: {
    title: string;
    description: string;
    logo?: string;
    link?: {
      text: string;
      url: string;
    };
  };
}

const ViewHeaderV0 = ({ data }: Props) => (
  <>
    <StyledHeaderImage src={data.logo} alt="header icon" />
    <StyledSlideHeader>{data.title}</StyledSlideHeader>

    <StyledDescription>
      {data.description}{' '}
      <a
        href={data.link?.url}
        rel="noopener noreferrer"
        target="_blank"
        className="b-alink"
      >
        {data.link?.text}
      </a>
    </StyledDescription>
  </>
);

export default ViewHeaderV0;
