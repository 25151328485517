import { Callout, styled } from '@kandji-inc/nectar-ui';
import React from 'react';

const StyledSectionMessage = styled('div', {
  paddingBottom: '$4',
});

interface Props {
  theme?: 'default' | 'danger' | 'success' | 'warning';
  showCloseButton?: boolean;
  data: {
    title: string;
    description: string;
  };
}

const ViewBanner = ({
  data,
  theme = 'success',
  showCloseButton = false,
}: Props) => (
  <StyledSectionMessage>
    <Callout
      theme={theme}
      title={data.title}
      text={data.description}
      showCloseButton={showCloseButton}
    />
  </StyledSectionMessage>
);

export default ViewBanner;
