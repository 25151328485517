/* istanbul ignore file - legacy code, moved */
import { ProfileTypes } from 'app/common/constants';
import { ImageIcon } from 'app/components/good/layout.styles';

import { cloneDeep } from 'lodash';
import React from 'react';
import { LibraryItemsAPI } from './SHAREDApi';

import styled from 'styled-components';
import SCEPIcon from './SCEP.svg';

import { SCEPDefaultState } from './SCEPConfig';
import SCEPProfilePageInner from './SCEPProfilePageInner';
import SCEPProfileSettingsTab from './SCEPProfileSettingsTab';

const SVGIconWrapper = styled('span')`
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  min-width: ${(props) => props.size}px;
  min-height: ${(props) => props.size}px;
  width: ${(props) => props.size}px;
  height: ${(props) => props.size}px;
  border-radius: ${(props) => props.size}px;
`;

const BaseIconWrapper = styled(SVGIconWrapper)`
  background: ${(props) => props.color};
`;

const LibraryItem = (config) => {
  const defaults = {
    type: '',
    identifier: '',
    deviceFamily: ['Mac'],
    osRequirement: ['macOS 10.11+'],
    macOSCompatible: false,
    iOSCompatible: false,
    tvOSCompatible: false,
    canCreate: true,
    plural: `${config.name}s`,
    newItemURL: `/${config.slug}/add`,
    shortDescription: config.description,
    getDescription: (item) => config.description,
    getPublisher: (item) => 'Apple, Inc.',
    getSettingsPageURL: (id) => `/${config.slug}/${id}/settings`,
    getStatusPageURL: (id) => `/${config.slug}/${id}/status`,
    getActivityPageURL: (id) => `/${config.slug}/${id}/activity`,
    getIcon: (item, size) =>
      config.getDefaultIcon(item.name, size, item.install_type),
    getListAPISubset: (l) => l,
    checkAccess: (isDEPEnabled) => true,
  };

  const dataConfig = {
    newItemData: {},

    // return errors object
    validate: (item) => {
      const errors = {};
      // find and set errors
      // e.g. errors.name = 'Required';
      return errors;
    },

    // format data to save
    serialize: (item) => {
      const storedItem = cloneDeep(item);
      // transformations
      return storedItem;
    },

    // read saved data format
    deserialize: (storedItem) => {
      const item = cloneDeep(storedItem);
      // transformations
      return item;
    },
  };

  return {
    ...defaults,
    ...dataConfig,
    ...config,
  };
};

export const SCEPProfile = LibraryItem({
  name: 'SCEP',
  plural: 'SCEP',
  slug: 'scep',
  type: 'profile',
  sections: ['profiles'],
  deviceFamily: ['Mac', 'iPhone', 'iPad', 'Apple TV'],
  osRequirement: ['macOS 10.7+', 'iOS 4.0+', 'iPadOS 13.0+', 'tvOS 9.0+'],
  identifier: ProfileTypes.SCEP,
  component: SCEPProfilePageInner,
  SettingsTab: SCEPProfileSettingsTab,
  defaultState: SCEPDefaultState,
  macOSCompatible: true,
  iOSCompatible: true,
  tvOSCompatible: true,
  description:
    'The Simple Certificate Enrollment Protocol (SCEP) profile allows you to securely issue certificates' +
    ' to your Apple devices from a SCEP server and Certificate Authority.' +
    ' These certificates can be used for services such as 802.1x, VPN, and others. ',
  api: LibraryItemsAPI,
  getListAPISubset: (l) => l.filter((i) => i.identifier === ProfileTypes.SCEP),
  getDefaultIcon: (name, size) => (
    <ImageIcon alt={name} src={SCEPIcon} size={size} />
  ),
  getIcon: (item, size) => (
    <BaseIconWrapper size={size} color="#44AE41">
      <ImageIcon alt={item.name} src={SCEPIcon} size={size * 0.6} />
    </BaseIconWrapper>
  ),
});
