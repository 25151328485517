/* istanbul ignore file */

import type { Node } from 'reactflow';
import { useReactFlow } from 'reactflow';

import { initialAssignmentData } from '../initial-state';

import { ASSIGNMENT_TYPES, DESCENDANT_TYPES } from '../constants';
import { getAssignmentTypeAndPeers } from '../helpers';
import useBlueprintFlow from '../store';
import { createAssignmentNode, createBranchEdge } from './data';
import useAncestry from './use-ancestry';
import useLayout from './use-layout';

/**
 * Hook for updating the data of Assignment nodes
 * @param assignment - the Assignment object { id, rules, items }
 * @param index - the position of the specific Assignment within the list
 * @param parentId - the ID of the parent Conditional node
 * @returns utilities for updating the data of an Assignment node
 */
function useAssignment(id: Node['id']) {
  const setModel = useBlueprintFlow((state) => state.setBlueprintModel);
  const { getNode, getNodes, setEdges, setNodes } = useReactFlow();
  const { getUniqueDescendantsUpToRouter } = useAncestry();
  const { runLayout } = useLayout();

  /**
   * Gets whether or not an Assignment node is removable. An Assignment node is removable if it is an 'if'
   * statement with an 'else if' to take its place or simply an 'else if' statement.
   */
  const getIsRemovable = () => {
    const { assignmentType, peers } = getAssignmentTypeAndPeers(
      getNodes(),
      getNode(id),
    );
    const isIfAndHasElseIf =
      assignmentType === ASSIGNMENT_TYPES.if && peers.length > 2;
    const isIfElse = ![ASSIGNMENT_TYPES.if, ASSIGNMENT_TYPES.else].includes(
      assignmentType,
    );

    return isIfAndHasElseIf || isIfElse;
  };

  /**
   * Clears an Assignment node of its rules and items
   */
  const clearAssignment = () => {
    setNodes((prevNodes) => {
      const updated = prevNodes.map((prevNode) => {
        const node: Node = prevNode;

        if (node.id === id) {
          node.data = {
            ...node.data,
            ...initialAssignmentData,
          };
        }

        return node;
      });

      setModel((prev) => ({ ...prev, nodes: updated }));

      return updated;
    });
  };

  /**
   * Adds a new Assignment node to the same level as the current Assignment node
   */
  const addAssignment = () => {
    const node = getNode(id);
    const routerNodeId = getUniqueDescendantsUpToRouter(id).find(
      (descendant) => descendant.descendantType === DESCENDANT_TYPES.router,
    ).id;
    const newAssignment = createAssignmentNode({
      parentNodeId: node.parentNode,
    });

    const newBranchEdge = createBranchEdge({
      source: newAssignment.id,
      target: routerNodeId,
    });

    setNodes((prevNodes) => {
      const elseNodeIndex: number = prevNodes.findIndex(
        (node) => node.id === id,
      );

      return [
        ...prevNodes.slice(0, elseNodeIndex),
        newAssignment,
        ...prevNodes.slice(elseNodeIndex),
      ];
    });

    setEdges((prevEdges) => prevEdges.concat([newBranchEdge]));
  };

  /**
   * Updates the Assignment rules in the Assignment node
   * @param rules - the new rules object to update the Assignment node with
   */
  const updateRules = (rules: object) => {
    setNodes((prevNodes) => {
      const updated = prevNodes.map((prevNode) => {
        const node: Node = prevNode;

        if (node.id === id) {
          node.data = {
            ...node.data,
            rules,
          };
        }

        return node;
      });

      setModel((prev) => ({ ...prev, nodes: updated }));

      return updated;
    });
  };

  /**
   * Deletes an Assignment node and its descendants
   */
  const deleteAssignment = () => {
    // Get all node and edge descendants
    const descendants = getUniqueDescendantsUpToRouter(id);

    // Filter for the non-Router node descendant ids
    const nodeDescendants = descendants
      .filter(
        (descendant) =>
          ![DESCENDANT_TYPES.edge, DESCENDANT_TYPES.router].includes(
            descendant.descendantType,
          ),
      )
      .map(({ id }) => id);

    // Filter for the edge descendant ids
    const edgeDescendants = descendants
      .filter(
        (descendant) => descendant.descendantType === DESCENDANT_TYPES.edge,
      )
      .map(({ id }) => id);

    // Get the id of the Router node descendant
    const routerDescendantId = descendants.find(
      (descendant) => descendant.descendantType === DESCENDANT_TYPES.router,
    ).id;

    // If the Assignment node is removable, remove it and its descendants
    if (getIsRemovable()) {
      const nodesToDelete = [id, ...nodeDescendants];
      const edgesToDelete = edgeDescendants;

      // Remove self and all node descendants (but not the Router node)
      setNodes((prevNodes) =>
        prevNodes.filter((node) => !nodesToDelete.includes(node.id)),
      );

      // Remove all edge descendants
      setEdges((prevEdges) =>
        prevEdges.filter((edge) => !edgesToDelete.includes(edge.id)),
      );
    } else {
      // If the Assignment node is not removeable, remove its descendants and clear its data
      clearAssignment();

      // Only remove descendant nodes and edges if there are node descendants
      if (nodeDescendants?.length) {
        const nodesToDelete = nodeDescendants;
        const edgesToDelete = edgeDescendants;

        const newBranchEdge = createBranchEdge({
          source: id,
          target: routerDescendantId,
        });

        // Remove all node descendants (but not the Router node)
        setNodes((prevNodes) =>
          prevNodes.filter((node) => !nodesToDelete.includes(node.id)),
        );

        // Remove all edge descendants and add the new branch edge
        setEdges((prevEdges) =>
          prevEdges
            .filter((edge) => !edgesToDelete.includes(edge.id))
            .concat(newBranchEdge),
        );
      }
    }

    runLayout();
  };

  return {
    getIsRemovable,
    addAssignment,
    updateRules,
    deleteAssignment,
  };
}

export default useAssignment;
