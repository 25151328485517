/* istanbul ignore file - legacy code, moved */
import { LibraryFiledTypes, SCEPMapping } from './SCEPConfig';

const validateNumberField = (config, key) => {
  if (!config[key].checked) {
    return false;
  }

  return (
    config[key].value < SCEPMapping.Options.fields[key].minimum ||
    config[key].value > SCEPMapping.Options.fields[key].maximum
  );
};

const numberFields = [
  'Retries',
  'RetryDelay',
  'CertificateRenewalTimeInterval',
  'Redistribution',
];

export const isValid = (config, key) => {
  if (
    key === 'SubjectAltName' &&
    config.SubjectAlternativeNameType !== 'None' &&
    !config[key]
  ) {
    return false;
  }
  if (key === 'URL' && !config[key]) {
    return false;
  }
  if (numberFields.includes(key) && validateNumberField(config, key)) {
    return false;
  }
  return true;
};

export const formatDataForRequest = (config, settings) => {
  const formatedConfig = { ...config };

  Object.keys(config).forEach((key) => {
    if (numberFields.includes(key) && !config[key].checked) {
      formatedConfig[key] = { ...config[key], value: settings[key].value };
    } else {
      formatedConfig[key] = config[key];
    }
  });

  return formatedConfig;
};

export const toType = (obj) =>
  ({}).toString
    .call(obj)
    .match(/\s([a-zA-Z]+)/)[1]
    .toLowerCase();

export const onChangeByFieldType = (event, category, key, callback) => {
  if (toType(event) === 'object') {
    callback({
      type: SCEPMapping[category].fields[key].type,
      key,
      value:
        SCEPMapping[category].fields[key].type !==
        LibraryFiledTypes.OPTIONAL_NUMBER
          ? event.target.value
          : null,
    });
  } else {
    callback({
      type: SCEPMapping[category].fields[key].type,
      key,
      value: event,
    });
  }
};

export const onChangeSubField = (event, category, key, callback) => {
  callback({
    type: SCEPMapping[category].fields[key].type,
    key,
    value: event.target.value,
    minimum: SCEPMapping[category].fields[key].minimum,
    maximum: SCEPMapping[category].fields[key].maximum,
  });
};

export const onChangeSubDropdown = (value, category, key, callback) => {
  callback({
    type: SCEPMapping[category].fields[key].type,
    key,
    value,
  });
};

export const labelByFieldType = (config, category, key) => {
  let label = '';
  if (key === 'SubjectAltName') {
    label = config.SubjectAlternativeNameType;
  } else {
    label = SCEPMapping[category].fields[key].name;
  }
  return label;
};

export const hideByFieldType = (config) =>
  config.SubjectAlternativeNameType === 'None';

export const filteredAndSortedConfig = (config, category) => {
  const byExist = (key) => !!SCEPMapping[category].fields[key];

  const byDependence = (key) =>
    !SCEPMapping[category].fields[key].dependence ||
    config[SCEPMapping[category].fields[key].dependence] !== 'None';

  const byWeight = (a, b) => {
    if (
      SCEPMapping[category].fields[a].weight >
      SCEPMapping[category].fields[b].weight
    ) {
      return 1;
    }
    if (
      SCEPMapping[category].fields[a].weight <
      SCEPMapping[category].fields[b].weight
    ) {
      return -1;
    }
    return 0;
  };

  return Object.keys(config)
    .filter(byExist)
    .filter(byDependence)
    .sort(byWeight);
};
