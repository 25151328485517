import React from 'react';

import { Box, Button, Flex, Icon, styled } from '@kandji-inc/nectar-ui';

import { FlowTippy, TippyContainer } from 'src/features/blueprint-flow/theme';

const IconContainer = styled(Flex, {
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: '$neutral30',
  border: '1px solid $neutral0',
  borderRadius: '$round',
  width: 'var(--sizes-4)',
  height: 'var(--sizes-4)',
  background: '$blue10',
  color: '$blue70',
  transition: 'all .1s linear',

  '&:hover': {
    background: '$button_primary_background_hover',
    color: '$neutral0',
    width: '22px',
    height: '22px',
  },
});

function PlusButton(props: {
  onClick: () => void;
  isDisabled?: boolean;
  ariaLabel?: string;
  style?: object;
}) {
  const { onClick, isDisabled, ariaLabel = 'plus-button', style = {} } = props;

  if (isDisabled) {
    return (
      <Box
        css={{
          height: '8px',
          width: '8px',
          borderRadius: '$round',
          background: '$neutral40',
        }}
      />
    );
  }

  return (
    <FlowTippy
      content={<TippyContainer gap="xs">Add conditional block</TippyContainer>}
    >
      <Button
        variant="subtle"
        onClick={onClick}
        aria-label={ariaLabel}
        style={{
          background: 'inherit',
          padding: 'var(--space-1)',
          cursor: 'pointer',

          ...style,
        }}
        data-testid="plus-button"
      >
        <IconContainer>
          <Icon name="fa-plus-minus-small" size="xs" />
        </IconContainer>
      </Button>
    </FlowTippy>
  );
}

export default PlusButton;
