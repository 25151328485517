import { Icon, styled } from '@kandji-inc/nectar-ui';
import React from 'react';
import type { CommonProps, InputProps } from '../types/integrator.types';
import ViewBanner from './partials/view-banner';
import ViewHeader from './partials/view-header';
import ViewHeaderH2 from './partials/view-header-h2';
import ViewInputFields from './partials/view-input-fields';

import {
  SpaceContainer,
  StyledContainer,
  StyledLink,
} from '../../../components/styled-components/main';

const StyledBodyContainer = styled(SpaceContainer, {
  width: '100%',
});
const StyledFooterContainer = styled(SpaceContainer, {
  marginBottom: '$4',
});

export interface OneColumnInputFullProps<InputType, StateType>
  extends CommonProps<InputType, StateType> {
  layout: 'one-column-input-full';
  header: {
    title: string;
    description: string;
    link?: {
      text: string;
      url: string;
    };
  };
  banner?: {
    title: string;
    description: string;
  };
  body: {
    title: string;
    description: string;
    inputs: Array<InputProps>;
  };
  footer?: {
    title: string;
    description: string;
    link?: {
      text: string;
      url: string;
    };
  };
  isProcessing?: boolean;
}

const OneColumnInputFull = <InputType, StateType>({
  logo,
  header,
  banner,
  body,
  footer,
  isProcessing,
}: OneColumnInputFullProps<InputType, StateType>) => (
  <StyledContainer>
    <ViewHeader data={{ logo, ...header }} />

    <StyledBodyContainer>
      {banner && <ViewBanner data={banner} />}
      <ViewHeaderH2 title={body.title} description={body.description} />

      <ViewInputFields data={body.inputs} isProcessing={isProcessing} />
    </StyledBodyContainer>

    {footer && (
      <StyledFooterContainer>
        <ViewHeaderH2 title={footer.title} description={footer.description} />
        <StyledLink href={footer.link?.url} rel="noreferrer" target="_blank">
          {footer.link?.text} &nbsp;
          <Icon name="arrow-up-right-from-square" size="sm" />
        </StyledLink>
      </StyledFooterContainer>
    )}
  </StyledContainer>
);
export default OneColumnInputFull;
