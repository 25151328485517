import { i18n } from 'i18n';
import React, { useContext } from 'react';
import { useOldForm } from 'src/old-honey-form';
import { Button } from 'theme/components/molecules/Action';
import { Context } from './context';

export default () => {
  const { isWorking, onCancel } = useContext(Context);
  const { handleReset } = useOldForm();
  const onClick = () => {
    handleReset();
    onCancel();
  };
  return (
    <Button
      tier="two"
      label={i18n.t('Cancel')}
      disabled={isWorking}
      onClick={onClick}
    />
  );
};
