import { useEffect, useState } from 'react';

const useDebouncedFilter = (
  watch = [],
  items,
  filterFn,
  debounceTimer = 150,
) => {
  const [filtered, setFiltered] = useState();

  useEffect(() => {
    const timer = setTimeout(() => {
      const filter = filterFn(items);
      setFiltered(filter);
    }, debounceTimer);

    return () => clearTimeout(timer);
  }, [...watch, items]);

  return filtered;
};

export default useDebouncedFilter;
