import { ENFORCEMENT_KEYS } from 'features/library-items/common/updates-card/updates-card-constants';
import { DateTime } from 'luxon';
import featureFlags from 'src/config/feature-flags';
import { getUpdatesModel } from 'src/features/library-items/common/updates-card/getUpdatesModel';
import { getAllBlueprintOptions } from '../../../data-service/blueprint/use-blueprint-service';
import {
  getOSRequirement,
  setBlueprintsToFormData,
} from '../../../data-service/library-item/transformers';
import AutomaticAppService from './automatic-app-service';

export const transformFromApi = async (apiData) => {
  const LDFF_autoAppInstallScripts = featureFlags.getFlag(
    'dc-730-auto-app-install-scripts',
  );
  try {
    const selectedBlueprints = await getAllBlueprintOptions(
      apiData.blueprints || [],
    );
    const excludedBlueprints = await getAllBlueprintOptions(
      apiData.excluded_blueprints,
    );

    const data = apiData.data_with_file_urls || apiData.data;

    const result = {
      _data: data,
      id: apiData.id,
      managedLibraryItemId: data.managed_library_item_id,
      name: apiData.name,
      description: data.description,
      subtitle: data.subtitle,
      icon: data.icon_url,
      isActive: 'active' in apiData ? apiData.active : true, // true because initially the auto app template that is returned doesn't have an active field, but the item is assumed active.
      instanceName: apiData.instance_name,
      selectedBlueprints,
      excludedBlueprints,
      isAllBlueprints: apiData.is_all_blueprints || false,
      rules: apiData.rules,
      requiresRosetta: data.requires_rosetta,
      warning: data.warning,
      publisher: data.publisher,
      version: data.version_display || data.version,
      runsOn: {
        ipad: apiData.runs_on_ipad,
        iphone: apiData.runs_on_iphone,
        ipod: apiData.runs_on_ipod,
        mac: apiData.runs_on_mac,
        tv: apiData.runs_on_tv,
        watch: apiData.runs_on_watch,
      },
      osRequirements: getOSRequirement(apiData),
    };
    result.installation = {
      type:
        data.install_enforcement ||
        AutomaticAppService.installationTypes.CONTINUOUSLY_ENFORCE,
      updates_on_pre_installed_versions_only:
        data.updates_on_pre_installed_versions_only || false,
    };

    result.selfService = {
      isEnabled: data.show_in_self_service,
      category: data.self_service_data?.category,
      isRecommended: data.self_service_data?.isRecommended,
      displayName: data.self_service_data?.displayName,
      shortDescription: data.self_service_data?.description ?? data.subtitle,
      longDescription: data.self_service_data?.bodyText ?? data.description,
      isRequireRead: data.self_service_data?.mustViewAgreement,
      // iconUrl: data.self_service_data?.iconUrl,
      // iconFile: null,
      iconUrl: null,
      iconFile: null,
      // size: data.file_size,
      size: 0,
      version: data.version || '-',
      displayInfoBtn: data.self_service_data?.displayInfoBtn, // ??
      infoBtnText: data.self_service_data?.infoBtnText, // ??
      infoBtnUrl: data.self_service_data?.infoBtnUrl, // ??
    };

    result.updates = getUpdatesModel(data);
    result.options = {
      addToDock: data.add_to_dock,
      ...(LDFF_autoAppInstallScripts
        ? {
            isPreInstallScript: Boolean(data.preinstall_script),
            isPostInstallScript: Boolean(data.postinstall_script),
            preInstallScript: data.preinstall_script || '',
            postInstallScript: data.postinstall_script || '',
            preInstallScriptRunOption:
              data.preinstall_script_run_option ||
              AutomaticAppService.getScriptInstallOptions().ONCE.value,
            postInstallScriptRunOption:
              data.postinstall_script_run_option ||
              AutomaticAppService.getScriptInstallOptions().ONCE.value,
          }
        : {}),
    };

    if (data.notifications) {
      result.notifications = {
        ...data.notifications,
        settings: {
          ...data.notifications.settings,
        },
      };
    }

    return {
      ...apiData,
      data: result,
    };
  } catch (e) {
    console.error(e);
    throw e.message;
  }
};

export const createTransformToApi =
  ({ selectedTimezone }) =>
  (model) => {
    const LDFF_autoAppInstallScripts = featureFlags.getFlag(
      'dc-730-auto-app-install-scripts',
    );
    const toSend = new FormData();

    const enf = model.updates.versionEnforcement.value;
    const isUpdateOnly =
      model.installation.type ===
        AutomaticAppService.installationTypes.CONTINUOUSLY_ENFORCE &&
      model.installation.updates_on_pre_installed_versions_only;

    toSend.set('active', model.isActive);
    toSend.set('instance_name', model.instanceName || '');
    toSend.set('type', 'automatic-app');
    toSend.set('name', model.name);
    toSend.set('is_skip_auto_update', enf === ENFORCEMENT_KEYS.any);
    toSend.set('rules', JSON.stringify(model.rules) || null);
    setBlueprintsToFormData(toSend, model.selectedBlueprints);
    toSend.set('is_all_blueprints', model.isAllBlueprints);

    const detailsUrl = model.updates.detailsUrl;

    const data = {
      ...model._data,

      details_url: detailsUrl,
      install_enforcement: model.installation.type,
      updates_on_pre_installed_versions_only:
        model.installation.updates_on_pre_installed_versions_only || false,
      version_enforcement: enf,
      _version_dates: model.updates.versions,
      ...(model.updates.versionsAutoApps.length > 0 && {
        _version_dates_auto_apps: model.updates.versionsAutoApps,
      }),

      add_to_dock: model.options.addToDock,
      ...(LDFF_autoAppInstallScripts && !isUpdateOnly
        ? {
            preinstall_script: model.options.isPreInstallScript
              ? model.options.preInstallScript
              : null,
            postinstall_script: model.options.isPostInstallScript
              ? model.options.postInstallScript
              : null,
            preinstall_script_run_option: model.options.isPreInstallScript
              ? model.options.preInstallScriptRunOption
              : null,
            postinstall_script_run_option: model.options.isPostInstallScript
              ? model.options.postInstallScriptRunOption
              : null,
          }
        : {}),

      notifications: {
        ...model.notifications,
        settings: { ...model.notifications.settings },
      },
    };

    // Delete the pre/post install scripts if they don't apply (in case they were previously set):
    if (LDFF_autoAppInstallScripts && isUpdateOnly) {
      delete data.preinstall_script;
      delete data.preinstall_script_run_option;
      delete data.postinstall_script;
      delete data.postinstall_script_run_option;
    }

    if (enf !== ENFORCEMENT_KEYS.any) {
      data.enforcement_time = model.updates.enforcementTime.value;
      data.enforcement_timezone = selectedTimezone.value;
    } else {
      data.enforcement_delay = null;
      data.enforcement_time = null;
      data.enforcement_timezone = null;
    }

    switch (enf) {
      case ENFORCEMENT_KEYS.custom:
        data.minimum_app_version = model.updates.minimumVersion.value;
        // auto app override
        if (model.updates.minimumAutoAppVersion.value?.version) {
          data.minimum_auto_app_version =
            model.updates.minimumAutoAppVersion.value;
          data.minimum_app_version =
            model.updates.minimumAutoAppVersion.value?.version;
        }
        data.enforce_after = DateTime.fromJSDate(
          model.updates.enforcementDeadline,
        ).toFormat('MM/dd/yyyy');
        break;
      case ENFORCEMENT_KEYS.newest:
        data.enforcement_delay = model.updates.enforcementDelay.value;
    }

    if (
      model.selfService.isEnabled &&
      !model.installation.updates_on_pre_installed_versions_only
    ) {
      data.show_in_self_service = true;
      data.self_service_data = {
        isRecommended: model.selfService.isRecommended,
        // iconS3Key: model.selfService.iconS3Key,
        iconS3Key: null,
        displayName: model.selfService.displayName,
        description: model.selfService.shortDescription,
        bodyText: model.selfService.longDescription,
        mustViewAgreement: model.selfService.isRequireRead,
        category: model.selfService.category,
        displayInfoBtn: model.selfService.displayInfoBtn,
        infoBtnText: model.selfService.infoBtnText,
        infoBtnUrl: model.selfService.infoBtnUrl,
      };
    } else {
      data.show_in_self_service = false;
      delete data.self_service_data;
    }

    if (model.skip_blueprint_conflict) {
      toSend.set('skip_blueprint_conflict', model.skip_blueprint_conflict);
    }

    toSend.set('data', JSON.stringify(data));

    return toSend;
  };
