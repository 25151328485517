import type colors from 'src/features/blueprints/colors';

type Colors = keyof typeof colors;

export interface BlueprintEnrollmentCode {
  is_active: boolean;
  code: string;
}

export interface Blueprint {
  id: string;
  name: string;
  icon: string;
  color: Colors;
  enrollment_code: BlueprintEnrollmentCode;
}

export interface AuthConfigAuthClient {
  client_id: string;
  auth0_configuration: {
    id: string;
    connection_id: string;
    display_name: string;
    strategy: string;
    created_at: string;
    updated_at: string;
  };
}

export interface BlueprintAuthConfig {
  id?: string;
  blueprints: string[];
  auth0_client: AuthConfigAuthClient;
  enabled: boolean;
  assign_user: boolean;
}

export interface AuthBlueprint extends Blueprint {
  authConfig: BlueprintAuthConfig;
}

export interface AuthConnection {
  id: string;
  display_name: string;
  is_enabled: boolean;
  strategy: string;
}

export interface AuthConfigUpsert {
  id: string | null;
  auth0_configuration: string;
  blueprints: string[];
  enabled: boolean;
  assign_user: boolean;
}

export class NewAuthConfig implements Partial<BlueprintAuthConfig> {
  blueprints: string[];
  auth0_client: AuthConfigAuthClient;
  enabled: boolean;
  assign_user: boolean;

  constructor(blueprintId: string, connection: AuthConnection | null) {
    this.blueprints = [blueprintId];
    this.auth0_client = {
      client_id: '',
      auth0_configuration: {
        id: '',
        connection_id: connection?.id,
        display_name: connection?.display_name,
        strategy: connection?.strategy,
        created_at: '',
        updated_at: '',
      },
    };
    this.enabled = false;
    this.assign_user = false;
  }
}
