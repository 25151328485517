import {
  Button,
  ButtonGroup,
  Dialog,
  Flex,
  Loader,
  type ToastVariants,
  Toaster_UNSTABLE as Toaster,
  styled,
  theme,
  useToast_UNSTABLE as useToast,
} from '@kandji-inc/nectar-ui';
import * as React from 'react';
import { api } from 'src/app/api/base';
import { URL_REPORTS_DEP_COMPUTERS_V2 } from 'src/app/api/urls';
import { getFileName, saveAs } from 'src/app/components/common/helpers';
import { InterfaceContext } from 'src/contexts/interface';
import { useADEListViewStore } from '../store/ADEListViewStore';
import { fromSortingState } from '../utils/fromSortingState';
import { getEnrollmentStatusFilter } from '../utils/getEnrollmentStatusFilters';
import { getTokenFilters } from '../utils/getTokenFilters';
import { getValidSearchColumns } from '../utils/getValidSearchColumns';

const ExportDialogContent = styled('div', {
  width: 356,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  gap: '$2',
  color: theme.colors.neutral70,
});

const ExportContentHeader = styled('div', {
  fontWeight: '$medium',
  fontSize: '$2',
});

const ExportDialogStrong = styled('strong', {
  fontWeight: '$medium',
});

const CURRENT_VIEW_COPY = (
  <div>
    CSV will <ExportDialogStrong>only</ExportDialogStrong> include data that is
    currently visible in the table, and contain any applied filters.
  </div>
);

const ALL_ATTRIBUTES_COPY = (
  <div>
    CSV will include <ExportDialogStrong>all</ExportDialogStrong> data for this
    table, including data that is not currently visible in the table.
  </div>
);

export const SIDEBAR_DOCKED_OFFSET = 256;
export const SIDEBAR_CLOSE_OFFSET = 78;

export function getCustomColumns(isAllAttributes: boolean, columns: string[]) {
  if (isAllAttributes) return {};

  return {
    columns: columns.join(','),
  };
}

export function ADEExportTable({
  isAllDevices,
  isOpen,
  toggleDialog,
  sorting,
}) {
  const { sidebarDocked } = React.useContext(InterfaceContext);
  const { visibleColumns, tokenFilters, enrollmentStatusFilter, searchText } =
    useADEListViewStore((state) => ({
      tokenFilters: state.tokenFilters,
      enrollmentStatusFilter: state.enrollmentStatusFilter,
      visibleColumns: state.visibleColumns,
      searchText: state.searchText,
    }));
  const [isAllAttributes, setIsAllAttributes] = React.useState(false);
  const [isExporting, setIsExporting] = React.useState(false);
  const { toast } = useToast();

  const displayToast = (
    title: string | React.ReactNode,
    variant: ToastVariants,
    content: string,
  ) =>
    toast({
      title,
      variant,
      style: {
        left: /* istanbul ignore next */ sidebarDocked
          ? `${SIDEBAR_DOCKED_OFFSET + 12}px`
          : `${SIDEBAR_CLOSE_OFFSET + 12}px`,
        bottom: '12px',
        position: 'absolute',
      },
      content,
    });

  function exportStartedToast() {
    displayToast(
      <Flex gap="xs">
        <Loader />
        Devices
      </Flex>,
      'default',
      'Preparing file for download...',
    );
  }

  function exportCompleteToast() {
    displayToast('Devices', 'success', 'Export complete!');
  }

  async function exportTable() {
    try {
      toggleDialog();
      setIsExporting(true);
      exportStartedToast();

      const body = {
        ext: 'csv',
        ordering: fromSortingState(sorting),
        search_columns: getValidSearchColumns(visibleColumns),
        search: searchText,
        ...getCustomColumns(isAllAttributes, visibleColumns),
        filters: JSON.stringify([
          ...getEnrollmentStatusFilter(isAllDevices, enrollmentStatusFilter),
          ...getTokenFilters(tokenFilters),
        ]),
      };

      const res = await api(URL_REPORTS_DEP_COMPUTERS_V2).get(body);
      const name = getFileName('Auto-Enroll Devices', '.csv');
      const content = `data:text/csv,${encodeURIComponent(res.data)}`;

      saveAs(content, name);
      exportCompleteToast();
      setIsExporting(false);
    } catch {
    } finally {
      setIsExporting(false);
      setIsAllAttributes(false);
    }
  }

  return (
    <div>
      <Dialog
        isOpen={isOpen}
        title="Export Devices to CSV"
        onOpenChange={toggleDialog}
        content={
          <ExportDialogContent>
            <ExportContentHeader>Includes</ExportContentHeader>
            <ButtonGroup
              compact
              variant="inverse"
              buttons={[
                {
                  label: `Current view`,
                  selected: !isAllAttributes,
                  onClick: /* istanbul ignore next */ () => {
                    setIsAllAttributes(false);
                  },
                },
                {
                  label: `All attributes`,
                  selected: isAllAttributes,
                  onClick: /* istanbul ignore next */ () => {
                    setIsAllAttributes(true);
                  },
                },
              ]}
            />

            <span>
              {isAllAttributes ? ALL_ATTRIBUTES_COPY : CURRENT_VIEW_COPY}
            </span>
          </ExportDialogContent>
        }
        footer={
          <Flex gap="xs" alignItems="end">
            <Button
              compact
              onClick={/* istanbul ignore next */ () => toggleDialog(false)}
              disabled={isExporting}
            >
              Cancel
            </Button>
            <Button
              variant="primary"
              compact
              onClick={exportTable}
              disabled={isExporting}
            >
              Export
            </Button>
          </Flex>
        }
      />
      <Toaster />
    </div>
  );
}
