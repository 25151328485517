import { i18n } from 'i18n';
import { formatDate } from './_helpers';

export default (activity) => [
  {
    label: i18n.t('Created by'),
    values: [activity.actor_name],
  },
  {
    label: i18n.t('Created at'),
    values: [formatDate(activity)],
  },
];
