import {
  Button,
  DropdownMenuPrimitives as Dropdown,
  Flex,
  Icon,
  Separator,
  Text,
  useToast_UNSTABLE as useToast,
} from '@kandji-inc/nectar-ui';
import { useMutation } from '@tanstack/react-query';
import { api } from 'app/api/base';
import { URL_COMPUTERS, URL_COMPUTER_CHANGE_BLUEPRINT } from 'app/api/urls';
import AssignBlueprintModal from 'features/blueprint-flow/pages/devices/AssignBlueprint';
import { useContext, useState } from 'react';
import { InterfaceContext } from 'src/contexts/interface';
import { pluralizeWord } from 'src/features/blueprint-flow/helpers';
import DeleteDeviceModal from 'src/features/blueprint-flow/pages/devices/DeleteDevice';
import BulkEditComputersModal from 'src/features/tags/bulk-tag-actions';

export const DeviceBulkActions = ({
  devices,
  onClear,
  onChangedBlueprint,
  onDeleted,
}: {
  devices: string[];
  onClear: () => void;
  onChangedBlueprint: () => void;
  onDeleted: () => void;
}) => {
  const { toast } = useToast();
  const { sidebarOpened } = useContext(InterfaceContext);
  // istanbul ignore next
  const toastStyle = {
    left: sidebarOpened ? '264px' : '100px',
    bottom: '12px',
    position: 'absolute',
  };
  const { mutateAsync: changeBlueprint } = useMutation({
    mutationKey: ['devices-change-blueprint'],
    mutationFn: ({
      computers,
      blueprint,
    }: { computers: Array<string>; blueprint: { id: string; name: string } }) =>
      api(URL_COMPUTER_CHANGE_BLUEPRINT).patch({
        computers,
        blueprintId: blueprint.id,
      }),
    onSuccess: (_data, { blueprint }) => {
      setChangingBlueprint(false);
      toast({
        title: `${devices.length} ${pluralizeWord(
          'device',
          devices.length,
        )} will be reassigned to Blueprint ${blueprint.name}.`,
        content: 'This may take some time to complete.',
        variant: 'success',
        // @ts-expect-error -- fix me
        style: toastStyle,
      });
      onChangedBlueprint();
    },
    onError:
      // istanbul ignore next
      () =>
        toast({
          title: `Failed to reassign ${pluralizeWord(
            'device',
            devices.length,
          )}.`,
          variant: 'error',
          content: 'Please try again.',
          // @ts-expect-error -- fix me
          style: toastStyle,
        }),
  });
  const { mutateAsync: deleteDevices } = useMutation({
    mutationKey: ['devices-delete'],
    mutationFn: ({ devices }: { devices: string[] }) => {
      return api(URL_COMPUTERS).delete(devices);
    },
    onSuccess: () => {
      setDeleting(false);
      toast({
        title: `${devices.length} ${pluralizeWord(
          'device',
          devices.length,
        )} will be deleted.`,
        variant: 'success',
        // @ts-expect-error -- fix me
        style: toastStyle,
      });
      onDeleted();
    },
    onError:
      // istanbul ignore next
      () =>
        toast({
          title: `Failed to delete ${pluralizeWord('device', devices.length)}.`,
          variant: 'error',
          content: 'Please try again.',
          // @ts-expect-error -- fix me
          style: toastStyle,
        }),
  });
  const [changingBlueprint, setChangingBlueprint] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [editingTags, setEditingTags] = useState(false);

  return (
    <Flex alignItems="center">
      <Flex flex="none" gap="sm" css={{ height: 28 }}>
        <Button
          aria-label="change blueprint"
          onClick={() => setChangingBlueprint(true)}
        >
          Change Blueprint
        </Button>
        <Dropdown.Root>
          <Dropdown.Trigger asChild>
            <Button
              aria-label="extra actions"
              data-testid="tertiary-menu"
              icon={{ name: 'ellipsis' }}
            />
          </Dropdown.Trigger>
          <Dropdown.Portal>
            <Dropdown.Content
              css={{ zIndex: 4, mb: 4 }}
              align="start"
              side="top"
            >
              <Dropdown.Item
                aria-label="edit tags"
                data-testid="edit-tags"
                onClick={() => setEditingTags(true)}
                css={{ gap: 4 }}
              >
                <Icon size="sm" name="pen" />
                <Text>Edit tags</Text>
              </Dropdown.Item>
              <Dropdown.Item
                aria-label="delete devices"
                data-testid="delete-devices"
                onClick={() => setDeleting(true)}
                css={{ color: '$red50', gap: 4 }}
              >
                <Icon size="sm" name="trash-can" />
                <Text>{pluralizeWord('Delete device', devices.length)}</Text>
              </Dropdown.Item>
            </Dropdown.Content>
          </Dropdown.Portal>
        </Dropdown.Root>
      </Flex>
      <Separator
        orientation="vertical"
        css={{ width: 1, minHeight: 36, margin: '0 18px' }}
      />
      <Flex alignItems="center" gap="md">
        <Text css={{ fontWeight: '$medium', color: '$neutral70' }}>
          {devices.length} selected
        </Text>
        <Button variant="link" onClick={onClear}>
          Clear
        </Button>
      </Flex>
      <AssignBlueprintModal
        isOpen={changingBlueprint}
        toggle={setChangingBlueprint}
        currentBlueprintId={''}
        isMultiple={devices.length > 1}
        onConfirm={(blueprint) =>
          changeBlueprint({ computers: devices, blueprint })
        }
      />
      <DeleteDeviceModal
        isOpen={deleting}
        isMultiple={devices.length > 1}
        toggle={setDeleting}
        onConfirm={async () => deleteDevices({ devices })}
      />
      {editingTags && (
        <BulkEditComputersModal
          onHide={() => setEditingTags(false)}
          computerIds={devices}
        />
      )}
    </Flex>
  );
};
