import { useContext } from 'react';
import { useDispatch } from 'react-redux';

import { fetchAccount } from 'app/_actions/account';

import { AccountContext } from 'contexts/account';

import { patchCompanySettings } from './api';
import { transformForAPI } from './transformer';

export default () => {
  const { company } = useContext(AccountContext);

  const dispatch = useDispatch();

  const { access_group } = company;

  const updateCompanySettings = async (form) => {
    let data = transformForAPI(form);

    if (access_group) {
      data = { ...data, access_group: null };
    }

    await patchCompanySettings(data);

    dispatch(fetchAccount());
  };

  return { updateCompanySettings };
};
