import { Button } from '@kandji-inc/bumblebee';
import { i18n } from 'i18n';
import { node, string } from 'prop-types';
import React from 'react';

function Ticket({ title, text, icon, link }) {
  return (
    <div className="welcome-modal_ticket__wrapper">
      <img src={icon} alt="ticket-icon" />
      <div className="b-ml">
        <h4 className="b-h4">{title}</h4>
        <p className="b-txt-light b-mt-tiny b-mb-tiny">{text}</p>
        {link && (
          <Button
            href={link}
            icon="circle-info"
            kind="link"
            target="_blank"
            size="small"
          >
            {i18n.t('Learn more')}
          </Button>
        )}
      </div>
    </div>
  );
}

Ticket.propTypes = {
  title: string.isRequired,
  text: node.isRequired,
  icon: string.isRequired,
  link: string,
};

Ticket.defaultProps = {
  link: null,
};

export default Ticket;
