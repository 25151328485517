import { Breadcrumb, Tabs } from '@kandji-inc/bumblebee';
import { oktaDeviceTrustService } from 'features/integrations/components-for-specific-types/okta/services';
import ConfigurationModal from 'features/integrations/components/configuration-modal';
import { i18n } from 'i18n';
import React, { useState } from 'react';
import { Redirect } from 'react-router-dom';
import { links } from 'src/app/common/constants';
import SummaryCard from 'src/features/integrations/components/integration-details/summary-card';
import { useGetLibraryItemById } from 'src/features/integrations/hooks/use-get-library-item-by-id';
import Loader from 'src/theme/components/atoms/Loader';
import styled from 'styled-components';
import odtLogo from '../assets/okta-verify-outline.svg';
import { useOktaLicense } from '../hooks';
import useGetOktaDeviceTrust from '../hooks/use-get-okta-device-trust';
import { DevicePlatforms, General } from './components';
import CannotDeleteIntegration from './components/cannot-delete-integration';
import DeleteIntegration from './components/delete-integration';
import OktaLibraryItemDetails from './components/okta-library-item';

const StyledTabs = styled(Tabs)`
  margin-top: 17px;
  margin-bottom: var(--b-gap3);
`;

const StyledContainer = styled.div`
  display: grid;
  gap: 32px;
`;

const StyledOktaConfigModal = styled(ConfigurationModal)`
  display: grid;
  align-items: center;
  text-align: center;
  padding: 0;
  width: 560px;
  height: 340px;
`;

const StyledOktaCannotDeleteConfigModal = styled(ConfigurationModal)`
  display: grid;
  align-items: center;
  text-align: center;
  padding: 0;
  width: 560px;
  height: 452px;
`;

const OktaDetails = () => {
  const TITLE = i18n.t('Okta Device Trust');

  const { data, loading, hasFetched, clearData } = useGetOktaDeviceTrust();

  const { data: oktaLicenseData, loading: isOktaLicenseLoading } =
    useOktaLicense();

  const { data: libraryItemData } = useGetLibraryItemById(oktaLicenseData?.id);

  const oktaLibraryItemData = libraryItemData?.data;

  const odtIntegrationData = data?.results?.[0];
  const [isIntegrationDeleteModalOpen, setIsIntegrationDeleteModalOpen] =
    useState(false);

  const [canDeleteIntegration, setCanDeleteIntegration] = useState<
    boolean | null
  >(null);

  if (isOktaLicenseLoading) {
    return <Loader type={Loader.types.LINE} />;
  }

  // Redirect to the marketplace if ODT is not configured
  /* istanbul ignore next */
  if (!loading && !odtIntegrationData && hasFetched) {
    return <Redirect to={links.integrations.marketplace} />;
  }
  // TODO: write test
  /* istanbul ignore next */
  const handleCloseModal = () => {
    clearData();
    setIsIntegrationDeleteModalOpen(false);
  };

  const getModal = () => {
    if (odtIntegrationData && canDeleteIntegration) {
      return (
        <StyledOktaConfigModal isModalOpen={isIntegrationDeleteModalOpen}>
          <DeleteIntegration
            oktaDeviceTrustId={odtIntegrationData?.id}
            onClose={handleCloseModal}
          />
        </StyledOktaConfigModal>
      );
    }
    if (oktaLicenseData) {
      return (
        <StyledOktaCannotDeleteConfigModal
          isModalOpen={isIntegrationDeleteModalOpen}
        >
          <CannotDeleteIntegration
            onClose={handleCloseModal}
            oktaLicenseData={oktaLicenseData}
          />
        </StyledOktaCannotDeleteConfigModal>
      );
    }
    return null;
  };

  return (
    <>
      {getModal()}
      <Breadcrumb
        crumbs={[
          {
            url: links.integrations.root,
            title: i18n.t('Integrations'),
          },
          { title: TITLE },
        ]}
        classes={{ wrapper: 'b-mt3 b-mb2' }}
      />
      <SummaryCard
        imgSrc={odtLogo}
        title={TITLE}
        description={i18n.t(
          'Okta Device Trust allows your organization to protect corporate resources by enforcing device compliance before allowing access to applications through Okta.',
        )}
        onDeleteIntegration={() => {
          /* istanbul ignore next */
          oktaDeviceTrustService
            .getOktaVerifyLibraryItem()
            .then((response) => {
              if (response.data === 'Okta Device Trust is false') {
                setCanDeleteIntegration(true);
              } else if (response.data === 'Okta Device Trust is true') {
                setCanDeleteIntegration(false);
              }
            })
            .catch(() => {
              setIsIntegrationDeleteModalOpen(false);
            });
          setIsIntegrationDeleteModalOpen(true);
        }}
      />

      <StyledTabs tabs={[{ label: i18n.t('Settings'), route: '' }]}>
        <StyledContainer>
          <General data={odtIntegrationData} />
          <DevicePlatforms
            data={odtIntegrationData}
            oktaLibraryItemData={oktaLibraryItemData}
          />
          <OktaLibraryItemDetails
            oktaLicenseData={oktaLicenseData}
            oktaLibraryItemData={oktaLibraryItemData}
            loading={isOktaLicenseLoading}
          />
        </StyledContainer>
      </StyledTabs>
    </>
  );
};

export default OktaDetails;
