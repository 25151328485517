// istanbul ignore file
import { Uploader, defaultRenderInfo } from '@kandji-inc/bumblebee';
import * as React from 'react';

import { Setting } from 'features/library-items/template';

import { apiTypes } from 'features/library-items/library/common';
import {
  getS3InfoForFile,
  uploadCustomApp,
  waitForSHA256,
} from 'src/templates/library/custom-apps/api';
import KandjiLoginService from '../../service/kandji-login-service';

type GoogleIdPCertFile = {
  filePath: string;
  id: string;
  name: string;
  size: number;
  sha256: string;
};

type AuthSetting = {
  clientSecret: string;
  googleIdpCertFile: GoogleIdPCertFile | null;
  idp: string;
  mode: string;
  oidcBaseUrl: string;
  publicAppId: string;
  redirectUri: string;
  webLoginClientId: string;
};

type GoogleIdpProps = Readonly<{
  setting: {
    clientSecret: string;
    googleIdpCertFile: GoogleIdPCertFile | null;
  };
  update: <Key extends keyof AuthSetting>(
    key: Key,
    cb: (value: AuthSetting[Key]) => AuthSetting[Key],
  ) => void;
  onValidate: (cb: (isValid: boolean) => void) => (isValid: boolean) => void;
  onInvalidate: (error: string | boolean) => void;
  invalidations: {
    googleIdp: string | false;
    [key: string]: string | boolean;
  };
  isDisabled: boolean;
  isSubmitted: boolean;
  isVisible: boolean;
}>;

const GoogleIdp = React.forwardRef<HTMLDivElement, GoogleIdpProps>(
  (props, invalidationAnchorRef) => {
    const {
      setting,
      update,
      onValidate: onValidateLibraryItem,
      onInvalidate,
      invalidations,
      isDisabled,
      isSubmitted,
      isVisible,
    } = props;

    const idpCertFile =
      setting[KandjiLoginService.idpUpload.GOOGLE_WORKSPACE.key];

    const [certFile, setCertFile] = React.useState(idpCertFile || null);
    const [uploadCancelFn, setUploadCancelFn] = React.useState();
    const setIsSaveEnabled =
      typeof onValidateLibraryItem === 'function'
        ? onValidateLibraryItem((isValid) => isValid)
        : (noopBool: boolean) => noopBool;

    const forceWithFile = React.useMemo(
      () =>
        (idpCertFile?.sha256 && {
          file: {
            name: idpCertFile.name,
            size: idpCertFile.size,
          },
          sha256: idpCertFile.sha256,
        }) ||
        null,
      [idpCertFile],
    );

    React.useEffect(() => {
      if (isVisible && !idpCertFile?.sha256) {
        onInvalidate('Required');
      }
      if (!isVisible) {
        onInvalidate(false);
      }

      return () => {
        const prevWasVisible = isVisible === true;
        if (prevWasVisible) {
          onInvalidate(false);
        }
      };
    }, [idpCertFile?.sha256, isVisible]);

    return isVisible ? (
      <Setting.Row>
        <Setting.Title>
          <p className="b-txt">Upload certificate from Google Workspace</p>
        </Setting.Title>

        <Setting.Helpers className="b-txt-light">
          Upload the new certificate .zip file generated from your Google
          Workspace instance, under the Authentication card.
        </Setting.Helpers>

        <Setting.Controls>
          <div role="presentation" ref={invalidationAnchorRef} />

          <Uploader
            onUpload={(file, updateProgress) =>
              getS3InfoForFile(file, apiTypes.KANDJI_LOGIN).then((r) => {
                setIsSaveEnabled(false);
                const s3Data = r.data.s3_post_data;
                const upWithCancel = uploadCustomApp(
                  file,
                  updateProgress,
                  s3Data,
                );

                setCertFile(
                  (
                    /* istanbul ignore next -- hard to test */
                    prev = {},
                  ) => ({
                    ...prev,
                    filePath: r.data.s3_post_data.fields.key,
                    id: r.data.id,
                    name: file.name,
                    size: file.size,
                  }),
                );

                setUploadCancelFn(() => upWithCancel.cancel);
                return upWithCancel.upload;
              })
            }
            onUploaded={(file) => {
              setIsSaveEnabled(true);
              setCertFile((prev) => ({
                ...prev,
                name: file.name,
                size: file.size,
              }));
            }}
            onValidate={(
              _file,
              { getUploadCurrentlyCancelled, CancelError },
            ) => {
              const [isUploadCancelled, getUploadPrevCancelled] =
                getUploadCurrentlyCancelled({
                  isTrackAlreadyCancelled: true,
                });

              /* istanbul ignore if -- hard to test */
              if (isUploadCancelled || !certFile) {
                const cancelError = new CancelError(
                  'Upload cancelled during validation',
                );
                return Promise.reject(cancelError);
              }

              return waitForSHA256(certFile.id).then((sha256) => {
                const isAlreadyCancelled = getUploadPrevCancelled();

                /* istanbul ignore if -- hard to test */
                if (getUploadCurrentlyCancelled() || isAlreadyCancelled) {
                  const cancelError = new CancelError(
                    'Upload cancelled after validation',
                  );
                  return Promise.reject(cancelError);
                }

                /* istanbul ignore else -- hard to test */
                if (certFile) {
                  onInvalidate(false);
                  update('googleIdpCertFile', {
                    ...certFile,
                    sha256,
                  });
                  return sha256;
                }

                const noSHACreated = '';
                return noSHACreated;
              });
            }}
            onCancel={uploadCancelFn}
            onDelete={() => {
              setUploadCancelFn(null);
              setCertFile(null);
              update('googleIdpCertFile', null);
            }}
            onError={(error: '' | unknown) => {
              if (error && typeof error === 'string') {
                setIsSaveEnabled(true);
                onInvalidate(error);
              }
            }}
            withError={(isSubmitted && invalidations.googleIdp) || ''}
            allowedTypes={
              KandjiLoginService.idpUpload.GOOGLE_WORKSPACE.allowedFiledTypes
            }
            isDisabled={isDisabled}
            uploadInstructions={
              <span>
                Drag file here or{' '}
                <span className="b-alink">click to upload</span>
              </span>
            }
            fileIcons={KandjiLoginService.idpUpload.GOOGLE_WORKSPACE.fileIcons}
            maxFileSize={
              KandjiLoginService.idpUpload.GOOGLE_WORKSPACE.maxFileSize
            }
            forceWithFile={forceWithFile}
            renderInfo={({ currentFile }) => (
              <div>
                {defaultRenderInfo({
                  isDisabled,
                  currentFile: {
                    ...currentFile,
                    // setting to null to hide displaying SHA-256 value
                    fileSHA: null,
                  },
                })}
              </div>
            )}
          />
        </Setting.Controls>
      </Setting.Row>
    ) : null;
  },
);

export default GoogleIdp;
