import { TextInput } from '@kandji-inc/bumblebee';
import { i18n } from 'i18n';
import styled from 'styled-components';

const Container = styled.div`
  display: grid;
  gap: var(--b-gap1);
`;
const EventNotificationName = (props) => {
  const { value, onChange } = props;

  return (
    <Container className="b-gap1">
      <div className="b-gap1">
        <label htmlFor="notification-name" className="b-txt">
          {i18n.t('Event notification name')}
        </label>
      </div>
      <TextInput
        id="notification-name"
        placeholder="Enter a name"
        name="notification-name"
        value={value ?? ''}
        onChange={(e) => onChange(e.target.value)}
      />
    </Container>
  );
};

export default EventNotificationName;
