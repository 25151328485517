import { i18n } from 'i18n';
import { createReducer } from 'redux-create-reducer';
import { account } from '../_actions/action-types';

const initialState = {
  userId: null,
  clientId: null,
  clientDomain: null,
  organizationId: null,
  locale: i18n.DEFAULT_I18N_LOCALE,
  connectionName: null,
  audience: null,
  error: false,
  callbackUrl: '',
  configureUrl: '',
  enablePendo: false,
  isManualAuth: false,
  tempDeviceId: '',
  metadata: '',
};

export default createReducer(initialState, {
  [account.ACCOUNT_AUTH0_CREDS_FETCHED](state, action) {
    return {
      ...state,
      ...{
        clientId: action.clientId,
        clientDomain: action.clientDomain,
        audience: action.audience,
        error: action.error,
        organizationId: action.organizationId || initialState.organizationId,
        connectionName: action.connectionName || initialState.connectionName,
        callbackUrl: action.callbackUrl || initialState.callbackUrl,
        configureUrl: action.configureUrl || initialState.configureUrl,
        enablePendo: action.enablePendo || initialState.enablePendo,
        isManualAuth: action.isManualAuth || initialState.isManualAuth,
        tempDeviceId: action.tempDeviceId || initialState.tempDeviceId,
        metadata: action.metadata || initialState.metadata,
        locale: action.locale || initialState.locale,
      },
    };
  },
  [account.ACCOUNT_AUTH0_USER](state, action) {
    return {
      ...state,
      ...{
        userId: action.userId,
      },
    };
  },
});
