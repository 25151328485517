import {
  Button,
  Checkbox,
  MultiSelectFilterValue,
  StyledMultiSelectV2 as Select,
  styled,
} from '@kandji-inc/nectar-ui';
import * as React from 'react';
import {
  ADEListViewStore,
  useADEListViewStore,
} from '../store/ADEListViewStore';

export const ClearButton = styled('div', {
  color: '#35414E7A',
  fontWeight: 500,
  fontSize: 14,
});

export function handleTokenFilterChange(token: string, checked: boolean) {
  const { updateTokenFilters, tokenFilters } = ADEListViewStore.getState();

  let newTokenFilters = [...tokenFilters];

  if (checked) {
    newTokenFilters.push(token);
  } else {
    newTokenFilters = newTokenFilters.filter((t) => t !== token);
  }

  updateTokenFilters(newTokenFilters);
}

export interface ADETokenFilterProps {
  tokens: string[];
}

export function ADETokenFilterItem({ item, isSelected }) {
  return (
    <Select.ListBoxItem
      onAction={() => {
        handleTokenFilterChange(item.name, !isSelected);
      }}
      key={item.name}
      aria-label={item.name}
    >
      <Checkbox checked={isSelected} />
      {item.name}
    </Select.ListBoxItem>
  );
}

export function ADETokenFilter({ tokens }) {
  const { tokenFilters, updateTokenFilters } = useADEListViewStore((state) => ({
    tokenFilters: state.tokenFilters,
    updateTokenFilters: state.updateTokenFilters,
  }));
  const filtersApplied = tokenFilters.length > 0;

  return (
    <Select.Root placeholder="Token" aria-label="Token Filter Select">
      <Select.Button
        variant={filtersApplied ? 'filterApplied' : 'filter'}
        compact
      >
        <MultiSelectFilterValue title="Token" filters={tokenFilters} />
        <Select.TriggerIcon name="fa-angle-down-small" />
      </Select.Button>
      <Select.Popover offset={4}>
        <Select.ListBox
          selectionMode="multiple"
          selectionBehavior="toggle"
          css={{ maxHeight: 200, overflow: 'auto' }}
          items={tokens.map((token: string) => ({
            id: token,
            name: token,
          }))}
        >
          <>
            {tokens.map((token: string) => {
              const item = { id: token, name: token };

              return (
                <ADETokenFilterItem
                  item={item}
                  isSelected={tokenFilters.includes(item.name)}
                />
              );
            })}
            <Select.ListBoxItem
              data-testid="ade-token-clear"
              onAction={() => {
                updateTokenFilters([]);
              }}
            >
              <Button
                css={{
                  padding: 0,
                  background: 'none',
                  '&:hover': { background: 'none' },
                }}
                compact
                variant="subtle"
                disabled={!filtersApplied}
              >
                Clear
              </Button>
            </Select.ListBoxItem>
          </>
        </Select.ListBox>
      </Select.Popover>
    </Select.Root>
  );
}
