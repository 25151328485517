import { Button } from '@kandji-inc/bumblebee';
import deepcopy from 'deepcopy';
import EmptyBeehive from 'features/blueprints/assets/empty-beehive.svg';
import { blueprintService } from 'features/library-items/data-service/blueprint/blueprint-service';
import React, { useMemo, useState, useEffect, useContext } from 'react';
import { useHistory } from 'react-router';
import { ReactSVG } from 'react-svg';
import { usePermissions } from 'src/contexts/account';
import Loader from '../../../../theme/components/atoms/Loader';
import toaster from '../../../../theme/toaster';
import { apiTypes } from '../../../library-items/library/common';
import { paths } from '../../common';
import BlueprintActions from '../actions';
import BlueprintContext from '../blueprint.context';
import Filter from '../filter';
import BlueprintLibraryList from './blueprint-library-list';
import './blueprint-library.css';

const BlueprintLibrary = () => {
  const {
    blueprint,
    blueprintMessages,
    getBlueprintData,
    setActiveCategory,
    setCurrentFilteredItems,
  } = useContext(BlueprintContext);
  const history = useHistory();
  const [blueprintModel, setBlueprintModel] = useState({
    ...deepcopy(blueprint),
    beforeEditLibraryEnabledItems: deepcopy(blueprint.library_items),
  });
  const [currentFilter, setCurrentFilter] = useState({});
  const [isEditing, setIsEditing] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [currentFiltered, setCurrentFiltered] = useState();
  const permissions = usePermissions();

  useEffect(() => {
    if (blueprint) {
      setBlueprintModel({
        ...deepcopy(blueprint),
        beforeEditLibraryEnabledItems: deepcopy(blueprint.library_items),
      });
    }
  }, [blueprint]);

  const afterAction = (
    isEditing,
    isEnabled,
    revertToBeforeEdit,
    scrollToTopContainer,
  ) => {
    setIsEditing(isEditing);
    setCurrentFilter((prev) => ({ ...prev, enabledOnly: isEnabled }));

    if (revertToBeforeEdit) {
      setBlueprintModel({
        ...deepcopy(blueprint),
        beforeEditLibraryEnabledItems: deepcopy(blueprint.library_items),
      });
    }

    if (scrollToTopContainer) {
      setTimeout(() => {
        document
          .querySelector('.bl-blueprint-page__main')
          ?.scrollTo({ top: 0, behavior: 'smooth', block: 'start' });
      }, 100);
    }
  };

  const onSave = () => {
    setIsSaving(true);
    const libraryItems = blueprintModel.library_items;

    /* Chain these to try and avoid a race condition on the BE w/ Blueprints V1.
    https://kandji.atlassian.net/browse/LIT-1053 */
    blueprintService
      .setLibraryItems(blueprint.id, {
        library_item_ids: libraryItems.map(({ id }) => id),
      })
      .then(() => {
        toaster('Successfully saved Blueprint.');
        return getBlueprintData(blueprint.id)
          .then(() => afterAction(false, true, false, true))
          .catch(() =>
            toaster('An error occurred retrieving the updated Blueprint.'),
          )
          .finally(() => setIsSaving(false));
      })
      .catch(() => {
        toaster('An error occurred saving Blueprint Library Items.');
        setIsSaving(false);
      });
  };

  const onToggle = (item, isEnabled) => {
    const { singleBlueprintAllowed } = item.defaultConfiguration;

    setBlueprintModel((prev) => ({
      ...prev,
      library_items: isEnabled
        ? [
            ...prev.library_items.filter(
              (existItem) =>
                !singleBlueprintAllowed ||
                existItem.type !== item.type ||
                existItem.identifier !== item.identifier ||
                (existItem.type === apiTypes.AUTO_APP &&
                  existItem.managed_library_item_id !==
                    item.managed_library_item_id),
            ),
            item,
          ]
        : prev.library_items.filter(
            (enabledItem) => enabledItem.id !== item.id,
          ),
    }));
  };

  useEffect(() => {
    if (currentFiltered) {
      setCurrentFilteredItems(currentFiltered);
    }
  }, [currentFiltered]);

  const BlueprintLibList = useMemo(() => {
    const IsTermInField = (currentFilter, fieldValue) =>
      fieldValue
        .toLowerCase()
        .includes(currentFilter?.searchTerm.toLowerCase());
    const filteredBlueprint = {
      ...blueprintModel,
      categorical: blueprintModel.categorical
        .map(({ category, data, ...rest }) => ({
          ...rest,
          category,
          data: data
            .map((sub) => ({
              ...sub,
              category: sub.category,
              data: sub.data.filter(
                (item) =>
                  (!currentFilter.searchTerm ||
                    IsTermInField(currentFilter, item.name) ||
                    (item.instance_name &&
                      IsTermInField(currentFilter, item.instance_name))) &&
                  (currentFilter.isAllDevices ||
                    item.devices.some((itemDevice) =>
                      currentFilter.devices?.includes(itemDevice),
                    )) &&
                  (!currentFilter.enabledOnly ||
                    [
                      ...blueprintModel.beforeEditLibraryEnabledItems,
                      ...blueprintModel.library_items,
                    ].find((enabledItem) => item.id === enabledItem.id)),
              ),
            }))
            .filter(
              ({ data }) =>
                (!currentFilter.searchTerm &&
                  currentFilter.isAllDevices &&
                  !currentFilter.enabledOnly &&
                  isEditing) ||
                data.length,
            ),
        }))
        .filter(
          ({ data }) =>
            (!currentFilter.searchTerm &&
              currentFilter.isAllDevices &&
              !currentFilter.enabledOnly &&
              isEditing) ||
            data.length,
        ),
    };

    setCurrentFiltered(filteredBlueprint);

    if (!Object.keys(currentFilter).length) {
      return <Loader type={Loader.types.LINE} />;
    }

    if (
      !isEditing &&
      !blueprintModel.library_items.length &&
      currentFilter.enabledOnly
    ) {
      return (
        <div className="bl-blueprint-parameter-item b-flex-vg bl-blueprint-parameters__enable">
          <h2 className="b-h2">No Library Items enabled</h2>
          {permissions.canManageBlueprints && (
            <Button onClick={() => afterAction(true, false)}>
              Enable Library Items
            </Button>
          )}
        </div>
      );
    }

    if (!filteredBlueprint.categorical.length) {
      return (
        <div className="bl-blueprint-parameters__empty-filter">
          <ReactSVG className="b-mb" src={EmptyBeehive} />
          <h3 className="b-h3">No results found</h3>
          <p className="b-txt">
            Try changing the filter or search with different keywords.
          </p>
        </div>
      );
    }

    return (
      <BlueprintLibraryList
        isEditing={isEditing}
        beforeEditBlueprint={blueprint}
        blueprintMessages={blueprintMessages}
        blueprint={filteredBlueprint}
        onToggle={onToggle}
        onActiveCategory={setActiveCategory}
      />
    );
  }, [blueprintModel, currentFilter, isEditing]);

  return (
    <div className="bl-blueprint-library">
      <h1 className="b-h1 b-mt3 b-mb3">Blueprint Library Items</h1>
      <Filter
        className="b-mb2"
        searchFor="library"
        filter={currentFilter}
        onFilter={(filter) => setCurrentFilter(filter)}
      />
      {BlueprintLibList}
      <BlueprintActions
        isEditable={permissions.canManageBlueprints}
        isEditing={isEditing}
        isSaving={isSaving}
        onEdit={() => afterAction(true, false)}
        onCancel={() => afterAction(false, true, true, true)}
        onClose={() => history.push(paths.root)}
        onSave={onSave}
        actionsFor="Library Items"
        isTextEnable={!blueprintModel?.library_items?.length}
      />
    </div>
  );
};

export default BlueprintLibrary;
