/* istanbul ignore file - legacy code, moved */
import history from 'app/router/history';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import { CheckBox } from './SCEP.styles';
import {
  AddNew,
  CheckboxGrid,
  Dropdown,
  NotExist,
  ScrollWrapper,
  Search,
  Title,
} from './SCEPAssignment.styles';
import { SCEPSettingsContext } from './SCEPSettingsContext';

export const SCEPAssignmentBlueprintsDropdown = ({
  refList,
  refInput,
  allBlueprintNames,
  selectedClassicBlueprints,
  selectedAssignmentMaps,
  setSelectedBlueprints,
}) => {
  const { isEditingItem } = useContext(SCEPSettingsContext);

  const [topOffset, setTopOffset] = useState(null);
  const [searchText, setSearchText] = useState('');

  const allBlueprints = allBlueprintNames ? Object.keys(allBlueprintNames) : [];

  useEffect(() => {
    if (refInput.current) {
      setTopOffset(refInput.current.clientHeight);
    }
  }, [selectedClassicBlueprints]);

  const toggleBlueprint = (blueprint) => {
    if (selectedClassicBlueprints.includes(blueprint)) {
      setSelectedBlueprints([
        ...selectedClassicBlueprints.filter((item) => item !== blueprint),
        ...selectedAssignmentMaps,
      ]);
    } else {
      setSelectedBlueprints([
        ...selectedClassicBlueprints,
        blueprint,
        ...selectedAssignmentMaps,
      ]);
    }
  };

  if (!topOffset) {
    return null;
  }

  const filteredBlueprints = allBlueprints.filter((bp) =>
    allBlueprintNames[bp].toLowerCase().includes(searchText.toLowerCase()),
  );

  return (
    <Dropdown topOffset={topOffset} ref={refList}>
      <Search.SWrapper>
        <Search.SInput
          value={searchText}
          onChange={(e) => setSearchText(e.target.value)}
          placeholder="Search Blueprints..."
        />
      </Search.SWrapper>
      <ScrollWrapper>
        {isEmpty(filteredBlueprints) ? (
          <NotExist.NEWrapper>
            <NotExist.NEText>
              <AddNew.ANLink onClick={() => history.push('/blueprints/')}>
                + Add new
              </AddNew.ANLink>
            </NotExist.NEText>
          </NotExist.NEWrapper>
        ) : (
          <>
            {filteredBlueprints.map((blueprint) => (
              <CheckboxGrid
                onClick={() => isEditingItem && toggleBlueprint(blueprint)}
              >
                <CheckBox
                  id={`key-${blueprint}`}
                  type="checkbox"
                  checked={selectedClassicBlueprints.includes(blueprint)}
                />
                <Title
                  title={allBlueprintNames[blueprint]}
                  for={`key-${blueprint}`}
                >
                  {allBlueprintNames[blueprint]}
                </Title>
              </CheckboxGrid>
            ))}
            <AddNew.ANWrapper>
              <AddNew.ANText>
                <AddNew.ANLink onClick={() => history.push('/blueprints/')}>
                  + Add new
                </AddNew.ANLink>
              </AddNew.ANText>
            </AddNew.ANWrapper>
          </>
        )}
      </ScrollWrapper>
    </Dropdown>
  );
};

SCEPAssignmentBlueprintsDropdown.propTypes = {
  refList: PropTypes.objectOf(PropTypes.any),
  refInput: PropTypes.objectOf(PropTypes.any),
  allBlueprintNames: PropTypes.objectOf(PropTypes.any).isRequired,
  selectedClassicBlueprints: PropTypes.arrayOf(PropTypes.any).isRequired,
  selectedAssignmentMaps: PropTypes.arrayOf(PropTypes.string),
  setSelectedBlueprints: PropTypes.func.isRequired,
};

SCEPAssignmentBlueprintsDropdown.defaultProps = {
  refList: null,
  refInput: null,
  selectedAssignmentMaps: [],
};
