/* istanbul ignore file */
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import Blueprints from 'features/blueprints';
import Integrations from 'features/integrations/routes';
import LibraryItems from 'features/library-items/routes';
import React, { useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Route as BaseRoute, Redirect, Router, Switch } from 'react-router-dom';
import AgentEnrollmentAuthentication from 'src/agent/pages/enrollmentAuthentication';
import { EdrRouter } from 'src/features/edr/route';

import featureFlags, { useFlags } from 'src/config/feature-flags';
import { AccountContext, usePermissions } from 'src/contexts/account';
import { DataExportProvider } from 'src/contexts/visibility/data-export-context';
import AgentLogs from 'src/features/agent-logs/agent-logs';
import Visibility from 'src/features/visibility/prism/Visibility';
import {
  CreatePulseCheck,
  PulseCheckDetails,
} from 'src/features/visibility/pulse';
import { SearchTest } from 'src/pages/search-test';

import { getDevelopmentEnvironmentName, getEnv } from 'src/util';
import ADCSEnrollmentAuthentication from '../../adcs/pages/enrollmentAuthentication';
import { useAuthenticationRequired } from '../../auth0';
import MainWithAuthCheck from '../../components/Main';
import Whoops404 from '../../pages/404';
import AddDevices from '../../pages/add-devices';
import EnrollmentPortal from '../../pages/enroll';
import ForgotPassword from '../../pages/forgot-password';
import Registration from '../../pages/registration';
import ResetPassword from '../../pages/reset-password';
import Resources from '../../pages/resources';
import SettingsNew from '../../pages/settings-new'; // todo: cleanup name and routing after "Integrations" UI overhaul
import SignIn, { RedirectToAuth0SigninFlow } from '../../pages/signin';
import UserProfile from '../../pages/user-profile';
import { checkEnrollmentPortalStatus } from '../_actions/app';
import { links } from '../common/constants';

import ActivityTabNew from '../components/ActivityTabNew';
import AlertsList from '../components/AlertsList';
import ComputerList from '../components/ComputerList';
import { isIpad, isIphone, isSafari } from '../components/common/helpers';

import ThreatList from 'src/features/edr/threat';
import { VulnerabilitiesTable } from 'src/features/edr/vulnerability/all-vulnerabilities/vulnerabilities-table';
import TrialDashboard from 'src/features/trial-dashboard';
import EnrollComputerPage from '../components/EnrollmentPortal/EnrollComputerPage';
import EnrollPhonePage from '../components/EnrollmentPortal/EnrollPhonePage';
import EnrollTabletPage from '../components/EnrollmentPortal/EnrollTabletPage';
import EnrollWarningPage from '../components/EnrollmentPortal/EnrollWarningPage';
import GSuiteUserList from '../components/GSuiteUserList';
import SingleDevicePage from '../components/SingleDevicePage';
import GSuiteUserRecord from '../components/gSuiteUsers/GSuiteUserRecord';
import { LineLoader } from '../components/interface/LineLoader';
import UiSnackbar from '../components/interface/Snackbar';
import history from './history';

export const queryClient = new QueryClient({
  defaultOptions: {
    // More details: https://tanstack.com/query/v4/docs/react/guides/important-defaults
    queries: {
      refetchOnWindowFocus: false,
      retry: 1,
    },
  },
});

export const publicQueryClient = new QueryClient({
  defaultOptions: {
    // More details: https://tanstack.com/query/v4/docs/react/guides/important-defaults
    queries: {
      refetchOnWindowFocus: false,
      retry: 1,
    },
  },
});

const PublicRoute = (props) => <BaseRoute {...props} />;

const AuthenticatedRoute = ({
  component: Component,
  profile,
  shouldRender = true,
  redirectOnFail = '/devices',
  ...rest
}) => {
  useAuthenticationRequired({
    returnTo: () => window.location.pathname,
  });

  if (!shouldRender) {
    if (redirectOnFail) {
      return <Redirect to={redirectOnFail} />;
    }
    return null;
  }
  return (
    <BaseRoute
      {...rest}
      render={(props) => <Component {...props} profile={profile} />}
    />
  );
};

// settings routes i.e /my-company
const AdminRoute = ({ user, component: Component, ...rest }) => {
  useAuthenticationRequired({
    returnTo: () => window.location.pathname,
  });
  const permissions = usePermissions();

  return (
    <BaseRoute
      {...rest}
      render={(props) => {
        const { pathname } = props.location;
        if (
          pathname.indexOf('/my-company') > -1 &&
          !permissions.canManageSettings
        ) {
          return (
            <Redirect
              to={{ pathname: links.index, state: { from: props.location } }}
            />
          );
        }
        return <Component {...props} />;
      }}
    />
  );
};

const EnrollmentPortalRoute = ({ component: Component, ...rest }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [isReady, setIsReady] = useState(false);
  const clientId = useSelector(({ auth0 }) => auth0.clientId);

  useEffect(() => {
    if (clientId) {
      checkEnrollmentPortalStatus()
        .then(() => setIsReady(true))
        .catch((err) => console.log(err))
        .finally(() => setIsLoading(false));
    }
  }, [clientId]);
  if (isLoading || !clientId) {
    return <LineLoader />;
  }
  if (!isReady) {
    return <BaseRoute component={Whoops404} />;
  }
  if ((isIpad() || isIphone()) && !isSafari) {
    return <EnrollWarningPage />;
  }
  return <BaseRoute {...rest} render={(props) => <Component {...props} />} />;
};

export const Routes = ({ user }) => {
  const { currentCompany } = useContext(AccountContext);
  const {
    'visibility-fleet-attributes': ffVisibility,
    'visibility-pulse-checks': ffPulseChecks,
    'edr_041724_threats-v3': ffEdrThreatsV3,
    'bzbe-04122024-agent-log-ingestion': ffAgentLogs,
    'vm_06252024_vuln-poc': ffVuln,
    'rd-plg-trial-dashboard': ffTrialDashboard,
    'dc-exp-auth0-redirect-signin-flow': ffAuth0RedirectSigninFlow,
  } = useFlags([
    'visibility-fleet-attributes',
    'visibility-pulse-checks',
    'edr_041724_threats-v3',
    'bzbe-04122024-agent-log-ingestion',
    'vm_06252024_vuln-poc',
    'rd-plg-trial-dashboard',
  ]);
  const {
    feature_configuration,
    billing_type,
    onboarding = {},
  } = currentCompany;
  const { canViewPulse } = usePermissions();

  // const isTrial =
  //   billing_type === 'automated_trial' && Object.keys(onboarding).length > 0;
  const isTrial =
    billing_type === 'automated_trial' || billing_type === 'trial';

  return (
    <Switch>
      {/* Public Routes For Agent */}
      <PublicRoute
        path={links.agentEnrollment}
        component={AgentEnrollmentAuthentication}
      />

      {/* Public Routes For ADCS app */}
      <PublicRoute
        path={links.adcsEnrollment}
        component={ADCSEnrollmentAuthentication}
      />

      {/* Public Routes For Web App */}
      <PublicRoute
        path={links.signin}
        component={
          ffAuth0RedirectSigninFlow ? RedirectToAuth0SigninFlow : SignIn
        }
      />
      <PublicRoute path={links.registration} component={Registration} />
      <PublicRoute path={links.forgotPassword} component={ForgotPassword} />
      <PublicRoute path={links.resetPassword} component={ResetPassword} />

      {/* Enrollment Portal Routes */}
      <EnrollmentPortalRoute
        exact
        path="/enroll"
        component={EnrollmentPortal}
      />
      <EnrollmentPortalRoute
        path="/enroll/access-code/:accessCode"
        component={EnrollComputerPage}
      />
      <EnrollmentPortalRoute
        path="/enroll/phone/access-code/:accessCode"
        component={EnrollPhonePage}
      />
      <EnrollmentPortalRoute
        path="/enroll/tablet/access-code/:accessCode"
        component={EnrollTabletPage}
      />

      {/* Authenticated Routes */}
      <MainWithAuthCheck>
        <QueryClientProvider client={queryClient}>
          <DataExportProvider>
            <Switch>
              <AuthenticatedRoute
                exact
                path={[
                  ...(ffTrialDashboard && isTrial ? [links.index] : []),
                  links.trial,
                ]}
                shouldRender={ffTrialDashboard && isTrial}
                component={TrialDashboard}
              />
              <AuthenticatedRoute
                exact={!ffEdrThreatsV3}
                path={links.threat}
                component={ThreatList}
                shouldRender={!!feature_configuration?.edr?.enabled}
              />
              <AuthenticatedRoute
                path={links.vulnerability}
                component={VulnerabilitiesTable}
                shouldRender={
                  !!feature_configuration?.vulnerability_management?.enabled &&
                  ffVuln
                }
              />
              <AuthenticatedRoute
                exact
                path={[
                  ...(!(ffTrialDashboard && isTrial) ? [links.index] : []),
                  links.computers,
                  links.devices,
                  `${links.devices}/auto-enroll`,
                ]}
                component={ffVisibility ? Visibility : ComputerList}
              />
              <AuthenticatedRoute
                exact
                path={[links.pulse, `${links.pulse}/new`]}
                component={Visibility}
                shouldRender={canViewPulse && !!ffVisibility && !!ffPulseChecks}
              />
              <AuthenticatedRoute
                exact
                path={[`${links.pulse}/new/:pulseType`]}
                component={CreatePulseCheck}
                shouldRender={canViewPulse && !!ffVisibility && !!ffPulseChecks}
              />
              <AuthenticatedRoute
                exact
                path={[links.prism, `${links.prism}/:prismCategory`]}
                component={Visibility}
                shouldRender={!!ffVisibility}
              />
              <AuthenticatedRoute
                exact
                path={[`${links.pulse}/:pulseJobId`]}
                component={PulseCheckDetails}
                shouldRender={canViewPulse && !!ffVisibility && !!ffPulseChecks}
              />
              <AuthenticatedRoute
                exact
                path={[links.depDevices]}
                component={ComputerList}
              />
              <AuthenticatedRoute
                path={[
                  `${links.computers}/:id/:tab?`,
                  `${links.devices}/:id/:tab?`,
                ]}
                component={SingleDevicePage}
              />
              <AuthenticatedRoute path="/alerts/:tab?" component={AlertsList} />
              <AuthenticatedRoute path="/blueprints" component={Blueprints} />
              <AuthenticatedRoute path="/add-devices" component={AddDevices} />
              <AuthenticatedRoute
                path="/users/all/:user_id"
                component={GSuiteUserRecord}
              />
              <AuthenticatedRoute
                path="/users/:tab?"
                component={GSuiteUserList}
              />
              <AuthenticatedRoute
                path={links.activity}
                component={ActivityTabNew}
              />
              <AuthenticatedRoute
                path={links.integrations.root}
                component={Integrations}
              />
              <AuthenticatedRoute
                path="/user-profile"
                component={UserProfile}
              />
              <AuthenticatedRoute path="/library" component={LibraryItems} />
              <AdminRoute
                path="/my-company"
                user={user}
                component={SettingsNew}
              />
              <AuthenticatedRoute path="/search-test" component={SearchTest} />
              <AuthenticatedRoute
                path={[links.resources]}
                component={Resources}
              />

              {/* Dev Demo Only */}
              <AuthenticatedRoute
                exact
                path={[links.logs, `${links.logs}/events`]}
                component={AgentLogs}
                shouldRender={!!ffAgentLogs && getEnv() === 'dev'}
              />

              <AuthenticatedRoute component={Whoops404} />
            </Switch>

            <UiSnackbar />
            <ReactQueryDevtools
              initialIsOpen={false}
              buttonPosition="bottom-left"
            />
          </DataExportProvider>
        </QueryClientProvider>
      </MainWithAuthCheck>
    </Switch>
  );
};

const AuthRouter = () => {
  const user = useSelector(({ account }) => account.user);

  if (
    window.location.pathname === links.signup &&
    !getDevelopmentEnvironmentName()
  ) {
    window.location = 'https://kandji.io';
    return null;
  }

  return (
    <Router history={history}>
      <Routes user={user} />
    </Router>
  );
};

export default AuthRouter;
