import { useMutation } from '@tanstack/react-query';
import { newIntegrationsService } from 'src/features/integrations/data-service/new-integrations-service';

const useRefreshChannels = () =>
  useMutation({
    mutationFn: (integrationId: number) =>
      newIntegrationsService.refreshChannels(integrationId),
  });

export default useRefreshChannels;
