import React from 'react';

import { i18n } from 'i18n';

import Ticket from '../../../../generic/welcome-modal/ticket';
import singleDownloadIcon from '../../../assets/download-single.svg';
import kandjiDownloadIcon from '../../../assets/kandji-download.svg';

const DownloadSlide = () => (
  <div className="welcome-modal_first-slide">
    <img src={singleDownloadIcon} className="b-mb2" alt="header-icons" />
    <h1 className="b-h1 b-mb1">{i18n.t('Download AD CS connector')}</h1>
    <p className="b-txt-light">
      {i18n.$t('{element}', {
        element: (
          <>
            Once the download is complete, you will need to install the
            connector on your
            <br />
            Windows server machine.
          </>
        ),
      })}{' '}
      <a
        href="https://support.kandji.io/support/solutions/articles/72000568715-active-directory-certificate-services-ad-cs-integration-ad-cs-connector-installation"
        className="b-alink"
        target="_blank"
        rel="noreferrer"
      >
        {i18n.t('Learn more')}...
      </a>
    </p>
    <div className="bordered">
      <Ticket
        icon={kandjiDownloadIcon}
        text={i18n.t('Supports: Windows Server 2016 and above')}
        title={i18n.t('Kandji AD CS connector')}
      />
    </div>
  </div>
);

export default DownloadSlide;
