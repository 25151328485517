/* istanbul ignore file - writing tests for edges requires additional overhead in relation to the DOM and SVGs, revisit later [https://reactflow.dev/docs/guides/testing/] */
import React, { memo } from 'react';
import type { EdgeProps } from 'reactflow';
import { BaseEdge, EdgeLabelRenderer } from 'reactflow';

import { Flex } from '@kandji-inc/nectar-ui';

import { PlusButton } from '../components';
import { useRootEdgeClick } from '../hooks';
import useBlueprintFlow from '../store';
import { getDevicePathStyle } from '../theme';

function RootEdge(props: EdgeProps) {
  const { id, data, style = {} } = props;
  const [isEditingAssignments, { devicePath }, isDeletingNode] =
    useBlueprintFlow((state) => [
      state.isEditingAssignments,
      state.selectedDevice,
      state.isDeletingNode,
    ]);

  const { handleRootEdgeClick } = useRootEdgeClick(id);

  // The generated d3 path for the edge
  const path = data?.path;

  // Use the start and end points of the edge to calculate the middle x position to place the '+' button
  const startPoint = data?.startPoint;
  const endPoint = data?.endPoint;
  const middleX = startPoint?.x + (endPoint?.x - startPoint?.x) / 2;

  const isShowingDevicePath = !isEditingAssignments && devicePath;
  const isOnDevicePath = isShowingDevicePath && true;

  return (
    <>
      <BaseEdge
        id={id}
        path={path}
        style={{
          fill: 'none',
          stroke: 'var(--colors-neutral80)',
          strokeWidth: '1',
          ...style,
          ...(isShowingDevicePath && getDevicePathStyle(isOnDevicePath).edge),
        }}
      />

      {!isShowingDevicePath && (
        <EdgeLabelRenderer>
          <Flex
            className="nodrag nopan"
            css={{
              position: 'absolute',
              transform: `translate(-50%, -50%) translate(${middleX}px,${startPoint?.y}px)`,
              pointerEvents: isEditingAssignments ? 'all' : 'none',
              visibility: path ? 'visible' : 'hidden',
            }}
          >
            {!isDeletingNode && (
              <PlusButton
                onClick={handleRootEdgeClick}
                isDisabled={!isEditingAssignments}
              />
            )}
          </Flex>
        </EdgeLabelRenderer>
      )}
    </>
  );
}

export default memo(RootEdge);
