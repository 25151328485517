export function formatDeviceCount(deviceCount: number | null | undefined) {
  if (deviceCount == null) return '0';

  if (deviceCount < 1000000 && deviceCount >= 1000) {
    const thousands = (deviceCount / 1000).toFixed(1);
    return `${thousands}k`;
  }

  return deviceCount.toString();
}
