import React, { useEffect } from 'react';
import styled from 'styled-components';
import FooterButtons from '../../../components/footer-buttons';
import useModal from '../../../hooks/use-modal';
import msTeamsIcon from '../assets/ms-teams-icon.svg';

import { i18n } from 'i18n';
import { useMSTeamsAuth } from '../hooks';

const StyledSlideHeader = styled.h1`
  text-align: center;
  margin-bottom: 12px;
`;

const Container = styled.div`
  display: grid;
  grid-template-rows: 1fr auto;
  height: 100%;
`;

const SignIn = () => {
  const { closeModal } = useModal();
  const { data, getAuthorizationData, redirectToAuthUrl } = useMSTeamsAuth();
  const onClose = () => closeModal();
  const handleNext = () => redirectToAuthUrl();

  useEffect(() => {
    getAuthorizationData();
  }, []);

  return (
    <Container>
      <div className="welcome-modal_first-slide">
        <img src={msTeamsIcon} className="b-mb2" alt="header-icons" />
        <StyledSlideHeader>
          {i18n.t('Sign in with Microsoft Teams')}
        </StyledSlideHeader>
        <p className="b-txt-light">
          {i18n.t(
            'Configure the Microsoft Teams integration by signing in to your administrative account and allowing app permissions to establish the connection to Kandji.',
          )}
        </p>
      </div>
      <FooterButtons
        onClose={onClose}
        handleNext={handleNext}
        nextButtonDisabled={!data}
        nextButtonText={i18n.t('Sign in with Microsoft')}
      />
    </Container>
  );
};

export default SignIn;
