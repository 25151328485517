import { createColumnHelper } from '@tanstack/react-table';
/* istanbul ignore file */
import type { z } from 'zod';

import { getIconName } from '../../components/PrismNav/prismNavUtils';
import {
  YES_NO_CELL_FILTER_OPTIONS,
  getFilteredDisabled as filterDisabled,
  getSpecificDeviceFamilyCell,
} from '../column-utils';
import { getScharedColumns } from './getSharedColumns';

export const getAppsColumns = ({
  appsSchema,
}): {
  columnDefs: any[];
  pinnedColumns: string[];
  alwaysHiddenColumns: string[];
} => {
  const { properties } = appsSchema.schema;
  const appsColumnHelper = createColumnHelper<z.infer<typeof properties>>();

  const columnDefs = [
    appsColumnHelper.accessor((row) => row['apps.name'], {
      id: 'apps.name',
      meta: {
        displayName: properties.name.title,
        displayIcon: getIconName(appsSchema.uri),
        filterType: 'string',
        filterIcon: 'text-size',
        filterDisabled,
      },
    }),

    appsColumnHelper.accessor((row) => row['apps.bundle_id'], {
      id: 'apps.bundle_id',
      meta: {
        displayName: properties.bundle_id.title,
        displayIcon: getIconName(appsSchema.uri),
        filterType: 'string',
        filterIcon: 'text-size',
        filterDisabled,
      },
    }),

    appsColumnHelper.accessor((row) => row['apps.version'], {
      id: 'apps.version',
      meta: {
        displayName: properties.version.title,
        displayIcon: getIconName(appsSchema.uri),
        filterType: 'version',
        filterIcon: 'hashtag',
        filterDisabled,
      },
    }),

    appsColumnHelper.accessor((row) => row['apps.path'], {
      id: 'apps.path',
      cell: (info) => getSpecificDeviceFamilyCell(info, ['Mac'], 'code'),
      meta: {
        displayName: properties.path.title,
        displayIcon: getIconName(appsSchema.uri),
        filterType: 'string',
        filterIcon: 'text-size',
        filterDisabled,
      },
    }),

    appsColumnHelper.accessor((row) => row['apps.obtained_from'], {
      id: 'apps.obtained_from',
      cell: (info) => getSpecificDeviceFamilyCell(info, ['Mac']),
      meta: {
        displayName: properties.obtained_from.title,
        displayIcon: getIconName(appsSchema.uri),
        defaultHidden: true,
        filterType: 'enum',
        filterIcon: 'list-check',
        filterDisabled,
      },
    }),

    appsColumnHelper.accessor((row) => row['apps.bundle_size'], {
      id: 'apps.bundle_size',
      cell: (info) =>
        getSpecificDeviceFamilyCell(
          info,
          ['iPad', 'AppleTV', 'iPhone'],
          'bytes',
        ),
      meta: {
        displayName: properties.bundle_size.title,
        displayIcon: getIconName(appsSchema.uri),
        defaultHidden: true,
        filterType: 'number',
        filterIcon: 'hashtag',
        filterDisabled,
      },
    }),

    appsColumnHelper.accessor((row) => row['apps.short_version'], {
      id: 'apps.short_version',
      meta: {
        displayName: properties.short_version.title,
        displayIcon: getIconName(appsSchema.uri),
        defaultHidden: true,
        filterType: 'version',
        filterIcon: 'hashtag',
        filterDisabled,
      },
    }),

    appsColumnHelper.accessor((row) => row['apps.signature'], {
      id: 'apps.signature',
      cell: (info) => getSpecificDeviceFamilyCell(info, ['Mac']),
      meta: {
        displayName: properties.signature.title,
        displayIcon: getIconName(appsSchema.uri),
        defaultHidden: true,
        filterType: 'string',
        filterIcon: 'text-size',
        filterDisabled,
      },
    }),

    appsColumnHelper.accessor((row) => row['apps.ad_hoc_code_signed'], {
      id: 'apps.ad_hoc_code_signed',
      cell: (info) =>
        getSpecificDeviceFamilyCell(
          info,
          ['iPad', 'AppleTV', 'iPhone'],
          'boolean',
        ),
      meta: {
        displayName: properties.ad_hoc_code_signed.title,
        displayIcon: getIconName(appsSchema.uri),
        defaultHidden: true,
        filterType: 'boolean',
        filterIcon: 'circle-dot',
        filterOptions: [...YES_NO_CELL_FILTER_OPTIONS],
        filterDisabled,
      },
    }),

    appsColumnHelper.accessor((row) => row['apps.app_store_vendable'], {
      id: 'apps.app_store_vendable',
      cell: (info) =>
        getSpecificDeviceFamilyCell(
          info,
          ['iPad', 'AppleTV', 'iPhone'],
          'boolean',
        ),
      meta: {
        displayName: properties.app_store_vendable.title,
        displayIcon: getIconName(appsSchema.uri),
        defaultHidden: true,
        filterType: 'boolean',
        filterIcon: 'circle-dot',
        filterOptions: [...YES_NO_CELL_FILTER_OPTIONS],
        filterDisabled,
      },
    }),

    appsColumnHelper.accessor((row) => row['apps.beta_app'], {
      id: 'apps.beta_app',
      cell: (info) =>
        getSpecificDeviceFamilyCell(
          info,
          ['iPad', 'AppleTV', 'iPhone'],
          'boolean',
        ),
      meta: {
        displayName: properties.beta_app.title,
        displayIcon: getIconName(appsSchema.uri),
        defaultHidden: true,
        filterType: 'boolean',
        filterIcon: 'circle-dot',
        filterOptions: [...YES_NO_CELL_FILTER_OPTIONS],
        filterDisabled,
      },
    }),

    appsColumnHelper.accessor((row) => row['apps.device_based_vpp'], {
      id: 'apps.device_based_vpp',
      cell: (info) =>
        getSpecificDeviceFamilyCell(
          info,
          ['iPad', 'AppleTV', 'iPhone'],
          'boolean',
        ),
      meta: {
        displayName: properties.device_based_vpp.title,
        displayIcon: getIconName(appsSchema.uri),
        defaultHidden: true,
        filterType: 'boolean',
        filterIcon: 'circle-dot',
        filterOptions: [...YES_NO_CELL_FILTER_OPTIONS],
        filterDisabled,
      },
    }),

    appsColumnHelper.accessor((row) => row['apps.download_canceled'], {
      id: 'apps.download_canceled',
      cell: (info) =>
        getSpecificDeviceFamilyCell(
          info,
          ['iPad', 'AppleTV', 'iPhone'],
          'boolean',
        ),
      meta: {
        displayName: properties.download_canceled.title,
        displayIcon: getIconName(appsSchema.uri),
        defaultHidden: true,
        filterType: 'boolean',
        filterIcon: 'circle-dot',
        filterOptions: [...YES_NO_CELL_FILTER_OPTIONS],
        filterDisabled,
      },
    }),

    appsColumnHelper.accessor((row) => row['apps.download_failed'], {
      id: 'apps.download_failed',
      cell: (info) =>
        getSpecificDeviceFamilyCell(
          info,
          ['iPad', 'AppleTV', 'iPhone'],
          'boolean',
        ),
      meta: {
        displayName: properties.download_failed.title,
        displayIcon: getIconName(appsSchema.uri),
        defaultHidden: true,
        filterType: 'boolean',
        filterIcon: 'circle-dot',
        filterOptions: [...YES_NO_CELL_FILTER_OPTIONS],
        filterDisabled,
      },
    }),

    appsColumnHelper.accessor((row) => row['apps.download_paused'], {
      id: 'apps.download_paused',
      cell: (info) =>
        getSpecificDeviceFamilyCell(
          info,
          ['iPad', 'AppleTV', 'iPhone'],
          'boolean',
        ),
      meta: {
        displayName: properties.download_paused.title,
        displayIcon: getIconName(appsSchema.uri),
        defaultHidden: true,
        filterType: 'boolean',
        filterIcon: 'circle-dot',
        filterOptions: [...YES_NO_CELL_FILTER_OPTIONS],
        filterDisabled,
      },
    }),

    appsColumnHelper.accessor((row) => row['apps.download_waiting'], {
      id: 'apps.download_waiting',
      cell: (info) =>
        getSpecificDeviceFamilyCell(
          info,
          ['iPad', 'AppleTV', 'iPhone'],
          'boolean',
        ),
      meta: {
        displayName: properties.download_waiting.title,
        displayIcon: getIconName(appsSchema.uri),
        defaultHidden: true,
        filterType: 'boolean',
        filterIcon: 'circle-dot',
        filterOptions: [...YES_NO_CELL_FILTER_OPTIONS],
        filterDisabled,
      },
    }),

    appsColumnHelper.accessor((row) => row['apps.dynamic_size'], {
      id: 'apps.dynamic_size',
      cell: (info) =>
        getSpecificDeviceFamilyCell(
          info,
          ['iPad', 'AppleTV', 'iPhone'],
          'bytes',
        ),
      meta: {
        displayName: properties.dynamic_size.title,
        displayIcon: getIconName(appsSchema.uri),
        defaultHidden: true,
        filterType: 'number',
        filterIcon: 'hashtag',
        filterDisabled,
      },
    }),

    appsColumnHelper.accessor(
      (row) => row['apps.external_version_identifier'],
      {
        id: 'apps.external_version_identifier',
        cell: (info) =>
          getSpecificDeviceFamilyCell(info, ['iPad', 'AppleTV', 'iPhone']),
        meta: {
          displayName: properties.external_version_identifier.title,
          displayIcon: getIconName(appsSchema.uri),
          defaultHidden: true,
          filterType: 'version',
          filterIcon: 'hashtag',
          filterDisabled,
        },
      },
    ),

    appsColumnHelper.accessor((row) => row['apps.has_update_available'], {
      id: 'apps.has_update_available',
      cell: (info) =>
        getSpecificDeviceFamilyCell(
          info,
          ['iPad', 'AppleTV', 'iPhone'],
          'boolean',
        ),
      meta: {
        displayName: properties.has_update_available.title,
        displayIcon: getIconName(appsSchema.uri),
        defaultHidden: true,
        filterType: 'boolean',
        filterIcon: 'circle-dot',
        filterOptions: [...YES_NO_CELL_FILTER_OPTIONS],
        filterDisabled,
      },
    }),

    appsColumnHelper.accessor((row) => row['apps.installing'], {
      id: 'apps.installing',
      cell: (info) =>
        getSpecificDeviceFamilyCell(
          info,
          ['iPad', 'AppleTV', 'iPhone'],
          'boolean',
        ),
      meta: {
        displayName: properties.installing.title,
        displayIcon: getIconName(appsSchema.uri),
        defaultHidden: true,
        filterType: 'boolean',
        filterIcon: 'circle-dot',
        filterOptions: [...YES_NO_CELL_FILTER_OPTIONS],
        filterDisabled,
      },
    }),

    appsColumnHelper.accessor((row) => row['apps.app_clip'], {
      id: 'apps.app_clip',
      cell: (info) =>
        getSpecificDeviceFamilyCell(
          info,
          ['iPad', 'AppleTV', 'iPhone'],
          'boolean',
        ),
      meta: {
        displayName: properties.app_clip.title,
        displayIcon: getIconName(appsSchema.uri),
        defaultHidden: true,
        filterType: 'boolean',
        filterIcon: 'circle-dot',
        filterOptions: [...YES_NO_CELL_FILTER_OPTIONS],
        filterDisabled,
      },
    }),

    appsColumnHelper.accessor((row) => row['apps.validated'], {
      id: 'apps.validated',
      cell: (info) =>
        getSpecificDeviceFamilyCell(
          info,
          ['iPad', 'AppleTV', 'iPhone'],
          'boolean',
        ),
      meta: {
        displayName: properties.validated.title,
        displayIcon: getIconName(appsSchema.uri),
        defaultHidden: true,
        filterType: 'boolean',
        filterIcon: 'circle-dot',
        filterOptions: [...YES_NO_CELL_FILTER_OPTIONS],
        filterDisabled,
      },
    }),

    appsColumnHelper.accessor((row) => row['apps.executable'], {
      id: 'apps.executable',
      cell: (info) => getSpecificDeviceFamilyCell(info, ['Mac']),
      meta: {
        displayName: properties.executable.title,
        displayIcon: getIconName(appsSchema.uri),
        defaultHidden: true,
        filterType: 'string',
        filterIcon: 'text-size',
        filterDisabled,
      },
    }),

    appsColumnHelper.accessor((row) => row['apps.bundle_name'], {
      id: 'apps.bundle_name',
      cell: (info) => getSpecificDeviceFamilyCell(info, ['Mac']),
      meta: {
        displayName: properties.bundle_name.title,
        displayIcon: getIconName(appsSchema.uri),
        defaultHidden: true,
        filterType: 'string',
        filterIcon: 'text-size',
        filterDisabled,
      },
    }),

    appsColumnHelper.accessor((row) => row['apps.bundle_type'], {
      id: 'apps.bundle_type',
      meta: {
        displayName: properties.bundle_type.title,
        displayIcon: getIconName(appsSchema.uri),
        defaultHidden: true,
        filterType: 'string',
        filterIcon: 'text-size',
        filterDisabled,
      },
    }),

    appsColumnHelper.accessor((row) => row['apps.environment_variables'], {
      id: 'apps.environment_variables',
      cell: (info) => getSpecificDeviceFamilyCell(info, ['Mac']),
      meta: {
        displayName: properties.environment_variables.title,
        displayIcon: getIconName(appsSchema.uri),
        defaultHidden: true,
        filterType: 'string',
        filterIcon: 'text-size',
        filterDisabled,
      },
    }),

    appsColumnHelper.accessor((row) => row['apps.compiler'], {
      id: 'apps.compiler',
      cell: (info) => getSpecificDeviceFamilyCell(info, ['Mac']),
      meta: {
        displayName: properties.compiler.title,
        displayIcon: getIconName(appsSchema.uri),
        defaultHidden: true,
        filterType: 'string',
        filterIcon: 'text-size',
        filterDisabled,
      },
    }),

    appsColumnHelper.accessor((row) => row['apps.bundle_region'], {
      id: 'apps.bundle_region',
      cell: (info) => getSpecificDeviceFamilyCell(info, ['Mac']),
      meta: {
        displayName: properties.bundle_region.title,
        displayIcon: getIconName(appsSchema.uri),
        defaultHidden: true,
        filterType: 'string',
        filterIcon: 'text-size',
        filterDisabled,
      },
    }),

    appsColumnHelper.accessor((row) => row['apps.bundle_display_name'], {
      id: 'apps.bundle_display_name',
      cell: (info) => getSpecificDeviceFamilyCell(info, ['Mac']),
      meta: {
        displayName: properties.bundle_display_name.title,
        displayIcon: getIconName(appsSchema.uri),
        defaultHidden: true,
        filterType: 'string',
        filterIcon: 'text-size',
        filterDisabled,
      },
    }),

    appsColumnHelper.accessor((row) => row['apps.info'], {
      id: 'apps.info',
      cell: (info) => getSpecificDeviceFamilyCell(info, ['Mac']),
      meta: {
        displayName: properties.info.title,
        displayIcon: getIconName(appsSchema.uri),
        defaultHidden: true,
        filterType: 'string',
        filterIcon: 'text-size',
        filterDisabled,
      },
    }),

    appsColumnHelper.accessor((row) => row['apps.minimum_system_version'], {
      id: 'apps.minimum_system_version',
      cell: (info) => getSpecificDeviceFamilyCell(info, ['Mac']),
      meta: {
        displayName: properties.minimum_system_version.title,
        displayIcon: getIconName(appsSchema.uri),
        defaultHidden: true,
        filterType: 'version',
        filterIcon: 'hashtag',
        filterDisabled,
      },
    }),

    appsColumnHelper.accessor((row) => row['apps.category'], {
      id: 'apps.category',
      cell: (info) => getSpecificDeviceFamilyCell(info, ['Mac']),
      meta: {
        displayName: properties.category.title,
        displayIcon: getIconName(appsSchema.uri),
        defaultHidden: true,
        filterType: 'string',
        filterIcon: 'text-size',
        filterDisabled,
      },
    }),

    appsColumnHelper.accessor((row) => row['apps.apple_scriptable'], {
      id: 'apps.apple_scriptable',
      cell: (info) => getSpecificDeviceFamilyCell(info, ['Mac'], 'boolean'),
      meta: {
        displayName: properties.apple_scriptable.title,
        displayIcon: getIconName(appsSchema.uri),
        defaultHidden: true,
        filterType: 'boolean',
        filterIcon: 'circle-dot',
        filterOptions: [...YES_NO_CELL_FILTER_OPTIONS],
        filterDisabled,
      },
    }),

    appsColumnHelper.accessor((row) => row['apps.copyright'], {
      id: 'apps.copyright',
      cell: (info) => getSpecificDeviceFamilyCell(info, ['Mac']),
      meta: {
        displayName: properties.copyright.title,
        displayIcon: getIconName(appsSchema.uri),
        defaultHidden: true,
        filterType: 'string',
        filterIcon: 'text-size',
        filterDisabled,
      },
    }),

    appsColumnHelper.accessor((row) => row['apps.developer_name'], {
      id: 'apps.developer_name',
      cell: (info) => getSpecificDeviceFamilyCell(info, ['Mac']),
      meta: {
        displayName: properties.developer_name.title,
        displayIcon: getIconName(appsSchema.uri),
        defaultHidden: true,
        filterType: 'string',
        filterIcon: 'text-size',
        filterDisabled,
      },
    }),

    appsColumnHelper.accessor((row) => row['apps.team_id'], {
      id: 'apps.team_id',
      cell: (info) => getSpecificDeviceFamilyCell(info, ['Mac']),
      meta: {
        displayName: properties.team_id.title,
        displayIcon: getIconName(appsSchema.uri),
        defaultHidden: true,
        filterType: 'string',
        filterIcon: 'text-size',
        filterDisabled,
      },
    }),

    ...getScharedColumns(appsSchema),
  ];

  return { columnDefs, pinnedColumns: [], alwaysHiddenColumns: [] };
};
