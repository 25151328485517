import React from 'react';

import { Button } from '@kandji-inc/bumblebee';

const RedownloadButton = ({ onClick, buttonText }) => (
  <Button className="redownload" kind="link" onClick={onClick}>
    {buttonText}
  </Button>
);

export default RedownloadButton;
