import { useCallback, useEffect, useRef, useState } from 'react';

/**
 *
 * @param {Array<string>} events a list of events that should trigger the re-render
 * @param {Object=window} eventEmitter the node which events are to be listened
 * @param {boolean=false} auto should the trigger be turned on automatically when the eventEmitter is rendered
 * @return {{turnOff: function, turnOn: function}}
 */
function useReRenderTrigger(
  events,
  { eventEmitter = window, auto = false } = {},
) {
  const [, setTrigger] = useState(Symbol('trigger'));

  const animationRequestRef = useRef(null);

  const onEvent = useCallback(() => {
    const callTrigger = () => setTrigger(Symbol('trigger'));
    cancelAnimationFrame(animationRequestRef.current);
    animationRequestRef.current = requestAnimationFrame(callTrigger);
  }, []);

  function turnOn() {
    if (eventEmitter) {
      events.forEach((ev) => eventEmitter.addEventListener(ev, onEvent));
    }
  }

  function turnOff() {
    if (eventEmitter) {
      events.forEach((ev) => eventEmitter.removeEventListener(ev, onEvent));
    }
  }

  useEffect(() => {
    if (auto) {
      turnOn();
    }

    return () => {
      turnOff();
    };
  }, [eventEmitter]);

  return { turnOn, turnOff };
}

export default useReRenderTrigger;
