import React, { useContext } from 'react';

import { Context } from './context';
import FormGoogleApps from './form-google-apps';
import FormHome from './form-home';
import FormSAMLP from './form-samlp';
import FormWAAD from './form-waad';
import slug from './slug';

export default () => {
  const { wizard } = useContext(Context);

  const { strategy } = wizard;

  switch (strategy) {
    case slug.GOOGLE_APPS:
      return <FormGoogleApps />;
    case slug.SAMLP:
      return <FormSAMLP />;
    case slug.WAAD:
      return <FormWAAD />;
    default:
      return <FormHome />;
  }
};
