import styled from 'styled-components';

export const Row = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: ${(props) => (props.align ? props.align : 'center')};
  ${(props) =>
    props.spacing &&
    `
    margin: 0 -${props.spacing / 2}px;
    & > * {
      margin: 0 ${props.spacing / 2}px;
    }  
  `}
`;

export const ImageIcon = styled('img')`
  background: transparent;
  width: ${(props) => (props.size ? props.size : 50)}px;
  height: ${(props) => (props.size ? props.size : 50)}px;
  background-size: cover !important;
  background-repeat: no-repeat !important;
`;
