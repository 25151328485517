import { useMemo } from 'react';
import useListIntegrations from 'src/features/integrations/components-for-specific-types/hooks/use-list-integrations';
import { INT_TYPES } from 'src/features/integrations/constants';

interface BaseIntegrationItem {
  type: string;
}

type ListApiData<Item> =
  | {
      data: { results: Item[] };
    }
  | undefined;

type MsdcConfigurationStatus = 'loading' | 'configured' | 'unconfigured';

function selectIntegrationList<IntegrationItem extends BaseIntegrationItem>(
  apiData: ListApiData<IntegrationItem>,
): readonly IntegrationItem[] {
  return apiData?.data?.results || [];
}

export function useMsdcConfigurationStatus() {
  const {
    data: integrationList,
    isLoading,
    isSuccess,
    ...restQueryResult
  } = useListIntegrations({
    integrationType: INT_TYPES.msDeviceCompliance,
    initialData: {
      data: { results: [] },
    },
    select: selectIntegrationList,
  });

  const msdcConfigStatus = useMemo<MsdcConfigurationStatus>(() => {
    if (isLoading) {
      return 'loading';
    }

    const foundIntegration = Boolean(
      integrationList.find(
        (integration: { type: string }) =>
          integration.type === INT_TYPES.msDeviceCompliance,
      ),
    );

    if (isSuccess && foundIntegration) {
      return 'configured';
    }

    return 'unconfigured';
  }, [isLoading, isSuccess, integrationList]);

  return {
    msdcConfigStatus,
    isLoading,
    isSuccess,
    ...restQueryResult,
  };
}
