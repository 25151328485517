import { Banner } from '@kandji-inc/bumblebee';
import React from 'react';

const ErrorBanner = () => (
  <Banner theme="error" icon="circle-xmark" kind="normal">
    <p>
      <strong>Download failed.</strong> Please try again.{' '}
    </p>
  </Banner>
);

export default ErrorBanner;
