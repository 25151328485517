/* istanbul ignore file */

import React from 'react';

import { i18n } from 'i18n';
import { formatTime } from '../../common/helpers';

const entry = ({ details }) => {
  const { server_name } = details;

  return {
    icon: 'pencil',
    iconText: i18n.t('AD CS server updated'),
    text: i18n.t('AD CS server updated'),
    description: server_name,
  };
};

const detail = ({ action_type, details }, { connectorList }) => {
  const { connector_assignment, server_name, updated_by, updated_on } = details;

  const { name, bound_domain } =
    connectorList?.find(({ id }) => id === connector_assignment) || {};

  return (
    <div className={`activity-entry-details ${action_type}`}>
      <p>Updated on: {formatTime(updated_on)}</p>
      <p>Updated by: {updated_by}</p>
      <p>Server name: {server_name}</p>
      <p>
        Connector assignment:{' '}
        {name ||
          bound_domain ||
          `${i18n.t('Connector details are pending')}...`}
      </p>
    </div>
  );
};

export default { entry, detail };
