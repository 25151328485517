import {
  Button,
  Flex,
  Icon,
  TextField,
  styled,
  useDialog,
} from '@kandji-inc/nectar-ui';
import type {
  QueryObserverResult,
  RefetchOptions,
} from '@tanstack/react-query';
import type {
  ColumnOrderState,
  SortingState,
  Table,
  VisibilityState,
} from '@tanstack/react-table';
import * as React from 'react';
import { InterfaceContext } from 'src/contexts/interface';
import PrismColumnEditor from 'src/features/visibility/prism/components/PrismTable/PrismColumnEditor/PrismColumnEditor';
import { getColumnDefinitionDefaults } from 'src/features/visibility/prism/components/PrismTable/utils/tableUtils';
import { useADEListViewStore } from '../store/ADEListViewStore';
import { ADEExportTable } from './ADEExportTable';
import { ADEFetchDevices } from './ADEFetchDevices';
import { ADETableColumnIds, type Device } from './ADEListViewTableColumns';
import { ADEStatusFilter } from './ADEStatusFilter';
import { ADETokenFilter } from './ADETokenFilter';

const ADEListViewControlsContainer = styled('div', {
  padding: '$2 $5',
});
const ADEListViewSearch = styled('div');
const ADEListViewSearchDropdown = styled('div');
const ADEListViewButtons = styled('div', {
  display: 'flex',
  marginLeft: 'auto',
  gap: 4,
});

export interface ADEListViewControlsProps {
  table: Table<any>;
  isAllDevices: boolean;
  lastSync: number;
  columnOrder: ColumnOrderState;
  columnVisibility: VisibilityState;
  setSearch: React.Dispatch<React.SetStateAction<string>>;
  tokens: string[];
  sorting: SortingState;
  sync: (
    options?: RefetchOptions,
  ) => Promise<QueryObserverResult<Device[], Error>>;
  refetchDevices: (
    options?: RefetchOptions,
  ) => Promise<QueryObserverResult<Device[], Error>>;
}

export function ADEListViewControls({
  table,
  isAllDevices,
  columnOrder,
  columnVisibility,
  setSearch,
  tokens,
  sync,
  refetchDevices,
  lastSync,
  sorting,
}: ADEListViewControlsProps) {
  const { sidebarOpened } = React.useContext(InterfaceContext);
  const { setVisibleColumns } = useADEListViewStore((state) => ({
    setVisibleColumns: state.setVisibleColumns,
  }));
  const [exportDialog, toggleExportDialog] = useDialog();

  React.useEffect(() => {
    const visibleColumns = table
      .getVisibleLeafColumns()
      .filter((column) => column.id !== ADETableColumnIds.SELECTED)
      .map((column) => {
        if (column.id === ADETableColumnIds.ENROLLMENT_STATUS) {
          return 'mdm_device_id';
        }

        return column.id;
      });

    setVisibleColumns(visibleColumns);
  }, [table, columnOrder, columnVisibility]);

  const handleUpdateColumns = ({
    columnOrder: order,
    columnVisibility: visibility,
  }: {
    columnOrder: ColumnOrderState;
    columnVisibility: VisibilityState;
  }) => {
    table.setColumnOrder(order);
    table.setColumnVisibility(visibility);
  };

  const columnDefinitionDefaults = () =>
    getColumnDefinitionDefaults(table.options.columns, null);

  const allColumns = table
    .getAllColumns()
    .filter((column) => column.id !== 'selected');

  return (
    <ADEListViewControlsContainer>
      <ADEExportTable
        isAllDevices={isAllDevices}
        isOpen={exportDialog}
        toggleDialog={toggleExportDialog}
        sorting={sorting}
      />
      <Flex alignItems="center">
        <ADEListViewSearch>
          <Flex alignItems="center" gap="xs">
            <TextField
              aria-label="Search Input"
              autoComplete="off"
              iconLeft
              compact
              placeholder="Search"
              onChange={(e) => setSearch(e.target.value)}
              value={null}
              icon="magnifying-glass"
            />

            {tokens.length > 1 && <ADETokenFilter tokens={tokens} />}
            {isAllDevices && <ADEStatusFilter />}

            <ADEListViewSearchDropdown />
          </Flex>
        </ADEListViewSearch>
        <ADEListViewButtons>
          <ADEFetchDevices
            lastSync={lastSync}
            sync={sync}
            refetchDevices={refetchDevices}
          />
          <PrismColumnEditor
            title={!isAllDevices ? 'Awaiting Enrollment' : 'All Devices'}
            columns={allColumns}
            columnOrder={columnOrder}
            handleUpdateColumns={handleUpdateColumns}
            sidebarOpened={sidebarOpened}
            columnVisibility={columnVisibility}
            columnDefaults={columnDefinitionDefaults()}
            trigger={(props) => (
              <Button
                compact
                variant="subtle"
                css={{
                  padding: 6,
                }}
                {...props}
              >
                <Icon name="table-columns" size="sm" />
              </Button>
            )}
          />
          <Button
            compact
            variant="subtle"
            css={{
              padding: 6,
            }}
            onClick={toggleExportDialog}
          >
            <Icon name="file-arrow-down" size="sm" />
          </Button>
        </ADEListViewButtons>
      </Flex>
    </ADEListViewControlsContainer>
  );
}
