import React from 'react';
import { Progress } from 'react-sweet-progress';

const ProgressBar = ({ downloadProgress, progressText }) => (
  <div className="b-progress">
    <p className="b-txt-bold">{progressText}</p>
    <Progress percent={downloadProgress} />
  </div>
);

export default ProgressBar;
