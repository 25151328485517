/* istanbul ignore file */
import { Box, Button, Flex, TextField } from '@kandji-inc/nectar-ui';
import { registerUser, validateRegistrationToken } from 'app/_actions/app';
import { links } from 'app/common/constants';
import classNames from 'classnames';
import { i18n } from 'i18n';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { setSnackbar as callSetSnackbar } from '../app/_actions/ui';
import Auth0Login from '../app/components/common/Auth0Login';
import history from '../app/router/history';
import stackedLogo from '../assets/img/new_icons/kandji_logo_stacked_dark.svg';

const queryString = require('query-string');

const SetPasswordForm = ({
  onChange,
  handleSubmit,
  onFocus,
  onFocusConfirm,
  onBlur,
  agree,
  password,
  usedField,
  isSubmitting,
  password2,
}) => {
  const isDisabled =
    !(
      agree &&
      password &&
      password2 &&
      password === password2 &&
      password.length >= 8
    ) || isSubmitting;
  return (
    <>
      <Flex mt5 justifyContent="center" flow="column" gap="xl">
        <Box>
          <TextField
            label={i18n.t('Password')}
            type="password"
            name="password"
            id="password"
            autoComplete="email"
            required
            onInput={onChange}
            onChange={onChange}
            value={password}
            onFocus={onFocus}
            onBlur={onBlur}
          />
        </Box>

        <Box>
          <TextField
            label={i18n.t('Confirm Password')}
            type="password"
            name="password2"
            id="password2"
            autoComplete="off"
            required
            onChange={onChange}
            value={password2}
            onFocus={onFocusConfirm}
            onBlur={onBlur}
          />

          <small className="form-text c-dark-red text-center mb-3 text-capitalize d-flex flex-column m-auto">
            {!!(agree && password) && (
              <>
                {!!(password2 && password !== password2) && (
                  <span>{i18n.t('Passwords should match')}</span>
                )}
                {password.length < 8 && (
                  <span>
                    {i18n.t(
                      'Password should contain at least 8 characters or digits',
                    )}
                  </span>
                )}
              </>
            )}
          </small>
        </Box>
      </Flex>

      <Flex mt5 justifyContent="end" className="form-row">
        <Button variant="primary" onClick={handleSubmit} disabled={isDisabled}>
          {isSubmitting ? i18n.t('Sending...') : i18n.t('Register')}
        </Button>
      </Flex>
    </>
  );
};

export class Registration extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      isSubmitting: false,
      agree: false,
      isValid: false,
      password: '',
      password2: '',
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  componentDidMount() {
    const { location } = this.props;
    const queryParams = queryString.parse(location.search);
    const token = get(queryParams, 'token');

    document.body.classList.add('white-bg');

    if (token) {
      this.setState({ isLoading: true }, () =>
        Promise.all([validateRegistrationToken(token)])
          .then(([dataToken]) => {
            this.setState({
              isValid: true,
              ...dataToken,
            });
          })
          .catch(() => this.setState({ isValid: false }))
          .finally(() => this.setState({ isLoading: false })),
      );
    }
  }

  componentWillUnmount() {
    document.body.classList.remove('white-bg');
  }

  componentDidUpdate() {
    const { agree, billing_type } = this.state;

    const isCustomer = billing_type === 'customer';

    if (isCustomer && agree === false) {
      this.setState({ agree: true });
    }
  }

  handleSubmit = () => {
    const { password } = this.state;
    const { setSnackbar, location } = this.props;
    this.setState({ isSubmitting: true });
    const queryParams = queryString.parse(location.search);
    const token = get(queryParams, 'token');

    registerUser({ password, token })
      .then(() => {
        this.setState({ isSubmitting: false }, () => {
          history.push(links.signin);
        });
      })
      .catch(() =>
        this.setState({ isSubmitting: false }, () =>
          setSnackbar(i18n.common.error()),
        ),
      );
  };

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  render() {
    const {
      isLoading,
      billing_type,
      email,
      company_name: companyName,
      role,
      first_name: firstName,
      last_name: lastName,
      password2,
      password,
      usedField,
      agree,
      isSubmitting,
      isValid,
    } = this.state;

    const { gotAuth0Creds } = this.props;

    const isCustomer = billing_type === 'customer';

    if (isLoading) {
      return <div data-loading="true" style={{ minHeight: 250 }} />;
    }
    return (
      <div className="sign-in container m-auto">
        <div className="mb-5 mt-5 text-center">
          <img
            src={stackedLogo}
            className="img-responsive center-block"
            alt="Kandji App"
          />
        </div>

        {!isValid && (
          <div className="d-flex flex-column signin-options">
            <div className="registration-title text-center mb-5">
              {i18n.t(
                'This account has passed the allowed invitation window. Please ask your account owner to resend your invitation to Kandji.',
              )}
            </div>
            <button
              className="btn btn-tertiary col-10 offset-1 col-sm-10 offset-sm-1 col-md-8 offset-md-2 col-sm-6 offset-lg-3 col-xl-4 offset-xl-4 m-auto"
              onClick={() => history.push('/signin')}
            >
              {i18n.t('To login page')}
            </button>
          </div>
        )}

        {isValid && (
          <>
            <div className="heading mb-5">
              <h2>{i18n.t('Create your Kandji account')}</h2>
            </div>

            <div className="row">
              <div className="col-5 offset-col-1 col-sm-6 col-md-6 col-lg-3 offset-lg-1 col-xl-3 offset-xl-1">
                <div className="d-flex flex-column">
                  <div className="d-flex flex-row">
                    <div className="ml-auto">
                      <strong>{i18n.t('Name:')}</strong>
                    </div>
                  </div>
                  <div className="d-flex flex-row">
                    <div className="ml-auto">
                      <strong>{i18n.t('Email Address:')}</strong>
                    </div>
                  </div>
                  <div className="d-flex flex-row">
                    <div className="ml-auto">
                      <strong>{i18n.t('Company:')}</strong>
                    </div>
                  </div>
                  <div className="d-flex flex-row">
                    <div className="ml-auto">
                      <strong>{i18n.t('Access Type:')}</strong>
                    </div>
                  </div>
                </div>
              </div>

              <div className="mr-auto">
                <div className="d-flex flex-column">
                  <div className="d-flex flex-row">
                    <div className="">{`${firstName} ${lastName}`}</div>
                  </div>
                  <div className="d-flex flex-row">
                    <div className="mr-auto">{email}</div>
                  </div>
                  <div className="d-flex flex-row">
                    <div className="mr-auto">{companyName}</div>
                  </div>
                  <div className="d-flex flex-row">
                    <div className="mr-auto">{role}</div>
                  </div>
                </div>
              </div>
            </div>

            {!isCustomer && (
              <div className="acknowledge-agree row pt-3 col-10 offset-1 mb-1">
                <label
                  style={{ paddingTop: '10px' }}
                  className="d-flex align-items-center m-auto"
                  htmlFor="agree"
                >
                  <input
                    id="agree"
                    type="checkbox"
                    onClick={() => this.setState({ agree: !agree })}
                    className="checkbox-agree mr-2"
                  />
                  <span>
                    {i18n.ut(
                      `I acknowledge that I agree to the <a href="https://www.kandji.io/terms" target="blank" class="mr-1"><u>Terms of Use</u></a>and have read the <a href="https://www.kandji.io/privacy" target="blank"><u>Privacy Policy</u></a>`,
                    )}
                  </span>
                </label>
              </div>
            )}

            <div className="signin-options">
              <div className="row">
                <div className="col-sm-8 offset-sm-2 col-md-6 offset-md-0 col-lg-5 offset-lg-0 col-xl-4 offset-xl-1 d-flex flex-column justify-content-center">
                  <Auth0Login
                    disabled={!gotAuth0Creds || !agree}
                    text={i18n.t('Single Sign-On')}
                  />
                </div>
                <div className="col-sm-8 offset-sm-2 col-md-1 offset-md-0 col-lg-2 offset-lg-0 col-xl-2 offset-xl-0">
                  <span className="or">{i18n.t('OR')}</span>
                </div>
                <div
                  className="col-sm-8 offset-sm-2 col-md-5 offset-md-0 col-lg-5 col-xl-4 d-flex flex-column justify-content-center"
                  data-loading={!!isLoading}
                >
                  <SetPasswordForm
                    onChange={this.onChange}
                    handleSubmit={this.handleSubmit}
                    onFocus={() => this.setState({ usedField: 'password' })}
                    onFocusConfirm={() =>
                      this.setState({ usedField: 'password2' })
                    }
                    onBlur={() => this.setState({ usedField: null })}
                    agree={agree}
                    password={password}
                    password2={password2}
                    usedField={usedField}
                    isSubmitting={isSubmitting}
                  />
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    );
  }
}

const mapStateToProps = ({ auth0 }) => ({
  gotAuth0Creds: !!auth0.clientId,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setSnackbar: callSetSnackbar,
    },
    dispatch,
  );

Registration.propTypes = {
  location: PropTypes.object.isRequired,
  gotAuth0Creds: PropTypes.bool.isRequired,
  setSnackbar: PropTypes.func.isRequired,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Registration),
);
