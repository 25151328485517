import React from 'react';

import { object } from 'prop-types';

import { Button } from '@kandji-inc/bumblebee';

import OptionsMenu from 'features/util/components/options-menu';

const Menu = ({ menu }) => (
  <OptionsMenu render={() => menu}>
    <Button className="k-action" kind="link" icon="ellipsis" />
  </OptionsMenu>
);

Menu.propTypes = {
  menu: object.isRequired,
};

export default Menu;
