import { styled } from '@kandji-inc/nectar-ui';
import React from 'react';
import type { CommonProps, PageTicketProps } from '../types/integrator.types';
import ViewCheckbox from './partials/view-checkbox';
import type { CheckboxOption } from './partials/view-checkbox';
import ViewHeader from './partials/view-header';
import ViewHeaderH2 from './partials/view-header-h2';
import ViewTickets from './partials/view-tickets';

import {
  SpaceContainer,
  StyledContainer,
} from '../../../components/styled-components/main';

const StyledBodyContainer = styled(SpaceContainer, {
  width: '100%',
});

const StyledHeader = styled('div', {
  paddingBottom: '$5',
});

export interface OneColumnCheckboxFullProps<InputType, StateType>
  extends CommonProps<InputType, StateType> {
  layout: 'one-column-checkbox-full';
  header: {
    title: string;
    description: string;
    link?: {
      text: string;
      url: string;
    };
  };
  body: {
    title: string;
    description: string;
    options: Array<CheckboxOption>;
  };
  tickets?: {
    title?: string;
    description?: string;
    list: Array<PageTicketProps>;
  };
  isProcessing?: boolean;
}

const OneColumnCheckboxFull = <InputType, StateType>({
  logo,
  header,
  tickets,
  body,
  isProcessing,
}: OneColumnCheckboxFullProps<InputType, StateType>) => (
  <StyledContainer>
    <ViewHeader data={{ logo, ...header }} />
    {tickets && <ViewTickets data={tickets} />}

    <StyledBodyContainer>
      <StyledHeader>
        <ViewHeaderH2 title={body.title} description={body.description} />
      </StyledHeader>

      <ViewCheckbox options={body.options} />
    </StyledBodyContainer>
  </StyledContainer>
);
export default OneColumnCheckboxFull;
