import { newIntegrationsService } from 'src/features/integrations/data-service/new-integrations-service';

export async function callChannelRefresh(
  integrationId: number,
): Promise<{ refresh_task_end_time: string; refresh_task_start_time: string }> {
  try {
    const response =
      await newIntegrationsService.getRefreshChannelsStatus(integrationId);
    const { data } = response;
    if (data.refresh_task_end_time) {
      return data;
    }
    await new Promise((resolve) => setTimeout(resolve, 1000));
    return callChannelRefresh(integrationId);
  } catch (e) {
    console.log(e);

    await new Promise((resolve) => setTimeout(resolve, 1000));
    callChannelRefresh(integrationId);
    return Promise.reject();
  }
}
