import { type UseQueryOptions, useQuery } from '@tanstack/react-query';
import { msDeviceComplianceService } from '../services';
import { msDeviceComplianceKeys } from './query-key-factory';

const useGetIntegrationDetails = (
  id: string,
  options?: { queryOptions?: Partial<UseQueryOptions> },
) => {
  const queryOptions = options?.queryOptions ?? {};
  return useQuery({
    queryFn: () => msDeviceComplianceService.getIntegrationDetails(id),
    queryKey: msDeviceComplianceKeys.integrationDetails(id),
    enabled: !!id,
    ...queryOptions,
  });
};

export default useGetIntegrationDetails;
