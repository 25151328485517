import { node, string } from 'prop-types';
import React from 'react';
import { formatTime } from 'src/app/components/common/helpers';
import { ACTION_NAME_MAPPING, NOTIFICATION_SHAPE } from '../../util';
import './expanded-notification-row.styles.scss';
import { i18n } from 'i18n';

function DetailsRow({ title, description }) {
  return (
    <div className="expanded-row_details__row">
      <div className="b-txt-bold">{title}</div>
      <div className="b-txt-light">{description}</div>
    </div>
  );
}

DetailsRow.propTypes = {
  title: string.isRequired,
  description: node.isRequired,
};

function ExpandedNotificationRow({ item }) {
  const { actions, created_by, created_at } = item;

  return (
    <div className="expanded-row_details">
      <DetailsRow
        title={i18n.t('Created by')}
        description={`${created_by.first_name} ${created_by.last_name}`}
      />
      <DetailsRow
        title={i18n.t('Created on')}
        description={formatTime(created_at)}
      />
      <DetailsRow
        title={i18n.t('Event triggers')}
        description={actions.map((action) => (
          <p style={{ margin: 0 }} key={action}>
            {ACTION_NAME_MAPPING[action]}
          </p>
        ))}
      />
    </div>
  );
}

ExpandedNotificationRow.propTypes = {
  item: NOTIFICATION_SHAPE.isRequired,
};

export default ExpandedNotificationRow;
