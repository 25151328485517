import {
  Breadcrumb,
  Button,
  Tabs,
  Toaster as toaster,
} from '@kandji-inc/bumblebee';
import { useQueryClient } from '@tanstack/react-query';
import { i18n } from 'i18n';
import React, { useState } from 'react';
import { links } from 'src/app/common/constants';
import history from 'src/app/router/history';
import SummaryCard from 'src/features/integrations/components/integration-details/summary-card';
import ImgWithAlt from 'src/features/util/components/img-with-alt';
import styled from 'styled-components';
import {
  CardContainer,
  CardSection,
  CardTitle,
  StyledOptionsMenu,
} from '../../../components/styled-components';
import { useModal as useMSTeamsAccountModal } from '../../adcs/hooks';
import msTeamsMediumIcon from '../assets/medium-icon.svg';
import msTeamsIcon from '../assets/ms-teams-icon.svg';
import {
  useDeleteMSTeamIntegrations,
  useDeleteMSTeamsIntegration,
  useGetMSTeamsIntegrations,
} from '../hooks';
import useDeleteNotification from '../hooks/use-delete-notification';
import MSTeamsModal from '../modals';
import AccountDeleteModal from './delete-account-modal/delete-account-modal';
import IntegrationDeleteModal from './integration-delete-modal/integration-delete-modal';
import IntegrationTable from './integration-table';
import MSTeamsPanel from './ms-teams-panel';
import NotificationDeleteModal from './notification-delete-modal/notification-delete-modal';

const StyledTabs = styled(Tabs)`
  margin-top: 17px;
  margin-bottom: var(--b-gap3);
`;

const Container = styled.div`
  display: grid;
  gap: 32px;
`;

const MSTeamsDetails = () => {
  const deleteIntegration = useDeleteMSTeamsIntegration();
  const deleteIntegrations = useDeleteMSTeamIntegrations();
  const deleteNotification = useDeleteNotification();
  const [isSidePanelOpen, setIsSidePanelOpen] = useState(false);
  const [sidePanelData, setSidePanelData] = useState({});
  const [isAdding, setIsAdding] = useState(false);
  const [isNotificationDeleteModalOpen, setIsNotificationDeleteModalOpen] =
    useState(false);
  const [isIntegrationDeleteModalOpen, setIsIntegrationDeleteModalOpen] =
    useState(false);
  const [deletionModalData, setDeletionModalData] = useState({});
  const [isAccountDeleteModalOpen, setIsAccountDeleteModalOpen] =
    useState(false);
  const [deleteAccountData, setDeleteAccountData] = useState({});

  const { openModal } = useMSTeamsAccountModal();

  const { data: integrationsData, refetch: refetchIntegrations } =
    useGetMSTeamsIntegrations();

  const TITLE = i18n.t('Microsoft Teams');
  const isLastIntegration = integrationsData?.data?.integrations?.length < 2;

  const handleOpenNotificationEditor = (item, integrationId) => {
    setIsSidePanelOpen(true);
    setSidePanelData({ ...item, integrationId });
  };

  const handleOpenAddNewNotificationPanel = (integrationId) => {
    setIsSidePanelOpen(true);
    setIsAdding(true);
    setSidePanelData({ integrationId });
  };

  const handlePanelToggle = () => {
    if (isAdding) {
      setIsAdding(false);
    }
    setIsSidePanelOpen((prev) => !prev);
  };

  const handleDeleteNotification = async (id, name) => {
    await deleteNotification.mutateAsync(id);
    if (isSidePanelOpen) {
      handlePanelToggle();
    }
    if (isNotificationDeleteModalOpen) {
      setIsNotificationDeleteModalOpen(false);
    }
    await refetchIntegrations();
    toaster(i18n.t('{name} has been deleted.', { name }));
  };

  const handleAddTeamAccount = () => {
    openModal('msTeams');
  };

  const queryClient = useQueryClient();

  return (
    <>
      <Breadcrumb
        crumbs={[
          { url: links.integrations.root, title: i18n.t('Integrations') },
          { title: TITLE },
        ]}
        classes={{ wrapper: 'b-mt3 b-mb2' }}
      />
      <SummaryCard
        imgSrc={msTeamsIcon}
        title={TITLE}
        description={i18n.t(
          'Set up and manage existing Teams accounts, channels and event notifications for Microsoft Teams. ',
        )}
        onDeleteIntegration={() => setIsIntegrationDeleteModalOpen(true)}
        onAddTeamAccount={handleAddTeamAccount}
      />

      <StyledTabs tabs={[{ label: i18n.t('Event notifications') }]}>
        <Container>
          {integrationsData?.data?.integrations?.map((data) => (
            <CardContainer key={data.integration_id}>
              <CardTitle>
                <ImgWithAlt
                  alt="integration-icon"
                  src={[data.icon, msTeamsMediumIcon]}
                />
                <h4 className="b-h4 b-mr b-ml-tiny">{data.tenant_name}</h4>
                <div className="b-txt-light">{data.tenant_domain}</div>
                <StyledOptionsMenu
                  render={(close) => (
                    <>
                      {/* TODO: implement reauthenticate - INT-932 */}
                      {/* {reAuthIsAvailable && ( */}
                      {/* <Button
                        icon='arrows-rotate'
                        kind='link'
                        onClick={() => {
                          close();
                        }}
                      >
                        Reauthenticate
                      </Button> */}
                      {/* )} */}
                      <Button
                        icon="trash-can"
                        theme="error"
                        kind="link"
                        onClick={() => {
                          close();
                          setIsAccountDeleteModalOpen(true);
                          setDeleteAccountData(data);
                        }}
                      >
                        {i18n.t('Delete account')}
                      </Button>
                    </>
                  )}
                >
                  <div
                    data-testid={`card-title:ellipsis-btn:${data.integration_id}`}
                  >
                    <Button icon="ellipsis" kind="link" />
                  </div>
                </StyledOptionsMenu>
              </CardTitle>

              <IntegrationTable
                data={data}
                openNotificationEditor={handleOpenNotificationEditor}
                onDeleteNotificationFromTable={(tableData) => {
                  setIsNotificationDeleteModalOpen(true);
                  setDeletionModalData(tableData);
                }}
              />

              <CardSection>
                <Button
                  icon="circle-plus"
                  kind="link"
                  onClick={() =>
                    handleOpenAddNewNotificationPanel(data.integration_id)
                  }
                  className="cmn-button--size-medium"
                >
                  {i18n.t('Add event notification')}
                </Button>
              </CardSection>
            </CardContainer>
          ))}

          {isSidePanelOpen && (
            <MSTeamsPanel
              isVisible={isSidePanelOpen}
              isAdding={isAdding}
              data={sidePanelData}
              handlePanelToggle={handlePanelToggle}
              onDeleteNotification={(id, name) =>
                handleDeleteNotification(id, name)
              }
              getMSTeamsIntegrations={refetchIntegrations}
              data-testid="ms-teams-panel"
            />
          )}

          <NotificationDeleteModal
            isOpen={isNotificationDeleteModalOpen}
            data={deletionModalData}
            onCancel={() => setIsNotificationDeleteModalOpen(false)}
            onDelete={() => {
              const { id, name } = deletionModalData;
              handleDeleteNotification(id, name);
            }}
          />

          {integrationsData?.data?.integrations.length && (
            <IntegrationDeleteModal
              isOpen={isIntegrationDeleteModalOpen}
              data={integrationsData}
              onCancel={() => setIsIntegrationDeleteModalOpen(false)}
              onDelete={() =>
                integrationsData?.data?.integrations.forEach((integration) => {
                  deleteIntegrations.mutate(integration.integration_id, {
                    onSuccess: () => {
                      queryClient.invalidateQueries(['msTeamsIntegrations']);
                      toaster(i18n.t('Microsoft Teams integration deleted.'));
                      history.push('/integrations');
                    },
                  });
                })
              }
            />
          )}

          <AccountDeleteModal
            data={deleteAccountData}
            isOpen={isAccountDeleteModalOpen}
            isLastIntegration={isLastIntegration}
            onCancel={() => {
              setIsAccountDeleteModalOpen(false);
            }}
            onDelete={(integrationId) =>
              deleteIntegration.mutate(integrationId)
            }
          />

          <MSTeamsModal />
        </Container>
      </StyledTabs>
    </>
  );
};

export default MSTeamsDetails;
