import type { MultiSelectValue } from '@kandji-inc/nectar-ui';
import {
  Badge,
  FilterButton,
  Flex,
  MultiSelect,
  Text,
} from '@kandji-inc/nectar-ui';
import React from 'react';

import { activityTypeLists } from 'src/app/common/constants';

type ActivityTypeFilterProps = {
  name: string;
  // options: SelectOptionMemberList<AllowedSelectValue, 'value', 'label'>;
  value: MultiSelectValue<string>;
  onChange: (value: any) => void;
  onClear: () => void;
  filtersSelectedCount: number;
};

const ActivityTypeFilter = (props: ActivityTypeFilterProps) => {
  const { name, value, onChange, onClear, filtersSelectedCount } = props;

  const activityTypeOptions = activityTypeLists.blueprint
    .filter(
      (opt) =>
        opt.value !== 'ALL' && Boolean(opt.value) && !/note/gi.test(opt.label),
    )
    .sort((a, b) => a.label.localeCompare(b.label));

  const activityTypeOptionsByValue = activityTypeOptions.reduce(
    (acc, opt) => ({ ...acc, [opt.value]: opt.label }),
    {},
  );

  return (
    <MultiSelect
      multi
      // @ts-expect-error -- activityTypeLists allows blank properties, but we filter them out above
      options={activityTypeOptions}
      value={value}
      onChange={onChange}
      footer={{
        showClear: true,
        clearLabel: 'Clear',
        handleClear: onClear,
      }}
      css={{
        maxWidth: '300px',
      }}
    >
      <FilterButton
        filtersSelected={Boolean(filtersSelectedCount)}
        showRemove={false}
        aria-label="frameworks"
      >
        <Flex flow="row" alignItems="center">
          <Text>{name}</Text>
          {filtersSelectedCount === activityTypeOptions.length && (
            <Flex flow="row" gap="xs">
              <Text>: </Text>
              <Text css={{ fontWeight: '$medium' }}>{' All'}</Text>
            </Flex>
          )}
          {filtersSelectedCount !== activityTypeOptions.length &&
            filtersSelectedCount > 0 && (
              <>
                <Text>:&nbsp;</Text>
                <Text
                  css={{
                    fontWeight: '$medium',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  }}
                >
                  {/* Only show the first value, since we don't have room to show more: */}
                  {activityTypeOptionsByValue[value[0]]}
                </Text>
                {filtersSelectedCount > 1 && (
                  <Badge
                    compact
                    css={{
                      color: '$neutral0',
                      backgroundColor: '$blue50',
                      marginLeft: '$1',
                    }}
                  >
                    +{filtersSelectedCount - 1}
                  </Badge>
                )}
              </>
            )}
        </Flex>
      </FilterButton>
    </MultiSelect>
  );
};

export default ActivityTypeFilter;
