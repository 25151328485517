import {
  Badge,
  Button,
  DropdownMenu,
  Flex,
  Heading,
  Loader,
  Tabs,
  Toaster_UNSTABLE as Toaster,
} from '@kandji-inc/nectar-ui';
import * as React from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { i18n } from 'i18n';
import type {
  ImperativePanelGroupHandle,
  ImperativePanelHandle,
} from 'react-resizable-panels';
import { useFlags } from 'src/config/feature-flags';
import { usePermissions } from 'src/contexts/account';
import { InterfaceContext } from 'src/contexts/interface';
import useAdjustSidebarChatBubble from 'src/features/util/hooks/use-adjust-sidebar-chat-bubble';
import ComputerList from '../../../app/components/ComputerList';
import ManageTags from '../../tags/ManageTags';
import PulseRoot from '../pulse/views/PulseRoot';
import PrismViews from '../views/PrismViews';
import { PrismViewsProvider } from '../views/contexts/PrismViewsContext';
import { ChatWindow } from './ai';
import { Panel, PanelGroup } from './components/panel-helpers';
import { PrismAIProvider } from './contexts/PrismAIContext';
import { PrismProvider } from './contexts/PrismContext';
import { PrismUrlProvider } from './contexts/PrismUrlContext';
import { useLastVisitedPrismCategory } from './hooks/use-last-visited-prism-category';
import { ResizableHandle } from './move-to-nectar/resizable';
import { getVisibilityTabId } from './utils/tabsUtils';
import PrismCategories from './views/PrismCategories';

const NARROW_CHAT_WINDOW_WIDTH = 40;
const FULL_CHAT_WINDOW_WIDTH = 80;

export const deviceSubsections = {
  Devices: () => i18n.t('Devices'),
  Prism: () => i18n.t('Prism'),
  Pulse: () => i18n.t('Pulse'),
};

const Visibility = () => {
  const { push, listen } = useHistory();
  const location = useLocation();
  const lastVisited = useLastVisitedPrismCategory();
  const [isAiChatWindowOpen, setIsAiChatWindowOpen] = React.useState(false);
  const [isManageTagsOpen, setIsManageTagsOpen] = React.useState(false);
  const [chatPanelSize, setChatPanelSize] = React.useState(0);
  const panelGroupRef = React.useRef<ImperativePanelGroupHandle>(null);
  const chatPanelRef = React.useRef<ImperativePanelHandle>(null);

  // istanbul ignore next
  const handleButtonClick = () => {
    if (chatPanelRef.current) {
      const currentSize = chatPanelRef.current.getSize();
      if (currentSize === 0) {
        chatPanelRef.current.resize(NARROW_CHAT_WINDOW_WIDTH);
      } else {
        chatPanelRef.current.collapse();
      }
    }
  };

  // istanbul ignore next
  const toggleChatPanelWidth = () => {
    if (chatPanelRef.current) {
      const currentSize = chatPanelRef.current.getSize();
      if (currentSize === NARROW_CHAT_WINDOW_WIDTH) {
        chatPanelRef.current.resize(FULL_CHAT_WINDOW_WIDTH);
      } else {
        chatPanelRef.current.resize(NARROW_CHAT_WINDOW_WIDTH);
      }
    }
  };

  // istanbul ignore next
  React.useEffect(() => {
    const unlisten = listen(({ pathname }) => {
      if (!pathname.includes('prism') && chatPanelRef.current) {
        chatPanelRef.current.collapse();
      }
    });

    return unlisten;
  }, [listen]);

  const {
    'visibility-device-list': ffDeviceList,
    'visibility-pulse-checks': ffPulseChecks,
    'visibility-fleet-attributes': ffVisPhase1,
    'data-team_20240409_llm-chatbot-mvp': ffChatbotMVP,
  } = useFlags([
    'visibility-device-list',
    'visibility-pulse-checks',
    'visibility-fleet-attributes',
    'data-team_20240409_llm-chatbot-mvp',
  ]);

  // istanbul ignore next
  const chatPanelWidth = React.useMemo(() => {
    const panelGroupEl = document.querySelector(
      `[data-panel-group][data-panel-group-id="${panelGroupRef.current?.getId()}"]`,
    );
    if (!panelGroupEl) {
      return 0;
    }
    return panelGroupEl.getBoundingClientRect().width * (chatPanelSize / 100);
  }, [chatPanelSize]);

  // istanbul ignore next
  useAdjustSidebarChatBubble(
    ffDeviceList
      ? { right: isAiChatWindowOpen ? chatPanelWidth : 0, bottom: 64 }
      : { bottom: 0 },
  );

  const layoutOverridesForNewDeviceList = {
    tabs: {
      marginLeft: '24px',
    },
    container: {
      margin: '0 calc(var(--k-main-layout-side-padding) * -1) -48px',
    },
    content: {
      paddingLeft: '24px',
      paddingRight: '24px',
    },
  };

  const permissions = usePermissions();

  const visibilityTabs = React.useMemo(() => {
    const visibilityTabs = [
      {
        label: ffDeviceList ? 'Views' : deviceSubsections.Devices(),
        tabId: 'devices',
        onClick: () => push(`/devices`),
        css: ffDeviceList ? layoutOverridesForNewDeviceList.tabs : undefined,
      },
      {
        label: (
          <Flex alignItems="center" gap="xs">
            {deviceSubsections.Prism()}
            {!ffVisPhase1 && (
              <Badge
                compact
                icon="sparkles"
                color={location.pathname.includes('prism') ? 'blue' : 'neutral'}
                css={{ alignSelf: 'center' }}
              >
                {i18n.t('Preview')}
              </Badge>
            )}
          </Flex>
        ),
        tabId: 'prism',
        onClick: () => push(`/devices/prism/${lastVisited}`),
      },
    ];

    // istanbul ignore next
    if (ffPulseChecks && permissions.canViewPulse) {
      visibilityTabs.push({
        label: deviceSubsections.Pulse(),
        tabId: 'pulse',
        onClick: () => push(`/devices/pulse`),
      });
    }

    return visibilityTabs;
  }, [
    ffPulseChecks,
    ffVisPhase1,
    ffDeviceList,
    lastVisited,
    location.pathname,
    permissions.canViewPulse,
    push,
  ]);

  /* istanbul ignore next */
  const showPanels = ffChatbotMVP && location.pathname.includes('prism');

  const { bannerTopOffset } = React.useContext(InterfaceContext);
  // istanbul ignore next
  const bannerHeight = bannerTopOffset ?? 0;
  /* istanbul ignore next */
  const shouldAdjustStickyElements =
    bannerHeight > 0 && !location.pathname.includes('prism');

  return (
    <PrismUrlProvider>
      <PrismAIProvider
        value={{
          onOpenChange: handleButtonClick,
          isOpen: isAiChatWindowOpen,
          featureEnabled: ffChatbotMVP,
          chatPanelSize,
          isFullWidth: chatPanelSize === FULL_CHAT_WINDOW_WIDTH,
          toggleWidth: toggleChatPanelWidth,
        }}
      >
        <Flex
          flow="column"
          css={{
            height: `calc(100vh - ${bannerHeight}px)`,
            ...(ffDeviceList ? layoutOverridesForNewDeviceList.container : {}),
          }}
        >
          <PanelGroup groupRef={panelGroupRef} canResize={showPanels}>
            <Panel canResize={showPanels} defaultSize={100}>
              <Flex
                justifyContent="space-between"
                alignItems="center"
                css={{
                  ...(ffDeviceList
                    ? layoutOverridesForNewDeviceList.content
                    : {}),
                  zIndex: 35,
                  background: /* istanbul ignore next */ [
                    'prism',
                    'pulse',
                  ].some((s) => location.pathname.includes(s) || ffDeviceList)
                    ? '$neutral0'
                    : '#eff0f5',
                }}
              >
                <Heading
                  as="h2"
                  size="1"
                  css={{
                    pb: '16px',
                    pt: '32px',
                  }}
                >
                  {i18n.t('Devices')}
                </Heading>
                <DropdownMenu
                  withArrow={false}
                  contentProps={{
                    align: 'end',
                  }}
                  options={[
                    {
                      label: i18n.t('Manage tags'),
                      onClick: /* istanbul ignore next */ () =>
                        setIsManageTagsOpen(!isManageTagsOpen),
                    },
                  ]}
                  css={{
                    zIndex: 46,
                  }}
                >
                  <Button compact icon={{ name: 'ellipsis' }} />
                </DropdownMenu>
              </Flex>
              <Tabs.Container
                tabId={getVisibilityTabId(location.pathname)}
                tabs={visibilityTabs}
                css={{
                  [`& ${Tabs.Styled.Group}`]: {
                    position: 'sticky',
                    top: /* istanbul ignore next */ shouldAdjustStickyElements
                      ? 88 + bannerHeight
                      : 88,
                  },
                }}
              >
                <Tabs.Content tabId="prism">
                  <PrismProvider>
                    <PrismCategories />
                  </PrismProvider>
                </Tabs.Content>
                <Tabs.Content tabId="pulse">
                  {permissions.canViewPulse ? (
                    <PulseRoot />
                  ) : (
                    <PermissionDenied />
                  )}
                </Tabs.Content>
                <Tabs.Content tabId="devices">
                  {ffDeviceList ? (
                    <PrismViewsProvider>
                      <PrismViews />
                    </PrismViewsProvider>
                  ) : (
                    <ComputerList />
                  )}
                </Tabs.Content>
              </Tabs.Container>
            </Panel>
            {
              /* istanbul ignore next */ showPanels ? (
                <ResizableHandle disabled={true} />
              ) : null
            }
            <Panel
              canResize={showPanels}
              defaultSize={0}
              maxSize={80}
              minSize={20}
              collapsible
              ref={chatPanelRef}
              onResize={
                /* istanbul ignore next */ (width) => {
                  setChatPanelSize(width);
                }
              }
              onCollapse={
                /* istanbul ignore next */ () => setIsAiChatWindowOpen(false)
              }
              onExpand={
                /* istanbul ignore next */ () => setIsAiChatWindowOpen(true)
              }
            >
              {
                /* istanbul ignore next */ showPanels && ffChatbotMVP && (
                  <ChatWindow />
                )
              }
            </Panel>
          </PanelGroup>
        </Flex>
        <Toaster />
        <ManageTags isOpen={isManageTagsOpen} setIsOpen={setIsManageTagsOpen} />
      </PrismAIProvider>
    </PrismUrlProvider>
  );
};

export default Visibility;

// istanbul ignore next
export const PermissionDenied = () => {
  const history = useHistory();
  React.useEffect(() => {
    history.push('/devices/prism');
  }, [history]);

  return (
    <Flex
      flow="column"
      alignItems="center"
      justifyContent="center"
      css={{ p: 32 }}
    >
      <Loader size="lg" />
    </Flex>
  );
};
