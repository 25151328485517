import { Select } from '@kandji-inc/bumblebee';
import React, { useEffect } from 'react';

const SingleSelect = (props) => {
  const { param, update, isDisabled, options, defaultValue, label } = props;
  const { details } = param;

  useEffect(() => {
    if (!details) {
      update({ details: defaultValue.value });
    }
  }, [details]);

  return (
    <div className="b-flex-vg1" style={{ maxWidth: '65%' }}>
      {label && <p className={`b-txt${isDisabled ? '-light' : ''}`}>{label}</p>}
      <Select
        disabled={isDisabled}
        options={options}
        value={options.find(({ value }) => value === details) || defaultValue}
        onChange={({ value }) => update({ details: value })}
      />
    </div>
  );
};

export default SingleSelect;
