import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import { Link } from 'react-router-dom';

import {
  arrayOf,
  bool,
  elementType,
  func,
  object,
  shape,
  string,
} from 'prop-types';

import { Button } from '@kandji-inc/bumblebee';

import { Callout } from '@kandji-inc/nectar-ui';

import { LineLoader } from 'app/components/interface/LineLoader';
import { Whoops404 } from 'pages/404';

import {
  ADCSConnectSuccessBanner,
  ADCSPendingBanner,
} from '../components-for-specific-types/adcs/banners';
import { FILTERS_SHAPE, INT_TYPES } from '../constants';
import IntegrationsFilters from '../filters';

import OneCategoryBlock from './one-category-block';

import { useConnector } from '../components-for-specific-types/adcs/hooks';

import iconEmptyState from './assets/emptystate.svg';

import featureFlags from '../../../config/feature-flags';
import UpdateServicenowIntegration from '../components-for-specific-types/servicenow/components/update-integration';
import useListIntegrationsBackend from '../hooks/use-list-integrations-backend';
import { shouldSuggestMarketplace } from '../overview/calc';
import { getIntegrationByType } from '../utils/get-integration-by-type';
import './generic-cards-view.styles.scss';

function GenericCardsView({
  title,
  buttonHref,
  buttonText,
  buttonIcon,
  buttonKind,
  CardComponent,
  extraPropsForCard,
  itemsByCategories,
  ListWrapper,
  counts,
  error,
  isFetching,
  filters,
  setFilters,
  testing,
  testOnClick,
}) {
  const history = useHistory();
  const location = useLocation();

  const { connectorList, fetchConnectorList } = useConnector();

  const isServicenowEnabled = featureFlags.getFlag('paint-servicenow');

  const [isPendingBannerVisible, setIsPendingBannerVisible] = useState(true);
  const [isSuccessBannerVisible, setIsSuccessBannerVisible] = useState(true);

  const [isServicenowUpdateDialogOpen, setIsServicenowUpdateDialogOpen] =
    useState(false);

  const { data: integrationsData } = useListIntegrationsBackend();

  const servicenowIntegration = getIntegrationByType(
    INT_TYPES.servicenow,
    integrationsData,
  );

  const hasServicenowReAuth =
    servicenowIntegration?.servicenow_configs?.[0]?.status?.includes(
      'CREDENTIAL_REFRESH_FAILED',
    );

  const showEmptyState =
    itemsByCategories.length === 0 && !shouldSuggestMarketplace(counts); // prevents marketplace from displaying if filtering for enrolled integrations

  /* istanbul ignore next */
  const toADCS = () => history.push('/integrations/adcs');
  const toADCSServers = () => history.push('/integrations/adcs/servers');

  useEffect(() => {
    if (!testing) {
      fetchConnectorList();
    }
  }, []);

  if (error) {
    return <Whoops404 />;
  }

  const emptyState = (
    <div className="b-flex-vc b-flex-col int-empty-state">
      <img src={iconEmptyState} className="b-mb" alt="empty search results" />
      <h4 className="b-h4 b-mb1">No results found</h4>
      <p className="b-txt">
        Try changing the filter or search with different keywords.
      </p>
    </div>
  );

  // TODO: bring back this banner when version stuff gets sorted
  //  const updateAvailableBanner = (
  //    <Callout
  //      css={{ marginTop: '32px', backgroundColor: '$neutral20' }}
  //      title="New Kandji App version available"
  //      text="Version 1.0.4 is now available in the ServiceNow store."
  //      link="/integrations"
  //      linkLabel="Install Kandji app"
  //    />
  //  );

  const servicenowReauthenticateBanner = (
    <Callout
      theme="warning"
      css={{ marginTop: '32px' }}
      title="Connection to ServiceNow has been disrupted."
      text="To resolve the connection disruption, please review your integration credentials and re-authenticate to ServiceNow."
      linkLabel="Re-authenticate"
      onClick={() => {
        setIsServicenowUpdateDialogOpen(true);
      }}
    />
  );

  const isIntegrationsOverviewPath = location.pathname === '/integrations';

  // TODO: bring back this back when version stuff gets sorted
  // const showUpdateAvailableBanner = false;

  const showServicenowReauthenticateBanner =
    isIntegrationsOverviewPath && hasServicenowReAuth && isServicenowEnabled;

  const content = isFetching ? (
    <LineLoader isDelayed />
  ) : (
    <>
      <IntegrationsFilters
        setFilters={setFilters}
        filters={filters}
        counts={counts}
        showSearch={title === 'Integrations'}
      />

      <ADCSPendingBanner
        isVisible={isPendingBannerVisible}
        connectorList={connectorList}
        onClick={toADCSServers}
        onClose={() => setIsPendingBannerVisible(false)}
      />
      <ADCSConnectSuccessBanner
        isVisible={isSuccessBannerVisible}
        connectorList={connectorList}
        onClick={toADCS}
        onClose={() => setIsSuccessBannerVisible(false)}
      />

      {showEmptyState && emptyState}
      <ListWrapper filters={filters}>
        {/* TODO: bring back this back when version stuff gets
        sorted */}
        {/* {showUpdateAvailableBanner && updateAvailableBanner} */}
        {showServicenowReauthenticateBanner && servicenowReauthenticateBanner}
        {itemsByCategories.map(({ title: catTitle, items }) => (
          <OneCategoryBlock
            key={catTitle}
            items={items}
            title={catTitle}
            CardComponent={CardComponent}
            extraPropsForCard={extraPropsForCard}
          />
        ))}
      </ListWrapper>
      {isServicenowUpdateDialogOpen && (
        <UpdateServicenowIntegration
          integrationId={servicenowIntegration?.id}
          onClose={() => setIsServicenowUpdateDialogOpen(false)}
        />
      )}
    </>
  );

  return (
    <>
      <div className="b-mt3 b-mb3 b-flex-btw">
        <h1 className="b-h1">{title}</h1>
        <Button
          icon={buttonIcon}
          isWide
          kind={buttonKind}
          href={buttonHref}
          linkComponent={Link}
        >
          {buttonText}
        </Button>
      </div>

      {content}
    </>
  );
}

GenericCardsView.propTypes = {
  title: string.isRequired,
  buttonHref: string.isRequired,
  buttonText: string.isRequired,
  buttonIcon: string.isRequired,
  buttonKind: string,
  CardComponent: elementType.isRequired,
  extraPropsForCard: object,
  itemsByCategories: arrayOf(
    shape({
      title: string.isRequired,
      items: arrayOf(object).isRequired,
    }),
  ).isRequired,
  ListWrapper: elementType,
  counts: object.isRequired,
  error: bool,
  isFetching: bool.isRequired,
  filters: FILTERS_SHAPE.isRequired,
  setFilters: func.isRequired,
  testOnClick: func,
};

GenericCardsView.defaultProps = {
  extraPropsForCard: {},
  ListWrapper: ({ children }) => children,
  buttonKind: 'fill',
  error: null,
  testOnClick: null,
};

export default GenericCardsView;
