import React from 'react';

import { Icon, Paragraph, Tooltip, styled } from '@kandji-inc/nectar-ui';

type Props = {
  toolTipMessage: string;
  url: string;
};

const StyledURLParagraph = styled(Paragraph, {
  backgroundColor: '$neutral5',
  color: '$neutral70',
  marginTop: '12px',
  padding: '$3 $2',
  border: '1px solid rgba(26, 29, 37, 0.05)',
  borderRadius: '4px',
  overflowWrap: 'break-word',
});

const MacOSToolTip = ({ toolTipMessage, url }: Props) => (
  <Tooltip
    side="right"
    theme="light"
    openDelay={450}
    interactive
    maxWidth="530px"
    css={{
      padding: '16px',
      textAlign: 'left',
      backgroundColor: '$neutral0',
      borderRadius: '$1',
      overflowWrap: 'break-word',
      filter: 'drop-shadow(0px 0px 10px rgba(26, 29, 37, 0.15))',
    }}
    // @ts-expect-error -- TODO fix this
    content={
      <>
        <Paragraph>{toolTipMessage}</Paragraph>
        <StyledURLParagraph>{url}</StyledURLParagraph>
      </>
    }
  >
    <span>
      <Icon name="circle-exclamation" size="sm" color="" />
    </span>
  </Tooltip>
);
export default MacOSToolTip;
