/* istanbul ignore file - legacy code, moved */
import { InfoBanner } from 'app/components/good/banners';
import { Row } from 'app/components/good/layout.styles';
import isEmpty from 'lodash/isEmpty';
import React from 'react';
import {
  CheckBox,
  DependenceInfo,
  Label,
  OptionalSubLabel,
  SubInfo,
  TagsWithLabel,
  TextBlock,
  Wrapper2,
} from './SCEP.styles';
import { Tag } from './SCEPShortenedTags';

const SCEPLibraryCheckbox = ({
  id,
  isEditorMode,
  isError,
  style,
  isDependenceDisable,
  disabled,
  value,
  onChange,
  label,
  tags,
  customSortTags,
  subLabel,
  info,
  dependenceInfo,
  isAdaptive,
  withSidebar,
  withSmallTopMargin,
  inversion,
}) => (
  <Wrapper2 withSmallTopMargin={withSmallTopMargin}>
    <CheckBox
      id={`key-${id}`}
      type="checkbox"
      disabled={!isEditorMode || isDependenceDisable || disabled}
      checked={isDependenceDisable ? false : inversion ? !value : value}
      onChange={onChange}
      error={isError}
      style={style}
      isDependenceDisable={isDependenceDisable}
    />
    <TextBlock withSidebar={withSidebar} isAdaptive={isAdaptive}>
      <TagsWithLabel
        withSidebar={withSidebar}
        isDependenceDisable={isDependenceDisable}
      >
        <Label
          withSidebar={withSidebar}
          subLabel={subLabel}
          htmlFor={`key-${id}`}
          isDependenceDisable={isDependenceDisable}
        >
          {label}
        </Label>
        <Row spacing={0}>
          {!isEmpty(tags) &&
            tags
              .sort((a, b) => {
                if (customSortTags[a] > customSortTags[b]) {
                  return 1;
                }
                if (customSortTags[a] < customSortTags[b]) {
                  return -1;
                }
                return 0;
              })
              .map((tag, idx) => (
                <Tag light fontDark key={idx}>
                  {tag}
                </Tag>
              ))}
        </Row>
      </TagsWithLabel>
      <SubInfo withSidebar={withSidebar} isAdaptive={isAdaptive}>
        <OptionalSubLabel isDependenceDisable={isDependenceDisable}>
          {subLabel}
        </OptionalSubLabel>
        {isDependenceDisable && dependenceInfo && (
          <DependenceInfo>{dependenceInfo}</DependenceInfo>
        )}
        {info && (
          <InfoBanner styles={{ padding: '14px' }} margin="5px 0" text={info} />
        )}
      </SubInfo>
    </TextBlock>
  </Wrapper2>
);

export default SCEPLibraryCheckbox;
