import pluralize from 'pluralize';
import React from 'react';
import history from '../../../router/history';
import UniversalAlert from './UniversalAlert';

const getSubject = (
  showBanner,
  mdmExpireStatus,
  depExpireStatus,
  vppExpireStatus,
  expireCount,
) => {
  let subject = '';

  if (expireCount > 1) {
    subject = 'Some of your integrations';
  } else {
    if (mdmExpireStatus) {
      subject = 'Your APNs integration';
    }
    if (depExpireStatus) {
      subject = 'Your Automated Device Enrollment integration';
    }
    if (vppExpireStatus) {
      subject = 'Your Apps & Books integration';
    }
  }

  return subject;
};

const isNullOrUndefined = (v) => v === null || v === undefined;

export const getExpirationDetails = (
  mdmDaysLeft,
  depDaysLeft,
  vppDaysLeft,
  canManageSettings,
) => {
  const isMdmExpired = !isNullOrUndefined(mdmDaysLeft) && mdmDaysLeft < 0;
  const isDepExpired = !isNullOrUndefined(depDaysLeft) && depDaysLeft < 0;
  const isVppExpired = !isNullOrUndefined(vppDaysLeft) && vppDaysLeft < 0;

  const isMdmExpiring = !isNullOrUndefined(mdmDaysLeft) && mdmDaysLeft < 30;
  const isDepExpiring = !isNullOrUndefined(depDaysLeft) && depDaysLeft < 30;
  const isVppExpiring = !isNullOrUndefined(vppDaysLeft) && vppDaysLeft < 30;

  const mdmExpireStatus = isMdmExpired || isMdmExpiring;
  const depExpireStatus = isDepExpired || isDepExpiring;
  const vppExpireStatus = isVppExpired || isVppExpiring;

  const isExpired = isMdmExpired || isDepExpired || isVppExpired;
  const showBanner = isMdmExpiring || isDepExpiring || isVppExpiring;
  const expireCount =
    Number(mdmExpireStatus) + Number(depExpireStatus) + Number(vppExpireStatus);

  const hasFiveDaysRemaining =
    !(mdmDaysLeft < 5) && !(depDaysLeft < 5) && !(vppDaysLeft < 5);
  const bannerType = hasFiveDaysRemaining ? 'warning' : 'danger';

  const verbiage = isExpired
    ? `${pluralize(
        'has',
        expireCount,
      )} expired, and functionality is currently limited.`
    : `${pluralize(
        'is',
        expireCount,
      )} about to expire, which will cause a loss in functionality.`;

  const subject = getSubject(
    showBanner,
    mdmExpireStatus,
    depExpireStatus,
    vppExpireStatus,
    expireCount,
  );
  let bannerText = `${subject} ${verbiage}`;

  if (!canManageSettings) {
    bannerText += ' Please contact your Kandji administrator.';
  }

  return { showBanner, bannerText, bannerType };
};

const TokenExpirationBanner = ({
  mdmInfo,
  depInfo,
  vppInfo,
  canManageSettings,
}) => {
  const { showBanner, bannerText, bannerType } = getExpirationDetails(
    mdmInfo.days_left,
    depInfo.days_left,
    vppInfo.days_left,
    canManageSettings,
  );

  const button = canManageSettings
    ? {
        text: 'Renew Integrations',
        onClick: () => history.push('/my-company/integrations'),
      }
    : null;

  return showBanner ? (
    <UniversalAlert type={bannerType} text={bannerText} button={button} />
  ) : null;
};

export default TokenExpirationBanner;
