import { useState } from 'react';
import { oktaDeviceTrustService } from '../services';
import type { ODTUpdateBody } from '../types';

const useUpdateOktaDeviceTrust = () => {
  const [loading, setLoading] = useState(false);

  const updateOktaDeviceTrust = async (body: ODTUpdateBody, id: string) => {
    setLoading(true);
    try {
      const response = await oktaDeviceTrustService.updateOktaDeviceTrust(
        body,
        id,
      );
      return Promise.resolve(response);
    } catch (e) {
      return Promise.reject(e);
    } finally {
      setLoading(false);
    }
  };
  return {
    loading,
    updateOktaDeviceTrust,
  };
};

export default useUpdateOktaDeviceTrust;
