import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { links } from 'src/app/common/constants';
import Ticket from 'src/features/integrations/components/ticket';
import styled from 'styled-components';
import FooterButtons from '../../../components/footer-buttons';
import FooterStatus from '../../../components/footer-status/footer-status';
import {
  Container,
  ModalContent,
  StyledSlideHeader,
} from '../../../components/styled-components';
import useModal from '../../../hooks/use-modal';
import odtComplete from '../assets/odt-complete.svg';
import oktaVerfiyOutline from '../assets/okta-verify-outline.svg';
import type { FooterStatusState, SetupCompleteProps } from '../types';
import footerIcon from './assets/okta-small.svg';

const StyledTicket = styled(Ticket)``;
const TicketContainer = styled.div`
  text-align: left;
  width: 568px;
  & > ${StyledTicket} {
    max-width: 600px;
  }
`;

const SetupComplete = (props: SetupCompleteProps) => {
  const { closeModal } = useModal();
  const { footerOptions, libraryItemId, isSetupLater } = props;
  const history = useHistory();
  const [footerStatusState, setFooterStatusState] =
    useState<FooterStatusState>('');
  const setupCompleteText = isSetupLater
    ? `The Okta Device Trust integration was successfully configured. Once you have added Okta Verify in 
    Apple Business Manager or Apple School Manager and Okta Verify syncs to your Library, you can turn on 
    device trust and scope the Library item to your devices.`
    : `The Okta Device Trust integration was successfully configured. You can
    turn on device trust in the Okta Verify Library Item now, or choose to
    set this up later and view the integration details.`;

  const handleViewIntegrationDetails = async () => {
    history.push(`${links.integrations.root}/okta_device_trust`);
    closeModal();
  };
  const handleNext = () => {
    history.push(`/library/app-store-apps/${libraryItemId}`);
    closeModal();
  };

  useEffect(() => {
    setFooterStatusState('success');
  }, []);

  return (
    <Container>
      <ModalContent>
        <img src={odtComplete} className="b-mb2" alt="header-icons" />
        <StyledSlideHeader>Okta Device Trust setup complete</StyledSlideHeader>
        <p className="b-txt-light">{setupCompleteText}</p>

        <TicketContainer>
          <StyledTicket
            icon={oktaVerfiyOutline}
            text="Navigate to the Okta Verify Library Item and turn on the Okta Device Trust integration under settings. Assigning Okta Verify to the correct Blueprints will allow you to deploy device trust to your fleet."
            title="Deploy device trust with Okta Verify"
            link="https://support.kandji.io/support/solutions/articles/72000580418-okta-device-trust-configuring-the-okta-verify-library-item"
          />
        </TicketContainer>
      </ModalContent>
      {isSetupLater ? (
        <FooterButtons
          nextButtonText="View integration settings"
          handleNext={handleViewIntegrationDetails}
          hasCancelButton={false}
        />
      ) : (
        <FooterButtons
          onClose={handleViewIntegrationDetails}
          handleNext={handleNext}
          cancelButtonText="View integration settings"
          nextButtonText="Go to Library Item"
          showStepper
          stepperIdx={5}
          stepsAmount={6}
          customComponent={
            footerStatusState ? (
              <FooterStatus
                icon={footerIcon}
                status={footerStatusState}
                options={
                  footerOptions || {
                    success: {
                      title: 'Okta Device Trust is successfully connected.',
                      statusText: 'Completed',
                    },
                  }
                }
              />
            ) : undefined
          }
        />
      )}
    </Container>
  );
};

export default SetupComplete;
