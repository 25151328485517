/* istanbul ignore file  */
import React from 'react';
import { Wrapper } from './components';
import {
  Activity,
  Assignments,
  Devices,
  LibraryItemList,
  Parameters,
} from './pages';
import { Header } from './regions';

const BlueprintFlow = () => (
  <Wrapper>
    <Header
      tabs={{
        assignments: Assignments,
        library: LibraryItemList,
        parameters: Parameters,
        devices: Devices,
        activity: Activity,
      }}
    />
  </Wrapper>
);

export default BlueprintFlow;
