import { Callout, Paragraph } from '@kandji-inc/nectar-ui';
import { i18n } from 'i18n';
import ActionDialog from '../../../../components/action-dialog';

type Props = {
  isDeleteDialogOpen: boolean;
  handleToggle: () => void;
  handleDelete: () => void;
};

const DeleteIntegration = (props: Props) => {
  const { isDeleteDialogOpen, handleToggle, handleDelete } = props;

  const dialogContent = () => (
    <>
      <Callout
        theme="warning"
        title={i18n.t(
          'Device information will immediately stop data synchronization to ServiceNow.',
        )}
        text={i18n.t(
          'Please be aware that deleting the integration in Kandji will neither uninstall the Kandji application or remove any of the data that has already synced to your ServiceNow instance.',
        )}
        showCloseButton={false}
      />
      <Paragraph>
        {i18n.t(
          'Deleting the Kandji integration will immediately stop any data synchronization from occuring from Kandji to ServiceNow.',
        )}
      </Paragraph>
      <Paragraph>
        {i18n.t('Are you sure you want to delete this integration?')}
      </Paragraph>
    </>
  );

  return (
    <ActionDialog
      size="sm"
      title={i18n.t('Delete integration')}
      content={dialogContent()}
      isOpen={isDeleteDialogOpen}
      nextText={i18n.t('Delete integration')}
      nextClick={handleDelete}
      backText={i18n.t('Cancel')}
      backClick={handleToggle}
    />
  );
};

export default DeleteIntegration;
