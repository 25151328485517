/* istanbul ignore file - legacy code, moved */
import { ProfileTypes } from 'app/common/constants';

const SCEPConfig = {
  // General
  URL: '',
  Name: '',
  Challenge: '',
  CAFingerprint: '',
  // Subject
  Subject: '',
  SubjectAlternativeNameType: 'None',
  SubjectAltName: '',
  ntPrincipalName: '',
  // Key
  Keysize: 1024,
  'Key Usage': 0,
  // Options
  Retries: {
    checked: false,
    value: 3,
  },
  RetryDelay: {
    checked: false,
    value: 10,
  },
  KeyIsExtractable: false,
  AllowAllAppsAccess: false,
  CertificateRenewalTimeInterval: {
    checked: false,
    value: 14,
  },
  Redistribution: {
    checked: false,
    value: 30,
  },
};

export const LibraryFiledTypes = {
  TEXT: 'text',
  TEXTAREA: 'textarea',
  CHECKBOX: 'checkbox',
  SELECT: 'select',
  OPTIONAL_NUMBER: 'optionalNumber',
  PASSWORD_VALIDATOR: 'passwordValidator',
};

export const SCEPMapping = {
  General: {
    name: 'General',
    weight: 1,
    fields: {
      URL: {
        name: 'URL',
        description: 'The base URL for the SCEP server.',
        placeholder: 'scep.accuhive.io',
        required: true,
        weight: 1,
        type: LibraryFiledTypes.TEXT,
      },
      Name: {
        name: 'Name',
        description:
          "A string that's understood by the SCEP server; for example, a domain name like example.org." +
          ' If a certificate authority has multiple CA certificates,' +
          ' this field can be used to distinguish which is required.',
        placeholder: 'ca2.accuhive.io',
        optional: true,
        weight: 2,
        type: LibraryFiledTypes.TEXT,
      },
      Challenge: {
        name: 'Challenge',
        description: 'A pre-shared secret used for automatic enrollment.',
        placeholder: 'Gz05FXUq\\ZROYox:{SRrB@',
        optional: true,
        weight: 3,
        type: LibraryFiledTypes.PASSWORD_VALIDATOR,
      },
      CAFingerprint: {
        name: 'Fingerprint',
        description:
          'The fingerprint (hex string) of the Certificate Authority certificate.',
        placeholder:
          '58 0F 80 47 92 AB C6 3B BB 80 15 4D 4D FD DD 8B 2E F2 67 4E',
        optional: true,
        weight: 4,
        type: LibraryFiledTypes.TEXTAREA,
      },
    },
  },
  Subject: {
    name: 'Subject',
    weight: 2,
    fields: {
      Subject: {
        name: 'Subject',
        description:
          'The representation of a X.500 name represented as an array of OID and value.' +
          ' Typically used to identify the device within the CA.',
        placeholder: 'CN=$SERIAL_NUMBER-$ASSET_TAG',
        optional: true,
        weight: 1,
        type: LibraryFiledTypes.TEXTAREA,
      },
      SubjectAlternativeNameType: {
        name: 'Subject Alternative Name Type',
        description:
          'Add additional Subject Alternative Names to the certificate request.',
        weight: 2,
        type: LibraryFiledTypes.SELECT,
        fieldValues: [
          { value: 'None', label: 'None' },
          { value: 'RFC 822 Name', label: 'RFC 822 Name' },
          { value: 'DNS Name', label: 'DNS Name' },
          {
            value: 'Uniform Resource Identifier',
            label: 'Uniform Resource Identifier',
          },
        ],
      },
      SubjectAltName: {
        name: 'Subject Alternative Name Value',
        description: 'The value of the Subject Alternative Name.',
        placeholder: 'CN=$SERIAL_NUMBER',
        dependence: 'SubjectAlternativeNameType',
        required: true,
        weight: 3,
        type: LibraryFiledTypes.TEXT,
      },
      ntPrincipalName: {
        name: 'NT Principal Name',
        description: 'The NT principal name.',
        placeholder: 'scep.sa@accuhive.io',
        dependence: 'SubjectAlternativeNameType',
        optional: true,
        weight: 4,
        type: LibraryFiledTypes.TEXT,
      },
    },
  },
  Key: {
    name: 'Key',
    weight: 3,
    fields: {
      Keysize: {
        name: 'Key Size',
        description: 'Key size, in bits.',
        weight: 4,
        type: LibraryFiledTypes.SELECT,
        fieldValues: [
          { value: 1024, label: '1024' },
          { value: 2048, label: '2048' },
          { value: 4096, label: '4096' },
        ],
      },
      'Key Usage': {
        name: 'Key Usage',
        description: 'Indicate the use of the key.',
        weight: 4,
        type: LibraryFiledTypes.SELECT,
        fieldValues: [
          { value: 0, label: 'None' },
          { value: 1, label: 'Signing' },
          { value: 4, label: 'Encryption' },
          { value: 5, label: 'Both signing and encryption' },
        ],
      },
    },
  },
  Options: {
    name: 'Options',
    weight: 4,
    fields: {
      Retries: {
        name: 'Retries',
        description:
          'The number of times the device should retry if the server sends a PENDING response.',
        weight: 1,
        type: LibraryFiledTypes.OPTIONAL_NUMBER,
        minimum: 0,
        maximum: 99,
        fieldText: 'retries',
      },
      RetryDelay: {
        name: 'Retry delay',
        description:
          'The number of seconds to wait between subsequent retries.' +
          ' The first retry is attempted without this delay.',
        weight: 2,
        type: LibraryFiledTypes.OPTIONAL_NUMBER,
        minimum: 1,
        maximum: 99,
        fieldText: 'seconds between retries',
      },
      AllowAllAppsAccess: {
        name: 'Allow apps to access the private key',
        description:
          'Allow all apps to access the certificate in the keychain. Available in macOS.',
        weight: 3,
        type: LibraryFiledTypes.CHECKBOX,
      },
      KeyIsExtractable: {
        name: 'Prevent the private key data from being extracted in the keychain',
        description:
          'Disables exporting the private key from the keychain. Available in macOS 10.15 and later.',
        weight: 4,
        type: LibraryFiledTypes.CHECKBOX,
      },
      CertificateRenewalTimeInterval: {
        name: 'Certificate expiration notification',
        description:
          'The number of days before the certificate expires at which to' +
          ' start showing the expiration notification. Available in macOS.',
        weight: 5,
        type: LibraryFiledTypes.OPTIONAL_NUMBER,
        minimum: 0,
        maximum: 99,
        fieldText: 'days before certificate expiration',
      },
      Redistribution: {
        name: 'Automatic profile redistribution',
        description:
          'The number of days prior to the certificate expiration that Kandji should attempt to re-issue' +
          ' the SCEP profile to renew the certificate.',
        weight: 6,
        type: LibraryFiledTypes.OPTIONAL_NUMBER,
        minimum: 1,
        maximum: 365,
        fieldText: 'days before certificate expiration',
      },
    },
  },
};

export const SCEPDefaultState = {
  isLoading: true,
  initialProfileData: {},
  profileData: {
    is_active: true,
    name: '',
    blueprints: [],
    identifier: ProfileTypes.SCEP,
    settings: SCEPConfig,
  },
  editName: false,
  nameError: false,
};
