/* istanbul ignore file */
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import { Toaster } from '@kandji-inc/bumblebee';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { getBlueprints } from 'src/app/_actions/blueprint';
import { paths } from 'src/features/blueprints/common';
import { blueprintService } from 'src/features/library-items/data-service/blueprint/blueprint-service';

import type { Blueprint } from '../blueprint-flow.types';
import { getInitialBlueprint } from '../initial-state';
import useBlueprintFlow from '../store';
import {
  transformBlueprintFromApi,
  transformBlueprintToApi,
} from './blueprints';

type DuplicateBlueprintOpt = {
  name: string;
  description: string;
  source: {
    id: Blueprint['id'];
    type: 'blueprint';
  };
};

const useBlueprint = () => {
  const { id } = useParams<{ id?: string }>();
  const client = useQueryClient();
  const dispatch = useDispatch();
  const history = useHistory();
  const blueprint = useBlueprintFlow((state) => state.blueprint);
  const isAddingBlueprint = useBlueprintFlow(
    (state) => state.isAddingBlueprint,
  );
  const setBlueprint = useBlueprintFlow((state) => state.setBlueprint);
  const setIsAddingBlueprint = useBlueprintFlow(
    (state) => state.setIsAddingBlueprint,
  );
  const setIsEditingAssignments = useBlueprintFlow(
    (state) => state.setIsEditingAssignments,
  );

  const { data, isLoading, isError } = useQuery({
    queryKey: ['flow-blueprint', id],
    queryFn: () => blueprintService.get(id),
    enabled: !isAddingBlueprint && Boolean(id),
  });

  const { mutateAsync: createBlueprint, isPending: isPendingCreate } =
    useMutation({
      mutationFn: (args: {
        blueprint?: Blueprint;
        duplicateBp?: DuplicateBlueprintOpt;
      }) =>
        blueprintService.create(
          args.duplicateBp
            ? args.duplicateBp
            : transformBlueprintToApi(args.blueprint),
        ),
    });
  const { mutateAsync: updateBlueprint, isPending: isPendingUpdate } =
    useMutation({
      mutationFn: (bp: Blueprint) =>
        blueprintService.patch(bp.id, transformBlueprintToApi(bp)),
      onSuccess: () =>
        client.invalidateQueries({ queryKey: ['flow-blueprint', id] }),
    });
  const { mutateAsync: deleteBlueprint, isPending: isPendingDelete } =
    useMutation({
      mutationFn: (bp: Blueprint) => blueprintService.delete(bp.id),
      onSuccess: () =>
        client.invalidateQueries({ queryKey: ['flow-blueprint', id] }),
    });

  useEffect(() => {
    setBlueprint(undefined, true);
    setIsAddingBlueprint(!id);

    if (!id) {
      setBlueprint(getInitialBlueprint());
    }
  }, [id]);

  useEffect(() => {
    if (isError) {
      Toaster('Failed to retrieve Blueprint.');
      history.push(paths.root);
    }
  }, [isError]);

  useEffect(() => {
    if (data) {
      const fetchedBp = transformBlueprintFromApi(data);
      setBlueprint(fetchedBp);
    }
  }, [data]);

  const create = async (bp: Blueprint) => {
    // TODO: Update isAddingBlueprint here?
    try {
      const createdBp = await createBlueprint({ blueprint: bp });
      setIsAddingBlueprint(false);
      setIsEditingAssignments(false);
      dispatch(getBlueprints());
      history.push(`/blueprints/maps/${createdBp.data.id}`, {
        isConfirmed: true,
      });
      Toaster('Successfully created Blueprint.');
      return transformBlueprintFromApi(createdBp);
    } catch (e) {
      if (e?.response?.data && Array.isArray(e.response.data)) {
        const errorMessages = e.response.data.join(', ');

        Toaster(errorMessages);
        return { error: errorMessages };
      } else {
        Toaster('Failed to create Blueprint.');
        return { error: 'Failed to create Blueprint.' };
      }

      return null;
    }
  };

  const patch = async (
    bp: Blueprint,
    opts = {
      withToaster: true,
    },
  ) => {
    try {
      const updatedBp = await updateBlueprint(bp);
      const transformed = transformBlueprintFromApi(updatedBp.data);
      setBlueprint(transformed);
      setIsEditingAssignments(false);
      dispatch(getBlueprints());
      if (opts.withToaster) {
        Toaster('Successfully updated Blueprint.');
      }
      return transformed;
    } catch (e) {
      if (e?.response?.data) {
        const errorMessages = Object.keys(e.response.data)
          .map((key) => e.response.data[key].join(', '))
          .join(', ');

        Toaster(errorMessages);
      } else {
        Toaster('Failed to update Blueprint.');
      }

      return Promise.reject(e);
    }
  };

  const duplicate = async (bp: Blueprint) => {
    try {
      const duplicatedBp = await createBlueprint({
        duplicateBp: {
          name: bp.name.trim(),
          description: bp.description,
          source: { id: bp.id, type: 'blueprint' },
        },
      });
      dispatch(getBlueprints());
      history.push(`/blueprints/maps/${duplicatedBp.data.id}`);
      Toaster('Successfully duplicated Blueprint.');
    } catch (e) {
      if (e?.response?.data && Array.isArray(e.response.data)) {
        const errorMessages = e.response.data.join(', ');

        Toaster(errorMessages);
      } else {
        Toaster('Failed to create Blueprint.');
      }
    }
  };

  const del = async (bp: Blueprint) => {
    try {
      await deleteBlueprint(bp);
      dispatch(getBlueprints());
      history.push(`/blueprints`);
      Toaster('Successfully deleted Blueprint.');
    } catch (e) {
      if (e?.response?.data && Array.isArray(e.response.data)) {
        const errorMessages = e.response.data.join(', ');

        Toaster(errorMessages);
      } else {
        Toaster('Failed to delete Blueprint.');
      }
    }
  };

  return {
    blueprint,
    isAddingBlueprint,
    isLoading:
      isLoading || isPendingCreate || isPendingUpdate || isPendingDelete,
    create,
    patch,
    duplicate,
    del,
  };
};

export default useBlueprint;
