import { createColumnHelper } from '@tanstack/react-table';
/* istanbul ignore file */
import type { z } from 'zod';

import {
  NULL_VALUE_N_DASH,
  ON_OFF_CELL_FILTER_OPTIONS,
  OnOffCell,
  YES_NO_CELL_FILTER_OPTIONS,
  YesNoCell,
  capitalizeFirstLetter,
  getFilteredDisabled as filterDisabled,
  getEnumFilterOptions,
} from '../column-utils';
import { getGlobalColumns } from './globalColumns';

export const getStartupSettingsColumns = (schema) => {
  if (!schema) {
    return { columnDefs: [], filterableColumns: [] };
  }

  const startupSettingsColumnHelper =
    createColumnHelper<z.infer<typeof schema>>();
  const globalColumns = getGlobalColumns(schema, startupSettingsColumnHelper);

  const columnDefs = [
    ...globalColumns.startColumnDefs,

    startupSettingsColumnHelper.accessor((row) => row.sip, {
      id: 'sip',
      cell: OnOffCell,
      meta: {
        displayName: 'System Integrity Protection',
        filterType: 'boolean',
        filterOptions: [...ON_OFF_CELL_FILTER_OPTIONS],
        filterIcon: 'circle-dot',
        filterDisabled,
      },
    }),
    startupSettingsColumnHelper.accessor((row) => row.ssv, {
      id: 'ssv',
      cell: OnOffCell,
      meta: {
        displayName: 'Authenticated Root Volume',
        filterType: 'boolean',
        filterOptions: [...ON_OFF_CELL_FILTER_OPTIONS],
        filterIcon: 'circle-dot',
        filterDisabled,
      },
    }),

    startupSettingsColumnHelper.accessor((row) => row.secure_boot_level, {
      id: 'secure_boot_level',
      cell: (info) => {
        const value = info.getValue();
        if (value === null) {
          return NULL_VALUE_N_DASH;
        }
        if (value) {
          return capitalizeFirstLetter(value);
        }
        return value;
      },
      meta: {
        displayName: 'Secure Boot Level',
        filterType: 'enum',
        filterOptions: getEnumFilterOptions(
          schema.schema?.definitions.SecureBootLevel.enum || [],
        ),
        filterIcon: 'list-check',
        filterDisabled,
      },
    }),

    startupSettingsColumnHelper.accessor((row) => row.external_boot_level, {
      id: 'external_boot_level',
      cell: (info) => {
        const value = info.getValue();
        if (value === null) {
          return NULL_VALUE_N_DASH;
        }
        if (value) {
          return capitalizeFirstLetter(value);
        }
        return value;
      },
      meta: {
        displayName: 'External Boot Level',
        filterType: 'enum',
        filterOptions: getEnumFilterOptions(
          schema.schema?.definitions.ExternalBootLevel.enum || [],
        ),
        filterIcon: 'list-check',
        filterDisabled,
      },
    }),

    startupSettingsColumnHelper.accessor(
      (row) => row.bootstrap_token_escrowed,
      {
        id: 'bootstrap_token_escrowed',
        cell: YesNoCell,
        meta: {
          displayName: 'Bootstrap Token Escrowed',
          filterType: 'boolean',
          filterOptions: [...YES_NO_CELL_FILTER_OPTIONS],
          filterIcon: 'circle-dot',
          filterDisabled,
        },
      },
    ),

    startupSettingsColumnHelper.accessor((row) => row.bootstrap_token_auth, {
      id: 'bootstrap_token_auth',
      cell: YesNoCell,
      meta: {
        defaultHidden: true,
        displayName: 'Bootstrap Token for Authentication',
        filterType: 'boolean',
        filterOptions: [...YES_NO_CELL_FILTER_OPTIONS],
        filterIcon: 'circle-dot',
        filterDisabled,
      },
    }),

    startupSettingsColumnHelper.accessor((row) => row.kext_requires_bst, {
      id: 'kext_requires_bst',
      cell: YesNoCell,
      meta: {
        defaultHidden: true,
        displayName: 'Kext Approval Requires Bootstrap Token',
        filterType: 'boolean',
        filterOptions: [...YES_NO_CELL_FILTER_OPTIONS],
        filterIcon: 'circle-dot',
        filterDisabled,
      },
    }),

    startupSettingsColumnHelper.accessor(
      (row) => row.software_update_requires_bst,
      {
        id: 'software_update_requires_bst',
        cell: YesNoCell,
        meta: {
          defaultHidden: true,
          displayName: 'Software Update Requires Bootstrap Token',
          filterType: 'boolean',
          filterOptions: [...YES_NO_CELL_FILTER_OPTIONS],
          filterIcon: 'circle-dot',
          filterDisabled,
        },
      },
    ),

    startupSettingsColumnHelper.accessor((row) => row.any_signed_os, {
      id: 'any_signed_os',
      cell: YesNoCell,
      meta: {
        defaultHidden: true,
        displayName: 'Allows Any Apple-Signed OS',
        filterType: 'boolean',
        filterOptions: [...YES_NO_CELL_FILTER_OPTIONS],
        filterIcon: 'circle-dot',
        filterDisabled,
      },
    }),

    startupSettingsColumnHelper.accessor((row) => row.mdm_manages_kext, {
      id: 'mdm_manages_kext',
      cell: YesNoCell,
      meta: {
        defaultHidden: true,
        displayName: 'MDM Allowed to Manage KEXT',
        filterType: 'boolean',
        filterOptions: [...YES_NO_CELL_FILTER_OPTIONS],
        filterIcon: 'circle-dot',
        filterDisabled,
      },
    }),

    startupSettingsColumnHelper.accessor((row) => row.user_manages_kext, {
      id: 'user_manages_kext',
      cell: YesNoCell,
      meta: {
        defaultHidden: true,
        displayName: 'User Allowed to Manage KEXT',
        filterType: 'boolean',
        filterOptions: [...YES_NO_CELL_FILTER_OPTIONS],
        filterIcon: 'circle-dot',
        filterDisabled,
      },
    }),

    ...globalColumns.endColumnDefs,
  ];

  return { columnDefs };
};
