import { Chip } from '@kandji-inc/bumblebee';
import { Icon, Text } from '@kandji-inc/nectar-ui';
import { i18n } from 'i18n';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import type { Blueprints } from '../../../types';
import {
  BlueprintScopingContainer,
  Container,
  StyledExpandedDetailsRow,
  StyledLink,
  StyledRowDetails,
} from './styled';

type Props = {
  blueprints: Blueprints[] | undefined;
};
const BlueprintsSection = (props: Props) => {
  const [showBlueprintData, setShowBlueprintData] = useState(false);

  const { blueprints } = props;

  const handleOpenBlueprintRows = () => {
    setShowBlueprintData((prevState) => !prevState);
  };

  return (
    <>
      <Container
        blueprintContainer
        isBlueprintContainerOpen={showBlueprintData}
        onClick={handleOpenBlueprintRows}
      >
        <Icon
          name={`fa-caret-${showBlueprintData ? 'down' : 'right'}-solid-small`}
        />

        <BlueprintScopingContainer data-testid="blueprint-scoping:row">
          <Text>{i18n.t('Blueprint scoping')}</Text>
          <Chip text={blueprints?.length.toString() ?? '0'} />
        </BlueprintScopingContainer>
      </Container>
      {showBlueprintData && (
        <Container subData>
          <StyledRowDetails>
            {blueprints?.map((blueprint) => (
              <StyledExpandedDetailsRow key={blueprint.id}>
                <Text css={{ color: '$neutral60' }}>{i18n.t('Blueprint')}</Text>
                <StyledLink
                  as={Link}
                  to={`/blueprints/${blueprint.id}/library`}
                >
                  {blueprint.name}
                </StyledLink>
              </StyledExpandedDetailsRow>
            ))}
          </StyledRowDetails>
        </Container>
      )}
    </>
  );
};

export default BlueprintsSection;
