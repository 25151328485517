import { Toggle } from '@kandji-inc/bumblebee';
import React, { useEffect } from 'react';

const SingleToggle = (props) => {
  const { param, update, isDisabled, defaultValue, label } = props;
  const { details } = param;

  useEffect(() => {
    if (details === null || details === undefined) {
      update({ details: defaultValue });
    }
  }, [details]);

  return (
    <div className="b-flex-g1 b-flex-vc">
      <Toggle
        checked={details}
        onToggle={(c) => update({ details: c })}
        disabled={isDisabled}
      />
      {label && <p className={`b-txt${isDisabled ? '-light' : ''}`}>{label}</p>}
    </div>
  );
};

export default SingleToggle;
