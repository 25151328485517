import styled from 'styled-components';

const Status = styled('div')`
  justify-self: end;
  grid-area: status;
  cursor: default;
  font-family: var(--font-family-primary);
  line-height: 14px;
  text-transform: capitalize;
  padding: 5px 20px;
  font-weight: 500;
  font-size: 12px;
  height: 24px;
  border-radius: 3px;
  text-align: center;
`;

export const Active = styled(Status)`
  color: #37ac2c;
  background: rgba(82, 197, 72, 0.2);
`;

export const Inactive = styled(Status)`
  padding: 5px 16px;
  background: #e3e6ee;
  color: #7f869a;
`;

export const Devices = styled('div')`
  display: grid;
  grid-template-columns: max-content auto;
  grid-template-rows: repeat(1, min-content);
  grid-column-gap: 10px;
  grid-template-areas: 'deviceIcon deviceText';
`;

const DevicesGrid = styled('div')`
  display: flex;
  flex-direction: column;
`;

const DevicesSpan = styled('span')`
  font-size: 18px;
  line-height: 25px;
  text-align: center;
  padding-top: 2px;
`;

const DevicesIcon = styled('img')`
  grid-area: deviceIcon;
  height: 18px;
  align-self: center;
`;

const DevicesFontIcon = styled('i')`
  grid-area: deviceFontIcon;
  height: 20px;
  align-self: center;
  color: #bfbfbf;
`;

const DevicesText = styled('div')`
  font-weight: normal;
  font-size: 14px;
  line-height: 28px;
  font-family: var(--font-family-primary);
`;

Devices.Icon = DevicesIcon;
Devices.Text = DevicesText;
Devices.Grid = DevicesGrid;
Devices.Span = DevicesSpan;
Devices.FontIcon = DevicesFontIcon;
