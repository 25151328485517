import { Banner, Button } from '@kandji-inc/bumblebee';
import { styled } from '@kandji-inc/nectar-ui';
import { i18n } from 'i18n';
import React, { useEffect, useState } from 'react';
import { useSendTestNotification } from '../hooks/use-send-test-notification';

type Channel = {
  id: string;
  name: string;
};

type Props = {
  channels: Channel[];
  integrationId: number;
  isOpen: boolean;
};

type Response = 'success' | 'error' | null;

const Container = styled('div', { marginTop: '$7' });
const StyledButton = styled(Button, {
  justifyContent: 'left',
});

const StyledBanner = styled(Banner, {
  minWidth: 0,
});

const getNotificationBanner = (response: Response) => {
  switch (response) {
    case 'success':
      return (
        <StyledBanner icon="circle-check" theme="success">
          <p className="b-txt">
            Test notification sent. Please check your Slack account.
          </p>
        </StyledBanner>
      );

    case 'error':
      return (
        <StyledBanner icon="circle-xmark" theme="error">
          <p>
            Test notification failed to send. Please check your Slack account
            and try again later.
          </p>
        </StyledBanner>
      );

    default:
      return '';
  }
};

const SendTestNotification = (props: Props) => {
  const { channels, integrationId, isOpen } = props;

  const [loading, setLoading] = useState(false);
  const [response, setResponse] = useState<Response>();

  const sendNotificationMutation = useSendTestNotification();

  const handleSendTestNotification = async () => {
    setLoading(true);
    const channelsForTest = channels.map((channel) => ({
      channel_id: channel.id,
      channel_name: channel.name,
    }));

    const data = {
      channels: channelsForTest,
      integration_id: integrationId,
      type: 'slack',
    };

    sendNotificationMutation.mutate(data, {
      onSuccess: () => setResponse('success'),
      onError: () => setResponse('error'),
      onSettled: () => setLoading(false),
    });
  };

  useEffect(() => {
    if (!isOpen) {
      setResponse(null);
    }
  }, [isOpen]);

  return (
    <Container>
      <StyledButton
        kind="link"
        icon={loading ? 'arrows-rotate' : 'paper-plane'}
        onClick={handleSendTestNotification}
        isProgress={loading}
        disabled={channels.length === 0}
      >
        {i18n.t('Send test notification')}
      </StyledButton>

      {response && getNotificationBanner(response)}
    </Container>
  );
};

export default SendTestNotification;
