import { i18n } from 'i18n';
import { string } from 'prop-types';
import styled from 'styled-components';

const ModalContent = styled.div`
  padding: 0;
  display: grid;
  width: 560px;
`;

const Content = (props) => {
  const { name } = props;
  return (
    <ModalContent>
      <p className="b-txt">
        {i18n.$t(
          'Are you sure you would like to delete {name}? This action cannot be undone.',
          { name: <b>{name}</b> },
        )}
        '
      </p>
    </ModalContent>
  );
};

export default Content;

Content.propTypes = {
  name: string.isRequired,
};
