import { INT_TYPES } from '../../constants';
import {
  transformNotificationToApi as standardTransformer,
  transformChannelsToApi,
  transformChannelsToOptions,
} from '../../data-service/transformers/communication';
import { INITIAL_NOTIFICATION_DATA as BASE } from '../generic/communication-integrations/util';

function transformNotificationToApi(pageData) {
  return standardTransformer(pageData);
}

function makeIntegrationLink(name) {
  return `${name.toLowerCase()}.slack.com`;
}

function transformNotificationToEditMode(rawNtfData) {
  return {
    ...rawNtfData,
    channels: transformChannelsToOptions(rawNtfData.channels),
  };
}

function transformNotificationToTest(notificationData) {
  return {
    type: INT_TYPES.slack,
    channels: transformChannelsToApi(notificationData.channels),
    integration_id: notificationData.integrationId,
  };
}

const INITIAL_NOTIFICATION_DATA = { ...BASE };

export {
  transformNotificationToApi,
  makeIntegrationLink,
  transformNotificationToEditMode,
  transformNotificationToTest,
  INITIAL_NOTIFICATION_DATA,
};
