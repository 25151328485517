import React from 'react';
/**
 * Renders a header with a title and an optional description.
 */
import {
  StyledHeaderH2,
  StyledParagraphSemiLight,
} from '../../../../components/styled-components/main';

interface Props {
  title: string;
  description?: string | JSX.Element;
}

const DescriptionRenderer = ({
  description,
}: {
  description?: string | JSX.Element;
}) =>
  React.isValidElement(description) ? (
    description
  ) : (
    <StyledParagraphSemiLight>{description}</StyledParagraphSemiLight>
  );

/**
 * Renders a header with a title and an optional description.
 * @param title - The title of the header.
 * @param description - The optional description of the header.
 * @returns The rendered header component.
 */
const ViewHeaderH2 = ({ title, description }: Props) => (
  <div>
    <StyledHeaderH2 as="h2">{title}</StyledHeaderH2>
    <DescriptionRenderer description={description} />
  </div>
);

export default ViewHeaderH2;
