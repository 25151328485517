import { useQuery } from '@tanstack/react-query';
import { newIntegrationsService } from 'src/features/integrations/data-service/new-integrations-service';
import type { IntegrationTypes } from '../types';

type ListNotifications = {
  integrationType: IntegrationTypes;
  isAutoFetchEnabled?: boolean;
};

const useListNotifications = ({
  integrationType,
  isAutoFetchEnabled = true,
}: ListNotifications) =>
  useQuery({
    queryKey: ['slackNotifications', integrationType],
    queryFn: () => newIntegrationsService.listNotifications(integrationType),
    enabled: isAutoFetchEnabled,
  });

export default useListNotifications;
