/* istanbul ignore file */
import React, { useCallback, useEffect, useState } from 'react';
import { INT_TYPES } from 'src/features/integrations/constants';
import { ModalStateProvider } from 'src/features/integrations/hooks/use-modal-state';
import Carousel from 'src/features/util/components/carousel';
import WelcomeModal from '../../../components/welcome-modal';
import useModal from '../../../hooks/use-modal';
import { oktaSlideMap } from './okta-slide-map';

const MODAL_WIDTH = 960;

const OktaModal = () => {
  const [slides, setSlides] = useState<JSX.Element[]>([]);
  const { modal, closeModal, modalSlideIdx, slideComponent, setModalSlideIdx } =
    useModal();
  const initialSlide = oktaSlideMap.welcome;

  const nextSelectedSlide = slideComponent as unknown as JSX.Element;

  const handleNextSlide = useCallback(() => {
    // We need to make sure that the next slide being set is always the last slide in the array and that there aren't any slides between the previous slide and the next one.

    setSlides((prev) => [...prev.slice(0, modalSlideIdx), nextSelectedSlide]);

    // setModalSlideIdx(slides.length - 1);
  }, [nextSelectedSlide, modalSlideIdx]);

  const removeConfirmCancelSlide = useCallback(() => {
    // If the back button is pressed on the cancel confirm slide, we need to remove it from the slides array
    // istanbul ignore next
    if (slides[modalSlideIdx + 1] === oktaSlideMap.cancelConfirm) {
      const filteredSlides = slides.filter(
        (slide) => slide !== oktaSlideMap.cancelConfirm,
      );
      setSlides(filteredSlides);

      if (modalSlideIdx !== filteredSlides.length - 1) {
        // @ts-expect-error -- this function does accept a param
        setModalSlideIdx(filteredSlides.length - 1);
      }
    }
  }, [modalSlideIdx, slides, setModalSlideIdx]);

  useEffect(() => {
    if (!slides.length) {
      setSlides([initialSlide]);
    }
  }, [slides, initialSlide]);

  useEffect(() => {
    if (nextSelectedSlide) {
      handleNextSlide();
    }
  }, [handleNextSlide, nextSelectedSlide]);

  useEffect(() => {
    removeConfirmCancelSlide();
  }, [removeConfirmCancelSlide]);

  return (
    <ModalStateProvider>
      <WelcomeModal isOpen={modal === INT_TYPES.okta} onClose={closeModal}>
        <Carousel width={MODAL_WIDTH} slides={slides} currentSlideIdx={5} />
      </WelcomeModal>
    </ModalStateProvider>
  );
};

export default OktaModal;
