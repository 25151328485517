import { links } from 'app/common/constants';
import { i18n } from 'i18n';
import { queryClient } from 'src/app/router';
import { integrationsBackendKeys } from 'src/features/integrations/hooks/query-key-factory';
import { INT_TYPES } from '../../../../constants';
import { servicenowService } from '../../../servicenow/services';
import type { CallBackFunc } from '../../types/integrator.types';
import type {
  ServicenowInputs,
  ServicenowState,
} from '../../types/servicenow.types';

type ServicenowCallBack = CallBackFunc<ServicenowInputs, ServicenowState>;

export const connectHandleNext: ServicenowCallBack = ({
  formInputs,
  setError,
  setState,
  history,
}) => {
  setState({ footerStatus: 'pending' });
  return servicenowService
    .createIntegration(formInputs)
    .then((resp) => {
      setState({ footerStatus: 'success' });
      if (resp.success) {
        // we need to invalidate the query so that the new integration is fetched from the backend with the proper pagination details
        queryClient.invalidateQueries({
          queryKey: integrationsBackendKeys.all,
        });
        history.push(
          `${links.integrations.root}/${INT_TYPES.servicenow}/${resp.data.id}`,
          {
            isConfirmed: true,
          },
        );
      }
    })
    .catch((error) => {
      setState({ footerStatus: 'failed' });
      const message = i18n.t('Validate input entry and try again');
      switch (error.response.status) {
        case 401:
          Object.keys(formInputs)
            .filter((f) => f !== 'tenant')
            .map((field) => setError(field, { message }));
          break;
        case 422:
          setError('tenant', { message });
          break;
        default:
          Object.keys(formInputs).map((field) => setError(field, { message }));
      }
    });
};

/* istanbul ignore next*/
export const connectStatusOptions = {
  pending: {
    get title() {
      return i18n.t('Authenticating to ServiceNow');
    },
    get statusText() {
      return i18n.t('In progress');
    },
  },
  success: {
    get title() {
      return i18n.t('Success! ServiceNow is connected');
    },
    get statusText() {
      return i18n.t('Complete');
    },
  },
  failed: {
    get title() {
      return i18n.t('Unable to obtain access token from ServiceNow.');
    },
    get statusText() {
      return i18n.t('Authentication failed. Please try again.');
    },
  },
};
