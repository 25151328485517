import React from 'react';
import Carousel from 'src/features/util/components/carousel';
import WelcomeModal from '../../../components/welcome-modal';
import { useModal } from '../../adcs/hooks';

import { INT_TYPES } from '../../../constants';
import { ModalStateProvider } from '../../../hooks/use-modal-state';
import EventNotificationDetails from './event-notification-details';
import EventNotifications from './event-notifications';
import SignIn from './sign-in';
import SuccessfulConnection from './successful-connection';
import TeamChannels from './team-channels';
import TestNotification from './test-notification';
import Welcome from './welcome';

const MODAL_WIDTH = 960;

const MSTeamsModal = () => {
  const { modal, closeModal, modalSlideIdx } = useModal();

  return (
    <ModalStateProvider>
      <WelcomeModal isOpen={modal === INT_TYPES.msTeams} onClose={closeModal}>
        <Carousel
          width={MODAL_WIDTH}
          slides={[
            <Welcome />,
            <SignIn />,
            <SuccessfulConnection />,
            <TeamChannels />,
            <EventNotifications />,
            <EventNotificationDetails />,
            <TestNotification />,
          ]}
          currentSlideIdx={modalSlideIdx}
        />
      </WelcomeModal>
    </ModalStateProvider>
  );
};

export default MSTeamsModal;
