/* istanbul ignore file */

import type { MouseEvent, TouchEvent } from 'react';

// Block DnD event propagation if elements have "data-no-dnd" attribute
// See: https://github.com/clauderic/dnd-kit/issues/477#issuecomment-1713536492
const handler = ({ nativeEvent: event }: MouseEvent | TouchEvent) => {
  let cur = event.target as HTMLElement;

  while (cur) {
    if (cur.dataset && cur.dataset.noDnd) {
      return false;
    }
    cur = cur.parentElement as HTMLElement;
  }

  return true;
};

export default handler;
