import {
  type QueryObserverResult,
  type RefetchOptions,
  useQuery,
} from '@tanstack/react-query';
import { api } from 'src/app/api/base';
import { URL_BLUEPRINTS, URL_BLUEPRINT_AUTH_CONFIG } from 'src/app/api/urls';
import {
  type AuthBlueprint,
  type AuthConnection,
  type Blueprint,
  type BlueprintAuthConfig,
  NewAuthConfig,
} from '../ManualEnrollment.types';
import { getAuthConnectors } from './useGetAuthConnectors';

export type RefetchBlueprints = (
  options?: RefetchOptions,
) => Promise<QueryObserverResult<AuthBlueprint[], Error>>;

export async function getAuthConfigs(): Promise<BlueprintAuthConfig[]> {
  try {
    const res = await api(URL_BLUEPRINT_AUTH_CONFIG).get({});
    const { data } = res;

    return data.results ?? [];
  } catch (error) {
    return [];
  }
}

export async function getBlueprints(): Promise<Blueprint[]> {
  try {
    const res = await api(URL_BLUEPRINTS).get({});
    const { data } = res;

    return data ?? [];
  } catch (error) {
    return [];
  }
}

function mapAuthConfigToBlueprint(
  authConfigs: BlueprintAuthConfig[],
  blueprints: Blueprint[],
  connections: AuthConnection[],
): AuthBlueprint[] {
  const authConfigsMap = {};

  for (const authConfig of authConfigs) {
    const blueprints = authConfig.blueprints ?? [];

    for (const blueprint of blueprints) {
      authConfigsMap[blueprint] = authConfig;
    }
  }

  return blueprints.map((blueprint: Blueprint): AuthBlueprint => {
    return {
      ...blueprint,
      enrollment_code: blueprint.enrollment_code ?? {
        code: '',
        is_active: false,
      },
      authConfig:
        authConfigsMap[blueprint.id] ??
        new NewAuthConfig(blueprint.id, connections[0]),
    };
  });
}

export async function getAuthConfigBlueprints() {
  const [authConfigs, blueprints, connections] = await Promise.all([
    getAuthConfigs(),
    getBlueprints(),
    getAuthConnectors(),
  ]);

  return mapAuthConfigToBlueprint(authConfigs, blueprints, connections);
}

export function useGetAuthBlueprints() {
  return useQuery({
    queryKey: ['get-manual-enrollment-blueprints'],
    queryFn: getAuthConfigBlueprints,
  });
}
