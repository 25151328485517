import { Button } from '@kandji-inc/bumblebee';
import { Flex, TooltipProvider, styled } from '@kandji-inc/nectar-ui';
import React from 'react';
import { StyledOptionsMenu } from 'src/features/integrations/components/styled-components';
import type { DeviceType } from '../../types';

import { i18n } from 'src/i18n';
import { CardSubheading, DataContainer } from '../styled-components';
import MacOSToolTip from './tooltip/mac-os-tooltip';

type Props = {
  scepUrl?: string;
  username?: string;
  challengeUrl?: string;
  hasDeleteMenuOption?: boolean;
  onEdit: (devicePlatform: 'macOS') => void;
  onDelete: (devicePlatform: DeviceType) => void;
};

type Close = () => void;

const Truncate = styled('p', {
  width: 300,
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
});

const MacOsSettings = (props: Props) => {
  const {
    scepUrl,
    username,
    challengeUrl,
    onEdit,
    hasDeleteMenuOption,
    onDelete,
  } = props;
  const macOS = 'macOS';
  return (
    <TooltipProvider>
      <CardSubheading data-testid="device-platforms:macos-settings">
        <h4 className="b-h4 b-mr">{i18n.t('macOS Settings')}</h4>

        <StyledOptionsMenu
          render={(close: Close) => (
            <>
              <Button
                icon="pencil"
                kind="link"
                onClick={() => {
                  onEdit(macOS);
                  close();
                }}
              >
                {i18n.t('Edit settings')}
              </Button>
              {hasDeleteMenuOption && (
                <Button
                  icon="trash-can"
                  kind="link"
                  theme="error"
                  onClick={() => {
                    onDelete(macOS);
                    close();
                  }}
                >
                  {i18n.t('Delete device platform')}
                </Button>
              )}
            </>
          )}
        >
          <Button size="small" icon="ellipsis" kind="link" />
        </StyledOptionsMenu>
      </CardSubheading>
      <DataContainer>
        <Flex flow="column">
          <Flex gap="xs" css={{ color: '$neutral60' }}>
            <p className="b-txt-light">{i18n.t('SCEP URL')}</p>
            {scepUrl && (
              <MacOSToolTip
                toolTipMessage={i18n.t(
                  'The Simple Certificate Enrollment Protocol (SCEP) URL that was used to connect macOS as a device platform for the integration.',
                )}
                url={scepUrl}
              />
            )}
          </Flex>

          <Truncate>{scepUrl}</Truncate>
        </Flex>
        <div>
          <p className="b-txt-light">{i18n.t('Username')}</p>
          <p>{username}</p>
        </div>

        <Flex flow="column">
          <Flex gap="xs" css={{ color: '$neutral60' }}>
            <p className="b-txt-light">{i18n.t('Challenge URL')}</p>
            {challengeUrl && (
              <MacOSToolTip
                toolTipMessage={i18n.t(
                  'The Challenge URL that was used to connect macOS as a device platform for the integration.',
                )}
                url={challengeUrl}
              />
            )}
          </Flex>

          <Truncate>{challengeUrl}</Truncate>
        </Flex>

        <div>
          <p className="b-txt-light">{i18n.t('Password')}</p>
          <p>•••••••••</p>
        </div>
      </DataContainer>
    </TooltipProvider>
  );
};

export default MacOsSettings;
