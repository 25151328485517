/* istanbul ignore file */

import deepcopy from 'deepcopy';
import type { Node } from 'reactflow';
import { useReactFlow } from 'reactflow';

import type { DraggableLibraryItem } from '../blueprint-flow.types';
import useMap from './use-map';

function useItem(flowId: DraggableLibraryItem['data']['flowId']) {
  const { getNodes, setNodes } = useReactFlow();
  const { updateLibraryItemConflicts } = useMap();

  const findAndRemoveItem = (items: DraggableLibraryItem[]) =>
    items?.filter(({ id }) => id !== flowId);

  /**
   * Deletes a Library Item from its node
   */
  const deleteItem = () => {
    const nodes: Node[] = deepcopy(getNodes());

    nodes.forEach((prevNode) => {
      const node: Node = prevNode;
      node.data = {
        ...node.data,
        items: findAndRemoveItem(node.data.items),
      };
    });

    setNodes(nodes);

    updateLibraryItemConflicts();
  };

  return { deleteItem };
}

export default useItem;
