function makeFieldUpdater(setState) {
  return (field, upd) =>
    setState((curr) => ({
      ...curr,
      [field]: typeof upd === 'function' ? upd(curr[field]) : upd,
    }));
}

function wAmount(amount, text) {
  return `${amount} ${text}${amount === 1 ? '' : 's'}`;
}

export { makeFieldUpdater, wAmount };
