import { createColumnHelper } from '@tanstack/react-table';
/* istanbul ignore file */
import type { z } from 'zod';

import { getIconName } from '../../components/PrismNav/prismNavUtils';
import {
  ON_OFF_CELL_FILTER_OPTIONS,
  OnOffCell,
  YES_NO_CELL_FILTER_OPTIONS,
  YesNoCell,
  getFilteredDisabled as filterDisabled,
  getEnumFilterOptions,
} from '../column-utils';
import { getScharedColumns } from './getSharedColumns';

export const getApplicationFirewallColumns = ({
  applicationFirewallSchema,
}): {
  columnDefs: any[];
  pinnedColumns: string[];
  alwaysHiddenColumns: string[];
} => {
  const { properties } = applicationFirewallSchema.schema;
  const applicationFirewallColumnHelper =
    createColumnHelper<z.infer<typeof properties>>();

  const columnDefs = [
    applicationFirewallColumnHelper.accessor(
      (row) => row['application_firewall.status'],
      {
        id: 'application_firewall.status',
        cell: OnOffCell,
        meta: {
          displayName: properties.status.title,
          displayIcon: getIconName(applicationFirewallSchema.uri),
          filterType: 'boolean',
          filterIcon: 'circle-dot',
          filterOptions: [...ON_OFF_CELL_FILTER_OPTIONS],
          filterDisabled,
        },
      },
    ),

    applicationFirewallColumnHelper.accessor(
      (row) => row['application_firewall.block_all_incoming'],
      {
        id: 'application_firewall.block_all_incoming',
        cell: YesNoCell,
        meta: {
          displayName: properties.block_all_incoming.title,
          displayIcon: getIconName(applicationFirewallSchema.uri),
          filterType: 'boolean',
          filterIcon: 'circle-dot',
          filterOptions: [...YES_NO_CELL_FILTER_OPTIONS],
          filterDisabled,
        },
      },
    ),

    applicationFirewallColumnHelper.accessor(
      (row) => row['application_firewall.allow_signed_applications'],
      {
        id: 'application_firewall.allow_signed_applications',
        cell: YesNoCell,
        meta: {
          displayName: properties.allow_signed_applications.title,
          displayIcon: getIconName(applicationFirewallSchema.uri),
          filterType: 'boolean',
          filterIcon: 'circle-dot',
          filterOptions: [...YES_NO_CELL_FILTER_OPTIONS],
          filterDisabled,
        },
      },
    ),

    applicationFirewallColumnHelper.accessor(
      (row) => row['application_firewall.stealth_mode'],
      {
        id: 'application_firewall.stealth_mode',
        cell: OnOffCell,
        meta: {
          displayName: properties.stealth_mode.title,
          displayIcon: getIconName(applicationFirewallSchema.uri),
          filterType: 'boolean',
          filterIcon: 'circle-dot',
          filterOptions: [...ON_OFF_CELL_FILTER_OPTIONS],
          filterDisabled,
        },
      },
    ),

    applicationFirewallColumnHelper.accessor(
      (row) => row['application_firewall.logging'],
      {
        id: 'application_firewall.logging',
        cell: OnOffCell,
        meta: {
          displayName: properties.logging.title,
          displayIcon: getIconName(applicationFirewallSchema.uri),
          filterType: 'boolean',
          filterIcon: 'circle-dot',
          filterOptions: [...ON_OFF_CELL_FILTER_OPTIONS],
          filterDisabled,
        },
      },
    ),

    applicationFirewallColumnHelper.accessor(
      (row) => row['application_firewall.logging_option'],
      {
        id: 'application_firewall.logging_option',
        meta: {
          displayName: properties.logging_option.title,
          displayIcon: getIconName(applicationFirewallSchema.uri),
          filterType: 'enum',
          filterOptions: getEnumFilterOptions(
            applicationFirewallSchema.schema?.definitions.FirewallLoggingOption
              .enum || [],
          ),
          filterIcon: 'list-check',
          filterDisabled,
        },
      },
    ),

    applicationFirewallColumnHelper.accessor(
      (row) => row['application_firewall.version'],
      {
        id: 'application_firewall.version',
        meta: {
          displayName: properties.version.title,
          displayIcon: getIconName(applicationFirewallSchema.uri),
          defaultHidden: true,
          filterType: 'version',
          filterIcon: 'hashtag',
          filterDisabled,
        },
      },
    ),

    applicationFirewallColumnHelper.accessor(
      (row) => row['application_firewall.unloading'],
      {
        id: 'application_firewall.unloading',
        cell: YesNoCell,
        meta: {
          displayName: properties.unloading.title,
          displayIcon: getIconName(applicationFirewallSchema.uri),
          defaultHidden: true,
          filterType: 'boolean',
          filterIcon: 'circle-dot',
          filterOptions: [...YES_NO_CELL_FILTER_OPTIONS],
          filterDisabled,
        },
      },
    ),

    ...getScharedColumns(applicationFirewallSchema),
  ];

  return { columnDefs, pinnedColumns: [], alwaysHiddenColumns: [] };
};
