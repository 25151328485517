import adcs from './adcs/config';
import msCompliance from './ms-device-compliance/config';
import okta from './okta/config';
import servicenow from './servicenow/config';

const integrations = {
  [okta.type]: okta,
  [servicenow.type]: servicenow,
  [adcs.type]: adcs,
  [msCompliance.type]: msCompliance,
};

export default integrations;
