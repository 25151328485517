/* istanbul ignore file */
const BooksIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="60"
    viewBox="0 0 29.6484 28.2715"
    style={{ minWidth: '60px' }}
  >
    <g>
      <rect height="28.2715" opacity="0" width="29.6484" x="0" y="0" />
      <path
        d="M1.57227 27.5977L3.81836 27.5977C4.85352 27.5977 5.39062 27.0312 5.39062 26.0254L5.39062 4.90234C5.39062 3.89648 4.85352 3.33008 3.81836 3.33008L1.57227 3.33008C0.527344 3.33008 0 3.89648 0 4.90234L0 26.0254C0 27.0312 0.527344 27.5977 1.57227 27.5977ZM8.32031 27.5977L13.2617 27.5977C14.3066 27.5977 14.834 27.0312 14.834 26.0254L14.834 9.17969C14.834 8.17383 14.3066 7.60742 13.2617 7.60742L8.32031 7.60742C7.27539 7.60742 6.74805 8.17383 6.74805 9.17969L6.74805 26.0254C6.74805 27.0312 7.27539 27.5977 8.32031 27.5977ZM9.36523 11.8359C8.96484 11.8359 8.67188 11.5332 8.67188 11.1426C8.67188 10.7715 8.96484 10.4785 9.36523 10.4785L12.2266 10.4785C12.6172 10.4785 12.9102 10.7715 12.9102 11.1426C12.9102 11.5332 12.6172 11.8359 12.2266 11.8359ZM9.36523 24.7363C8.96484 24.7363 8.67188 24.4336 8.67188 24.0527C8.67188 23.6719 8.96484 23.3789 9.36523 23.3789L12.2266 23.3789C12.6172 23.3789 12.9102 23.6719 12.9102 24.0527C12.9102 24.4336 12.6172 24.7363 12.2266 24.7363ZM17.7637 27.5977L20.6934 27.5977C21.7285 27.5977 22.2656 27.0312 22.2656 26.0254L22.2656 1.57227C22.2656 0.566406 21.7285 0 20.6934 0L17.7637 0C16.7285 0 16.1914 0.566406 16.1914 1.57227L16.1914 26.0254C16.1914 27.0312 16.7285 27.5977 17.7637 27.5977ZM26.0254 27.6562L27.8906 27.4121C28.916 27.2949 29.3848 26.6797 29.2773 25.6738L27.0215 5.14648C26.9141 4.14062 26.3281 3.62305 25.293 3.75L23.4375 4.00391C22.4023 4.13086 21.9434 4.73633 22.0508 5.73242L24.3066 26.2695C24.4141 27.2656 24.9902 27.7734 26.0254 27.6562Z"
        fill="#98989d"
      />
    </g>
  </svg>
);

export default BooksIcon;
