import React, { useEffect, useState } from 'react';
import { Handle, Position } from 'reactflow';
import { useShallow } from 'zustand/react/shallow';

import { Flex, styled, useDialog } from '@kandji-inc/nectar-ui';

import Overlay from '../components/Overlay';
import { getDeleteCopy } from '../helpers';
import { useAncestry, useConditional } from '../hooks';
import DeleteModal from '../modals/delete';
import useBlueprintFlow from '../store';
import DeleteButton from './parts/actions/DeleteButton';

const Container = styled(Flex, {
  display: 'inline-flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  gap: '12px',
  borderRadius: '10px',
  border: '1px solid $neutral20',
  background: '#FBFDFE',

  variants: {
    isEditingAssignments: {
      true: {
        padding: '20px 18px 10px 18px',
      },
      false: {
        padding: '20px 18px',
      },
    },
  },
});

const handleStyle = { opacity: 0 };

export type ConditionalNodeProps = {
  id: string;
  xPos: number;
  yPos: number;
  data: {
    path: string;
    width?: number;
    height?: number;
  };
};

function ConditionalNode(props: ConditionalNodeProps) {
  const { id, data, xPos, yPos } = props;
  const { path, width, height } = data;
  const [
    isEditingAssignments,
    model,
    setIsDeletingNode,
    isDeletingNode,
    descendantsToBeDeleted,
  ] = useBlueprintFlow(
    useShallow((state) => [
      state.isEditingAssignments,
      state.model,
      state.setIsDeletingNode,
      state.isDeletingNode,
      state.descendantsToBeDeleted,
    ]),
  );
  const { deleteConditional } = useConditional({ id, path });
  const {
    getConditionalDescendantsUpToRouter,
    getUniqueDescendantsUpToRouter,
  } = useAncestry();
  const [isOpen, toggle] = useDialog(false);
  const [conditionalDescendants, setConditionalDescendants] = useState([]);
  const [allDescendants, setAllDescendants] = useState<string[]>([]);
  const isAwaitingPosition = xPos === 0 && yPos === 0;
  const isDeletingThisNode =
    isDeletingNode && descendantsToBeDeleted.includes(id as never);

  useEffect(() => {
    setConditionalDescendants(getConditionalDescendantsUpToRouter(id));
    setAllDescendants(
      getUniqueDescendantsUpToRouter(id)
        .map(
          /* istanbul ignore next */ ({ id, descendantType }) =>
            descendantType === 'conditional' || descendantType === 'edge'
              ? id
              : null,
        )
        .filter(Boolean),
    );
  }, [model.nodes]);

  const deleteCopy = getDeleteCopy(
    'conditional',
    conditionalDescendants.length,
  );
  return (
    <>
      <Container
        isEditingAssignments={isEditingAssignments}
        css={{
          justifyContent: 'end',
          minWidth: width,
          minHeight: height,
          visibility: isAwaitingPosition ? 'hidden' : 'visible',
          ...(isDeletingThisNode
            ? {
                backgroundColor: '$red10',
                border: '1px solid $red60',
              }
            : {}),
        }}
      >
        <Flex wFull flow="column" gap="sm">
          {isEditingAssignments && !isDeletingNode && (
            <Flex alignItems="center" justifyContent="end">
              <DeleteButton
                onClick={() => {
                  setIsDeletingNode(true, [...allDescendants, id]);
                  toggle(true);
                }}
                name="delete-conditional-block"
                tooltip="Delete conditional block"
              />
            </Flex>
          )}
        </Flex>
        <Overlay
          isHidden={
            /* istanbul ignore next */ !isDeletingNode || isDeletingThisNode
          }
          style={{ borderRadius: '10px' }}
        />
      </Container>

      <Handle
        type="target"
        position={Position.Left}
        isConnectable={false}
        style={handleStyle}
      />

      <DeleteModal
        title="Delete conditional block?"
        content={deleteCopy}
        isOpen={isOpen}
        toggle={(isToggled) => {
          /* istanbul ignore next */
          if (!isToggled) {
            setIsDeletingNode(isToggled, []);
          }
          toggle(isToggled);
        }}
        runDelete={deleteConditional}
      />
    </>
  );
}

export default ConditionalNode;
