import { i18n } from 'i18n';
import certIcon from '../../assets/cert-icon.svg';
import multiDownloadIcon from '../../assets/download-multi.svg';

function getFirstSlideTickets() {
  return [
    {
      text: i18n.t(
        'You will download our AD CS connector and then install it on a Windows server client. Supports Windows 12 and above.',
      ),
      title: i18n.t('Install the AD CS connector'),
      link: 'https://support.kandji.io/support/solutions/articles/72000568715-active-directory-certificate-services-ad-cs-integration-ad-cs-connector-installation',
      icon: multiDownloadIcon,
    },
    {
      text: i18n.t(
        'Create Certificate or Wi-Fi profile Library Items to issue certificates through your certificate authority for device security and compliance.',
      ),
      title: i18n.t('AD CS security and compliance'),
      link: 'https://support.kandji.io/support/solutions/articles/72000569068-active-directory-certificate-services-ad-cs-integration-overview',
      icon: certIcon,
    },
  ];
}

export default getFirstSlideTickets;
