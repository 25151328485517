import { Checkbox } from '@kandji-inc/bumblebee';
import React from 'react';
import styled from 'styled-components';

type Props = Readonly<{
  checked: boolean | undefined;
  icon: string;
  label: string;
  altText: string;
  onChange: () => void;
}>;

type StyledOptionsProps = Readonly<{
  checked: boolean | undefined;
}>;

const StyledCheckboxContainer = styled.div`
  display: inline-block;
`;

const StyledOption = styled.div<StyledOptionsProps>`
  display: flex;
  align-items: center;
  justify-content: center;

  border: 1px solid
    ${(props) =>
      props.checked
        ? 'var(--b-link-blue-active)'
        : 'var(--b-neutrals-grey-light)'};
  border-radius: 10px;
  width: 284px;
  height: 96px;

  &:hover {
    border: 1px solid var(--b-link-blue-hover-focus);
    cursor: pointer;
  }

  & > ${StyledCheckboxContainer} {
    margin-right: var(--b-gap1);
  }
`;

const ConfigurationOption = (props: Props) => {
  const { checked, icon, altText, label, onChange } = props;

  return (
    <StyledOption checked={checked} onClick={onChange}>
      <StyledCheckboxContainer>
        <Checkbox checked={checked} name={label} />
      </StyledCheckboxContainer>
      <img src={icon} alt={altText} />
      {label}
    </StyledOption>
  );
};

export default ConfigurationOption;
