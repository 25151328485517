const moment = require('moment-timezone');
const _ = require('lodash');

export const noop = () => {};

export const slugify = (text) => {
  const a = 'àáäâèéëêìíïîòóöôùúüûñçßÿœæŕśńṕẃǵǹḿǘẍźḧ·/_,:;';
  const b = 'aaaaeeeeiiiioooouuuuncsyoarsnpwgnmuxzh------';
  const p = new RegExp(a.split('').join('|'), 'g');
  return (
    (text &&
      text
        .toString()
        .toLowerCase()
        // Replace spaces with -
        .replace(/\s+/g, '-')
        // Replace special chars
        .replace(p, (c) => b.charAt(a.indexOf(c)))
        // Replace & with 'and'
        .replace(/&/g, '-and-')
        // Remove all non-word chars
        .replace(/[^\w-]+/g, '')
        // Replace multiple - with single -
        .replace(/--+/g, '-')
        // Replace something with single -
        .replace(/[\s_-]+/g, '-')
        // Trim - from start of text
        .replace(/^-+/, '')
        // Trim - from end of text
        .replace(/-+$/, '')
        // Trim spaces from start and end of text
        .trim()) ||
    null
  );
};

export const copyToClipboard = (str) => {
  navigator.clipboard.writeText(str);
};

export const formatTime = (
  timestamp,
  hideSeconds = false,
  errorMessage = 'Invalid timestamp format',
  dateOnly = false,
  passRelativity = false,
  format = 'MM/DD/YY',
  tz = null,
  disableRelativity = false,
) => {
  // it is slightly modified
  // formatTime() from src/app/components/common/helpers.js
  // todo: refactor, maybe create a separate component

  const relativeMomentDateFormat = {
    lastDay: '[Yesterday]',
    nextDay: '[Tomorrow]',
    lastWeek: format,
    nextWeek: format,
    sameElse: format,
    sameDay(now) {
      const diff = moment.duration(now.diff(this));
      const diffAsHours = Math.floor(diff.asHours());
      const diffAsMinutes = Math.floor(diff.asMinutes());

      if (diffAsHours > 1) {
        return `[${diffAsHours} hours ago]`;
      }
      if (diffAsHours === 1) {
        return `[1 hour ago]`;
      }
      if (diffAsMinutes > 1) {
        return `[${diffAsMinutes} minutes ago]`;
      }
      if (diffAsMinutes === 1) {
        return '[1 minute ago]';
      }
      return '[Less than 1 minute ago]';
    },
  };

  const momentObject =
    typeof timestamp === 'number'
      ? moment.unix(Number(timestamp.toString().substring(0, 10)))
      : moment(timestamp);

  const userTimezone = tz || Intl.DateTimeFormat().resolvedOptions().timeZone;

  if (disableRelativity || passRelativity) {
    return momentObject
      .tz(userTimezone)
      .format(`${format}${hideSeconds ? '' : ' [at] LTS'}`);
  }

  if (!momentObject.isValid()) {
    return errorMessage;
  }

  if (dateOnly) {
    return momentObject.tz(userTimezone).format(format);
  }

  return momentObject.tz(userTimezone).calendar(null, relativeMomentDateFormat);
};
