import { Button, styled } from '@kandji-inc/nectar-ui';
import * as React from 'react';
import { useHistory } from 'react-router-dom';
import { links } from 'src/app/common/constants';
import notConfiguredImg from './assets/not_configured.png';

const NotConfiguredContainer = styled('div', {
  width: '100%',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '$10',
});

const Image = styled('img', {
  marginBottom: '16px',
});

const Header = styled('span', {
  fontWeight: 'bold',
});

const Description = styled('span', {
  marginBottom: '14px',
});

export function ADENotConfigured() {
  const history = useHistory();

  return (
    <NotConfiguredContainer>
      <Image src={notConfiguredImg} />
      <Header>Automated Device Enrollment is not configured</Header>
      <Description>Set it up in your Apple Integrations settings.</Description>
      <Button onClick={() => history.push(links.myCompany.integrations)}>
        Visit Apple Integrations settings
      </Button>
    </NotConfiguredContainer>
  );
}
