import PropTypes from 'prop-types';
import React from 'react';
import { useOldForm } from 'src/old-honey-form';

import { Button } from 'theme/components/molecules/Action';

const ButtonSave = ({ label, disabled }) => {
  const { handleSubmit } = useOldForm();
  return (
    <Button
      disabled={disabled}
      tier="one"
      label={label}
      onClick={handleSubmit}
      loader
    />
  );
};

ButtonSave.propTypes = {
  label: PropTypes.string,
  disabled: PropTypes.bool,
};

ButtonSave.defaultProps = {
  label: 'Save',
  disabled: false,
};

export { ButtonSave };
