import { Banner, Button, Chip } from '@kandji-inc/bumblebee';
import React from 'react';
import { useModalState } from 'src/features/integrations/hooks/use-modal-state';
import styled from 'styled-components';

import { i18n } from 'i18n';
import history from 'src/app/router/history';
import FooterButtons from '../../../components/footer-buttons';
import useModal from '../../../hooks/use-modal';
import channelChipIcon from '../assets/channel-chip-icon.svg';
import notificationBellIcon from '../assets/notification-bell-icon.svg';
import { useGetMSTeamsIntegrations, useTestNotification } from '../hooks';

const StyledSlideHeader = styled.h1`
  text-align: center;
  margin-bottom: 12px;
`;

const ChannelNotificationContainer = styled.div`
  display: grid;
  justify-items: center;
  gap: 12px;
`;

const Container = styled.div`
  display: grid;
  grid-template-rows: 1fr auto;
  height: 100%;
`;

const StyledModalContent = styled.div`
  display: grid;
  gap: 12px;
  align-items: center;
  justify-items: center;
  max-width: 520px;
  margin: auto;
  text-align: center;

  & > ${ChannelNotificationContainer} {
    margin-top: 20px;
  }
`;

const ChipContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
`;

const ChannelChip = ({ text }) => (
  <Chip
    text={text}
    kind="tertiary"
    iconLeft={channelChipIcon}
    asImage
    size="lg"
  />
);

const TestNotification = () => {
  const { closeModal, previousModal } = useModal();
  const { state: modalState } = useModalState();

  const mutation = useTestNotification();

  const { refetch: fetchIntegrations } = useGetMSTeamsIntegrations({
    isAutoFetchEnabled: false,
  });

  const onClose = () => closeModal();
  const handleNext = async () => {
    await fetchIntegrations();
    closeModal();
    history.push('/integrations/ms_teams');
  };

  const { selectedChannels, integrationId } = modalState ?? {};

  const handleSendTestNotification = () => {
    const channelIds = selectedChannels.map((channel) => channel.id);

    mutation.mutate({ integrationId, channels: channelIds });
  };

  const { data, isPending: loading } = mutation;

  const notificationStatus = data?.data;

  const getNotificationBanner = (isSuccessful) => {
    if (isSuccessful) {
      return (
        <Banner icon="circle-check" theme="success">
          <p>
            {i18n.t(
              'Test notification sent. Please check your Microsoft Teams account.',
            )}
          </p>
        </Banner>
      );
    }

    return (
      <Banner icon="circle-xmark" theme="error">
        <p>
          {i18n.t(
            'Test notification failed to send. Please check your Microsoft Teams account and try again later.',
          )}
        </p>
      </Banner>
    );
  };

  const hasNotificationStatus =
    notificationStatus && Object.keys(notificationStatus)?.includes('success');

  return (
    <Container>
      <StyledModalContent>
        <img
          src={notificationBellIcon}
          className="b-mb2"
          alt={i18n.t('header icon')}
        />
        <StyledSlideHeader>{i18n.t('Test notification')}</StyledSlideHeader>
        <p className="b-txt">
          {i18n.t(
            'Send a test notification before adding this event notification. We will provide a sample message to the channels specified below.',
          )}
        </p>
        <ChannelNotificationContainer>
          <p className="b-txt-bold">
            {i18n.t('The following channels will receive a test notification:')}
          </p>
          <ChipContainer>
            {selectedChannels?.map(({ id, name }) => (
              <ChannelChip key={id} text={name} />
            ))}
          </ChipContainer>
          <Button
            kind="link"
            icon={loading ? 'arrows-rotate' : 'paper-plane'}
            onClick={handleSendTestNotification}
            isProgress={loading}
          >
            {i18n.t('Send test notification')}
          </Button>
          {hasNotificationStatus &&
            getNotificationBanner(notificationStatus?.success)}
        </ChannelNotificationContainer>
      </StyledModalContent>
      <FooterButtons
        onClose={onClose}
        handleNext={handleNext}
        showBackButton
        backOnClick={previousModal}
        nextButtonText={i18n.t('Done')}
        stepperIdx={3}
        stepsAmount={4}
        showStepper
      />
    </Container>
  );
};

export default TestNotification;
