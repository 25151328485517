import type { History } from 'history';
import * as React from 'react';

interface Callback {
  (): void;
}

export const useConfirmNavigationFromPage = (history: History) => {
  const [isDirty, setIsDirty] = React.useState(false);
  const [userHasConfirmedNavigation, setUserHasConfirmedNavigation] =
    React.useState(false);
  const [nextLocation, setNextLocation] = React.useState<null | string>(null);
  const [isConfirmNavigationModalOpen, setIsConfirmNavigationModalOpen] =
    React.useState(false);

  const noop = () => {};
  const setDirtyCallback = React.useRef(noop);
  React.useEffect(() => {
    if (typeof setDirtyCallback.current === 'function') {
      setDirtyCallback.current();
      setDirtyCallback.current = noop;
    }
  }, [isDirty]);

  const setDirty = React.useCallback((cb?: Callback) => {
    if (cb && typeof cb === 'function') {
      setDirtyCallback.current = cb;
    }
    setIsDirty(true);
  }, []);

  const setClean = React.useCallback((cb?: Callback) => {
    if (cb && typeof cb === 'function') {
      setDirtyCallback.current = cb;
    }
    setIsDirty(false);
  }, []);

  const handleBlockedNavigation = (location) => {
    if (
      isDirty &&
      !userHasConfirmedNavigation &&
      !location.pathname.includes('/prism')
    ) {
      setIsConfirmNavigationModalOpen(true);
      setNextLocation(location.pathname);
      return false;
    }
    return true;
  };

  const confirmNavigation = React.useCallback(
    /* istanbul ignore next */
    (e: BeforeUnloadEvent) => {
      if (isDirty) {
        e.preventDefault();
        e.returnValue = '';
      }
    },
    [isDirty],
  );

  React.useEffect(() => {
    window.addEventListener('beforeunload', confirmNavigation);

    return () => {
      window.removeEventListener('beforeunload', confirmNavigation);
    };
  }, [confirmNavigation]);

  React.useEffect(() => {
    if (userHasConfirmedNavigation && nextLocation) {
      history.push(nextLocation);
    }
  }, [history, nextLocation, userHasConfirmedNavigation]);

  return {
    isDirty,
    setDirty,
    setClean,
    handleBlockedNavigation,
    isConfirmNavigationModalOpen,
    setUserHasConfirmedNavigation,
    setIsConfirmNavigationModalOpen,
  };
};
