/* istanbul ignore file */

import { newIntegrationsAxios } from 'app/api/base';

class ConnectorService {
  constructor() {
    this.resource = 'v1/adcs/connector/';
    this.installerResource = 'v1/adcs/';
  }

  // CREATE ------------------------------ //

  async createConnector(data) {
    return newIntegrationsAxios(this.resource)
      .post(data)
      .then((r) => r.data);
  }

  // READ -------------------------------- //

  async getConnector(id) {
    return newIntegrationsAxios(`${this.resource}${id}/`)
      .get()
      .then((r) => r.data);
  }

  async listConnectors() {
    return newIntegrationsAxios(this.resource)
      .get()
      .then((r) => r.data.results);
  }

  async getConnectorLimit() {
    return newIntegrationsAxios(`${this.resource}limit`)
      .get()
      .then((r) => r.data.limit);
  }

  // UPDATE ------------------------------ //

  async updateConnectorReplaceAuthorities(connectorId, authorityIds) {
    if (!Array.isArray(authorityIds)) {
      throw new Error(
        'This method expects authorityIds to be an array of authority IDs',
      );
    }
    const data = {
      adcs_authorities: authorityIds,
    };
    return newIntegrationsAxios(`${this.resource}${connectorId}/`)
      .patch(data)
      .then((r) => r.data);
  }

  // DELETE ------------------------------ //

  async deleteConnector(id) {
    return newIntegrationsAxios(`${this.resource}${id}`)
      .delete()
      .then((r) => r.data);
  }

  // Download Installer  ------------------------------ //
  async getInstallerDownloadLink() {
    return newIntegrationsAxios(`${this.installerResource}installer`)
      .get()
      .then((r) => r.data);
  }
}

const connectorService = new ConnectorService();

export default ConnectorService;
export { connectorService };
