import { CollapsableSearchInput, DateRangeSelect } from '@kandji-inc/bumblebee';
import { useDateFormat } from 'app/components/common/helpers';
import React from 'react';
import { i18n } from 'src/i18n';
import MultiSelectDropdown from 'theme/components/atoms/MultiSelectDropdown';

// TODO:
//  - Replace with MultiSelectSearch from bumblebee when there's an ability to hide the search field
//  - Replace options={[typesObject]} with options={typeOptions}
function ActivityTypeSelect(typesObject) {
  return ({ onChange, value }) => (
    <div className="select-types-dropdown activity-table__filters_gap">
      <MultiSelectDropdown
        name="dsa"
        options={[typesObject]}
        disabled={false}
        onToggle={onChange}
        selectedValues={value}
        emptyPlaceholder={i18n.t('Filter activity')}
        withSearch={false}
      />
    </div>
  );
}

function ActivityDateRangeSelect({ onChange, value }) {
  const dateFormat = useDateFormat({ asLuxon: true });

  return (
    <DateRangeSelect
      onChange={onChange}
      value={value}
      format={dateFormat}
      datesPreset={DateRangeSelect.getDefaultDatesPreset(dateFormat)}
      className="activity-table__filters_gap"
    />
  );
}

function ActivityCollapsableSearch({ onChange, value }) {
  return (
    <CollapsableSearchInput
      placeholder={i18n.t('Search')}
      value={value}
      onChange={onChange}
      className="activity-table__filters_gap"
    />
  );
}

export {
  ActivityTypeSelect,
  ActivityDateRangeSelect,
  ActivityCollapsableSearch,
};
