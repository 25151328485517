import { Checkbox } from '@kandji-inc/bumblebee';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import type { RegisterOptions } from 'react-hook-form';
import styled from 'styled-components';
import { useTrigger } from '../../hooks';

type OptionProps = {
  name: string;
  icon: string;
  label: string;
  altText: string;
  default?: boolean;
};

type Props = Readonly<{
  options: Array<OptionProps>;
  validate?: RegisterOptions['validate'];
}>;

type StyledOptionsProps = Readonly<{
  checked: boolean | undefined;
}>;

const StyledConfigurationOptions = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: var(--b-gap2);
`;

const StyledCheckboxContainer = styled.div`
  display: inline-block;
`;

const StyledOption = styled.div<StyledOptionsProps>`
  display: flex;
  align-items: center;
  justify-content: left;
  padding: 0 var(--b-gap2);

  border: 1px solid
    ${(props) =>
      props.checked
        ? 'var(--b-link-blue-active)'
        : 'var(--b-neutrals-grey-light)'};
  border-radius: 10px;
  width: 100%;
  height: 96px;

  &:hover {
    border: 1px solid var(--b-link-blue-hover-focus);
    cursor: pointer;
  }

  & > ${StyledCheckboxContainer} {
    margin-right: var(--b-gap1);
  }
`;

const ViewCheckboxCard = ({ options, validate }: Props) => {
  const { control, trigger } = useFormContext();
  useTrigger(options, trigger);

  return (
    <StyledConfigurationOptions>
      {options.map((v) => (
        <Controller
          key={v.name}
          control={control}
          name={v.name}
          defaultValue={v.default}
          rules={{ validate }}
          render={({ field }) => (
            <StyledOption
              checked={field.value}
              onClick={() => field.onChange(!field.value)}
            >
              <StyledCheckboxContainer>
                <Checkbox checked={field.value} name={v.label} />
              </StyledCheckboxContainer>
              <img src={v.icon} alt={v.altText} />
              {v.label}
            </StyledOption>
          )}
        />
      ))}
    </StyledConfigurationOptions>
  );
};

export default ViewCheckboxCard;
