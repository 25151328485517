import React from 'react';
import { Panel } from 'reactflow';

import { Flex, Toaster_UNSTABLE as Toaster } from '@kandji-inc/nectar-ui';

import { NOTIFICATION_POSITIONS } from '../constants';
import useBlueprintFlow from '../store';

function Notifications() {
  const notificationPosition = useBlueprintFlow(
    (state) => state.notificationPosition,
  );

  switch (notificationPosition) {
    case NOTIFICATION_POSITIONS.top:
      return (
        <Panel position="top-center" className="notification-top">
          <Toaster />
        </Panel>
      );
    case NOTIFICATION_POSITIONS['bottom-left']:
    default:
      return (
        <Flex className="notification-bottom-left">
          <Toaster />
        </Flex>
      );
  }
}

export default Notifications;
