/* istanbul ignore file */
import React from 'react';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';

import { usePermissions } from 'src/contexts/account';

import BlueprintFlowRouter from '../blueprint-flow/route';
import Blueprint from './blueprint/blueprint';
import ConnectedBlueprintsList from './blueprints-list/blueprints-list';

const Blueprints = () => {
  const { path } = useRouteMatch();
  const { canManageBlueprints } = usePermissions();

  return (
    <Switch>
      <Route
        exact
        path={`${path}/maps/add`}
        component={
          !canManageBlueprints
            ? () => <Redirect to="/blueprints" />
            : BlueprintFlowRouter
        }
      />
      <Route path={`${path}/maps/:id`} component={BlueprintFlowRouter} />
      <Route path={`${path}/:id/:tab?`} component={Blueprint} />
      <Route exact path={path} component={ConnectedBlueprintsList} />
      <Redirect to="/blueprints" />
    </Switch>
  );
};

export default Blueprints;
