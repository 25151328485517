/* istanbul ignore file */
const AutoAppsIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="60"
    viewBox="0 0 36.0449 30.6543"
    style={{ minWidth: '60px' }}
  >
    <g>
      <rect height="30.6543" opacity="0" width="36.0449" x="0" y="0" />
      <path
        d="M31.6406 7.90039L31.6406 22.7832L34.4043 22.7832C35.1172 22.7832 35.6836 23.3594 35.6836 24.082C35.6836 24.8047 35.1172 25.3809 34.4043 25.3809L1.2793 25.3809C0.566406 25.3809 0 24.8047 0 24.082C0 23.3594 0.566406 22.7832 1.2793 22.7832L4.04297 22.7832L4.04297 7.90039C4.04297 6.18164 5.0293 5.29297 6.66992 5.29297L15.5078 5.29297L15.5078 7.02148L7.10938 7.02148C6.2207 7.02148 5.77148 7.46094 5.77148 8.35938L5.77148 22.7832L29.9121 22.7832L29.9121 8.35938C29.9121 7.46094 29.4629 7.02148 28.5742 7.02148L20.1758 7.02148L20.1758 5.29297L29.0137 5.29297C30.7324 5.29297 31.6406 6.18164 31.6406 7.90039Z"
        fill="#98989d"
      />
      <path
        d="M17.0703 2.34375L17.0703 12.6074L17.168 15.0879L14.6484 12.334L13.8086 11.5039C13.6621 11.3574 13.457 11.2695 13.2324 11.2695C12.793 11.2695 12.4805 11.5918 12.4805 12.0215C12.4805 12.2363 12.5684 12.4316 12.7148 12.5879L17.2656 17.1484C17.4512 17.334 17.6562 17.4316 17.8418 17.4316C18.0273 17.4316 18.2422 17.334 18.418 17.1484L22.9688 12.5879C23.1152 12.4316 23.2031 12.2363 23.2031 12.0215C23.2031 11.5918 22.8906 11.2695 22.4512 11.2695C22.2266 11.2695 22.0312 11.3574 21.875 11.5039L21.0352 12.334L18.5156 15.0879L18.623 12.6074L18.623 2.34375C18.623 1.86523 18.2422 1.5625 17.8418 1.5625C17.4414 1.5625 17.0703 1.86523 17.0703 2.34375Z"
        fill="#98989d"
      />
    </g>
  </svg>
);

export default AutoAppsIcon;
