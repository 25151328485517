import { Select } from '@kandji-inc/bumblebee';
import { exact, func, string } from 'prop-types';
import React from 'react';
import { EDIT_RECOMMENDED_CATEGORY_NAME_OPTIONS } from '../constants';

const CategoryNameSelect = ({ nameSelect, setNameSelect }) => (
  <div className="k-self-service-side-panel-category-name-select">
    <Select
      value={nameSelect}
      onChange={setNameSelect}
      options={EDIT_RECOMMENDED_CATEGORY_NAME_OPTIONS}
    />
  </div>
);

CategoryNameSelect.propTypes = {
  nameSelect: exact({
    label: string,
    value: string,
  }),
  setNameSelect: func,
};

CategoryNameSelect.defaultProps = {
  nameSelect: null,
  setNameSelect: () => {},
};

export default CategoryNameSelect;
