import * as React from 'react';

export function usePrettyLoading(isLoading: boolean, debounce = 250) {
  const [isPrettyLoading, setIsPrettyLoading] = React.useState(false);

  React.useEffect(() => {
    let timeout: ReturnType<typeof setTimeout>;
    if (isLoading) {
      setIsPrettyLoading(true);
    } else {
      timeout = setTimeout(() => {
        setIsPrettyLoading(false);
      }, debounce);
    }

    return () => {
      clearTimeout(timeout);
    };
  }, [isLoading]);

  return isPrettyLoading;
}
