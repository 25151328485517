import { NoContent } from '@kandji-inc/bumblebee';
import React from 'react';
import styled from 'styled-components';

type Props = {
  header: string;
  text: string;
  children?: JSX.Element;
};

const StyledChildren = styled.div`
  margin-bottom: var(--b-gap2);
`;

const StyledNoContent = styled(NoContent)`
  margin: var(--b-gap2) 0;
  & > img {
    height: 64px;
    width: 160px;
    margin-bottom: var(--b-gap);
  }
`;

const Container = styled.div`
  max-width: 480px;
  justify-self: center;
  margin: auto;
`;

const NoNotificationsMessage = ({ header, text, children }: Props) => (
  <Container>
    <StyledNoContent header={header} text={text} />
    {children && <StyledChildren>{children}</StyledChildren>}
  </Container>
);

export default NoNotificationsMessage;
