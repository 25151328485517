/* istanbul ignore file - legacy code, moved */
import styled from 'styled-components';

const TagThemes = {
  secondary: `
    background-color: #FFFFFF!important;
    border: 1px solid rgb(236, 238, 243)!important;
    color: #8F97AE!important;
    font-weight: 400!important;
  `,
};

export const Tag = styled('span')`
  ${(props) => TagThemes[props.theme]};
  background-color: rgba(
    238,
    240,
    246,
    ${(props) => (props.light ? '.5' : '1')}
  );
  border-radius: 3px;
  height: 22px;
  padding: 8px 8px 7px 8px;
  font-weight: ${(props) => (props.fontDark ? '400' : '500')};
  white-space: nowrap;
  font-family: var(--font-family-primary);
  font-size: 12px;
  line-height: 14px;
  display: flex;
  align-items: center;
  text-align: center;
  color: rgba(
    143,
    151,
    174,
    ${(props) => (props.fontDark || !props.light ? '1' : '.5')}
  );
  &:not(:last-of-type) {
    margin-right: 8px;
  }
`;
