import { Button, TextInput } from '@kandji-inc/bumblebee';
import React, { useEffect, useState } from 'react';

const Exclusions = (props) => {
  const {
    param,
    update,
    isDisabled,
    validators,
    buttonLabel,
    inputLabel,
    inputPlaceholder,
    validations,
  } = props;
  const { details } = param;
  const [exclusions, setExclusions] = useState(details || []);
  const [invalids, setInvalids] = useState(details?.map((d) => false) || []);

  const {
    Exclusions: validateParam,
    Exclusions_excludedDirectory: validateExcludedDirectory,
  } = validations;

  useEffect(() => {
    if (isDisabled) {
      setExclusions(details);
      setInvalids(details?.map((d) => false) || []);
    }
  }, [param, isDisabled]);

  useEffect(() => {
    if (!isDisabled) {
      const time = setTimeout(() => {
        if (!exclusions.length) {
          update({ isInvalid: true });
        } else {
          update({
            details: exclusions,
            isInvalid: invalids.some(Boolean),
          });
        }
      }, 250);

      return () => clearTimeout(time);
    }
  }, [exclusions, invalids]);

  const addExclusion = () => {
    setExclusions((prev) => [...prev, '']);
    setInvalids((prev) => [...prev, true]);
  };
  const onTrash = (i) => {
    setExclusions((prev) => prev.filter((_, idx) => idx !== i));
    setInvalids((prev) => prev.filter((_, idx) => idx !== i));
  };

  return (
    <div className="">
      {exclusions.map((exclusion, idx) => {
        return (
          <div
            key={idx}
            className="b-mb3 b-flex"
            style={{ alignItems: 'flex-end' }}
          >
            <div className="flex-6">
              <TextInput
                fieldsGrid
                disabled={isDisabled}
                label={inputLabel}
                value={exclusion}
                placeholder={inputPlaceholder}
                onChange={(e) => {
                  const val = e.target.value;
                  setExclusions((prev) => {
                    prev[idx] = val;
                    return [...prev];
                  });
                }}
                validator={validators}
                onInvalidate={(isInvalid) => {
                  setInvalids((prev) => [
                    ...prev.map((c, i) => (i === idx ? isInvalid : c)),
                  ]);
                }}
                {...validateExcludedDirectory.syncInvalid(invalids[idx], {
                  key: `excluded-directory-${idx}`,
                })}
              />
            </div>
            <Button
              className="b-ml-tiny"
              onClick={() => onTrash(idx)}
              disabled={isDisabled}
              kind="link"
              icon="trash-can"
              theme="error"
            />
          </div>
        );
      })}
      <Button onClick={addExclusion} disabled={isDisabled} icon="plus">
        {buttonLabel}
      </Button>
      {validateParam.displayInvalid(param.isInvalid && !exclusions.length, {
        key: param.parameter.id,
      })}
    </div>
  );
};

export default Exclusions;
