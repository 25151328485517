import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import './ToggleButtonNew.styles.scss';

const ToggleButton = ({ onToggle, disabled, checked, small }) => (
  <div className={classNames('ToggleButtonWrapperNew', { small })}>
    <input
      className={classNames('ToggleButtonInputNew', { small })}
      type="checkbox"
      onChange={onToggle}
      disabled={disabled}
      checked={checked}
    />
  </div>
);

ToggleButton.propTypes = {
  onToggle: PropTypes.func,
  disabled: PropTypes.bool,
  checked: PropTypes.bool,
  small: PropTypes.bool,
};

ToggleButton.defaultProps = {
  onToggle: () => {},
  disabled: false,
  checked: false,
  small: false,
};

export default ToggleButton;
