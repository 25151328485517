import React, { memo } from 'react';

import { Flex } from '@kandji-inc/nectar-ui';

import type { DraggableLibraryItem } from '../blueprint-flow.types';
import { getLibraryItem } from '../helpers';
import useBlueprintFlow from '../store';
import Avatar from './avatar';

function Deck(props: { items: DraggableLibraryItem[]; size: number }) {
  const { items, size } = props;
  const libraryItems = useBlueprintFlow((state) => state.libraryItems);
  const remainder = items?.length - size;
  const hasRemainder = remainder > 0;
  const avatars = items?.slice(0, size)?.map(({ data }) => {
    const { id } = data;

    const item = getLibraryItem(id, libraryItems);
    if (!item) {
      return null;
    }

    return <Avatar key={id} item={item} />;
  });

  return (
    <Flex className="flow-deck" gap="sm" alignItems="center">
      <Flex gap="xs" alignItems="center" css={{ margin: '-1px 0' }}>
        {avatars}
      </Flex>

      {hasRemainder && <Flex css={{ margin: '-3px 0' }}>...</Flex>}
    </Flex>
  );
}

export default memo(Deck);
