import React from 'react';

import { string } from 'prop-types';

const Detail = ({ name, value }) => (
  <div className="k-adcs-detail">
    <div className="k-adcs-detail-name">{name}</div>
    <div className="k-adcs-detail-value">{value}</div>
  </div>
);

Detail.propTypes = {
  name: string,
  value: string,
};

Detail.defaultProps = {
  name: '-',
  value: '-',
};

export default Detail;
