export const requiredField = (v) => {
  const r1 = {
    message: 'Required',
    invalid: () => !String(v).length,
    trigger: ['onBlur', 'onInput'],
  };
  return r1;
};

export const overCharLimit =
  (charLimit, validators = []) =>
  (v) => {
    const otherValidations = Array.isArray(validators)
      ? validators.map((valCb) => valCb(v))
      : [];
    const r1 = {
      message: 'Max character limit',
      invalid: () => String(v).length > Number(charLimit),
      trigger: ['onBlur', 'onInput', 'onMount'],
    };
    return [r1, ...otherValidations];
  };

export const requiredFieldCustom = (isCustomMounted, isDisabled) => (v) => {
  const r1 = {
    message: 'Selected option is required.',
    invalid: () => {
      const isEmptyString = !String(v).length;
      if (isDisabled) {
        return isEmptyString;
      }
      if (isCustomMounted && isEmptyString) {
        return false;
      }
      return isEmptyString;
    },
    trigger: ['onBlur', 'onInput'],
  };
  return r1;
};

export default {
  requiredField,
  requiredFieldCustom,
  overCharLimit,
};
