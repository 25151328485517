import { useDroppable } from '@dnd-kit/core';
import {
  SortableContext,
  rectSortingStrategy,
  useSortable,
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { Box, Flex, Text, styled } from '@kandji-inc/nectar-ui';
/* istanbul ignore file */
import React from 'react';
import { i18n } from 'src/i18n';
import CustomChip from './CustomChip';
import { deviceVariables } from './common';

const Dropbox = styled(Box, {
  borderWidth: '1px',
  borderStyle: 'solid',
  minHeight: '216px',
  borderRadius: '$1',
  padding: '$3',
  transition: '0.2s border-color ease-in-out',

  variants: {
    isOver: {
      true: {
        borderColor: '$neutral50',
      },
      false: {
        borderColor: '$neutral20',
      },
    },
    isEmpty: {
      true: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      },
    },
  },
});

const SortableChip = (props: any) => {
  const {
    value,
    zone,
    label,
    itemId,
    onRemove,
    onEditClick,
    isCustom,
    isDisabled,
  } = props;
  const {
    isDragging,
    attributes,
    listeners,
    transition,
    transform,
    setNodeRef,
  } = useSortable({
    id: `${zone}_${itemId}`,
    data: { value, zone, itemId, isCustom },
    transition: {
      duration: 150,
      easing: 'cubic-bezier(0.25, 1, 0.5, 1)',
    },
  });
  const SPACE_LABEL = '[space]';

  const baseStyle = {
    transition,
    transform: CSS.Translate.toString(transform),
    ...((isDragging || isDisabled) && {
      backgroundColor: '#EEF0F6',
      color: '#A5AAB5',
    }),
  };

  const isLabelSpace = !label.trim().length;
  return (
    <CustomChip
      label={isLabelSpace ? SPACE_LABEL.repeat(label.length) : label}
      onDismiss={!isDisabled && onRemove}
      onEdit={isCustom && !isDisabled && onEditClick}
      ref={setNodeRef}
      css={baseStyle}
      {...listeners}
      {...attributes}
    />
  );
};

const VariableDropzone = (props: any) => {
  const {
    id,
    title,
    variables,
    onManualRemove,
    onEditCustomVariable,
    isDisabled,
  } = props;
  const { isOver, setNodeRef } = useDroppable({
    id,
  });

  return (
    <SortableContext
      id={id}
      items={variables.map((v) => `${id}_${v.id}`)}
      strategy={rectSortingStrategy}
      disabled={isDisabled}
    >
      <Box css={{ userSelect: 'none' }}>
        <p className="b-txt b-mb1">{i18n.t('{title} pattern', { title })}</p>
        <Dropbox ref={setNodeRef} isOver={isOver} isEmpty={!variables?.length}>
          {variables.length ? (
            <Flex wrap="wrap" gap="sm">
              {variables.map((variable) => {
                const deviceVar = deviceVariables().find(
                  ({ value }) => value === variable.value,
                );
                const name = deviceVar?.name || variable.value;
                return (
                  <SortableChip
                    key={variable.id}
                    zone={id}
                    label={name}
                    value={variable.value}
                    itemId={variable.id}
                    onEditClick={() => onEditCustomVariable(variable.id)}
                    onRemove={() => onManualRemove(variable.id)}
                    isCustom={!deviceVar}
                    isDisabled={isDisabled}
                  />
                );
              })}
            </Flex>
          ) : (
            <Text variant="description">{i18n.t(`Drag variables here`)}</Text>
          )}
        </Dropbox>
      </Box>
    </SortableContext>
  );
};

export default VariableDropzone;
