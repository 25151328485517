import { i18n } from 'i18n';
import validator from 'validator';

const requiredValidator = (val) => ({
  message: i18n.t('Required'),
  invalid: () => !val.length,
  trigger: ['onBlur'],
});

const matchingValidator = (val, email) => ({
  message: i18n.t('Emails do not match'),
  invalid: () => val !== email,
  trigger: ['onBlur'],
});

const emailValidator = (val) => ({
  message: i18n.t('Invalid email address'),
  invalid: () => !validator.isEmail(val),
  trigger: ['onBlur'],
});

export { requiredValidator, matchingValidator, emailValidator };
