/* istanbul ignore file - writing tests for edges requires additional overhead in relation to the DOM and SVGs, revisit later [https://reactflow.dev/docs/guides/testing/] */
import React, { memo } from 'react';
import type { EdgeProps } from 'reactflow';
import { BaseEdge, EdgeLabelRenderer } from 'reactflow';

import { Flex } from '@kandji-inc/nectar-ui';

import { PlusButton } from '../components';
import { useBranchEdgeClick } from '../hooks';
import useBlueprintFlow from '../store';
import { getDevicePathStyle } from '../theme';

function BranchEdge(props: EdgeProps) {
  const { id, data, style = {} } = props;
  const [
    isEditingAssignments,
    { devicePath },
    isDeletingNode,
    descendantsToBeDeleted,
  ] = useBlueprintFlow((state) => [
    state.isEditingAssignments,
    state.selectedDevice,
    state.isDeletingNode,
    state.descendantsToBeDeleted,
  ]);
  const { handleBranchEdgeClick } = useBranchEdgeClick(id);

  const path = data?.path;
  const startPoint = data?.startPoint;
  const isDeletingThisNode =
    isDeletingNode && descendantsToBeDeleted.includes(id as never);

  const isShowingDevicePath = !isEditingAssignments && devicePath;
  const isOnDevicePath = isShowingDevicePath && devicePath?.edges.includes(id);

  return (
    <>
      <BaseEdge
        id={id}
        path={path}
        style={{
          fill: 'none',
          stroke: 'var(--colors-neutral80)',
          strokeWidth: '1',
          ...(isDeletingThisNode
            ? {
                stroke: 'var(--colors-red30)',
                strokeWidth: '3',
              }
            : {}),
          ...style,
          ...(isShowingDevicePath && getDevicePathStyle(isOnDevicePath).edge),
        }}
      />

      {!isShowingDevicePath && (
        <EdgeLabelRenderer>
          <Flex
            className="nodrag nopan"
            css={{
              position: 'absolute',
              transform: `translate(-18%, -50%) translate(${startPoint?.x}px,${startPoint?.y}px)`,
              pointerEvents: 'all',
              visibility: path ? 'visible' : 'hidden',
            }}
          >
            {isEditingAssignments && !isDeletingNode && (
              <PlusButton onClick={handleBranchEdgeClick} />
            )}
          </Flex>
        </EdgeLabelRenderer>
      )}
    </>
  );
}

export default memo(BranchEdge);
