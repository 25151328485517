import { modal as Modal } from '@kandji-inc/bumblebee';
import type { ReactNode } from 'react';
import React from 'react';
/* istanbul ignore file */
import './welcome-modal.styles.scss';

type Props = Readonly<{
  isOpen: boolean;
  onClose: () => void;
  withoutCloseButton?: boolean;
  disableCloseOnOutsideClick?: boolean;
  children: ReactNode;
}>;
const WelcomeModal = (props: Props) => {
  const {
    isOpen,
    onClose,
    withoutCloseButton = true,
    disableCloseOnOutsideClick = true,
    children,
  } = props;
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      withoutCloseButton={withoutCloseButton}
      disableCloseOnOutsideClick={disableCloseOnOutsideClick}
      parts={{
        content: {
          className: 'int-welcome-modal_content',
          children,
        },
      }}
    />
  );
};

export default WelcomeModal;
