import { createColumnHelper } from '@tanstack/react-table';
/* istanbul ignore file */
import type { z } from 'zod';

import {
  DateTimeCell,
  ON_OFF_CELL_FILTER_OPTIONS,
  OnOffCell,
  YES_NO_CELL_FILTER_OPTIONS,
  YesNoCell,
  extractFilterableColumns,
  getFilteredDisabled as filterDisabled,
  getEnumFilterOptions,
} from '../column-utils';
import { getGlobalColumns } from './globalColumns';

export const getFileVaultColumns = (schema) => {
  if (!schema) {
    return { columnDefs: [], filterableColumns: [] };
  }

  const fileVaultColumnHelper = createColumnHelper<z.infer<typeof schema>>();
  const globalColumns = getGlobalColumns(schema, fileVaultColumnHelper);

  const columnDefs = [
    ...globalColumns.startColumnDefs,

    fileVaultColumnHelper.accessor((row) => row.status, {
      id: 'status',
      cell: OnOffCell,
      meta: {
        displayName: 'Status',
        filterType: 'boolean',
        filterOptions: [...ON_OFF_CELL_FILTER_OPTIONS],
        filterIcon: 'circle-dot',
        filterDisabled,
      },
    }),

    fileVaultColumnHelper.accessor((row) => row.key_type, {
      id: 'key_type',
      meta: {
        displayName: 'Recovery Key Type',
        filterType: 'enum',
        filterOptions: getEnumFilterOptions(
          schema.schema?.definitions.KeyType.enum || [],
        ),
        filterIcon: 'list-check',
        filterDisabled,
      },
    }),

    fileVaultColumnHelper.accessor((row) => row.key_escrowed, {
      id: 'key_escrowed',
      cell: YesNoCell,
      meta: {
        displayName: 'Recovery Key Escrowed',
        filterType: 'boolean',
        filterOptions: [...YES_NO_CELL_FILTER_OPTIONS],
        filterIcon: 'circle-dot',
        filterDisabled,
      },
    }),

    fileVaultColumnHelper.accessor((row) => row.regeneration_needed, {
      id: 'regeneration_needed',
      cell: YesNoCell,
      meta: {
        displayName: 'Regeneration Needed',
        filterType: 'boolean',
        filterOptions: [...YES_NO_CELL_FILTER_OPTIONS],
        filterIcon: 'circle-dot',
        filterDisabled,
      },
    }),

    fileVaultColumnHelper.accessor((row) => row.scheduled_key_rotation, {
      id: 'scheduled_key_rotation',
      cell: DateTimeCell,
      meta: {
        displayName: 'Next Scheduled Rotation',
        filterType: 'date-time',
        filterIcon: 'calendar',
        filterDisabled,
      },
    }),

    ...globalColumns.endColumnDefs,
  ];

  const filterableColumns = extractFilterableColumns(columnDefs);

  return { columnDefs, filterableColumns };
};
