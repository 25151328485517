/* istanbul ignore file */
import type React from 'react';

type OverlayProps = {
  isHidden: boolean;
  style?: React.CSSProperties;
};

const Overlay = (props: OverlayProps) => {
  const { isHidden, style = {} } = props;

  if (isHidden) {
    return null;
  }

  return (
    <div
      style={{
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(0, 0, 0, 0.45)',
        ...style,
      }}
    />
  );
};

export default Overlay;
