import { i18n } from 'i18n';
import { formatDate } from './_helpers';

export default (activity) => [
  {
    label: i18n.t('Deleted by'),
    values: [activity.actor_name || activity.ip || '-'],
  },
  {
    label: i18n.t('Deleted at'),
    values: [formatDate(activity)],
  },
];
