import styled from 'styled-components';
import { H3 } from '../../interface/Typography';

const Title = styled(H3)`
  font-family: var(--font-family-primary);
  font-size: 24px;
  font-weight: bold;
  line-height: 39px;

  color: #1d212a;
  margin-bottom: 7px;
`;

const SubTitle = styled(H3)`
  font-family: var(--font-family-primary);
  font-size: 22px;
  font-weight: bold;
  line-height: 29px;

  color: #1a1d25;
`;

Title.SubTitle = SubTitle;
export default Title;
