import { Button, Icon } from '@kandji-inc/bumblebee';
import classNames from 'classnames';
import { i18n } from 'i18n';
import get from 'lodash/get';
import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { links } from '../common/constants';
import history from '../router/history';
import AddDevicesSelect from './common/AddDevicesSelect';
import { formatTime, moment } from './common/helpers';
import {
  getDepIntegration,
  getPushDepPEM,
  uploadDepSToken,
} from './integrations/Apple/api';
import { H2 } from './interface/Typography';
import {
  LabelInput,
  LabelSubInput,
  TextInput,
} from './library/common/LibrarySetting.styles';

const BaseBlock = styled('div')`
  background-color: white;
  margin: 20px 0 30px 0;
  padding: 20px 27px 20px 33px;
  display: grid;
  grid-gap: 15px;
  border-radius: 4px;
`;

const Title = styled(H2)`
  text-transform: none;
  font-size: var(--font-headings-m-size);
  line-height: var(--font-headings-m-line-height);
  font-weight: var(--font-headings-m-weight);
  letter-spacing: var(--font-headings-m-letter-spacing);
  color: var(--color-neutral-100);
`;

const Notification = styled('section')`
  background: #ffeecd;
  border-radius: 4px;
  padding: 11px 16px;

  font-family: var(--font-body-xs-family);
  font-weight: var(--font-body-xs-weight);
  font-size: var(--font-body-xs-size);
  line-height: var(--font-body-xs-line-height);
  letter-spacing: var(--font-body-xs-letter-spacing);

  color: #d98017;
`;

const NotificationIcon = styled(Icon)`
  font-size: 18px;
  color: #d98017;
  margin-right: 10px;
`;

const Card = styled('section')`
  background-color: ${(props) =>
    props.cardColor ? props.cardColor : '#F6F7F9'};
  border-radius: 4px;
  padding-right: 20px;
  display: grid;
  grid-template-areas: "icon header" "icon content";
  grid-template-columns: 86px auto;
  grid-template-rows: auto auto;
  color: #1a1d25;
  &.instruction {
    //grid-template-areas: "icon header" "icon content" "footer footer";
    //grid-template-columns: 86px auto;
    //grid-template-rows: auto auto 50px;
  }
  &.info {
    background: #eef0f6;
    color: #4d5a79;
  }
`;

const CardIcon = styled('div')`
  grid-area: icon;
  width: 33px;
  height: 33px;
  border-radius: 100%;
  background: #1a1d25;
  margin: 31px 13px auto 40px;

  font-family: var(--font-heading-s-family);
  font-weight: var(--font-heading-s-weight);
  font-size: var(--font-heading-s-size);
  line-height: var(--font-heading-s-line-height);
  letter-spacing: var(--font-heading-s-letter-spacing);
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const CardHeader = styled('div')`
  grid-area: header;
  margin: 35px 0 10px;
  font-family: var(--font-heading-m-family);
  font-weight: var(--font-heading-m-weight);
  font-size: var(--font-heading-m-size);
  line-height: var(--font-heading-m-line-height);
  letter-spacing: var(--font-heading-m-letter-spacing);
`;

const CardContent = styled('div')`
  grid-area: content;
  margin-bottom: 34px;

  font-family: var(--font-body-xs-family);
  font-weight: var(--font-body-xs-weight);
  font-size: var(--font-body-xs-size);
  line-height: var(--font-body-xs-line-height);
  letter-spacing: var(--font-body-xs-letter-spacing);
`;

const Text = styled('div')`
  max-width: ${(props) => (props.fullWidthText ? '100%' : '603px')};
`;

const BoldText = styled('b')`
  font-weight: 600;
`;

const Action = styled('div')`
  margin: 24px 0;
`;

const Instruction = styled('img')`
  margin: 24px 0;
  max-width: 600px;
`;

const CardFooter = styled('div')`
  grid-area: footer;
  line-height: 1em;
  box-shadow: 0 -2px 0px 0 rgba(26, 29, 37, 0.05);
  padding: 18px 85px;
  cursor: pointer;
`;

const FooterText = styled('span')`
  font-family: var(--font-family-primary);
  font-size: 12px;
  line-height: 16px;
  font-weight: 700;

  letter-spacing: 0.15em;
  text-transform: uppercase;
`;

const CaretIcon = styled('i')`
  font-size: 16px;
  margin-left: 8px;
`;

const ButtonsWrapper = styled('div')`
  display: grid;
  grid-template-columns: auto min-content min-content;
  grid-template-areas: ". button1 button2";
  grid-gap: 10px;
  padding-bottom: 50px;
`;

const UploadAreaWrapper = styled('section')`
  min-height: 185px;
  margin-right: 10px;
  width: 100%;
  background: #ffffff;
  border-radius: 4px;
  padding: 20px;
`;

const DashedArea = styled('div')`
  border: 2px dashed #d7dbe8;
  box-sizing: border-box;
  border-radius: 4px;
  min-height: 145px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  &.highlight {
    border: 2px dashed #d98017;
  }
`;

const UploadAreaIcon = styled('i')`
  font-size: 35px;
  color: #7d8dbd;
  margin-bottom: 19px;
`;

const UploadAreaText = styled('div')`
  font-family: var(--font-body-xs-family);
  font-weight: var(--font-body-xs-weight);
  font-size: var(--font-body-xs-size);
  line-height: var(--font-body-xs-line-height);
  letter-spacing: var(--font-body-xs-letter-spacing);
  text-align: center;

  color: #1a1d25;
`;

const AutoEnrollTextInput = styled(TextInput)`
  margin-top: 10px;
`;

export const StepCard = ({
  children,
  icon,
  title,
  instruction,
  action,
  fullWidthText,
  cardColor,
}) => {
  const [show, setShow] = useState(false);
  const onShowClick = useCallback(() => setShow(!show), [show]);
  return (
    <Card className={classNames({ instruction })} cardColor={cardColor}>
      <CardIcon>{icon}</CardIcon>
      <CardHeader>{title}</CardHeader>
      <CardContent>
        <Text fullWidthText={fullWidthText}>{children}</Text>
        {action && <Action>{action}</Action>}
        {instruction &&
          instruction.map((element) => (
            <Instruction src={element} alt={title} />
          ))}
      </CardContent>
    </Card>
  );
};

// todo: refactor
const UploadArea = ({ uploadError, setUploadError, file, setFile }) => {
  const fileInput = useRef(null);
  const validateFile = (dfile) => {
    if (dfile) {
      if (!dfile.name.endsWith('.p7m')) {
        setFile(null);
        /* istanbul ignore next */
        setUploadError(i18n.t("This doesn't appear to be a valid token."));
      } else {
        setFile(dfile);
        setUploadError(null);
      }
    }
  };

  useLayoutEffect(() => {
    const dropArea = document.getElementById('drop-area');
    const handleDrop = (e) => validateFile(e.dataTransfer.files[0]);
    const highlight = () => dropArea.classList.add('highlight');
    const unhighlight = () => dropArea.classList.remove('highlight');
    const preventDefaults = (e) => {
      e.preventDefault();
      e.stopPropagation();
    };
    ['dragenter', 'dragover', 'dragleave', 'drop'].forEach((eventName) => {
      dropArea.addEventListener(eventName, preventDefaults, false);
    });
    ['dragenter', 'dragover'].forEach((eventName) => {
      dropArea.addEventListener(eventName, highlight, false);
    });
    ['dragleave', 'drop'].forEach((eventName) => {
      dropArea.addEventListener(eventName, unhighlight, false);
    });
    dropArea.addEventListener('drop', handleDrop, false);
  }, []);

  const handleFiles = (e) => validateFile(e.target.files[0]);
  const handleClick = () => fileInput.current && fileInput.current.click();

  return (
    <>
      <UploadAreaWrapper>
        <input
          hidden
          ref={fileInput}
          type="file"
          accept=".p7m"
          onChange={handleFiles}
        />
        <DashedArea onClick={handleClick} id="drop-area">
          <UploadAreaIcon className="fal fa-file-alt" />
          <UploadAreaText>
            {
              /* istanbul ignore next */
              get(file, 'name') || i18n.t('Drag here or click to upload')
            }
          </UploadAreaText>
        </DashedArea>
      </UploadAreaWrapper>
      {uploadError && (
        <Notification
          style={{ marginTop: 10, display: 'flex', justifyContent: 'center' }}
        >
          <NotificationIcon name="octagon-exclamation" />
          {uploadError}
        </Notification>
      )}
    </>
  );
};

const DEPIntegrationPage = (blueprints) => {
  const isRenewPage = window.location.pathname.indexOf('renew') >= 0;
  const [integrationData, setIntegrationData] = useState({
    defaults: {},
    blueprint: {},
  });
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [blueprint, setBlueprint] = useState(blueprints.blueprints[0]);
  const [file, setFile] = useState(null);
  const [uploadError, setUploadError] = useState(null);
  const [saveButtonClicked, setSaveButtonClicked] = useState(false);
  useEffect(() => {
    setBlueprint(blueprints.blueprints[0]);
    if (isRenewPage) {
      getDepIntegration()
        .then((integrationDataResult) => {
          if (integrationDataResult.days_left === null) {
            history.push('/my-company/integrations');
          }
          setIntegrationData(integrationDataResult);
        })
        .catch(() => history.push('/my-company/integrations'));
    }
  }, [setIntegrationData]);
  // TODO: add validation
  // useEffect(() => {
  //   if (file) {
  //     const formData = new FormData();
  //     formData.append('file', file);
  //     uploadPushDepCSR(formData, { validate: true })
  //       .catch(() => {
  //         setFile(null);
  //         setUploadError('This doesn\'t appear to be a valid certificate.');
  //       });
  //   }
  // }, [file]);
  const handleSubmit = () => {
    setSaveButtonClicked(true);
    const formData = new FormData();
    formData.append('file', file);
    formData.append('email', integrationData.defaults.email || email);
    formData.append('phone', integrationData.defaults.phone || phone);
    formData.append(
      'blueprint_id',
      integrationData.blueprint.id || blueprint.id,
    );
    return uploadDepSToken(formData)
      .then(() => history.push('/my-company/integrations'))
      .catch((err) => {
        // todo: unify, refactor
        const errorMessages = {
          2001: i18n.t(
            'This token is not a renewed token for the current MDM Server in Apple Business Manager. If you are attempting to change the MDM Server Token, please first disconnect the Automated Device Enrollment integration. ',
          ),
        };

        const errorCode = `${err.response.data.code}`;
        const message = errorMessages[errorCode];

        setFile(null);
        setSaveButtonClicked(false);
        /* istanbul ignore next */
        setUploadError(
          message || i18n.t("This doesn't appear to be a valid token."),
        );
      });
  };
  return (
    <>
      <BaseBlock>
        <Title>
          {isRenewPage
            ? i18n.t('Automated Device Enrollment renewal')
            : i18n.t('Automated Device Enrollment initial configuration')}
        </Title>
        {isRenewPage && get(integrationData, 'access_token_expiry') && (
          <Notification>
            <NotificationIcon name="circle-info" />
            {i18n.t('Your Automated Device Enrollment Server Token expires on')}{' '}
            {formatTime(
              get(integrationData, 'access_token_expiry'),
              true,
              null,
              true,
              false,
              'MMMM D, YYYY',
            )}
            {' - '}
            {moment(get(integrationData, 'access_token_expiry')).fromNow()}.
          </Notification>
        )}
        {!isRenewPage && (
          <StepCard
            icon="1"
            title={i18n.t('Download your public key')}
            fullWidthText
            action={
              <Button
                className="pendo-ade-download-key"
                onClick={getPushDepPEM}
              >
                {i18n.t('Download Public Key')}
              </Button>
            }
          >
            {i18n.ut(
              `By default, the <b>kandji-auto-enroll.pem</b> file will appear in your Downloads folder. You will upload it in Step 3.`,
            )}
          </StepCard>
        )}
        <StepCard
          icon={isRenewPage ? '1' : '2'}
          title={i18n.t(
            'Sign in to Apple Business Manager or Apple School Manager ',
          )}
          fullWidthText
        >
          {i18n.$t(
            'Visit {website_link} and sign in to Apple Business Manager.',
            {
              website_link: (
                <a
                  href="https://business.apple.com"
                  style={{ color: '#618FE8', fontWeight: '500' }}
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <BoldText>
                    <u>business.apple.com</u>
                  </BoldText>
                </a>
              ),
            },
          )}
          <br />
          {i18n.$t(
            'Visit {website_link} and sign in to Apple School Manager.',
            {
              website_link: (
                <a
                  href="https://school.apple.com/"
                  style={{ color: '#618FE8', fontWeight: '500' }}
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <BoldText>
                    <u>school.apple.com</u>
                  </BoldText>
                </a>
              ),
            },
          )}
        </StepCard>
        {!isRenewPage && (
          <StepCard icon="3" title={i18n.t('Add an MDM Server')} fullWidthText>
            <ol style={{ margin: 0, marginLeft: '15px', padding: 0 }}>
              <li>
                {i18n.ut(
                  `Click your name at the bottom of the sidebar, then click <b>Preferences</b>.`,
                )}
              </li>
              <li>
                {i18n.ut(
                  `Next to <b>Your MDM Servers</b>, click the <b>+Add</b> button.`,
                )}
              </li>
              <li>
                {i18n.ut(
                  `Name the server <b>Kandji</b> (or another useful
                name), and upload the public key you downloaded in Step 1.`,
                )}
              </li>
              <li>
                {i18n.ut(
                  `Click the <b>Save</b> button, and then click the <b>Download Token</b> button. The
                token is downloaded to your Downloads folder.`,
                )}
              </li>
            </ol>
          </StepCard>
        )}
        {isRenewPage && (
          <StepCard
            icon="2"
            title={i18n.t('Download a new token')}
            fullWidthText
          >
            <ol style={{ margin: 0, marginLeft: '15px', padding: 0 }}>
              <li>
                {i18n.ut(
                  `Click your name at the bottom of the sidebar, then click <b>Preferences</b>.`,
                )}
              </li>
              <li>
                {i18n.ut(`Under <b>Your MDM Servers</b>, select your
                Kandji server from the list.`)}
              </li>
              <li>
                {i18n.ut(`Click <b>Download Token</b> above the server
                details. The token is downloaded to your Downloads folder.`)}
              </li>
            </ol>
          </StepCard>
        )}
        <StepCard
          icon={isRenewPage ? '3' : '4'}
          title={i18n.t('Upload your token to Kandji')}
          fullWidthText
          action={
            <UploadArea
              uploadError={uploadError}
              setUploadError={setUploadError}
              file={file}
              setFile={setFile}
            />
          }
        >
          {i18n.ut(
            `Upload the token that you just downloaded. The filename ends in <b>.p7m</b>`,
          )}
        </StepCard>
        {!isRenewPage && (
          <StepCard
            icon="5"
            title={i18n.t(
              'Configure defaults for Automated Device Enrollment devices',
            )}
            action={
              <>
                <LabelInput>{i18n.t('Default Blueprint')}</LabelInput>
                <LabelSubInput>
                  {i18n.t(
                    `Automated Device Enrollment devices will be assigned to the
                  Default Blueprint when they enroll into Kandji. You can
                  override this setting for individual devices on the `,
                  )}
                  <a
                    href={links.depDevices}
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    <BoldText>
                      <u>
                        {i18n.t('Automated Device Enrollment Devices page.')}
                      </u>
                    </BoldText>
                  </a>
                </LabelSubInput>
                <AddDevicesSelect
                  title="None"
                  verticalCenteredIcon
                  selected={blueprint}
                  width="50%"
                  list={blueprints.blueprints}
                  resetThenSet={(value) => {
                    setBlueprint(value);
                  }}
                />
                <LabelInput>{i18n.t('Default Support Phone')}</LabelInput>
                <LabelSubInput>
                  {i18n.t(
                    `Phone number displayed during Setup Assistant for Automated
                  Device Enrollment devices. You may override this when
                  configuring an Automated Device Enrollment Library Item.`,
                  )}
                </LabelSubInput>
                <div>
                  <AutoEnrollTextInput
                    value={phone}
                    placeholder="800-800-8000"
                    onChange={(e) => setPhone(e.target.value.slice(0, 50))}
                    error={!phone && saveButtonClicked}
                  />
                </div>
                <LabelInput>{i18n.t('Default Email Address')}</LabelInput>
                <LabelSubInput>
                  {i18n.t(
                    `Email address displayed during Setup Assistant for Automated
                  Device Enrollment devices. You may override this when
                  configuring an Automated Device Enrollment Library Item.`,
                  )}
                </LabelSubInput>
                <div>
                  <AutoEnrollTextInput
                    value={email}
                    placeholder="support@example.com"
                    onChange={(e) => setEmail(e.target.value.slice(0, 250))}
                    error={!email && saveButtonClicked}
                  />
                </div>
              </>
            }
          />
        )}
      </BaseBlock>
      <ButtonsWrapper>
        <Button
          style={{ gridArea: 'button1' }}
          kind="outline"
          theme="dark"
          onClick={() => history.push('/my-company/integrations')}
        >
          {i18n.t('Cancel')}
        </Button>
        <Button
          className="pendo-ade-done"
          disabled={
            saveButtonClicked ||
            !file ||
            (!isRenewPage && (!email || !phone || !blueprint))
          }
          style={{ gridArea: 'button2' }}
          theme="dark"
          onClick={handleSubmit}
        >
          {isRenewPage
            ? saveButtonClicked
              ? i18n.t('Adding Token...')
              : i18n.t('Complete Renewal')
            : saveButtonClicked
              ? i18n.t('Adding Token...')
              : i18n.t('Done')}
        </Button>
      </ButtonsWrapper>
    </>
  );
};

const mapStateToProps = (state) => ({
  blueprints: state.data.blueprints,
});

export default connect(mapStateToProps)(DEPIntegrationPage);
