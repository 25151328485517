import useCreateNotificationDetail from './use-create-notification-detail';
import useEventNotifications from './use-event-notifications';
import useSendMSTeamsChannels from './use-send-ms-teams-channels';

type Events = string[];
type Channels = string[];

type Data = {
  name: string;
  channels: Channels;
  events: Events;
};

const useAddNewNotification = () => {
  const mutateNotificationDetail = useCreateNotificationDetail();
  const mutateMSTeamsChannels = useSendMSTeamsChannels();

  const mutateEvents = useEventNotifications();

  const createNewNotification = (props: Data) =>
    mutateNotificationDetail.mutateAsync(props?.name).then((resp) => {
      const notificationId = resp.data.id;
      const channelsPromise = mutateMSTeamsChannels.mutateAsync({
        notificationId,
        channels: props.channels,
      });
      const eventsPromise = mutateEvents.mutateAsync({
        notificationId,
        data: { events: props.events },
      });
      return Promise.all([channelsPromise, eventsPromise]);
    });

  /* istanbul ignore next */

  const loading =
    mutateNotificationDetail.isPending &&
    mutateMSTeamsChannels.isPending &&
    mutateEvents.isPending;

  return { createNewNotification, loading };
};

export default useAddNewNotification;
