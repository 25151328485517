import React from 'react';
import CreateMap from 'src/features/blueprint-flow/modals/create-map';
import DeleteBlueprint from './delete';
import DuplicateBlueprint from './duplicate';
import EditBlueprint from './edit';
import EditAssignmentMap from './edit-assignment-map';
import NewBlueprint from './new';

const modalTypes = {
  DELETE: 0,
  EDIT: 1,
  EDIT_ASSIGNMENT_MAP: 2,
  DUPLICATE: 3,
  NEW: 4,
  NONE: 5,
  CREATE_MAP: 6,
};

const BlueprintListModals = (props) => {
  const {
    type,
    onClose,
    onCreate,
    onEdit,
    onDelete,
    onDuplicate,
    templates,
    blueprint,
    errorMessage,
    isFlowBlueprint = false,
  } = props;
  const {
    DELETE,
    EDIT,
    EDIT_ASSIGNMENT_MAP,
    DUPLICATE,
    NEW,
    NONE,
    CREATE_MAP,
  } = modalTypes;

  if (type === NONE) {
    return null;
  }

  if (type === NEW) {
    return (
      <NewBlueprint
        templates={templates}
        onCreate={onCreate}
        onClose={onClose}
        errorMessage={errorMessage}
      />
    );
  }
  if (type === EDIT) {
    return (
      <EditBlueprint blueprint={blueprint} onEdit={onEdit} onClose={onClose} />
    );
  }

  if (type === EDIT_ASSIGNMENT_MAP) {
    return (
      <EditAssignmentMap
        blueprint={blueprint}
        onEdit={onEdit}
        onClose={onClose}
      />
    );
  }

  if (type === DELETE) {
    return <DeleteBlueprint onDelete={onDelete} onClose={onClose} />;
  }

  if (type === DUPLICATE) {
    return (
      <DuplicateBlueprint
        blueprint={blueprint}
        isFlowBlueprint={isFlowBlueprint}
        onDuplicate={onDuplicate}
        onClose={onClose}
      />
    );
  }

  /* istanbul ignore next */
  if (type === CREATE_MAP) {
    return (
      <CreateMap templates={templates} onCreate={onCreate} onClose={onClose} />
    );
  }

  return null;
};

export default BlueprintListModals;
export { modalTypes };
