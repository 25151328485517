import React, { useRef, useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import './blueprint-library-item.css';

import { Chip, Toggle } from '@kandji-inc/bumblebee';

import {
  installTypes,
  updateOnlyIconName,
} from 'features/library-items/library/common';
import IconRules from 'features/library-items/library/library-list/assets/icon_rules.svg';
import InstallContinuous from 'features/library-items/library/library-list/assets/install_continuous.svg';
import InstallOnce from 'features/library-items/library/library-list/assets/install_once.svg';
import SelfServiceBee from 'features/library-items/library/library-list/assets/self_service_bee.svg';
import HoverTippy from 'features/util/components/hover-tippy';
import PreviewHoverTippy from 'features/util/components/preview-hover-tippy';

import { AccountContext } from 'contexts/account';
import { EnvironmentContext } from 'contexts/environment';

import { Box, Icon } from '@kandji-inc/nectar-ui';
import AssignmentRulesDropdown from '../../../rules/blueprint-library';

const installTypeIcons = {
  [installTypes.ONCE]: InstallOnce,
  [installTypes.CONTINUOUS]: InstallContinuous,
};

const BlueprintLibraryItem = (props) => {
  const { item, onToggle, canToggle, isEnabled, facetMap } = props;
  const {
    id,
    icon,
    name,
    devices,
    counts,
    isSelfServiceEnabled,
    selfServiceInstallType,
    defaultConfiguration,
    instance_name: instanceName,
    rules,
    is_preview_chip: isPreviewChip,
    is_all_blueprints: isAllBlueprints,
    is_update_only: isUpdateOnly,
  } = item;
  const history = useHistory();
  const account = useContext(AccountContext);
  const environment = useContext(EnvironmentContext);
  const [iconSrc, setIconSrc] = useState(icon);
  const toggleRef = useRef();
  const assignmentRulesRef = useRef();

  const linkRoute = defaultConfiguration.getUrl({ id, account, environment });

  const hasRules = item?.has_rules;

  const handleClick = (e) => {
    const isToggle = !(
      toggleRef.current !== e.target &&
      !toggleRef.current.contains(e.target) &&
      !canToggle
    );
    const isAssignmentRules = assignmentRulesRef.current?.contains(e.target);

    // If the Assignment Rules dropdown was clicked, allow that component to handle the event.
    // Otherwise, either toggle the blueprint library item or navigate to the library item page.
    if (!isAssignmentRules) {
      if (isToggle) {
        e.preventDefault();
        onToggle(!isEnabled);
      } else {
        history.push(linkRoute);
      }
    }
  };

  return (
    <button
      id={id}
      type="button"
      className="decorate-off bl-blueprint-library-item-wrapper"
      onClick={handleClick}
    >
      <div className="bl-blueprint-library-item">
        <img
          src={iconSrc}
          alt="icon"
          className="bl-blueprint-library-item__icon"
          onError={() => setIconSrc(defaultConfiguration.icon)}
        />
        <div className="bl-blueprint-library-item__title b-flex-col">
          <h3 className="b-h3 mb-1">{name}</h3>
          {instanceName && (
            <span className="b-txt-ctrl9 li-primary-marengo-dark">
              {instanceName}
            </span>
          )}
        </div>
        <div
          onClick={(e) => e.stopPropagation()}
          className="b-flex-vc"
          ref={toggleRef}
        >
          <Toggle
            className="bl-blueprint-library-item__toggle"
            checked={isEnabled}
            onToggle={onToggle}
            disabled={!canToggle}
          />
        </div>
        <div className="b-flex-g1" style={{ gridArea: 'tags' }}>
          {devices.map((device) => (
            <Chip key={`${name}_${device}`} text={device} kind="secondary" />
          ))}
        </div>
        <div className="b-flex-g" style={{ gridArea: 'status' }}>
          {counts &&
            ['success', 'failed', 'pending'].map((status) => (
              <div key={status} className="b-flex-vc b-flex-hc">
                <div
                  className={`bl-blueprint-library-item__count --${status} li-mr-micro`}
                />
                <p className="b-txt-bold bl-blueprint-library-item__count-number">
                  {counts[status]}
                </p>
              </div>
            ))}
        </div>
        <div
          className="b-flex-vc b-flex-g1 b-flex-justify-end"
          style={{
            gridArea: 'glimpse',
            width: '45px',
          }}
        >
          {isPreviewChip && <PreviewHoverTippy />}
          {hasRules && <HoverTippy text="Assignment Rules" icon={IconRules} />}
          {isSelfServiceEnabled && (
            <HoverTippy text="Self Service" icon={SelfServiceBee} />
          )}
          {installTypeIcons[selfServiceInstallType] && (
            <HoverTippy
              text={
                isUpdateOnly
                  ? 'Update Only'
                  : selfServiceInstallType === installTypes.ONCE
                    ? 'Install Once'
                    : 'Continuously Enforce'
              }
              icon={!isUpdateOnly && installTypeIcons[selfServiceInstallType]}
            >
              {isUpdateOnly && (
                <Box
                  css={{
                    '& svg': {
                      color: 'rgb(77, 90, 121)',
                      width: '14px',
                      height: '11px',
                      scale: '1.3',
                    },
                  }}
                >
                  <Icon className="tippy-svg" name={updateOnlyIconName} />
                </Box>
              )}
            </HoverTippy>
          )}
          {isAllBlueprints && (
            <HoverTippy text={'All Classic Blueprints'}>
              <Chip
                className="bl-blueprint-library-item__all-blueprints"
                iconLeft="kandji-blueprint"
                kind="info"
              />
            </HoverTippy>
          )}
        </div>
      </div>
      {rules && (
        <div ref={assignmentRulesRef}>
          <AssignmentRulesDropdown rules={rules} facetMap={facetMap} />
        </div>
      )}
    </button>
  );
};

export default BlueprintLibraryItem;
