import { Banner, Button } from '@kandji-inc/bumblebee';
import { i18n } from 'i18n';
import { array, string } from 'prop-types';
import styled from 'styled-components';
import { useTestNotification } from '../../../hooks';

const StyledBanner = styled(Banner)`
  min-width: 0;
`;

const StyledButton = styled(Button)`
  justify-content: left;
`;

const Container = styled.div`
  margin-top: var(--b-gap3);
`;

const SendTestNotification = (props) => {
  const { integrationId, channels } = props;

  const mutation = useTestNotification();

  const handleSendTestNotification = () => {
    const channelIds = channels.map((channel) => channel.id);
    mutation.mutate({ integrationId, channels: channelIds });
  };

  const { data, isPending: loading } = mutation;

  const notificationStatus = data?.data;

  const getNotificationBanner = (isSuccessful) => {
    if (isSuccessful) {
      return (
        <StyledBanner icon="circle-check" theme="success">
          <p>
            {i18n.t(
              'Test notification sent. Please check your Microsoft Teams account.',
            )}
          </p>
        </StyledBanner>
      );
    }

    return (
      <StyledBanner icon="circle-xmark" theme="error">
        <p>
          {i18n.t(
            'Test notification failed to send. Please check your Microsoft Teams account and try again later.',
          )}
        </p>
      </StyledBanner>
    );
  };

  const hasNotificationStatus =
    notificationStatus && Object.keys(notificationStatus)?.includes('success');

  return (
    <Container>
      <StyledButton
        kind="link"
        icon={loading ? 'arrows-rotate' : 'paper-plane'}
        onClick={handleSendTestNotification}
        isProgress={loading}
        disabled={channels.length === 0}
      >
        {i18n.t('Send test notification')}
      </StyledButton>

      {hasNotificationStatus &&
        getNotificationBanner(notificationStatus?.success)}
    </Container>
  );
};

export default SendTestNotification;

SendTestNotification.propTypes = {
  channels: array,
  integrationId: string.isRequired,
};

SendTestNotification.defaultProps = {
  channels: [],
};
