import { useMutation } from '@tanstack/react-query';
import { msTeamsService } from '../services/teams';

const useDeleteNotification = () =>
  useMutation({
    mutationFn: (notificationId: string) =>
      msTeamsService.deleteNotification(notificationId),
  });

export default useDeleteNotification;
