import { Button, Flex, styled, theme } from '@kandji-inc/nectar-ui';
import { useADEFetchDevices } from '../hooks/useADEFetchDevices';
import { useADEListViewStore } from '../store/ADEListViewStore';
import { formatLastFetchTime } from '../utils/formatLastFetchTime';
import './ADEFetchDevices.styles.css';
import type {
  QueryObserverResult,
  RefetchOptions,
} from '@tanstack/react-query';
import type { Device } from './ADEListViewTableColumns';

export const LastFetchContainer = styled('div', {
  display: 'flex',
  alignItems: 'center',
  color: theme.colors.neutral70,
  fontSize: 12,
});

interface ADEFetchDevicesProps {
  hideLastFetch?: boolean;
  lastSync: number | null;
  sync: () => Promise<QueryObserverResult<Device[], Error>>;
  refetchDevices: (
    options?: RefetchOptions,
  ) => Promise<QueryObserverResult<Device[], Error>>;
}

export function ADEFetchDevices({
  hideLastFetch = false,
  lastSync,
  sync,
  refetchDevices,
}: ADEFetchDevicesProps) {
  const { isSyncing } = useADEListViewStore((state) => ({
    isSyncing: state.isSyncing,
  }));

  const { currentTime, handleSync, isFetching } = useADEFetchDevices(
    lastSync,
    sync,
    refetchDevices,
  );

  return (
    <Flex gap={'sm'}>
      {lastSync != null && !hideLastFetch && (
        <LastFetchContainer>
          Last fetch {formatLastFetchTime(lastSync, currentTime)} ago
        </LastFetchContainer>
      )}
      <Button
        compact
        variant="subtle"
        icon={{ name: 'arrows-rotate', position: 'left' }}
        onClick={() => {
          handleSync();
        }}
        className={(isSyncing || isFetching) && 'fetching'}
        disabled={isSyncing || isFetching}
      >
        Fetch devices
      </Button>
    </Flex>
  );
}
