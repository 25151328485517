import { useMutation } from '@tanstack/react-query';
import { newIntegrationsService } from 'src/features/integrations/data-service/new-integrations-service';
import type { NotificationData } from '../slack.types';

type UpdateNotificationParams = {
  slackWorkspaceId: number;
  notificationData: NotificationData;
};

const useUpdateNotification = () =>
  useMutation({
    mutationFn: (data: UpdateNotificationParams) =>
      newIntegrationsService.updateNotification(
        data.slackWorkspaceId,
        data.notificationData,
      ),
  });

export default useUpdateNotification;
