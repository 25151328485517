import { enterpriseApi } from 'app/api/base';

/* Abstract CRUD ops on a resource, this could become
 * a generic Resource class, with the same interface for any other resource.
 *
 * Usage:
 *
 * import { newTokenService } from "./api-token-serivce";
 * const tokenSvc = newTokenService();
 * tokenSvc.find({}).then(console.log)
 * tokenSvc.get(id).then(console.log)
 * tokenSvc.create({ label: ""}).then(console.log)
 * tokenSvc.remove("id").then(console.log)
 * tokenSvc.patch("id", { label: ""}).then(console.log)
 */
class ApiTokenService {
  constructor() {
    this.resource = 'token';
  }

  get(id, params) {
    const client = enterpriseApi(`${this.resource}/${id}/`);
    return client.get(params);
  }

  find(params) {
    const client = enterpriseApi(`${this.resource}/`);
    return client.get(params).then((r) => {
      const res = {};
      Object.keys(r.data).forEach((k) => {
        if (k !== 'results') {
          res[k] = r.data[k];
        }
      });
      return { ...r, data: r.data.results, ...res };
    });
  }

  create(data, params) {
    const client = enterpriseApi(`${this.resource}/`);
    return client.post(data, params);
  }

  // I guess we dont support PUT?
  // update(id, data, params) {
  //   const client = enterpriseApi(`${this.resource}/${id}/`);
  //   return client.put(data, params);
  // }
  patch(id, data, params) {
    const client = enterpriseApi(`${this.resource}/${id}/`);
    return client.patch(data, params);
  }

  remove(id, params) {
    const client = enterpriseApi(`${this.resource}/${id}/`);
    return client.delete(params);
  }
}

const newTokenService = () => new ApiTokenService();

/* for debugging */
window.tokenService = newTokenService();

export { ApiTokenService, newTokenService };

export default newTokenService;
