import { Button } from '@kandji-inc/bumblebee';
import React from 'react';
import { ReactSVG } from 'react-svg';
import SortableList from '../../library-items/items/kandji-setup/v2/screens/success-screen/sortable-list';
import DragIcon from '../assets/horizontal-ellipsis.svg';
import Menu from '../menu';

const Row = ({
  name,
  description,
  icon,
  isFetchingDeleteData,
  onEdit,
  onEditRecommended,
  onEditAll,
  onDelete,
  canDrag,
  canDelete,
  dragDropAttributes,
}) => {
  const editHandler =
    {
      recommended: onEditRecommended,
      all: onEditAll,
    }[name] || onEdit;

  return (
    <div className="k-self-service-category__row">
      <div {...(canDrag ? dragDropAttributes : {})}>
        {canDrag && (
          <img
            src={DragIcon}
            alt="drag"
            className="k-self-service-category__row-drag"
          />
        )}
      </div>
      <div className="b-flex-g">
        {icon && (
          <ReactSVG className="k-self-service-category__img" src={icon} />
        )}
        <p className="b-txt">{name}</p>
      </div>
      <div>
        <p className="b-txt-light">{description || '-'}</p>
      </div>
      <div>
        <Menu
          options={[
            { name: 'Edit', icon: 'pencil', onClick: editHandler },
            ...(canDelete
              ? [
                  {
                    name: 'Delete',
                    icon: isFetchingDeleteData ? 'arrows-rotate' : 'trash-can',
                    loading: isFetchingDeleteData,
                    disabled: isFetchingDeleteData,
                    theme: 'error',
                    onClick: onDelete,
                  },
                ]
              : []),
          ]}
        >
          <div>
            <Button kind="link" icon="ellipsis-vertical" />
          </div>
        </Menu>
      </div>
    </div>
  );
};

const Table = (props) => {
  const {
    data,
    onEdit,
    onEditRecommended,
    onEditAll,
    onDelete,
    isDeleting,
    onReorder,
  } = props;

  return (
    <div className="k-self-service-category__table">
      <Row
        name={data.recommended.name}
        description={data.recommended.description}
        icon={data.recommended.iconImg}
        onEdit={() => onEditRecommended(data.recommended)}
      />
      <Row
        name={data.all.name}
        description={data.all.description}
        icon={data.all.iconImg}
        onEdit={() => onEditAll(data.all)}
      />
      <SortableList
        model={data.categories}
        onSort={(categories) => onReorder(categories)}
        strategy="vertical"
      >
        {data.categories.map((r) => (attributes, listeners) => (
          <Row
            key={r.id}
            dragDropAttributes={{ ...listeners, ...attributes }}
            isFetchingDeleteData={
              isDeleting.data.id === r.id ? isDeleting.fetching : false
            }
            name={r.name}
            description={r.description}
            icon={r.iconImg}
            onEdit={() => onEdit(r)}
            onDelete={() => onDelete(r)}
            canDrag
            canDelete
          />
        ))}
      </SortableList>
    </div>
  );
};

export default Table;
