/* istanbul ignore file */
import { colors } from 'app/common/constants';
import React from 'react';
import featureFlags from 'src/config/feature-flags';
import styled from 'styled-components';
import ToggleButtonNew from '../interface/buttons/ToggleButtonNew';

const Container = styled('div')``;

const Card = styled('div')`
  display: grid;
  padding: 28px 31px 28px 33px;
  background: ${colors.white};
  border-radius: 4px;
`;

const TitleSection = styled('div')`
  display: flex;
  flex-wrap: nowrap;
  align-items: flex-end;
  justify-content: space-between;
`;

const Title = styled('div')`
  font-family: var(--font-family-primary);
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0.2em;
  text-transform: uppercase;
  color: ${colors.shark500};
`;

const SwitcherWrapper = styled('div')`
  display: flex;
  flex-wrap: nowrap;
`;

const Status = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80px;
  height: 24px;
  margin-right: 10px;
  border-radius: 3px;
  background-color: ${(props) =>
    props.active ? 'rgba(82, 197, 72, 0.2)' : '#E3E6EE'};
  font-family: var(--font-family-primary);
  font-size: 12px;
  line-height: 14px;
  text-transform: capitalize;
  color: ${(props) => (props.active ? '#37AC2C' : '#7F869A')};
`;

const Separator = styled('hr')`
  width: 100%;
  height: 2px;
  background-color: ${colors.wildSand500};
  border: 0 none;
  margin: 11px 0 21px 0;
`;

const Description = styled('div')`
  margin-bottom: 30px;
  font-family: var(--font-family-primary);
  font-size: 14px;
  line-height: 24px;
  color: ${colors.shark500};
`;

export const AddDevicesCard = ({
  title,
  description,
  switchButton,
  active,
  children,
  onToggle,
  switchButtonDisabled,
}) => {
  const isEnrollmentPageEnabled = featureFlags.getFlag(
    'core_022824_enrollment-page',
  );

  return (
    <Container style={isEnrollmentPageEnabled ? { padding: '0 24px' } : {}}>
      <Card>
        <TitleSection>
          <Title>{title}</Title>
          {switchButton && (
            <SwitcherWrapper>
              <Status active={active}>{active ? 'active' : 'inactive'}</Status>
              <ToggleButtonNew
                onToggle={onToggle}
                checked={active}
                disabled={switchButtonDisabled}
              />
            </SwitcherWrapper>
          )}
        </TitleSection>
        <Separator />
        <Description>{description}</Description>
        {children}
      </Card>
    </Container>
  );
};
