import { Banner } from '@kandji-inc/bumblebee';
import React from 'react';

const AddMacOSErrorBanner = ({
  showErrorBanner,
}: {
  showErrorBanner: boolean;
}) =>
  showErrorBanner ? (
    <Banner icon="circle-xmark" theme="error" kind="block" className="b-mt">
      <p>
        There was an error with one of the fields below. Please confirm the
        information entered in each of the followings fields for macOS and try
        again.
      </p>
    </Banner>
  ) : null;

export default AddMacOSErrorBanner;
