import AddEditInputs from './add-edit-inputs';
import EditAllInputs from './edit-all-inputs';
import EditRecommendedInputs from './edit-recommended-inputs';

export default {
  AddEditInputs,
  EditRecommendedInputs,
  EditAllInputs,
};

export { AddEditInputs, EditRecommendedInputs, EditAllInputs };
