/* istanbul ignore file | too much browser functionality to really test */
import axios from 'axios';

import util from './files';

const { downloadBlob } = util;

// Kicks off the auth config download
//   gets the S3 presigned URL, then downloads the installer
const handleDownload = async (
  getInstallerDownloadLink,
  setDownloadProgress,
) => {
  try {
    const res = await getInstallerDownloadLink();
    const { s3_location } = res;

    const downloadRes = await axios({
      url: s3_location,
      method: 'GET',
      responseType: 'blob',
      onDownloadProgress: (progressEvent) => {
        const { loaded, total } = progressEvent;
        const percentCompleted = Math.floor((loaded / total) * 100);
        setDownloadProgress(percentCompleted);
      },
    });

    downloadBlob(new Blob([downloadRes.data]), 'Kandji ADCS.exe');
    return true;
  } catch (err) {}

  return false;
};

export default handleDownload;
