import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Handle, Position, useReactFlow } from 'reactflow';
import { useShallow } from 'zustand/react/shallow';

import { useDroppable } from '@dnd-kit/core';
import {
  Box,
  Button,
  Card,
  Flex,
  Text,
  useDialog,
} from '@kandji-inc/nectar-ui';

import DeleteModal from 'src/features/blueprint-flow/modals/delete';

import type { AssignmentData } from '../blueprint-flow.types';
import Overlay from '../components/Overlay';
import { ASSIGNMENT_TYPES } from '../constants';
import { getAssignmentTypeAndPeers, getDeleteCopy } from '../helpers';
import { useAncestry, useLayout } from '../hooks';
import useAssignment from '../hooks/use-assignment';
import useBlueprintFlow from '../store';
import {
  FlowTippy,
  TippyContainer,
  getDevicePathStyle,
  styleNode,
} from '../theme';
import ErrorMessage from './parts/error-message';
import Items from './parts/items';
import Rules from './parts/rules/rules';

const handleStyle = { opacity: 0 };

function AssignmentNode(props: {
  id: string;
  data: {
    parentNode: string;
    parentNodePosition: { x: number; y: number };
    rules: AssignmentData['rules'];
    items: AssignmentData['items'];
  };
}) {
  const { id, data } = props;
  const { getNode, getNodes } = useReactFlow();
  const { addAssignment, deleteAssignment, getIsRemovable, updateRules } =
    useAssignment(id);
  const {
    getConditionalDescendantsUpToRouter,
    getUniqueDescendantsUpToRouter,
  } = useAncestry();
  const { isOver, setNodeRef } = useDroppable({ id });
  const assignmentRef = useRef(null);
  const [isOpen, toggle] = useDialog(false);
  const [conditionalDescendants, setConditionalDescendants] = useState<
    string[]
  >([]);
  const [allDescendants, setAllDescendants] = useState<string[]>([]);
  const [
    isEditingAssignments,
    setIsDeletingNode,
    isDeletingNode,
    descendantsToBeDeleted,
    model,
    { devicePath },
  ] = useBlueprintFlow(
    useShallow((state) => [
      state.isEditingAssignments,
      state.setIsDeletingNode,
      state.isDeletingNode,
      state.descendantsToBeDeleted,
      state.model,
      state.selectedDevice,
    ]),
  );

  const { parentNodePosition, rules, items, parentNode } = data;
  const isAwaitingPosition = !parentNodePosition;
  const { assignmentType } = getAssignmentTypeAndPeers(getNodes(), getNode(id));
  const hasData = rules || items?.length > 0;
  const devicePathItems = devicePath?.libraryItems.filter((itemId) =>
    items.find((item) => item.data.id === itemId),
  );
  const isDeletable = Boolean(
    getIsRemovable() || Boolean(conditionalDescendants?.length) || hasData,
  );
  const [isHovering, setIsHovering] = useState(false);
  const [isHoveringOverLibraryItems, setIsHoveringOverLibraryItems] =
    useState(false);

  const validationErrors = useBlueprintFlow((state) => state.validationErrors);
  const { assignmentNodesWithoutRules } = validationErrors;
  const hasMissingRulesError = assignmentNodesWithoutRules?.includes(id);
  const isDeletingThisNode =
    isDeletingNode && descendantsToBeDeleted.includes(id as never);

  useEffect(() => {
    setConditionalDescendants(getConditionalDescendantsUpToRouter(id));
    setAllDescendants(
      getUniqueDescendantsUpToRouter(id)
        .map(
          /* istanbul ignore next */ ({ id, descendantType }) =>
            descendantType !== 'assignment' ? id : null,
        )
        .filter(Boolean),
    );
  }, [model.nodes]);

  const isShowingDevicePath = !isEditingAssignments && devicePath;
  const isOnDevicePath = Boolean(
    isShowingDevicePath && devicePath.nodes.includes(id),
  );

  const nodeStyle = {
    ...styleNode(isOver, hasMissingRulesError),
    ...(isShowingDevicePath && getDevicePathStyle(isOnDevicePath).node),
    display: 'flex',
    flexDirection: 'column',
    padding: '6px 10px',
    width: '448px',
  };

  const deleteCopy = getDeleteCopy(
    'assignment',
    conditionalDescendants?.length,
    assignmentType,
  );

  const MemoizedItems = useMemo(
    () => (
      <Items
        items={items}
        isScrollable
        itemIdsOnDevicePath={isShowingDevicePath && devicePathItems}
        setIsHoveringOverLibraryItems={setIsHoveringOverLibraryItems}
      />
    ),
    [items, isShowingDevicePath],
  );

  return (
    <FlowTippy
      content={
        <TippyContainer gap="xs">
          <Text>The selected device did not meet this criteria.</Text>
        </TippyContainer>
      }
      popperOptions={{ strategy: 'fixed' }}
      visible={
        /* istanbul ignore next */
        Boolean(isShowingDevicePath) &&
        !isOnDevicePath &&
        isHovering &&
        !isHoveringOverLibraryItems
      }
    >
      <Box
        ref={assignmentRef}
        css={{ visibility: isAwaitingPosition ? 'hidden' : 'visible' }}
        onMouseEnter={/* istanbul ignore next */ () => setIsHovering(true)}
        onMouseLeave={/*  istanbul ignore next */ () => setIsHovering(false)}
      >
        {assignmentType === ASSIGNMENT_TYPES.else && isEditingAssignments && (
          <Button
            compact
            variant="subtle"
            icon={{ name: 'fa-plus-minus-small' }}
            css={{
              backgroundColor: 'inherit',
              fontWeight: 400,
              marginBottom: '$2',
            }}
            onClick={
              /* istanbul ignore next */ !isDeletingNode
                ? addAssignment
                : () => {}
            }
            data-testid="add-assignment-block"
          >
            add else if
          </Button>
        )}

        <Flex className="nodrag nopan" flow="column" gap="xs" ref={setNodeRef}>
          <Card
            css={{
              ...nodeStyle,
              .../* istanbul ignore next */ (isDeletingThisNode
                ? {
                    backgroundColor: '$red10',
                    border: '1px solid $red60',
                  }
                : {}),
              .../* istanbul ignore next */ (isDeletingNode &&
              !isDeletingThisNode
                ? {
                    borderColor: '$neutral70',
                  }
                : {}),
            }}
          >
            <Rules
              nodeId={id}
              rules={rules}
              type={assignmentType}
              isError={hasMissingRulesError}
              updateRules={updateRules}
              onDelete={() => {
                setIsDeletingNode(true, [...allDescendants, id]);
                toggle(true);
              }}
              canDelete={isDeletable}
              isDisabled={isDeletingNode}
            />
            {MemoizedItems}
            <Handle
              type="source"
              position={Position.Right}
              isConnectable={false}
              style={handleStyle}
              id={`${id}-s`}
            />
            <Overlay
              isHidden={
                /* istanbul ignore next */
                !isDeletingNode ||
                isDeletingThisNode ||
                descendantsToBeDeleted.includes(parentNode as never)
              }
              style={{ borderRadius: '8px' }}
            />
          </Card>

          {hasMissingRulesError && (
            <ErrorMessage message="Please enter rules or delete this assignment node." />
          )}
        </Flex>

        <DeleteModal
          title="Delete assignment node?"
          content={deleteCopy}
          isOpen={isOpen}
          toggle={
            /* istanbul ignore next */ (isToggled) => {
              if (!isToggled) {
                setIsDeletingNode(isToggled, []);
              }
              toggle(isToggled);
            }
          }
          runDelete={deleteAssignment}
        />
      </Box>
    </FlowTippy>
  );
}

export default AssignmentNode;
