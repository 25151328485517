import { Button, TextInput } from '@kandji-inc/bumblebee';
import React, { useEffect, useState } from 'react';
import './common.css';

const CheckLibraryFolder = (props) => {
  const { param, update, isDisabled, validations } = props;
  const { details } = param;
  const [exclusions, setExclusions] = useState(details || []);
  const [isInvalid, setIsInvalid] = useState(
    new Array(details?.length || 0).fill(false),
  );

  const {
    CheckLibraryFolder: { syncInvalid, displayInvalid },
  } = validations;

  useEffect(() => {
    if (isDisabled) {
      setExclusions(details || []);
      setIsInvalid(new Array(details?.length || 0).fill(false));
    }
  }, [param, isDisabled]);

  useEffect(() => {
    if (!isDisabled) {
      const time = setTimeout(() => {
        update({
          details: exclusions,
          isInvalid: isInvalid.some(Boolean),
        });
      }, 250);
      return () => clearTimeout(time);
    }
  }, [exclusions]);

  const addExclusion = () => {
    setExclusions((prev) => [...prev, '']);
    setIsInvalid((prev) => [...prev, true]);
  };

  const onTrash = (i) => {
    setExclusions((prev) => prev.filter((_, idx) => idx !== i));
    setIsInvalid((prev) => [...prev.filter((_, idx) => idx !== i)]);
  };

  return (
    <div className="">
      {exclusions.map((exclusion, idx) => {
        return (
          <div
            key={idx}
            className="b-mb3 b-flex"
            style={{ alignItems: 'flex-end' }}
          >
            <div className="b-flex1">
              <TextInput
                fieldsGrid
                disabled={isDisabled}
                wrapperClassName="b-flex1"
                className="b-flex1"
                label="Full paths of excluded directories"
                value={exclusion}
                placeholder="/Library/Excluded Directory/"
                onChange={(e) => {
                  const val = e.target.value;
                  setExclusions((prev) => {
                    prev[idx] = val;
                    return [...prev];
                  });
                }}
                onInvalidate={(isInvalid) =>
                  setIsInvalid((prev) => {
                    prev[idx] = isInvalid;
                    return [...prev];
                  })
                }
                validator={(v) => [
                  {
                    message: 'Required',
                    invalid: () => !v,
                    trigger: ['onBlur', 'onInput'],
                  },
                  {
                    message: 'Path must end with a /',
                    invalid: () => v[v.length - 1] !== '/',
                    trigger: ['onBlur', 'onInput'],
                  },
                  {
                    message: 'Path must begin with /Library/',
                    invalid: () => !v.startsWith('/Library/'),
                    trigger: ['onBlur', 'onInput'],
                  },
                  {
                    message: 'Path must not be equal to /Library/',
                    invalid: () => v.toLowerCase() === '/library/',
                    trigger: ['onBlur', 'onInput'],
                  },
                ]}
                {...syncInvalid(param.isInvalid, {
                  key: `exclusion-library-${idx}`,
                })}
              />
            </div>
            <Button
              className="b-ml-tiny"
              onClick={() => onTrash(idx)}
              disabled={isDisabled}
              kind="link"
              icon="trash-can"
              theme="error"
            />
          </div>
        );
      })}
      <Button onClick={addExclusion} disabled={isDisabled} icon="plus">
        Add Directory Exclusion
      </Button>
      {displayInvalid(param.isInvalid && !exclusions.length)}
    </div>
  );
};

export default CheckLibraryFolder;
