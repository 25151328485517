import { i18n } from 'i18n';
import { get } from 'lodash';
import _cloneDeep from 'lodash/fp/cloneDeep';
import _flow from 'lodash/fp/flow';
import _omit from 'lodash/fp/omit';
import React, { Fragment, useState, useEffect, useContext } from 'react';
import { useOldField } from 'src/old-honey-form';
import uuidv4 from 'uuid/v4';

import { AccountContext } from 'contexts/account';
import { EnvironmentContext, branch } from 'contexts/environment';
import { Button } from 'theme/components/molecules/Action';
import { FieldSelect, FieldText, Form } from 'theme/components/molecules/Form';
import useScroll from '../../hooks/useScroll';
import Banner from '../../theme/components/atoms/Banner';
import Icon from '../../theme/components/atoms/Icon';
import Link from '../../theme/components/atoms/Link';
import Loader from '../../theme/components/atoms/Loader';

import ButtonCancel from './button-cancel';
import ButtonSave from './button-save';
import { Context } from './context';
import FileUpload from './file-upload';
import slug from './slug';
import strategy from './strategy';

const { SAMLP: vendor } = slug;

const initial = {
  display_name: strategy[vendor].label,
  is_enabled: false,
};

const buildData = _flow(
  _cloneDeep,
  (raw) => {
    const optionKey = (key, defaultValue) =>
      get(raw, `options.${key}`, defaultValue);

    // ------------------------------------------------------------------------
    // Calculated state of the form inputs including their default values
    // ------------------------------------------------------------------------

    const data = {
      ...raw,
      options_sign_in_endpoint: optionKey('sign_in_endpoint', ''),
      options_sign_out_endpoint: optionKey('sign_out_endpoint', ''),
      options_signing_cert: optionKey('signing_cert', '')
        ? '-----BEGIN CERTIFICATE-----\n'.concat(
            optionKey('signing_cert', ''),
            '\n-----END CERTIFICATE-----',
          )
        : '',
      options_user_id_attribute: optionKey(
        'user_id_attribute',
        'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier',
      ),
      options_sign_saml_request: optionKey('sign_saml_request', true),
      options_signature_algorithm: optionKey(
        'signature_algorithm',
        'rsa-sha256',
      ),
      options_digest_algorithm: optionKey('digest_algorithm', 'sha256'),
      options_protocol_binding: optionKey(
        'protocol_binding',
        'urn:oasis:names:tc:SAML:2.0:bindings:HTTP-Redirect',
      ),
    };

    // ------------------------------------------------------------------------
    // Return
    // ------------------------------------------------------------------------

    return data;
  },
  _omit(['options']),
);

const deserialize = (raw) => buildData(raw);

const mapData = _flow(_cloneDeep, (form) => ({
  connection_name: form.connection_name,
  strategy: form.strategy,
  display_name: form.display_name,
  is_enabled: form.is_enabled,
  options: {
    sign_in_endpoint: form.options_sign_in_endpoint,
    sign_out_endpoint: form.options_sign_out_endpoint,
    signing_cert: form.options_signing_cert,
    user_id_attribute: form.options_user_id_attribute,
    sign_saml_request: form.options_sign_saml_request,
    signature_algorithm: form.options_signature_algorithm,
    digest_algorithm: form.options_digest_algorithm,
    protocol_binding: form.options_protocol_binding,
  },
}));

const processData = (form, dataMapped) => {
  const data = _cloneDeep(dataMapped);

  // --------------------------------------------------------------------------
  // Transformations
  // --------------------------------------------------------------------------

  if (form.options_sign_saml_request === false) {
    data.options.signature_algorithm = '';
    data.options.digest_algorithm = '';
  }

  if (form.options_signing_cert !== undefined) {
    data.options.signing_cert = form.options_signing_cert
      .replace(/-+BEGIN[a-zA-Z ]*-+\n*/s, '') // Strip Header
      .replace(/\n*-+END[a-zA-Z ]*-+\n*/s, '') // Strip Footer
      .replaceAll('\n', ''); // Strip all remaining newlines
  }

  // --------------------------------------------------------------------------
  // Deletions
  // --------------------------------------------------------------------------

  // deleted settings

  // --------------------------------------------------------------------------
  // Return
  // --------------------------------------------------------------------------

  return data;
};

const serialize = (form) => {
  const dataMapped = mapData(form);
  const data = processData(form, dataMapped);
  return data;
};

const Options = () => {
  const options_sign_saml_request = useOldField('options_sign_saml_request');
  const options_sign_saml_request_value = options_sign_saml_request.getValue();
  const isSignSAMLRequest = options_sign_saml_request_value === true;

  return (
    <>
      <div className="theme-form-block">
        <FieldText
          name="options_sign_in_endpoint"
          label={i18n.t('Sign-In URL')}
          detail={i18n.t(
            'Enter the application Sign In URL for your Identity Provider.',
          )}
          required
          maxLength={256}
          validateAfterSubmit
        />
      </div>
      <div className="theme-form-block">
        <FieldText
          name="options_sign_out_endpoint"
          label={i18n.t('Sign-Out URL')}
          detail={i18n.t(
            'Enter the Single Sign-Out URL for your identity provider.',
          )}
          maxLength={256}
          validateAfterSubmit
        />
      </div>
      <div className="theme-form-block">
        <FileUpload
          name="options_signing_cert"
          label={i18n.t('Signing Certificate Upload')}
          detail={i18n.t('Upload the signing certificate (PEM or CER).')}
          required
        />
      </div>
      <div className="theme-form-block">
        <FieldText
          name="options_user_id_attribute"
          label={i18n.t('User ID Attribute')}
          detail={i18n.t(
            'Provide the User ID attribute that will match to the email record in Kandji.',
          )}
          required
          maxLength={128}
          validateAfterSubmit
        />
      </div>
      <div className="theme-form-block">
        <FieldSelect
          name="options_sign_saml_request"
          label={i18n.t('Sign Request')}
          detail={i18n.t(
            'When enabled the SAML authentication request to the IdP will be signed.',
          )}
          options={[
            {
              label: i18n.t('Yes'),
              value: true,
            },
            {
              label: i18n.t('No'),
              value: false,
            },
          ]}
        />
      </div>
      {isSignSAMLRequest && (
        <>
          <div className="theme-form-block">
            <FieldSelect
              name="options_signature_algorithm"
              label={i18n.t('Sign Request Algorithm')}
              detail={i18n.t(
                'Choose the signing algorithm appropriate for your identity provider.',
              )}
              disabled={!isSignSAMLRequest}
              options={[
                {
                  label: 'RSA-SHA256',
                  value: 'rsa-sha256',
                },
                {
                  label: 'RSA-SHA1',
                  value: 'rsa-sha1',
                },
              ]}
            />
          </div>
          <div className="theme-form-block">
            <FieldSelect
              name="options_digest_algorithm"
              label={i18n.t('Sign Request Algorithm Digest')}
              detail={i18n.t(
                'Choose the signing algorithm appropriate for your identity provider.',
              )}
              disabled={!isSignSAMLRequest}
              options={[
                {
                  label: 'SHA 256',
                  value: 'sha256',
                },
                {
                  label: 'SHA 1',
                  value: 'sha1',
                },
              ]}
              attachment="bottom"
            />
          </div>
        </>
      )}
      <div className="theme-form-block">
        <FieldSelect
          name="options_protocol_binding"
          label={i18n.t('Protocol Binding')}
          detail={i18n.t(
            'Select the appropriate protocol binding for your identity provider.',
          )}
          options={[
            {
              label: i18n.t('HTTP-Redirect'),
              value: 'urn:oasis:names:tc:SAML:2.0:bindings:HTTP-Redirect',
            },
            {
              label: i18n.t('HTTP-POST'),
              value: 'urn:oasis:names:tc:SAML:2.0:bindings:HTTP-POST',
            },
          ]}
          attachment="bottom"
        />
      </div>
    </>
  );
};

const branchEnvMap = {
  [branch.DEV]: 'dev',
  [branch.QA]: 'qa',
  [branch.STAGE]: 'stage',
  [branch.PRD]: 'prod',
  [branch['PRD-EU']]: 'prod',
};

export default () => {
  const {
    data,
    fieldValidation,
    isFetching,
    initializeData,
    resetData,
    handleSave,
    onWizardClose,
  } = useContext(Context);
  const { currentCompany } = useContext(AccountContext);
  const { environmentBranch, environmentDomain } =
    useContext(EnvironmentContext);

  const [connectionNameNew, setConnectionNameNew] = useState(undefined);
  const [showAll, setShowAll] = useState(false);
  const initialValues = data ? deserialize(data) : initial;
  const { label, icon } = strategy[vendor];
  const [bodyScroll, bodyRef] = useScroll();

  const onClose = () => onWizardClose(false);
  const onSubmit = (form) => {
    const serialized = serialize(form);
    handleSave(serialized, connectionNameNew);
  };

  useEffect(() => {
    initializeData(initial);
    return () => resetData();
  }, []);
  useEffect(() => {
    setConnectionNameNew(`${currentCompany.subdomain}-${vendor}-${uuidv4()}`);
  }, []);

  const connectionName = get(data, 'connection_name', connectionNameNew);

  const linkAssertionConsumerServiceURL = `https://auth.${environmentDomain}.io/login/callback?connection=${connectionName}`;
  const linkServiceProviderSigningCertificate = `https://auth.${environmentDomain}.io/cer?cert=connection`;
  const linkServiceProviderMetadataFile = `https://auth.${environmentDomain}.io/samlp/metadata?connection=${connectionName}`;

  const entityID = `urn:auth0:kandji-${branchEnvMap[environmentBranch]}:${connectionName}`;

  const textConfiguration =
    data && !isFetching ? (
      <>
        <p>
          <strong>
            {i18n.t('Identity Provider Configuration Information')}
          </strong>
        </p>
        <p>
          <u>{i18n.t('Service Provider Metadata File')}</u>
          <br />
          {linkServiceProviderMetadataFile}
        </p>
        {showAll ? (
          <>
            <p>
              <u>{i18n.t('Assertion Consumer Service URL')}</u>
              <br />
              {linkAssertionConsumerServiceURL}
            </p>
            <p>
              <u>{i18n.t('Entity ID')}</u>
              <br />
              {entityID}
            </p>
            <p>
              <u>{i18n.t('Service Provider Signing Certificate')}</u>
              <br />
              <Link
                text={i18n.t('Download Certificate')}
                href={linkServiceProviderSigningCertificate}
              />
            </p>
            <p>
              <a className="btn-link" onClick={() => setShowAll(false)}>
                {i18n.t('Hide Advanced Details')}
              </a>
            </p>
          </>
        ) : (
          <p>
            <a className="btn-link" onClick={() => setShowAll(true)}>
              {i18n.t('Show Advanced Details')}
            </a>
          </p>
        )}
      </>
    ) : (
      <p>{i18n.t('Loading')}...</p>
    );

  return (
    <div className="paper">
      <div className="theme-form-aside">
        <div className="node">
          <Button kind="close" tier="two" icon="xmark" onClick={onClose} />
        </div>
      </div>
      <div className={`theme-form-header ${bodyScroll ? 'is-sticky' : ''}`}>
        <div className="node">
          <h3 className="theme-form-title paper-title strategy">
            <Icon icon={icon} />
            <span className="label">{label}</span>
          </h3>
          <p className="theme-form-description paper-description">
            {i18n.t('Configure SSO with Custom SAML.')}{' '}
            <Link href="https://support.kandji.io/support/solutions/articles/72000560505" />
          </p>
          <Banner text={textConfiguration} />
        </div>
      </div>
      {data && !isFetching ? (
        <Form
          initialValues={initialValues}
          onSubmit={onSubmit}
          fieldValidation={fieldValidation}
        >
          <div ref={bodyRef} className="theme-form-body">
            <div className="node hubspot-buffer-bottom">
              <div className="theme-form-block">
                <FieldText
                  name="display_name"
                  label={i18n.t('Name')}
                  detail={i18n.t(
                    'Provide a name for the SSO connection. Cannot exceed 32 characters.',
                  )}
                  required
                  maxLength={32}
                  validateAfterSubmit
                />
              </div>
              <Options />
            </div>
          </div>
          <div className="theme-form-footer">
            <div className="node">
              <ButtonCancel />
              <ButtonSave />
            </div>
          </div>
        </Form>
      ) : (
        <div ref={bodyRef} className="theme-form-body">
          <div className="node node-height-full">
            <Loader type="simple" />
          </div>
        </div>
      )}
    </div>
  );
};
