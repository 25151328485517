import {
  type ToastVariants,
  useToast_UNSTABLE as useToast,
} from '@kandji-inc/nectar-ui';
import { type CSSProperties, useCallback, useContext } from 'react';

import { InterfaceContext } from 'src/contexts/interface';

export const DEFAULT_SIDEBAR_OPENED_TOAST_LEFT_OFFSET_PX = 268;
export const DEFAULT_SIDEBAR_CLOSED_TOAST_LEFT_OFFSET_PX = 90;

export type DisplayToastArgs = {
  title: string;
  content?: string;
  variant?: ToastVariants;
};

export interface UseSidebarToastArgs {
  config?: {
    sidebarOpenedOffset?: number;
    sidebarClosedOffset?: number;
  };
  style?: CSSProperties;
}

/**
 * Custom hook wrapping over Nectar's `useToast@v2` hook to display a toast with
 * customizable left offset and CSS styles when the global sidebar is opened or
 * closed.
 *
 * @param config - Configuration object to override default sidebar open/closed
 * offset in pixels.
 * @param style - Custom style object for larger control overriding default
 * toast CSS styles.
 * @returns Object with `displayToast` function to display a toast with sidebar
 * open/closed offset as well as all other `useToast` hook return values.
 */
export function useSidebarToast({
  config = {},
  style = {},
}: UseSidebarToastArgs = {}) {
  const { toast, ...restToastActions } = useToast();
  const { sidebarOpened } = useContext(InterfaceContext);

  const sidebarOpenedOffsetPx =
    config.sidebarOpenedOffset || DEFAULT_SIDEBAR_OPENED_TOAST_LEFT_OFFSET_PX;
  const sidebarClosedOffsetPx =
    config.sidebarClosedOffset || DEFAULT_SIDEBAR_CLOSED_TOAST_LEFT_OFFSET_PX;

  const displayToast = useCallback(
    ({ title, content, variant }: DisplayToastArgs) =>
      toast({
        title,
        content,
        variant,
        style: {
          left: sidebarOpened
            ? `${sidebarOpenedOffsetPx}px`
            : `${sidebarClosedOffsetPx}px`,
          bottom: '12px',
          position: 'absolute',
          width: '100%',
          ...style,
        },
      }),
    [toast, sidebarOpened, style, sidebarOpenedOffsetPx, sidebarClosedOffsetPx],
  );

  return {
    displayToast,
    ...restToastActions,
  };
}
