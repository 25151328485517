import { modal as Modal } from '@kandji-inc/bumblebee';
import { bool, func, object } from 'prop-types';
import React from 'react';
import { i18n } from 'src/i18n';
import Content from './components/content';
import Footer from './components/footer';

const IntegrationDeleteModal = (props) => {
  const { isOpen, data, onDelete, onCancel } = props;

  const { integrations } = data;

  return (
    <Modal
      isOpen={isOpen}
      withoutCloseButton
      disableCloseOnOutsideClick
      parts={{
        header: {
          text: i18n.t('Delete Microsoft Teams integration'),
        },
        content: {
          children: <Content integrations={integrations} />,
        },
        footer: {
          children: <Footer onCancel={onCancel} onDelete={onDelete} />,
        },
      }}
    />
  );
};

export default IntegrationDeleteModal;

IntegrationDeleteModal.propTypes = {
  isOpen: bool.isRequired,
  data: object.isRequired,
  onDelete: func.isRequired,
  onCancel: func.isRequired,
};
