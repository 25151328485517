import { Box, Code, Flex, Text, Tooltip, styled } from '@kandji-inc/nectar-ui';
import { i18n } from 'i18n';
import { useEffect, useRef, useState } from 'react';
import EDRCopyButton from 'src/features/edr/common/components/EDRCopyButton';
import { formatDate } from 'src/features/edr/vulnerability/helpers';
import type { AppSummary } from 'src/features/edr/vulnerability/vulnerability.types';
import {
  doesTextHaveMatch,
  highlightedText,
} from 'src/pages/ADEListView/utils/highlightText';

const DetailText = styled(Text, {
  fontSize: '$1',
  color: '$neutral70',
});

const DevicePath = (props: { app: AppSummary; searchTerm: string }) => {
  const { app, searchTerm } = props;
  const { detection_date, name, version, path } = app;
  const [isHoveringPath, setIsHoveringPath] = useState(false);
  const [isOverflowing, setIsOverflowing] = useState(false);

  const [isMatchInOverflow, setIsMatchInOverflow] = useState(false);
  const codeRef = useRef<HTMLDivElement>(null);
  const hiddenTextRef = useRef<HTMLDivElement>(null);

  useEffect(
    /* istanbul ignore next */ () => {
      if (codeRef.current && hiddenTextRef.current) {
        const isOverflowing =
          hiddenTextRef.current.scrollWidth > codeRef.current.clientWidth;
        setIsOverflowing(isOverflowing);

        // Determines if there is a match in the overflowed text. This determines whether or not the ellipsis should be highlighted.
        if (isOverflowing) {
          const visibleTextWidth = codeRef.current.clientWidth;
          const fullText = hiddenTextRef.current.textContent || '';
          const visibleText = fullText.slice(
            0,
            Math.floor(visibleTextWidth / 8),
          ); // Approximate character width
          const overflowingText = fullText.slice(visibleText.length);

          setIsMatchInOverflow(
            searchTerm && doesTextHaveMatch(overflowingText, searchTerm),
          );
        }
      }
    },
    [path, searchTerm],
  );

  const pathDisplay = (
    <Code
      css={{ display: 'flex', maxWidth: isHoveringPath ? '177px' : '201px' }}
    >
      <Text
        ref={codeRef}
        css={{
          fontSize: '$1',
          fontFamily: '$code',
          lineHeight: '$1',
          maxWidth: '177px',
          overflow: 'hidden',
          whiteSpace: 'nowrap',
        }}
      >
        {highlightedText(path, searchTerm)}
      </Text>
      <Text
        ref={hiddenTextRef}
        css={{
          fontSize: '$1',
          fontFamily: '$code',
          lineHeight: '$1',
          position: 'absolute',
          visibility: 'hidden',
          whiteSpace: 'nowrap',
        }}
      >
        {path}
      </Text>

      {/* Manually add ellipsis when the text is overflowing so that we have 
      control over whether or not it is highlighted upon search. */}
      {
        /* istanbul ignore next - text cannot overflow in tests */ isOverflowing && (
          <Text
            css={{
              fontSize: '$1',
              lineHeight: '$1',
              background: isMatchInOverflow ? '$yellow30' : '',
            }}
          >
            ...
          </Text>
        )
      }
    </Code>
  );

  return (
    <Flex
      flow="column"
      pt3
      pb3
      css={{ gap: '$2', borderTop: '1px solid $neutral20' }}
      onMouseLeave={() => setIsHoveringPath(false)}
    >
      <Flex alignItems="center">
        <Flex alignItems="center" gap="xs" css={{ width: '237px' }}>
          <DetailText>{i18n.t('Detection date:')}</DetailText>
          <Tooltip
            side="bottom"
            theme="dark"
            content={formatDate({
              date: detection_date,
              showUserFriendlyTimestamp: true,
            })}
            css={{ zIndex: 2 }}
          >
            <Text size="1">{formatDate({ date: detection_date })}</Text>
          </Tooltip>
        </Flex>

        <Flex alignItems="center" gap="xs" css={{ width: '237px' }}>
          <DetailText>{i18n.t('Application:')}</DetailText>
          <Text>{name ? name : '-'}</Text>
        </Flex>

        <Flex alignItems="center" gap="xs" css={{ width: '147px' }}>
          <DetailText>{i18n.t('Version:')}</DetailText>
          <Text size="1">{highlightedText(version, searchTerm)}</Text>
        </Flex>
      </Flex>

      <Flex
        alignItems="center"
        gap="xs"
        onMouseEnter={() => setIsHoveringPath(true)}
        onMouseLeave={() => setIsHoveringPath(false)}
        css={{ width: '237px' }}
      >
        <DetailText>{i18n.t('Path:')}</DetailText>
        {
          /* istanbul ignore next - text cannot overflow in tests */ isOverflowing ? (
            <Tooltip
              content={<Text>{highlightedText(path, searchTerm)}</Text>}
              theme="dark"
              side="bottom"
              css={{ zIndex: 2, maxWidth: 'auto' }}
            >
              {pathDisplay}
            </Tooltip>
          ) : (
            pathDisplay
          )
        }
        <Box css={{ visibility: isHoveringPath ? 'visible' : 'hidden' }}>
          <EDRCopyButton
            value={path}
            testId="path-copy-button"
            css={{ tooltipCss: { zIndex: 2 } }}
          />
        </Box>
      </Flex>
    </Flex>
  );
};

export { DevicePath };
