import React, { useState, useEffect } from 'react';

import {
  Banner,
  Button,
  Checkbox,
  Flex,
  Select,
  TextInput,
} from '@kandji-inc/bumblebee';

import { i18n } from 'i18n';

import { STATUS_CONNECTED } from 'features/integrations/components-for-specific-types/adcs/status';
import { Controls, Title } from 'features/library-items/template';
import {
  useAdvanced,
  useAuthority,
  useConnector,
  useIs,
  useModal,
  usePanel,
} from '../../hooks';

import iconConnector from '../../assets/icon-connector.svg';

import { excludeByProperty, findOption, includeByProperty } from '../../util';

import Panel from '..';
import Header from '../header';

const PanelDeleteConnector = () => {
  const { deleteConnectorItem } = useAdvanced();

  const { authorityList, fetchAuthorityList } = useAuthority();

  const {
    connector,
    connectorItem,
    connectorList,
    clearConnector,
    clearConnectorItem,
    fetchConnectorItem,
    fetchConnectorList,
  } = useConnector();

  const { isLoading, isWorking } = useIs();
  const { openModal } = useModal();
  const { panel, closePanel } = usePanel();

  const isOpen = !!panel['delete-connector'];

  const initial = {
    id: '',
    manage: [],
  };

  const [form, setForm] = useState(initial);

  const { manage } = form;

  const [agreed, setAgreed] = useState(false);

  /* istanbul ignore next */
  const servers = includeByProperty(authorityList, 'connector', connector)?.map(
    (server) => ({
      ...server,
      connector: null,
    }),
  );

  /* istanbul ignore next */
  const connectorListWithoutCurrent = excludeByProperty(
    connectorList,
    'id',
    connector,
  );

  const filteredConnectorList = includeByProperty(
    connectorListWithoutCurrent,
    'status',
    STATUS_CONNECTED,
  );

  /* istanbul ignore next */
  const hasConnectorInstalled = filteredConnectorList?.length > 0;

  /* istanbul ignore next */
  const optionsConnectors = [
    {
      value: null,
      label: i18n.t('Not assigned'),
    },
    ...filteredConnectorList?.map(
      ({ id: value, name: connectorName, bound_domain }) => ({
        label:
          connectorName ||
          bound_domain ||
          `${i18n.t('Connector details are pending')}...`,
        value,
      }),
    ),
  ];

  /* istanbul ignore next */
  const valueConnector = (index) => {
    const list = [...manage];

    return findOption(list[index].connector, optionsConnectors);
  };

  const updateForm = (k, v) => setForm((p) => ({ ...p, [k]: v }));

  /* istanbul ignore next */
  const handleEditServerConnector = (index, value) => {
    const list = [...manage];

    list[index].connector = value;

    updateForm('manage', list);
  };

  /* istanbul ignore next */
  const handleAgreed = () => setAgreed(!agreed);

  /* istanbul ignore next */
  const reset = () => {
    clearConnector();
    clearConnectorItem();
    setAgreed(false);
    setForm(initial);
  };

  /* istanbul ignore next */
  const openModalDeleteConnector = () => {
    closePanel(() => openModal('delete-connector'));
  };

  /* istanbul ignore next */
  const onCancel = () => {
    closePanel(reset);
  };

  /* istanbul ignore next */
  const onDelete = async () => {
    await deleteConnectorItem(form);
    fetchAuthorityList();
    fetchConnectorList();
    closePanel(reset);
  };

  /* istanbul ignore next */
  useEffect(() => {
    if (isOpen && connector) {
      fetchAuthorityList();
      fetchConnectorItem();
    }
  }, [isOpen, connector]);

  /* istanbul ignore next */
  useEffect(() => {
    if (isOpen && connectorItem?.adcs_authorities?.length === 0) {
      openModalDeleteConnector();
    }
  }, [isOpen, connectorItem]);

  /* istanbul ignore next */
  useEffect(() => {
    if (connectorItem?.id) {
      setForm({
        ...form,
        id: connectorItem?.id,
        manage: servers,
      });
    }
    return () => setForm(initial);
  }, [connectorItem]);

  useEffect(
    () => () => {
      reset();
    },
    [],
  );

  /* istanbul ignore next */
  const message = hasConnectorInstalled ? (
    <>
      <p className="b-txt">
        {i18n.$t(
          'The integration with {connectorNameElement} will be removed and any existing assigned servers specified below will need to be reassigned.',
          {
            connectorNameElement: (
              <strong>
                {i18n.t('Connector {connectorItemName}', {
                  connectorItemName: connectorItem?.name || '',
                })}
              </strong>
            ),
          },
        )}
      </p>

      <Banner className="b-mt" theme="info" kind="block">
        <p>
          {i18n.t(
            'Servers that are not assigned to an active AD CS connector will no longer be able to issue certificates or process future requests for certificate management.',
          )}
        </p>
      </Banner>
    </>
  ) : (
    <>
      <p className="b-txt">
        {i18n.$t(
          'The integration with {connectorNameElement} will be removed and any existing servers specified below will be unassigned.',
          {
            connectorNameElement: (
              <strong>
                {i18n.t('Connector {connectorItemName}', {
                  connectorItemName: connectorItem?.name || '',
                })}
              </strong>
            ),
          },
        )}
      </p>

      <Banner
        className="b-mt"
        theme="warning"
        kind="block"
        icon="octagon-exclamation"
      >
        <p>
          {i18n.t(
            'Servers that are not assigned to an active AD CS connector will no longer be able to issue certificates or process future requests for certificate management.',
          )}
        </p>
      </Banner>
    </>
  );

  /* istanbul ignore next */
  const loopEditServer = manage?.length > 0 && (
    <div className="k-section-primary k-section-border-top">
      <h4 className="b-h4 b-mb1">
        {
          /* istanbul ignore next */
          hasConnectorInstalled
            ? i18n.t('Reassign the following AD CS servers:')
            : i18n.t('The following servers will be unassigned:')
        }
      </h4>

      <div className="k-adcs-server-list">
        {manage?.map(({ id, name }, index) => (
          <div
            key={id}
            className={`k-adcs-server-item k-adcs-server-item-${index}`}
          >
            <div className="k-section-secondary">
              <Title>
                <p className="b-txt b-mb1">
                  {i18n.t('Server name and new connector assignment')}
                </p>
              </Title>

              <Controls>
                <TextInput
                  className="b-mb"
                  placeholder={i18n.t('Enter the server name')}
                  value={name}
                  onChange={
                    /* istanbul ignore next */
                    () => {}
                  }
                  disabled
                  readOnly
                />

                <Select
                  className={
                    /* istanbul ignore next */
                    hasConnectorInstalled ? undefined : 'k-adcs-select-disabled'
                  }
                  placeholder={i18n.t('Select connector from list')}
                  options={optionsConnectors}
                  value={valueConnector(index)}
                  onChange={
                    /* istanbul ignore next */
                    hasConnectorInstalled
                      ? ({ value }) => handleEditServerConnector(index, value)
                      : () => {}
                  }
                  disabled={isLoading || isWorking || !hasConnectorInstalled}
                />
              </Controls>
            </div>
          </div>
        ))}
      </div>
    </div>
  );

  /* istanbul ignore next */
  return connectorItem?.adcs_authorities?.length > 0 ? (
    <Panel name="delete-connector">
      <Header title={i18n.t('Delete AD CS connector')} icon={iconConnector} />

      <div className="b-side-panel-layout__body hubspot-buffer-bottom">
        <div className="k-section-primary">
          <h4 className="b-h4 b-mb1">
            {i18n.t('Are you sure you want to delete this integration?')}
          </h4>

          {message}
        </div>

        {loopEditServer}

        <div className="k-section-primary k-section-border-top">
          <Controls>
            <Checkbox
              className="k-adcs-agreed"
              label={i18n.t(
                'I have read the above information and understand this action cannot be undone.',
              )}
              checked={agreed}
              onChange={handleAgreed}
              disabled={isLoading || isWorking}
            />
          </Controls>
        </div>
      </div>

      <div className="b-side-panel-layout__footer">
        <Flex justify="flex-end">
          <div className="b-grid-ctas">
            <Button
              kind="outline"
              onClick={onCancel}
              isDisabled={isLoading || isWorking}
            >
              {i18n.t('Cancel')}
            </Button>

            <Button
              theme="error"
              icon={isWorking ? 'arrows-rotate' : undefined}
              onClick={
                /* istanbul ignore next */
                agreed ? onDelete : () => {}
              }
              isDisabled={isLoading || isWorking || !agreed}
              isProgress={isWorking}
            >
              {i18n.t('Delete')}
            </Button>
          </div>
        </Flex>
      </div>
    </Panel>
  ) : null;
};

export default PanelDeleteConnector;
