import { integrationsBackendKeys } from 'src/features/integrations/hooks/query-key-factory';

/* This query key factory is used to provide query keys to our custom reactQuery hooks  */

export const msDeviceComplianceKeys = {
  list: () => [...integrationsBackendKeys.all, 'msCompliance'] as const,
  userGroupList: (searchTerm?: string, ids?: string) =>
    ['msCompliance', 'userGroupList', searchTerm, ids] as const,
  integrationDetails: (id: string) =>
    ['msCompliance', 'integrationDetails', id] as const,
};
