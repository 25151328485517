import { links } from 'app/common/constants';
import { i18n } from 'i18n';
import { useMemo, useState } from 'react';
import RenderBreadcrumbs from '../../../../components/render-breadcrumbs';
import { INT_TYPES } from '../../../../constants';

import {
  Button,
  DropdownMenu,
  Flex,
  Icon,
  Loader,
  Tabs,
  Text,
  Toaster_UNSTABLE as Toaster,
  styled,
} from '@kandji-inc/nectar-ui';

import {
  StyledCard,
  StyledCardSectionFooter,
  StyledCardSectionHeader,
  StyledHeaderH2,
  StyledNav,
} from '../../../../components/styled-components/main';
import msDeviceComplianceLogo from '../../../../generic-cards-view/assets/microsoft-device-compliance.svg';
import useListIntegrations from '../../../hooks/use-list-integrations';
import useGetIntegrationDetails from '../../hooks/use-get-integration-details';
import AddEditDevicePlatform from '../add-edit-device-platform';
import DeleteDevicePlatform from '../delete-device-platform';
import DeleteIntegration from '../delete-integration';
import ViewUserGroups from '../partials/view-user-groups';

import type { IntegrationDetails, PlatformStatus } from '../../types';
import { DEVICE_PLATFORMS } from '../../types';

const StyledAddDeviceButton = styled(Button, {
  marginLeft: 'auto',
});

const breadcrumbItems = [
  {
    get title() {
      return i18n.t('Integrations');
    },
    url: links.integrations.root,
  },
  {
    get title() {
      return i18n.t('Microsoft Device Compliance');
    },
  },
];

const tabs = [
  {
    get label() {
      return i18n.t('Overview');
    },
    tabId: 'overview',
  },
];

export const getPlatformLabel = (platform: PlatformStatus) => {
  return platform && platform.toLowerCase() === DEVICE_PLATFORMS.macos
    ? i18n.t('macOS')
    : i18n.t('iOS/iPadOS');
};

export const DetailsIntegration = () => {
  const [isDeleteIntegrationDialogOpen, setIsDeleteIntegrationDialogOpen] =
    useState(false);
  const [isDeletePlatformDialogOpen, setIsDeletePlatformDialogOpen] =
    useState(false);
  const [isAddOrEditPlatformDialogOpen, setIsAddOrEditPlatformDialogOpen] =
    useState(false);
  const [platformActive, setPlatformActive] = useState<PlatformStatus>(null);

  // * get integration id by integration type from main-be service *
  const { data: listResult, isLoading: isListLoading } = useListIntegrations({
    integrationType: INT_TYPES.msDeviceCompliance,
  });
  const integrationId = listResult?.data?.results?.[0]?.uuid;

  // * get integration details by id from device-compliance service *
  const { data: detailsResult, isLoading: isDetailsLoading } =
    useGetIntegrationDetails(integrationId) as {
      data: IntegrationDetails;
      isLoading: boolean;
    };

  const isPlatformConfigured = (platform: DEVICE_PLATFORMS) => {
    const value =
      detailsResult?.data?.[`${platform.toLowerCase()}_entra_group_ids`];
    return value && value.length > 0;
  };

  const isAllLoading: boolean = useMemo(
    () => isListLoading || isDetailsLoading,
    [isListLoading, isDetailsLoading],
  );

  const handleOpenAzureAdminPortal = () => {
    window.open('https://entra.microsoft.com', '_blank');
  };

  const toggleDeleteIntegrationDialog = () => {
    setIsDeleteIntegrationDialogOpen((prev) => !prev);
  };

  const toggleAddOrEditPlatformDialog = (platform: PlatformStatus) => {
    setIsAddOrEditPlatformDialogOpen((prev) => !prev);
    setPlatformActive(platform);
  };

  const toggleDeletePlatformDialog = (platform: PlatformStatus) => {
    setIsDeletePlatformDialogOpen((prev) => !prev);
    setPlatformActive(platform);
  };

  return (
    <>
      <Flex flow="column" gap="md">
        <StyledNav>
          <RenderBreadcrumbs items={breadcrumbItems} />
        </StyledNav>

        <Tabs.Container
          compact
          tabs={tabs}
          defaultTabId="overview"
          css={{
            gap: '$6',
          }}
        >
          {tabs.map((tab) => (
            <Tabs.Content tabId="overview" lazy key={tab.tabId}>
              {isAllLoading ? (
                <Flex justifyContent="center">
                  <Loader size="lg" />
                </Flex>
              ) : (
                <>
                  {detailsResult?.data && (
                    <Flex flow="column" gap="xl">
                      {/* General */}
                      <StyledCard>
                        <StyledCardSectionHeader>
                          <StyledHeaderH2 as="h2">
                            {i18n.t('General')}
                          </StyledHeaderH2>
                          <DropdownMenu
                            options={[
                              {
                                testId: 'dropdown-menu:delete-integration',
                                label: i18n.t('Delete integration'),
                                onClick: () => toggleDeleteIntegrationDialog(),
                              },
                            ]}
                          >
                            <Button
                              data-testid="dropdown-menu:ellipsis"
                              variant="default"
                              css={{
                                color: '$neutral70',
                                backgroundColor: '$neutral0',
                                position: 'absolute',
                                right: 32,
                              }}
                            >
                              <Icon name="ellipsis" />
                            </Button>
                          </DropdownMenu>
                        </StyledCardSectionHeader>

                        <Flex gap="lg" p5>
                          <Flex
                            alignItems="center"
                            justifyContent="center"
                            css={{
                              width: '72px',
                              height: '72px',
                              border: '1px solid rgba(120, 120, 128, 0.16)',
                              borderRadius: '15px',
                            }}
                          >
                            <img
                              src={msDeviceComplianceLogo}
                              width="52"
                              height="52"
                              alt="icon"
                            />
                          </Flex>

                          <Flex flow="column" gap="xs">
                            <Text variant="description">
                              {i18n.t('Microsoft Primary Domain')}
                            </Text>
                            <Text>{detailsResult.data?.ms_domain_name}</Text>
                          </Flex>
                        </Flex>

                        <StyledCardSectionFooter>
                          <Button
                            variant="link"
                            onClick={handleOpenAzureAdminPortal}
                            icon={{
                              name: 'arrow-up-right-from-square',
                              position: 'right',
                            }}
                            css={{
                              color: '$neutral70',
                              py: '$2',
                              px: '$3',
                            }}
                          >
                            {i18n.t('Open Microsoft Entra ID admin portal')}
                          </Button>
                        </StyledCardSectionFooter>
                      </StyledCard>

                      {/* Platforms && User Groups */}
                      {Object.values(DEVICE_PLATFORMS).map((platform) => (
                        <StyledCard key={platform}>
                          <StyledCardSectionHeader>
                            <StyledHeaderH2 as="h2">
                              {getPlatformLabel(platform)} {i18n.t('devices')}
                            </StyledHeaderH2>
                            {!isPlatformConfigured(platform) && (
                              <StyledAddDeviceButton
                                variant="subtle"
                                icon={{
                                  name: 'circle-plus',
                                  position: 'left',
                                }}
                                onClick={() =>
                                  toggleAddOrEditPlatformDialog(platform)
                                }
                              >
                                {i18n.t('Add device platform')}
                              </StyledAddDeviceButton>
                            )}

                            {isPlatformConfigured(platform) && (
                              <DropdownMenu
                                options={[
                                  {
                                    testId: 'dropdown-menu:edit-user-group',
                                    label: i18n.t('Edit user group'),
                                    onClick: () =>
                                      toggleAddOrEditPlatformDialog(platform),
                                  },
                                  {
                                    testId:
                                      'dropdown-menu:delete-device-platform',
                                    label: i18n.t('Delete device platform'),
                                    theme: 'danger',
                                    onClick: () =>
                                      toggleDeletePlatformDialog(platform),
                                  },
                                ]}
                              >
                                <Button
                                  data-testid="dropdown-menu:ellipsis-platform"
                                  variant="default"
                                  css={{
                                    color: '$neutral70',
                                    backgroundColor: '$neutral0',
                                    position: 'absolute',
                                    right: 32,
                                  }}
                                >
                                  <Icon name="ellipsis" />
                                </Button>
                              </DropdownMenu>
                            )}
                          </StyledCardSectionHeader>

                          {isPlatformConfigured(platform) && (
                            <ViewUserGroups
                              key={platform}
                              integrationDetails={detailsResult.data}
                              devicePlatform={platform}
                            />
                          )}
                        </StyledCard>
                      ))}
                    </Flex>
                  )}
                </>
              )}
            </Tabs.Content>
          ))}
        </Tabs.Container>
      </Flex>

      <DeleteIntegration
        isDialogOpen={isDeleteIntegrationDialogOpen}
        integrationId={integrationId}
        handleToggle={toggleDeleteIntegrationDialog}
      />

      <DeleteDevicePlatform
        isDialogOpen={isDeletePlatformDialogOpen}
        platform={platformActive}
        integrationId={integrationId}
        handleToggle={toggleDeletePlatformDialog}
      />

      <AddEditDevicePlatform
        isDialogOpen={isAddOrEditPlatformDialogOpen}
        platform={platformActive}
        integrationId={integrationId}
        integrationDetails={detailsResult?.data}
        handleToggle={toggleAddOrEditPlatformDialog}
      />

      <Toaster />
    </>
  );
};
