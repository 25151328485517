import { array, bool, func, number, string } from 'prop-types';
import React from 'react';
import ReactSelect from 'react-select';
import {
  Control,
  DropdownIndicator,
  Option,
  ValueContainer,
} from './components';

const SelectDropdown = ({
  options,
  onChange,
  placeholder,
  maxMenuHeight = 150,
  noOptionsMessage,
  disabled = false,
  isLoading,
  ...props
}) => (
  <ReactSelect
    className="cmp-select_input-wrapper"
    components={{ Control, ValueContainer, Option, DropdownIndicator }}
    options={options}
    getOptionLabel={(option) => option.name}
    getOptionValue={(option) => option.id}
    placeholder={placeholder}
    maxMenuHeight={maxMenuHeight}
    onChange={onChange}
    noOptionsMessage={() => noOptionsMessage}
    isLoading={isLoading}
    styles={{
      noOptionsMessage: (provided) => ({
        ...provided,
        textAlign: 'left',
      }),
    }}
    isDisabled={disabled}
    {...props}
  />
);

SelectDropdown.propTypes = {
  options: array.isRequired,
  onChange: func.isRequired,
  placeholder: string,
  maxMenuHeight: number,
  noOptionsMessage: string,
  disabled: bool,
  isLoading: bool,
};

SelectDropdown.defaultProps = {
  maxMenuHeight: 150,
  noOptionsMessage: 'No results found',
  placeholder: '',
  disabled: false,
  isLoading: false,
};

export default SelectDropdown;
