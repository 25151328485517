import { api } from 'app/api/base';
import { URL_COMPANY_INFO } from 'app/api/urls';

const patchCompanySettings = async (data) => {
  try {
    const response = await api(URL_COMPANY_INFO).patch(data);
    return response.data;
  } catch (error) {
    return error;
  }
};

export { patchCompanySettings };
