/* istanbul ignore file */
import { useEffect } from 'react';

function useScrollToTop(props = {}) {
  const { isLoading = false } = props;

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'auto',
    });
    if (isLoading) {
      window.setTimeout(() => {
        window.scrollTo({
          top: 0,
          left: 0,
          behavior: 'auto',
        });
      }, 0);
    }
  }, [isLoading]);
}

export default useScrollToTop;
