import { Select, TextInput } from '@kandji-inc/bumblebee';
import deepcopy from 'deepcopy';
import React, { useEffect, useState } from 'react';
import './common.css';

const defaultData = {
  days: 60,
  modifier: 'or',
  size: 1,
  unit: 'GB',
};

const modifierOptions = [
  { label: 'Or', value: 'or' },
  { label: 'And', value: 'and' },
];

const unitOptions = [
  { label: 'GB', value: 'GB' },
  { label: 'MB', value: 'MB' },
];

const RetentionSecurityAuditing = (props) => {
  const { param, update, isDisabled, validations } = props;
  const { details } = param;
  const [value, setValue] = useState(details || deepcopy(defaultData));
  const [isInvalid, setIsInvalid] = useState([false, false]);

  const {
    RetentionSecurityAuditing_days: validateDays,
    RetentionSecurityAuditing_size: validateSize,
  } = validations;

  useEffect(() => {
    if (isDisabled) {
      setValue(details || deepcopy(defaultData));
      setIsInvalid([false, false]);
    }
  }, [param, isDisabled]);

  useEffect(() => {
    if (!isDisabled) {
      const time = setTimeout(() => {
        update({ details: value, isInvalid: isInvalid.some(Boolean) });
      }, 250);

      return () => clearTimeout(time);
    }
  }, [value, isInvalid]);

  return (
    <div className="bl-p-align-end bl-p-grid-4 b-mb">
      <TextInput
        fieldsGrid
        type="number"
        disabled={isDisabled}
        label="Days"
        value={value.days}
        onChange={(e) => {
          const val = e.target.value;
          setValue((prev) => ({ ...prev, days: parseInt(val) || '' }));
        }}
        onInvalidate={(isInvalid) =>
          setIsInvalid((prev) => {
            prev[0] = isInvalid;
            return [...prev];
          })
        }
        validator={(v) => [
          {
            message: 'Required',
            invalid: () => !v,
            trigger: ['onBlur', 'onInput'],
          },
          {
            message: 'Value not in range. Min: 1 - Max: 1095',
            invalid: () => v < 1 || v > 1095,
            trigger: ['onBlur', 'onInput'],
          },
        ]}
        {...validateDays.syncInvalid(isInvalid[0])}
      />
      <Select
        disabled={isDisabled}
        options={modifierOptions}
        value={modifierOptions.find((d) => d.value === value.modifier)}
        onChange={({ value }) =>
          setValue((prev) => ({ ...prev, modifier: value }))
        }
      />
      <TextInput
        fieldsGrid
        type="number"
        disabled={isDisabled}
        label="Size"
        value={value.size}
        onChange={(e) => {
          const val = e.target.value;
          setValue((prev) => ({ ...prev, size: parseInt(val) || '' }));
        }}
        onInvalidate={(isInvalid) =>
          setIsInvalid((prev) => {
            prev[1] = isInvalid;
            return [...prev];
          })
        }
        validator={(v) => [
          {
            message: 'Required',
            invalid: () => !v,
            trigger: ['onBlur', 'onInput'],
          },
          {
            message: 'Value not in range. Min: 0',
            invalid: () => v < 0,
            trigger: ['onBlur', 'onInput'],
          },
        ]}
        {...validateSize.syncInvalid(isInvalid[1])}
      />
      <Select
        disabled={isDisabled}
        options={unitOptions}
        value={unitOptions.find((d) => d.value === value.unit)}
        onChange={({ value }) => setValue((prev) => ({ ...prev, unit: value }))}
      />
    </div>
  );
};

export default RetentionSecurityAuditing;
