import { i18n } from 'i18n';
import sortBy from 'lodash/sortBy';
import React, { useState } from 'react';
import featureFlags from 'src/config/feature-flags';
import styled from 'styled-components';
import FooterButtons from '../../../components/footer-buttons';
import SelectDropdown from '../../../components/select-dropdown';
import useModal from '../../../hooks/use-modal';
import { useModalState } from '../../../hooks/use-modal-state';
import teamChannelIcon from '../assets/team-and-channels-icons.svg';
import { useMSTeamsTeams, useSendMSTeamsChannels } from '../hooks';
import ChannelSelect from '../ms-teams-details/ms-teams-panel/channel-select/channel-select';
import ChannelTypeaheadSelect from '../ms-teams-details/ms-teams-panel/channel-select/channel-typeahead-select';

const StyledSlideHeader = styled.h1`
  text-align: center;
  margin-bottom: 12px;
`;

const Container = styled.div`
  display: grid;
  grid-template-rows: 1fr auto;
  height: 100%;
`;

const StyledDropdownGroup = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 12px;
  width: 360px;
  margin-top: 24px;
`;

const StyledContent = styled.div`
  display: grid;
  align-items: center;
  justify-items: center;
  max-width: 640px;
  margin: auto;
`;

const TeamChannels = () => {
  const [selectedTeam, setSelectedTeam] = useState({});
  const [selectedChannels, setSelectedChannels] = useState([]);

  // Turn on/of typeahead select for ms channels
  const channelsTypeaheadFeatureFlag = featureFlags.getFlag(
    'splint-ms-channels-typeahead',
  );

  const { nextModal, closeModal } = useModal();
  const { state: modalState, setModalState } = useModalState();
  const onClose = () => closeModal();

  const notificationId = modalState?.msTeamsNotificationId;

  const channelMutation = useSendMSTeamsChannels();
  const { integrationId } = modalState;

  const handleNext = () => {
    setModalState({
      selectedTeam,
      selectedChannels,
      integrationId,
    });

    const selectedChannelIds = selectedChannels.map(({ id }) => id);

    channelMutation
      .mutateAsync({ notificationId, channels: selectedChannelIds })
      .then(() => nextModal());
  };

  const handleSelectedTeamValue = (selectedValue) => {
    setSelectedChannels([]);
    setSelectedTeam(selectedValue);
  };

  const handleSelectedChannelValue = (selectedChannel) => {
    setSelectedChannels(selectedChannel);
  };

  const { data: msTeamsTeamsData } = useMSTeamsTeams(integrationId);
  const teamsData = msTeamsTeamsData?.data?.teams;
  const teamsSorted = sortBy(teamsData, ['name']);

  return (
    <Container>
      <StyledContent>
        <img
          src={teamChannelIcon}
          className="b-mb2"
          alt={i18n.t('header icons')}
        />
        <StyledSlideHeader>{i18n.t('Team & channels')}</StyledSlideHeader>
        <p className="b-txt-light">
          {i18n.t(
            'Specify the Team and channels this event notification should be assigned to.',
          )}
        </p>

        <StyledDropdownGroup>
          <label htmlFor="team-select" className="b-txt">
            {i18n.t('Select Team')}{' '}
          </label>

          <SelectDropdown
            inputId="team-select"
            options={teamsSorted}
            onChange={handleSelectedTeamValue}
            placeholder={i18n.t('Search or select from list')}
            aria-label="select team"
          />
        </StyledDropdownGroup>

        <StyledDropdownGroup>
          {channelsTypeaheadFeatureFlag ? (
            <ChannelTypeaheadSelect
              integrationId={integrationId}
              selectedTeamId={selectedTeam.id}
              onChange={handleSelectedChannelValue}
              compact
            />
          ) : (
            <ChannelSelect
              value={selectedChannels}
              integrationId={integrationId}
              selectedTeamId={selectedTeam.id}
              onChange={handleSelectedChannelValue}
            />
          )}
        </StyledDropdownGroup>
      </StyledContent>

      <FooterButtons
        onClose={onClose}
        showStepper
        stepperIdx={0}
        stepsAmount={4}
        handleNext={handleNext}
        nextButtonDisabled={!selectedTeam?.id || selectedChannels.length === 0}
        isWorking={channelMutation.isLoading}
      />
    </Container>
  );
};

export default TeamChannels;
