import React, { useContext } from 'react';
import BlueprintContext from '../blueprint.context';
import BlueprintMenu from './blueprint-menu';
import NavList from './nav-list';
import './sidebar.css';

const Sidebar = (props) => {
  const { options, onBlueprintChange } = props;
  const { blueprint, blueprints } = useContext(BlueprintContext);

  return (
    <div className="bl-blueprint-sidebar">
      <div className="bl-blueprint-sidebar__top-nav">
        <BlueprintMenu
          onChange={onBlueprintChange}
          blueprint={blueprint}
          options={blueprints}
        />
      </div>
      <div className="bl-blueprint-sidebar__list">
        <NavList options={options} />
      </div>
    </div>
  );
};

export default Sidebar;
