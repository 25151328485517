/* istanbul ignore file - legacy code, moved */
import React, { useState } from 'react';
import SCEPProfilePageInner from './SCEPProfilePageInner';
import { SCEPSettingsContext } from './SCEPSettingsContext';

export const SCEPProfilePage = (props) => {
  const [isEditingItem, setIsEditingItem] = useState(false);
  const [isPendingValidation, setIsPendingValidation] = useState(false);

  const settingsContext = {
    isEditingItem,
    setIsEditingItem,
    isPendingValidation,
    setIsPendingValidation,
  };

  return (
    <SCEPSettingsContext.Provider value={settingsContext}>
      <SCEPProfilePageInner {...props} />
    </SCEPSettingsContext.Provider>
  );
};
