/* istanbul ignore file - legacy code, moved */
import isEmpty from 'lodash/isEmpty';
import React from 'react';
import { useSelector } from 'react-redux';

import {
  Badge,
  Box,
  Chip,
  Flex,
  Heading,
  Icon,
  Text,
} from '@kandji-inc/nectar-ui';

import { blueprintTypes } from 'app/common/constants';

import {
  BlueprintInputTitle,
  Grid,
  Header,
  InputGrid,
  Label,
  Tag,
  Tags,
  Wrapper,
} from './SCEPAssignment.styles';

export const SCEPAssignmentDisplay = ({ selectedBlueprints }) => {
  const allBlueprints = useSelector((state) => state.data.blueprints);

  const selectedBlueprintData = allBlueprints.filter(({ id }) =>
    selectedBlueprints.includes(id),
  );
  const selectedClassicBlueprints = selectedBlueprintData.filter(
    ({ type }) => type === blueprintTypes.form,
  );
  const assignmentMaps = selectedBlueprintData.filter(
    ({ type }) => type === blueprintTypes.flow,
  );

  return (
    <Wrapper>
      <Header>Assignment</Header>
      <Flex flow="column" gap="lg" css={{ marginBottom: '$2' }}>
        <Flex flow="row" alignItems="center">
          <Icon name="sitemap" size="sm" />
          <Heading
            size="5"
            css={{
              marginLeft: '$1',
              marginRight: '$3',
              fontWeight: '$medium',
            }}
          >
            Assignment Maps
          </Heading>

          <Badge color="blue" icon="sparkles">
            New
          </Badge>
        </Flex>

        {!assignmentMaps.length && (
          <Flex flow="row" alignItems="end" gap="sm">
            <Text css={{ color: '$neutral70' }}>
              Not used in any Assignment Maps.
            </Text>

            <a
              href="https://support.kandji.io/support/solutions/articles/72000627625"
              target="_blank"
              rel="noreferrer noopener"
            >
              <Text
                variant="primary"
                size="1"
                css={{
                  fontWeight: '$medium',
                  textDecoration: 'none',
                  '&:hover': {
                    textDecoration: 'underline',
                  },
                }}
              >
                Learn more
              </Text>
            </a>
          </Flex>
        )}

        {assignmentMaps.length > 0 && (
          <Grid>
            <Label>Blueprints</Label>
            {assignmentMaps.map(({ name }) => (
              <Chip
                label={name}
                key={name}
                css={{
                  minWidth: 0,
                }}
              />
            ))}
          </Grid>
        )}

        <Box wFull css={{ backgroundColor: '$neutral30', height: '1px' }} />

        <Flex flow="row" alignItems="center">
          <Icon name="memo-pad" size="sm" />
          <Heading
            size="5"
            css={{
              marginLeft: '$1',
              marginRight: '$3',
              fontWeight: '$medium',
            }}
          >
            Classic Blueprints
          </Heading>
        </Flex>
      </Flex>

      {selectedBlueprints && (
        <Grid>
          <Label>Blueprints</Label>
          <InputGrid>
            <Tags>
              {isEmpty(selectedBlueprints) && (
                <Tag theme="secondary">Not Assigned</Tag>
              )}
              {selectedClassicBlueprints.map(({ name }) => (
                <Tag theme="basic">
                  <BlueprintInputTitle>{name}</BlueprintInputTitle>
                </Tag>
              ))}
            </Tags>
          </InputGrid>
        </Grid>
      )}
    </Wrapper>
  );
};
