/* istanbul ignore file */
import React, { useEffect } from 'react';

const script =
  'https://embed.launchnotes.io/latest/dist/esm/launchnotes-embed.js';

const appendScript = (src, isModule) => {
  const script = document.createElement('script');
  script.src = src;
  script.async = true;
  if (isModule) {
    script.type = 'module';
  } else {
    script.noModule = true;
  }
  document.head.appendChild(script);
  return script;
};

const LaunchNotesEmbed = () => {
  useEffect(() => {
    const module = appendScript(script, true);
    const noModule = appendScript(script, false);
    return () => {
      document.head.removeChild(noModule);
      document.head.removeChild(module);
    };
  }, []);
  return null;
};

export default LaunchNotesEmbed;
