import type { DeviceType } from '../../../types';
/**
 *
 *
 * @description This is used to determine what values to send to "delete" device platform. To signal a delete, we are just passing empty strings.
 * @param {DeviceType} deviceType
 * @return {*}
 */
export function getDevicePlatformDataToDelete(deviceType: DeviceType) {
  const deviceData = {
    iOS: {
      ios_provider_secret_key: '',
    },
    macOS: {
      mac_scep_url: '',
      mac_scep_challenge_url: '',
      mac_scep_challenge_username: '',
    },
  };

  return deviceData[deviceType];
}
