import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  loadUser as callLoadUser,
  updateUser as callUpdateUser,
} from '../../_actions/users';
import { LineLoader } from '../interface/LineLoader';
import UserForm from './UserForm';

class UserPage extends React.Component {
  constructor(props) {
    super(props);
    this.userId = props.match.params.user_id;
    this.handleSubmit = this.handleSubmit.bind(this);
    this.deleteUser = this.deleteUser.bind(this);
    this.state = {
      isLoading: true,
    };
  }

  deleteUser(params) {
    const { history, location, deleteUser } = this.props;
    const pathname = location.pathname.split('/users')[0];
    deleteUser(params.id);
    history.push(pathname);
  }

  componentDidMount() {
    const { loadUser } = this.props;

    loadUser(this.userId).finally(() => {
      this.setState({ isLoading: false });
    });
  }

  handleSubmit(params) {
    const { updateUser } = this.props;
    const payload = {
      first_name: params.first_name,
      last_name: params.last_name,
      email: params.email,
    };
    if (params.id) {
      payload.id = params.id;
    }
    return updateUser(payload);
  }

  render() {
    const { isLoading } = this.state;
    if (isLoading) {
      return <LineLoader />;
    }

    return (
      <UserForm onSubmit={this.handleSubmit} deleteUser={this.deleteUser} />
    );
  }
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      updateUser: callUpdateUser,
      loadUser: callLoadUser,
    },
    dispatch,
  );

export default connect(null, mapDispatchToProps)(UserPage);
