import React from 'react';
import './footer-buttons.styles.scss';

import { Flex } from '@kandji-inc/bumblebee';
import type { ButtonProps } from '@kandji-inc/nectar-ui';
import Stepper from '../../../util/components/stepper';
import FooterButtonsLeft from './footer-buttons-left';
import FooterButtonsRight from './footer-buttons-right';

type Props = Readonly<{
  showDownloadError?: boolean;
  downloadProgress?: number;
  stepperIdx?: number;
  stepsAmount?: number;
  nextButtonDisabled?: boolean;
  handleNext: () => void;
  onClose?: () => void;
  integrationDownloadSuccessText?: string;
  nextButtonText?: string;
  cancelButtonText?: string;
  showStepper?: boolean;
  showBackButton?: boolean;
  backOnClick?: () => void;
  isWorking?: boolean;
  showRedownloadButton?: boolean;
  progressText?: string;
  redownloadButtonText?: string;
  hasCancelButton?: boolean;
  onRedownloadClick?: () => void;
  theme?: ButtonProps['variant'];
  backButtonText?: string;
  customComponent?: JSX.Element;
  textIcon?: string;
  classWrapper?: boolean;
}>;

const FooterButtons = (props: Props) => {
  const {
    showDownloadError,
    downloadProgress,
    stepperIdx = 0,
    stepsAmount = 0,
    nextButtonDisabled = false,
    handleNext,
    onClose,
    integrationDownloadSuccessText,
    nextButtonText,
    cancelButtonText,
    showStepper,
    showBackButton,
    backOnClick,
    isWorking,
    showRedownloadButton,
    progressText,
    redownloadButtonText,
    hasCancelButton,
    onRedownloadClick,
    backButtonText,
    theme,
    customComponent,
    textIcon,
    classWrapper = true,
  } = props;

  return (
    <Flex
      className={classWrapper ? 'welcome-modal_footer__wrapper' : undefined}
      align="center"
      justify="space-between"
    >
      <FooterButtonsLeft
        showDownloadError={showDownloadError}
        downloadProgress={downloadProgress}
        downloadSuccessText={integrationDownloadSuccessText}
        showBackButton={showBackButton}
        backOnClick={backOnClick}
        showRedownloadButton={showRedownloadButton}
        progressText={progressText}
        redownloadButtonText={redownloadButtonText}
        onRedownloadClick={onRedownloadClick}
        backButtonText={backButtonText}
        customComponent={customComponent}
      />

      {showStepper && (
        <Stepper currentStepIdx={stepperIdx} stepsAmount={stepsAmount} />
      )}

      <FooterButtonsRight
        onClose={onClose}
        downloadProgress={downloadProgress}
        nextButtonDisabled={nextButtonDisabled}
        handleNext={handleNext}
        nextButtonText={nextButtonText}
        cancelButtonText={cancelButtonText}
        isWorking={isWorking}
        hasCancelButton={hasCancelButton}
        theme={theme}
        textIcon={textIcon}
      />
    </Flex>
  );
};

export default FooterButtons;
