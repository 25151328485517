import { Paragraph } from '@kandji-inc/nectar-ui';
import { i18n } from 'i18n';
import { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useSidebarToast } from 'src/hooks/useSidebarToast';
import ActionDialog from '../../../../components/action-dialog';
import useAddDevicePlatform from '../../hooks/use-add-device-platform';
import useGetUserGroupListOptions from '../../hooks/use-get-user-group-list-options';
import { getPlatformLabel } from '../details-integration/details-integration';
import SelectUserGroup from '../select-user-group';

import type { MsDeviceComplianceInputs } from '../../../integrator/types/ms-device-compliance.types';
import type {
  GroupOptionList,
  IntegrationDetails,
  PlatformStatus,
} from '../../types';

type Props = {
  isDialogOpen: boolean;
  platform: PlatformStatus;
  integrationId: string;
  integrationDetails: IntegrationDetails;
  handleToggle: (args: any) => void;
};

const AddEditDevicePlatform = (props: Props) => {
  const {
    integrationDetails,
    platform,
    isDialogOpen,
    integrationId,
    handleToggle,
  } = props;

  if (!platform) return null;

  const [groupOptions, setGroupOptions] = useState<GroupOptionList>([]);
  const platformLabel = getPlatformLabel(platform);

  /* istanbul ignore next */
  const groupIds = integrationDetails[`${platform}_entra_group_ids`] || [];
  const { data: groupsOptions } =
    groupIds.length > 0
      ? useGetUserGroupListOptions({ setGroupOptions, groupIds })
      : { data: [] };

  /* format: id|external_id */
  const сonfiguredGroupsOptions =
    groupsOptions?.map((v) => `${v.id}|${v.external_id}`) || [];
  const isAddPlatform = сonfiguredGroupsOptions.length === 0;
  const title = isAddPlatform
    ? i18n.t('Add {platformLabel} platform', { platformLabel })
    : i18n.t('Edit user group');
  const fieldName = `${platform}Devices`; // macosDevices or iosDevices

  /* Set default/selected values for form */
  const methods = useForm({
    mode: 'onChange',
    values: {
      [`${fieldName}`]: true,
      [`${fieldName}_groups`]: сonfiguredGroupsOptions,
    },
  });

  const handleNextClick = () => {
    const formInputs = methods.getValues();
    methods.trigger().then((isValid) => {
      if (isValid) {
        handleAddPlatform({ formInputs });
      }
    });
  };

  const { displayToast } = useSidebarToast();

  const addPlatformMutation = useAddDevicePlatform(integrationId);
  const isAdding = addPlatformMutation.isPending;

  const handleAddPlatform = ({
    formInputs,
  }: { formInputs: MsDeviceComplianceInputs }) => {
    /* istanbul ignore next */
    addPlatformMutation.mutate(
      { ...formInputs },
      {
        onSuccess: () => {
          const title = isAddPlatform
            ? i18n.t('{platformLabel} device platform successfully added', {
                platformLabel,
              })
            : i18n.t('{platformLabel} device platform successfully updated', {
                platformLabel,
              });
          displayToast({
            title,
            variant: 'success',
          });
        },
        onError: () => {
          displayToast({
            title: i18n.common.error(),
            content: i18n.t('Please try again.'),
            variant: 'error',
          });
        },
        onSettled: () => {
          handleToggle(null);
        },
      },
    );
  };

  return (
    <FormProvider {...methods}>
      <ActionDialog
        variant="primary"
        size="sm"
        title={title}
        content={
          <DialogContent
            field={fieldName}
            options={groupOptions}
            platformLabel={platformLabel}
          />
        }
        isOpen={isDialogOpen}
        nextText={isAddPlatform ? i18n.t('Add') : i18n.t('Save')}
        nextClick={handleNextClick}
        backText={i18n.t('Cancel')}
        backClick={() => handleToggle(null)}
        isProcessing={isAdding}
      />
    </FormProvider>
  );
};

const DialogContent = ({
  field,
  options,
  platformLabel,
}: {
  field: 'macosDevices' | 'iosDevices';
  options: GroupOptionList;
  defaultOptions: GroupOptionList;
  platformLabel: string;
}) => (
  <>
    <Paragraph>
      {i18n.t(
        'Select the group that was configured in your Intune portal when configuring partner compliance for {platformLabel}.',
        { platformLabel },
      )}
    </Paragraph>

    <SelectUserGroup
      field={field}
      defaultOptions={options}
      size="full"
      css={{ paddingLeft: 0 }}
    />
  </>
);

export default AddEditDevicePlatform;
