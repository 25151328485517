import { useCallback, useEffect } from 'react';

function useEscapeKey(handleClose) {
  const handleEscKey = useCallback(
    (event) => {
      if (event.key === 'Escape') {
        handleClose();
        event.stopImmediatePropagation();
      }
    },
    [handleClose],
  );

  useEffect(() => {
    window.addEventListener('keydown', handleEscKey, true);

    return () => {
      window.removeEventListener('keydown', handleEscKey, true);
    };
  }, [handleClose, handleEscKey]);
}

export default useEscapeKey;
