// Note: This file is used to generate query keys for react-query. It generates keys for the new integrationsBackend service and the existing integrations service. The keys are used in the data fetching hooks that use react-query.

// Currently is used for servicenow integration only
export const integrationsBackendKeys = {
  all: ['integrations-backend'] as const,
};

export const integrationsKeys = {
  all: ['integrations'] as const,
};

export const libraryItemsKeys = {
  all: ['library_items'] as const,
  list: (filters?: string) => [...libraryItemsKeys.all, { filters }] as const,
  details: (id?: string) => [...libraryItemsKeys.all, id] as const,
};
