import type { IntegrationsBackendResponse } from '../data-service/types/integrations-backend.types';

/**
 * Retrieves an integration from the given list of integrations based on its type.
 * @param type - The type of the integration to retrieve.
 * @param integrations - The list of integrations to search in.
 * @returns The integration object if found, otherwise undefined.
 */

export const getIntegrationByType = (
  type: string,
  integrations: IntegrationsBackendResponse,
) => integrations?.items?.find((integration) => integration.type === type);
