import { Banner } from '@kandji-inc/bumblebee';
import { string } from 'prop-types';
import React from 'react';

const SuccessBanner = ({ integrationDownloadSuccessText = '' }) => (
  <Banner theme="success" icon="circle-check" kind="normal">
    <p>
      <strong>{integrationDownloadSuccessText}</strong> was successfully
      downloaded.
    </p>
  </Banner>
);

SuccessBanner.propTypes = {
  integrationDownloadSuccessText: string.isRequired,
};

export default SuccessBanner;
