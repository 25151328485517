import {
  Button,
  Checkbox,
  Icon,
  NoContent,
  Table,
  TextInput,
  setClass,
  useAfterMount,
  useInputsValidators,
  useRemoveValidationOnUnmount,
} from '@kandji-inc/bumblebee';
import Tippy from '@tippyjs/react';
import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import { i18n } from 'src/i18n';
import './managed-domains-table.css';
import {
  i18nOsRequirements,
  osRequirements,
} from 'features/library-items/library/common';
import Menu from 'features/self-service-new/menu';
import uuid from 'uuid';
// import 'tippy.js/dist/tippy.css';

const MDFContext = createContext({});

const updateDomain = ({
  update,
  item,
  key,
  value,
  isInvalid,
  isCheckboxInvalid,
}) => {
  update('domains', (p) => {
    const newDomains = [...p];
    const index = newDomains.findIndex((f) => f.id === item.id);
    newDomains[index] = {
      ...newDomains[index],
      [key]: value,
      ...(isInvalid === undefined ? {} : { isInvalid }),
      ...(isCheckboxInvalid === undefined ? {} : { isCheckboxInvalid }),
    };
    return newDomains;
  });
};

const HeaderWithInfo = ({ header, osReqs }) => (
  <div className="b-flex-vc">
    {header}
    <Tippy
      content={
        <div>
          {osReqs.map((r) => (
            <p key={r} className="b-txt">
              {r}
            </p>
          ))}
        </div>
      }
      theme="basic-white"
      interactive
    >
      <Icon
        name="circle-info"
        className="b-ml-tiny"
        style={{ color: 'var(--color-neutral-50)' }}
      />
    </Tippy>
  </div>
);

const CheckboxField = ({ item, update, itemKey, showError }) => {
  const { isDisabled } = useContext(MDFContext);
  const [err, setErr] = useState(false);
  const afterMount = useAfterMount();
  const { validationDep } = useContext(MDFContext);
  const { isEmailDomain, isManagedDomain, isSafariPassword } = item;
  const areAllCheckboxesFalse = isAllFalse(
    isEmailDomain,
    isManagedDomain,
    isSafariPassword,
  );
  const areRestFalse = ['isEmailDomain', 'isManagedDomain', 'isSafariPassword']
    .filter((k) => k !== itemKey)
    .every((k) => !item[k]);

  useEffect(() => {
    if (afterMount) {
      setErr(areAllCheckboxesFalse);
    }
  }, [validationDep]);

  useEffect(() => {
    if (err && !areAllCheckboxesFalse) {
      setErr(false);
    }
  }, [item]);

  return (
    <div>
      <div className="b-mdf-checkbox">
        <Checkbox
          isDisabled={isDisabled}
          className={setClass(err && 'b-mdf-checkbox__error')}
          checked={item[itemKey]}
          onChange={() => {
            const next = !item[itemKey];
            updateDomain({
              update,
              item,
              key: itemKey,
              value: next,
              isCheckboxInvalid: !next && areRestFalse,
            });
          }}
        />
      </div>
      {err && (
        <p className="b-txt b-flex-vc b-mdf-checkbox__error-text">
          {showError && (
            <>
              <i className="bi bi-exclamation-circle b-mr-tiny" />{' '}
              {i18n.t('At least 1 must be selected')}
            </>
          )}
        </p>
      )}
    </div>
  );
};

const isAllFalse = (...args) => args.every((d) => !d);

const getColumns = ({ update, onDeleteRow }) => [
  {
    header: i18n.t('Domain name'),
    fieldName: 'name',
    ratio: 2,
    Cell: ({ item, index }) => {
      const { isDisabled, isSaving, validationDep, setting, refs } =
        useContext(MDFContext);

      var re_weburl = new RegExp(
        '^' +
          // protocol identifier (optional)
          // short syntax // still required
          '(?:(?:(?:https?|ftp):)?\\/\\/)?' +
          // user:pass BasicAuth (optional)
          '(?:\\S+(?::\\S*)?@)?' +
          '(?:' +
          // IP address exclusion
          // private & local networks
          '(?!(?:10|127)(?:\\.\\d{1,3}){3})' +
          '(?!(?:169\\.254|192\\.168)(?:\\.\\d{1,3}){2})' +
          '(?!172\\.(?:1[6-9]|2\\d|3[0-1])(?:\\.\\d{1,3}){2})' +
          // IP address dotted notation octets
          // excludes loopback network 0.0.0.0
          // excludes reserved space >= 224.0.0.0
          // excludes network & broadcast addresses
          // (first & last IP address of each class)
          '(?:[1-9]\\d?|1\\d\\d|2[01]\\d|22[0-3])' +
          '(?:\\.(?:1?\\d{1,2}|2[0-4]\\d|25[0-5])){2}' +
          '(?:\\.(?:[1-9]\\d?|1\\d\\d|2[0-4]\\d|25[0-4]))' +
          '|' +
          // host & domain names, may end with dot
          // can be replaced by a shortest alternative
          // (?![-_])(?:[-\\w\\u00a1-\\uffff]{0,63}[^-_]\\.)+
          '(?:' +
          '(?:' +
          '[a-z0-9\\u00a1-\\uffff]' +
          '[a-z0-9\\u00a1-\\uffff_-]{0,62}' +
          ')?' +
          '[a-z0-9\\u00a1-\\uffff]\\.' +
          ')+' +
          // TLD identifier name, may end with dot
          '(?:[a-z\\u00a1-\\uffff]{2,}\\.?)' +
          ')' +
          // port number (optional)
          '(?::\\d{2,5})?' +
          // resource path (optional)
          '(?:[/?#]\\S*)?' +
          '$',
        'i',
      );

      const r = new RegExp(re_weburl);
      if (!isDisabled || isSaving) {
        return (
          <div
            ref={refs ? refs[index] : null}
            className={setClass('b-flex1', 'b-mdf-text-input-cell')}
          >
            <TextInput
              disabled={isDisabled}
              value={item.name}
              placeholder={i18n.t('Enter the domain name')}
              onChange={(e) =>
                updateDomain({
                  update,
                  item,
                  key: 'name',
                  value: e.target.value,
                })
              }
              validator={(v) => [
                {
                  message: i18n.t('Required.'),
                  invalid: () => !v.length,
                  trigger: ['onBlur', validationDep],
                },
                {
                  message: i18n.t(
                    'Duplicate found. Domain names must be unique.',
                  ),
                  invalid: () =>
                    setting?.find((r) => r.id !== item.id && v === r.name),
                  trigger: ['onBlur', validationDep],
                },
                {
                  message: i18n.t('Invalid domain name.'),
                  invalid: () => {
                    return !r.test(v);
                  },
                  trigger: ['onBlur', validationDep],
                },
              ]}
              onInvalidate={(isInvalid) =>
                updateDomain({
                  update,
                  item,
                  key: 'name',
                  value: item.name,
                  isInvalid,
                })
              }
            />
          </div>
        );
      }

      return (
        <p ref={refs[index + 1]} className="b-txt-bold b-flex-vc">
          {item.name}
        </p>
      );
    },
  },
  {
    header: i18n.t('Email domain'),
    fieldName: 'isEmailDomain',
    HeaderCell: () => (
      <HeaderWithInfo
        header={i18n.t('Email domain')}
        osReqs={[
          i18nOsRequirements(osRequirements.IOS_8_0),
          i18nOsRequirements(osRequirements.IPAD_13_0),
          i18nOsRequirements(osRequirements.MAC_10_10),
        ]}
      />
    ),
    Cell: ({ item }) => (
      <CheckboxField
        item={item}
        update={update}
        itemKey="isEmailDomain"
        showError
      />
    ),
  },
  {
    header: i18n.t('Safari password'),
    fieldName: 'isSafariPassword',
    HeaderCell: () => (
      <HeaderWithInfo
        header={i18n.t('Safari password')}
        osReqs={[
          i18nOsRequirements(osRequirements.IOS_9_3),
          i18nOsRequirements(osRequirements.IPAD_13_0),
        ]}
      />
    ),
    Cell: ({ item }) => (
      <CheckboxField item={item} update={update} itemKey="isSafariPassword" />
    ),
  },
  {
    header: i18n.t('Managed domain'),
    fieldName: 'isManagedDomain',
    HeaderCell: () => (
      <HeaderWithInfo
        header={i18n.t('Managed domain')}
        osReqs={[
          i18nOsRequirements(osRequirements.IOS_8_0),
          i18nOsRequirements(osRequirements.IPAD_13_0),
        ]}
      />
    ),
    Cell: ({ item }) => (
      <CheckboxField item={item} update={update} itemKey="isManagedDomain" />
    ),
  },
  {
    header: '',
    fieldName: 'ellipsis',
    ratio: 0.1,
    Cell: ({ item }) => {
      const { isDisabled, isSaving } = useContext(MDFContext);
      return (
        <Menu
          options={[
            {
              name: i18n.t('Delete'),
              icon: 'trash-can',
              theme: 'error',
              disabled: isDisabled || isSaving,
              onClick: () => onDeleteRow(item),
            },
          ]}
          placement="bottom"
          offset={[0, 0]}
        >
          <div className="b-mdf-action-btn">
            <Button
              isDisabled={isDisabled || isSaving}
              style={{ color: 'var(--color-neutral-50)' }}
              kind="link"
              icon="ellipsis-vertical"
            />
          </div>
        </Menu>
      );
    },
  },
];

export const defaultDomainEntry = () => ({
  id: uuid(),
  name: '',
  isEmailDomain: false,
  isManagedDomain: false,
  isSafariPassword: false,
  isInvalid: true,
  isCheckboxInvalid: true,
});

const ManagedDomainsTable = (props) => {
  const { isDisabled, isSaving, setting, update, validationDep } = props;
  const MAX_DOMAINS = 20;
  const ref = useRef();
  const domainsIdentifiers = new Array(MAX_DOMAINS)
    .fill(0)
    .map((_, idx) => idx);
  const { refs, onInvalidate } = useInputsValidators(
    domainsIdentifiers,
    update,
  );
  useRemoveValidationOnUnmount(domainsIdentifiers, update);

  const onDeleteRow = ({ id }) => {
    update('domains', (p) => [...p.filter((domain) => domain.id !== id)]);
  };

  const onAddRow = () => {
    const newDomainEntry = defaultDomainEntry();
    update('domains', (p) => [...p, newDomainEntry]);
  };

  useEffect(() => {
    if (setting && !setting.length) {
      onAddRow();
    }
  }, []);

  const columns = useMemo(
    () =>
      getColumns({
        update,
        onDeleteRow,
      }),
    [],
  );

  useEffect(() => {
    if (setting) {
      domainsIdentifiers.forEach((n) => {
        const curr = setting[n];
        onInvalidate(n)(curr?.isInvalid || curr?.isCheckboxInvalid);
      });
    }
  }, [setting]);

  return (
    <MDFContext.Provider
      value={{ isDisabled, isSaving, validationDep, setting, refs }}
    >
      <div ref={ref} className="b-mdf-table">
        {setting?.length ? (
          <Table isLoading={false} columns={columns} data={setting} />
        ) : (
          <NoContent
            className="b-pad2"
            header={i18n.t('Managed domains have not been configured')}
            text={i18n.t(
              'Add a domain and specify the reach domain permissions.',
            )}
          />
        )}
        <div className="b-mdf-table__footer">
          <Button
            isDisabled={isDisabled || setting?.length >= MAX_DOMAINS}
            kind="link"
            icon="circle-plus"
            onClick={onAddRow}
          >
            {i18n.t('Add domain')}
          </Button>
        </div>
      </div>
    </MDFContext.Provider>
  );
};

export default ManagedDomainsTable;
