import type { CSSProperties } from 'react';
import React from 'react';

import type { CSS, IconNames } from '@kandji-inc/nectar-ui';
import { Flex, Icon, Text, styled } from '@kandji-inc/nectar-ui';
import { Link } from 'react-router-dom';
import { links } from 'src/app/common/constants';

import Overlay from '../components/Overlay';
import useBlueprintFlow from '../store';

const TopIcon = styled(Flex, {
  position: 'absolute',
  top: '-24px',
  padding: '16px',
  alignItems: 'flex-start',
  borderRadius: '60px',
  border: '1px solid $neutral20',
  background: '$neutral5',

  '& svg': {
    height: '20px',
    width: '20px',
  },
});

const InfoBox = styled(Flex, {
  width: '171px',
  minHeight: '92px',
  flexDirection: 'column',
  justifyContent: 'start',
  alignItems: 'center',
  borderRadius: '8px',
  border: '1px solid $neutral20',
  background: '$neutral0',
});

const TextContainer = styled(Flex, {
  justifyContent: 'center',
  alignItems: 'center',
  padding: '34px 10px 10px',
  gap: '10px',
  alignSelf: 'stretch',
  textAlign: 'center',
});

const DeviceLink = styled(Link, {
  display: 'flex',
  gap: '2px',
  alignItems: 'center',
  justifyContent: 'center',
  color: '$blue50',
  textDecoration: 'none',
  marginTop: '3px',

  '&:hover': { textDecoration: 'none', color: '$blue50' },
});

type EventKind = 'start' | 'end' | 'endWithDevice';

const copy: Record<
  EventKind,
  {
    icon: IconNames;
    text: (args: any) => string | JSX.Element;
    iconCss?: CSSProperties;
    topIconCss?: CSS;
  }
> = {
  start: {
    icon: 'arrow-right',
    text: () => 'A device is added to this Blueprint or changes attributes',
  },
  end: {
    icon: 'flag',
    text: () => 'Library Items will be installed',
  },
  endWithDevice: {
    icon: 'sf-iphone',
    text: (args: { deviceSerial: string; deviceId: string }) => (
      <>
        Library Items will be installed or available in Self Service on
        <Text css={{ fontWeight: '$medium' }}>{args.deviceSerial}</Text>
        <DeviceLink
          to={`${links.devices}/${args.deviceId}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          <Text css={{ fontWeight: '$medium' }}>View device record</Text>
          <Icon size="xs" name="arrow-up-right-from-square" />
        </DeviceLink>
      </>
    ),
    topIconCss: { backgroundColor: '$blue60' },
    iconCss: { color: '#fff' },
  },
};

type EventProps = {
  kind: EventKind;
  args?: any;
};

const Event = (props: EventProps) => {
  const { kind, args } = props;
  const event = copy[kind];

  const isDeletingNode = useBlueprintFlow((state) => state.isDeletingNode);

  return (
    <Flex
      flow="column"
      alignItems="center"
      css={{
        position: 'relative',
      }}
    >
      <TopIcon
        css={{
          ...event.topIconCss,
          .../* istanbul ignore next */ (isDeletingNode
            ? { border: '1px solid $neutral70' }
            : {}),
        }}
      >
        <Icon name={event.icon} style={event.iconCss} />
        <Overlay isHidden={!isDeletingNode} style={{ borderRadius: '50%' }} />
      </TopIcon>
      <InfoBox
        css={{
          .../* istanbul ignore next */ (isDeletingNode
            ? {
                backgroundColor: '#969696',
                borderColor: '$neutral70',
              }
            : {}),
        }}
      >
        <TextContainer>
          <Text size="1">{event.text(args)}</Text>
        </TextContainer>
      </InfoBox>
    </Flex>
  );
};

export default Event;
