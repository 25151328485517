import { Breadcrumb, Tabs } from '@kandji-inc/bumblebee';
import { LineLoader } from 'app/components/interface/LineLoader';
import { omit } from 'lodash';
import { Whoops404 } from 'pages/404';
import React, { useState } from 'react';
import { Redirect, useHistory } from 'react-router-dom';

import { links } from 'app/common/constants';
import WrappedPage from '../building-blocks/page-wrapper';
import { CONFIG_SHAPE, TYPE_PROP_TYPE } from './util';

import EventNotificationsTab from './event-notifications/event-notifications-tab';
import SummaryCard from './summary-card';

import { i18n } from 'i18n';
import useListIntegrations from '../../hooks/use-list-integrations';
import useListNotifications from '../../hooks/use-list-notifications';

function IntegrationPage({ config, type }) {
  const {
    location: { state: newIntegrationData },
  } = useHistory();

  const [isAdding, setIsAdding] = useState(false);
  const [notificationData, setNotificationData] = useState({
    ...config.initialNotificationData,
    integrationId: newIntegrationData?.id ? +newIntegrationData.id : null,
  });
  const [isSaving, setIsSaving] = useState(false);

  const {
    data: integrationsData,
    isLoading: isIntegrationDataLoading,
    isError: hasIntegrationsError,
  } = useListIntegrations({
    integrationType: type,
  });
  const {
    data: notificationsData,
    isLoading: isNotificationDataLoading,
    isError: hasNotificationsError,
  } = useListNotifications({
    integrationType: type,
  });

  const integrations = integrationsData?.data?.results;
  const allNotifications = notificationsData?.data?.results;

  const isLoading = isIntegrationDataLoading || isNotificationDataLoading;
  const error = hasIntegrationsError || hasNotificationsError;

  if (error) {
    return <Whoops404 />;
  }

  if (!isLoading && !integrations.length) {
    return <Redirect to={links.integrations.root} />;
  }

  const groupedByIntegrations = integrations?.map((integration) => ({
    integration,
    notificationsForIntegration: allNotifications
      ?.filter((notification) => notification.integration.id === integration.id)
      .map((ntf) => omit(ntf, 'integration')),
  }));

  return (
    <>
      {isLoading ? (
        <LineLoader isDelayed />
      ) : (
        <>
          <Breadcrumb
            crumbs={[
              { url: links.integrations.root, title: 'Integrations' },
              { title: config.title },
            ]}
            classes={{ wrapper: 'b-mt3 b-mb2' }}
          />

          <SummaryCard type={type} config={config} />

          <Tabs
            style={{ marginTop: 17, marginBottom: 'var(--b-gap3)' }}
            tabs={[{ label: i18n.t('Event notifications') }]}
          >
            <EventNotificationsTab
              groupedByIntegrations={groupedByIntegrations}
              tabid="Event notifications"
              setNotificationData={setNotificationData}
              notificationData={notificationData}
              isAdding={isAdding}
              isSaving={isSaving}
              setIsAdding={setIsAdding}
              config={config}
              integrationData={integrations}
            />
          </Tabs>
        </>
      )}
    </>
  );
}

IntegrationPage.propTypes = {
  config: CONFIG_SHAPE.isRequired,
  type: TYPE_PROP_TYPE.isRequired,
};

export default WrappedPage(IntegrationPage);
