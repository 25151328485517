import { Chip } from '@kandji-inc/bumblebee';
import { Icon, Chip as NectarChip, styled } from '@kandji-inc/nectar-ui';
import React from 'react';
import { Link } from 'react-router-dom';
import {
  CardContainer,
  CardSection,
  CardTitle,
} from 'src/features/integrations/components/styled-components';

import { i18n } from 'i18n';
import type { LibraryItem } from 'src/features/integrations/data-service/types/library-item-service.types';
import oktaVerifyOutline from '../../../assets/okta-verify-outline.svg';
import type { OktaLibraryItem } from '../../../types';
import { CardSubheading, DataContainer } from '../../styled-components';
import { buildDeviceFamilyList } from '../utils/build-device-family-list';
import BlueprintsSection from './blueprints-section';
import NoContent from './no-content';
import {
  ChipContainer,
  Container,
  Content,
  StyledImg,
  StyledLink,
} from './styled';

type Props = {
  oktaLicenseData: LibraryItem | undefined;
  oktaLibraryItemData: OktaLibraryItem | undefined;
  loading: boolean | undefined;
};

const StyledNectarChip = styled(NectarChip, {
  marginLeft: 'auto',
});

const StyledNoContentContainer = styled(CardContainer, {
  justifyContent: 'center',
  display: 'flex',
  flexDirection: 'column',
});

const OktaLibraryItemDetails = (props: Props) => {
  const { oktaLibraryItemData, oktaLicenseData, loading } = props;

  const {
    licenses_total: licensesTotal,
    licenses_used: licensesUsed,
    blueprints,
  } = oktaLicenseData || {};

  const { device_families: deviceFamilies, data } = oktaLibraryItemData || {};

  const { okta_device_trust: oktaDeviceTrust } = data || {};

  const oktaStatusLabel = oktaDeviceTrust
    ? i18n.t('Okta Device Trust is on')
    : i18n.t('Okta Device Trust is off');

  return (
    <>
      {!loading && !licensesTotal ? (
        <StyledNoContentContainer>
          <CardTitle>
            <h4 className="b-h4 b-mr">{i18n.t('Library item details')}</h4>
          </CardTitle>
          <NoContent />
        </StyledNoContentContainer>
      ) : (
        <CardContainer>
          <CardTitle>
            <h4 className="b-h4 b-mr">{i18n.t('Library item details')}</h4>

            <StyledLink
              as={Link}
              to={`/library/app-store-apps/${
                oktaLicenseData?.id && oktaLicenseData.id
              }`}
              css={{ marginLeft: 'auto' }}
            >
              <Icon name="arrow-right" size="sm" />{' '}
              {i18n.t('Go to Library Item')}
            </StyledLink>
          </CardTitle>
          <CardSection>
            <Container>
              <StyledImg src={oktaVerifyOutline} alt="okta-verify-outline" />
              <Content>
                <CardSubheading>
                  <h4 className="b-h4 b-mr">{i18n.t('Okta Verify')}</h4>
                  <StyledNectarChip
                    data-testid="status-chip"
                    label={oktaStatusLabel}
                    css={{
                      backgroundColor: oktaDeviceTrust
                        ? '$green10'
                        : '$neutral20',
                    }}
                  />
                </CardSubheading>
                <DataContainer>
                  <div>
                    <p className="b-txt-light">{i18n.t('App licenses')}</p>
                    <p>
                      {i18n.t(
                        '{licensesUsed} of {licensesTotal} licenses used',
                        {
                          licensesUsed: licensesUsed ?? 0,
                          licensesTotal: licensesTotal ?? 0,
                        },
                      )}
                    </p>
                  </div>
                  <div>
                    <p className="b-txt-light">{i18n.t('Device families')}</p>
                    <ChipContainer>
                      {deviceFamilies &&
                        buildDeviceFamilyList(deviceFamilies).map((device) => (
                          <Chip text={device} key={device} />
                        ))}
                    </ChipContainer>
                  </div>
                </DataContainer>
              </Content>
            </Container>
          </CardSection>
          <CardSection>
            <BlueprintsSection blueprints={blueprints} />
          </CardSection>
        </CardContainer>
      )}
    </>
  );
};

export default OktaLibraryItemDetails;
