/* istanbul ignore file */
import { useEffect } from 'react';

import { useQuery } from '@tanstack/react-query';

import { ruleService } from 'src/features/rules/rule-service';
import { transformFacetDataFromApi } from 'src/features/rules/transformers';

import useBlueprintFlow from '../store';

const useGetFacets = () => {
  const setFacetMap = useBlueprintFlow((state) => state.setFacetMap);
  const { data, isLoading } = useQuery({
    queryKey: ['facets'],
    queryFn: async () => {
      try {
        const facets = await ruleService.get();
        const transformedData = transformFacetDataFromApi(
          facets.data,
          'assignment-map',
        );
        return transformedData;
      } catch (e) {
        return Promise.reject(e);
      }
    },
  });

  useEffect(() => {
    if (data) {
      setFacetMap(data);
    }
  }, [data]);

  return {
    isLoading,
  };
};

export default useGetFacets;
