/* istanbul ignore file */
import {
  Box,
  Button,
  Flex,
  type IconNames,
  Text,
  Tooltip,
} from '@kandji-inc/nectar-ui';
import { useHistory } from 'react-router';
import { links } from 'src/app/common/constants';
import { i18n } from 'src/i18n';
import { paths } from '../blueprints/common';
import AddDevicesIcon from './assets/add-devices-icon.png';
import AddDevices from './assets/add-devices.png';
import AdeIcon from './assets/ade-icon.png';
import ApnsIcon from './assets/apns-icon.png';
import AppsBooksTotal from './assets/apps-books-total.png';
import AssignmentMapsIcon from './assets/assignment-maps-icon.png';
import AssignmentMaps from './assets/assignment-maps.png';
import ConfigureAde from './assets/configure-ade.png';
import ConfigureApns from './assets/configure-apns.png';
import type { WalkthroughResult } from './useGetWalkthrough';

export const getSteps = (
  stepsCompleted: Array<string>,
  mutate: (key: string, value: boolean) => void,
  completions: WalkthroughResult,
  hasLibraryItems: boolean,
): Array<{ [k: string]: any; icon: IconNames }> => [
  {
    id: 'explore',
    title: i18n.t('Explore Library Items and Blueprints'),
    description: i18n.t(
      'Use Library Items and Blueprints to configure devices and deploy apps.',
    ),
    icon: 'kandji-blueprint',
    Image: () => {
      return (
        <Flex
          flex={1}
          css={{
            position: 'relative',
            backgroundImage: `url(${AssignmentMaps})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
          }}
        >
          <img
            src={AssignmentMapsIcon}
            alt="amicon"
            style={{
              position: 'absolute',
              bottom: '20px',
              left: '-13px',
              height: '62px',
            }}
          />
        </Flex>
      );
    },
    Actions: () => {
      const history = useHistory();

      return (
        <Flex alignItems="center" gap="sm">
          <Button
            compact
            id="explore-library"
            icon={{ name: hasLibraryItems ? 'grid-2' : 'circle-plus' }}
            disabled={completions?.is_explored_library}
            onClick={() => {
              mutate('is_explored_library', true);
              history.push(hasLibraryItems ? links.library : links.addLibrary);
            }}
          >
            {hasLibraryItems
              ? i18n.t('Explore Library')
              : i18n.t('Add Library Items')}
          </Button>
          <Button
            compact
            id="explore-blueprints"
            disabled={completions?.is_explored_blueprints}
            icon={{ name: 'kandji-blueprint' }}
            onClick={() => {
              mutate('is_explored_blueprints', true);
              history.push(paths.root);
            }}
          >
            {i18n.t('Explore Blueprints')}
          </Button>
        </Flex>
      );
    },
  },
  {
    id: 'apns',
    title: i18n.t('Configure APNs'),
    description: i18n.t(
      'Get set up with APNs to start managing devices in Kandji.',
    ),
    icon: 'gear',
    timeToComplete: i18n.t('3 min'),
    Image: () => {
      return (
        <Flex
          flex={1}
          css={{
            position: 'relative',
            marginTop: '20px',
            marginLeft: '20px',
            backgroundImage: `url(${ConfigureApns})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
          }}
        >
          <img
            src={ApnsIcon}
            alt="apnsicon"
            style={{
              position: 'absolute',
              bottom: '20px',
              left: '-55px',
              height: '65px',
            }}
          />
        </Flex>
      );
    },
    Actions: () => {
      const history = useHistory();
      return (
        <Box>
          <Button
            compact
            id="configure-apns"
            onClick={() => history.push('my-company/integrations/new')}
          >
            {i18n.t('Configure APNs')}
          </Button>
        </Box>
      );
    },
  },
  {
    id: 'devices',
    title: i18n.t('Add devices'),
    description: i18n.t(
      'Enroll devices manually or through Apple Business Manager.',
    ),
    icon: 'circle-plus',
    timeToComplete: i18n.t('5-10 min'),
    Image: () => {
      return (
        <Flex
          flex={1}
          css={{
            position: 'relative',
            marginTop: '20px',
            marginLeft: '20px',
            backgroundImage: `url(${AddDevices})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
          }}
        >
          <img
            src={AddDevicesIcon}
            alt="devicesicon"
            style={{
              position: 'absolute',
              bottom: '64px',
              left: '-45px',
              height: '67px',
            }}
          />
        </Flex>
      );
    },
    Actions: () => {
      const history = useHistory();

      return (
        <Tooltip
          css={{ maxWidth: 'unset' }}
          content={<Text>{i18n.t('Configure APNs to add a device.')}</Text>}
        >
          <Box css={{ width: 'fit-content' }}>
            <Button
              compact
              id="add-devices"
              disabled={!stepsCompleted.includes('apns')}
              onClick={() => history.push('add-devices?tab=manual')}
            >
              {i18n.t('Add devices')}
            </Button>
          </Box>
        </Tooltip>
      );
    },
  },
  {
    id: 'ade',
    title: i18n.t('Configure Automated Device Enrollment'),
    description: i18n.t('Use ADE for zero-touch deployment.'),
    icon: 'sf-desktop-computer',
    timeToComplete: i18n.t('3 min'),
    Image: () => {
      return (
        <Flex
          flex={1}
          css={{
            position: 'relative',
            marginTop: '20px',
            marginLeft: '20px',
            backgroundImage: `url(${ConfigureAde})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
          }}
        >
          <img
            src={AdeIcon}
            alt="adeicon"
            style={{
              position: 'absolute',
              bottom: '91px',
              left: '-41px',
              height: '67px',
            }}
          />
        </Flex>
      );
    },
    Actions: () => {
      const history = useHistory();

      return (
        <Tooltip
          css={{ maxWidth: 'unset' }}
          content={
            <Text>
              {i18n.t('Configure APNs to set up Automated Device Enrollment.')}
            </Text>
          }
        >
          <Box css={{ width: 'fit-content' }}>
            <Button
              compact
              id="configure-ade"
              disabled={!stepsCompleted.includes('apns')}
              css={{ pointerEvents: 'all !important' }}
              onClick={() =>
                history.push(
                  'my-company/integrations#automated-device-enrollment',
                )
              }
            >
              {i18n.t('Configure Automated Device Enrollment')}
            </Button>
          </Box>
        </Tooltip>
      );
    },
    isDismissable: true,
  },
  {
    id: 'vpp',
    title: i18n.t('Configure Apps and Books'),
    description: i18n.t(
      'Configuring Apps and Books enhances device management by enabling the deployment of app store apps.',
    ),
    icon: 'app-store',
    iconCss: {
      marginLeft: '-2px',
      marginTop: '3px',
      marginRight: '-4px',
    },
    timeToComplete: i18n.t('3 min'),
    Image: () => {
      return (
        <Flex
          flex={1}
          css={{
            position: 'relative',
            marginTop: '20px',
            marginLeft: '-30px',
            backgroundImage: `url(${AppsBooksTotal})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
          }}
        ></Flex>
      );
    },
    Actions: () => (
      <Tooltip
        interactive
        css={{ maxWidth: 'unset' }}
        content={
          <Text>{i18n.t('Configure APNs to set up Apps and Books.')}</Text>
        }
      >
        <Box css={{ width: 'fit-content' }}>
          <Button
            compact
            id="configure-vpp"
            disabled={!stepsCompleted.includes('apns')}
            css={{ pointerEvents: 'all !important' }}
          >
            {i18n.t('Configure Apps and Books')}
          </Button>
        </Box>
      </Tooltip>
    ),
    isDismissable: true,
  },
  {
    id: 'complete',
    title: i18n.t('Setup complete!'),
    icon: 'party-horn',
    isViewOnly: true,
  },
];
