import { i18n } from 'i18n';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import { object } from 'prop-types';

import { Button, Chip } from '@kandji-inc/bumblebee';

import iconCaretDown from '../../assets/caret-down.svg';

import { useAuthority, useModal, usePanel } from '../../hooks';

import library from '../../library';

import Menu from '../../menu';

import {
  i18nAdcsLibraryItemConfigLabelMap,
  i18nAdcsStatusMap,
} from '../../../../localization';
import { statusClass, statusLabel } from '../../status';

/* istanbul ignore next */
const Row = ({ server }) => {
  const { id, name, status, connector } = server;

  const { setAuthority, authorityListLibraryItemMap } = useAuthority();

  const { openModal } = useModal();
  const { openPanel } = usePanel();

  const [expand, setExpand] = useState(false);

  const list = authorityListLibraryItemMap[id] || [];

  const hasLibraryItems = list.length > 0;

  const toggleExpand = () => setExpand(!expand);

  /* istanbul ignore next */
  const openModalCreateLibraryItem = () => {
    setAuthority(id);
    openModal('create-library-item');
  };

  const openPanelEditServer = () => {
    setAuthority(id);
    openPanel('edit-server');
  };

  const openPanelDeleteServer = () => {
    setAuthority(id);
    openPanel('delete-server');
  };

  const menu = (
    <>
      <Button
        theme="action"
        kind="link"
        icon="pencil"
        onClick={openPanelEditServer}
      >
        {i18n.t('Edit server')}
      </Button>

      <Button
        theme="action"
        kind="link"
        icon="folder-plus"
        onClick={openModalCreateLibraryItem}
      >
        {i18n.t('Create Library Item')}
      </Button>

      <Button
        theme="error"
        kind="link"
        icon="trash-can"
        onClick={openPanelDeleteServer}
      >
        {i18n.t('Delete server')}
      </Button>
    </>
  );

  const loopLibrary = hasLibraryItems && expand && (
    <div className="k-adcs-server-library-item-list">
      {list.map(
        /* istanbul ignore next */
        ({ id: itemID, identifier, name: itemName }) => {
          const { label, path } = library[identifier];

          return (
            <div key={itemID} className="k-adcs-server-library-item">
              <div className="k-adcs-table-row k-table-row">
                <div className="k-adcs-table-column-toggle k-table-column" />

                <div className="k-adcs-table-column-label k-table-column">
                  {i18nAdcsLibraryItemConfigLabelMap(label)}
                </div>

                <div className="k-adcs-table-column-link k-table-column">
                  <Link className="b-alink" to={`/${path}/${itemID}`}>
                    {itemName}
                  </Link>
                </div>
              </div>
            </div>
          );
        },
      )}
    </div>
  );

  return (
    <section
      className={`k-adcs-table-section k-table-section k-table-section-${
        expand ? 'expanded' : 'collapsed'
      }`}
    >
      <div className="k-adcs-server k-table-section-highlight">
        <div className="k-adcs-table-row k-table-row">
          <div className="k-adcs-table-column-toggle k-table-column">
            {hasLibraryItems && (
              <Button className="k-action" kind="link" onClick={toggleExpand}>
                <img
                  className={`k-caret k-caret-${expand ? 'down' : 'right'}`}
                  src={iconCaretDown}
                  alt="caret"
                />
              </Button>
            )}
          </div>

          <div className="k-adcs-table-column-server k-table-column">
            {name || '-'}
          </div>

          <div className="k-adcs-table-column-connector k-table-column">
            {connector || i18n.t('Not assigned')}
          </div>

          <div className="k-adcs-table-column-library-items k-table-column">
            {list?.length || 0}
          </div>

          <div className="k-adcs-table-column-status k-table-column">
            <Chip
              className={`k-adcs-status-${statusClass(status)}`}
              text={i18nAdcsStatusMap(statusLabel(status))}
            />
          </div>

          <div className="k-adcs-table-column-action k-table-column">
            <Menu menu={menu} />
          </div>
        </div>
      </div>

      {loopLibrary}
    </section>
  );
};

Row.propTypes = {
  server: object.isRequired,
};

export default Row;
