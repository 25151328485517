import { useMutation } from '@tanstack/react-query';
import { msTeamsService } from '../services/teams';

type Channels = string[];

type Notification = {
  integrationId: number;
  channels: Channels;
};

const useTestNotification = () =>
  useMutation({
    mutationFn: (notification: Notification) =>
      msTeamsService.sendTestNotification(
        notification.integrationId,
        notification.channels,
      ),
  });

export default useTestNotification;
