import { Banner } from '@kandji-inc/bumblebee';
import { i18n } from 'i18n';
import React from 'react';

const SuccessBanner = () => (
  <Banner theme="success" icon="circle-check" kind="normal">
    <p>
      {i18n.$t('{kandjiAdcsConnector} was successfully downloaded.', {
        kandjiAdcsConnector: <strong>Kandji AD CS Connector</strong>,
      })}
    </p>
  </Banner>
);

export default SuccessBanner;
