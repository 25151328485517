import { string } from 'prop-types';
import React from 'react';
import { ReactSVG } from 'react-svg';

const CategoryIcon = ({ iconImg }) => (
  <div className="k-self-service-side-panel-category-icon">
    <h3 className="b-txt b-mb1">Category icon</h3>
    <ReactSVG className="k-self-service-category__img" src={iconImg} />
  </div>
);

CategoryIcon.propTypes = {
  iconImg: string,
};

CategoryIcon.defaultProps = {
  iconImg: '',
};

export default CategoryIcon;
