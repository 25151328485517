import { Flex, styled } from '@kandji-inc/nectar-ui';
import React from 'react';
import type { RegisterOptions } from 'react-hook-form';
import type { CommonProps } from '../types/integrator.types';
import ViewCheckboxCard from './partials/view-checkbox-card';
import ViewHeaderV0 from './partials/view-header-v0';

const LayoutContainer = styled(Flex, {
  alignItems: 'center',
  flexDirection: 'column',
});

const StyledOptions = styled('div', {
  marginTop: '$5',
});

type OptionProps = {
  name: string;
  icon: string;
  label: string;
  altText: string;
  default?: boolean;
};

export interface TwoColumnCheckboxProps<InputType, StateType>
  extends CommonProps<InputType, StateType> {
  layout: 'two-column-checkbox';
  header: {
    title: string;
    description: string;
    link?: {
      url: string;
      text: string;
    };
  };
  options: Array<OptionProps>;
  validate?: RegisterOptions['validate'];
}

const TwoColumnCheckbox = <InputType, StateType>({
  logo,
  header,
  options,
  validate,
}: TwoColumnCheckboxProps<InputType, StateType>) => (
  <LayoutContainer>
    <ViewHeaderV0 data={{ logo, ...header }} />

    <StyledOptions>
      <ViewCheckboxCard options={options} validate={validate} />
    </StyledOptions>
  </LayoutContainer>
);

export default TwoColumnCheckbox;
