import React from 'react';

import { Button, Dialog, Flex } from '@kandji-inc/nectar-ui';

type CancelAssignmentsProps = {
  isOpen: boolean;
  toggle: (boolean) => void;
  onConfirm: () => void;
  isAddingBlueprint: boolean;
};

function CancelAssignments(props: CancelAssignmentsProps) {
  const { isOpen, toggle, onConfirm, isAddingBlueprint } = props;

  const footer = (
    <Flex gap="md" justifyContent="end" wrap="wrap">
      <Button variant="subtle" onClick={() => toggle(false)}>
        Stay on page
      </Button>

      <Button
        variant="primary"
        onClick={() => {
          onConfirm();
          toggle(false);
        }}
      >
        {isAddingBlueprint ? 'Leave page' : 'Yes, cancel'}
      </Button>
    </Flex>
  );

  return (
    <Dialog
      title={
        isAddingBlueprint
          ? 'Are you sure you want to leave this page?'
          : 'Are you sure you want to cancel?'
      }
      content="Any changes will be lost."
      footer={footer}
      isOpen={isOpen}
      onOpenChange={() => toggle(false)}
      css={{ width: '500px', zIndex: 2000 }}
    />
  );
}

export default CancelAssignments;
