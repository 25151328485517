import OptionsMenu from 'src/features/util/components/options-menu';
import styled from 'styled-components';

const StyledOptionsMenu = styled(OptionsMenu)`
  margin-left: auto;

  button {
    width: 36px;
    height: 36px;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--color-neutral-50);
  }

  button:hover {
    color: var(--color-neutral-50);
    background: var(--color-neutral-30);
  }
`;

export default StyledOptionsMenu;
