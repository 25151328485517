import type { EdgeTypes } from 'reactflow';

import { EDGE_TYPES } from '../constants';

import BranchEdge from './branch';
import PlaceholderEdge from './placeholder';
import RootEdge from './root';

const edgeTypes: EdgeTypes = {
  [EDGE_TYPES.root]: RootEdge,
  [EDGE_TYPES.branch]: BranchEdge,
  [EDGE_TYPES.placeholder]: PlaceholderEdge,
};

export { edgeTypes, RootEdge, BranchEdge, PlaceholderEdge };
