import { Flex, Paragraph, styled } from '@kandji-inc/nectar-ui';
import React from 'react';

import {
  StyledHeaderH1,
  StyledHeaderImage,
  StyledLink,
} from '../../../../components/styled-components/main';

export const StyledHeaderContainer = styled(Flex, {
  width: '100%',
  gap: '0 4px',
  borderBottom: '1px solid $neutral30',
  padding: '$4 0',
  marginBottom: '$4',
});

interface Props {
  data: {
    title: string;
    description: string;
    logo?: string;
    link?: {
      text: string;
      url: string;
    };
  };
}

const ViewHeader = ({ data }: Props) => (
  <StyledHeaderContainer>
    <StyledHeaderImage src={data.logo} alt="header icon" />
    <div>
      <StyledHeaderH1>{data.title}</StyledHeaderH1>
      <Paragraph>
        {data.description}
        <StyledLink href={data.link?.url} rel="noreferrer" target="_blank">
          {' '}
          {data.link?.text}
        </StyledLink>
      </Paragraph>
    </div>
  </StyledHeaderContainer>
);

export default ViewHeader;
