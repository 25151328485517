import {
  Banner,
  Button,
  Icon,
  kmodal as KModal,
  Select,
} from '@kandji-inc/bumblebee';
import React, { useState } from 'react';

const DeleteModal = (props) => {
  const { isDeleting, model, onClose, onDelete } = props;
  const [value, setValue] = useState();
  const [isLoading, setIsLoading] = useState(false);

  const options = model.categories
    .filter((cat) => cat.id !== isDeleting.data.id)
    .map((cat) => ({
      label: cat.name,
      value: cat.id,
    }));
  return (
    <>
      <KModal className="k-self-service__delete-modal">
        <div className="k-self-service__delete-modal-top">
          <div className="b-flex-vc b-mb">
            <Icon
              name="circle-xmark"
              className="k-self-service__delete-modal-icon b-mr"
            />
            <h2 className="b-h2">Delete category</h2>
          </div>
          <p className="b-txt b-mb3">
            This category is assigned to Library Items available in Self
            Service. Reassign them to a new category below before deleting.
          </p>
          <p className="b-txt b-mb1">Reassign Library Items to</p>
          <Select
            className="b-mb2"
            options={options}
            value={value}
            onChange={setValue}
          />
          <Banner theme="info" kind="block">
            <p>
              <b className="b-txt-bold">
                {isDeleting.conflicts.length} Library Items
              </b>{' '}
              will be re-assigned
            </p>
          </Banner>
        </div>
        <div className="k-self-service__delete-modal-footer b-flex-vc b-flex-end">
          <Button kind="outline" className="b-mr" onClick={onClose}>
            Cancel
          </Button>
          <Button
            disabled={!value}
            isProgress={isLoading}
            icon={isLoading ? 'arrows-rotate' : ''}
            theme="error"
            onClick={() => {
              setIsLoading(true);
              onDelete(value.value).catch(() => setIsLoading(false));
            }}
          >
            Delete
          </Button>
        </div>
      </KModal>
    </>
  );
};

export default DeleteModal;
