import { useCallback, useEffect } from 'react';
import type { FieldValues, UseFormTrigger } from 'react-hook-form';

export const useTrigger = (
  inputs: Array<{ name: string }>,
  trigger: UseFormTrigger<FieldValues>,
) => {
  const validateFields = useCallback(() => {
    trigger(inputs.map((v) => v.name));
  }, [inputs, trigger]);

  // Trigger vadlidation right after page load
  useEffect(() => validateFields(), [validateFields]);
};
