import PropTypes from 'prop-types';
import React from 'react';
import Field from '../../common/Field';
import IntegrationCheckbox from './Fields/IntegrationCheckbox';

const IntegrationField = ({
  type,
  value,
  label,
  description,
  onChange,
  disabled,
}) => {
  const inputTypes = new Map().set('checkbox', {
    component: IntegrationCheckbox,
    props: {
      value,
      label,
      description,
      onChange,
      disabled,
    },
  });

  const { component, props } = inputTypes.get(type);

  return (
    <>
      <Field component={component} props={props} />
    </>
  );
};

IntegrationField.propTypes = {
  type: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
    PropTypes.number,
    PropTypes.object,
  ]).isRequired,
  label: PropTypes.string.isRequired,
  description: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
};

IntegrationField.defaultProps = {
  description: '',
};

export default IntegrationField;
