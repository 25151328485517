import { Flex, Loader, styled } from '@kandji-inc/nectar-ui';
import { useState } from 'react';
import { Pagination } from 'src/components/ui';
import { NoResultsFound } from 'src/features/edr/vulnerability/common/table/no-results-found';
import { useGetVulnerabilityApplications } from 'src/features/edr/vulnerability/hooks/use-get-vulnerability-applications';
import type { Vulnerability } from 'src/features/edr/vulnerability/vulnerability.types';
import { usePagination } from 'src/features/visibility/prism/hooks';
import { ApplicationCard } from './application-card';

const StickySeparator = styled(Flex, {
  height: 1,
  bg: '$neutral20',
  position: 'sticky',
  top: 109,
  zIndex: 1,
});

const ApplicationList = (props: { selectedVulnerability: Vulnerability }) => {
  const { selectedVulnerability } = props;

  const itemsPerPageOptions = [10, 25, 50, 75, 100];

  const [expandedApplicationCards, setExpandedApplicationCards] = useState<
    string[]
  >([]);
  const { paginationState: pagination, setPagination } = usePagination({
    defaultPageSize: itemsPerPageOptions[0],
  });

  const { applications, count, isLoading } = useGetVulnerabilityApplications(
    selectedVulnerability.cve_id,
    [pagination],
    {
      size: pagination.pageSize,
      page: pagination.pageIndex + 1,
    },
  );

  const hasApplications = applications?.length > 0;

  return (
    <Flex hFull flow="column" pt5 css={{ gap: '10px' }}>
      {/* TODO: reintroduce when filters are added */}
      {/* <StickySeparator /> */}
      {isLoading && (
        <Flex
          wFull
          hFull
          flow="column"
          alignItems="center"
          justifyContent="center"
          gap="sm"
          data-testid="loading-applications"
        >
          <Loader size="lg" />
        </Flex>
      )}

      {!isLoading && (
        <Flex flow="column" css={{ gap: '10px', mt: -10 }}>
          {applications.map((application) => (
            <ApplicationCard
              application={application}
              isExpanded={expandedApplicationCards.includes(application.name)}
              setIsExpanded={(value) =>
                setExpandedApplicationCards(
                  value
                    ? [...expandedApplicationCards, application.name]
                    : expandedApplicationCards.filter(
                        (id) => id !== application.name,
                      ),
                )
              }
            />
          ))}
        </Flex>
      )}

      {!isLoading && !hasApplications && <NoResultsFound />}

      <Flex
        wFull
        css={{
          position: 'absolute',
          bottom: 0,
          marginInline: '-20px',
          paddingInline: '20px',
          padding: '$3 $4',
          borderTop: '1px solid $neutral20',
          backgroundColor: '$neutral0',
        }}
      >
        <Pagination
          currentPage={pagination.pageIndex + 1}
          totalItems={count}
          itemsPerPageOptions={itemsPerPageOptions}
          itemsPerPage={pagination.pageSize}
          onPageChange={
            /* istanbul ignore next */ (page) =>
              setPagination({ ...pagination, pageIndex: page - 1 })
          }
          onItemsPerPageChange={
            /*  istanbul ignore next */ (itemsPerPage) => {
              setPagination({
                ...pagination,
                pageIndex: 0,
                pageSize: itemsPerPage,
              });
            }
          }
        />
      </Flex>
    </Flex>
  );
};

export { ApplicationList };
