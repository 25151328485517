import type { NodeTypes } from 'reactflow';

import { NODE_TYPES } from '../constants';
import AssignmentNode from './assignment';
import ConditionalNode from './conditional';
import EndNode from './end';
import PreStartNode from './pre-start';
import RouterNode from './router';
import StartNode from './start';

const nodeTypes: NodeTypes = {
  [NODE_TYPES.start]: StartNode,
  [NODE_TYPES.end]: EndNode,
  [NODE_TYPES.conditional]: ConditionalNode,
  [NODE_TYPES.router]: RouterNode,
  [NODE_TYPES.preStart]: PreStartNode,
  [NODE_TYPES.assignment]: AssignmentNode,
};

export {
  nodeTypes,
  StartNode,
  EndNode,
  ConditionalNode,
  AssignmentNode,
  RouterNode,
};
