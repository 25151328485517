import { Banner, Button, Checkbox, Flex } from '@kandji-inc/bumblebee';
import { InterfaceContext } from 'contexts/interface';
import { bool, func } from 'prop-types';
import React, { useState, useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { i18n } from 'src/i18n';
import { useOldField } from 'src/old-honey-form';
import {
  FieldPassword,
  FieldText,
  FieldTextarea,
  Form,
} from 'theme/components/molecules/Form';
import Modal from '../../theme/components/atoms/Modal';
import { ButtonSave } from './buttons';
import { Context } from './context';

// TODO: move the modals in this file out into separate, individual files such as 'modal-delete,js', 'modal-rename.js', etc..

const FieldPasswordWrapper = () => {
  const { givenToken } = useContext(Context);

  const given_token = useOldField('given_token');
  const { setValue } = given_token;

  useEffect(() => {
    setValue(givenToken);
  }, []);

  return (
    <FieldPassword
      name="given_token"
      readOnly
      label={i18n.t('Token')}
      pureLabel
      wrapperClassName="copy-token-modal_token-field"
    />
  );
};

const DeleteModal = ({ onDelete, onCancel, shouldShow }) => {
  const { onTokenDelete, onCancelDelete, isWorking } = useContext(Context);

  return (
    <Modal name="delete" shouldShow={shouldShow}>
      <div className="paper">
        <header className="modal-division division">
          <div className="cross-container">
            <Button
              kind="link"
              onClick={onCancel || onCancelDelete}
              icon="xmark"
            />
          </div>
          <div className="node d-flex justify-content-start">
            <h3 className="modal-title">{i18n.t('Are you sure?')}</h3>
          </div>
        </header>
        <section className="modal-division division">
          <div className="node d-flex justify-content-start">
            <p className="modal-regular">
              {i18n.t('Your token will be deleted.')}
            </p>
          </div>
        </section>
        <footer className="modal-division division divide">
          <Flex justify="flex-end" className="b-pad">
            <Button onClick={onCancel || onCancelDelete} kind="outline">
              {i18n.t('Cancel')}
            </Button>
            <Button
              onClick={onDelete || onTokenDelete}
              theme="error"
              isProgress={isWorking}
              iconPlacement="right"
              icon={isWorking ? 'arrows-rotate' : ''}
            >
              {i18n.t('Revoke')}
            </Button>
          </Flex>
        </footer>
      </div>
    </Modal>
  );
};

DeleteModal.propTypes = {
  onDelete: func,
  onCancel: func,
  shouldShow: bool,
};

DeleteModal.defaultProps = {
  onDelete: null,
  onCancel: null,
  shouldShow: false,
};

const CreateModal = () => {
  const { onModalOpen, onModalClose } = useContext(InterfaceContext);
  const {
    onTokenCopy,
    onTokenCreate,
    onCancelCreate,
    tokenHoneyValidation,
    givenID,
    pathToTokenPage,
    isWorking,
  } = useContext(Context);
  const [isCopyTokenConfirmation, setIsCopyTokenConfirmation] = useState(false);
  const history = useHistory();
  const initialForm = { label: '' };

  const onNext = () => {
    onModalClose();
    onModalOpen('add-third');
    setIsCopyTokenConfirmation(false);
  };

  const onSkip = () => {
    onModalClose();
  };

  const onConfigure = () => {
    onModalClose();
    history.push(pathToTokenPage + givenID, { inEditMode: true });
  };

  const bannerCopyToken = (
    <p style={{ margin: 0 }}>
      {i18n.$t(
        '{status} Copy your token now and store it in a safe place. You will not be able to see these details again.',
        {
          status: <strong>Your token has been successfully created!</strong>,
        },
      )}
    </p>
  );

  return (
    <>
      <Modal name="add">
        <Form
          initialValues={initialForm}
          fieldValidation={tokenHoneyValidation}
          onSubmit={onTokenCreate}
        >
          <div className="paper" style={{ width: 560 }}>
            <header className="modal-division division">
              {false ? (
                <div className="cross-container">
                  <Button kind="link" onClick={onModalClose} icon="xmark" />
                </div>
              ) : (
                <div style={{ paddingTop: 'var(--b-gap2)' }} />
              )}
              <div className="node">
                <h2 className="b-h2 b-mb">{i18n.t('Add an API token')}</h2>
                <p className="b-txt b-mb-tiny">
                  {i18n.t(
                    'Choose a name that is short and easy for you to remember.',
                  )}
                </p>
              </div>
            </header>

            <div className="b-pad3 divide" style={{ paddingTop: 0 }}>
              <div className="theme-form-group">
                <FieldText
                  name="label"
                  id="label"
                  label={i18n.t('Name')}
                  placeholder={i18n.t('Add name of token')}
                  required
                  showAsteriskIfRequired={false}
                />
              </div>
              <div className="theme-form-group theme-form-group-space">
                <FieldTextarea
                  name="description"
                  id="description"
                  label={i18n.t('Description')}
                  placeholder={i18n.t(
                    'Add a short description about this API token',
                  )}
                  style={{
                    height: 94,
                    maxHeight: 94,
                    minHeight: 94,
                    resize: 'none',
                  }}
                />
              </div>
            </div>

            <footer className="modal-division division divide">
              <Flex justify="flex-end" className="b-pad">
                <Button onClick={onCancelCreate} kind="outline">
                  {i18n.t('Cancel')}
                </Button>
                <ButtonSave label={i18n.t('Create')} disabled={isWorking} />
              </Flex>
            </footer>
          </div>
        </Form>
      </Modal>
      <Modal name="add-second">
        <Form
          initialValues={initialForm}
          fieldValidation={tokenHoneyValidation}
        >
          <div className="paper" style={{ width: 560 }}>
            <header className="modal-division division">
              {false ? (
                <div className="cross-container">
                  <Button kind="link" onClick={onModalClose} icon="xmark" />
                </div>
              ) : (
                <div style={{ paddingTop: '16px' }} />
              )}
              <h2 className="b-h2 enterprise-api--ultra-dark b-pcontent3 b-mt3">
                {i18n.t('Copy your API token')}
              </h2>
            </header>
            <div className="b-pcontent3 b-mb3 b-mt">
              <Banner
                theme="warning"
                kind="block"
                style={{ minWidth: 0 }}
                className="banner-with-top-icon"
                icon="octagon-exclamation"
              >
                {bannerCopyToken}
              </Banner>
            </div>
            <div className="divide b-pcontent3 b-mb3">
              <div className="theme-form-group">
                <FieldPasswordWrapper />
              </div>
              <div className="theme-form-group theme-form-group-space">
                <Button
                  kind="link"
                  icon="copy"
                  onClick={onTokenCopy}
                  className="b-mt1"
                >
                  {i18n.t('Copy Token')}
                </Button>
              </div>
              <div className="theme-form-group b-mt3">
                <div className="theme-form-check">
                  <Checkbox
                    id="copy-token-confirmation"
                    checked={isCopyTokenConfirmation}
                    onChange={() =>
                      setIsCopyTokenConfirmation(!isCopyTokenConfirmation)
                    }
                    style={{ height: 20, marginRight: 16 }}
                  />
                  <label
                    htmlFor="copy-token-confirmation"
                    className="theme-form-check-label"
                  >
                    {i18n.t(
                      'I have copied the token and understand that I will not be able to see these details again.',
                    )}
                  </label>
                </div>
              </div>
            </div>
            <footer className="divide">
              <Flex justify="flex-end" className="b-pad">
                <Button
                  disabled={!isCopyTokenConfirmation}
                  onClick={isCopyTokenConfirmation ? onNext : () => {}}
                >
                  {i18n.t('Next')}
                </Button>
              </Flex>
            </footer>
          </div>
        </Form>
      </Modal>
      <Modal name="add-third">
        <div className="paper">
          <header className="modal-division division">
            {true ? (
              <div className="cross-container">
                <Button kind="link" onClick={onModalClose} icon="xmark" />
              </div>
            ) : (
              <div style={{ paddingTop: '20px' }} />
            )}
            <div className="node">
              <h3 className="modal-title">
                {i18n.t('Manage API Permissions')}
              </h3>
              <p className="modal-regular">
                {i18n.t(
                  'You can configure specific API permissions for this token now, or skip this step and set them up later.',
                )}
              </p>
            </div>
          </header>
          <footer className="modal-division division divide">
            <Flex justify="flex-end" className="b-pad">
              <Button onClick={onSkip} kind="outline">
                {i18n.t('Skip')}
              </Button>
              <Button onClick={onConfigure}>{i18n.t('Configure')}</Button>
            </Flex>
          </footer>
        </div>
      </Modal>
    </>
  );
};

export { CreateModal, DeleteModal };

export default () => (
  <>
    <CreateModal />
    <DeleteModal />
  </>
);
