import { arrayOf, bool, func, shape } from 'prop-types';
// istanbul ignore file
import React, { useEffect, useRef, useState } from 'react';

import { Toast } from '@kandji-inc/nectar-ui';
import { InterfaceContext } from 'contexts/interface';
import { useHistory } from 'react-router';
import {
  CONFIG_SHAPE,
  INTEGRATION_SHAPE,
  NOTIFICATION_DATA_SHAPE,
  NOTIFICATION_SHAPE,
} from '../util';

import HubspotService from '../../../../../../components/common/hubspot-handler/hubspot-service';
import SlackSidePanel from '../../../slack/slack-side-panel';
import useGetChannelsForNewIntegrationWorkspace from '../new-integration/hooks/use-get-channels-for-new-integration-workspace';
import IntegrationCard from './integration-card';
import { DeleteIntegrationModal, DeleteNotificationModal } from './modals';

const INITIAL_DELETE_INTEGRATION_PROPS = {
  isOpen: false,
  name: '',
  id: 0,
  notificationsAmount: 0,
};
const INITIAL_DELETE_NOTIFICATION_PROPS = {
  isOpen: false,
  name: '',
  id: 0,
};
const HUBSPOT_MODIFIERS = [
  HubspotService.modifiers.moveUp,
  HubspotService.modifiers.overlay,
];

function EventNotificationsTab({
  groupedByIntegrations,
  integrationData: slackIntegrationData,
  config,
}) {
  const [isNotificationEditorOpen, setIsNotificationEditorOpen] =
    useState(false);

  const [eventNotificationPanelData, setEventNotificationPanelData] = useState(
    {},
  );

  const [deleteIntegrationModalProps, setDeleteIntegrationModalProps] =
    useState(INITIAL_DELETE_INTEGRATION_PROPS);
  const [deleteNotificationModalProps, setDeleteNotificationModalProps] =
    useState(INITIAL_DELETE_NOTIFICATION_PROPS);

  const { sidebarOpened } = React.useContext(InterfaceContext);

  const {
    action: historyAction,
    location: { state: newIntegrationData },
  } = useHistory();

  const toastRef = useRef();
  const {
    isChannelLoadingComplete,
    newlyAddedIntegrationId,
    setCloseToast,
    toastManager,
  } = useGetChannelsForNewIntegrationWorkspace({
    integrationResponse: slackIntegrationData,
    newIntegrationData,
    toastRef,
    historyAction,
    openEventNotificationEditor,
  });

  function closeEventNotificationEditor() {
    HubspotService.discardModifiers(...HUBSPOT_MODIFIERS);
    setIsNotificationEditorOpen(false);
  }

  function handleToggleSidePanel() {
    closeEventNotificationEditor();
  }

  function openDeleteNotificationModal(ntfData) {
    setDeleteNotificationModalProps({
      isOpen: true,
      name: ntfData.name,
      id: ntfData.id,
    });
  }

  function openDeleteIntegrationModal(integrationData) {
    setDeleteIntegrationModalProps({
      isOpen: true,
      name:
        integrationData.integration.display_name ||
        integrationData.integration.name,
      id: integrationData.integration.id,
      notificationsAmount: integrationData.notificationsForIntegration.length,
    });
  }

  function openEventNotificationEditor(
    existingNotificationData,
    integrationData,
  ) {
    // structure of integrationData is different depending on if we are adding an event notification from the Succes Toast action or the notification card
    const integrationId =
      integrationData?.integration?.id ?? integrationData?.id;

    HubspotService.applyModifiers(...HUBSPOT_MODIFIERS);
    setEventNotificationPanelData({ integrationId, existingNotificationData });

    setIsNotificationEditorOpen(true);
  }

  useEffect(() => {
    const clearLocationHistory = () => {
      window.history.replaceState({}, '');
    };

    if (isChannelLoadingComplete) {
      // location.history gets persisted during refreshes. This causes issues with enabling the "Add event notification" button if we don't clear the history.
      clearLocationHistory();
    }
  }, [isChannelLoadingComplete]);

  return (
    <>
      <div>
        {groupedByIntegrations.map((integrationData) => (
          <IntegrationCard
            key={integrationData.integration.id}
            integration={integrationData.integration}
            notificationsForIntegration={
              integrationData.notificationsForIntegration
            }
            openNotificationEditor={(editedNotification) =>
              openEventNotificationEditor(editedNotification, integrationData)
            }
            openDeleteIntegrationModal={() =>
              openDeleteIntegrationModal(integrationData)
            }
            openDeleteNotificationModal={openDeleteNotificationModal}
            config={config}
            isAddEventNotificationButtonDisabled={
              integrationData.integration.id === newlyAddedIntegrationId &&
              !isChannelLoadingComplete
            }
          />
        ))}
      </div>

      <SlackSidePanel
        isOpen={isNotificationEditorOpen}
        data={eventNotificationPanelData}
        handleToggleSidePanel={handleToggleSidePanel}
        handleDeleteNotification={({ id, name }) =>
          openDeleteNotificationModal({ id, name, isOpen: true })
        }
      />

      <DeleteNotificationModal
        {...deleteNotificationModalProps}
        onClose={() => {
          setDeleteNotificationModalProps(INITIAL_DELETE_NOTIFICATION_PROPS);
          closeEventNotificationEditor();
        }}
      />

      <DeleteIntegrationModal
        {...deleteIntegrationModalProps}
        onClose={() =>
          setDeleteIntegrationModalProps(INITIAL_DELETE_INTEGRATION_PROPS)
        }
        config={config}
        isLast={groupedByIntegrations.length === 1}
      />

      <Toast
        managerRef={toastRef}
        open={toastManager}
        onOpenChange={(isOpen) => {
          if (!isOpen) {
            setCloseToast();
          }
        }}
        viewportCss={{
          left: sidebarOpened ? '256px' : '78px',
        }}
      />
    </>
  );
}

EventNotificationsTab.propTypes = {
  groupedByIntegrations: arrayOf(
    shape({
      integration: INTEGRATION_SHAPE.isRequired,
      notificationsForIntegration: arrayOf(NOTIFICATION_SHAPE).isRequired,
    }),
  ).isRequired,
  setAllNotifications: func.isRequired,
  setIntegrations: func.isRequired,
  isAdding: bool.isRequired,
  setIsAdding: func.isRequired,
  isSaving: bool.isRequired,
  notificationData: NOTIFICATION_DATA_SHAPE.isRequired,
  setNotificationData: func.isRequired,
  config: CONFIG_SHAPE.isRequired,
};

export default EventNotificationsTab;
export { HUBSPOT_MODIFIERS };
