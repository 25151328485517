import { Box, Button, Flex, Heading, Text } from '@kandji-inc/nectar-ui';
import { useHistory, useParams } from 'react-router-dom';

import { paths } from 'src/features/blueprints/common';

import { noLibraryItemsAlt } from '../../assets';

const EmptyState = () => {
  const history = useHistory();
  const { id } = useParams<{ id: string }>();

  return (
    <Flex
      hFull
      flow="column"
      alignItems="center"
      justifyContent="center"
      data-testid="am-device-empty"
    >
      <Box mb4>{noLibraryItemsAlt}</Box>
      <Flex flow="column" alignItems="center" mb4 css={{ maxWidth: '532px' }}>
        <Heading size="4">No Library Items assigned to this Blueprint</Heading>
        <Text>
          Use the Assignment Map to assign Library Items to devices in this
          Blueprint.
        </Text>
      </Flex>
      <Button
        onClick={() => history.push(paths.flowBlueprint(id, 'assignments'))}
      >
        View Assignment Map
      </Button>
    </Flex>
  );
};

export default EmptyState;
