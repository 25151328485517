import type { IconNames } from '@kandji-inc/nectar-ui';

import type {
  LibraryItem,
  LibraryItemFilterSort,
} from './blueprint-flow.types';

const NODE_TYPES = {
  preStart: 'preStart',
  start: 'start',
  end: 'end',
  conditional: 'conditional',
  router: 'router',
  assignment: 'assignment',
};

const EDGE_TYPES = {
  root: 'root',
  branch: 'branch',
  placeholder: 'placeholder',
};

const ASSIGNMENT_TYPES = {
  if: 'if',
  else: 'else',
  elseif: 'elseif',
};

const DESCENDANT_TYPES = {
  assignment: 'assignment',
  conditional: 'conditional',
  router: 'router',
  edge: 'edge',
};

const ORIGIN_TYPES: {
  bank: LibraryItem['origin'];
  graph: LibraryItem['origin'];
} = {
  bank: 'bank',
  graph: 'graph',
};

const NOTIFICATION_POSITIONS = {
  top: 'top',
  'bottom-left': 'bottom-left',
};

const FLOW_PATHS = {
  assignments: { path: 'assignments', label: 'Assignment Map' },
  library: { path: 'library', label: 'Library Items' },
  parameters: { path: 'parameters', label: 'Parameters' },
  devices: { path: 'devices', label: 'Devices' },
  activity: { path: 'activity', label: 'Activity' },
};

const ONLY_ALL_DEVICES_LIBRARY_ITEMS = ['dep-profile'];

const deviceFamiliesSelectOptions = [
  {
    key: 'MAC',
    label: 'Mac',
    value: 'mac',
  },
  {
    key: 'IPHONE',
    label: 'iPhone',
    value: 'iphone',
  },
  {
    key: 'IPAD',
    label: 'iPad',
    value: 'ipad',
  },
  {
    key: 'TV',
    label: 'Apple TV',
    value: 'tv',
  },
];

const deviceFamilySelectOptionToRunsOn = {
  mac: 'runsOnMac',
  iphone: 'runsOnIphone',
  ipad: 'runsOnIpad',
  tv: 'runsOnTv',
};

const deviceToIcon: Record<string, IconNames> = {
  mac: 'sf-desktop-computer',
  ipad: 'sf-ipad-landscape',
  iphone: 'sf-iphone',
  tv: 'sf-apple-tv',
};

const libraryItemSortOptions: Array<{
  label: string;
  value: LibraryItemFilterSort;
}> = [
  {
    label: 'Library Item type (A-Z)',
    value: 'li_type_az',
  },
  {
    label: 'Library Item type (Z-A)',
    value: 'li_type_za',
  },
  {
    label: 'Library Item name (A-Z)',
    value: 'li_name_az',
  },
  {
    label: 'Library Item name (Z-A)',
    value: 'li_name_za',
  },
  {
    label: 'Newest Created',
    value: 'newest',
  },
  {
    label: 'Oldest Created',
    value: 'oldest',
  },
  {
    label: 'Recently Updated',
    value: 'recently_updated',
  },
];

const sortMap: Record<LibraryItemFilterSort, string> = {
  li_name_az: '+name',
  li_name_za: '-name',
  newest: '-created_at',
  oldest: '+created_at',
  li_type_az: '+name',
  li_type_za: '-name',
  recently_updated: '-updated_at',
};

const deviceKinds = ['mac', 'ipad', 'iphone', 'tv'] as const;

const SIDEBAR_DOCKED_WIDTH = 256;
const SIDEBAR_WIDTH = 77;
const SIDEBAR_TOASTER_PADDING = 12;

const GRAPH_CONTAINER_ID = 'assignment-map-graph-container';

const MIN_ZOOM = 0.1;
const MAX_ZOOM = 2;

const PENDO_ID_PREFIX = 'for-pendo__';
const PENDO_IDS = {
  allDevicesNode: `${PENDO_ID_PREFIX}am-all-devices-node`,
  startFromScratch: `${PENDO_ID_PREFIX}start-from-scratch`,
  createModalNextBtn: `${PENDO_ID_PREFIX}create-modal-next-btn`,
  editMapBtn: `${PENDO_ID_PREFIX}edit-map-btn`,
};

export {
  NODE_TYPES,
  EDGE_TYPES,
  ASSIGNMENT_TYPES,
  DESCENDANT_TYPES,
  ORIGIN_TYPES,
  NOTIFICATION_POSITIONS,
  FLOW_PATHS,
  ONLY_ALL_DEVICES_LIBRARY_ITEMS,
  deviceFamiliesSelectOptions,
  deviceFamilySelectOptionToRunsOn,
  deviceToIcon,
  libraryItemSortOptions,
  sortMap,
  deviceKinds,
  SIDEBAR_DOCKED_WIDTH,
  SIDEBAR_WIDTH,
  SIDEBAR_TOASTER_PADDING,
  GRAPH_CONTAINER_ID,
  MIN_ZOOM,
  MAX_ZOOM,
  PENDO_IDS,
};
