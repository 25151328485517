import React from 'react';
import history from '../../../router/history';
import UniversalAlert from './UniversalAlert';

const MDMConfigureBanner = () => (
  <UniversalAlert
    type="danger"
    text="Apple Push Notification service not configured. You will not be able to enroll devices until this is complete."
    button={{
      text: 'Configure APNs',
      onClick: () => history.push('/my-company/integrations'),
    }}
  />
);

export default MDMConfigureBanner;
