import React, { useState } from 'react';
import { useShallow } from 'zustand/react/shallow';

import { Box, Button, Code, Flex, Icon, Tooltip } from '@kandji-inc/nectar-ui';

import useBlueprintFlow from 'src/features/blueprint-flow/store';

const copiedTooltipStyle = {
  marginBottom: '8px',
  backgroundColor: '$green60',
  '& span svg': {
    display: 'none !important',
  },
};

type CopyUUIDProps = {
  nodeId: string;
};

const CopyUUID = (props: CopyUUIDProps) => {
  const { nodeId } = props;

  const [didCopyUUID, setDidCopyUUID] = useState(false);
  const [isEditingAssignments, isOptionPressed] = useBlueprintFlow(
    useShallow((state) => [state.isEditingAssignments, state.isOptionPressed]),
  );

  const handleCopyUUID = () => {
    navigator.clipboard.writeText(nodeId);
    setDidCopyUUID(true);

    setTimeout(
      /* istanbul ignore next */ () => {
        setDidCopyUUID(false);
      },
      2000,
    );
  };

  const isShowingNodeId = isOptionPressed && !isEditingAssignments;

  if (isShowingNodeId) {
    return (
      <Flex
        css={{ zIndex: 15, position: 'absolute', height: '$6', right: '8px' }}
      >
        <Box
          css={{
            width: '26px',
            background: 'linear-gradient(to left, #FFFFFF, transparent)',
          }}
        />
        <Flex
          alignItems="center"
          css={{ backgroundColor: '$neutral0', gap: '2px' }}
        >
          <Code css={{ width: 'fit-content', height: '$5', lineHeight: '$1' }}>
            {nodeId}
          </Code>
          <Tooltip
            content={
              !didCopyUUID ? (
                'Click to copy'
              ) : (
                <Flex alignItems="center" gap="xs">
                  <Icon size="xs" name="circle-check" />
                  UUID copied
                </Flex>
              )
            }
            side="top"
            onPointerDownOutside={
              /* istanbul ignore next */ (e) => e.preventDefault()
            }
            css={{
              zIndex: 20,
              ...(didCopyUUID ? copiedTooltipStyle : {}),
            }}
          >
            <Button
              variant="subtle"
              icon={{ name: 'copy' }}
              css={{
                padding: '6px 4px',
                '& > svg': {
                  height: 'var(--sizes-4)',
                },
              }}
              onClick={handleCopyUUID}
            />
          </Tooltip>
        </Flex>
      </Flex>
    );
  }

  return null;
};

export default CopyUUID;
