import { Button, modal as Modal, TextInput } from '@kandji-inc/bumblebee';
import SemiBold from 'features/util/components/semi-bold';
import { i18n } from 'i18n';
import { func, string } from 'prop-types';
import React, { useState } from 'react';

function DeleteUserIntegrationModal({
  stopDeletingIntegration,
  deleteIntegration,
  name,
}) {
  const [confirmationInputValue, setConfirmationInputValue] = useState('');
  const [isProcessing, setIsProcessing] = useState(false);

  const confirmed = confirmationInputValue === name;

  function closeCompletely() {
    setConfirmationInputValue('');
    stopDeletingIntegration();
  }

  return (
    <Modal
      isOpen={name != null}
      onClose={closeCompletely}
      withoutCloseButton
      parts={{
        header: { text: i18n.t('Delete user integration') },
        content: {
          children: (
            <>
              <p className="b-txt">
                {i18n.t('Are you sure you want to remove this user directory?')}
              </p>
              <p className="b-txt b-mb2 ">
                {i18n.t(
                  'Existing users that are currently assigned to a device will not be removed.',
                )}
              </p>
              <p className="b-txt b-mb1">
                {i18n.$t(
                  'Please type {name} to confirm. This action cannot be undone.',
                  { name: <SemiBold>{name}</SemiBold> },
                )}
              </p>
              <TextInput
                value={confirmationInputValue}
                onChange={(e) => {
                  e.persist();
                  setConfirmationInputValue(e.target.value);
                }}
                placeholder={name || ''}
              />
            </>
          ),
          className: 'cmn-modal_content--b-spacing',
        },
        footer: {
          children: (
            <>
              <Button
                kind="outline"
                onClick={closeCompletely}
                disabled={isProcessing}
                className="b-mr"
              >
                {i18n.t('Cancel')}
              </Button>
              <Button
                disabled={!confirmed || isProcessing}
                isProgress={isProcessing}
                icon={isProcessing ? 'arrows-rotate' : null}
                onClick={() =>
                  deleteIntegration(
                    () => setIsProcessing(true),
                    () => setConfirmationInputValue(''),
                    () => setIsProcessing(false),
                  )
                }
                theme="error"
              >
                {i18n.t('Delete')}
              </Button>
            </>
          ),
        },
      }}
    />
  );
}

DeleteUserIntegrationModal.propTypes = {
  stopDeletingIntegration: func.isRequired,
  deleteIntegration: func.isRequired,
  name: string,
};

DeleteUserIntegrationModal.defaultProps = {
  name: null,
};

export default DeleteUserIntegrationModal;
