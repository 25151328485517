import React from 'react';
import styled from 'styled-components';
import FooterButtons from '../../../components/footer-buttons';
import { Container } from '../../../components/styled-components';
import Ticket from '../../../components/ticket';
import useModal from '../../../hooks/use-modal';
import oktaIcon from '../assets/okta.svg';
import { oktaSlideMap } from './okta-slide-map';
import tickets from './tickets';

const StyledSlideHeader = styled.h1`
  text-align: center;
  margin-bottom: 12px;
  font-size: 30px;
  line-height: 40px;
`;

const Welcome = () => {
  const { closeModal, nextModal } = useModal();
  const onClose = () => closeModal();

  const handleNext = () => {
    nextModal(oktaSlideMap.oktaDomain);
  };
  return (
    <Container>
      <div className="welcome-modal_first-slide b-pad3">
        <img src={oktaIcon} className="b-mb3" alt="header-icons" />
        <StyledSlideHeader>Welcome to Okta Device Trust</StyledSlideHeader>
        <p className="b-txt-light">
          The Okta Device Trust integration enables organizations to protect
          their corporate resources and ensures that devices are compliant
          before allowing access to applications through Okta.
        </p>
        {tickets.map((ticket) => (
          <Ticket
            key={ticket.title}
            icon={ticket.icon}
            text={ticket.text}
            title={ticket.title}
            link={ticket.link}
          />
        ))}
      </div>
      <FooterButtons
        onClose={onClose}
        handleNext={handleNext}
        nextButtonText="Get started"
      />
    </Container>
  );
};

export default Welcome;
