import type React from 'react';

import type { ButtonProps } from '@kandji-inc/nectar-ui';
import { Button } from '@kandji-inc/nectar-ui';

function XmarkButton(props: {
  onClick: () => void;
  onHover: (isHovering: boolean) => void;
  variant?: ButtonProps['variant'];
  ariaLabel?: string;
  size?: string;
  style?: React.CSSProperties;
}) {
  const {
    onClick,
    onHover,
    variant = 'default',
    ariaLabel = 'delete-button',
    size = 'var(--sizes-2)',
    style = {},
  } = props;
  return (
    <Button
      onClick={onClick}
      onMouseEnter={() => onHover(true)}
      onMouseLeave={() => onHover(false)}
      variant={variant}
      aria-label={ariaLabel}
      icon={{
        name: 'xmark',
      }}
      css={{
        padding: '$1',
        '& > svg': {
          height: size,
          width: size,
        },
      }}
      style={style}
    />
  );
}

export default XmarkButton;
