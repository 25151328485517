import { Button } from '@kandji-inc/bumblebee';
import { i18n } from 'i18n';
import { func } from 'prop-types';
import styled from 'styled-components';

const ButtonContainer = styled.div`
  display: flex;
  gap: var(--b-gap);
`;
const Footer = (props) => {
  const { onCancel, onDelete } = props;
  return (
    <ButtonContainer>
      <Button kind="outline" onClick={onCancel}>
        {i18n.t('Cancel')}
      </Button>
      <Button theme="error" onClick={onDelete}>
        {i18n.t('Delete')}
      </Button>
    </ButtonContainer>
  );
};

export default Footer;

Footer.propTypes = {
  onCancel: func.isRequired,
  onDelete: func.isRequired,
};
