import { Button } from '@kandji-inc/bumblebee';
import { i18n } from 'i18n';
import { string } from 'prop-types';
import styled from 'styled-components';
import {
  CardContainer,
  CardSection,
  CardTitle,
  StyledOptionsMenu,
} from '../../styled-components';

const IntIcon = styled.img`
  margin: 2px 0;
  width: 48px;
  height: 48px;
`;

const StyledCardTitle = styled(CardTitle)`
  & > ${StyledOptionsMenu} {
    margin-left: auto;
  }
`;

const SummaryCard = ({ imgSrc, title, description, onDeleteIntegration }) => (
  <CardContainer>
    <StyledCardTitle>
      <IntIcon src={imgSrc} alt={i18n.t('microsoft teams icon')} />
      <h1 className="b-h1 b-ml">{title}</h1>

      <StyledOptionsMenu
        render={(close) => (
          <>
            {/* TODO: Implement Add teams account after release of MS Teams */}
            {/* {reAuthIsAvailable && ( */}
            {/* <Button
              icon='plus-circle'
              kind='link'
              onClick={() => {
                onAddTeamAccount();
                close();
              }}
            >
              Add Teams account
            </Button> */}
            {/* )} */}

            <Button
              icon="trash-can"
              theme="error"
              kind="link"
              onClick={() => {
                close();
                onDeleteIntegration();
              }}
            >
              {i18n.t('Delete integration')}
            </Button>
          </>
        )}
      >
        <div data-testid="summary-card:ellipsis-btn">
          <Button icon="ellipsis" kind="link" />
        </div>
      </StyledOptionsMenu>
    </StyledCardTitle>
    <CardSection className="b-txt">
      <p>{description}</p>
    </CardSection>
  </CardContainer>
);

SummaryCard.propTypes = {
  imgSrc: string.isRequired,
  title: string.isRequired,
  description: string.isRequired,
};

export default SummaryCard;
