/* istanbul ignore file - writing tests for edges requires additional overhead in relation to the DOM and SVGs, revisit later [https://reactflow.dev/docs/guides/testing/] */

import React, { memo } from 'react';
import type { EdgeProps } from 'reactflow';
import { BaseEdge } from 'reactflow';
import useBlueprintFlow from '../store';
import { getDevicePathStyle } from '../theme';

function PlaceholderEdge(props: EdgeProps) {
  const { id, data, style = {} } = props;
  const [isEditingAssignments, { device }] = useBlueprintFlow((state) => [
    state.isEditingAssignments,
    state.selectedDevice,
  ]);

  const path = data?.path;
  const isShowingDevicePath = !isEditingAssignments && device;

  return (
    <BaseEdge
      id={id}
      path={path}
      style={{
        fill: 'none',
        stroke: 'var(--colors-neutral80)',
        strokeWidth: '1',
        ...style,
        ...(isShowingDevicePath && getDevicePathStyle(false).edge),
      }}
    />
  );
}

export default memo(PlaceholderEdge);
