import { modal as Modal } from '@kandji-inc/bumblebee';
import { bool, func, object } from 'prop-types';
import { i18n } from 'src/i18n';
import Content from './components/content';
import Footer from './components/footer';

const AccountDeleteModal = (props) => {
  const { isOpen, data, onDelete, onCancel, isLastIntegration } = props;
  const tenantName = data.tenant_name;
  const notificationCount = data.notifications?.length ?? 0;

  return (
    <Modal
      isOpen={isOpen}
      withoutCloseButton
      disableCloseOnOutsideClick
      parts={{
        header: {
          text: i18n.t('Delete Microsoft Teams account'),
        },
        content: {
          children: (
            <Content
              data={data}
              name={tenantName}
              isLastIntegration={isLastIntegration}
              notificationCount={notificationCount}
            />
          ),
        },
        footer: {
          children: (
            <Footer
              onCancel={onCancel}
              onDelete={() => {
                onDelete(data.integration_id);
              }}
            />
          ),
        },
      }}
    />
  );
};

export default AccountDeleteModal;

AccountDeleteModal.propTypes = {
  isOpen: bool.isRequired,
  data: object.isRequired,
  onDelete: func.isRequired,
  onCancel: func.isRequired,
  isLastIntegration: bool.isRequired,
};
