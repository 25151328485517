import React, { useState, useEffect } from 'react';

import {
  Button,
  Checkbox,
  Flex,
  Select,
  TextInput,
} from '@kandji-inc/bumblebee';

import { i18n } from 'i18n';

import { STATUS_CONNECTED } from 'features/integrations/components-for-specific-types/adcs/status';
import { Controls, Title } from 'features/library-items/template';
import { useAuthority, useConnector, useIs, usePanel } from '../../hooks';

import { findOption, includeByProperty } from '../../util';

import Panel from '..';
import Header from '../header';

const PanelDeleteServer = () => {
  const {
    authority,
    authorityItem,
    authorityItemLibraryItemList,
    clearAuthority,
    clearAuthorityItem,
    clearAuthorityItemLibraryItemList,
    fetchAuthorityItem,
    fetchAuthorityItemLibraryItemList,
    fetchAuthorityList,
    deleteAuthorityItem,
  } = useAuthority();

  const { connectorList, fetchConnectorList } = useConnector();

  const { isLoading, isWorking, hasChecked, setHasChecked } = useIs();
  const { panel, openPanel, closePanel } = usePanel();

  const isOpen = !!panel['delete-server'];

  const [agreed, setAgreed] = useState(false);

  const { name, connector } = authorityItem;

  const filteredConnectorList = includeByProperty(
    connectorList,
    'status',
    STATUS_CONNECTED,
  );

  /* istanbul ignore next */
  const optionsConnectors = [
    {
      value: null,
      label: i18n.t('Not assigned'),
    },
    ...filteredConnectorList?.map(
      ({ id: value, name: connectorName, bound_domain }) => ({
        label:
          connectorName ||
          bound_domain ||
          `${i18n.t('Connector details are pending')}...`,
        value,
      }),
    ),
  ];

  const valueConnector = findOption(connector, optionsConnectors);

  /* istanbul ignore next */
  const handleAgreed = () => setAgreed(!agreed);

  /* istanbul ignore next */
  const reset = () => {
    clearAuthority();
    clearAuthorityItem();
    clearAuthorityItemLibraryItemList();
    setAgreed(false);
    setHasChecked(false);
  };

  /* istanbul ignore next */
  const openPanelHandleConflictLibraryItem = () => {
    closePanel(() => openPanel('handle-conflict-library-item'));
  };

  /* istanbul ignore next */
  const onCancel = () => {
    closePanel(reset);
  };

  /* istanbul ignore next */
  const onDelete = async () => {
    await deleteAuthorityItem(authority);
    fetchAuthorityList();
    fetchConnectorList();
    closePanel(reset);
  };

  /* istanbul ignore next */
  useEffect(() => {
    if (isOpen && authority) {
      fetchAuthorityItem();
      fetchAuthorityItemLibraryItemList();
      fetchConnectorList();
    }
  }, [isOpen, authority]);

  /* istanbul ignore next */
  useEffect(() => {
    if (isOpen && authorityItemLibraryItemList?.length > 0) {
      openPanelHandleConflictLibraryItem();
    }
  }, [isOpen, authorityItemLibraryItemList]);

  useEffect(
    () => () => {
      reset();
    },
    [],
  );

  /* istanbul ignore next */
  return hasChecked && authorityItemLibraryItemList?.length === 0 ? (
    <Panel name="delete-server">
      <Header title={i18n.t('Delete AD CS server')} />

      <div className="b-side-panel-layout__body">
        <div className="k-section-primary">
          <h4 className="b-h4 b-mb1">
            {i18n.t('Are you sure you want to delete this server?')}
          </h4>

          <p className="b-txt">
            {i18n.t(
              'The server will be permanently removed and unassigned from the following connector. This action cannot be undone.',
            )}
          </p>

          <div className="k-section-secondary b-mt">
            <Title>
              <p className="b-txt b-mb1">
                {i18n.t('Server name and connector assigment')}
              </p>
            </Title>

            <Controls>
              <TextInput
                className="b-mb"
                placeholder={i18n.t('Enter the server name')}
                value={name}
                onChange={
                  /* istanbul ignore next */
                  () => {}
                }
                disabled
                readOnly
              />

              <Select
                className="k-adcs-select-disabled"
                placeholder={i18n.t('Select connector from list')}
                options={optionsConnectors}
                value={valueConnector}
                onChange={
                  /* istanbul ignore next */
                  () => {}
                }
                disabled
              />
            </Controls>
          </div>
        </div>

        <div className="k-section-primary k-section-border-top">
          <Controls>
            <Checkbox
              className="k-adcs-agreed"
              label={i18n.t(
                'I have read the above information and understand this action cannot be undone.',
              )}
              checked={agreed}
              onChange={handleAgreed}
              disabled={isLoading || isWorking}
            />
          </Controls>
        </div>
      </div>

      <div className="b-side-panel-layout__footer">
        <Flex justify="flex-end">
          <div className="b-grid-ctas">
            <Button
              kind="outline"
              onClick={onCancel}
              isDisabled={isLoading || isWorking}
            >
              {i18n.t('Cancel')}
            </Button>

            <Button
              theme="error"
              icon={isWorking ? 'arrows-rotate' : undefined}
              onClick={
                /* istanbul ignore next */
                agreed ? onDelete : () => {}
              }
              isDisabled={isLoading || isWorking || !agreed}
              isProgress={isWorking}
            >
              {i18n.t('Delete')}
            </Button>
          </div>
        </Flex>
      </div>
    </Panel>
  ) : null;
};

export default PanelDeleteServer;
