/* istanbul ignore file */
import { Checkbox } from '@kandji-inc/bumblebee';
import { Setting } from 'features/library-items/template';
import { i18n } from 'i18n';
import { useEffect } from 'react';
import AutomaticAppService from './service/automatic-app-service';

const UpdateOnlyInstallationOption = (props) => {
  const { setting, update, isDisabled } = props;

  useEffect(() => {
    if (
      setting.type !==
      AutomaticAppService.installationTypes.CONTINUOUSLY_ENFORCE
    ) {
      update('updates_on_pre_installed_versions_only', false);
    }
  }, [setting]);

  if (
    setting.type !== AutomaticAppService.installationTypes.CONTINUOUSLY_ENFORCE
  ) {
    return null;
  }

  return (
    <Setting.SecondaryControls>
      <Setting.SecondaryControlsRow>
        <Setting.Controls>
          <Checkbox
            checked={setting.updates_on_pre_installed_versions_only}
            label={i18n.t('Update Only')}
            onChange={() =>
              update('updates_on_pre_installed_versions_only', (p) => !p)
            }
            isDisabled={isDisabled}
          />
        </Setting.Controls>
        <Setting.Helpers>
          <p className="b-txt-light">
            {i18n.t(
              "This option enforces updates for this application if it's already installed, but does not install it on any new computers. It is also not made available in Self Service.",
            )}
          </p>
        </Setting.Helpers>
      </Setting.SecondaryControlsRow>
    </Setting.SecondaryControls>
  );
};

export default UpdateOnlyInstallationOption;
