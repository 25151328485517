import { TextInput } from '@kandji-inc/bumblebee';
import { bool, func, oneOf, shape, string } from 'prop-types';
import React from 'react';

import initialState from '../../initial-state';
import { requiredField } from '../../input-validations';
import CategoryIcon from './category-icon';
import IconSearchSelect from './icon-search-select';

import { STATUSES } from '../constants';

const AddEditInputs = ({
  isAdd,
  addEditCategory,
  update,
  checkDisableSave,
  status,
}) => (
  <>
    <TextInput
      value={addEditCategory.name}
      onChange={(e) => update({ key: 'name', value: e.target.value })}
      fieldsGrid
      label="Name"
      maxLength={64}
      onBlur={() => checkDisableSave()}
      placeholder={isAdd ? 'Add category name' : ''}
      validator={requiredField}
      runValidationOn={[status]}
    />
    <TextInput
      value={addEditCategory.description}
      onChange={(e) => update({ key: 'description', value: e.target.value })}
      textArea
      fieldsGrid
      label="Description"
      isOptional
      maxLength={120}
      placeholder={isAdd ? 'Provide a short description' : ''}
    />
    <div>
      <CategoryIcon iconImg={addEditCategory.iconImg} />
      <IconSearchSelect selectedIcon={addEditCategory.icon} update={update} />
    </div>
  </>
);

AddEditInputs.propTypes = {
  addEditCategory: shape({
    id: string,
    icon: string,
    iconImg: string,
    name: string,
    description: string,
  }),
  update: func,
  checkDisableSave: func,
  isAdd: bool,
  status: oneOf(Object.values(STATUSES)),
};

AddEditInputs.defaultProps = {
  addEditCategory: initialState.adddEditCategory,
  update: () => {},
  checkDisableSave: () => {},
  isAdd: false,
  status: STATUSES.IDLE,
};

export default AddEditInputs;
