import React from 'react';

import { Button, Dialog, Flex } from '@kandji-inc/nectar-ui';

type ConfirmNavigationProps = {
  isOpen: boolean;
  toggle: (boolean) => void;
  onConfirm: () => void;
};

function ConfirmNavigation(props: ConfirmNavigationProps) {
  const { isOpen, toggle, onConfirm } = props;

  const footer = (
    <Flex gap="md" justifyContent="end" wrap="wrap">
      <Button variant="subtle" onClick={() => toggle(false)} compact>
        Stay on page
      </Button>

      <Button
        compact
        variant="primary"
        onClick={() => {
          onConfirm();
          toggle(false);
        }}
      >
        Leave page
      </Button>
    </Flex>
  );

  return (
    <Dialog
      title="Leave this page?"
      content="Changes you made will not be saved."
      footer={footer}
      isOpen={isOpen}
      onOpenChange={() => toggle(false)}
      css={{
        width: '560px',
        zIndex: 2000,
        '& > :last-child': {
          borderTop: 'unset',
        },
      }}
    />
  );
}

export default ConfirmNavigation;
