/* istanbul ignore file */
import { Flex, styled, theme } from '@kandji-inc/nectar-ui';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';

const statementLineHeight = '18px';

const nodePadding = '10px';

const getBackgroundColor = (isOver: boolean, isError: boolean) => {
  if (isOver) {
    return 'var(--colors-blue10)';
  }

  if (isError) {
    return 'var(--colors-red10)';
  }

  return '';
};

const styleNode = (isOver: boolean, isError?: boolean) => ({
  backgroundColor: getBackgroundColor(isOver, isError),
  borderColor: isError ? 'var(--colors-red50)' : '',
  padding: '0',
  width: '100%',
  cursor: 'default',
  overflow: 'hidden',
});

const FlowTippy = styled(Tippy, {
  '& > .tippy-content': {
    paddingBottom: 0,
  },

  '& > .tippy-arrow': {
    color: '$neutral90',
  },
});

const getDevicePathStyle = (isOnDevicePath: boolean, isHoverable = true) => {
  if (isOnDevicePath) {
    return {
      preStartNode: {},
      node: {
        border: `2px solid ${theme.colors.blue50.value}`,
      },
      edge: {
        strokeWidth: '3px',
        stroke: theme.colors.blue60.value,
      },
      router: {
        background: theme.colors.blue60.value,
        borderRadius: '0',
        height: '3px',
        minHeight: 'unset',
        border: `0px solid ${theme.colors.blue60.value}`,
      },
    };
  }

  return {
    preStartNode: {
      opacity: 0.2,
    },
    node: {
      opacity: 0.2,

      '&:hover, & *': {
        opacity: isHoverable ? 'unset' : '0.2',
      },
    },
    edge: {
      stroke: theme.colors.neutral100.value,
      opacity: 0.2,
    },
    router: {
      opacity: 0.2,
      background: theme.colors.neutral100.value,
      borderRadius: '0',
      height: '3px',
      minHeight: 'unset',
      border: `0px solid ${theme.colors.neutral100.value}`,
    },
  };
};

const TippyContainer = styled(Flex, {
  padding: '6px 8px',
  borderRadius: '4px',
  background: '$neutral90',
  color: '$neutral0',
  zIndex: 9999,
});

export {
  statementLineHeight,
  nodePadding,
  styleNode,
  getDevicePathStyle,
  FlowTippy,
  TippyContainer,
};
