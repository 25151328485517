import { modal as Modal } from '@kandji-inc/bumblebee';
import { makeFieldUpdater } from 'features/util/misc';
import { i18n } from 'i18n';
import { bool, func } from 'prop-types';
import React, { useState, useEffect } from 'react';
import Carousel from '../../../../util/components/carousel';
import { INT_TYPES, TYPE_PROP_TYPE } from '../../../constants';
import configurations from '../../configurations';
import Ticket from './ticket';
import './welcome-modal.styles.scss';

import useModal from '../../../hooks/use-modal';

const MODAL_WIDTH = 960;

function WelcomeModal({
  onClose,
  type,
  isOpen = false,
  externalTrigger,
  noRedirectOnClose,
  deleteIntegrationOnCancel,
}) {
  const {
    title,
    welcomeModal: {
      initialState,
      extraSlides,
      headerIcons,
      description,
      tickets,
      FooterButtons,
    },
  } = configurations[type];

  const { modal, modalSlideIdx, closeModal } = useModal();
  const isModalOpen = externalTrigger ? !!modal[INT_TYPES.adcs] : isOpen;

  const [modalState, setModalState] = useState(initialState);
  const updateField = makeFieldUpdater(setModalState);
  const [currentStepIdx, setCurrentStepIdx] = useState(0);

  function closeCompletely() {
    setModalState(initialState);
    if (externalTrigger) {
      closeModal();
    } else {
      setCurrentStepIdx(0);
      onClose();
    }
  }
  useEffect(() => {
    if (externalTrigger) {
      setCurrentStepIdx(modalSlideIdx);
    }
  }, [modalSlideIdx]);

  const props = { ...modalState, updateField };

  return (
    <Modal
      isOpen={isModalOpen}
      onClose={closeCompletely}
      withoutCloseButton
      disableCloseOnOutsideClick
      parts={{
        content: {
          children: (
            <Carousel
              width={MODAL_WIDTH}
              slides={[
                <div className="welcome-modal_first-slide b-pad3">
                  <img src={headerIcons} className="b-mb2" alt="header-icons" />
                  <h1 className="b-h1 b-mb1">
                    {i18n.t('Welcome to {title}', { title })}
                  </h1>
                  <p className="b-txt-light">{description}</p>
                  {tickets.map((ticket, idx) => (
                    <Ticket
                      key={idx}
                      icon={ticket.icon}
                      text={ticket.text}
                      title={ticket.title}
                      link={ticket.link}
                    />
                  ))}
                </div>,
                ...(extraSlides || []).map((SlideComponent, idx) => (
                  <SlideComponent
                    key={SlideComponent.name}
                    {...props}
                    isActive={currentStepIdx === idx + 1}
                  />
                )),
              ]}
              currentSlideIdx={currentStepIdx}
            />
          ),
          className: 'welcome-modal_content',
        },

        footer: {
          className: 'cmn-modal_footer--narrow',
          children: (
            <FooterButtons
              {...props}
              currentStepIdx={currentStepIdx}
              setCurrentStepIdx={setCurrentStepIdx}
              onClose={closeCompletely}
              type={type}
              noRedirectOnClose={noRedirectOnClose}
              deleteIntegrationOnCancel={deleteIntegrationOnCancel}
            />
          ),
        },
      }}
    />
  );
}

WelcomeModal.propTypes = {
  onClose: func.isRequired,
  type: TYPE_PROP_TYPE,
  isOpen: bool,
  externalTrigger: bool,
  noRedirectOnClose: bool,
  deleteIntegrationOnCancel: bool,
};

WelcomeModal.defaultProps = {
  type: null,
  isOpen: false,
  externalTrigger: false,
  noRedirectOnClose: false,
  deleteIntegrationOnCancel: false,
};

export default WelcomeModal;
