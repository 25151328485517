import React from 'react';

import CompanySettings from '../../features/company-settings';
import DeviceDomains from '../../features/device-domains';

export default () => (
  <div className="k-tab">
    <CompanySettings>
      <DeviceDomains />
    </CompanySettings>
  </div>
);
