import React from 'react';

import { Button } from '@kandji-inc/nectar-ui';
import { i18n } from 'i18n';

type Props = Readonly<{
  onClick: () => void;
  isDisabled: boolean;
  cancelButtonText?: string;
}>;

const CancelButton = (props: Props) => {
  const { onClick, isDisabled, cancelButtonText = i18n.t('Cancel') } = props;
  return (
    <Button variant="subtle" onClick={onClick} disabled={isDisabled}>
      {cancelButtonText}
    </Button>
  );
};

export default CancelButton;
