import { Button, Select, TextInput } from '@kandji-inc/bumblebee';
import deepcopy from 'deepcopy';
/* istanbul ignore file */
import React, { useEffect, useState } from 'react';

const requiredValidator = (v) => [
  {
    message: 'Required',
    invalid: () => !v,
    trigger: ['onBlur'],
  },
];

const pathValidator = (v, watch) => [
  {
    message: 'Path must start with /',
    invalid: () => !v.startsWith('/'),
    trigger: ['onBlur', 'onInput', ...watch],
  },
];

const developerValidator = (v, watch) => [
  {
    message: 'Invalid developer id',
    invalid: () => v.match(/^[a-z0-9]+$/i),
    trigger: ['onBlur', 'onInput', ...watch],
  },
];

const bundleValidator = (v, watch) => [
  {
    message: 'Invalid bundle id',
    // Per Kandji (and Apple) documentation, Bundle IDs can only contain alphanumeric characters, hyphens, and periods.
    // https://support.kandji.io/support/solutions/articles/72000559831
    invalid: () => !v.match(/^[a-zA-Z0-9-.]+$/i),
    trigger: ['onBlur', 'onInput', ...watch],
  },
];

const defaultData = {
  message_customization: {
    button_title: 'Learn More',
    message:
      'The following application has been blocked by your organization. For more information, please contact your IT administrator.',
    url: 'https://support.kandji.io/support/solutions/articles/72000559795',
  },
};

const rows = [
  {
    key: 'by_process',
    dataKey: 'process_name',
    title: 'By Process',
    textLabel: 'Process identifier',
    validator: requiredValidator,
  },
  {
    key: 'by_path',
    dataKey: 'full_path_to_app',
    title: 'By Path',
    textLabel: 'Full path to app',
    validator: (v, disable, watch) => [
      ...requiredValidator(v, watch),
      ...(disable ? [] : pathValidator(v, watch)),
    ],
  },
  {
    key: 'by_developer_id',
    dataKey: 'developer_id',
    title: 'By Developer ID',
    textLabel: 'Developer ID',
    noMatch: true,
    validator: (v, disable, watch) => [
      ...requiredValidator(v, watch),
      ...(disable ? [] : developerValidator(v, watch)),
    ],
  },
  {
    key: 'by_bundle_id',
    dataKey: 'bundle_identifier',
    title: 'By Bundle ID',
    textLabel: 'Bundle identifier',
    validator: (v, disable, watch) => [
      ...requiredValidator(v, watch),
      ...(disable ? [] : bundleValidator(v, watch)),
    ],
  },
];

const matchOptions = [
  { label: 'Contains', value: 'contains' },
  { label: 'Exact', value: 'exact' },
  { label: 'Regex', value: 'regex' },
];

const Row = ({
  data,
  title,
  textLabel,
  textKey,
  isDisabled,
  onAdd,
  onChange,
  noMatch,
  onTrash,
  validator,
  onInvalidate,
  validation,
}) => {
  const [validateRow, isRowInvalid, getRowKey] = validation;
  return (
    <div className="b-flex-col b-mb2">
      <h3 className={`b-h3 ${isDisabled ? 'b-txt-light2' : ''} b-mb1`}>
        {title}
      </h3>
      {data?.map((d, idx) => {
        const selectedMatch =
          (!noMatch && matchOptions.find(({ value }) => value === d.type)) ||
          matchOptions[0];

        return (
          <div key={idx} className="b-flex-g1 b-mb3">
            <div className="b-flex1">
              <TextInput
                fieldsGrid
                label={textLabel}
                aria-label={title}
                disabled={isDisabled}
                value={d[textKey]}
                onChange={(e) =>
                  onChange(e.target.value, selectedMatch.value, idx)
                }
                onInvalidate={(...args) => onInvalidate(...args, idx)}
                validator={(v) =>
                  validator(
                    v,
                    // String validation should be disabled if the user wants to block an application via regex
                    noMatch || selectedMatch.value === 'regex',
                    [],
                  )
                }
                {...validateRow.syncInvalid(isRowInvalid, {
                  key: getRowKey(idx),
                })}
              />
            </div>
            {!noMatch && (
              <div className="b-flex1">
                <p className={`b-txt${isDisabled ? '-light' : ''} b-mb1`}>
                  Match type:
                </p>
                <Select
                  disabled={isDisabled}
                  options={matchOptions}
                  value={selectedMatch}
                  onChange={({ value }) => onChange(d[textKey], value, idx)}
                />
              </div>
            )}
            <Button
              className="bl-p-align-self-end"
              disabled={isDisabled}
              kind="link"
              icon="trash-can"
              theme="error"
              onClick={() => onTrash(idx)}
            />
          </div>
        );
      })}
      <Button
        className="bl-p-align-self-start"
        disabled={isDisabled}
        icon="plus"
        isWide
        onClick={onAdd}
      >
        Add
      </Button>
    </div>
  );
};

const ApplicationBlacklisting = (props) => {
  const { param, update, isDisabled, validations } = props;
  const { details } = param;
  const [value, setValue] = useState(details || deepcopy(defaultData));
  const [newInvalids, setNewInvalids] = useState({
    by_process: [],
    by_path: [],
    by_developer_id: [],
    by_bundle_id: [],
    message: true,
    buttonTitle: true,
    buttonUrl: true,
  });
  const isAtLeastOneRowSelected = !rows.some(({ key }) => value[key]?.length);

  const {
    ApplicationBlacklisting: validateParam,
    ApplicationBlacklisting_byProcess: validateByProcess,
    ApplicationBlacklisting_byPath: validateByPath,
    ApplicationBlacklisting_byDeveloperId: validateByDeveloperId,
    ApplicationBlacklisting_byBundleId: validateByBundleId,
    ApplicationBlacklisting_customizationMessage: validateCustomizationMessage,
    ApplicationBlacklisting_customizationTitle: validateCustomizationTitle,
    ApplicationBlacklisting_customizationUrl: validateCustomizationUrl,
  } = validations;

  const getRowValidation = (rowKey, rowNum) => {
    const isRowInvalid = Boolean(newInvalids[rowKey].filter(Boolean).length);
    const getRowKey = (rowIdx) => `${rowKey}-${rowIdx}`;

    return (
      {
        by_bundle_id: [validateByBundleId, isRowInvalid, getRowKey],
        by_process: [validateByProcess, isRowInvalid, getRowKey],
        by_path: [validateByPath, isRowInvalid, getRowKey],
        by_developer_id: [validateByDeveloperId, isRowInvalid, getRowKey],
      }[rowKey] ?? [undefined, undefined]
    );
  };

  useEffect(() => {
    if (isDisabled) {
      setValue(param.details || deepcopy(defaultData));
      setNewInvalids(() => ({
        by_process: param.details?.by_process?.map(() => false) || [],
        by_path: param.details?.by_path?.map(() => false) || [],
        by_developer_id: param.details?.by_developer_id?.map(() => false) || [],
        by_bundle_id: param.details?.by_bundle_id?.map(() => false) || [],
        message: false,
        buttonTitle: false,
        buttonUrl: false,
      }));
    }
  }, [param, isDisabled]);

  useEffect(() => {
    if (!isDisabled) {
      const time = setTimeout(() => {
        // LIT-1284. Existing items with this field that have a type field need
        // to be handled. It's only for this case, and with Parameters going
        // extinct, this works for now.
        /* istanbul ignore next */
        value['by_developer_id']?.forEach((k, idx) => {
          if (k?.type) {
            delete value['by_developer_id'][idx].type;
          }
        });

        update({
          details: value,
          isInvalid:
            Object.values(newInvalids).some((v) =>
              Array.isArray(v) ? v.filter(Boolean).length : v,
            ) || isAtLeastOneRowSelected,
        });
      }, 250);
      return () => clearTimeout(time);
    }
  }, [value, newInvalids, isDisabled]);

  const onAdd = (key, d) => () => {
    setValue((prev) => ({
      ...prev,
      [key]: [...(prev[key] || []), d],
    }));
    setNewInvalids((prev) => ({ ...prev, [key]: [...prev[key], true] }));
  };

  const onTrash = (key, idx) => {
    setValue((prev) => {
      const f = {
        ...prev,
        [key]: prev[key].filter((_, i) => i !== idx),
      };

      if (!f[key].length) {
        delete f[key];
      }

      return { ...f };
    });
    setNewInvalids((prev) => ({
      ...prev,
      [key]: prev[key].filter((_, i) => i !== idx),
    }));
  };

  return (
    <div className="">
      {rows.map((row, idx) => {
        return (
          <Row
            key={row.key}
            data={value[row.key]}
            textLabel={row.textLabel}
            title={row.title}
            textKey={row.dataKey}
            isDisabled={isDisabled}
            noMatch={row.noMatch}
            onChange={(text, match, idx) =>
              setValue((prev) => {
                prev[row.key][idx] = {
                  [row.dataKey]: text,
                  /* Every field has a type that is an element of matchOptions.
                  In some cases, the noMatch property is defined that indicates
                  there is no `type` field required.  */
                  ...(!row.noMatch && match ? { type: match } : {}),
                };
                return { ...prev };
              })
            }
            validator={row.validator}
            onInvalidate={(isInvalid, i) =>
              setNewInvalids((prev) => ({
                ...prev,
                [row.key]: prev[row.key].map((inv, invI) =>
                  invI === i ? isInvalid : inv,
                ),
              }))
            }
            onAdd={onAdd(row.key, {
              [row.dataKey]: '',
              /* istanbul ignore next */
              ...(row.noMatch ? {} : { type: matchOptions[0].value }),
            })}
            onTrash={(idx) => onTrash(row.key, idx)}
            validation={getRowValidation(row.key, idx)}
          />
        );
      })}

      {validateParam.displayInvalid(isAtLeastOneRowSelected)}

      <div className="b-flex-vg3">
        <TextInput
          fieldsGrid
          label={'Message to display when an app is blocked'}
          disabled={isDisabled}
          value={value.message_customization.message}
          validator={requiredValidator}
          onInvalidate={(isInvalid) =>
            setNewInvalids((prev) => ({ ...prev, message: isInvalid }))
          }
          onChange={(e) => {
            const val = e.target.value;
            setValue((prev) => ({
              ...prev,
              message_customization: {
                ...prev.message_customization,
                message: val,
              },
            }));
          }}
          {...validateCustomizationMessage.syncInvalid(newInvalids.message)}
        />
        <TextInput
          fieldsGrid
          label={'More info button title'}
          disabled={isDisabled}
          value={value.message_customization.button_title}
          validator={requiredValidator}
          onInvalidate={(isInvalid) =>
            setNewInvalids((prev) => ({ ...prev, buttonTitle: isInvalid }))
          }
          onChange={(e) => {
            const val = e.target.value;
            setValue((prev) => ({
              ...prev,
              message_customization: {
                ...prev.message_customization,
                button_title: val,
              },
            }));
          }}
          {...validateCustomizationTitle.syncInvalid(newInvalids.buttonTitle)}
        />
        <TextInput
          fieldsGrid
          label={'More info button url'}
          disabled={isDisabled}
          value={value.message_customization.url}
          validator={requiredValidator}
          onInvalidate={(isInvalid) =>
            setNewInvalids((prev) => ({ ...prev, buttonUrl: isInvalid }))
          }
          onChange={(e) => {
            const val = e.target.value;
            setValue((prev) => ({
              ...prev,
              message_customization: {
                ...prev.message_customization,
                url: val,
              },
            }));
          }}
          {...validateCustomizationUrl.syncInvalid(newInvalids.buttonUrl, {
            imperativeHandleElement: (inputEl) => {
              const MAX_RAF_DURATION_MS = 500;
              let start;
              let cancelId;

              const rafCb = (timestamp) => {
                start = start ?? timestamp;
                const elapsed = timestamp - start;
                const inputContainerEl = inputEl.closest('div.b-txt-input');
                const isUnderMaxDuration = elapsed < MAX_RAF_DURATION_MS;

                if (!inputContainerEl && isUnderMaxDuration) {
                  requestAnimationFrame(rafCb);
                } else {
                  const matchesError = inputContainerEl.matches(
                    '.b-txt-input--error-state',
                  );

                  if (!matchesError) {
                    requestAnimationFrame(rafCb);
                    inputContainerEl.style.marginBottom = '';
                  } else {
                    inputContainerEl.style.marginBottom = 'var(--b-gap2)';
                  }
                }
              };

              requestAnimationFrame(rafCb);
            },
          })}
        />
      </div>
    </div>
  );
};

export default ApplicationBlacklisting;
