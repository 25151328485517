import React, { useCallback } from 'react';
import Ticket from 'src/features/integrations/components/ticket';
import useEscapeKey from 'src/features/integrations/hooks/use-escapekey';
import { useModalState } from 'src/features/integrations/hooks/use-modal-state';
import styled from 'styled-components';
import { buildDeepLink } from '../utils/build-deep-link';

import FooterButtons from '../../../components/footer-buttons';
import {
  Container,
  ModalContent,
  StyledSlideHeader,
} from '../../../components/styled-components';
import useModal from '../../../hooks/use-modal';
import oktaLogo from '../assets/okta.svg';
import oktaBtnSm from './assets/okta-button-sm.svg';
import { oktaSlideMap } from './okta-slide-map';
import { oktaSignInTickets } from './tickets';

const TicketContainer = styled.div`
  text-align: left;
`;

const SignIn = () => {
  const { nextModal, previousModal } = useModal();
  const { state: modalState } = useModalState();

  const { oktaDomain } = modalState;

  const onClose = useCallback(() => {
    nextModal(oktaSlideMap.cancelConfirm);
  }, [nextModal]);

  const handleNext = () => {
    const deepLink = buildDeepLink(oktaDomain);

    window.open(deepLink, '_blank', 'noreferrer');
    nextModal(oktaSlideMap.completeTasks);
  };
  const handleBack = () => previousModal();

  useEscapeKey(onClose);

  return (
    <Container>
      <ModalContent>
        <img src={oktaLogo} className="b-mb2" alt="header-icons" />
        <StyledSlideHeader>Sign in with Okta</StyledSlideHeader>
        <p className="b-txt-light">
          Sign in to your Okta account to establish the connection between your
          Kandji and Okta administrative accounts.
        </p>
        <TicketContainer>
          {oktaSignInTickets.map((ticket, i) => (
            <Ticket
              key={ticket.title}
              title={ticket.title}
              text={ticket.text}
              link={ticket.link}
              icon={ticket.icon}
              testId={`sign-in-${i}`}
            />
          ))}
        </TicketContainer>
      </ModalContent>
      <FooterButtons
        onClose={onClose}
        handleNext={handleNext}
        cancelButtonText="Cancel setup"
        backOnClick={handleBack}
        showBackButton
        textIcon={oktaBtnSm}
        nextButtonText="Sign in with Okta"
        showStepper
        stepperIdx={1}
        stepsAmount={6}
      />
    </Container>
  );
};

export default SignIn;
