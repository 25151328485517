import './style.css';

import { Button, TextInput, Toaster as toaster } from '@kandji-inc/bumblebee';
import cn from 'classnames';
import PropTypes from 'prop-types';

import React from 'react';

const copyToClipboard = (value) => navigator.clipboard.writeText(value);

const TextInputReadOnly = ({ value, toast, className }) => {
  const onClick = () => {
    copyToClipboard(value);
    toaster(toast);
  };

  return (
    <div className={cn('k-text-input-readonly b-flex', className)}>
      <TextInput value={value} readOnly />
      <Button kind="link" icon="copy" onClick={onClick} />
    </div>
  );
};

TextInputReadOnly.propTypes = {
  value: PropTypes.string.isRequired,
  toast: PropTypes.string.isRequired,
};

export default TextInputReadOnly;
