import { i18n } from 'i18n';
import { INT_TYPES } from './constants';

export const integrationTitleMap = i18n.createMap({
  [INT_TYPES.slack]: () => i18n.t('Slack'),
  // [INT_TYPES.msTeams]: () => i18n.t('Microsoft Teams'),
  [INT_TYPES.gsuite]: () => i18n.t('Google Workspace'),
  // [INT_TYPES.scim]: () => i18n.t('SCIM'),
  // [INT_TYPES.office365]: () => i18n.t('Microsoft Entra ID'),
  [INT_TYPES.adcs]: () => i18n.t('Active Directory Certificate Services'),
  // [INT_TYPES.okta]: () => i18n.t('Okta Device Trust'),
});

/* istanbul ignore next */
export const i18nAdcsStatusMap = i18n.createMap({
  Connected: () => i18n.t('Connected'),
  Disconnected: () => i18n.t('Disconnected'),
  Pending: () => i18n.t('Pending'),
});

export const i18nAdcsWelcomeNextButtonTextMap = i18n.createMap({
  'Get started': () => i18n.t('Get started'),
  'Download connector': () => i18n.t('Download connector'),
  Close: () => i18n.t('Close'),
});

export const i18nAdcsTicketTitleMap = i18n.createMap({
  'Install the AD CS connector': () => i18n.t('Install the AD CS connector'),
  'Authenticate with Kandji': () => i18n.t('Authenticate with Kandji'),
});

export const i18nAdcsTicketTextMap = i18n.createMap({
  'Once installed, open the AD CS Connector app to authenticate with Kandji and complete setup of the connection.':
    () =>
      i18n.t(
        'Once installed, open the AD CS Connector app to authenticate with Kandji and complete setup of the connection.',
      ),
});

/* istanbul ignore next */
export const i18nAdcsLibraryItemConfigLabelMap = i18n.createMap({
  'Certificate profile': () => i18n.t('Certificate profile'),
  'Wi-Fi profile': () => i18n.t('Wi-Fi profile'),
});
