/* istanbul ignore file */
import {
  Banner,
  Button,
  Checkbox,
  Select,
  TextInput,
  mapInvalidIndexToField,
  setClass,
  useInputsValidators,
  useInvalidations,
  useRefs,
} from '@kandji-inc/bumblebee';
import React, { useCallback, useRef, useEffect } from 'react';
import uuidv4 from 'uuid/v4';

import { Setting } from 'features/library-items/template';

import featureFlags, { useFlags } from 'src/config/feature-flags';
import getOptionFor from '../../get-option';
import KandjiLoginService from '../../service/kandji-login-service';
import usePreviousValue from '../../use-previous-value';

// const MAX_GROUP_COUNT = 10;
const MAX_LOCAL_USER_COUNT = 20;
const MAX_INPUT_COUNT_UNTIL_SCROLL = 5;

const idpUserAccessOptions = [
  {
    value: 'allow_all_idp_users',
    label: 'Allow all users to log in',
  },
  {
    value: 'specify_idp_users',
    label: 'Specify which IdP users can log in',
  },
];

const localUserAccessOptions = [
  {
    value: 'allow_local_users',
    label: 'Allow all local users to log in',
  },
  {
    value: 'allow_local_admins',
    label: 'Allow local administrators to log in',
  },
  {
    value: 'which_local_users',
    label: 'Specify which local users can log in',
  },
];

const autoLoginOptions = [
  {
    value: 'filevault_autologin_allow',
    label: 'Allow automatic FileVault login',
  },
  {
    value: 'filevault_autologin_disallow',
    label: 'Disallow automatic FileVault login',
  },
];

const secureStoreUserPassOptions = [
  {
    value: 'secure_store_user_password_allow',
    label: 'Securely store password ',
  },
  {
    value: 'secure_store_user_password_disallow',
    label: 'Do not store password',
  },
];

const getFieldsToValidate = () => {
  const fields = [];

  for (let i = 0; i < 10; i++) {
    fields.push(`local-user-${i}`);
  }

  for (let i = 0; i < 10; i++) {
    fields.push(`idp-user-${i}`);
  }

  return [...fields, 'idpUserAccess', 'autoIncludeIdpUser'];
};

const Access = (props) => {
  const { setting, update, isDisabled, validationDep, authSetting } = props;
  const { 'DC-Passport-IdP-Users': LDFF_idpUsers } = useFlags();
  const localUsersLastInputRef = useRef(null);
  const localUsersScrollContainerRef = useRef(null);
  const prevSetting = usePreviousValue(setting);

  const fieldsToValidate = getFieldsToValidate();
  const refs = useRefs(fieldsToValidate.length);
  const { invalidations, onInvalidate } = useInvalidations({
    inputs: fieldsToValidate.length,
  });

  const invalidationsMap = mapInvalidIndexToField(
    invalidations,
    fieldsToValidate,
    refs,
  );

  useEffect(() => {
    update('invalidationsMap', invalidationsMap);
  }, [...invalidations]);

  useEffect(() => {
    const isRequired = setting.localUserAccess === 'which_local_users';
    for (let i = 0; i < 10; i++) {
      onInvalidate(i)(false);
    }

    setting.localUsers?.forEach((v, i) => {
      onInvalidate(i)(isRequired && !v.value && 'Required');
    });
  }, [setting.localUserAccess, setting.localUsers]);

  useEffect(() => {
    onInvalidate(20)(
      setting.idpUserAccess === 'specify_idp_users' &&
        !setting.autoIncludeIdpUser &&
        setting.idpUsers.some(({ value }) => !value?.length),
    );

    for (let i = 10; i < 20; i++) {
      const idpIdx = i - 10;
      onInvalidate(i)(
        Boolean(
          LDFF_idpUsers &&
            setting.idpUserAccess === 'specify_idp_users' &&
            !setting.autoIncludeIdpUser &&
            setting.idpUsers[idpIdx] &&
            setting.idpUsers[idpIdx]?.value?.length === 0,
        ),
      );
    }
  }, [setting]);

  const trigger = ['onBlur', validationDep];

  const getLastInputRefProps = (
    ref,
    index,
    pos,
    offset = MAX_INPUT_COUNT_UNTIL_SCROLL,
  ) => {
    if (index === pos && index > offset - 1) {
      return {
        ref,
      };
    }
    return {};
  };

  useEffect(() => {
    if (
      localUsersLastInputRef?.current &&
      localUsersScrollContainerRef?.current &&
      prevSetting?.localUsers?.length &&
      setting.localUsers?.length &&
      setting.localUsers.length > MAX_INPUT_COUNT_UNTIL_SCROLL
    ) {
      const isAddUser =
        setting.localUsers.length > prevSetting.localUsers.length;

      if (isAddUser) {
        const top = localUsersLastInputRef.current.offsetTop;
        localUsersScrollContainerRef.current.scrollTo({
          top,
          behavior: 'smooth',
        });
      }
    }
  }, [setting.localUsers.length]);

  const addUser = useCallback((key) => {
    const newUser = {
      value: '',
      uuid: uuidv4(),
    };
    update(key, (p) => {
      const copy = [...p];
      copy.push(newUser);
      return copy;
    });
  }, []);

  const removeUser = useCallback((key, v) => {
    update(key, (p) => p.filter((pg) => pg.uuid !== v.uuid));
  }, []);

  const shouldShowWebLoginPassthrough =
    featureFlags.getFlag('ag_07212023_Passport-Web-Login-Passthrough') &&
    authSetting?.mode === KandjiLoginService.authenticationMode.WEB_LOGIN &&
    setting.secureStoreUserPassword === 'secure_store_user_password_allow';

  return (
    <Setting.Card>
      <Setting.Header>
        <h3 className="b-h3">Access</h3>
      </Setting.Header>
      <Setting.SubHeader>
        <p className="b-txt">
          Configure general access settings and automatic FileVault login.&nbsp;
          <a
            href="https://support.kandji.io/support/solutions/articles/72000558707"
            rel="noopener noreferrer"
            target="_blank"
            className="b-alink"
          >
            Learn more...
          </a>
        </p>
      </Setting.SubHeader>
      <Setting.Rows>
        {LDFF_idpUsers && (
          <Setting.Row>
            <Setting.Title>
              <p className="b-txt">IdP user access</p>
            </Setting.Title>
            <Setting.Helpers className="b-txt-light">
              Specify which IdP users can log in. These settings apply
              specifically to new or existing Passport users that were
              authenticated against the IdP. Use the Local User Access setting
              below to control access for other local users.
            </Setting.Helpers>
            <Setting.Controls>
              <Select
                disabled={isDisabled}
                options={idpUserAccessOptions}
                compact
                onChange={(v) => {
                  update('idpUserAccess', v.value);
                  if (setting.idpUserAccess === 'specify_idp_users') {
                    update('idpUsers', [{ uuid: uuidv4(), value: '' }]);
                    update('autoIncludeIdpUser', false);
                  }
                }}
                value={getOptionFor(
                  setting.idpUserAccess,
                  idpUserAccessOptions,
                )}
              />
            </Setting.Controls>
            {setting.idpUserAccess === 'specify_idp_users' && (
              <Setting.SecondaryControls>
                <Setting.SecondaryControlsRow>
                  <Setting.Controls>
                    <Checkbox
                      testId="auto-include-user"
                      disabled={isDisabled}
                      label="Automatically include user assigned to device record"
                      checked={setting.autoIncludeIdpUser}
                      onChange={() => update('autoIncludeIdpUser', (p) => !p)}
                    />
                  </Setting.Controls>
                  <Setting.Helpers>
                    <p className="b-txt-light">
                      If selected, automatically allows the user assigned to the
                      device record in Kandji to log in.
                    </p>
                  </Setting.Helpers>
                </Setting.SecondaryControlsRow>
                <Setting.SecondaryControlsRow>
                  <div>
                    <div>
                      <p className="b-txt">Specify additional IdP users</p>
                      <p className="b-txt-light b-mb1">
                        Optionally specify any additional IdP users that can log
                        in.
                      </p>
                      <div
                        className="k-klogin-accounts-scrollable"
                        ref={localUsersScrollContainerRef}
                      >
                        {setting.idpUsers.map((u, i, l) => (
                          <div
                            key={u.uuid}
                            className={setClass(
                              'k-klogin-localusers-grid',
                              i !== setting.idpUsers.length - 1 ? 'b-mb1' : '',
                            )}
                            ref={refs[i + 10]}
                          >
                            <TextInput
                              compact
                              value={u.value}
                              onChange={(e) => {
                                e.persist();
                                update('idpUsers', (p) => {
                                  const copy = [...p];
                                  const idx = copy.findIndex(
                                    (c) => c.uuid === u.uuid,
                                  );
                                  copy[idx].value = e.target.value;
                                  return copy;
                                });
                              }}
                              placeholder="Username"
                              disabled={isDisabled}
                              validator={(v) => [
                                {
                                  message: 'Required',
                                  invalid: () =>
                                    !v && !setting.autoIncludeIdpUser,
                                  trigger: [
                                    ...trigger,
                                    setting.autoIncludeIdpUser,
                                  ],
                                },
                              ]}
                            />
                            {setting.idpUsers.length > 1 ? (
                              <Button
                                theme="error"
                                kind="link"
                                icon="trash-can"
                                onClick={() => removeUser('idpUsers', u)}
                                disabled={isDisabled}
                              />
                            ) : null}
                          </div>
                        ))}
                      </div>
                      <Button
                        disabled={
                          setting.localUsers.length === MAX_LOCAL_USER_COUNT ||
                          isDisabled
                        }
                        kind="link"
                        icon="circle-plus"
                        className="b-mt1"
                        onClick={() => addUser('idpUsers')}
                      >
                        Add user
                      </Button>
                    </div>
                  </div>
                </Setting.SecondaryControlsRow>
              </Setting.SecondaryControls>
            )}
          </Setting.Row>
        )}

        <Setting.Row>
          <Setting.Title>
            <p className="b-txt">Local user access</p>
          </Setting.Title>
          <Setting.Helpers className="b-txt-light">
            Specify whether existing local users can log in, or if access should
            be restricted to local administrators or specific local users.
            {authSetting?.mode ===
              KandjiLoginService.authenticationMode.WEB_LOGIN && (
              <Banner kind="block" theme="info" className="b-mt1">
                <span>
                  {`Web Login requires a user's device to be online. If you want
                  to allow users to log in offline, we recommend that you
                  configure this setting.`}
                </span>
              </Banner>
            )}
          </Setting.Helpers>
          <Setting.Controls>
            <Select
              disabled={isDisabled}
              options={localUserAccessOptions}
              compact
              onChange={(v) => {
                update('localUserAccess', v.value);
                if (setting.localUserAccess !== 'which_local_users') {
                  update('localUsers', [{ uuid: uuidv4(), value: '' }]);
                }
              }}
              value={getOptionFor(
                setting.localUserAccess,
                localUserAccessOptions,
              )}
            />
          </Setting.Controls>
          {setting.localUserAccess === 'which_local_users' && (
            <Setting.SecondaryControls>
              <Setting.SecondaryControlsRow>
                <div>
                  <div>
                    <p className="b-txt">Specify local users</p>
                    <p className="b-txt-light b-mb1">
                      Specify which existing local users can bypass Passport
                      authentication when logging in.
                    </p>
                    <div
                      className="k-klogin-accounts-scrollable"
                      ref={localUsersScrollContainerRef}
                    >
                      {setting.localUsers.map((u, i, l) => (
                        <div
                          key={u.uuid}
                          className={setClass(
                            'k-klogin-localusers-grid',
                            i !== setting.localUsers.length - 1 ? 'b-mb1' : '',
                          )}
                          {...getLastInputRefProps(
                            localUsersLastInputRef,
                            i,
                            l.length - 1,
                          )}
                          ref={refs[i]}
                        >
                          <TextInput
                            compact
                            value={u.value}
                            onChange={(e) => {
                              e.persist();
                              update('localUsers', (p) => {
                                const copy = [...p];
                                const idx = copy.findIndex(
                                  (c) => c.uuid === u.uuid,
                                );
                                copy[idx].value = e.target.value;
                                return copy;
                              });
                            }}
                            placeholder="Local user short name"
                            disabled={isDisabled}
                            validator={(v) => [
                              {
                                message: 'Required',
                                invalid: () => !v,
                                trigger,
                              },
                            ]}
                          />
                          {setting.localUsers.length > 1 ? (
                            <Button
                              theme="error"
                              kind="link"
                              icon="trash-can"
                              onClick={() => removeUser('localUsers', u)}
                              disabled={isDisabled}
                            />
                          ) : null}
                        </div>
                      ))}
                    </div>
                    <Button
                      disabled={
                        setting.localUsers.length === MAX_LOCAL_USER_COUNT ||
                        isDisabled
                      }
                      kind="link"
                      icon="circle-plus"
                      className="b-mt1"
                      onClick={() => addUser('localUsers')}
                    >
                      Add user
                    </Button>
                  </div>
                </div>
              </Setting.SecondaryControlsRow>
            </Setting.SecondaryControls>
          )}
        </Setting.Row>

        <Setting.Row>
          <Setting.Title>
            <p className="b-txt">Automatic FileVault login</p>
          </Setting.Title>
          <Setting.Helpers className="b-txt-light">
            <p className="b-txt-light b-mb1">
              Specify if FileVault pass-through authentication should be enabled
              or disabled.
            </p>
            {setting.autoLogin === 'filevault_autologin_disallow' && (
              <Banner theme="info" kind="block">
                <p>
                  Disabling FileVault pass-through authentication will require
                  users to authenticate twice during system reboot.
                </p>
              </Banner>
            )}
            {setting.autoLogin === 'filevault_autologin_allow' && (
              <Banner theme="info" kind="block">
                <p>
                  Allowing FileVault pass-through authentication will bypass the
                  Passport login window.
                </p>
              </Banner>
            )}
          </Setting.Helpers>
          <Setting.Controls>
            <Select
              disabled={isDisabled}
              options={autoLoginOptions}
              compact
              onChange={(v) => update('autoLogin', v.value)}
              value={getOptionFor(setting.autoLogin, autoLoginOptions)}
            />
          </Setting.Controls>
        </Setting.Row>

        <Setting.Row>
          <Setting.Title>
            <p className="b-txt">Store user password</p>
          </Setting.Title>
          <Setting.Helpers className="b-txt-light">
            <p className="b-txt-light b-mb1">
              Optionally store the user’s current password in a dedicated
              keychain in order to silently handle password changes at the login
              window.
            </p>
            {setting.secureStoreUserPassword ===
              'secure_store_user_password_disallow' && (
              <Banner theme="info" kind="block">
                <p>
                  The users will be prompted to provide the existing local
                  password in order to complete the password change at the login
                  window.
                </p>
              </Banner>
            )}
          </Setting.Helpers>
          <Setting.Controls>
            <Select
              disabled={isDisabled}
              options={secureStoreUserPassOptions}
              compact
              onChange={(v) => update('secureStoreUserPassword', v.value)}
              value={getOptionFor(
                setting.secureStoreUserPassword,
                secureStoreUserPassOptions,
              )}
            />
          </Setting.Controls>
          {shouldShowWebLoginPassthrough && (
            <Setting.SecondaryControls>
              <Setting.SecondaryControlsRow>
                <Setting.Helpers className="b-txt-light">
                  <p className="b-txt-light b-mb1">
                    When this option is selected, and “Store user password” is
                    set to “Securely store password”, users will see an
                    additional password verification screen only the first time
                    they log in; for subsequent logins, the login process will
                    be completed after the user successfully authenticates in
                    the Web Login window.
                  </p>
                </Setting.Helpers>
                <Setting.Controls>
                  <Checkbox
                    testId="web-login-passthrough-authentication"
                    disabled={isDisabled}
                    label="Web Login Passthrough"
                    checked={setting.webLoginPassthrough}
                    onChange={() => {
                      update('webLoginPassthrough', (p) => !p);
                    }}
                  />
                </Setting.Controls>
              </Setting.SecondaryControlsRow>
            </Setting.SecondaryControls>
          )}
        </Setting.Row>
      </Setting.Rows>
    </Setting.Card>
  );
};

export default Access;
