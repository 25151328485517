import { TextField } from '@kandji-inc/nectar-ui';
import * as React from 'react';
import { api } from 'src/app/api/base';
import { URL_DEP_COMPUTER_CHANGE_ASSET_TAG } from 'src/app/api/urls';
import useDebouncedState from 'src/features/compliance/Policy/Devices/useDebouncedState';
import {
  type ADEListViewStoreType,
  useADEListViewStore,
} from '../store/ADEListViewStore';

export interface ADECellAssetTagProps {
  deviceId: string;
  assetTagValue: string;
}

export const ADECellAssetTag = React.memo(function ADECellAssetTag({
  deviceId,
  assetTagValue,
}: ADECellAssetTagProps) {
  const [debouncedTag, setAssetTag, assetTag] = useDebouncedState(
    assetTagValue,
    750,
  );
  const updateAssetTag = useADEListViewStore(
    (state: ADEListViewStoreType) => state.updateAssetTag,
  );

  async function handleUpdateAssetTag(value: string) {
    setAssetTag(value);
  }

  React.useEffect(() => {
    async function updateAssetTagAsync() {
      if (assetTag === assetTagValue) return;
      try {
        updateAssetTag([deviceId], debouncedTag);
        await api(URL_DEP_COMPUTER_CHANGE_ASSET_TAG).post({
          devices: [deviceId],
          asset_tag: debouncedTag,
        });
      } catch {
        setAssetTag(assetTagValue);
        updateAssetTag([deviceId], assetTagValue);
      }
    }

    updateAssetTagAsync();
  }, [debouncedTag, updateAssetTag, deviceId, assetTagValue]);

  return (
    <TextField
      aria-label="Asset Tag Input"
      autoComplete="off"
      maxLength={100}
      compact
      placeholder="Enter asset tag"
      value={assetTag as string}
      onChange={(e) => handleUpdateAssetTag(e.target.value)}
    />
  );
});
