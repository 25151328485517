import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { animated, useTransition } from 'react-spring';

import HubSpotHandler from 'components/common/hubspot-handler';
import { InterfaceContext } from 'contexts/interface';

import { noop, slugify } from '../../function';
import * as spring from '../../spring';

import Shroud from './Shroud';

const Offcanvas = ({ kind, name, children, onClickShroud }) => {
  const { offcanvas } = useContext(InterfaceContext);

  const isOpen = offcanvas ? offcanvas[name] : false;

  const springOffcanvas = useTransition(isOpen, null, spring.slide);

  const id = slugify(name);

  const animateOffcanvas = springOffcanvas.map(
    ({ item, key, props }) =>
      item && (
        <animated.aside
          key={key}
          style={props}
          id={`offcanvas-${id}`}
          className={`spring-offcanvas-${kind} spring-offcanvas spring-slide theme-base`}
        >
          {children}
        </animated.aside>
      ),
  );

  return (
    <>
      {isOpen && <HubSpotHandler />}
      <Shroud isOpen={isOpen} isLock={isOpen} onClick={onClickShroud} />
      {animateOffcanvas}
    </>
  );
};

Offcanvas.kinds = {
  DEFAULT: 'default',
  WIDE: 'wide',
  MEDIUM: 'medium',
};

Offcanvas.propTypes = {
  kind: PropTypes.oneOf(Object.values(Offcanvas.kinds)),
  name: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  onClickShroud: PropTypes.func,
};

Offcanvas.defaultProps = {
  kind: 'default',
  onClickShroud: noop,
};

export default Offcanvas;
