import { i18n } from 'i18n';
import React from 'react';
import { Progress } from 'react-sweet-progress';

const ProgressBar = ({ downloadProgress }) => (
  <div className="b-progress">
    <p className="b-txt-bold">
      {i18n.t('Kandji AD CS connector is downloading')}...
    </p>
    <Progress percent={downloadProgress} />
  </div>
);

export default ProgressBar;
