import type { EnrollmentStatusFilters } from '../ADEListViewTable/ADEStatusFilter';
import { ADEListViewStore } from '../store/ADEListViewStore';
import { getEnrollmentStatusFilter } from './getEnrollmentStatusFilters';
import { getTokenFilters } from './getTokenFilters';

export function addSearchQueryFilters(
  body: Record<string, any>,
  isAllDevices: boolean,
  enrollmentStatus: EnrollmentStatusFilters,
) {
  const { searchText, tokenFilters, visibleColumns } =
    ADEListViewStore.getState();

  const filters = {
    search_columns: visibleColumns.join(','),
    search: searchText,
    filters: [
      ...getEnrollmentStatusFilter(isAllDevices, enrollmentStatus),
      ...getTokenFilters(tokenFilters),
    ],
  };

  return {
    ...body,
    search_query: {
      ...filters,
    },
  };
}
