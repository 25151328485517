import { useQuery } from '@tanstack/react-query';
import { api } from 'src/app/api/base';
import { URL_DEP_ACCOUNT_INFO } from 'src/app/api/urls';
import { ADEListViewStore } from '../store/ADEListViewStore';

export async function getADELastSync(): Promise<any> {
  try {
    const res = await api(URL_DEP_ACCOUNT_INFO).get();
    const { data } = res;

    return data.last_device_sync;
  } catch (error) {
    return null;
  }
}

export function isWithinLastMinute(timestampMs: number) {
  const now = Date.now();
  const oneMinuteAgo = now - 60 * 1000;
  return timestampMs >= oneMinuteAgo;
}

export function retryFetchSync() {
  const { isSyncing } = ADEListViewStore.getState();

  if (!isSyncing) return false;

  return 1000;
}

export function useGetADELastSync() {
  return useQuery({
    queryKey: ['get-ade-last-sync'],
    queryFn: getADELastSync,
    refetchInterval: retryFetchSync,
  });
}
