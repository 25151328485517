import React from 'react';
import { Link } from 'react-router-dom';

import { Button, Flex } from '@kandji-inc/bumblebee';

import { i18n } from 'i18n';

import { useAuthority, useIs, usePanel } from '../../hooks';

import iconIntegration from '../../assets/adcs.svg';

import library from '../../library';

import Panel from '..';
import Header from '../header';
import Item from '../item';

const PanelHandleConflictLibraryItem = () => {
  const {
    authority,
    authorityItem,
    authorityItemLibraryItemList,
    authorityListLibraryItemList,
    clearAuthority,
    clearAuthorityItem,
    clearAuthorityItemLibraryItemList,
  } = useAuthority();

  const { isLoading, isWorking, setHasChecked } = useIs();
  const { closePanel } = usePanel();

  /* istanbul ignore next */
  const list = authority
    ? authorityItemLibraryItemList
    : authorityListLibraryItemList;

  /* istanbul ignore next */
  const reset = () => {
    clearAuthority();
    clearAuthorityItem();
    clearAuthorityItemLibraryItemList();
    setHasChecked(false);
  };

  /* istanbul ignore next */
  const onClose = () => {
    closePanel(reset);
  };

  /* istanbul ignore next */
  const header = authority ? (
    <Header title={i18n.t('Unable to delete AD CS server')} />
  ) : (
    <Header
      title={i18n.t('Unable to delete AD CS integration')}
      icon={iconIntegration}
    />
  );

  /* istanbul ignore next */
  const description = authority ? (
    <div className="k-section-primary">
      <h4 className="b-h4 b-mb1">
        {i18n.t('Server cannot be deleted because of a Library Items conflict')}
      </h4>

      <p className="b-txt">
        {i18n.$t(
          'In order to delete {authorityItemElement}, the following Library Items will need to be modified or deleted. Navigate to the Library Item to edit or delete by clicking the Library Item name below.',
          {
            authorityItemElement: (
              <strong className="k-adcs-strong">
                {authorityItem?.name || ''}
              </strong>
            ),
          },
        )}
      </p>
    </div>
  ) : (
    <div className="k-section-primary">
      <h4 className="b-h4 b-mb1">
        {i18n.t(
          'Integration cannot be deleted because of a Library Items conflict',
        )}
      </h4>

      <p className="b-txt">
        {i18n.$t(
          'In order to delete {adcsElement}, the following Library Items will need to be modified or deleted. Navigate to the Library Item to edit or delete by clicking the Library Item name below.',
          {
            adcsElement: (
              <strong className="k-adcs-strong">
                Active Directory Certificate Services
              </strong>
            ),
          },
        )}
      </p>

      <p className="b-txt b-mt1">
        {i18n.t(
          'The following Library Items have specified AD CS servers from this integration in the configuration settings:',
        )}
      </p>
    </div>
  );

  /* istanbul ignore next */
  const loopLibraryItem = list?.length > 0 && (
    <div className="k-adcs-library-item-list k-section-primary k-section-border-top">
      <h4 className="b-h4 b-mb1">
        {i18n.t('The following Library Items need to be modified or deleted:')}
      </h4>
      {list?.map(
        /* istanbul ignore next */
        ({ id, identifier, name }) => {
          const { path, icon } = library[identifier];

          return (
            <Item key={id} icon={icon}>
              <Link className="b-alink" to={`/${path}/${id}`}>
                {name}
              </Link>
            </Item>
          );
        },
      )}
    </div>
  );

  return (
    <Panel name="handle-conflict-library-item">
      {header}

      <div className="b-side-panel-layout__body hubspot-buffer-bottom">
        {description}

        {loopLibraryItem}
      </div>

      <div className="b-side-panel-layout__footer">
        <Flex justify="flex-end">
          <div className="b-grid-ctas">
            <Button
              onClick={onClose}
              isDisabled={isLoading || isWorking}
              isProgress={isWorking}
            >
              {i18n.t('Close')}
            </Button>
          </div>
        </Flex>
      </div>
    </Panel>
  );
};

export default PanelHandleConflictLibraryItem;
