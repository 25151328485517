import {
  Button,
  DialogV2 as Dialog,
  Text,
  styled,
} from '@kandji-inc/nectar-ui';
import * as React from 'react';
import { api } from 'src/app/api/base';
import { URL_DEP_COMPUTER_CHANGE_USER } from 'src/app/api/urls';
import { useGetADEUsers } from '../hooks/useGetADEUsers';
import { ADEListViewStore } from '../store/ADEListViewStore';
import { addSearchQueryFilters } from '../utils/addSearchQueryFilters';
import { ADECellSelect } from './ADECellSelect/ADECellSelect';
import { NotAssignedTrigger } from './ADECellUserSelect';
import { type User, UserSelections, isUser } from './ADEListViewTableColumns';
import { EnrollmentStatusFilters } from './ADEStatusFilter';

const Label = styled('div', {
  fontWeight: 500,
  marginBottom: 4,
  color: '$neutral70',
});

const Description = styled(Text, {
  marginBottom: 14,
});

const DialogActions = styled('div', {
  display: 'flex',
  justifyContent: 'flex-end',
  gap: 4,
  padding: '16px 0',
  marginTop: 20,
});

export async function handleUpdateUsers(
  user: User | UserSelections,
  handleClose: () => void,
) {
  const { devices, selectedDevices, selectAllDevices, updateUser } =
    ADEListViewStore.getState();

  const devicesToUpdate = selectAllDevices
    ? devices.map(/* istanbul ignore next */ (device) => device.id)
    : selectedDevices;

  let bulkUpdateBody: Record<string, any> = {
    devices: selectedDevices,
    user_id: isUser(user) ? user.id : null,
  };

  if (selectAllDevices) {
    bulkUpdateBody = addSearchQueryFilters(
      bulkUpdateBody,
      false,
      EnrollmentStatusFilters.AWAITING,
    );
  }

  try {
    await api(URL_DEP_COMPUTER_CHANGE_USER).post(bulkUpdateBody);
    updateUser(devicesToUpdate, isUser(user) ? user : null);
    handleClose();
  } catch {}
}

export function getTriggerTitle(user: User | UserSelections) {
  if (user === UserSelections.NOT_ASSIGNED || user == UserSelections.EMPTY) {
    return user;
  }

  return user.name;
}

export function ADEUserDialog({ isOpen, toggle }) {
  const [user, setUser] = React.useState<
    User | UserSelections.EMPTY | UserSelections.NOT_ASSIGNED
  >(UserSelections.EMPTY);

  function handleClose() {
    setUser(UserSelections.EMPTY);
    toggle();
  }

  let triggerStyle: any = {};
  if (!isUser(user)) {
    triggerStyle = { ...NotAssignedTrigger };
    if (user === UserSelections.EMPTY) {
      triggerStyle.fontStyle = 'unset';
    }
  }

  return (
    <Dialog
      isOpen={isOpen}
      title="Assign user"
      onOpenChange={handleClose}
      content={
        <div>
          <Description>
            Selected devices will be assigned to this user when they enroll.
          </Description>
          <Label>Assign devices to user:</Label>
          <ADECellSelect
            isCompact={false}
            value={isUser(user) ? user.id : user}
            defaultValue={UserSelections.EMPTY}
            triggerValue={getTriggerTitle(user)}
            triggerTitle={getTriggerTitle(user)}
            triggerStyle={triggerStyle}
            ariaLabel={'User Select'}
            placeholder="Search users"
            onValueChange={
              /* istanbul ignore next */
              (newUser) => {
                setUser(newUser);
              }
            }
            useGetData={useGetADEUsers}
            triggerIcon={null}
            valueIsNullable
            searchable={false}
          />
          <DialogActions>
            <Button variant="subtle" onClick={handleClose}>
              Cancel
            </Button>
            <Button
              onClick={
                /* istanbul ignore next */ () =>
                  handleUpdateUsers(user, handleClose)
              }
              variant="primary"
              disabled={user === UserSelections.EMPTY}
            >
              Assign
            </Button>
          </DialogActions>
        </div>
      }
    />
  );
}
