import { i18n } from 'i18n';
import React from 'react';

// Bumblebee
import { Button } from '@kandji-inc/bumblebee';

const CancelButton = ({ onClick, isDisabled }) => (
  <Button kind="outline" onClick={onClick} isDisabled={isDisabled}>
    {i18n.t('Cancel')}
  </Button>
);

export default CancelButton;
