import { links } from 'app/common/constants';
import React from 'react';
import { Redirect, useParams } from 'react-router-dom';
import configurations from '../../../configurations';

function WrappedPage(IntegrationPage) {
  return function Wrapper() {
    const { type } = useParams();
    const config = configurations[type];

    if (!config) {
      return <Redirect to={links.integrations.root} />;
    }

    return <IntegrationPage config={config} type={type} />;
  };
}

export default WrappedPage;
