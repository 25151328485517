import { useQuery } from '@tanstack/react-query';
import { api } from 'src/app/api/base';
import { URL_DEP_TOKENS } from 'src/app/api/urls';

export async function getADETokens(): Promise<string[]> {
  try {
    const res = await api(URL_DEP_TOKENS).get();
    const { data } = res;

    const results = data.results ?? [];
    return results.map((result: { server_name: string }) => result.server_name);
  } catch (error) {
    return [];
  }
}

export function useGetADETokens() {
  return useQuery({
    queryKey: ['get-ade-tokens'],
    queryFn: getADETokens,
  });
}
