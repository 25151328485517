import { Dropdown, PLACEMENT, setClass } from '@kandji-inc/bumblebee';
import { func, node, string } from 'prop-types';
import React from 'react';
import './options-menu.styles.scss';

function OptionsMenu({ children, dropdownClassName, className, render }) {
  return (
    <Dropdown
      render={render}
      className={setClass(className, 'b-table__prevent-row-toggle')}
      dropdownClassName={setClass(
        dropdownClassName,
        'b-table__prevent-row-toggle',
        'options-menu_dropdown-paper',
      )}
      renderPlace={document.body}
      placement={PLACEMENT.bottomEnd}
      offset={8}
    >
      {children}
    </Dropdown>
  );
}

OptionsMenu.propTypes = {
  children: node.isRequired,
  render: func.isRequired,
  className: string,
  dropdownClassName: string,
};

OptionsMenu.defaultProps = {
  className: '',
  dropdownClassName: '',
};

export default OptionsMenu;
