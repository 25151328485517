import { Banner } from '@kandji-inc/nectar-ui';
import React from 'react';
import { moment } from '../../common/helpers';

const DeferredInstallNotification = ({ lastEnroll }) => {
  if (!lastEnroll) {
    return null;
  }
  let hours = 0;
  let minutes = 0;
  try {
    const diff =
      moment.utc(lastEnroll).unix() - moment.utc().unix() + 72 * 60 * 60;
    hours = Math.floor(diff / 3600);
    minutes = Math.floor((diff % 3600) / 60);
  } catch (err) {
    console.log('ERROR renderDeferredInstallAlert:', err);
  }

  return (
    <Banner
      theme="warning"
      text={
        <>
          First Run has not been initiated.
          {!(hours >= 0 && minutes >= 0) && (
            <span className="ml-1">
              Enforcement will begin on next check-in.
            </span>
          )}
          {!!(hours >= 0 && minutes >= 0) && (
            <span className="ml-1">{`Auto-start in ${hours} hours, ${minutes} minutes.`}</span>
          )}
        </>
      }
    />
  );
};

export default DeferredInstallNotification;
