import { TextInput } from '@kandji-inc/bumblebee';
import React, { useEffect, useState } from 'react';

const SingleInput = (props) => {
  const {
    param,
    update,
    isDisabled,
    defaultValue,
    validators,
    label,
    validations,
  } = props;
  const { details } = param;
  const [value, setValue] = useState(details || defaultValue);
  const [isInvalid, setIsInvalid] = useState(false);

  const {
    SingleInput: { syncInvalid },
  } = validations;

  useEffect(() => {
    if (isDisabled) {
      setValue(details || defaultValue);
      setIsInvalid(false);
    }
  }, [param, isDisabled]);

  useEffect(() => {
    if (!isDisabled) {
      const time = setTimeout(() => {
        update({ details: value, isInvalid });
      }, 250);

      return () => clearTimeout(time);
    }
  }, [value, isInvalid]);

  const allValidators = (v) => [
    {
      message: 'Required',
      invalid: () => !v,
      trigger: ['onBlur', 'onInput'],
    },
    ...(validators ? validators(v) : []),
  ];

  return (
    <div className="b-mb1" style={{ maxWidth: '65%' }}>
      <TextInput
        fieldsGrid
        disabled={isDisabled}
        label={label}
        value={value}
        onChange={(e) => setValue(e.target.value)}
        onInvalidate={(isInvalid) => setIsInvalid(isInvalid)}
        validator={allValidators}
        {...syncInvalid(isInvalid, { key: param.parameter.id })}
      />
    </div>
  );
};

export default SingleInput;
