export const TYPES = {
  CUSTOMIZE: 'customize',
  ADD: 'add',
  EDIT: 'edit',
  EDIT_RECOMMENDED: 'edit-recommended',
  EDIT_ALL: 'edit-all',
  DELETE: 'delete',
  INACTIVE: 'inactive',
};

export const STATUSES = {
  IDLE: 'idle',
  LOADING: 'loading',
  SAVING: 'saving',
  DISABLED: 'disabled',
};

export const EDIT_RECOMMENDED_CATEGORY_NAMES = {
  RECOMMENDED: {
    label: 'Recommended',
    value: 'recommended',
  },
  STARRED: {
    label: 'Starred',
    value: 'starred',
  },
  FEATURED: {
    label: 'Featured',
    value: 'featured',
  },
  FAVORITES: {
    label: 'Favorites',
    value: 'favorites',
  },
  CUSTOM: {
    label: 'Custom...',
    value: 'custom',
  },
};

export const EDIT_RECOMMENDED_CATEGORY_NAME_OPTIONS = [
  {
    label: EDIT_RECOMMENDED_CATEGORY_NAMES.RECOMMENDED.label,
    value: EDIT_RECOMMENDED_CATEGORY_NAMES.RECOMMENDED.value,
  },
  {
    label: EDIT_RECOMMENDED_CATEGORY_NAMES.STARRED.label,
    value: EDIT_RECOMMENDED_CATEGORY_NAMES.STARRED.value,
  },
  {
    label: EDIT_RECOMMENDED_CATEGORY_NAMES.FEATURED.label,
    value: EDIT_RECOMMENDED_CATEGORY_NAMES.FEATURED.value,
  },
  {
    label: EDIT_RECOMMENDED_CATEGORY_NAMES.FAVORITES.label,
    value: EDIT_RECOMMENDED_CATEGORY_NAMES.FAVORITES.value,
  },
  {
    label: EDIT_RECOMMENDED_CATEGORY_NAMES.CUSTOM.label,
    value: EDIT_RECOMMENDED_CATEGORY_NAMES.CUSTOM.value,
  },
];

export const BRANDING_MAX_CHAR_LIMIT = {
  HEADER: 30,
  SUBHEADER: 64,
};

export default {
  TYPES,
  STATUSES,
  EDIT_RECOMMENDED_CATEGORY_NAME_OPTIONS,
  BRANDING_MAX_CHAR_LIMIT,
};
