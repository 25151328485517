/* istanbul ignore file */

// This set of keys uses default values as defined in the MDM Restrictions spec.
export const getRestrictionKeys = () => ({
  allowAccountModification: { value: true },
  allowActivityContinuation: { value: true },
  allowAddingGameCenterFriends: { value: true },
  allowAirDrop: { value: true },
  allowAirPlayIncomingRequests: { value: true },
  allowAirPrint: { value: true },
  allowAirPrintCredentialsStorage: { value: true },
  allowAirPrintiBeaconDiscovery: { value: true },
  allowAppCellularDataModification: { value: true },
  allowAppClips: { value: true },
  allowAppInstallation: { value: true },
  allowApplePersonalizedAdvertising: { value: true },
  allowAppRemoval: { value: true },
  allowAssistant: { value: true },
  allowAssistantUserGeneratedContent: { value: true },
  allowAssistantWhileLocked: { value: true },
  allowAutoCorrection: { value: true },
  allowAutomaticAppDownloads: { value: true },
  allowAutomaticScreenSaver: { value: true },
  allowAutoUnlock: { value: true },
  allowBluetoothModification: { value: true },
  allowBookstore: { value: true },
  allowBookstoreErotica: { value: true },
  allowCamera: { value: true },
  allowCellularPlanModification: { value: true },
  allowChat: { value: true },
  allowCloudAddressBook: { value: true },
  allowCloudBackup: { value: true },
  allowCloudBookmarks: { value: true },
  allowCloudCalendar: { value: true },
  allowCloudDesktopAndDocuments: { value: true },
  allowCloudDocumentSync: { value: true },
  allowCloudKeychainSync: { value: true },
  allowCloudMail: { value: true },
  allowCloudNotes: { value: true },
  allowCloudPhotoLibrary: { value: true },
  allowCloudPrivateRelay: { value: true },
  allowCloudReminders: { value: true },
  allowContentCaching: { value: true },
  allowContinuousPathKeyboard: { value: true },
  allowDefinitionLookup: { value: true },
  allowDeviceNameModification: { value: true },
  allowDeviceSleep: { value: true },
  allowDiagnosticSubmission: { value: true },
  allowDiagnosticSubmissionModification: { value: true },
  allowDictation: { value: true },
  allowEnablingRestrictions: { value: true },
  allowEnterpriseAppTrust: { value: true },
  allowEnterpriseBookBackup: { value: true },
  allowEnterpriseBookMetadataSync: { value: true },
  allowEraseContentAndSettings: { value: true },
  allowESIMModification: { value: true },
  allowExplicitContent: { value: true },
  allowFilesNetworkDriveAccess: { value: true },
  allowFilesUSBDriveAccess: { value: true },
  allowFindMyDevice: { value: true },
  allowFindMyFriends: { value: true },
  allowFindMyFriendsModification: { value: true },
  allowFingerprintForUnlock: { value: true },
  allowFingerprintModification: { value: true },
  allowGameCenter: { value: true },
  allowGlobalBackgroundFetchWhenRoaming: { value: true },
  allowHostPairing: { value: true },
  allowInAppPurchases: { value: true },
  allowiTunes: { value: true },
  allowiTunesFileSharing: { value: true },
  allowKeyboardShortcuts: { value: true },
  allowLockScreenControlCenter: { value: true },
  allowLockScreenNotificationsView: { value: true },
  allowLockScreenTodayView: { value: true },
  allowMailPrivacyProtection: { value: true },
  allowManagedAppsCloudSync: { value: true },
  allowManagedToWriteUnmanagedContacts: { value: false },
  allowMultiplayerGaming: { value: true },
  allowMusicService: { value: true },
  allowNews: { value: true },
  allowNFC: { value: true },
  allowNotificationsModification: { value: true },
  allowOpenFromManagedToUnmanaged: { value: true },
  allowOpenFromUnmanagedToManaged: { value: true },
  allowOTAPKIUpdates: { value: true },
  allowPairedWatch: { value: true },
  allowPassbookWhileLocked: { value: true },
  allowPasscodeModification: { value: true },
  allowPasswordAutoFill: { value: true },
  allowPasswordProximityRequests: { value: true },
  allowPasswordSharing: { value: true },
  allowPersonalHotspotModification: { value: true },
  allowPhotoStream: { value: true },
  allowPodcasts: { value: true },
  allowPredictiveKeyboard: { value: true },
  allowProximitySetupToNewDevice: { value: true },
  allowRadioService: { value: true },
  allowRemoteAppPairing: { value: true },
  allowSafari: { value: true },
  allowScreenShot: { value: true },
  allowSharedDeviceTemporarySession: { value: true },
  allowSharedStream: { value: true },
  allowSpellCheck: { value: true },
  allowSpotlightInternetResults: { value: true },
  allowSystemAppRemoval: { value: true },
  allowUIAppInstallation: { value: true },
  allowUIConfigurationProfileInstallation: { value: true },
  allowUniversalControl: { value: true },
  allowUnmanagedToReadManagedContacts: { value: false },
  allowUnpairedExternalBootToRecovery: { value: false },
  allowUntrustedTLSPrompt: { value: true },
  allowUSBRestrictedMode: { value: true },
  allowVideoConferencing: { value: true },
  allowVoiceDialing: { value: true },
  allowVPNCreation: { value: true },
  allowWallpaperModification: { value: true },
  allowWebDistributionAppInstallation: { value: true },
  autonomousSingleAppModePermittedAppIDs: {
    checked: false,
    value: [],
  }, // TODO:
  blacklistedAppBundleIDs: {
    checked: false,
    value: [],
  }, // TODO: use blockedAppBundleIDs instead
  enforcedFingerprintTimeout: { checked: false, value: 48 },
  enforcedSoftwareUpdateDelay: { checked: false, value: 30 },
  forceAirDropUnmanaged: { value: false },
  forceAirPlayIncomingRequestsPairingPassword: { value: false },
  forceAirPlayOutgoingRequestsPairingPassword: { value: false },
  forceAirPrintTrustedTLSRequirement: { value: false },
  forceAssistantProfanityFilter: { value: false },
  forceAuthenticationBeforeAutoFill: { value: false },
  forceAutomaticDateAndTime: { value: false },
  forceDelayedAppSoftwareUpdates: { value: false },
  forceEncryptedBackup: { value: false },
  forceITunesStorePasswordEntry: { value: false },
  forceLimitAdTracking: { value: false },
  forceOnDeviceOnlyDictation: { value: false },
  forceOnDeviceOnlyTranslation: { value: false },
  forceWatchWristDetection: { value: false },
  forceWiFiPowerOn: { value: false },
  forceWiFiWhitelisting: { value: false }, // TODO: use forceWiFiToAllowedNetworksOnly instead
  ratingApps: { checked: false, value: 1000 },
  ratingMovies: { checked: false, value: 1000 },
  ratingRegion: { checked: false, value: 'us' }, // Note: this is not used in the UI or sent to device
  ratingTVShows: { checked: false, value: 1000 },
  safariAcceptCookies: { checked: false, value: '1' }, // TODO: Transform value to number
  safariAllowAutoFill: { value: true },
  safariAllowJavaScript: { value: true },
  safariAllowPopups: { value: true },
  whitelistedAppBundleIDs: {
    checked: false,
    value: [],
  }, // TODO: use allowListedAppBundleIDs instead
  safariForceFraudWarning: { value: false },
  allowRapidSecurityResponseInstallation: { value: true },
  allowRapidSecurityResponseRemoval: { value: true },
  allowARDRemoteManagementModification: { value: true },
  allowBluetoothSharingModification: { value: true },
  allowCloudFreeform: { value: true },
  allowFileSharingModification: { value: true },
  allowInternetSharingModification: { value: true },
  allowiPhoneWidgetsOnMac: { value: true },
  allowLocalUserCreation: { value: true },
  allowPrinterSharingModification: { value: true },
  allowRemoteAppleEventsModification: { value: true },
  allowStartupDiskModification: { value: true },
  allowTimeMachineBackup: { value: true },
  allowMarketplaceAppInstallation: { value: true },
  allowLiveVoicemail: { value: true },
});

/**
 * Created this after getRestrictionKeys as we avoided changing
 * defaults/inversion logic in favor of moving forward with updating
 * Restrictions safely.
 *
 * This function returns the original defaults for Restrictions as taken from
 * the restrictionsConfig.js file we have.
 */
export const getRestrictionKeysWithV1Defaults = () => ({
  // *** APPS ***
  allowAppInstallation: { value: false },
  allowUIAppInstallation: { value: false },
  allowInAppPurchases: { value: false },
  allowAutomaticAppDownloads: { value: false },
  allowAppRemoval: { value: false },
  allowSystemAppRemoval: { value: false },
  allowVideoConferencing: { value: false },
  allowChat: { value: false },
  allowNews: { value: false },
  allowPodcasts: { value: false },
  ratingApps: { checked: false, value: 1000 },
  allowAppClips: { value: false },
  allowMarketplaceAppInstallation: { value: false },
  forceDelayedAppSoftwareUpdates: { value: false },
  allowWebDistributionAppInstallation: { value: false },
  allowAppsToBeLocked: { value: false },
  allowAppsToBeHidden: { value: false },
  // *** APP BLACKLISTING & WHITELISTING ***
  blacklistedAppBundleIDs: {
    checked: false,
    value: [],
  },
  whitelistedAppBundleIDs: {
    checked: false,
    value: [],
  },
  // *** AUTONOMOUS SINGLE APP MODE ***
  autonomousSingleAppModePermittedAppIDs: {
    checked: false,
    value: [],
  },
  // *** PASSCODE & AUTHENTICATION ***
  allowPasscodeModification: { value: false },
  allowFingerprintModification: { value: false },
  allowFingerprintForUnlock: { value: false },
  allowAutoUnlock: { value: false },
  enforcedFingerprintTimeout: { checked: false, value: 48 },
  // *** LOCK SCREEN ***
  allowAssistantWhileLocked: { value: false },
  allowUSBRestrictedMode: { value: false },
  allowVoiceDialing: { value: false },
  allowLockScreenControlCenter: { value: false },
  allowLockScreenNotificationsView: { value: false },
  allowLockScreenTodayView: { value: false },
  allowPassbookWhileLocked: { value: false },
  // *** ACCOUNTS & PASSWORDS ***
  allowAccountModification: { value: false },
  allowPasswordAutoFill: { value: false },
  forceAuthenticationBeforeAutoFill: { value: false },
  allowPasswordSharing: { value: false },
  allowPasswordProximityRequests: { value: false },
  allowProximitySetupToNewDevice: { value: false },
  // *** DATA SEGREGATION ***
  allowOpenFromManagedToUnmanaged: { value: false },
  allowOpenFromUnmanagedToManaged: { value: false },
  allowManagedToWriteUnmanagedContacts: { value: false },
  allowUnmanagedToReadManagedContacts: { value: false },
  // *** ICLOUD ***
  allowCloudAddressBook: { value: false },
  allowCloudBookmarks: { value: false },
  allowCloudCalendar: { value: false },
  allowCloudDocumentSync: { value: false },
  allowCloudDesktopAndDocuments: { value: false },
  allowCloudKeychainSync: { value: false },
  allowCloudMail: { value: false },
  allowCloudNotes: { value: false },
  allowCloudReminders: { value: false },
  allowCloudPhotoLibrary: { value: false },
  allowPhotoStream: { value: false },
  allowSharedStream: { value: false },
  allowCloudBackup: { value: false },
  allowManagedAppsCloudSync: { value: false },
  allowActivityContinuation: { value: false },
  allowCloudPrivateRelay: { value: true },
  allowiPhoneWidgetsOnMac: { value: false },
  allowCloudFreeform: { value: false },
  // *** NETWORK ***
  forceWiFiPowerOn: { value: false },
  forceWiFiWhitelisting: { value: false },
  allowBluetoothModification: { value: false },
  allowVPNCreation: { value: false },
  allowNFC: { value: true },
  // *** CELLULAR ***
  allowCellularPlanModification: { value: false },
  allowAppCellularDataModification: { value: false },
  allowESIMModification: { value: false },
  allowPersonalHotspotModification: { value: false },
  allowGlobalBackgroundFetchWhenRoaming: { value: false },
  allowLiveVoicemail: { value: false },
  allowESIMOutgoingTransfers: { value: false },
  forcePreserveESIMOnErase: { value: false },
  allowRCSMessaging: { value: false },
  allowCallRecording: { value: false },
  // *** AIRDROP ***
  allowAirDrop: { value: false },
  forceAirDropUnmanaged: { value: false },
  // *** AIRPLAY ***
  forceAirPlayIncomingRequestsPairingPassword: { value: false },
  forceAirPlayOutgoingRequestsPairingPassword: { value: false },
  allowAirPlayIncomingRequests: { value: false },
  // *** AIRPRINT ***
  allowAirPrint: { value: false },
  allowAirPrintCredentialsStorage: { value: false },
  allowAirPrintiBeaconDiscovery: { value: false },
  forceAirPrintTrustedTLSRequirement: { value: false },
  // *** APPLE WATCH ***
  allowPairedWatch: { value: false },
  forceWatchWristDetection: { value: false },
  // *** DIAGNOSTICS ***
  allowDiagnosticSubmission: { value: false },
  allowDiagnosticSubmissionModification: { value: false },
  // *** FILES ***
  allowFilesNetworkDriveAccess: { value: false },
  allowFilesUSBDriveAccess: { value: false },
  // *** FIND MY ***
  allowFindMyDevice: { value: false },
  allowFindMyFriends: { value: false },
  allowFindMyFriendsModification: { value: false },
  // *** GAME CENTER ***
  allowGameCenter: { value: false },
  allowAddingGameCenterFriends: { value: false },
  allowMultiplayerGaming: { value: false },
  // *** KEYBOARD ***
  allowAutoCorrection: { value: false },
  allowDefinitionLookup: { value: false },
  allowDictation: { value: false },
  allowKeyboardShortcuts: { value: false },
  allowSpellCheck: { value: false },
  allowPredictiveKeyboard: { value: false },
  allowContinuousPathKeyboard: { value: false },
  // *** MEDIA ***
  allowBookstore: { value: false },
  allowBookstoreErotica: { value: false },
  allowEnterpriseBookBackup: { value: false },
  allowEnterpriseBookMetadataSync: { value: false },
  allowMusicService: { value: false },
  allowRadioService: { value: false },
  allowiTunes: { value: false },
  forceITunesStorePasswordEntry: { value: false },
  allowExplicitContent: { value: false },
  allowiTunesFileSharing: { value: false },
  ratingMovies: { checked: false, value: 1000 },
  ratingTVShows: { checked: false, value: 1000 },
  ratingRegion: { checked: false, value: 'us' }, // Note: this is not used in the UI or sent to device
  // *** OS UPDATES ***
  enforcedSoftwareUpdateDelay: { checked: false, value: 30 },
  // *** SAFARI ***
  allowSafari: { value: false },
  safariAllowAutoFill: { value: false },
  safariAllowPopups: { value: false },
  safariForceFraudWarning: { value: false },
  safariAllowJavaScript: { value: false },
  safariAcceptCookies: { checked: false, value: '1' },
  allowDeprecatedWebKitTLS: { value: false },
  // *** SIRI ***
  allowAssistant: { value: false },
  allowAssistantUserGeneratedContent: { value: false },
  forceAssistantProfanityFilter: { value: false },
  forceOnDeviceOnlyTranslation: { value: false },
  forceOnDeviceOnlyDictation: { value: false },
  // *** OS & GENERAL ***
  allowUntrustedTLSPrompt: { value: false },
  forceAutomaticDateAndTime: { value: false },
  allowRemoteAppPairing: { value: false },
  allowCamera: { value: false },
  allowHostPairing: { value: false },
  allowContentCaching: { value: false },
  allowDeviceNameModification: { value: false },
  allowDeviceSleep: { value: false },
  allowEraseContentAndSettings: { value: true },
  allowUIConfigurationProfileInstallation: { value: false },
  allowNotificationsModification: { value: false },
  allowOTAPKIUpdates: { value: false },
  allowEnablingRestrictions: { value: false },
  allowScreenShot: { value: false },
  allowSpotlightInternetResults: { value: false },
  allowEnterpriseAppTrust: { value: false },
  allowWallpaperModification: { value: false },
  forceLimitAdTracking: { value: false },
  forceEncryptedBackup: { value: false },
  allowSharedDeviceTemporarySession: { value: false },
  allowUnpairedExternalBootToRecovery: { value: false },
  allowAutomaticScreenSaver: { value: false },
  allowUniversalControl: { value: false },
  allowApplePersonalizedAdvertising: { value: false },
  allowRapidSecurityResponseInstallation: { value: false },
  allowRapidSecurityResponseRemoval: { value: false },
  allowStartupDiskModification: { value: false },
  allowTimeMachineBackup: { value: false },
  allowLocalUserCreation: { value: false },
  allowAutoDim: { value: false },
  forceBypassScreenCaptureAlert: { value: false },
  // *** SHARING ***
  allowFileSharingModification: { value: false },
  allowPrinterSharingModification: { value: false },
  allowARDRemoteManagementModification: { value: false },
  allowRemoteAppleEventsModification: { value: false },
  allowInternetSharingModification: { value: false },
  allowBluetoothSharingModification: { value: false },
  allowMailPrivacyProtection: { value: false },
  allowiPhoneMirroring: { value: false },
  allowVideoConferencingRemoteControl: { value: false },
  allowMediaSharingModification: { value: false },
  // *** Apple Intelligence ***
  allowGenmoji: { value: false },
  allowImagePlayground: { value: false },
  allowPersonalizedHandwritingResults: { value: false },
  allowImageWand: { value: false },
  allowWritingTools: { value: false },
  allowMailSummary: { value: false },
});
