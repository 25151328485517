import { Checkbox } from '@kandji-inc/bumblebee';
import { Flex, Hint, styled } from '@kandji-inc/nectar-ui';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import type { RegisterOptions } from 'react-hook-form';

const StyledCheckboxGroup = styled('div', {
  width: '100%',
  '&.horizontal': {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gap: '$3 0',
  },
  '&.vertical': {
    display: 'flex',
    flexDirection: 'column',
    gap: '$3',
  },
});

const StyledImg = styled('img', {
  size: '$7',
});

const StyledOption = styled(Flex, {
  gap: '$1',
  alignItems: 'center',
  cursor: 'pointer',
  paddingBottom: '$1',
});

export type CheckboxOption = {
  name: string;
  label: string;
  altText?: string;
  icon?: string;
  default?: boolean;
  validations?: RegisterOptions;
};

type Props = {
  options: Array<CheckboxOption>;
  horizontal?: boolean;
};

const ViewCheckbox = ({ options, horizontal }: Props) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  return (
    <StyledCheckboxGroup className={horizontal ? 'horizontal' : 'vertical'}>
      {options.map((v) => (
        <Controller
          key={v.name}
          control={control}
          name={v.name}
          defaultValue={v.default}
          rules={v.validations}
          render={({ field }) => (
            <div>
              <StyledOption onClick={() => field.onChange(!field.value)}>
                <Checkbox checked={field.value} name={v.label} />
                {v.icon && <StyledImg src={v.icon} alt={v.altText} />}
                {v.label}
              </StyledOption>
              <Hint variant="error" label={errors[v.name]?.message as string} />
            </div>
          )}
        />
      ))}
    </StyledCheckboxGroup>
  );
};

export default ViewCheckbox;
