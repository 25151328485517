import { Flex, Icon, styled } from '@kandji-inc/nectar-ui';
import React from 'react';
import {
  StyledContainer,
  StyledLink,
} from '../../../components/styled-components/main';
import Ticket from '../../../components/ticket';
import type { CommonProps, TicketProps } from '../types/integrator.types';
import ViewHeaderV0 from './partials/view-header-v0';

const LayoutContainer = styled(StyledContainer, {
  alignItems: 'center',
  maxWidth: '640px',
  margin: 'auto',
  height: 'inherit',
});

const LinkContainer = styled(Flex, {
  gap: '$6',
  marginTop: '$6',
  marginBottom: '$6',
});

export interface OneColumnInfoProps<InputType, StateType>
  extends CommonProps<InputType, StateType> {
  layout: 'one-column-info';
  header: {
    title: string;
    description: string | ((args: StateType) => string);
  };
  tickets: Array<TicketProps<StateType>>;
  links?: Array<{
    title: string;
    url: string;
  }>;
  state?: StateType;
}

const OneColumnInfo = <InputType, StateType>({
  logo,
  header,
  tickets,
  links,
  state,
}: OneColumnInfoProps<InputType, StateType>) => {
  const descriptionText = ((desc) =>
    typeof desc === 'function' ? desc(state as StateType) : desc)(
    header.description,
  );

  return (
    <LayoutContainer>
      <ViewHeaderV0
        data={{ logo, title: header.title, description: descriptionText }}
      />

      {tickets
        .filter((v) => (v.condition && state ? v.condition(state) : true))
        .map((t) => (
          <Ticket
            key={t.title}
            icon={t.icon}
            text={t.text}
            title={t.title}
            link={t.link}
          />
        ))}

      <LinkContainer>
        {links &&
          links.map((link) => (
            <StyledLink href={link.url} rel="noreferrer" target="_blank">
              {link.title}&nbsp;
              <Icon name="arrow-up-right-from-square" size="sm" />
            </StyledLink>
          ))}
      </LinkContainer>
    </LayoutContainer>
  );
};

export default OneColumnInfo;
