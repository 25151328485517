import { Button, DropdownMenu, Flex, css, styled } from '@kandji-inc/nectar-ui';
import type { CellContext } from '@tanstack/react-table';
import * as React from 'react';
import { Link } from 'react-router-dom';
import { links } from 'src/app/common/constants';
import deviceImagesMap from 'src/components/common/image-device/map';
import { getDeviceIconByFamily } from 'src/features/visibility/prism/utils/column-utils';
import {
  type Device,
  type MDMDevice,
  SearchableText,
} from './ADEListViewTableColumns';

export function ADECellDropdown({ options, children }: any) {
  return (
    <DropdownMenu options={options}>
      <Button
        compact
        variant="inverse"
        icon={{
          name: 'angle-down',
          position: 'right',
        }}
        css={{ width: '100%' }}
      >
        <Flex gap="sm" justifyContent="start" alignItems="center" wFull>
          {children}
        </Flex>
      </Button>
    </DropdownMenu>
  );
}

const truncatedCss = css({
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',

  variants: {
    variant: {
      rowHeaderCell: {
        // need to add some number z-index to override default `z-index: auto`
        // and allow elements like anchor links to stack above its fixed `th`
        // container and be clickable
        zIndex: 1,
      },
    },
  },
});

const truncatedFlexCss = css({
  minWidth: 0,
});

export const NULL_VALUE_N_DASH = '–';
export const CellItalic = styled('span', {
  color: '$text_secondary',
  fontStyle: 'italic',
});

export const ADECellItalic = ({
  device,
  children,
}: {
  device?: Device;
  children: string;
}) => {
  const isAwaitingEnrollment = device.mdm_device == null;

  return isAwaitingEnrollment ? (
    <CellItalic title={children}>{children}</CellItalic>
  ) : (
    <span title={children}>{children}</span>
  );
};

export function getDeviceCell(info: CellContext<Device, MDMDevice>) {
  const device = info.getValue();

  if (device === null) {
    return <CellItalic title="Pending">Pending</CellItalic>;
  }

  const modelId = device.id;
  const deviceFamily = info.row.original?.device_family;

  const icon = deviceImagesMap[modelId] ?? getDeviceIconByFamily(deviceFamily);

  return (
    <Flex
      alignItems="center"
      gap="sm"
      className={truncatedFlexCss().className}
      data-testid="device_cell"
    >
      <img
        data-testid="device_icon_image"
        height="20"
        width="20"
        src={icon}
        alt={modelId ?? deviceFamily}
      />
      <Link
        className={
          truncatedCss({
            variant: 'rowHeaderCell',
          }).className
        }
        to={`${links.devices}/${modelId}`}
        title={device.name}
      >
        <SearchableText>{device.name}</SearchableText>
      </Link>
    </Flex>
  );
}
