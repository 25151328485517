/* istanbul ignore file */
import { useMutation } from '@tanstack/react-query';

import { api } from 'app/api/base';
import { URL_REPORTS_COMPUTERS } from 'app/api/urls';
import { getFileName, saveAs } from 'src/app/components/common/helpers';

const useComputersExport = () => {
  const { mutateAsync: exportComputers, isPending: isPendingExport } =
    useMutation({
      mutationFn: (blueprintId: string) =>
        api(URL_REPORTS_COMPUTERS)
          .get({
            filters: JSON.stringify([
              {
                name: 'blueprint',
                value: [blueprintId],
                operator: 'equals',
              },
            ]),
            fields: JSON.stringify([
              'name',
              'model',
              'last_seen_dt',
              'serial_number',
            ]),
            ordering: 'name',
          })
          .then((res) => {
            const name = getFileName('devices', '.csv');
            const content = `data:text/csv,${encodeURIComponent(res.data)}`;
            saveAs(content, name);
          })
          .catch((e) => e),
    });

  return {
    exportComputers,
    isPendingExport,
  };
};

export default useComputersExport;
