import React, { memo, useEffect, useState } from 'react';
import { v4 as uuid } from 'uuid';
import { useShallow } from 'zustand/react/shallow';

import { Box, Button, Flex, styled } from '@kandji-inc/nectar-ui';

import { RulesContextProvider } from 'features/rules-modal/RulesContext';
import { RulesModal } from 'features/rules-modal/RulesModal';
import type { RuleData } from 'features/rules-modal/types';
import { useFlags } from 'src/config/feature-flags';
import CopyUUID from 'src/features/blueprint-flow/components/CopyUUID';
import { useValidation } from 'src/features/blueprint-flow/hooks';
import useBlueprintFlow from 'src/features/blueprint-flow/store';
import { FlowTippy, TippyContainer } from 'src/features/blueprint-flow/theme';
import BuilderModal from 'src/features/rules/library/builder-modal';
import { supportedDeviceFamilies } from '../../../config';
import { ASSIGNMENT_TYPES } from '../../../constants';
import { BuilderProvider } from '../../../services';
import DeleteButton from '../actions/DeleteButton';
import TagList from './rule-tag-list';
import Statement from './statement';

const RulesActionButton = styled(Button, {
  padding: '4px !important',
  '& svg': {
    height: '16px',
    width: '16px',
  },

  '&:not(:hover)': {
    backgroundColor: 'inherit !important',
  },
});

function Rules(props: {
  nodeId: string;
  rules: RuleData | null;
  type: string;
  isError?: boolean;
  updateRules: (rules: object) => void;
  onDelete: () => void;
  canDelete: boolean;
  isDisabled: boolean;
}) {
  const {
    nodeId,
    rules,
    type,
    isError,
    updateRules,
    onDelete,
    canDelete,
    isDisabled,
  } = props;

  const { 'DC-610-nectar-rules-modal': LD_nectarRulesModal } = useFlags([
    'DC-610-nectar-rules-modal',
  ]);

  let Provider;
  let Modal;

  if (LD_nectarRulesModal) {
    Provider = RulesContextProvider;
    Modal = RulesModal;
  } else {
    Provider = BuilderProvider;
    Modal = BuilderModal;
  }

  const [
    facetMap,
    isEditingAssignments,
    isOptionPressed,
    countOfUserDirectoryIntegrations,
    hasUserDirectoryIntegration,
  ] = useBlueprintFlow(
    useShallow((state) => [
      state.facetMap,
      state.isEditingAssignments,
      state.isOptionPressed,
      state.countOfUserDirectoryIntegrations,
      state.hasUserDirectoryIntegration,
    ]),
  );

  const { runValidation } = useValidation();

  const [model, setModel] = useState({ rules });
  const [isHovering, setIsHovering] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const isElse = type === ASSIGNMENT_TYPES.else;

  const handleRulesClick = () => setIsOpen(true);

  // Per the Library Items implementation, the Rule Builder expects and uses
  // a `setModel` function passed into it, which is just a `useState` updater
  // function, so this `model` state has its `rules` key value passed into the
  // Flow Blueprint's `updateRules` function every time the `model` changes
  // (this happens each time the Rule Builder modal has any of its changes
  // confirmed).
  useEffect(() => {
    // Include an id with rules to allow map searching to function.
    const rules: any = model?.rules;
    updateRules(
      rules
        ? {
            and: rules.and.map((rule) => {
              if (rule.or) {
                return {
                  ...rule,
                  or: rule.or.map((orRule) => ({ ...orRule, id: uuid() })),
                };
              }

              return { ...rule, id: uuid() };
            }),
          }
        : rules,
    );
  }, [model]);

  // Run validation if the node was previously in an error
  // state and the modal was closed. This validation should
  // only remove the error state, it should never add it.
  useEffect(() => {
    if (!isOpen && isError) {
      runValidation();
    }
  }, [isOpen]);

  const kind = type;
  const canViewEdit = isHovering && !isElse && isEditingAssignments;
  const canViewDelete = isHovering && isEditingAssignments;

  const isShowingNodeId = isOptionPressed && !isEditingAssignments;

  return (
    <Provider
      rules={rules}
      facetMap={facetMap}
      setModel={setModel}
      supportedDeviceFamilies={supportedDeviceFamilies}
      installOnDeviceFamilies={supportedDeviceFamilies}
      countOfUserDirectoryIntegrations={countOfUserDirectoryIntegrations}
      hasUserDirectoryIntegration={hasUserDirectoryIntegration}
      afterSubmit={/* istanbul ignore next */ () => setIsOpen(false)}
    >
      <CopyUUID nodeId={nodeId} />

      <Flex
        css={{
          gap: '10px',
          overflow: 'hidden',
          ...(isShowingNodeId ? { zIndex: 10 } : {}),
        }}
        onMouseEnter={() => setIsHovering(true)}
        onMouseLeave={() => setIsHovering(false)}
      >
        <Box>
          <Statement
            css={{ paddingTop: '3px' }}
            kind={isElse ? 'else' : kind}
          />
        </Box>
        <Flex css={{ gap: '6px' }} flex="1" wrap="wrap" alignItems="center">
          {!isElse && (
            <TagList
              rules={rules}
              facetMap={facetMap}
              compact={kind === ASSIGNMENT_TYPES.elseif}
            />
          )}
        </Flex>
        <Flex alignItems="start">
          {!isElse && (
            <FlowTippy
              content={<TippyContainer gap="xs">Edit rules</TippyContainer>}
            >
              <RulesActionButton
                compact
                variant="subtle"
                icon={{ name: 'pen' }}
                onClick={handleRulesClick}
                disabled={isDisabled}
                css={{
                  pointerEvents: isEditingAssignments ? 'unset' : 'none',
                  visibility: canViewEdit ? 'unset' : 'hidden',
                  '& svg': {
                    height: '16px',
                    width: '16px',
                  },
                }}
                data-testid="open-rules"
              />
            </FlowTippy>
          )}
          {canDelete && (
            <DeleteButton
              name="clear-assignment"
              onClick={onDelete}
              disabled={isDisabled}
              css={{
                pointerEvents: isEditingAssignments ? 'unset' : 'none',
                visibility: canViewDelete ? 'unset' : 'hidden',
              }}
              tooltip={`${isElse ? 'Clear' : 'Delete'} assignment node`}
            />
          )}
        </Flex>
      </Flex>

      <Modal
        origin="flow"
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        disableCloseOnOutsideClick
      />
    </Provider>
  );
}

export default memo(Rules);
