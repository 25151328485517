import { sortBy } from 'lodash';
import React from 'react';
import styled from 'styled-components';

import { i18n } from 'i18n';
import SelectDropdown from '../../../../../components/select-dropdown';
import { useMSTeamsTeams } from '../../../hooks';

const Container = styled.div`
  display: grid;
  gap: var(--b-gap1);
`;

const TeamsSelect = (props) => {
  const { onChange, value, integrationId } = props;

  const { data: msTeamsTeamsData } = useMSTeamsTeams(integrationId);

  const teamsData = msTeamsTeamsData?.data?.teams;

  const hasSelectedTeam = value && Object.keys(value).length !== 0;

  const teamsSorted = sortBy(teamsData, ['name']);

  return (
    <Container className="b-gap1">
      <div className="b-gap1">
        <label htmlFor="team-select" className="b-txt">
          {i18n.t('Team')}
        </label>
        <p className="b-txt-light">
          {i18n.t(
            'Select the team this event notification should be assigned to.',
          )}
        </p>
      </div>
      <SelectDropdown
        inputId="team-select"
        value={hasSelectedTeam ? value : ''}
        options={teamsSorted}
        onChange={onChange}
        placeholder={i18n.t('Search or select from list')}
        hideSearchIcon
      />
    </Container>
  );
};

export default TeamsSelect;
