import React from 'react';
import history from '../../../router/history';
import UniversalAlert from './UniversalAlert';

export const VPPWarningBanner = ({ manager }) => (
  <UniversalAlert
    type="danger"
    text={
      manager
        ? `Kandji no longer manages your Apps and Books. They are currently being managed by ${manager}.`
        : 'Kandji no longer manages your Apps and Books.'
    }
    button={{
      text: 'RECLAIM APPS AND BOOKS',
      onClick: () =>
        history.push({
          pathname: '/my-company/integrations',
          state: {
            anchor: 'apps-and-books',
          },
        }),
    }}
  />
);
