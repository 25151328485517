import { styled } from '@kandji-inc/nectar-ui';

export const Content = styled('div', {
  height: '100%',
});

export const StyledImg = styled('img', {
  width: 72,
});

export const Container = styled('div', {
  display: 'grid',
  gridTemplateColumns: '72px 1fr',
  [`& > ${Content}`]: {
    gridColumnStart: 2,
    marginLeft: '$3',
  },
  variants: {
    subData: {
      true: {
        backgroundColor: '#fafcff',
        margin: '0 -16px -16px -16px',
      },
    },
    blueprintContainer: {
      true: {
        height: '56px',
        marginTop: '-18px',
        alignContent: 'center',
        '&:hover': {
          backgroundColor: '$table_row_background_hover',
          cursor: 'pointer',
        },
      },
    },
    isBlueprintContainerOpen: {
      false: {
        marginBottom: '-18px',
      },
    },
  },
});

export const StyledLink = styled('a', {
  fontFamily: '$primary',
  color: 'LinkText',
  '&:hover': {
    color: '$blue60',
  },
});

export const ChipContainer = styled('div', {
  display: 'flex',
  flexDirection: 'row',
  gap: '$1',
});

export const BlueprintScopingContainer = styled('div', {
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  gap: '$3',
  marginLeft: '12px',
});

export const StyledRowDetails = styled('div', {
  gridColumnStart: '2',
  marginLeft: '16px',
});

export const StyledExpandedDetailsRow = styled('div', {
  padding: '18px 60px 18px 0',
  display: 'flex',
  boxShadow: 'inset 0px -1px 0px rgba(26, 29, 37, 0.05)',
  marginLeft: '12px',
  [`& > span`]: {
    [`&:nth-of-type(1)`]: {
      width: '100%',
      maxWidth: '260px',
    },
  },
});
