import { useQuery } from '@tanstack/react-query';
import { servicenowService } from '../services';
import { servicenowKeys } from './query-key-factory';

export const useGetServicenowIntegration = (integrationId: string) =>
  useQuery({
    queryKey: servicenowKeys.details(integrationId),

    queryFn: () => servicenowService.getIntegration(integrationId),
    staleTime: Infinity,
  });
