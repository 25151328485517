/* istanbul ignore file */

import React, { createContext, useState, useContext } from 'react';

import { node, object } from 'prop-types';

const ModalContext = createContext({
  modal: {},
  modalSlideIdx: 0,
  modalCreateConnector: true,
  includeAuthToken: false,
  isOpen: false,
  slideComponent: null,
  setModal: () => {},
  setModalSlideIdx: () => {},
  setModalCreateConnector: () => {},
  setIncludeAuthToken: () => {},
  nextModal: (name) => {},
  openModal: () => {},
  closeModal: () => {},
  previousModal: () => {},
});

const ModalProvider = ({ children, value }) => {
  const [modal, setModal] = useState({});
  const [modalSlideIdx, setModalSlideIdx] = useState(0);
  const [includeAuthToken, setIncludeAuthToken] = useState(false);
  const [modalCreateConnector, setModalCreateConnector] = useState(true);
  const [isOpen, setIsOpen] = useState(false);
  const [slideComponent, setSlideComponent] = useState(null);

  const openModal = (name, slideIdx, createConnector = true) => {
    setModalCreateConnector(createConnector);
    setModalSlideIdx(slideIdx || 0);
    setModal({ [name]: true });
    setIsOpen(true);
  };

  const closeModal = (closed) => {
    setIsOpen(false);
    setModal({});
    setSlideComponent(null);
    setTimeout(() => {
      setModalSlideIdx(0);
      if (closed) {
        closed();
      }
    }, 500);
  };

  const nextModal = (name) => {
    if (name) {
      setSlideComponent(name);
    }
    setModalSlideIdx((current) => current + 1);
  };

  const previousModal = () => {
    if (slideComponent) {
      setSlideComponent(null);
    }
    setModalSlideIdx((current) => current - 1);
  };

  return (
    <ModalContext.Provider
      value={{
        modal,
        modalSlideIdx,
        modalCreateConnector,
        includeAuthToken,
        nextModal,
        previousModal,

        setModal,
        setModalSlideIdx,
        setModalCreateConnector,
        setIncludeAuthToken,
        isOpen,
        openModal,
        closeModal,

        slideComponent,

        ...value,
      }}
    >
      {children}
    </ModalContext.Provider>
  );
};

ModalProvider.propTypes = {
  children: node.isRequired,
  value: object,
};

ModalProvider.defaultProps = {
  value: {},
};

const useModal = () => useContext(ModalContext);

export default useModal;
export { ModalContext, ModalProvider };
