import React, { memo } from 'react';

import type { CSS } from '@kandji-inc/nectar-ui';
import { Text, theme } from '@kandji-inc/nectar-ui';

import { statementLineHeight } from '../../../theme';

type StatementProps = {
  kind?: string;
  css?: CSS;
};

function Statement(props: StatementProps) {
  const { kind, css = {} } = props;
  const copy = {
    for: 'For',
    if: 'If',
    else: 'else',
    elseif: 'else if',
  };

  const styles = {
    lineHeight: statementLineHeight,
    color: theme.colors.green60.value,
    fontWeight: '$semibold',
    fontFamily: '$code',
    whiteSpace: 'nowrap',
    ...css,
  };

  return <Text css={styles}>{copy[kind]}</Text>;
}

export default memo(Statement);
