import React from 'react';

import { Flex } from '@kandji-inc/nectar-ui';

import type { LibraryItem } from '../blueprint-flow.types';

function Avatar(props: { item: LibraryItem }) {
  const { item } = props;
  const { name, icon, defaultConfiguration } = item;
  const avatar = icon || defaultConfiguration?.icon;

  return (
    <Flex alignItems="center" css={{ width: '$5', height: '$5', flex: 'none' }}>
      {avatar ? (
        <img
          src={avatar}
          alt={name}
          style={{ width: '100%', height: 'auto', userSelect: 'none' }}
        />
      ) : null}
    </Flex>
  );
}

export default Avatar;
