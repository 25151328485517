import { Button, Flex, styled } from '@kandji-inc/nectar-ui';
import { useADEFetchDevices } from '../hooks/useADEFetchDevices';
import { formatLastFetchTime } from '../utils/formatLastFetchTime';
import { ADEFetchDevices, LastFetchContainer } from './ADEFetchDevices';
import NoDevices from './assets/no_devices.png';

const ADETableNoDevicesContainer = styled('div', {
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '$10',
});

const Image = styled('img', {
  marginBottom: '16px',
});

const Header = styled('span', {
  color: '$text_primary',
  fontWeight: '$medium',
});

const Description = styled('span', {
  marginBottom: '14px',
  width: 532,
  textAlign: 'center',
});

const FetchContainer = styled('div', {
  marginLeft: 'auto',
  paddingRight: '$5',
});

export function ADETableNoAwaitingDevices({
  setIsAllDevices,
  lastSync,
  sync,
  refetchDevices,
}) {
  const { currentTime } = useADEFetchDevices(lastSync, sync, refetchDevices);

  return (
    <>
      {lastSync != null && (
        <FetchContainer>
          <LastFetchContainer>
            Last fetch {formatLastFetchTime(lastSync, currentTime)} ago
          </LastFetchContainer>
        </FetchContainer>
      )}
      <ADETableNoDevicesContainer>
        <Image src={NoDevices} />
        <Header>No devices awaiting enrollment</Header>
        <Description>
          If you recently added devices to Kandji in Apple Business Manager, try
          fetching devices to refresh this list.
        </Description>
        <Flex gap="sm">
          <ADEFetchDevices
            lastSync={lastSync}
            sync={sync}
            refetchDevices={refetchDevices}
            hideLastFetch
          />
          <Button
            variant="default"
            onClick={() => setIsAllDevices(true)}
            compact
          >
            View all Automated Device Enrollment devices
          </Button>
        </Flex>
      </ADETableNoDevicesContainer>
    </>
  );
}

export function ADETableNoDevices({ lastSync, sync, refetchDevices }) {
  const { currentTime } = useADEFetchDevices(lastSync, sync, refetchDevices);

  return (
    <>
      {lastSync != null && (
        <FetchContainer>
          <LastFetchContainer>
            Last fetch {formatLastFetchTime(lastSync, currentTime)} ago
          </LastFetchContainer>
        </FetchContainer>
      )}
      <ADETableNoDevicesContainer>
        <Image src={NoDevices} />
        <Header>No Automated Device Enrollment devices</Header>
        <Description>
          If you recently added devices to Kandji in Apple Business Manager, try
          fetching devices to refresh this list.
        </Description>
        <ADEFetchDevices
          lastSync={lastSync}
          sync={sync}
          refetchDevices={refetchDevices}
          hideLastFetch
        />
      </ADETableNoDevicesContainer>
    </>
  );
}
