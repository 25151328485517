import { Button, NoContent } from '@kandji-inc/bumblebee';
import { Text, Tooltip, TooltipProvider, styled } from '@kandji-inc/nectar-ui';
import { i18n } from 'i18n';
import React from 'react';

const ButtonContainer = styled('div', {
  display: 'grid',
  justifyContent: 'center',
  paddingBottom: '$6',
});

type Props = {
  onButtonClick: () => void;
  isButtonDisabled: boolean;
};

export function NoNotificationsMessage({
  onButtonClick,
  isButtonDisabled,
}: Props) {
  return (
    <TooltipProvider>
      <NoContent
        header={i18n.t('You have not set up any event notifications')}
        text={i18n.t(
          'Add an event notification below to be alerted on specific events that are triggered by the system on a device.',
        )}
        className="integration-card_table__no-data-msg"
      />
      <ButtonContainer>
        <Tooltip
          side="right"
          theme="dark"
          interactive
          css={isButtonDisabled ? {} : { display: 'none' }}
          // @ts-expect-error -- TODO fix this
          content={
            <Text>
              {i18n.t('Loading Slack channels. Please check back in soon.')}
            </Text>
          }
        >
          <span>
            <Button
              icon="circle-plus"
              kind="link"
              onClick={() => onButtonClick()}
              isDisabled={isButtonDisabled}
              className="cmn-button--size-medium"
            >
              {i18n.t('Add event notification')}
            </Button>
          </span>
        </Tooltip>
      </ButtonContainer>
    </TooltipProvider>
  );
}
