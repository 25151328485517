import OldZip from 'features/library-items/items/custom-apps/icons/old_zip.png';
import React from 'react';
import NewLibraryItemService from '../../../data-service/library-item/new-library-item-service';

const ONE_MB_IN_BYTES = 1024 * 1024;
const FIFTEEN_MB_IN_BYTES = ONE_MB_IN_BYTES * 15;

class KandjiLoginService extends NewLibraryItemService {
  static tempFeatureFlagGoogleIdpIdentityProviders = {
    GOOGLE_WORKSPACE: 'Google Workspace',
    MICROSOFT_AZURE: 'Microsoft Azure',
    OKTA: 'Okta',
    ONE_LOGIN: 'OneLogin',
    OTHER: 'Identity Provider',
  };

  static identityProviders = {
    MICROSOFT_AZURE: 'Microsoft Azure',
    OKTA: 'Okta',
    ONE_LOGIN: 'OneLogin',
    OTHER: 'Identity Provider',
  };

  static idpUpload = {
    GOOGLE_WORKSPACE: {
      key: 'googleIdpCertFile',
      allowedFiledTypes: ['.zip'],
      maxFileSize: FIFTEEN_MB_IN_BYTES,
      fileIcons: {
        '.zip': (
          <img src={OldZip} alt="Upload Google Idp Certificate .zip file" />
        ),
      },
    },
  };

  static idpHumanNames = {
    [this.identityProviders.MICROSOFT_AZURE]: 'Microsoft Entra ID',
    [this.identityProviders.OKTA]: 'Okta',
    [this.identityProviders.ONE_LOGIN]: 'OneLogin',
    [this.identityProviders.OTHER]: null,
  };

  static authenticationMode = {
    MAC_LOGIN: 'user-pass',
    WEB_LOGIN: 'mfa',
  };
}

export const kandjiLoginService = new KandjiLoginService();
export default KandjiLoginService;
