/* istanbul ignore file */
import { useReactFlow } from 'reactflow';
import { useShallow } from 'zustand/react/shallow';

import { apiTypes } from 'src/features/library-items/library/common';

import type { LibraryItem } from '../blueprint-flow.types';

import { DESCENDANT_TYPES, NODE_TYPES } from '../constants';
import { getItemConfig, getLibraryItem } from '../helpers';

import useBlueprintFlow from '../store';
import useAncestry from './use-ancestry';

function useMap() {
  const { getNode, getNodes } = useReactFlow();
  const { getUniqueDescendants } = useAncestry();

  const [libraryItems, setConflictingLibraryItems] = useBlueprintFlow(
    useShallow((state) => [
      state.libraryItems,
      state.setConflictingLibraryItems,
    ]),
  );

  /**
   * Updates the list of Conflicting Library Item ids in the global state
   */
  const updateLibraryItemConflicts = () => {
    const nodes = getNodes();
    const nodesAndDescendants = {};
    const newConflictingLibraryItemIds = new Set<LibraryItem['flowId']>();

    // HOTFIX - disable recursion checking for LI conflicts for the time being
    // Get the Assignment descendants of all Assignment and Start nodes
    // nodes.forEach((node) => {
    //   if ([NODE_TYPES.assignment, NODE_TYPES.start].includes(node.type)) {
    //     nodesAndDescendants[node.id] = getUniqueDescendants(node.id).filter(
    //       (desc) => desc.descendantType === DESCENDANT_TYPES.assignment,
    //     );
    //   }
    // });

    // Walk through each node and its descendants to find conflicting Library Items
    // Object.keys(nodesAndDescendants).forEach((nodeId) => {
    //   const currentLibraryItems = getNode(nodeId)?.data.items;
    //   const currentDescendants = nodesAndDescendants[nodeId];

    //   currentDescendants.forEach((desc) => {
    //     const descLibraryItems = getNode(desc.id)?.data.items;

    //     if (currentLibraryItems && descLibraryItems && libraryItems.length) {
    //       currentLibraryItems.forEach((currentLibraryItem) => {
    //         const currentLibraryItemData = getLibraryItem(
    //           currentLibraryItem.data.id,
    //           libraryItems,
    //         );
    //         const currentLibraryItemConfig = getItemConfig(
    //           currentLibraryItemData,
    //         );

    //         descLibraryItems.forEach((descLibraryItem) => {
    //           const descLibraryItemData = getLibraryItem(
    //             descLibraryItem.data.id,
    //             libraryItems,
    //           );
    //           const descLibraryItemConfig = getItemConfig(descLibraryItemData);

    //           // If the Library Items are not the exact same Library Item, are self-conflicting,
    //           // and are of the same type, they may conflict with each other
    //           if (
    //             currentLibraryItem.data.id !== descLibraryItem.data.id &&
    //             (currentLibraryItemConfig.singleBlueprintAllowed ||
    //               currentLibraryItemConfig.singleAssignmentMappingAllowed) &&
    //             currentLibraryItemConfig.type === descLibraryItemConfig.type
    //           ) {
    //             let areConflicting = true;

    //             // If the Library Items are Profiles, they conflict if they have the same identifier
    //             if (currentLibraryItemConfig.type === apiTypes.PROFILE) {
    //               areConflicting =
    //                 currentLibraryItemConfig.identifier ===
    //                 descLibraryItemConfig.identifier;
    //             }

    //             // If the Library Items are Auto Apps, they conflict if they have the same Managed Library Item Id
    //             if (currentLibraryItemConfig.type === apiTypes.AUTO_APP) {
    //               areConflicting =
    //                 currentLibraryItemData.managedLibraryItemId ===
    //                 descLibraryItemData.managedLibraryItemId;
    //             }

    //             if (areConflicting) {
    //               // Add the flow ids of conflicting Library Items
    //               newConflictingLibraryItemIds.add(currentLibraryItem.id);
    //               newConflictingLibraryItemIds.add(descLibraryItem.id);
    //             }
    //           }
    //         });
    //       });
    //     }
    //   });
    // });

    setConflictingLibraryItems(Array.from(newConflictingLibraryItemIds));
  };

  return { updateLibraryItemConflicts };
}

export default useMap;
