/* istanbul ignore file */
import { createColumnHelper } from '@tanstack/react-table';
import type { z } from 'zod';

import {
  ON_OFF_CELL_FILTER_OPTIONS,
  YES_NO_CELL_FILTER_OPTIONS,
  YesNoCell,
  extractFilterableColumns,
  getFilteredDisabled as filterDisabled,
  getSpecificDeviceFamilyCell,
} from '../column-utils';
import { getGlobalColumns } from './globalColumns';

export const getActivationLockColumns = (schema) => {
  if (!schema) {
    return { columnDefs: [], filterableColumns: [] };
  }

  const activationLockColumnHelper =
    createColumnHelper<z.infer<typeof schema>>();
  const globalColumns = getGlobalColumns(schema, activationLockColumnHelper);

  const columnDefs = [
    ...globalColumns.startColumnDefs,

    activationLockColumnHelper.accessor(
      (row) => row.device_activation_lock_status,
      {
        id: 'device_activation_lock_status',
        cell: (info) =>
          getSpecificDeviceFamilyCell(info, ['iPad', 'iPhone'], 'on-off-badge'),
        meta: {
          displayName: 'Device Activation Lock',
          filterType: 'boolean',
          filterOptions: [...ON_OFF_CELL_FILTER_OPTIONS],
          filterIcon: 'circle-dot',
          filterDisabled,
        },
      },
    ),

    activationLockColumnHelper.accessor((row) => row.device_albc, {
      id: 'device_albc',
      cell: (info) =>
        getSpecificDeviceFamilyCell(info, ['iPad', 'iPhone'], 'boolean'),
      meta: {
        displayName: 'Device Bypass Code Escrowed',
        filterType: 'boolean',
        filterOptions: [...YES_NO_CELL_FILTER_OPTIONS],
        filterIcon: 'circle-dot',
        filterDisabled,
      },
    }),

    activationLockColumnHelper.accessor(
      (row) => row.user_activation_lock_status,
      {
        id: 'user_activation_lock_status',
        cell: (info) =>
          getSpecificDeviceFamilyCell(
            info,
            ['Mac', 'AppleTV', 'iPad', 'iPhone'],
            'on-off',
          ),
        meta: {
          displayName: 'User Activation Lock',
          filterType: 'boolean',
          filterOptions: [...ON_OFF_CELL_FILTER_OPTIONS],
          filterIcon: 'circle-dot',
          filterDisabled,
        },
      },
    ),

    activationLockColumnHelper.accessor((row) => row.user_albc_escrowed, {
      id: 'user_albc_escrowed',
      cell: (info) =>
        getSpecificDeviceFamilyCell(info, ['iPad', 'iPhone', 'Mac'], 'boolean'),
      meta: {
        displayName: 'User Bypass Code Escrowed',
        filterType: 'boolean',
        filterOptions: [...YES_NO_CELL_FILTER_OPTIONS],
        filterIcon: 'circle-dot',
        filterDisabled,
      },
    }),

    activationLockColumnHelper.accessor((row) => row.bypass_code_expired, {
      id: 'bypass_code_expired',
      cell: (info) =>
        getSpecificDeviceFamilyCell(info, ['iPad', 'iPhone', 'Mac'], 'boolean'),
      meta: {
        defaultHidden: true,
        displayName: 'Bypass Code Expired',
        filterType: 'boolean',
        filterOptions: [...YES_NO_CELL_FILTER_OPTIONS],
        filterIcon: 'circle-dot',
        filterDisabled,
      },
    }),

    activationLockColumnHelper.accessor(
      (row) => row.user_activation_lock_allowed,
      {
        id: 'user_activation_lock_allowed',
        cell: YesNoCell,
        meta: {
          displayName: 'User Activation Lock Allowed',
          filterType: 'boolean',
          filterOptions: [...YES_NO_CELL_FILTER_OPTIONS],
          filterIcon: 'circle-dot',
          filterDisabled,
        },
      },
    ),

    activationLockColumnHelper.accessor(
      (row) => row.is_activation_lock_supported,
      {
        id: 'is_activation_lock_supported',
        cell: YesNoCell,
        meta: {
          defaultHidden: true,
          displayName: 'Activation Lock Supported',
          filterType: 'boolean',
          filterOptions: [...YES_NO_CELL_FILTER_OPTIONS],
          filterIcon: 'circle-dot',
          filterDisabled,
        },
      },
    ),

    ...globalColumns.endColumnDefs,
  ];

  const filterableColumns = extractFilterableColumns(columnDefs);

  return { columnDefs, filterableColumns };
};
