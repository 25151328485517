import React from 'react';
import {
  GenericFooterButtons,
  GenericNameSlide,
} from '../../generic/user-integrations/welcome-modal';
import largeIcon from '../assets/large-icon.svg';
import smallIcon from '../assets/small-icon.svg';

function NameSlide(props) {
  return (
    <GenericNameSlide {...props} icon={largeIcon} typeName="Google Workspace" />
  );
}

function FooterButtons(props) {
  return <GenericFooterButtons {...props} icon={smallIcon} typeName="Google" />;
}

export { NameSlide, FooterButtons };
