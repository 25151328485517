import { Icon, TextInput, setClass } from '@kandji-inc/bumblebee';
import { AccountContext } from 'contexts/account';
import React, { useContext, useEffect, useState } from 'react';
import './common.css';

const customText = {
  label: 'Custom Text',
  value: 'Custom Text',
  key: 'custom_text',
};

const patterns = [
  {
    label: 'Model name',
    value: 'MacBook Pro',
    key: 'model_name',
  },
  {
    label: 'Assigned user',
    value: 'Heather Smith',
    key: 'kandji_user_name',
  },
  {
    label: 'Asset tag',
    value: 'K023',
    key: 'asset_id',
  },
  {
    label: 'Blueprint name',
    value: 'blueprint_name',
    key: 'blueprint_name',
  },
  {
    label: 'Company name',
    value: 'company_name',
    key: 'company_name',
  },
  {
    label: 'Serial number',
    value: 'S123456789',
    key: 'serial_number',
  },
  {
    label: 'Primary local user name',
    value: 'Morgan Freeman',
    key: 'primary_local_user_name',
  },
];

const DraggableItem = (props) => {
  const {
    item,
    onDragOver,
    isItemHovered,
    isSelected,
    setActiveItem,
    isActive,
    isEditing,
    onEdit,
    onChange,
    onSave,
    isDisabled,
  } = props;

  return (
    <div
      draggable={!isDisabled}
      onClick={onEdit}
      onDragOver={(e) => onDragOver && onDragOver(e, item)}
      onDragStart={(e) => {
        setActiveItem(item);
        e.dataTransfer.setData('text/plain', JSON.stringify(item));
      }}
      onDragEnd={() => setActiveItem()}
      className={`bl-p-computer-name-option ${
        isActive ? '--is-dragging' : ''
      } ${isItemHovered && !isActive ? '--hovered' : ''} ${
        isSelected ? '--selected' : ''
      }`}
    >
      {isEditing ? (
        <div className="b-flex-gtiny">
          <input
            type="text"
            className="b-txt"
            value={item.label}
            onChange={onChange}
          />
          <a
            className="b-decorate-off b-flex-vc"
            href=""
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              onSave();
            }}
          >
            <Icon
              name="check-bold"
              style={{ color: 'var(--color-neutral-0)' }}
            />
          </a>
        </div>
      ) : (
        <p className="b-txt">{item.label}</p>
      )}
    </div>
  );
};

const SetComputerName = (props) => {
  const { param, update, isDisabled, blueprint, validations } = props;
  const { details } = param;
  const { currentCompany } = useContext(AccountContext);
  const [value, setValue] = useState(details || { pattern: [] });
  const [hoveredOver, setHoveredOver] = useState();
  const [hoveredOverItem, setHoveredOverItem] = useState();
  const [activeItem, setActiveItem] = useState();
  const [currentEditingItem, setCurrentEditingItem] = useState();

  const {
    SetComputerName: { displayInvalid },
  } = validations;

  useEffect(() => {
    if (isDisabled) {
      setValue(details);
    }
  }, [param, isDisabled]);

  useEffect(() => {
    if (!isDisabled) {
      update({ details: value, isInvalid: !value.pattern.length });
    }
  }, [value]);

  const onDrop = (dropArea) => (e) => {
    e.preventDefault();
    setHoveredOver();
    setActiveItem();
    setCurrentEditingItem();
    const data = JSON.parse(e.dataTransfer.getData('text/plain'));
    const isKeyInPattern = value.pattern.includes(data.key);

    if (!isKeyInPattern && dropArea === 'bank') {
      return;
    }

    // When dropping from the pattern, onto another key in the pattern, we sort.
    if (isKeyInPattern && dropArea === 'pattern') {
      if (!hoveredOverItem) {
        return;
      }
      const hoveredIdx = value.pattern.findIndex(
        (key) => hoveredOverItem.key === key,
      );
      const dataIdx = value.pattern.findIndex((key) => data.key === key);
      setValue((prev) => {
        const tmp = prev.pattern[hoveredIdx];
        prev.pattern[hoveredIdx] = prev.pattern[dataIdx];
        prev.pattern[dataIdx] = tmp;
        return {
          ...prev,
        };
      });
      return;
    }

    setValue((prev) => {
      if (prev.pattern.includes(data.key)) {
        return {
          ...prev,
          pattern: prev.pattern.filter((key) => data.key !== key),
        };
      }
      const isCustomText = data.key === 'custom_text';
      let randomNum;
      if (isCustomText) {
        while (
          !randomNum ||
          (prev.custom_text && prev.custom_text[`custom_text_${randomNum}`])
        ) {
          randomNum = Math.floor(Math.random() * (999 - 100 + 1) + 100);
        }
      }
      return {
        ...prev,
        ...(isCustomText
          ? {
              custom_text: {
                ...(prev.custom_text || {}),
                [`custom_text_${randomNum}`]: customText.value,
              },
            }
          : {}),
        pattern: [
          ...prev.pattern,
          isCustomText ? `custom_text_${randomNum}` : data.key,
        ],
      };
    });
  };

  const onDragOver = (from) => (e) => {
    e.preventDefault();
    e.dataTransfer.dropEffect = 'move';
    setHoveredOver(from);
  };

  const onDragEnd = (e) => {
    e.preventDefault();
    setHoveredOver();
    setHoveredOverItem();
  };

  const onDragLeave = (e) => {
    e.preventDefault();
    setHoveredOver();
    setHoveredOverItem();
  };

  const onHoverOverItem = (e, item) => {
    const isKeyInPattern = value.pattern.includes(activeItem?.key);
    if (isKeyInPattern) {
      setHoveredOverItem(item);
    }
  };

  return (
    <div className="b-flex-vg1">
      <div
        className={setClass(
          'bl-p-computer-name-options',
          hoveredOver === 'bank' && '--hovered',
        )}
        onDrop={onDrop('bank')}
        onDragOver={onDragOver('bank')}
        onDragLeave={onDragLeave}
        onDragEnd={onDragEnd}
      >
        <DraggableItem item={customText} setActiveItem={setActiveItem} />
        {patterns
          .filter(({ key }) => !value.pattern.includes(key))
          .map((pattern) => {
            return (
              <DraggableItem
                key={pattern.key}
                item={pattern}
                isActive={activeItem?.key === pattern.key}
                setActiveItem={(item) => {
                  setActiveItem(item);
                  setCurrentEditingItem();
                }}
                isDisabled={isDisabled}
              />
            );
          })}
      </div>
      <p className={`b-txt${isDisabled ? '-light2' : ''}`}>Pattern</p>
      <div
        className={setClass(
          'bl-p-computer-name-patterns',
          hoveredOver === 'pattern' && '--hovered',
        )}
        onDrop={onDrop('pattern')}
        onDragOver={onDragOver('pattern')}
        onDragLeave={onDragLeave}
        onDragEnd={onDragEnd}
      >
        {value.pattern.map((pattern) => {
          const foundPattern = patterns.find(({ key }) => pattern === key);
          const isCustomText =
            value.custom_text && pattern in value.custom_text;
          const customTextPattern = {
            key: pattern,
            value: isCustomText && value.custom_text[pattern],
            label: isCustomText && value.custom_text[pattern],
          };
          return (
            <DraggableItem
              key={pattern}
              isDisabled={isDisabled}
              item={foundPattern || customTextPattern}
              onDragOver={onHoverOverItem}
              setActiveItem={(item) => {
                setActiveItem(item);
                setCurrentEditingItem();
              }}
              isActive={activeItem?.key === pattern}
              isItemHovered={hoveredOverItem?.key === pattern}
              onEdit={() =>
                isCustomText ? setCurrentEditingItem(customTextPattern) : null
              }
              isEditing={currentEditingItem?.key === pattern}
              onChange={(e) => {
                const val = e.target.value;
                setValue((prev) => {
                  prev.custom_text[pattern] = val;
                  return { ...prev };
                });
              }}
              onSave={() => {
                if (!value.custom_text[pattern]) {
                  setValue((prev) => {
                    prev.custom_text[pattern] = customText.value;
                    return { ...prev };
                  });
                }
                setCurrentEditingItem(null);
              }}
              isSelected
            />
          );
        })}
      </div>
      <TextInput
        readOnly
        fieldsGrid
        disabled={isDisabled}
        label="Preview"
        value={value.pattern
          .map((pattern) => {
            const patternTemplate = patterns.find(({ key }) => key === pattern);
            if (pattern === 'company_name' && currentCompany?.name) {
              return currentCompany.name;
            }
            if (pattern === 'blueprint_name' && blueprint?.name) {
              return blueprint.name;
            }

            return (
              patternTemplate?.value ||
              (value.custom_text && value.custom_text[pattern]) ||
              pattern
            );
          })
          .join(' ')}
      />

      {displayInvalid(!value.pattern.length, {
        imperativeHandleElement: (
          errMsgEl,
          { dataAttrsTuples: [[_, dataNameValue]] },
        ) => {
          const prevSiblingEl = errMsgEl.previousElementSibling;

          if (!prevSiblingEl) {
            return;
          }

          prevSiblingEl.setAttribute(
            `data-sync-validation-${dataNameValue}-link`,
            dataNameValue,
          );
          prevSiblingEl.style.marginBottom = 0;
        },
        imperativeOnElementUnmount: (
          _nullRef,
          { dataAttrsTuples: [[_, dataNameValue]] },
        ) => {
          const prevSiblingEl = document.querySelector(
            `[data-sync-validation-${dataNameValue}-link="${dataNameValue}"]`,
          );

          if (prevSiblingEl) {
            prevSiblingEl.style.marginBottom = '';
          }
        },
      })}
    </div>
  );
};

export default SetComputerName;
