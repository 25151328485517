import { useQuery } from '@tanstack/react-query';
import { newLibraryItemService } from 'src/features/library-items/data-service/library-item/new-library-item-service';
import { libraryItemsKeys } from './query-key-factory';

export const useGetLibraryItemById = (id?: string) =>
  useQuery({
    queryKey: libraryItemsKeys.details(id),
    queryFn: () => newLibraryItemService.get(id),
    enabled: !!id,
  });
