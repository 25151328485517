import {
  TextInput,
  onEmptyBlurValidator,
  useInvalidations,
} from '@kandji-inc/bumblebee';
import type { ChangeEvent } from 'react';
import React, { useState } from 'react';
import { useModalState } from 'src/features/integrations/hooks/use-modal-state';
import styled from 'styled-components';
import FooterButtons from '../../../components/footer-buttons';
import FooterStatus from '../../../components/footer-status/footer-status';
import {
  Container,
  ModalContent,
  StyledSlideHeader,
} from '../../../components/styled-components';
import useModal from '../../../hooks/use-modal';
import LabelTooltip from '../components/label-tooltip';
import useOktaDeviceTrustPlatform from '../hooks/use-okta-device-trust-platform';
import useOktaLicense from '../hooks/use-okta-license';
import type { AddIosProps, FooterStatusState, LibraryItem } from '../types';
import iosOdt from './assets/ios-odt.svg';
import footerIcon from './assets/okta-small.svg';
import { oktaSlideMap } from './okta-slide-map';

const StyledTextInputContainer = styled.div`
  margin-top: 24px;
  width: 360px;
  height: 95px;
  text-align: left;
`;

const AddIOS = (props: AddIosProps) => {
  const { skipMacOs } = props;
  const [secretKey, setSecretKey] = useState('');
  const [footerStatusState, setFooterStatusState] =
    useState<FooterStatusState>('');
  const { isLoading, createDevicePlatform } = useOktaDeviceTrustPlatform();
  const { nextModal, previousModal } = useModal();
  const { state: modalState } = useModalState();
  const { invalidations, onInvalidate } = useInvalidations({ inputs: 1 });
  const { getLicenseCount, error } = useOktaLicense({ disableAutoFetch: true });
  const onClose = () => nextModal(oktaSlideMap.cancelConfirm);

  const handleNext = async () => {
    const {
      oktaDomain,
      token,
      scepUrl,
      challengeUrl,
      username,
      password,
      clientId,
      clientSecret,
    } = modalState;
    setFooterStatusState('pending');

    const devicePlatformData = {
      domain: oktaDomain,
      token,
      okta_url: oktaDomain,
      ios_provider_secret_key: secretKey,
      client_id: clientId,
      client_secret: clientSecret,
      ...(!skipMacOs && {
        mac_scep_url: scepUrl,
        mac_scep_challenge_url: challengeUrl,
        mac_scep_challenge_password: password,
        mac_scep_challenge_username: username,
      }),
    };

    try {
      const devicePlatformResponse =
        await createDevicePlatform(devicePlatformData);

      if (
        devicePlatformResponse &&
        Object.keys(devicePlatformResponse).length
      ) {
        const licenseData = (await getLicenseCount()) as LibraryItem;

        if (licenseData?.licenses_total && error == null) {
          nextModal(
            oktaSlideMap.setupComplete({ libraryItemId: licenseData.id }),
          );
        } else {
          nextModal(oktaSlideMap.addOktaVerifyLibrary);
        }
      }
    } catch (e) {
      setFooterStatusState('');
    }
  };

  const onBack = () => previousModal();

  const handleOnChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSecretKey(event.target.value);
  };

  const handleValidation = (value: string) => [
    onEmptyBlurValidator(value, {
      message: 'Required',
      trigger: ['onBlur'],
    }),
  ];

  const isNextDisabled = invalidations.some(Boolean) || secretKey.length > 50;

  return (
    <Container>
      <ModalContent>
        <img src={iosOdt} className="b-mb2" alt="header-icons" />
        <StyledSlideHeader>Add iOS as a device platform</StyledSlideHeader>
        <p className="b-txt-light">
          Provide the following information for iOS devices. The secret key was
          generated in Okta when adding iOS as a device management provider.
        </p>
        <StyledTextInputContainer>
          <LabelTooltip
            label="Secret key"
            tooltipMessage="The secret key that was generated in Okta when adding iOS as a device management provider."
          />
          <TextInput
            placeholder="Enter the secret key"
            onChange={handleOnChange}
            value={secretKey.trim()}
            onInvalidate={onInvalidate(0)}
            validator={handleValidation}
            maxLength={50}
            type="password"
            hideMaxLength
          />
        </StyledTextInputContainer>
      </ModalContent>
      <FooterButtons
        onClose={onClose}
        handleNext={handleNext}
        nextButtonDisabled={isNextDisabled}
        cancelButtonText="Cancel setup"
        nextButtonText="Finish setup"
        backOnClick={onBack}
        showBackButton
        showStepper
        stepperIdx={4}
        stepsAmount={6}
        isWorking={isLoading}
        customComponent={
          footerStatusState ? (
            <FooterStatus
              icon={footerIcon}
              status={footerStatusState}
              options={{
                pending: {
                  title: 'Finalizing integration setup...',
                  statusText: 'In progress',
                },
              }}
            />
          ) : undefined
        }
      />
    </Container>
  );
};

export default AddIOS;
