import deepcopy from 'deepcopy';
import { getItemConfig } from 'features/library-items/library/api/transformer';
import {
  apiToDevice,
  apiTypes,
  canAddLibraryTypes,
  deviceTypes,
} from 'features/library-items/library/common';
import allLibraryItemsConfigurations from 'features/library-items/library/library-item-configurations/items';
/* istanbul ignore file */
import { blueprintLibraryCategories } from '../../common';

const transformItem =
  (blueprintLibraryItems = []) =>
  (item) => {
    const itemInBlueprint = blueprintLibraryItems.find(
      ({ id }) => id === item.id,
    );

    const hasLicenses =
      !isNaN(item.used_licenses) && !isNaN(item.data?.num_licenses);

    const itemConfig = getItemConfig(item);

    if (!itemConfig) {
      console.error('ITEM WITH NO CONFIGURATION', item);
    }

    // Enrollment category options do not show counts.
    const itemCounts = itemConfig?.blueprintCategory !==
      blueprintLibraryCategories.ENROLLMENT && {
      success: itemInBlueprint?.success_count || 0,
      failed: itemInBlueprint?.failing_count || 0,
      pending: itemInBlueprint?.pending_count || 0,
    };

    const devices = Object.keys(apiToDevice)
      .filter((deviceKey) => item[deviceKey])
      .map((deviceKey) => apiToDevice[deviceKey]);

    return {
      ...item,
      licenses: hasLicenses && {
        used: item.used_licenses,
        total: item.data.num_licenses,
      },
      devices: devices.length
        ? devices
        : itemConfig?.devices
          ? itemConfig.devices
          : [deviceTypes.MAC],
      icon:
        item.icon ||
        item.app_icon ||
        item.data?.icon ||
        item.data?.app_icon ||
        item.data?.icon_url ||
        itemConfig?.icon,
      backupIcon: itemConfig.icon,
      defaultConfiguration: itemConfig,
      getUrl: itemConfig.getUrl,
      getAddUrl: itemConfig.getAddUrl,
      isSelfServiceEnabled: item.is_self_service,
      selfServiceInstallType: item.install_enforcement,
      counts: itemCounts,
    };
  };

const transformFromAPI = (
  blueprint,
  libraryItems,
  parameters,
  parametersTags,
  { account, environment },
) => {
  blueprint.library_items = blueprint.library_items.filter(getItemConfig);
  const transformItemWithBlueprint = transformItem(blueprint.library_items);

  const transformedBlueprintLibItems = blueprint.library_items.map(
    transformItemWithBlueprint,
  );
  const transformedLibItems = libraryItems
    .filter(getItemConfig)
    .map(transformItemWithBlueprint);

  // Setup categorical structure for the root category then the subcategories
  // relating to each item.
  const categorical = Object.values(blueprintLibraryCategories).map(
    (category) => {
      return {
        category,
        data: [],
      };
    },
  );

  Object.values(allLibraryItemsConfigurations).forEach((libConfig) => {
    const blueprintCategory = libConfig.blueprintCategory;
    const blueprintLibraryItemName =
      libConfig.blueprintLibraryName || libConfig.name;
    const idx = categorical.findIndex(
      ({ category }) => category === blueprintCategory,
    );

    if (libConfig.isHidden({ account, environment })) {
      return;
    }

    const items = transformedLibItems
      .filter(({ active }) => active)
      .filter(({ type, identifier }) => {
        if (
          type === apiTypes.PROFILE &&
          apiTypes.PROFILE === libConfig.type &&
          identifier === libConfig.identifier
        ) {
          return true;
        } else if (type !== apiTypes.PROFILE) {
          return type === libConfig.type;
        }
      })
      .sort(
        (a, b) =>
          a.name.localeCompare(b.name) ||
          a.instance_name ||
          ''.localeCompare(b.instance_name || ''),
      );

    if (!items.length && libConfig.canAdd === canAddLibraryTypes.CANNOT_ADD) {
      return;
    }

    const idxOfExistingConfigType = categorical[idx].data.findIndex(
      (d) => d.category === blueprintLibraryItemName,
    );

    // Some items can fall into the same category.
    if (idxOfExistingConfigType >= 0) {
      categorical[idx].data[idxOfExistingConfigType].data.push(...items);
    } else {
      categorical[idx].data.push({
        category: blueprintLibraryItemName,
        defaultConfiguration: libConfig,
        data: items,
      });
    }
  });

  // Sorts specific categories of Library items (ex. Airplay security, Passport, ADE)
  categorical.forEach(({ data }) =>
    data.sort((a, b) => a.category.localeCompare(b.category)),
  );

  blueprint.categorical = categorical;
  blueprint.library_items = transformedBlueprintLibItems;

  // Parameters
  const categoriesWithParameters = parameters.categories
    .sort((a, b) => a.weight - b.weight)
    .map((category) => {
      const subcategories = category.subcategories
        .filter(({ name }) => name !== 'No Subcategory')
        .map((subcat) => {
          return {
            ...subcat,
            parameters: parameters.parameters
              .filter(
                (param) =>
                  param.category?.id === category.id &&
                  param.subcategory?.id === subcat.id,
              )
              .map((param) => {
                if (param.dependency) {
                  const dependency = parameters.parameters.find(
                    ({ id }) => param.dependency === id,
                  );
                  if (dependency) {
                    param.dependency = dependency;
                  }
                }
                return { ...param };
              })
              .sort((a, b) => a.weight - b.weight),
          };
        })
        .sort((a, b) => a.weight - b.weight);

      return {
        ...category,
        subcategories,
      };
    });

  const complianceTags = ['NIST', 'CIS', 'STIG'];
  const compatibilityTag = ['10.13', '10.14', '10.15'];
  blueprint.parameterTags =
    parametersTags?.results
      .map((tag) => {
        const isCompatibilityTag = compatibilityTag.includes(tag.name);
        const isNum = !isNaN(parseInt(tag.name));
        return {
          ...tag,
          hidden: isCompatibilityTag ? false : tag.hidden,
          kind: isNum ? 'compatibility' : 'framework',
        };
      })
      .filter(({ hidden }) => !hidden)
      .sort((a, b) => a.name.localeCompare(b.name)) || [];
  blueprint.parameterList = parameters.parameters;
  blueprint.parameters = categoriesWithParameters;
  blueprint.params = Object.keys(blueprint.params).reduce((a, c) => {
    const parameter = parameters.parameters.find(({ id }) => id === c);
    return {
      ...a,
      [c]: {
        parameter,
        ...blueprint.params[c],
      },
    };
  }, {});

  return blueprint;
};

const paramsKey = {
  createUserAccounts: 'bb40515e-4b28-48fc-9596-59c8db14a7a3',
};

const transformParamsToAPI = (blueprint) => {
  const params = deepcopy(blueprint.params);

  for (const key in params) {
    const paramToMutate = params[key];

    if (
      key === paramsKey.createUserAccounts &&
      paramToMutate?.details?.length
    ) {
      paramToMutate.details.forEach((userAcct) => {
        delete userAcct.repeatPassword;
        delete userAcct.isInvalid;
      });
    }

    if (paramToMutate) {
      delete paramToMutate.parameter;
      delete paramToMutate.isInvalid;
    }
  }

  return params;
};

export { transformFromAPI, transformParamsToAPI };
