import { styled } from '@kandji-inc/nectar-ui';
import type React from 'react';
import type { CommonProps } from '../types/integrator.types';
import ViewHeader from './partials/view-header';

import {
  SpaceContainer,
  StyledContainer,
} from '../../../components/styled-components/main';

const StyledBodyContainer = styled(SpaceContainer, {
  width: '100%',
});

export interface CustomLayoutProps<InputType, StateType, DataType>
  extends CommonProps<InputType, StateType> {
  layout: 'custom-layout';
  header: {
    title: string;
    description: string;
    link?: {
      text: string;
      url: string;
    };
  };
  data?: DataType;
  state?: StateType;
  Component: React.ComponentType<{ data?: DataType; state?: StateType }>;
}

const CustomLayout = <InputType, StateType, DataType>(
  props: CustomLayoutProps<InputType, StateType, DataType>,
) => {
  const { logo, header, state, data, Component } = props;

  return (
    <StyledContainer>
      <ViewHeader data={{ logo, ...header }} />

      <StyledBodyContainer>
        <Component data={data} state={state} />
      </StyledBodyContainer>
    </StyledContainer>
  );
};

export default CustomLayout;
