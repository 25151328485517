/* istanbul ignore file - legacy code, moved */
import FilterSelect from 'app/components/common/FilterSelect';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import { Label, SubInfo, SubLabel, TextBlock } from './SCEP.styles';

const FilterSelectAdaptive = styled(FilterSelect)`
  width: 400px;
  :focus {
    border: 2px solid #1a1d25;
  }
`;

const SCEPLibrarySelect = ({
  label,
  subLabel,
  minWidth800,
  isWithCount,
  value,
  list,
  onChange,
  isEditorMode,
  disabled,
  isAdaptive,
}) => (
  <>
    <TextBlock isAdaptive={isAdaptive}>
      <Label subLabel={subLabel} htmlFor={`key-${label}`}>
        {label}
      </Label>
      <div className="d-flex align-items-center">
        <FilterSelectAdaptive
          isAdaptive={isAdaptive}
          minWidth800={minWidth800}
          selected={value}
          withCount={isWithCount}
          list={list}
          resetThenSet={onChange}
          disabled={!isEditorMode || disabled}
        />
      </div>
      <SubInfo isAdaptive={isAdaptive}>
        <SubLabel isAdaptive={isAdaptive}>{subLabel}</SubLabel>
      </SubInfo>
    </TextBlock>
  </>
);

SCEPLibrarySelect.propTypes = {
  label: PropTypes.string.isRequired,
  isAdaptive: PropTypes.bool.isRequired,
  subLabel: PropTypes.string.isRequired,
  minWidth800: PropTypes.string.isRequired,
  isWithCount: PropTypes.bool.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
    PropTypes.number,
    PropTypes.object,
  ]).isRequired,
  list: PropTypes.arrayOf(PropTypes.any).isRequired,
  onChange: PropTypes.func.isRequired,
  isEditorMode: PropTypes.bool,
  disabled: PropTypes.bool.isRequired,
};

SCEPLibrarySelect.defaultProps = {
  isEditorMode: false,
};

export default SCEPLibrarySelect;
