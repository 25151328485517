/* istanbul ignore file */

const STATUS_CONNECTED = 'connected';
const STATUS_DISCONNECTED = 'disconnected';
const STATUS_PENDING = 'pending';
const STATUS_UNKNOWN = 'unknown';

type ADCSStatus =
  | typeof STATUS_CONNECTED
  | typeof STATUS_DISCONNECTED
  | typeof STATUS_PENDING
  | typeof STATUS_UNKNOWN;

const status = {
  [STATUS_CONNECTED]: {
    class: 'connected',
    label: 'Connected',
  },
  [STATUS_DISCONNECTED]: {
    class: 'disconnected',
    label: 'Disconnected',
  },
  [STATUS_PENDING]: {
    class: 'pending',
    label: 'Pending',
  },
  [STATUS_UNKNOWN]: {
    class: 'pending',
    label: 'Pending',
  },
} as const;

const statusClass = (s: ADCSStatus) => status[s || STATUS_UNKNOWN]?.class;
const statusLabel = (s: ADCSStatus) => status[s || STATUS_UNKNOWN]?.label;

export default status;
export {
  statusClass,
  statusLabel,
  STATUS_CONNECTED,
  STATUS_DISCONNECTED,
  STATUS_PENDING,
  STATUS_UNKNOWN,
};
