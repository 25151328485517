import React, { useContext } from 'react';
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import ActivityTabNew from '../../../../app/components/ActivityTabNew';
import BlueprintContext from '../blueprint.context';
import './blueprint-activity.css';

const BlueprintActivity = () => {
  const { blueprint } = useContext(BlueprintContext);
  const location = useLocation();
  const match = useRouteMatch();
  const history = useHistory();

  return (
    <div className="bl-blueprint-activity">
      <ActivityTabNew
        blueprintId={blueprint.id}
        activityTabType="blueprint"
        filters={['type', 'period']}
        location={location}
        match={match}
        history={history}
      />
    </div>
  );
};

export default BlueprintActivity;
