import { createColumnHelper } from '@tanstack/react-table';
/* istanbul ignore file */
import type { z } from 'zod';

import { de } from '@faker-js/faker';
import { getIconName } from '../../components/PrismNav/prismNavUtils';
import {
  NULL_VALUE_N_DASH,
  YES_NO_CELL_FILTER_OPTIONS,
  YesNoCell,
  getFilteredDisabled as filterDisabled,
  getCodeSnippetCell,
} from '../column-utils';
import { getScharedColumns } from './getSharedColumns';

export const getDesktopAndScreensaverColumns = ({
  desktopAndScreensaverSchema,
}): {
  columnDefs: any[];
  pinnedColumns: string[];
  alwaysHiddenColumns: string[];
} => {
  const { properties } = desktopAndScreensaverSchema.schema;
  const desktopAndScreensaverColumnHelper =
    createColumnHelper<z.infer<typeof properties>>();

  const hotCornerOptionFilterOptions = [
    { value: 'Mission Control', label: 'Mission Control' },
    { value: 'Application Windows', label: 'Application Windows' },
    { value: 'Desktop', label: 'Desktop' },
    { value: 'Notification Center', label: 'Notification Center' },
    { value: 'Launchpad', label: 'Launchpad' },
    { value: 'Quick Note', label: 'Quick Note' },
    { value: 'Start Screen Saver', label: 'Start Screen Saver' },
    { value: 'Put Display to Sleep', label: 'Put Display to Sleep' },
    { value: 'Lock Screen', label: 'Lock Screen' },
    { value: 'Off', label: 'Off' },
  ];

  const columnDefs = [
    desktopAndScreensaverColumnHelper.accessor(
      (row) => row[`${desktopAndScreensaverSchema.uri}.user_account`],
      {
        id: `${desktopAndScreensaverSchema.uri}.user_account`,
        meta: {
          displayName: properties.user_account.title,
          displayIcon: getIconName(desktopAndScreensaverSchema.uri),
          filterType: 'string',
          filterIcon: 'text-size',
          filterDisabled,
        },
      },
    ),

    desktopAndScreensaverColumnHelper.accessor(
      (row) => row[`${desktopAndScreensaverSchema.uri}.screensaver_interval`],
      {
        id: `${desktopAndScreensaverSchema.uri}.screensaver_interval`,
        cell: (info) => {
          const value = info.getValue();
          if (value === null) {
            return NULL_VALUE_N_DASH;
          }
          const num = Number(value);
          if (!Number.isNaN(num)) {
            const minutes = Math.floor(num / 60);
            if (minutes === 0) {
              return `${num} seconds`;
            }
            return `${minutes} minutes`;
          }
          return '';
        },
        meta: {
          displayName: properties.screensaver_interval.title,
          displayIcon: getIconName(desktopAndScreensaverSchema.uri),
          filterType: 'time',
          filterIcon: 'clock',
          filterDisabled,
        },
      },
    ),

    desktopAndScreensaverColumnHelper.accessor(
      (row) => row[`${desktopAndScreensaverSchema.uri}.screensaver_path`],
      {
        id: `${desktopAndScreensaverSchema.uri}.screensaver_path`,
        cell: getCodeSnippetCell,
        meta: {
          displayName: properties.screensaver_path.title,
          displayIcon: getIconName(desktopAndScreensaverSchema.uri),
          filterType: 'string',
          filterIcon: 'text-size',
          filterDisabled,
        },
      },
    ),

    desktopAndScreensaverColumnHelper.accessor(
      (row) => row[`${desktopAndScreensaverSchema.uri}.show_with_clock`],
      {
        id: `${desktopAndScreensaverSchema.uri}.show_with_clock`,
        cell: YesNoCell,
        meta: {
          displayName: properties.show_with_clock.title,
          displayIcon: getIconName(desktopAndScreensaverSchema.uri),
          filterType: 'boolean',
          filterOptions: [...YES_NO_CELL_FILTER_OPTIONS],
          filterIcon: 'circle-dot',
          filterDisabled,
        },
      },
    ),

    desktopAndScreensaverColumnHelper.accessor(
      (row) =>
        row[`${desktopAndScreensaverSchema.uri}.uses_random_screensaver`],
      {
        id: `${desktopAndScreensaverSchema.uri}.uses_random_screensaver`,
        cell: YesNoCell,
        meta: {
          displayName: properties.uses_random_screensaver.title,
          displayIcon: getIconName(desktopAndScreensaverSchema.uri),
          filterType: 'boolean',
          filterOptions: [...YES_NO_CELL_FILTER_OPTIONS],
          filterIcon: 'circle-dot',
          filterDisabled,
        },
      },
    ),

    desktopAndScreensaverColumnHelper.accessor(
      (row) =>
        row[`${desktopAndScreensaverSchema.uri}.top_left_hot_corner_option`],
      {
        id: `${desktopAndScreensaverSchema.uri}.top_left_hot_corner_option`,
        meta: {
          displayName: properties.top_left_hot_corner_option.title,
          displayIcon: getIconName(desktopAndScreensaverSchema.uri),
          filterType: 'enum',
          filterIcon: 'list-check',
          filterDisabled,
          defaultHidden: true,
          filterOptions: hotCornerOptionFilterOptions,
        },
      },
    ),

    desktopAndScreensaverColumnHelper.accessor(
      (row) =>
        row[`${desktopAndScreensaverSchema.uri}.top_left_hot_corner_modifier`],
      {
        id: `${desktopAndScreensaverSchema.uri}.top_left_hot_corner_modifier`,
        meta: {
          displayName: properties.top_left_hot_corner_modifier.title,
          displayIcon: getIconName(desktopAndScreensaverSchema.uri),
          defaultHidden: true,
        },
      },
    ),

    desktopAndScreensaverColumnHelper.accessor(
      (row) =>
        row[`${desktopAndScreensaverSchema.uri}.top_right_hot_corner_option`],
      {
        id: `${desktopAndScreensaverSchema.uri}.top_right_hot_corner_option`,
        meta: {
          displayName: properties.top_right_hot_corner_option.title,
          displayIcon: getIconName(desktopAndScreensaverSchema.uri),
          filterType: 'enum',
          filterIcon: 'list-check',
          filterDisabled,
          defaultHidden: true,
          filterOptions: hotCornerOptionFilterOptions,
        },
      },
    ),

    desktopAndScreensaverColumnHelper.accessor(
      (row) =>
        row[`${desktopAndScreensaverSchema.uri}.top_right_hot_corner_modifier`],
      {
        id: `${desktopAndScreensaverSchema.uri}.top_right_hot_corner_modifier`,
        meta: {
          displayName: properties.top_right_hot_corner_modifier.title,
          displayIcon: getIconName(desktopAndScreensaverSchema.uri),
          defaultHidden: true,
        },
      },
    ),

    desktopAndScreensaverColumnHelper.accessor(
      (row) =>
        row[`${desktopAndScreensaverSchema.uri}.bottom_left_hot_corner_option`],
      {
        id: `${desktopAndScreensaverSchema.uri}.bottom_left_hot_corner_option`,
        meta: {
          displayName: properties.bottom_left_hot_corner_option.title,
          displayIcon: getIconName(desktopAndScreensaverSchema.uri),
          filterType: 'enum',
          filterIcon: 'list-check',
          filterDisabled,
          defaultHidden: true,
          filterOptions: hotCornerOptionFilterOptions,
        },
      },
    ),

    desktopAndScreensaverColumnHelper.accessor(
      (row) =>
        row[
          `${desktopAndScreensaverSchema.uri}.bottom_left_hot_corner_modifier`
        ],
      {
        id: `${desktopAndScreensaverSchema.uri}.bottom_left_hot_corner_modifier`,
        meta: {
          displayName: properties.bottom_left_hot_corner_modifier.title,
          displayIcon: getIconName(desktopAndScreensaverSchema.uri),
          defaultHidden: true,
        },
      },
    ),

    desktopAndScreensaverColumnHelper.accessor(
      (row) =>
        row[
          `${desktopAndScreensaverSchema.uri}.bottom_right_hot_corner_option`
        ],
      {
        id: `${desktopAndScreensaverSchema.uri}.bottom_right_hot_corner_option`,
        meta: {
          displayName: properties.bottom_right_hot_corner_option.title,
          displayIcon: getIconName(desktopAndScreensaverSchema.uri),
          filterType: 'enum',
          filterIcon: 'list-check',
          filterDisabled,
          defaultHidden: true,
          filterOptions: hotCornerOptionFilterOptions,
        },
      },
    ),

    desktopAndScreensaverColumnHelper.accessor(
      (row) =>
        row[
          `${desktopAndScreensaverSchema.uri}.bottom_right_hot_corner_modifier`
        ],
      {
        id: `${desktopAndScreensaverSchema.uri}.bottom_right_hot_corner_modifier`,
        meta: {
          displayName: properties.bottom_right_hot_corner_modifier.title,
          displayIcon: getIconName(desktopAndScreensaverSchema.uri),
          defaultHidden: true,
        },
      },
    ),

    ...getScharedColumns(desktopAndScreensaverSchema),
  ];

  return { columnDefs, alwaysHiddenColumns: [], pinnedColumns: [] };
};
