import { Icon, useOutsideClick } from '@kandji-inc/bumblebee';
import React, { useState, useRef } from 'react';
import { IconDisplay } from '../../blueprints-list/icon-select';

const BlueprintMenu = (props) => {
  const { blueprint, options, onChange } = props;
  const ref = useRef();
  const [isOpen, setIsOpen] = useState(false);

  useOutsideClick(ref, () => setIsOpen(false), [isOpen]);

  return (
    <div className="bl-blueprint-sidebar__menu">
      <div className="b-flex-vc b-flex-btw" onClick={() => setIsOpen(true)}>
        <div className="b-flex-vc">
          <IconDisplay
            className="bl-blueprint-sidebar__blueprint-icon b-mr1"
            withBorder={false}
            icon={blueprint.icon}
            color={blueprint.color}
            size="small"
          />
          <p className="b-txt-bold bl-blueprint-sidebar__blueprint-name">
            {blueprint.name}
          </p>
        </div>
        <Icon name="angle-down" />
      </div>

      {isOpen && (
        <div ref={ref} className="bl-blueprint-sidebar__menu-dropdown">
          {options.map((option) => (
            <div
              key={option.id}
              className="bl-blueprint-sidebar__menu-dropdown-item"
              onClick={() => {
                onChange(option.id);
                setIsOpen(false);
              }}
            >
              <div className="b-flex-vc">
                <IconDisplay
                  className="bl-blueprint-sidebar__blueprint-icon b-mr1"
                  withBorder={false}
                  icon={option.icon}
                  color={option.color}
                  size="small"
                />
                <p className="b-txt-bold bl-blueprint-sidebar__blueprint-name">
                  {option.name}
                </p>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default BlueprintMenu;
