/* istanbul ignore file */
import {
  Box,
  Button,
  Flex,
  Heading,
  Icon,
  Text,
  styled,
} from '@kandji-inc/nectar-ui';
import React, { useContext, useEffect, useState } from 'react';
import { useFlags } from 'src/config/feature-flags';
import { AccountContext } from 'src/contexts/account';
import { InterfaceContext } from 'src/contexts/interface';
import { i18n } from 'src/i18n';
import ContactSalesDialog from './ContactSalesDialog';
import QuoteDialog from './QuoteDialog';
import DevicesHero from './assets/devices-hero-end-trial.png';
import Kandji from './assets/kandji.png';
import { convertToCurrencyFormat } from './common.data';
import { useGetQuote } from './useGetQuote';

const TakeoverScreen = styled(Flex, {
  position: 'relative',
  height: '100vh',
  width: 'calc(100vw - 77px)',
  background: 'white',
  overflow: 'hidden',
  marginLeft: '-49px',

  '@media (max-width: 1400px)': {
    "img[id='splash-hero']": {
      right: '-200px !important',
    },
  },
  '@media (max-width: 975px)': {
    "img[id='splash-hero']": {
      right: '-400px !important',
    },
  },
  '@media (max-width: 768px)': {
    "img[id='splash-hero']": {
      right: '-500px !important',
    },
  },
  '@media (max-width: 576px)': {
    "img[id='splash-hero']": {
      right: '-600px !important',
    },
  },

  '@media (max-height: 900px)': {
    "img[id='splash-hero']": {
      bottom: '-100px !important',
    },
  },

  variants: {
    sidebarDocked: {
      true: {
        width: 'calc(100vw - 256px)',
      },
    },
  },
});

const Logo = styled('img', {
  position: 'absolute',
  top: '24px',
  left: '24px',
  width: '60px',
  height: '60px',
});

const InfoSide = styled(Flex, {
  position: 'relative',
  flex: 0.6,
  height: '100%',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  zIndex: 25,
  padding: '45px',
});

const SplashSide = styled(Box, {
  flex: 0.7,
  backgroundColor: '$neutral100',
  zIndex: 10,

  "& img[id='splash-hero']": {
    transition: 'right 0.15s ease-in',
  },
});

const DisplayQuote = ({ data, onEdit }) => {
  const { currentCompany } = useContext(AccountContext);
  const locale = currentCompany.locale;

  if (!data) {
    return null;
  }

  const {
    macOsUnitPrice,
    iosAppleTvDeviceUnitPrice,
    edrEnabled,
    iosAppleTvDeviceCount,
    macOsDeviceCount,
    quoteContractTermMonths,
    edrPriceTotal,
    quoteTotal,
    quoteTotalCurrency,
    macOsPriceTotal,
    iosAppleTvPriceTotal,
    quoteUrl,
  } = data;

  return (
    <Flex flow="column">
      <Heading size="3">{i18n.t('Your quote is')}</Heading>
      <Flex alignItems="center" gap="xs">
        <Heading size="1">
          {convertToCurrencyFormat(quoteTotal, 2, 'en', quoteTotalCurrency)}
        </Heading>
        <Button
          compact
          variant="subtle"
          icon={{ name: 'pencil' }}
          onClick={onEdit}
        />
      </Flex>
      <Text css={{ lineHeight: '20px' }}>{i18n.t('for annual contract')}</Text>
      <Box mt4 p4 css={{ backgroundColor: '$blue05', borderRadius: '6px' }}>
        <Flex gap="md" flow="column" css={{ gap: '6px' }}>
          {Boolean(macOsDeviceCount) && (
            <Flex justifyContent="space-between" css={{ width: '100%' }}>
              <Flex css={{ gap: '6px' }} flex="1">
                <Icon
                  size="sm"
                  name="sf-desktop-computer"
                  style={{ width: '14px', height: '14px' }}
                />
                <Box>
                  <Text size="1" css={{ fontWeight: 500 }}>
                    {i18n.t('{macOsDeviceCount} Macs', { macOsDeviceCount })}
                  </Text>
                  <Text size="1" variant="secondary">
                    {i18n.t(
                      '${macOsUnitPrice}/device x {quoteContractTermMonths} months',
                      {
                        macOsUnitPrice,
                        quoteContractTermMonths,
                      },
                    )}
                  </Text>
                  {edrEnabled && (
                    <Text
                      size="1"
                      variant="secondary"
                      css={{ marginTop: '6px' }}
                    >
                      {i18n.t('+ Endpoint Detection and Response')}
                    </Text>
                  )}
                </Box>
              </Flex>

              <Flex
                flow="column"
                justifyContent="space-between"
                css={{ textAlign: 'end' }}
              >
                <Text size="1">
                  {convertToCurrencyFormat(
                    macOsPriceTotal,
                    2,
                    locale,
                    quoteTotalCurrency,
                  )}
                </Text>
                {Boolean(edrPriceTotal) && (
                  <Text size="1">
                    {convertToCurrencyFormat(
                      edrPriceTotal,
                      2,
                      locale,
                      quoteTotalCurrency,
                    )}
                  </Text>
                )}
              </Flex>
            </Flex>
          )}

          {Boolean(iosAppleTvDeviceCount) && (
            <Flex justifyContent="space-between" css={{ width: '100%' }}>
              <Flex css={{ gap: '6px' }} flex="1">
                <Icon
                  size="sm"
                  name="sf-iphone"
                  style={{ width: '14px', height: '14px' }}
                />
                <Box>
                  <Text size="1" css={{ fontWeight: 500 }}>
                    {i18n.t('{iosAppleTvDeviceCount} iPhone/iPad/Apple TV', {
                      iosAppleTvDeviceCount,
                    })}
                  </Text>
                  <Text size="1" variant="secondary">
                    {i18n.t(
                      '${iosAppleTvDeviceUnitPrice}/device x {quoteContractTermMonths} months',
                      {
                        iosAppleTvDeviceUnitPrice,
                        quoteContractTermMonths,
                      },
                    )}
                  </Text>
                </Box>
              </Flex>

              <Text size="1" css={{ textAlign: 'end' }}>
                {convertToCurrencyFormat(
                  iosAppleTvPriceTotal,
                  2,
                  locale,
                  quoteTotalCurrency,
                )}
              </Text>
            </Flex>
          )}
        </Flex>
      </Box>
      <Box mt6>
        <Button
          icon={{ name: 'signature' }}
          variant="primary"
          onClick={() => window.open(quoteUrl, '_blank')}
        >
          {i18n.t('Sign now')}
        </Button>
      </Box>
    </Flex>
  );
};

const TrialEnded = () => {
  const {
    'exp-74-trial-dashboard-quoting': LDFF_quotingEnabled,
    'dc-exp-quote-syntax': LDFF_quoteSyntax,
  } = useFlags(['exp-74-trial-dashboard-quoting']);
  const { sidebarDocked } = useContext(InterfaceContext);
  const { currentCompany } = useContext(AccountContext);
  const { data: quoteData, isLoading } = useGetQuote();
  const [isQuoteDialogOpen, toggleQuoteDialog] = useState({
    isOpen: false,
    openWithDetails: false,
  });
  const [isContactSalesDialogOpen, toggleContactSales] = useState({
    isOpen: false,
    error: null,
  });
  const hasHubspotDealId = currentCompany.trial_banner_data?.hubspot_deal_id;

  useEffect(() => {
    document.body.style.marginBottom = '0';

    return () => {
      document.body.style.marginBottom = '48px';
    };
  }, []);

  if (isLoading) {
    return null;
  }

  const isFromSignupFlow = Boolean(
    currentCompany.onboarding?.experience === 'v1',
  );
  const isQuotingEnabled = LDFF_quotingEnabled && isFromSignupFlow;

  return (
    <TakeoverScreen sidebarDocked={sidebarDocked}>
      <Logo src={Kandji} alt="Kandji" />
      <InfoSide>
        <Flex flow="column" gap="xl">
          <Box>
            <Heading size="1" css={{ marginBottom: '16px' }}>
              {i18n.t('Your free trial has ended')}
            </Heading>
            <Text css={{ lineHeight: '20px' }}>
              {i18n.t('Ready to manage your fleet with Kandji?')}
            </Text>
          </Box>

          {hasHubspotDealId && quoteData && isQuotingEnabled && (
            <DisplayQuote
              data={quoteData}
              onEdit={() =>
                toggleQuoteDialog((prev) => ({
                  ...prev,
                  isOpen: true,
                  openWithDetails: true,
                }))
              }
            />
          )}
          {!quoteData && hasHubspotDealId && isQuotingEnabled && (
            <Box>
              <Button
                id="trial-dashboard-get-a-quote"
                variant="primary"
                onClick={() =>
                  toggleQuoteDialog((prev) => ({ ...prev, isOpen: true }))
                }
              >
                {i18n.t(LDFF_quoteSyntax)}
              </Button>
            </Box>
          )}
          {((!quoteData && !hasHubspotDealId) || !isQuotingEnabled) && (
            <Box>
              <Button
                onClick={() =>
                  toggleContactSales({ isOpen: true, error: null })
                }
              >
                {i18n.t('Contact sales')}
              </Button>
            </Box>
          )}
        </Flex>
      </InfoSide>
      <SplashSide>
        <img
          id="splash-hero"
          src={DevicesHero}
          style={{
            position: 'absolute',
            bottom: 0,
            right: 0,
          }}
          alt="splash"
        />
      </SplashSide>
      {isQuoteDialogOpen.isOpen && (
        <QuoteDialog
          openWithDetails={isQuoteDialogOpen.openWithDetails}
          details={quoteData}
          onClose={() =>
            toggleQuoteDialog((prev) => ({ ...prev, isOpen: false }))
          }
          onToggleContactSales={({ error }) => {
            toggleQuoteDialog((prev) => ({ ...prev, isOpen: false }));
            toggleContactSales({ isOpen: true, error });
          }}
        />
      )}
      {isContactSalesDialogOpen && (
        <ContactSalesDialog
          pageName="Trial End"
          isOpen={isContactSalesDialogOpen.isOpen}
          setIsOpen={(isOpen) => toggleContactSales({ isOpen, error: null })}
          withError={isContactSalesDialogOpen.error}
        />
      )}
    </TakeoverScreen>
  );
};

export default TrialEnded;
