import { onEmptyBlurValidator, urlValidator } from '@kandji-inc/bumblebee';
import { i18n } from 'i18n';

export const validationLimit = {
  clientId: 25,
  clientSecret: 64,
};

export const handleOktaValidation = (value: string) => [
  onEmptyBlurValidator(value, {
    message: i18n.t('Required'),
    trigger: ['onBlur'],
  }),
  urlValidator(value, {
    message: i18n.t('Invalid URL'),
    trigger: 'onBlur',
    options: {
      require_protocol: false,
      allow_underscores: true,
      protocols: ['http', 'https'],
    },
  }),
];
