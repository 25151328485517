import { useMutation } from '@tanstack/react-query';
import { msTeamsService } from '../services/teams';

type Channels = string[];

type MSTeamChannelData = {
  notificationId: string;
  channels: Channels;
};

const useSendMSTeamsChannels = () =>
  useMutation({
    mutationFn: (data: MSTeamChannelData) =>
      msTeamsService.sendChannels(data.notificationId, data.channels),
  });

export default useSendMSTeamsChannels;
