export const requiredField =
  (message = 'Required') =>
  (v) => {
    const r1 = {
      invalid: () => !String(v).length,
      trigger: ['onBlur', 'onInput'],
      message,
    };
    return r1;
  };

export default null;
