import { api } from 'app/api/base';

class NotesService {
  constructor() {
    this.resource = 'notes/';
  }

  async get(id, params) {
    const client = api(`${this.resource}${id}`);
    return client.get(params).then((r) => r.data);
  }

  async find(params) {
    const client = api(this.resource);
    return client.get(params).then((r) => r.data);
  }

  async delete(id, params) {
    const client = api(`${this.resource}${id}`);
    return client.delete(params);
  }

  async create(data, params) {
    const client = api(this.resource);
    return client.post(data, params);
  }

  async patch(id, data, params) {
    const client = api(`${this.resource}${id}`);
    return client.patch(data, params);
  }
}

const notesService = new NotesService();

export default NotesService;
export { notesService };
