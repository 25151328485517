import { styled } from '@kandji-inc/nectar-ui';
import type { CSS, SelectSize } from '@kandji-inc/nectar-ui';
import { i18n } from 'i18n';
import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import ViewMultiSelect from '../../../integrator/layouts/partials/view-multi-select';
import useGetUserGroupListOptions from '../../hooks/use-get-user-group-list-options';
import { DEVICE_PLATFORMS } from '../../types';

import type { GroupOption, GroupOptionList } from '../../types';

interface Props {
  field: string;
  defaultOptions?: GroupOptionList;
  size?: SelectSize;
  css?: CSS;
}

const StyledTypeaheadContainer = styled('div', {
  paddingLeft: '$5',
});

const SelectUserGroup = ({
  field,
  defaultOptions = [],
  size,
  css = {},
}: Props) => {
  const { watch, getValues } = useFormContext();
  const [searchOptions, setSearchOptions] = useState<GroupOptionList>([]);
  const [selectedOptions, setSelectedOptions] = useState<GroupOptionList>([]);
  const [availableOptions, setAvailableOptions] = useState<GroupOptionList>([]);

  const [searchTerm, setSearchTerm] = useState('');

  watch([field]);
  const formInputs = getValues();

  const platformLabel =
    field === `${DEVICE_PLATFORMS.macos}Devices`
      ? i18n.t('macOS')
      : i18n.t('iOS/iPadOS');

  useGetUserGroupListOptions({
    setGroupOptions: setSearchOptions,
    searchTerm,
  });

  useEffect(() => {
    /* istanbul ignore next */
    if (defaultOptions) setSelectedOptions(defaultOptions);
  }, [defaultOptions]);

  useEffect(() => {
    const isInSearch = (option: GroupOption) =>
      searchOptions.some((so) => so.value === option.value);

    const uniqueSelected = selectedOptions.filter((s) => !isInSearch(s));
    setAvailableOptions([...searchOptions, ...uniqueSelected]);
  }, [searchOptions, selectedOptions]);

  return (
    <StyledTypeaheadContainer
      css={{
        ...css,
        '[role=combobox] textarea': {
          // for ensuring entire placeholder text is visible
          'min-width': 300,
        },
      }}
    >
      {formInputs[field] && (
        <ViewMultiSelect
          size={size}
          label={i18n.t('{platformLabel} user group', { platformLabel })}
          options={availableOptions}
          onChange={(currentSelections) =>
            setSelectedOptions(currentSelections.map((s) => s.originalOption))
          }
          handleSearch={setSearchTerm}
          debounceTime={500}
          name={`${field}_groups`} // macosDevices_groups or iosDevices_groups
          validations={{ required: i18n.t('Please select a user group') }}
          placeholder={i18n.t('Search for and select groups')}
        />
      )}
    </StyledTypeaheadContainer>
  );
};

export default SelectUserGroup;
