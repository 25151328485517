/* istanbul ignore file - legacy code, moved */
import { prettyScroll } from 'app/common/style-utils';
import { PrimaryText } from 'app/components/good/text.styles';
import styled from 'styled-components';

const unselectable = `
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
`;

export const Dropdown = styled('div')`
  position: absolute;
  top: ${(props) => props.topOffset}px;
  grid-column-start: 2;
  z-index: 160;
  background: #ffffff;
  box-shadow: 0 0 10px rgba(148, 148, 150, 0.4);
  border-radius: 4px;
  width: 430px;
  margin-top: 12px;
  overflow: visible;
`;

export const CheckboxGrid = styled('div')`
  display: grid;
  cursor: pointer;
  grid-template-areas: 'checkbox title';
  align-items: center;
  grid-template-columns: 35px auto;
  padding: 0 24px 0 24px;
`;

export const Title = styled('div')`
  grid-area: title;
  font-family: var(--font-family-primary);
  font-size: 14px;
  line-height: 36px;
  color: #1a1d25;
  overflow: hidden;
  display: inline-block;
  text-overflow: ellipsis;
  white-space: nowrap;
  ${unselectable};
`;

export const Wrapper = styled('div')`
  grid-area: blueprints;
  width: 100%;
  height: max-content;
  background: #f6f7f9;
  border-radius: 4px;
  padding: 20px 30px 30px 30px;
`;

export const Header = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;

  font-family: var(--font-family-primary);
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.2em;
  text-transform: uppercase;
  font-weight: bold;
  padding-bottom: 11px;
  margin-bottom: 24px;
  border-bottom: 2px solid rgba(26, 29, 37, 0.05);
`;

export const Grid = styled('div')`
  margin-left: 40px;
  position: relative;
  display: grid;
  grid-template-areas: 'label input';
  align-items: center;
  grid-template-columns: 170px min-content;
`;

export const Label = styled('div')`
  grid-area: label;
  font-family: var(--font-family-primary);
  width: 170px;
  font-size: 14px;
  line-height: 17px;
  color: #1a1d25;
`;

export const InputGrid = styled('div')`
  grid-area: input;
  width: 430px;
  grid-template-columns: 390px 25px;
  min-height: 45px;
  display: grid;
  grid-template-areas: 'tags button';
  background: #ffffff;
  border: 2px solid
    ${(props) => {
      if (props.opened) {
        return '#1A1D25';
      }
      if (props.error) {
        return 'red';
      }
      return '#E5E5E5';
    }};
  border-radius: 4px;
  padding: 0 8px 8px 8px;
`;

export const Tags = styled('div')`
  grid-area: tags;
  height: fit-content;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

const tagThemes = {
  basic: `
    background-color: #ECEEF3;
    border: none;
    font-weight: 500;
    color: #8F97AE;
  `,
  secondary: `
    background-color: #FFFFFF;
    border: 1px solid rgb(236, 238, 243);
    font-weight: 400;
    color: #8F97AE;
    `,
  device: `
    background: rgba(236, 238, 243, 0.5);
    color: #8F97AE;
  `,
};

export const Tag = styled('div')`
  ${(props) => tagThemes[props.theme]};
  border-radius: 3px;
  height: 25px;
  width: fit-content;
  max-width: 275px;
  padding: 5px 10px;
  white-space: nowrap;
  font-family: var(--font-family-primary);
  font-size: 12px;
  line-height: 14px;
  display: flex;
  align-items: center;
  text-align: center;
  margin-right: 8px;
  margin-top: 8px;
`;

export const Button = styled('div')`
  grid-area: button;
  align-self: center;
`;

export const Cross = styled('img')`
  width: 7px;
  height: 7px;
  margin-left: 8px;
`;

export const ScrollWrapper = styled('div')`
  padding-top: 20px;
  max-height: 268px;
  overflow-y: scroll;
  ${prettyScroll({ scrollWidth: 4, scrollColor: 'marengo-90' })};
`;

const SWrapper = styled('div')`
  height: 45px;
  padding-left: 25px;
  padding-top: 5px;
  border-bottom: 2px solid rgba(26, 29, 37, 0.05);
  &:after {
    content: '\f002';
    font-family: 'Font Awesome\ 5 Pro', sans-serif;
    font-size: 16px;
    color: #bbbcbf;
    position: absolute;
    top: 22px;
    transform: translateY(-50%);
    right: 20px;
  }
`;

const SInput = styled('input')`
  ${PrimaryText};
  font-size: 14px;
  line-height: 17px;
  border: none;
  outline: none;
  width: 100%;
  height: 100%;
  &::placeholder {
    color: rgba(26, 29, 37, 0.4);
  }
`;

export const Search = {
  SInput,
  SWrapper,
};

const ANWrapper = styled('div')`
  height: 45px;
  margin: 20px 24px 0 24px;
  padding-top: 5px;
  border-top: 2px solid rgba(26, 29, 37, 0.05);
`;

const ANText = styled('div')`
  ${PrimaryText};
  font-size: 14px;
  line-height: 30px;
  color: rgba(26, 29, 37, 0.6);
`;

const ANLink = styled('a')`
  color: #498bee !important;
  cursor: pointer;
  font-weight: 500;
`;

export const AddNew = {
  ANWrapper,
  ANText,
  ANLink,
};

const NEWrapper = styled('div')`
  height: 223px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  line-height: 22px;
`;

const NEText = styled('div')`
  font-family: var(--font-family-primary);
  font-size: 14px;
  line-height: 22px;
  text-align: center;
  color: rgba(26, 29, 37, 0.6);
`;

export const NotExist = {
  NEWrapper,
  NEText,
};

export const BlueprintInputTitle = styled('div')`
  max-width: 250px;
  overflow: hidden;
  display: inline-block;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
