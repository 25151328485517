import React from 'react';
import { createPortal } from 'react-dom';

import { Button } from '@kandji-inc/nectar-ui';

type HeaderActions = {
  onCancel: () => void;
  onSave: () => void;
  isLoading?: boolean;
};

const HeaderActions = (props: HeaderActions) => {
  const { onCancel, onSave, isLoading } = props;

  return createPortal(
    <>
      <Button onClick={onCancel} disabled={isLoading}>
        Cancel
      </Button>
      <Button variant="primary" onClick={onSave} disabled={isLoading}>
        Save
      </Button>
    </>,
    document.querySelector('#blueprint-flow-header-actions'),
  ) as JSX.Element;
};

export default HeaderActions;
