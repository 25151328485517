import type { ProOptions } from 'reactflow';

const proOptions: ProOptions = { hideAttribution: true };

const centerX = 500;
const centerY = 0;

const supportedDeviceFamilies = ['Mac', 'iPhone', 'iPad', 'Apple TV'];

export { proOptions, centerX, centerY, supportedDeviceFamilies };
