import { setClass } from '@kandji-inc/bumblebee';
import React from 'react';

const PanelSectionTitle = ({ title, description = '' }) => (
  <>
    <h4 className={setClass([description ? 'b-mb1' : 'b-mb2', 'b-h4'])}>
      {title}
    </h4>
    {description && <p className="b-txt-light">{description}</p>}
  </>
);

export default PanelSectionTitle;
