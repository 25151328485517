/* istanbul ignore file */
import React from 'react';

const RSSIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="60"
    viewBox="0 0 19.2383 19.0137"
    style={{ minWidth: '60px' }}
  >
    <g>
      <rect height="19.0137" opacity="0" width="19.2383" x="0" y="0" />
      <path
        d="M0.341797 0.869141C0.341797 1.33789 0.732422 1.72852 1.21094 1.72852C10 1.72852 17.1484 8.88672 17.1484 17.6758C17.1484 18.1445 17.5391 18.5449 18.0176 18.5449C18.4863 18.5449 18.877 18.1445 18.877 17.6758C18.877 7.92969 10.9473 0 1.21094 0C0.732422 0 0.341797 0.390625 0.341797 0.869141Z"
        fill="#98989d"
      />
      <path
        d="M0.341797 6.19141C0.341797 6.66016 0.732422 7.06055 1.21094 7.06055C7.08008 7.06055 11.8262 11.8066 11.8262 17.6758C11.8262 18.1445 12.2168 18.5449 12.6855 18.5449C13.1641 18.5449 13.5547 18.1445 13.5547 17.6758C13.5547 10.8594 8.02734 5.32227 1.21094 5.32227C0.732422 5.32227 0.341797 5.72266 0.341797 6.19141Z"
        fill="#98989d"
      />
      <path
        d="M0.341797 11.1914C0.341797 11.6699 0.732422 12.0605 1.21094 12.0605C4.29688 12.0605 6.82617 14.5801 6.82617 17.6758C6.82617 18.1445 7.2168 18.5449 7.68555 18.5449C8.16406 18.5449 8.55469 18.1445 8.55469 17.6758C8.55469 13.623 5.24414 10.332 1.21094 10.332C0.732422 10.332 0.341797 10.7227 0.341797 11.1914Z"
        fill="#98989d"
      />
      <path
        d="M1.75781 18.8867C2.70508 18.8867 3.48633 18.0957 3.48633 17.1582C3.48633 16.1816 2.72461 15.4199 1.75781 15.4199C0.800781 15.4199 0 16.2207 0 17.1875C0 18.0957 0.820312 18.8867 1.75781 18.8867Z"
        fill="#98989d"
      />
    </g>
  </svg>
);

export default RSSIcon;
