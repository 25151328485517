import { useEffect, useState } from 'react';
import { newTokenService } from './api-token-service';

const noop = (v) => v;
const useGetTokenDetails = (id, transformer = noop) => {
  const [err, setErr] = useState();
  const [resp, setResp] = useState();
  const tokenSvc = newTokenService();

  useEffect(() => {
    tokenSvc
      .get(id)
      .then((r) => {
        const apiResp = {
          ...r,
          data: transformer(r.data),
        };
        setResp(apiResp);
      })
      .catch((error) => setErr(error));
  }, []);

  return [err, resp];
};

export { useGetTokenDetails };
