/* istanbul ignore file */
import { newIntegrationsAxios } from 'app/api/base';

class MSTeamsService {
  constructor() {
    this.resource = 'microsoft-teams';
  }

  async getTeams(integrationId) {
    return newIntegrationsAxios(
      `${this.resource}/${integrationId}/teams/`,
    ).get();
  }

  async getAuthorizationData() {
    return newIntegrationsAxios(`${this.resource}/oauth/`)
      .post()
      .then((r) => r.data);
  }

  async getChannels(integrationId, teamId, term) {
    const searchTerm = term ? `?term=${term}` : '';
    return newIntegrationsAxios(
      `${this.resource}/${integrationId}/${teamId}/channels/${searchTerm}`,
    ).get();
  }

  async createIntegration(state) {
    return newIntegrationsAxios(`${this.resource}/`).post({ state });
  }

  async sendTestNotification(integrationId, channels) {
    return newIntegrationsAxios(
      `${this.resource}/${integrationId}/send-test-message/`,
    )
      .post({ channels })
      .then((r) => r);
  }

  async sendChannels(notificationId, channels) {
    return newIntegrationsAxios(`${this.resource}/${notificationId}/channels/`)
      .post({ channels })
      .then((r) => r);
  }

  async getIntegrations() {
    return newIntegrationsAxios(`${this.resource}/`).get();
  }

  async deleteNotification(notificationId) {
    return newIntegrationsAxios(
      `${this.resource}/notifications/${notificationId}/`,
    ).delete();
  }

  async updateNotification(notificationId, data) {
    return newIntegrationsAxios(
      `${this.resource}/notifications/${notificationId}/`,
    )
      .patch(data)
      .then((r) => r);
  }

  async deleteIntegration(integrationId) {
    return newIntegrationsAxios(`${this.resource}/${integrationId}`).delete();
  }
}

const msTeamsService = new MSTeamsService();

export default MSTeamsService;

export { msTeamsService };
