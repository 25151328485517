import { createColumnHelper } from '@tanstack/react-table';
/* istanbul ignore file */
import type { z } from 'zod';

import {
  YES_NO_CELL_FILTER_OPTIONS,
  extractFilterableColumns,
  getFilteredDisabled as filterDisabled,
  getEnumFilterOptions,
  getSpecificDeviceFamilyCell,
} from '../column-utils';
import { getGlobalColumns } from './globalColumns';

export const getAppsColumns = (schema) => {
  if (!schema) {
    return { columnDefs: [], filterableColumns: [] };
  }

  const appsColumnHelper = createColumnHelper<z.infer<typeof schema>>();
  const globalColumns = getGlobalColumns(schema, appsColumnHelper);

  const columnDefs = [
    ...globalColumns.startColumnDefs,

    appsColumnHelper.accessor((row) => row.name, {
      id: 'name',
      meta: {
        displayName: 'App Name',
        filterType: 'string',
        filterIcon: 'text-size',
        filterDisabled,
      },
    }),

    appsColumnHelper.accessor((row) => row.bundle_id, {
      id: 'bundle_id',
      meta: {
        displayName: 'Bundle ID',
        filterType: 'string',
        filterIcon: 'text-size',
        filterDisabled,
      },
    }),

    appsColumnHelper.accessor((row) => row.version, {
      id: 'version',
      meta: {
        displayName: 'Version',
        filterType: 'version',
        filterIcon: 'hashtag',
        filterDisabled,
      },
    }),

    appsColumnHelper.accessor((row) => row.path, {
      id: 'path',
      cell: (info) => getSpecificDeviceFamilyCell(info, ['Mac'], 'code'),
      meta: {
        displayName: 'Path',
        filterType: 'string',
        filterIcon: 'text-size',
        filterDisabled,
      },
    }),

    appsColumnHelper.accessor((row) => row.obtained_from, {
      id: 'obtained_from',
      cell: (info) => getSpecificDeviceFamilyCell(info, ['Mac']),
      meta: {
        displayName: 'Obtained From',
        defaultHidden: true,
        filterType: 'enum',
        filterOptions: getEnumFilterOptions(
          schema.schema?.definitions.ObtainedFromType.enum || [],
        ),
        filterIcon: 'list-check',
        filterDisabled,
      },
    }),

    appsColumnHelper.accessor((row) => row.bundle_size, {
      id: 'bundle_size',
      cell: (info) =>
        getSpecificDeviceFamilyCell(
          info,
          ['iPad', 'AppleTV', 'iPhone'],
          'bytes',
        ),
      meta: {
        displayName: 'Size',
        defaultHidden: true,
        filterType: 'number',
        filterIcon: 'hashtag',
        filterDisabled,
      },
    }),

    appsColumnHelper.accessor((row) => row.short_version, {
      id: 'short_version',
      meta: {
        displayName: 'Short Version',
        defaultHidden: true,
        filterType: 'version',
        filterIcon: 'hashtag',
        filterDisabled,
      },
    }),

    appsColumnHelper.accessor((row) => row.signature, {
      id: 'signature',
      cell: (info) => getSpecificDeviceFamilyCell(info, ['Mac']),
      meta: {
        displayName: 'Signature',
        defaultHidden: true,
        filterType: 'string',
        filterIcon: 'text-size',
        filterDisabled,
      },
    }),

    appsColumnHelper.accessor((row) => row.ad_hoc_code_signed, {
      id: 'ad_hoc_code_signed',
      cell: (info) =>
        getSpecificDeviceFamilyCell(
          info,
          ['iPad', 'AppleTV', 'iPhone'],
          'boolean',
        ),
      meta: {
        displayName: 'AdHoc Code Signed',
        defaultHidden: true,
        filterType: 'boolean',
        filterIcon: 'circle-dot',
        filterOptions: [...YES_NO_CELL_FILTER_OPTIONS],
        filterDisabled,
      },
    }),

    appsColumnHelper.accessor((row) => row.app_store_vendable, {
      id: 'app_store_vendable',
      cell: (info) =>
        getSpecificDeviceFamilyCell(
          info,
          ['iPad', 'AppleTV', 'iPhone'],
          'boolean',
        ),
      meta: {
        displayName: 'App Store Vendable',
        defaultHidden: true,
        filterType: 'boolean',
        filterIcon: 'circle-dot',
        filterOptions: [...YES_NO_CELL_FILTER_OPTIONS],
        filterDisabled,
      },
    }),

    appsColumnHelper.accessor((row) => row.beta_app, {
      id: 'beta_app',
      cell: (info) =>
        getSpecificDeviceFamilyCell(
          info,
          ['iPad', 'AppleTV', 'iPhone'],
          'boolean',
        ),
      meta: {
        displayName: 'Beta App',
        defaultHidden: true,
        filterType: 'boolean',
        filterIcon: 'circle-dot',
        filterOptions: [...YES_NO_CELL_FILTER_OPTIONS],
        filterDisabled,
      },
    }),

    appsColumnHelper.accessor((row) => row.device_based_vpp, {
      id: 'device_based_vpp',
      cell: (info) =>
        getSpecificDeviceFamilyCell(
          info,
          ['iPad', 'AppleTV', 'iPhone'],
          'boolean',
        ),
      meta: {
        displayName: 'Device Assigned License',
        defaultHidden: true,
        filterType: 'boolean',
        filterIcon: 'circle-dot',
        filterOptions: [...YES_NO_CELL_FILTER_OPTIONS],
        filterDisabled,
      },
    }),

    appsColumnHelper.accessor((row) => row.download_canceled, {
      id: 'download_canceled',
      cell: (info) =>
        getSpecificDeviceFamilyCell(
          info,
          ['iPad', 'AppleTV', 'iPhone'],
          'boolean',
        ),
      meta: {
        displayName: 'Download Canceled',
        defaultHidden: true,
        filterType: 'boolean',
        filterIcon: 'circle-dot',
        filterOptions: [...YES_NO_CELL_FILTER_OPTIONS],
        filterDisabled,
      },
    }),

    appsColumnHelper.accessor((row) => row.download_failed, {
      id: 'download_failed',
      cell: (info) =>
        getSpecificDeviceFamilyCell(
          info,
          ['iPad', 'AppleTV', 'iPhone'],
          'boolean',
        ),
      meta: {
        displayName: 'Download Failed',
        defaultHidden: true,
        filterType: 'boolean',
        filterIcon: 'circle-dot',
        filterOptions: [...YES_NO_CELL_FILTER_OPTIONS],
        filterDisabled,
      },
    }),

    appsColumnHelper.accessor((row) => row.download_paused, {
      id: 'download_paused',
      cell: (info) =>
        getSpecificDeviceFamilyCell(
          info,
          ['iPad', 'AppleTV', 'iPhone'],
          'boolean',
        ),
      meta: {
        displayName: 'Download Paused',
        defaultHidden: true,
        filterType: 'boolean',
        filterIcon: 'circle-dot',
        filterOptions: [...YES_NO_CELL_FILTER_OPTIONS],
        filterDisabled,
      },
    }),

    appsColumnHelper.accessor((row) => row.download_waiting, {
      id: 'download_waiting',
      cell: (info) =>
        getSpecificDeviceFamilyCell(
          info,
          ['iPad', 'AppleTV', 'iPhone'],
          'boolean',
        ),
      meta: {
        displayName: 'Download Waiting',
        defaultHidden: true,
        filterType: 'boolean',
        filterIcon: 'circle-dot',
        filterOptions: [...YES_NO_CELL_FILTER_OPTIONS],
        filterDisabled,
      },
    }),

    appsColumnHelper.accessor((row) => row.dynamic_size, {
      id: 'dynamic_size',
      cell: (info) =>
        getSpecificDeviceFamilyCell(
          info,
          ['iPad', 'AppleTV', 'iPhone'],
          'bytes',
        ),
      meta: {
        displayName: 'Dynamic Size',
        defaultHidden: true,
        filterType: 'number',
        filterIcon: 'hashtag',
        filterDisabled,
      },
    }),

    appsColumnHelper.accessor((row) => row.external_version_identifier, {
      id: 'external_version_identifier',
      cell: (info) =>
        getSpecificDeviceFamilyCell(info, ['iPad', 'AppleTV', 'iPhone']),
      meta: {
        displayName: 'External Identifier',
        defaultHidden: true,
        filterType: 'version',
        filterIcon: 'hashtag',
        filterDisabled,
      },
    }),

    appsColumnHelper.accessor((row) => row.has_update_available, {
      id: 'has_update_available',
      cell: (info) =>
        getSpecificDeviceFamilyCell(
          info,
          ['iPad', 'AppleTV', 'iPhone'],
          'boolean',
        ),
      meta: {
        displayName: 'Update Pending',
        defaultHidden: true,
        filterType: 'boolean',
        filterIcon: 'circle-dot',
        filterOptions: [...YES_NO_CELL_FILTER_OPTIONS],
        filterDisabled,
      },
    }),

    appsColumnHelper.accessor((row) => row.installing, {
      id: 'installing',
      cell: (info) =>
        getSpecificDeviceFamilyCell(
          info,
          ['iPad', 'AppleTV', 'iPhone'],
          'boolean',
        ),
      meta: {
        displayName: 'Installing',
        defaultHidden: true,
        filterType: 'boolean',
        filterIcon: 'circle-dot',
        filterOptions: [...YES_NO_CELL_FILTER_OPTIONS],
        filterDisabled,
      },
    }),

    appsColumnHelper.accessor((row) => row.app_clip, {
      id: 'app_clip',
      cell: (info) =>
        getSpecificDeviceFamilyCell(
          info,
          ['iPad', 'AppleTV', 'iPhone'],
          'boolean',
        ),
      meta: {
        displayName: 'App Clip',
        defaultHidden: true,
        filterType: 'boolean',
        filterIcon: 'circle-dot',
        filterOptions: [...YES_NO_CELL_FILTER_OPTIONS],
        filterDisabled,
      },
    }),

    appsColumnHelper.accessor((row) => row.validated, {
      id: 'validated',
      cell: (info) =>
        getSpecificDeviceFamilyCell(
          info,
          ['iPad', 'AppleTV', 'iPhone'],
          'boolean',
        ),
      meta: {
        displayName: 'Validated',
        defaultHidden: true,
        filterType: 'boolean',
        filterIcon: 'circle-dot',
        filterOptions: [...YES_NO_CELL_FILTER_OPTIONS],
        filterDisabled,
      },
    }),

    appsColumnHelper.accessor((row) => row.executable, {
      id: 'executable',
      cell: (info) => getSpecificDeviceFamilyCell(info, ['Mac']),
      meta: {
        displayName: 'Executable',
        defaultHidden: true,
        filterType: 'string',
        filterIcon: 'text-size',
        filterDisabled,
      },
    }),

    appsColumnHelper.accessor((row) => row.bundle_name, {
      id: 'bundle_name',
      cell: (info) => getSpecificDeviceFamilyCell(info, ['Mac']),
      meta: {
        displayName: 'Bundle Name',
        defaultHidden: true,
        filterType: 'string',
        filterIcon: 'text-size',
        filterDisabled,
      },
    }),

    appsColumnHelper.accessor((row) => row.bundle_type, {
      id: 'bundle_type',
      meta: {
        displayName: 'Bundle Type',
        defaultHidden: true,
        filterType: 'string',
        filterIcon: 'text-size',
        filterDisabled,
      },
    }),

    appsColumnHelper.accessor((row) => row.environment_variables, {
      id: 'environment_variables',
      cell: (info) => getSpecificDeviceFamilyCell(info, ['Mac']),
      meta: {
        displayName: 'Environment Variables',
        defaultHidden: true,
        filterType: 'string',
        filterIcon: 'text-size',
        filterDisabled,
      },
    }),

    appsColumnHelper.accessor((row) => row.compiler, {
      id: 'compiler',
      cell: (info) => getSpecificDeviceFamilyCell(info, ['Mac']),
      meta: {
        displayName: 'Compiler',
        defaultHidden: true,
        filterType: 'string',
        filterIcon: 'text-size',
        filterDisabled,
      },
    }),

    appsColumnHelper.accessor((row) => row.bundle_region, {
      id: 'bundle_region',
      cell: (info) => getSpecificDeviceFamilyCell(info, ['Mac']),
      meta: {
        displayName: 'Bundle Region',
        defaultHidden: true,
        filterType: 'string',
        filterIcon: 'text-size',
        filterDisabled,
      },
    }),

    appsColumnHelper.accessor((row) => row.bundle_display_name, {
      id: 'bundle_display_name',
      cell: (info) => getSpecificDeviceFamilyCell(info, ['Mac']),
      meta: {
        displayName: 'Bundle Display Name',
        defaultHidden: true,
        filterType: 'string',
        filterIcon: 'text-size',
        filterDisabled,
      },
    }),

    appsColumnHelper.accessor((row) => row.info, {
      id: 'info',
      cell: (info) => getSpecificDeviceFamilyCell(info, ['Mac']),
      meta: {
        displayName: 'Info',
        defaultHidden: true,
        filterType: 'string',
        filterIcon: 'text-size',
        filterDisabled,
      },
    }),

    appsColumnHelper.accessor((row) => row.minimum_system_version, {
      id: 'minimum_system_version',
      cell: (info) => getSpecificDeviceFamilyCell(info, ['Mac']),
      meta: {
        displayName: 'Minimum System Version',
        defaultHidden: true,
        filterType: 'version',
        filterIcon: 'hashtag',
        filterDisabled,
      },
    }),

    appsColumnHelper.accessor((row) => row.category, {
      id: 'category',
      cell: (info) => getSpecificDeviceFamilyCell(info, ['Mac']),
      meta: {
        displayName: 'Category',
        defaultHidden: true,
        filterType: 'string',
        filterIcon: 'text-size',
        filterDisabled,
      },
    }),

    appsColumnHelper.accessor((row) => row.apple_scriptable, {
      id: 'apple_scriptable',
      cell: (info) => getSpecificDeviceFamilyCell(info, ['Mac'], 'boolean'),
      meta: {
        displayName: 'Apple Scriptable',
        defaultHidden: true,
        filterType: 'boolean',
        filterIcon: 'circle-dot',
        filterOptions: [...YES_NO_CELL_FILTER_OPTIONS],
        filterDisabled,
      },
    }),

    appsColumnHelper.accessor((row) => row.copyright, {
      id: 'copyright',
      cell: (info) => getSpecificDeviceFamilyCell(info, ['Mac']),
      meta: {
        displayName: 'Copyright',
        defaultHidden: true,
        filterType: 'string',
        filterIcon: 'text-size',
        filterDisabled,
      },
    }),

    appsColumnHelper.accessor((row) => row.developer_name, {
      id: 'developer_name',
      cell: (info) => getSpecificDeviceFamilyCell(info, ['Mac']),
      meta: {
        displayName: 'Developer Name',
        defaultHidden: true,
        filterType: 'string',
        filterIcon: 'text-size',
        filterDisabled,
      },
    }),

    appsColumnHelper.accessor((row) => row.team_id, {
      id: 'team_id',
      cell: (info) => getSpecificDeviceFamilyCell(info, ['Mac']),
      meta: {
        displayName: 'Team ID',
        defaultHidden: true,
        filterType: 'string',
        filterIcon: 'text-size',
        filterDisabled,
      },
    }),

    ...globalColumns.endColumnDefs,
  ];

  const filterableColumns = extractFilterableColumns(columnDefs);

  return { columnDefs, filterableColumns };
};
