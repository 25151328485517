import { Badge, Flex, Icon, Text, Tooltip } from '@kandji-inc/nectar-ui';
import { i18n } from 'i18n';
import iosDevicesLogo from '../../../../components-for-specific-types/ms-device-compliance/assets/ios-devices.svg';
import macosDevicesLogo from '../../../../components-for-specific-types/ms-device-compliance/assets/mac-os-devices.svg';
import useGetUserGroupList from '../../hooks/use-get-user-group-list';

import type {
  IntegrationDetails,
  IntegrationGroupsResult,
  PlatformStatus,
} from '../../types';

const ViewUserGroups = ({
  devicePlatform,
  integrationDetails,
}: {
  devicePlatform: PlatformStatus;
  integrationDetails: IntegrationDetails;
}) => {
  const getConfiguredUserGroups = (groupIds: string[] | []) => {
    // Get user group list filtered by ids and return unique groups by external_id
    const { data: groupList } = useGetUserGroupList(undefined, groupIds);
    const groups = groupList?.data?.results;

    const uniqueGroups = groups?.reduce(
      (a, current: IntegrationGroupsResult) => {
        const isDuplicate = a.find(
          (option: IntegrationGroupsResult) =>
            option.external_id === current.external_id,
        );
        if (!isDuplicate) a.push(current);
        return a;
      },
      [] as IntegrationGroupsResult[],
    );

    return uniqueGroups || [];
  };

  const getUserGroupTooltipContent = (groups: IntegrationGroupsResult[]) => {
    // won't work in IE
    const copyToClipboard = (text: string) =>
      navigator.clipboard.writeText(text);

    return (
      <Tooltip
        content={
          <Flex flow="column" p1 gap="xs">
            {groups.map((group: IntegrationGroupsResult) => {
              return (
                <Flex key={group.id} gap="sm">
                  {group.name}
                  <Icon
                    name="copy"
                    size="sm"
                    onClick={() =>
                      copyToClipboard(`${group.name} (${group.external_id})`)
                    }
                  />
                </Flex>
              );
            })}
          </Flex>
        }
        theme="light"
        interactive
        side="bottom"
        css={{ maxWidth: '100%' }}
      >
        <Badge css={{ margin: '-3px' }}>+{groups.length}</Badge>
      </Tooltip>
    );
  };

  const displayUserGroupsByPlatform = (platform: PlatformStatus) => {
    const maxGroups = 2;
    /* istanbul ignore next */
    const platformGroupIds =
      integrationDetails[`${platform}_entra_group_ids`] || [];
    const groups = getConfiguredUserGroups(platformGroupIds);

    const list = groups.slice(0, maxGroups).map((group, i) => (
      <Tooltip
        content={`${group.name} (${group.external_id})`}
        side="top"
        closeOnOutsideClick={true}
        trigger="hover"
        interactive
        key={group.external_id}
      >
        <Text>
          {group.name}
          {i < (groups.length - 1 && maxGroups - 1) ? ',' : ''}
        </Text>
      </Tooltip>
    ));

    if (groups.length > maxGroups) {
      list.push(getUserGroupTooltipContent(groups.slice(2)));
    }

    return <Flex gap="sm">{list}</Flex>;
  };

  return (
    <Flex gap="lg" p5>
      <img
        src={devicePlatform === 'ios' ? iosDevicesLogo : macosDevicesLogo}
        width="64"
        height="64"
        alt={`${devicePlatform} icon`}
      />

      <Flex flow="column" gap="xs">
        <Text variant="description">{i18n.t('User groups')}</Text>
        {displayUserGroupsByPlatform(devicePlatform)}
      </Flex>
    </Flex>
  );
};

export default ViewUserGroups;
