const getGridElementsPosition = (gridEl, cardEl, only = []) => {
  const gridItems = Array.from(gridEl.children).filter((d) =>
    Array.from(d.classList).some((cl) => only.includes(cl)),
  );
  let index = -1;

  for (let i = 0; i < gridItems.length; i++)
    if (gridItems[i] == cardEl) {
      index = i;
    }

  if (index < 0) {
    return;
  }

  let offset =
    Number(window.getComputedStyle(gridEl.children[0]).gridColumnStart) - 1;

  if (isNaN(offset)) {
    offset = 0;
  }
  const colCount = window
    .getComputedStyle(gridEl)
    .gridTemplateColumns.split(' ').length;

  const rowPosition = Math.floor((index + offset) / colCount);
  const colPosition = (index + offset) % colCount;

  return { row: rowPosition, column: colPosition };
};

export { getGridElementsPosition };
