// istanbul ignore file
import { useMutation } from '@tanstack/react-query';
import type {
  FiltersState,
  GetBehavioralThreatGroupResponse,
  GetByThreatsResponse,
  PaginationState,
  SortColumnState,
} from '../../../threat.types';
import behavioralThreatsGroupQuery from '../hooks/use-get-behavioral-threats-group/behavioralThreatsGroupQuery';
import threatsGroupQuery from '../hooks/use-get-by-threats/threatsGroupQuery';

type UseExportMutation = (
  onSuccess: (
    data: GetByThreatsResponse | GetBehavioralThreatGroupResponse,
    isAll: boolean,
  ) => void,
  onError: () => void,
) => {
  fetchForExport: (args: {
    filters: FiltersState | object;
    sort: SortColumnState | object;
    pagination: PaginationState | object;
    isAll: boolean;
  }) => void;
  isLoading: boolean;
};

const useExportThreatsMutation: UseExportMutation = (onSuccess, onError) => {
  const { mutate, isPending } = useMutation({
    mutationKey: ['export-threats'],
    mutationFn: ({
      filters,
      sort,
      pagination,
      isFileDetectionType,
    }: {
      filters: FiltersState | object;
      sort: SortColumnState | object;
      pagination: PaginationState | object;
      isAll: boolean;
      isFileDetectionType?: boolean;
    }) => {
      const exportFn = isFileDetectionType
        ? threatsGroupQuery
        : behavioralThreatsGroupQuery;
      return exportFn(filters, sort, pagination);
    },
    onSuccess: (data, { isAll }) => onSuccess(data?.data, isAll),
    onError,
  });

  return {
    fetchForExport: mutate as unknown as () => void,
    isLoading: isPending,
  };
};

export default useExportThreatsMutation;
