import { useCallback, useEffect, useRef, useState } from 'react';

const useScroll = () => {
  const [scroll, setScroll] = useState();
  const elemRef = useRef();
  useEffect(() => {
    if (elemRef.current) {
      const element = elemRef.current;
      const scrollHandler = (e) => setScroll(e.target.scrollTop);
      element.addEventListener('scroll', scrollHandler);

      return () => element.removeEventListener('scroll', scrollHandler);
    }

    return undefined;
  }, [elemRef.current]);

  return [scroll, elemRef];
};

/* Approximated scaling factor and scroll duration for cross-browser space bar
 * scroll down distance and speed */
const DEFAULT_SPACEBAR_SCROLL_SCALE = 0.975;
const DEFAULT_SPACEBAR_SCROLL_DURATION = 130;

const DEFAULT_DISABLED_HTML_TAGS = ['INPUT', 'TEXTAREA', 'SELECT'];
const MODIFIER_KEYS = ['Alt', 'Control', 'Meta', 'Shift', 'AltGraph'];

/**
 * @callback DebounceCallback
 * @param {...unknown} args
 * @returns {void}
 */

/**
 * Simple debounce utility function to delay firing next scroll event for
 * `delay` milliseconds whenever the user stops scrolling.
 *
 * @param {(...args: unknown[]) => unknown} func - Callback function invoked
 * after delay timeout.
 * @param {number} [delay=200] - Delay in milliseconds before `func` is invoked.
 * @returns {DebounceCallback}
 */
function debounce(func, delay = 200) {
  let timeout;
  return function execute(...args) {
    if (delay <= 0) {
      return func.apply(this, args);
    }
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      timeout = null;
      func.apply(this, args);
    }, delay);
  };
}

/**
 * @typedef {[isInViewport: boolean, element: HTMLElement]} ElementInViewportTuple
 */

/**
 * @callback GetViewportSizingCallback
 * @param {HTMLElement} element
 * @returns {ElementInViewportTuple}
 */

/**
 * `Array.map` callback argument to return an array of `ElementInViewportTuple`
 * indicating which elements are fully or partially visible in the viewport and
 * their respective element references.
 *
 * @param {boolean} isPartiallyVisible - Controls if partially visible elements
 * are considered within the viewport.
 * @returns {GetViewportSizingCallback}
 */
function getViewportSizing(isPartiallyVisible) {
  return (element) => {
    const { top, bottom } = element.getBoundingClientRect();
    const partialOffset = isPartiallyVisible ? element.clientHeight : 0;
    const isInViewport =
      top >= 0 &&
      bottom <=
        (document.documentElement.clientHeight + partialOffset ||
          window.innerHeight + partialOffset);

    return [isInViewport, element];
  };
}

/**
 * Animates element or `document`'s scroll to new `scrollTop` position using the
 * browser `requestAnimationFrame` API.
 *
 * @param {number} to - Scroll to vertical destination in pixels.
 * @param {number} duration - Animation duration in milliseconds to control
 * scroll animation speed.
 * @param {Element | undefined} [element=undefined] - Element to animate scroll
 * to, otherwise `document.scrollingElement` or `document.documentElement`.
 * @returns {void}
 */
export function animateScrollToSideEffect(to, duration, element) {
  const scrollingElement =
    element || document.scrollingElement || document.documentElement;
  const scrollStartY = scrollingElement.scrollTop;
  const scrollDeltaY = to - scrollStartY;
  const startDateMs = Number(new Date());

  /**
   * Makes calculations for quadratic ease-in-out animation.
   *
   * @param {number} elapsedTime - How much time in milliseconds has passed
   * since animation start.
   * @param {number} startY - Starting vertical position.
   * @param {number} deltaY - Vertical difference in pixels between start and
   * end positions.
   * @param {number} durationTime - Animation duration time in milliseconds.
   * @returns {number}
   */
  const calcQuadraticEaseInOut = (
    elapsedTime,
    startY,
    deltaY,
    durationTime,
  ) => {
    let time = elapsedTime;
    time /= durationTime / 2;

    if (time < 1) {
      return (deltaY / 2) * time * time + startY;
    }

    time -= 1;
    return (-deltaY / 2) * (time * (time - 2) - 1) + startY;
  };

  const animateScroll = () => {
    const currentDateMs = Number(new Date());
    const currentTimeElapsed = currentDateMs - startDateMs;

    scrollingElement.scrollTop = Math.floor(
      calcQuadraticEaseInOut(
        currentTimeElapsed,
        scrollStartY,
        scrollDeltaY,
        duration,
      ),
    );

    if (currentTimeElapsed < duration) {
      requestAnimationFrame(animateScroll);
    } else {
      scrollingElement.scrollTop = to;
    }
  };

  animateScroll();
}

/**
 * @typedef UseScrollDownKeyOptions
 * @prop {React.DependencyList} [deps=[]] - Dependencies to watch and trigger
 * re-run of hook to get updated elements for determining current last visible
 * element in the viewport.
 * @prop {Document["getElementsByClassName" | "querySelectorAll"]} [getElementsMethod=Document["getElementsByClassName"]] -
 * A query method on the `document` object that returns a collection of
 * multiple HTML elements, i.e `document.getElementsByClassName`. Note that
 * chaining `.bind(document)` onto the method is necessary in order to properly
 * call the method using the `document` object as the method's `this`
 * context.
 * @prop {keyof Pick<DocumentEventMap, "keyup" | "keydown" | "keypress">} [keyTriggerName="keydown"] -
 * Keyboard event to listen on for scroll down.
 * @prop {string} [keyName=" "] - User keyboard event to listen for to
 * trigger scroll down.
 * @prop {Array.<typeof MODIFIER_KEYS[number]>} [modifierKeys = []] - Additional
 * modifier keys to listen for with `keyName` to trigger scroll down.
 * @prop {Array.<typeof DEFAULT_DISABLED_HTML_TAGS[number]>} [enabledTags = []] -
 * Array of HTML tags enabled to override default disabled HTML tags that
 * prevent scrolling on keyboard event when they are focused.
 * @prop {boolean} [isEnabledForContentEditable=false] - Allows keyboard event
 * to trigger scrolling when content editable elements are focused.
 * @prop {boolean} [isPreventDefault=true] - Allows `event.preventDefault` to be
 * called on keyboard event.
 * @prop {AddEventListenerOptions} [keyEventListenerOptions={capture: false, passive: false}] -
 * Event listener options for keyboard event listener.
 * @prop {number} [vertOffset=0] - Vertical offset for scroll down (negative
 * values for decreasing scroll downward, postive values for increasing scroll
 * downward).
 * @prop {number} [elementHeightScaleOffset=0] - Vertical offset based on a
 * scale multiplier based on the height of the last visible element in the
 * viewport.
 * @prop {boolean} [isLastElemPartiallyVisible=true] - Determines if partially
 * visible elements at the bottom of the viewport, i.e. their bottom Y positions
 * are greater than the height of the `document` or `window`, are considered
 * within the viewport and therefore can be scrolled to as the last element.
 * @prop {"start" | "center" | "end" | "nearest" | undefined} [scrollIntoViewBlock=undefined] -
 * Use `element.scrollIntoView` method for scrolling to the last element by
 * providing one of the method's `block` property values.
 * @prop {"auto" | "smooth" | undefined} [scrollIntoViewBehavior=undefined] -
 * Controls if `element.scrollIntoView` method scrolls to the element
 * smoothly. Note that as of Safari 15 for web and iOS the "smooth"
 * behavior option is not supported, so omit this parameter for Safari.
 * @prop {(e: KeyboardEvent, lastVisibleElement: Element) => unknown | undefined} [onKeyTrigger=undefined] -
 * Callback function that is called on keyboard event instead of this hook's
 * scroll down behavior. Used for providing custom scrolling logic
 * based on the keyboard event and last visible element in the viewport.
 * @prop {number} [scrollAnimationDuration=DEFAULT_SPACEBAR_SCROLL_DURATION] -
 * Animation duration that controls scroll animation speed in milliseconds.
 * The parameter default value uses the `DEFAULT_SPACEBAR_SCROLL_DURATION`
 * constant to emulate the browser's space bar press scroll down animation
 * speed.
 * @prop {number} [debounceDelay=0] - Debounce delay in milliseconds before
 * re-firing scroll event.
 */

/**
 * React hook to verticially scroll down to the last element (bottommost in the viewport) of a
 * given collection of DOM elements queried by a given CSS selector after triggering a keyboard
 * event such as `keyup`. This hook is useful for dependably scrolling to the
 * last element in the viewport such that it is repositioned aligned to the
 * viewport top and is not hidden or scrolled past by providing vertical scroll
 * offsets, e.g. overriding how much is scrolled down for the browser's default
 * space bar scroll. If the selector is omitted by passing `undefined` or `null`
 * the keyboard event triggers simple scroll down with optional vertical offsets
 * applied. Horizontal scrolling or scrolling up is not supported.
 *
 * @param {string | undefined | null | (() => HTMLCollection | NodeList)} selector -
 * Provides one of: a CSS selector string used by the `getElementsMethod`
 * parameter, a callback function that returns a collection of DOM elements, or
 * `undefined` | `null` for simple scroll down instead of scrolling to the last
 * viewport elemement.
 * @param {UseScrollDownKeyOptions} [opts={}] - Options for controlling
 * scrolling and keyboard event behavior.
 * @returns {void}
 */
export const useScrollDownKey = (
  selector,
  {
    deps = [],
    getElementsMethod = document.getElementsByClassName.bind(document),
    keyTriggerName = 'keydown',
    keyName = ' ',
    modifierKeys = [],
    enabledTags = [],
    isEnabledForContentEditable = false,
    isPreventDefault = true,
    keyEventListenerOptions = { capture: false, passive: false },
    vertOffset = 0,
    elementHeightScaleOffset = 0,
    isLastElemPartiallyVisible = true,
    scrollIntoViewBlock = undefined,
    scrollIntoViewBehavior = undefined,
    onKeyTrigger = undefined,
    scrollAnimationDuration = DEFAULT_SPACEBAR_SCROLL_DURATION,
    debounceDelay = 0,
  } = {},
) => {
  /**
   * @type {React.MutableRefObject<HTMLCollection | NodeList | undefined>}
   */
  const elementsRef = useRef(undefined);

  /**
   * @type {React.MutableRefObject<HTMLElement | undefined>}
   */
  const lastVisibleElementRef = useRef(undefined);

  const setLastVisibleElement = useCallback(() => {
    const elements = elementsRef?.current;
    if (!elements?.length) {
      return;
    }

    /**
     * @type {ElementInViewportTuple[]}
     */
    const elementsInViewport = Array.prototype.map
      .call(elements, getViewportSizing(isLastElemPartiallyVisible))
      .filter(([isInViewport]) => isInViewport);

    const lastItem = elementsInViewport[elementsInViewport.length - 1];

    if (lastItem) {
      const [, lastViewportElement] = lastItem;
      lastVisibleElementRef.current = lastViewportElement;
    }
  }, [isLastElemPartiallyVisible]);

  useEffect(() => {
    const getElements = () => {
      if (!selector) {
        return false;
      }
      return typeof selector === 'string' && getElementsMethod(selector);
    };

    const initElementsAndLastVisibleElem = () => {
      const elements =
        typeof selector === 'function' ? selector() : getElements();

      if (elements && elements.length) {
        elementsRef.current = elements;
        setLastVisibleElement();
      }
    };

    initElementsAndLastVisibleElem();
  }, [selector, getElementsMethod, setLastVisibleElement, ...deps]);

  useEffect(() => {
    document.addEventListener(
      'scroll',
      debounce(setLastVisibleElement, debounceDelay),
    );

    return () => document.removeEventListener('scroll', setLastVisibleElement);
  }, [debounceDelay, setLastVisibleElement]);

  useEffect(() => {
    /**
     * @param {KeyboardEvent} e
     */
    const scrollToLastVisibleElement = (e) => {
      const normalizedEnabledTags = enabledTags
        .map((tag) => (typeof tag === 'string' ? tag.toUpperCase() : false))
        .filter(Boolean);
      const disabledTags = DEFAULT_DISABLED_HTML_TAGS.filter(
        (tag) => !normalizedEnabledTags.includes(tag.toUpperCase()),
      );

      const eventTarget = e.target;
      const targetTagName = eventTarget?.tagName;
      const isTargetContentEditable = eventTarget?.isContentEditable;

      const isDisabledTag =
        (targetTagName && disabledTags.includes(targetTagName)) ||
        (isTargetContentEditable && !isEnabledForContentEditable);
      const scrollNoop = typeof e.key !== 'string' || isDisabledTag;

      if (scrollNoop) {
        return;
      }

      const normalizedModifierKeys = modifierKeys.map(
        (k) => typeof k === 'string' && k.toUpperCase(),
      );
      const hasModifierKeys = MODIFIER_KEYS.filter((k) =>
        normalizedModifierKeys.includes(k.toUpperCase()),
      ).every((k) => e.getModifierState(k));
      const hasKey =
        e.key.toLowerCase() === keyName.toLowerCase() && hasModifierKeys;
      const isSimpleScrollMode = !selector && hasKey;

      if (isSimpleScrollMode) {
        if (isPreventDefault) {
          e.preventDefault();
        }

        const hasVertOffset = vertOffset !== 0;
        const defaultSpacebarScrollTo =
          document.documentElement.scrollTop +
          (document.documentElement.clientHeight *
            DEFAULT_SPACEBAR_SCROLL_SCALE ||
            window.innerHeight * DEFAULT_SPACEBAR_SCROLL_SCALE);
        const vertOffsetScrollTo =
          document.documentElement.scrollTop +
          (document.documentElement.clientHeight + vertOffset ||
            window.innerHeight + vertOffset);
        const to = hasVertOffset ? vertOffsetScrollTo : defaultSpacebarScrollTo;

        animateScrollToSideEffect(to, scrollAnimationDuration);
      }

      const hasScrollIntoView =
        typeof scrollIntoViewBlock === 'string' &&
        (scrollIntoViewBlock === 'start' ||
          scrollIntoViewBlock === 'center' ||
          scrollIntoViewBlock === 'end' ||
          scrollIntoViewBlock === 'nearest');
      const isScrollIntoViewMode =
        lastVisibleElementRef?.current && hasKey && hasScrollIntoView;

      if (isScrollIntoViewMode) {
        if (isPreventDefault) {
          e.preventDefault();
        }

        const behavior =
          typeof scrollIntoViewBehavior === 'string' &&
          (scrollIntoViewBehavior === 'auto' ||
            scrollIntoViewBehavior === 'smooth')
            ? scrollIntoViewBehavior
            : 'auto';

        lastVisibleElementRef.current.scrollIntoView({
          block: scrollIntoViewBlock,
          behavior,
        });
      }

      const isScrollToLastVisibleElement =
        lastVisibleElementRef?.current && hasKey && !hasScrollIntoView;

      if (isScrollToLastVisibleElement) {
        if (isPreventDefault) {
          e.preventDefault();
        }

        const to =
          lastVisibleElementRef.current.offsetTop +
          (elementHeightScaleOffset *
            lastVisibleElementRef.current.clientHeight +
            vertOffset);

        animateScrollToSideEffect(to, scrollAnimationDuration);
      }
    };

    const keyTriggerCb =
      typeof onKeyTrigger === 'function'
        ? /** @param {KeyboardEvent} e */ (e) =>
            onKeyTrigger(e, lastVisibleElementRef?.current)
        : scrollToLastVisibleElement;
    document.addEventListener(
      keyTriggerName,
      keyTriggerCb,
      keyEventListenerOptions,
    );

    return () => document.removeEventListener(keyTriggerName, keyTriggerCb);
  }, [
    selector,
    keyTriggerName,
    onKeyTrigger,
    enabledTags,
    isEnabledForContentEditable,
    isPreventDefault,
    keyEventListenerOptions,
    keyName,
    modifierKeys,
    vertOffset,
    scrollAnimationDuration,
    scrollIntoViewBlock,
    elementHeightScaleOffset,
    scrollIntoViewBehavior,
  ]);
};

export default useScroll;
