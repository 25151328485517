import React from 'react';

import { Button } from '@kandji-inc/nectar-ui';

function TrashButton(props: {
  onClick: () => void;
  ariaLabel?: string;
  style?: object;
}) {
  const { onClick, ariaLabel = 'trash-button', style = {} } = props;

  return (
    <Button
      variant="subtle"
      onClick={onClick}
      aria-label={ariaLabel}
      icon={{
        name: 'trash-can',
      }}
      css={{
        padding: '$1',

        '& > svg': {
          height: 'var(--sizes-4)',
        },
      }}
      style={style}
    />
  );
}

export default TrashButton;
