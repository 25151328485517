import { Paragraph } from '@kandji-inc/nectar-ui';
import { i18n } from 'i18n';
import { useSidebarToast } from 'src/hooks/useSidebarToast';
import ActionDialog from '../../../../components/action-dialog';
import useDeleteDevicePlatform from '../../hooks/use-delete-device-platform';
import { getPlatformLabel } from '../details-integration/details-integration';

import type { PlatformStatus } from '../details-integration/details-integration';

type Props = {
  isDialogOpen: boolean;
  platform: PlatformStatus;
  integrationId: string;
  handleToggle: (args: any) => void;
};

const DeleteDevicePlatform = (props: Props) => {
  const { isDialogOpen, handleToggle, integrationId, platform } = props;

  const platformLabel = getPlatformLabel(platform);
  const { displayToast } = useSidebarToast();

  const deletePlatformMutation = useDeleteDevicePlatform(integrationId);
  const isDeleting = deletePlatformMutation.isPending;

  const handleDeletePlatform = () => {
    if (integrationId && platform) {
      deletePlatformMutation.mutate(platform, {
        onSuccess: () => {
          displayToast({
            title: i18n.t(
              '{platformLabel} device platform successfully deleted',
              { platformLabel },
            ),
            variant: 'success',
          });
        },
        onError: (error) => {
          const errorTitle =
            error?.message ||
            i18n.t('Could not delete the device platform. Please try again.');
          displayToast({
            title: errorTitle,
            variant: 'error',
          });
        },
        onSettled: () => {
          handleToggle(null);
        },
      });
    }
  };

  const dialogContent = () => (
    <Paragraph>
      {i18n.t(
        'By removing this device platform from the integration, Microsoft Device Compliance cannot be deployed to {platformLabel} devices. This action cannot be undone.',
        { platformLabel },
      )}
    </Paragraph>
  );

  return (
    <ActionDialog
      isOpen={isDialogOpen}
      size="sm"
      title={i18n.t(
        'Delete {platformLabel} from Microsoft Device Compliance?',
        { platformLabel },
      )}
      content={dialogContent()}
      nextText={i18n.t('Delete')}
      nextClick={handleDeletePlatform}
      backText={i18n.t('Cancel')}
      backClick={() => handleToggle(null)}
      isProcessing={isDeleting}
    />
  );
};

export default DeleteDevicePlatform;
