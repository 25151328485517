import type { ChannelsList, ExistingNotificationData } from '../types';

const actionsMap = {
  remediations: 'remediations',
  computerGoesMissing: 'computer_goes_missing',
  computerGoesActive: 'computer_goes_active',
  newMacEnrolled: 'new_mac_enrolled',
  macCheckedOut: 'mac_checked_out',
  newMacFirstRunSummary: 'new_mac_first_run_summary',
  applicationBlocked: 'application_blocked',
  threatAlertDetected: 'threat_alert_detected',
} as const;

export function transformChannelsToOptions(channels: ChannelsList) {
  return channels.map((channel) => ({
    id: channel.id,
    name: channel.name,
  }));
}

export function transformChannelsForApiCall(channels: ChannelsList) {
  return channels.map((channel) => ({
    channel_id: channel.id,
    channel_name: channel.name,
  }));
}

export function convertActionsToArrayForApi(actions: Record<string, boolean>) {
  const selectedActions = Object.keys(actions).filter((key) => actions[key]);

  const convertedActions = selectedActions.map((action) => actionsMap[action]);

  return convertedActions;
}

export function transformActionsFromApi(actions: string[]) {
  const mappedActionKeys = Object.keys(actionsMap).filter((key) =>
    actions.includes(actionsMap[key]),
  );
  return mappedActionKeys.reduce((acc, curr) => {
    acc[curr] = true;
    return acc;
  }, {});
}

export function transformChannelsFromApi(
  channels: ExistingNotificationData['channels'],
) {
  return channels.map((channel) => ({
    id: channel.channel_id,
    name: channel.channel_name,
  }));
}
