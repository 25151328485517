export function getDiffSuffix(diff: number) {
  return diff >= 2 ? 's' : '';
}

export function formatLastFetchTime(lastFetch: number, currentTime: number) {
  const diffInMilliseconds = Math.abs(currentTime - lastFetch);
  const diffInSeconds = diffInMilliseconds / 1000;
  const diffInMinutes = diffInSeconds / 60;
  const diffInHours = diffInMinutes / 60;
  const diffInDays = diffInHours / 24;

  if (diffInMinutes < 1) {
    return 'less than a minute';
  } else if (diffInHours < 1) {
    return `${Math.floor(diffInMinutes)} minute${getDiffSuffix(diffInMinutes)}`;
  } else if (diffInDays < 1) {
    return `${Math.floor(diffInHours)} hour${getDiffSuffix(diffInHours)}`;
  } else {
    return `${Math.floor(diffInDays)} day${getDiffSuffix(diffInDays)}`;
  }
}
