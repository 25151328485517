import { useEffect, useState } from 'react';
import {
  LIBRARY_ITEMS,
  libraryItemService,
} from '../../../data-service/library-item-service';
import type { LibraryItem } from '../../../data-service/types/library-item-service.types';

type UseOktaLicense = {
  disableAutoFetch?: boolean;
};

const useOktaLicense = (props?: UseOktaLicense) => {
  const { disableAutoFetch = false } = props ?? {};

  const [data, setData] = useState<LibraryItem>();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<Error>();
  const [hasFetched, setHasFetched] = useState(false);

  const getLicenseCount = async (): Promise<
    LibraryItem | undefined | unknown
  > => {
    setLoading(true);
    try {
      const response = await libraryItemService.getLicenseCountByName(
        LIBRARY_ITEMS.oktaVerify,
      );

      setData(response);
      setHasFetched(true);
      return response;
    } catch (err) {
      if (err instanceof Error) {
        setError(err);
      }
      setHasFetched(true);
      return err;
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    let isSubscribed = true;
    if (!hasFetched && isSubscribed && !disableAutoFetch) {
      getLicenseCount();
    }
    return () => {
      isSubscribed = false;
    };
  }, [loading, hasFetched, disableAutoFetch]);
  return {
    data,
    loading,
    error,
    getLicenseCount,
  };
};

export default useOktaLicense;
