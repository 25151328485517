/* istanbul ignore file */

import { newIntegrationsAxios } from 'app/api/base';

import { INT_TYPES } from 'features/integrations/constants';

import { authorityService } from '../authority';
import { connectorService } from '../connector';

class AdvancedService {
  constructor() {
    this.authorityService = authorityService;
    this.connectorService = connectorService;
    this.integrationResource = '/';
  }

  // CREATE ------------------------------ //

  async createADCSIntegration() {
    const data = {
      type: 'adcs',
    };
    return newIntegrationsAxios(this.integrationResource)
      .post(data)
      .then((r) => r.data);
  }

  // BATCH ------------------------------- //

  async assignExistingAndNewAuthoritiesToConnector({
    connectorId,
    existingAuthorityIds,
    newAuthorities,
  }) {
    const newAuthoritiesWithConnectorIds = newAuthorities.map((authority) => ({
      ...authority,
      connector: connectorId,
    }));

    const newAuthoritiesResp =
      await this.authorityService.bulkCreateAuthorities(
        newAuthoritiesWithConnectorIds,
      );

    const newAuthorityIds = newAuthoritiesResp.map((authority) => authority.id);
    const allAuthorityIds = [...existingAuthorityIds, ...newAuthorityIds];

    return this.connectorService.updateConnectorReplaceAuthorities(
      connectorId,
      allAuthorityIds,
    );
  }

  // DELETE ------------------------------ //

  async deleteIntegration() {
    const authorities = await this.authorityService.listAuthorities();
    const connectors = await this.connectorService.listConnectors();

    try {
      const integrations = await newIntegrationsAxios(this.integrationResource)
        .get()
        .then((r) => r.data.results);
      const adcsIntegration = integrations.find(
        (integration) => integration.type === INT_TYPES.adcs,
      );

      if (adcsIntegration) {
        await newIntegrationsAxios(
          `${this.integrationResource}${adcsIntegration.id}/`,
        ).delete();
      }
    } catch (err) {
      throw new Error('Failed to delete adcs integration');
    }

    try {
      await Promise.all([
        ...authorities.map((authority) =>
          this.authorityService.deleteAuthority(authority.id),
        ),
      ]);
    } catch (err) {
      throw new Error('Failed to delete all authorities');
    }

    try {
      await Promise.all([
        ...connectors.map((connector) =>
          this.connectorService.deleteConnector(connector.id),
        ),
      ]);
    } catch (err) {
      throw new Error('Failed to delete all connectors');
    }
  }

  async deleteConnectorAndReassignAuthorities({ connectorId, authorities }) {
    try {
      await Promise.all(
        authorities.map(({ id, connector }) =>
          this.authorityService.updateAuthority(id, {
            connector,
          }),
        ),
      );
    } catch (err) {
      console.error(err);
      throw new Error('Failed to update authorities with new connectors');
    }

    try {
      await this.connectorService.deleteConnector(connectorId);
    } catch (err) {
      console.error(err);
      throw new Error('Failed to delete connector');
    }
  }
}

const advancedService = new AdvancedService();

export default AdvancedService;
export { advancedService };
