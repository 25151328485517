import { useRef } from 'react';
import { useOldAllFields } from 'src/old-honey-form';

// I saw 'useFieldUpdateListener' hook in source code of library
// But it isn't exported );
const useIsFormChanged = () => {
  const allFields = useOldAllFields();
  const currentSerializedValues = JSON.stringify(allFields.getValues());
  const initialSerializedValues = useRef(currentSerializedValues);

  return initialSerializedValues.current !== currentSerializedValues;
};

export default useIsFormChanged;
