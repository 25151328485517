import { createColumnHelper } from '@tanstack/react-table';
/* istanbul ignore file */
import type { z } from 'zod';

import {
  DateCell,
  YES_NO_CELL_FILTER_OPTIONS,
  YesNoCell,
  extractFilterableColumns,
  getFilteredDisabled as filterDisabled,
  getCodeSnippetCell,
  getEnumFilterOptions,
} from '../column-utils';
import { getGlobalColumns } from './globalColumns';

export const getLaunchAgentsDaemonsColumns = (schema) => {
  if (!schema) {
    return { columnDefs: [], filterableColumns: [] };
  }

  const launchAgentsDaemonsColumnHelper =
    createColumnHelper<z.infer<typeof schema>>();
  const globalColumns = getGlobalColumns(
    schema,
    launchAgentsDaemonsColumnHelper,
  );

  const columnDefs = [
    ...globalColumns.startColumnDefs,

    launchAgentsDaemonsColumnHelper.accessor((row) => row.label, {
      id: 'label',
      meta: {
        displayName: 'Label',
        filterType: 'string',
        filterIcon: 'text-size',
        filterDisabled,
      },
    }),

    launchAgentsDaemonsColumnHelper.accessor((row) => row.is_disabled, {
      id: 'is_disabled',
      cell: YesNoCell,
      meta: {
        displayName: 'Service Disabled',
        filterType: 'boolean',
        filterOptions: [...YES_NO_CELL_FILTER_OPTIONS],
        filterIcon: 'circle-dot',
        filterDisabled,
      },
    }),

    launchAgentsDaemonsColumnHelper.accessor((row) => row.run_at_load, {
      id: 'run_at_load',
      cell: YesNoCell,
      meta: {
        displayName: 'Runs at Load',
        filterType: 'boolean',
        filterOptions: [...YES_NO_CELL_FILTER_OPTIONS],
        filterIcon: 'circle-dot',
        filterDisabled,
      },
    }),

    launchAgentsDaemonsColumnHelper.accessor((row) => row.service_domain, {
      id: 'service_domain',
      meta: {
        displayName: 'Service Domain',
        filterType: 'enum',
        filterIcon: 'list-check',
        filterOptions: [
          { value: 'Daemon', label: 'Daemon' },
          { value: 'User Agent', label: 'User agent' },
          { value: 'System Agent', label: 'System agent' },
        ],
        filterDisabled,
      },
    }),

    launchAgentsDaemonsColumnHelper.accessor((row) => row.program, {
      id: 'program',
      cell: getCodeSnippetCell,
      meta: {
        displayName: 'Program Path',
      },
    }),

    launchAgentsDaemonsColumnHelper.accessor((row) => row.created_at, {
      id: 'created_at',
      cell: DateCell,
      meta: {
        defaultHidden: true,
        displayName: 'First Seen',
        filterType: 'date-time',
        filterIcon: 'calendar',
        filterDisabled,
      },
    }),

    launchAgentsDaemonsColumnHelper.accessor((row) => row.path, {
      id: 'path',
      cell: getCodeSnippetCell,
      meta: {
        defaultHidden: true,
        displayName: 'Path',
        filterType: 'string',
        filterIcon: 'text-size',
        filterDisabled,
      },
    }),

    launchAgentsDaemonsColumnHelper.accessor((row) => row.file_name, {
      id: 'file_name',
      meta: {
        defaultHidden: true,
        displayName: 'File Name',
      },
    }),

    launchAgentsDaemonsColumnHelper.accessor((row) => row.program_arguments, {
      id: 'program_arguments',
      cell: getCodeSnippetCell,
      meta: {
        defaultHidden: true,
        displayName: 'Program Arguments',
      },
    }),

    launchAgentsDaemonsColumnHelper.accessor((row) => row.keep_alive, {
      id: 'keep_alive',
      cell: YesNoCell,
      meta: {
        defaultHidden: true,
        displayName: 'Keep Alive',
        filterType: 'boolean',
        filterOptions: [...YES_NO_CELL_FILTER_OPTIONS],
        filterIcon: 'circle-dot',
        filterDisabled,
      },
    }),

    launchAgentsDaemonsColumnHelper.accessor((row) => row.disabled, {
      id: 'disabled',
      cell: YesNoCell,
      meta: {
        defaultHidden: true,
        displayName: 'Disabled',
        filterType: 'boolean',
        filterOptions: [...YES_NO_CELL_FILTER_OPTIONS],
        filterIcon: 'circle-dot',
        filterDisabled,
      },
    }),

    launchAgentsDaemonsColumnHelper.accessor((row) => row.user_name, {
      id: 'user_name',
      meta: {
        defaultHidden: true,
        displayName: 'User',
        filterType: 'string',
        filterIcon: 'text-size',
        filterDisabled,
      },
    }),

    launchAgentsDaemonsColumnHelper.accessor((row) => row.group_name, {
      id: 'group_name',
      meta: {
        defaultHidden: true,
        displayName: 'Group',
        filterType: 'string',
        filterIcon: 'text-size',
        filterDisabled,
      },
    }),

    launchAgentsDaemonsColumnHelper.accessor((row) => row.standard_out_path, {
      id: 'standard_out_path',
      cell: getCodeSnippetCell,
      meta: {
        defaultHidden: true,
        displayName: 'STDOUT Path',
        filterType: 'string',
        filterIcon: 'text-size',
        filterDisabled,
      },
    }),

    launchAgentsDaemonsColumnHelper.accessor((row) => row.standard_error_path, {
      id: 'standard_error_path',
      cell: getCodeSnippetCell,
      meta: {
        defaultHidden: true,
        displayName: 'STDERR Path',
        filterType: 'string',
        filterIcon: 'text-size',
        filterDisabled,
      },
    }),

    launchAgentsDaemonsColumnHelper.accessor((row) => row.start_interval, {
      id: 'start_interval',
      meta: {
        defaultHidden: true,
        displayName: 'Start Interval',
        filterType: 'number',
        filterIcon: 'hashtag',
        filterDisabled,
      },
    }),

    launchAgentsDaemonsColumnHelper.accessor((row) => row.watch_paths, {
      id: 'watch_paths',
      cell: getCodeSnippetCell,
      meta: {
        defaultHidden: true,
        displayName: 'Watch Paths',
        filterType: 'string',
        filterIcon: 'text-size',
        filterDisabled,
      },
    }),

    launchAgentsDaemonsColumnHelper.accessor((row) => row.queue_directories, {
      id: 'queue_directories',
      cell: getCodeSnippetCell,
      meta: {
        defaultHidden: true,
        displayName: 'Queue Directories',
        filterType: 'string',
        filterIcon: 'text-size',
        filterDisabled,
      },
    }),

    launchAgentsDaemonsColumnHelper.accessor((row) => row.start_on_mount, {
      id: 'start_on_mount',
      meta: {
        defaultHidden: true,
        displayName: 'Start on Mount',
        filterType: 'boolean',
        filterOptions: [...YES_NO_CELL_FILTER_OPTIONS],
        filterIcon: 'circle-dot',
        filterDisabled,
      },
    }),

    launchAgentsDaemonsColumnHelper.accessor((row) => row.root_directory, {
      id: 'root_directory',
      cell: getCodeSnippetCell,
      meta: {
        defaultHidden: true,
        displayName: 'Root Directory',
        filterType: 'string',
        filterIcon: 'text-size',
        filterDisabled,
      },
    }),

    launchAgentsDaemonsColumnHelper.accessor((row) => row.working_directory, {
      id: 'working_directory',
      cell: getCodeSnippetCell,
      meta: {
        defaultHidden: true,
        displayName: 'Working Directory',
        filterType: 'string',
        filterIcon: 'text-size',
        filterDisabled,
      },
    }),

    launchAgentsDaemonsColumnHelper.accessor((row) => row.process_type, {
      id: 'process_type',
      meta: {
        defaultHidden: true,
        displayName: 'Process Type',
        filterType: 'enum',
        filterOptions: getEnumFilterOptions(
          schema.schema?.definitions.ProcesssType.enum || [],
        ),
        filterIcon: 'list-check',
        filterDisabled,
      },
    }),

    launchAgentsDaemonsColumnHelper.accessor(
      (row) => row.user_agent_local_user,
      {
        id: 'user_agent_local_user',
        meta: {
          defaultHidden: true,
          displayName: 'User Agent Local User',
          filterType: 'string',
          filterIcon: 'text-size',
          filterDisabled,
        },
      },
    ),

    launchAgentsDaemonsColumnHelper.accessor(
      (row) => row.user_agent_local_user_id,
      {
        id: 'user_agent_local_user_id',
        meta: {
          defaultHidden: true,
          displayName: 'User Agent Local User ID',
          filterType: 'number',
          filterIcon: 'hashtag',
          filterDisabled,
        },
      },
    ),

    launchAgentsDaemonsColumnHelper.accessor((row) => row.is_loaded, {
      id: 'is_loaded',
      cell: YesNoCell,
      meta: {
        defaultHidden: true,
        displayName: 'Service Loaded',
        filterType: 'boolean',
        filterOptions: [...YES_NO_CELL_FILTER_OPTIONS],
        filterIcon: 'circle-dot',
        filterDisabled,
      },
    }),

    launchAgentsDaemonsColumnHelper.accessor((row) => row.pid, {
      id: 'pid',
      meta: {
        defaultHidden: true,
        displayName: 'Process Identifier',
        filterType: 'number',
        filterIcon: 'hashtag',
        filterDisabled,
      },
    }),

    ...globalColumns.endColumnDefs,
  ];

  const filterableColumns = extractFilterableColumns(columnDefs);

  return { columnDefs, filterableColumns };
};
