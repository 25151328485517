import { INT_TYPES } from 'features/integrations/constants';

function validateName(name) {
  return !!name && typeof name === 'string';
}

function validateId(id) {
  return !!id && Number.isInteger(Number(id));
}

function validateFields(expected, actual) {
  return !Object.keys(actual).some((f) => !expected.includes(f));
}

function validateUserIntegration(data) {
  const expectedFields = ['name', 'type'];
  return validateFields(expectedFields, data) && validateName(data.name);
}

function validateCommunicationIntegration(data) {
  const expectedFields = ['name', 'id', 'type'];
  if (data.type === INT_TYPES.msTeams) {
    expectedFields.push('teams');
  }
  return (
    validateFields(expectedFields, data) &&
    validateName(data.name) &&
    validateId(data.id)
  );
}

export { validateCommunicationIntegration, validateUserIntegration };
