import React from 'react';

import { string } from 'prop-types';

import { Flex } from '@kandji-inc/bumblebee';

import Icon from '../assets/server-icon-cert.svg';

const Header = ({ title, icon }) => (
  <div className="b-side-panel-layout__header">
    <Flex justify="space-between" align="center">
      <h4 className="b-h2">{title}</h4>
      <div className="k-adcs-header-icon">
        <img src={icon || Icon} alt={title} />
      </div>
    </Flex>
  </div>
);

Header.propTypes = {
  title: string.isRequired,
  icon: string,
};

Header.defaultProps = {
  icon: undefined,
};

export default Header;
