import { Button, setClass } from '@kandji-inc/bumblebee';
import HubSpotHandler from 'components/common/hubspot-handler';
import React from 'react';

const BlueprintActions = (props) => {
  const {
    style,
    className,
    onSave,
    onEdit,
    onCancel,
    onClose,
    actionsFor,
    isTextEnable,
    isEditing,
    isSaving,
    isEditable,
  } = props;

  return (
    <div style={style} className={setClass('b-library__actions', className)}>
      <HubSpotHandler />
      <div className="b-library__actions-inner">
        <div className="b-library__actions-secondary" />
        <div className="b-library__actions-primary b-grid-ctas">
          {isEditing ? (
            <Button isDisabled={isSaving} kind="outline" onClick={onCancel}>
              Cancel
            </Button>
          ) : (
            <Button kind="outline" onClick={onClose}>
              Close
            </Button>
          )}
          {isEditable &&
            (isEditing ? (
              <Button
                className="pendo-save-parameters"
                onClick={onSave}
                isProgress={isSaving}
                iconPlacement="right"
                icon={isSaving ? 'arrows-rotate' : ''}
                disabled={isSaving}
              >
                {`Save ${actionsFor}`}
              </Button>
            ) : (
              <Button onClick={onEdit} className="pendo-edit-parameters">{`${
                isTextEnable ? 'Enable' : 'Edit'
              } ${actionsFor}`}</Button>
            ))}
        </div>
      </div>
    </div>
  );
};

BlueprintActions.defaultProps = {
  style: {},
  className: null,
  onSave: () => {},
  onEdit: () => {},
  onCancel: () => {},
  onClose: () => {},
  isValid: true,
  isEditable: true,
};

export default BlueprintActions;
