import { i18n } from 'i18n';
import React from 'react';

import { INT_TYPES } from '../constants';

import communicationsHeaderIcons from './generic/communication-integrations/assets/welcome-icons.svg';
import {
  INITIAL_STATE as COMM_INT_WELCOME_INITIAL,
  FooterButtons as CommIntFooterButtons,
  getDescription as getCommIntWelcomeDescription,
  getTickets as getCommIntWelcomeTickets,
} from './generic/communication-integrations/welcome-modal';

import slackLargeIcon from './slack/assets/large-icon.svg';
import slackMediumIcon from './slack/assets/medum-icon.svg';
import slackSmallIcon from './slack/assets/small-icon.svg';
import getSlackTableColumns from './slack/integration-card-table';
import {
  INITIAL_NOTIFICATION_DATA as INITIAL_SLACK_NOTIFICATION_DATA,
  makeIntegrationLink as makeSlackIntegrationLink,
  transformNotificationToApi as transformSlackNotificationToApi,
  transformNotificationToEditMode as transformSlackNotificationToEditMode,
  transformNotificationToTest as transformSlackNotificationToTest,
} from './slack/util';

import userHeaderIcons from './generic/user-integrations/assets/welcome-icons.svg';
import {
  INITIAL_STATE as USER_INT_WELCOME_INITIAL,
  getDescription as getUserIntWelcomeDescription,
  getTickets as getUserIntWelcomeTickets,
} from './generic/user-integrations/welcome-modal';

import {
  FooterButtons as AzureFooterButtons,
  NameSlide as AzureNameSlide,
} from './azure/welcome-modal';

import {
  FooterButtons as GoogleFooterButtons,
  NameSlide as GoogleNameSlide,
} from './google/welcome-modal';

import ViewScimIntegrationExtra from './scim/view-integration';
import {
  INITIAL_STATE as SCIM_WELCOME_INITIAL,
  FooterButtons as ScimFooterButtons,
  NameSlide as ScimNameSlide,
  TokenSlide as ScimTokenSlide,
  tickets as scimWelcomeTickets,
} from './scim/welcome-modal';

import { integrationTitleMap } from '../localization';
import adcsSummaryIcon from './adcs/assets/adcs.svg';
import adcsHeaderIcons from './adcs/assets/welcome-icons.svg';
import {
  INITIAL_STATE as ADCS_WELCOME_INITIAL,
  ConnectionPendingSlide as AdcsConnectionPendingSlide,
  DownloadSlide as AdcsDownloadSlide,
  FooterButtons as AdcsFooterButtons,
  getTickets as getAdcsWelcomeTickets,
} from './adcs/modals/welcome';

/* istanbul ignore next */
const configurations = {
  // //////////////////////
  // COMMUNICATION
  // //////////////////////
  [INT_TYPES.slack]: {
    icons: {
      large: slackLargeIcon,
      small: slackSmallIcon,
      medium: slackMediumIcon,
    },
    get title() {
      return integrationTitleMap(INT_TYPES.slack);
    },
    get description() {
      return i18n.t(
        'Set up and manage workspaces, and create event notifications for Slack.',
      );
    },
    get integrationAlias() {
      return i18n.t('workspace');
    },
    transformNotificationToApi: transformSlackNotificationToApi,
    transformNotificationToEditMode: transformSlackNotificationToEditMode,
    transformNotificationToTest: transformSlackNotificationToTest,
    initialNotificationData: INITIAL_SLACK_NOTIFICATION_DATA,
    makeIntegrationLink: makeSlackIntegrationLink,
    getTableColumns: getSlackTableColumns,

    welcomeModal: {
      initialState: COMM_INT_WELCOME_INITIAL,
      headerIcons: communicationsHeaderIcons,
      get description() {
        return getCommIntWelcomeDescription(
          integrationTitleMap(INT_TYPES.slack),
        );
      },
      get tickets() {
        return getCommIntWelcomeTickets(
          'Slack',
          'support/solutions/articles/72000560486-slack-integration',
        );
      },
      FooterButtons: CommIntFooterButtons,
    },
  },

  // TODO: This unfortunately needs to be here until we can fully refactor the integrations overview card page
  [INT_TYPES.msTeams]: {
    icons: {
      large: '',
      small: '',
      medium: '',
    },
    title: 'Microsoft Teams',
    integrationAlias: 'account',
    makeIntegrationLink: (name) => name,
    welcomeModal: {
      tickets: [],
    },
  },

  // //////////////////////
  // USER
  // //////////////////////
  [INT_TYPES.gsuite]: {
    get title() {
      return integrationTitleMap(INT_TYPES.gsuite);
    },
    welcomeModal: {
      initialState: USER_INT_WELCOME_INITIAL,
      headerIcons: userHeaderIcons,
      get description() {
        return getUserIntWelcomeDescription(
          integrationTitleMap(INT_TYPES.gsuite),
        );
      },
      tickets: getUserIntWelcomeTickets('Google Workspace'),
      extraSlides: [GoogleNameSlide],
      FooterButtons: GoogleFooterButtons,
    },
    viewIntegration: {
      text: 'The following Google Workspace administrative account was used to connect this integration.',
      emailRow: 'Email',
    },
  },
  [INT_TYPES.scim]: {
    title: 'the SCIM user integration',
    welcomeModal: {
      initialState: SCIM_WELCOME_INITIAL,
      headerIcons: userHeaderIcons,
      get description() {
        return getUserIntWelcomeDescription(i18n.t('the SCIM protocol'));
      },
      tickets: scimWelcomeTickets,
      extraSlides: [ScimNameSlide, ScimTokenSlide],
      FooterButtons: ScimFooterButtons,
    },
    viewIntegration: {
      get text() {
        return i18n.t(
          'The following Kandji administrative account created the API token for this SCIM integration.',
        );
      },
      get emailRow() {
        return i18n.t('Kandji admin');
      },
      extra: <ViewScimIntegrationExtra />,
    },
  },
  [INT_TYPES.office365]: {
    title: 'Microsoft Entra ID',
    welcomeModal: {
      initialState: USER_INT_WELCOME_INITIAL,
      headerIcons: userHeaderIcons,
      get description() {
        return getUserIntWelcomeDescription('Microsoft Entra ID');
      },
      tickets: getUserIntWelcomeTickets('Microsoft Entra ID'),
      extraSlides: [AzureNameSlide],
      FooterButtons: AzureFooterButtons,
    },
    viewIntegration: {
      get text() {
        return i18n.t(
          'The following Microsoft Entra ID administrative account was used to connect this integration.',
        );
      },
      emailRow: 'Email',
    },
  },

  // //////////////////////
  // Security
  // //////////////////////
  [INT_TYPES.adcs]: {
    icons: {
      summary: adcsSummaryIcon,
    },
    get title() {
      return integrationTitleMap(INT_TYPES.adcs);
    },
    get description() {
      return i18n.t(
        'Active Directory Certificate Services (AD CS) allows you to issue certificates from your certificate authority.',
      );
    },
    welcomeModal: {
      initialState: ADCS_WELCOME_INITIAL,
      headerIcons: adcsHeaderIcons,
      description:
        'Configuring an integration with Active Directory Certificate Services ' +
        '(AD CS) will allow you to issue certificates to devices from your ' +
        'certificate authority.',
      get tickets() {
        return getAdcsWelcomeTickets();
      },
      extraSlides: [AdcsDownloadSlide, AdcsConnectionPendingSlide],
      FooterButtons: AdcsFooterButtons,
    },
  },
  [INT_TYPES.okta]: {
    icons: {
      summary: adcsSummaryIcon,
    },
    title: 'Okta Device Trust',
    description:
      'Active Directory Certificate Services (AD CS) allows you to issue certificates from your certificate authority.',
  },
};

export default configurations;
