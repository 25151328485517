/* istanbul ignore file - legacy code, moved */
import Field from 'app/components/common/Field';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { LibraryFiledTypes } from './SCEPConfig';
import { toType } from './SCEPHelpers';
import SCEPLibraryCheckbox from './SCEPLibraryCheckbox';
import SCEPLibraryOptionalNumber from './SCEPLibraryOptionalNumber';
import SCEPLibrarySelect from './SCEPLibrarySelect';
import SCEPLibraryText from './SCEPLibraryText';
import SCEPLibraryTextarea from './SCEPLibraryTextarea';
import PasswordValidator from './SCEPPasswordValidator';
import { SCEPSettingsContext } from './SCEPSettingsContext';

const hideEmpty = (isHideEmpty, isEditingItem, value) => {
  if (!isHideEmpty || isEditingItem) {
    return false;
  }
  const valueType = toType(value);
  switch (valueType) {
    case 'null':
      return true;
    case 'string':
      return value === '';
    case 'array':
      return isEmpty(value);
    case 'bool':
      return !value;
    default:
      return false;
  }
};

const SCEPLibraryField = ({
  label,
  subLabel,
  subLabelLink,
  value,
  placeholder,
  onChange,
  isError,
  type,
  style,
  isHideEmpty,
  info,
  list,
  isWithCount,
  isDisplay,
  onChangeSubField,
  subFieldTextBefore,
  subFieldText,
  isDependenceDisable,
  index,
  verifyText,
  key,
  isOptional,
  verifiedValue,
  setVerifiedValue,
  valueUpdatedAt,
  saveButtonClicked,
  disabled,
  isNeedValidation,
  isShowHideIcon,
  tags,
  customSortTags,
  minWidth800,
  dependenceInfo,
  isAdaptive,
  id,
  isRequired,
  withSidebar,
  withSmallTopMargin,
  dispatch,
  fieldKey,
  enforceUnique,
  validator,
  onBlur,
  inversion,
}) => {
  const { isEditingItem } = useContext(SCEPSettingsContext);
  const isEditorMode = isEditingItem;

  const inputTypes = new Map()
    .set(LibraryFiledTypes.TEXT, {
      component: SCEPLibraryText,
      props: {
        label,
        subLabel,
        value,
        onChange,
        isEditorMode,
        disabled,
        isOptional,
        isRequired,
        info,
        placeholder,
        isError,
        style,
        isAdaptive,
      },
    })
    .set(LibraryFiledTypes.TEXTAREA, {
      component: SCEPLibraryTextarea,
      props: {
        label,
        subLabel,
        value,
        onChange,
        isEditorMode,
        disabled,
        isOptional,
        isRequired,
        info,
        placeholder,
        isError,
        style,
        isAdaptive,
      },
    })
    .set(LibraryFiledTypes.SELECT, {
      component: SCEPLibrarySelect,
      props: {
        isAdaptive,
        label,
        subLabel,
        minWidth800,
        isWithCount,
        value,
        list,
        onChange,
        isEditorMode,
        disabled,
      },
    })
    .set(LibraryFiledTypes.CHECKBOX, {
      component: SCEPLibraryCheckbox,
      props: {
        withSmallTopMargin,
        isAdaptive,
        withSidebar,
        isEditorMode,
        isError,
        style,
        isDependenceDisable,
        dependenceInfo,
        disabled,
        value,
        onChange,
        label,
        tags,
        customSortTags,
        subLabel,
        info,
        id,
        inversion,
      },
    })
    .set(LibraryFiledTypes.OPTIONAL_NUMBER, {
      component: SCEPLibraryOptionalNumber,
      props: {
        isAdaptive,
        withSidebar,
        isEditorMode,
        disabled,
        value,
        onChange,
        label,
        tags,
        subLabel,
        subLabelLink,
        info,
        style,
        placeholder,
        customSortTags,
        isDependenceDisable,
        dependenceInfo,
        onChangeSubField,
        isError,
        subFieldTextBefore,
        subFieldText,
        id,
        onBlur,
      },
    })
    .set(LibraryFiledTypes.PASSWORD_VALIDATOR, {
      component: PasswordValidator,
      props: {
        index,
        text: label,
        verifyText,
        nameValue: key,
        isEditorMode,
        isOptional,
        value,
        isShowHideIcon,
        verifiedValue,
        setVerifiedValue,
        onChangeInput: onChange,
        valueUpdatedAt,
        saveButtonClicked,
        isHideEmpty,
        isDisplay,
        isNeedValidation,
        isAdaptive,
        placeholder,
        subLabel,
      },
    });

  if (hideEmpty(isHideEmpty, isEditingItem, value)) {
    return null;
  }
  const { component, props } = inputTypes.get(type);

  return (
    <>
      <Field isDisplay={isDisplay} component={component} props={props} />
    </>
  );
};

SCEPLibraryField.propTypes = {
  label: PropTypes.string,
  subLabel: PropTypes.string,
  subLabelLink: PropTypes.shape({
    link: PropTypes.string,
    text: PropTypes.string,
  }),
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
    PropTypes.number,
    PropTypes.array,
    PropTypes.object,
  ]).isRequired,
  placeholder: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  isError: PropTypes.bool,
  type: PropTypes.string.isRequired,
  style: PropTypes.oneOfType([PropTypes.object]),
  isHideEmpty: PropTypes.bool,
  info: PropTypes.string,
  list: PropTypes.arrayOf(PropTypes.any),
  isWithCount: PropTypes.bool,
  isDisplay: PropTypes.bool,
  onChangeSubField: PropTypes.func,
  subFieldTextBefore: PropTypes.string,
  subFieldText: PropTypes.string,
  onChangeSubDropdown: PropTypes.func,
  isDependenceDisable: PropTypes.bool,
  index: PropTypes.number,
  verifyText: PropTypes.string,
  key: PropTypes.string,
  isOptional: PropTypes.bool,
  verifiedValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  setVerifiedValue: PropTypes.func,
  valueUpdatedAt: PropTypes.number,
  minWidth800: PropTypes.number,
  saveButtonClicked: PropTypes.func,
  disabled: PropTypes.bool,
  isNeedValidation: PropTypes.bool,
  isShowHideIcon: PropTypes.bool,
  isAdaptive: PropTypes.bool,
  isRequired: PropTypes.bool,
  withSmallTopMargin: PropTypes.bool,
  tags: PropTypes.arrayOf(PropTypes.string),
  customSortTags: PropTypes.objectOf(PropTypes.any),
  dependenceInfo: PropTypes.string,
  dispatch: PropTypes.func,
  fieldKey: PropTypes.string,
  onBlur: PropTypes.func,
  inversion: PropTypes.bool,
};

SCEPLibraryField.defaultProps = {
  isHideEmpty: false,
  isDisplay: true,
  subLabel: null,
  subLabelLink: {},
  isError: false,
  placeholder: null,
  style: {},
  info: null,
  list: [],
  isWithCount: false,
  onChangeSubField: null,
  subFieldText: null,
  subFieldTextBefore: null,
  onChangeSubDropdown: null,
  isDependenceDisable: false,
  label: null,
  index: null,
  verifyText: null,
  key: null,
  isOptional: false,
  verifiedValue: null,
  setVerifiedValue: null,
  valueUpdatedAt: null,
  saveButtonClicked: null,
  disabled: false,
  isNeedValidation: true,
  isShowHideIcon: false,
  tags: [],
  customSortTags: {},
  minWidth800: null,
  dependenceInfo: '',
  isAdaptive: false,
  isRequired: false,
  withSmallTopMargin: false,
  dispatch: () => null,
  fieldKey: '',
  onBlur: () => null,
  inversion: null,
};

export default SCEPLibraryField;
