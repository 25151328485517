const SERVER_DATE_FORMAT = 'yyyy-MM-dd';

function serverPaginatedFetcher(fetcher) {
  return async (requestParams) => {
    const response = await fetcher(requestParams);
    return { ...response.data, data: response.data.results };
  };
}

export { SERVER_DATE_FORMAT, serverPaginatedFetcher };
