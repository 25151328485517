import { useMemo } from 'react';
import type { Blueprint } from '../okta-details/components/delete-device-platform/delete-device-platform';

const useMapBlueprints = (
  odtBlueprints: string[] | undefined,
  blueprintsList: Blueprint[] | undefined,
) => {
  const odtBlueprintsWithMetaData = useMemo(() => {
    const selectedBlueprints = odtBlueprints?.reduce(
      (accumulator: Blueprint[], currentValue) => {
        const selectedBlueprint = blueprintsList?.find(
          (blueprint) => blueprint.id === currentValue,
        );
        if (selectedBlueprint) {
          accumulator.push(selectedBlueprint);
        }
        return accumulator;
      },
      [],
    );
    return selectedBlueprints;
  }, [blueprintsList, odtBlueprints]);

  return { odtBlueprintsWithMetaData };
};

export default useMapBlueprints;
