import type React from 'react';

import type { CSS } from '@kandji-inc/nectar-ui';
import { Button, Tooltip } from '@kandji-inc/nectar-ui';

import { FlowTippy, TippyContainer } from '../../../theme';

type DeleteButtonProps = {
  onClick: React.MouseEventHandler;
  css?: CSS;
  color?: 'default' | 'red';
  name?: string;
  tooltip?: string;
  disabled?: boolean;
};

const DeleteButton = (props: DeleteButtonProps) => {
  const { onClick, css, name, tooltip, color, disabled = false } = props;

  const styles = {
    ...css,
    background: 'inherit',
    padding: '4px',

    '&:hover': {
      background: '$button_danger_background_hover',
      color: '$neutral0',
      border: 'none',
    },
    '&:active': {
      background: '$button_danger_background_active',
      color: '$neutral0',
      border: 'none',
    },
    '& svg': {
      height: '16px',
      width: '16px',
    },
  };

  const DeleteBtn = (
    <Button
      compact
      variant="subtle"
      icon={{ name: 'trash-can' }}
      onClick={onClick}
      css={styles}
      name={name}
      id={name}
      disabled={disabled}
      data-testid={name}
    />
  );

  /* istanbul ignore next */
  if (Boolean(tooltip)) {
    return (
      <FlowTippy content={<TippyContainer gap="xs">{tooltip}</TippyContainer>}>
        {DeleteBtn}
      </FlowTippy>
    );
  }

  /* istanbul ignore next */
  return DeleteBtn;
};

export default DeleteButton;
