/* istanbul ignore file */
import { INT_TYPES } from '../../../../constants';
import type { IntegratorViewConfig } from '../../types/integrator.types';
import type {
  ServicenowInputs,
  ServicenowState,
} from '../../types/servicenow.types';

// Helpers
import { connectHandleNext, connectStatusOptions } from './helper';

import { i18n } from 'i18n';
// Icons
import servicenowLogo from '../../../../generic-cards-view/assets/servicenow.svg';

// Config
const config: IntegratorViewConfig<ServicenowInputs, ServicenowState, object> =
  {
    type: INT_TYPES.servicenow,
    name: 'ServiceNow',
    logo: servicenowLogo,
    pages: [
      {
        name: 'servicenowConfiguration',
        layout: 'one-column-input-full',
        header: {
          get title() {
            return i18n.t('Configure ServiceNow');
          },
          get description() {
            return i18n.t(
              'The ServiceNow integration enables organizations to sync their Kandji device information into ServiceNow for asset management. ',
            );
          },
          link: {
            get text() {
              return i18n.t('Learn more');
            },
            url: 'https://support.kandji.io/solutions/articles/72000613963',
          },
        },
        banner: {
          get title() {
            return i18n.t(
              'We do not store your admin password in our system. ',
            );
          },
          get description() {
            return i18n.t(
              'The password is only used to establish a connection between ServiceNow and Kandji. Please use credentials that have admin access in the ServiceNow tenant specified.',
            );
          },
        },
        body: {
          get title() {
            return i18n.t('Authentication');
          },
          get description() {
            return i18n.t(
              'Provide the following information to establish the connection with your ServiceNow tenant.',
            );
          },
          inputs: [
            {
              name: 'tenant',
              type: 'text',
              get label() {
                return i18n.t('Tenant name');
              },
              get placeholder() {
                return i18n.t('Enter the tenant name from ServiceNow');
              },
              get hint() {
                return i18n.t(
                  'If your domain is subdomain.service-now.com, just enter subdomain',
                );
              },
              validations: {
                required: {
                  value: true,
                  get message() {
                    return i18n.t('Required');
                  },
                },
                maxLength: {
                  value: 256,
                  get message() {
                    return i18n.t('Must be 256 characters or fewer');
                  },
                },
                pattern: {
                  value: /[a-zA-Z0-9-_]*[a-zA-Z0-9]+$/,
                  get message() {
                    return i18n.t('Must contain only subdomain');
                  },
                },
              },
            },
            {
              name: 'client_id',
              type: 'text',
              get label() {
                return i18n.t('Client ID');
              },
              get placeholder() {
                return i18n.t('Enter the client ID from ServiceNow ');
              },
              validations: {
                required: {
                  value: true,
                  get message() {
                    return i18n.t('Required');
                  },
                },
                maxLength: {
                  value: 256,
                  get message() {
                    return i18n.t('Must be 256 characters or fewer');
                  },
                },
              },
            },
            {
              name: 'client_secret',
              type: 'password',
              get label() {
                return i18n.t('Client secret');
              },
              get placeholder() {
                return i18n.t('Enter the client secret from ServiceNow');
              },
              validations: {
                required: {
                  value: true,
                  get message() {
                    return i18n.t('Required');
                  },
                },
                maxLength: {
                  value: 256,
                  get message() {
                    return i18n.t('Must be 256 characters or fewer');
                  },
                },
              },
            },
            {
              name: 'username',
              type: 'text',
              get label() {
                return i18n.t('Username');
              },
              get placeholder() {
                return i18n.t('Enter your ServiceNow username');
              },
              validations: {
                required: {
                  value: true,
                  get message() {
                    return i18n.t('Required');
                  },
                },
                maxLength: {
                  value: 256,
                  get message() {
                    return i18n.t('Must be 256 characters or fewer');
                  },
                },
              },
            },
            {
              name: 'password',
              type: 'password',
              get label() {
                return i18n.t('Password');
              },
              get placeholder() {
                return i18n.t('Enter your ServiceNow password');
              },
              validations: {
                required: {
                  value: true,
                  get message() {
                    return i18n.t('Required');
                  },
                },
                maxLength: {
                  value: 256,
                  get message() {
                    return i18n.t('Must be 256 characters or fewer');
                  },
                },
              },
            },
          ],
        },
        footer: {
          get title() {
            return i18n.t('Install the Kandji application in ServiceNow');
          },
          get description() {
            return i18n.t(
              'If you haven’t already, please install the Kandji application in ServiceNow before proceeding.',
            );
          },
          link: {
            get text() {
              return i18n.t('Open ServiceNow Store to install');
            },
            url: 'https://store.servicenow.com/sn_appstore_store.do#!/store/application/e330778897bfe150f89bf7021153af52',
          },
        },
        footerStatusOptions: connectStatusOptions,
        get nextButtonText() {
          return i18n.t('Connect to ServiceNow');
        },
        handleNext: connectHandleNext,
        get cancelButtonText() {
          return i18n.t('Cancel setup');
        },
      },
    ],
  };

export default config;
