import styled from 'styled-components';

export const SeparateLine = styled('div')`
  &:not(:last-child) {
    display: flex;
    align-items: center;
    padding-bottom: 28px;
    margin-bottom: 34px;
    border-bottom: 1px solid rgba(26, 29, 37, 0.05);
  }
`;
