import { newLibraryItemService } from 'features/library-items/data-service/library-item/new-library-item-service';
import type { LibraryItem } from './types/library-item-service.types';

export enum LIBRARY_ITEMS {
  oktaVerify = 'Okta Verify',
}
type Keys = keyof typeof LIBRARY_ITEMS;

class LibraryItemService {
  public async getLicenseCountByName(itemName: (typeof LIBRARY_ITEMS)[Keys]) {
    return newLibraryItemService
      .list({ limit: 10000, q: itemName })
      .then(({ data }) => {
        const filtered = data.results.filter(
          (item: LibraryItem) => item.name === itemName,
        );
        return Promise.resolve(filtered[0]);
      })
      .catch((err) => Promise.reject(err));
  }
}

export const libraryItemService = new LibraryItemService();
