/* istanbul ignore file - tested in LibraryItemService */
import { i18n } from 'src/i18n';
import NewLibraryItemService from '../../../data-service/library-item/new-library-item-service';

class AutomaticAppService extends NewLibraryItemService {
  constructor(data = { isAdding: false }) {
    super();
    this.isAdd = data.isAdding;
  }

  /**
   * Get either the auto app template or instance depending on current URL.
   * @override
   */
  async get(id, params) {
    return this.isAdd ? super.getTemplate(id, params) : super.get(id, params);
  }

  static installationTypes = {
    CONTINUOUSLY_ENFORCE: 'continuously_enforce',
    NO_ENFORCEMENT: 'no_enforcement',
  };

  static updateTypes = {
    ANY: 'any',
    NEWEST: 'newest',
    CUSTOM: 'custom',
  };

  static notificationAlertTypeOptions = () => ({
    NONE: {
      label: i18n.t('None'),
      value: 0,
    },
    BANNERS: {
      label: i18n.t('Banners'),
      value: 1,
    },
    ALERTS: {
      label: i18n.t('Alerts'),
      value: 2,
    },
  });

  static notificationGroupingTypeOptions = () => ({
    AUTOMATIC: {
      label: i18n.t('Automatic (default)'),
      value: 0,
    },
    BY_APP: {
      label: i18n.t('By app'),
      value: 1,
    },
    OFF: {
      label: i18n.t('Off'),
      value: 2,
    },
  });

  static notificationPreviewTypeOptions = () => ({
    ALWAYS: {
      label: i18n.t('Always'),
      value: 0,
    },
    WHEN_LOCKED: {
      label: i18n.t('When unlocked'),
      value: 1,
    },
    NEVER: {
      label: i18n.t('Never'),
      value: 2,
    },
  });

  static getScriptInstallOptions = () => ({
    ONCE: {
      label: i18n.t('Run once per device'),
      value: 'once',
    },
    ALWAYS: {
      label: i18n.t('Run on each install/update'),
      value: 'always',
    },
  });
}

export const automaticAppService = new AutomaticAppService();
export default AutomaticAppService;
