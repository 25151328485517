/* istanbul ignore file - legacy code, moved */
import { Button } from '@kandji-inc/bumblebee';
import { links } from 'app/common/constants';
import SimpleIconButton from 'app/components/interface/buttons/SimpleIconButton';
import { usePermissions } from 'contexts/account';
import React, { useContext } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import styled from 'styled-components';
import { FixedBottomLine } from './SCEP.styles';
import { SCEPSettingsContext } from './SCEPSettingsContext';

const MarginRight = styled('span')`
  margin-right: 15px;
`;

const Left = styled('div')`
  margin-right: auto;
`;

const BinIcon = (
  <svg
    width="17"
    height="18"
    viewBox="0 0 17 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.7398 6.20646L10.4091 6.15479L10.1206 14.4968L11.4513 14.5483L11.7398 6.20646Z"
      fill="#1A1D25"
    />
    <path
      d="M9.16556 6.18018H7.83398V14.5222H9.16556V6.18018Z"
      fill="#1A1D25"
    />
    <path
      d="M6.87947 14.4963L6.59097 6.1543L5.26025 6.20601L5.54879 14.548L6.87947 14.4963Z"
      fill="#1A1D25"
    />
    <path
      d="M0.577148 2.70264V4.11254H1.96467L3.06542 17.3571C3.09561 17.7211 3.38348 18.0003 3.72856 18.0003H13.2492C13.5943 18.0003 13.8824 17.7209 13.9124 17.3569L15.0131 4.11254H16.4231V2.70264H0.577148ZM12.6394 16.5904H4.33823L3.30137 4.11254H13.6765L12.6394 16.5904Z"
      fill="#1A1D25"
    />
    <path
      d="M10.6748 0H6.32498C5.71311 0 5.21533 0.527062 5.21533 1.17492V3.40731H6.54691V1.40991H10.4529V3.40731H11.7845V1.17492C11.7845 0.527062 11.2867 0 10.6748 0Z"
      fill="#1A1D25"
    />
  </svg>
);

const SCEPProfileSettingsFooter = ({
  profileId,
  history,
  sidebarDocked,
  onDelete,
  onCLickButtonCancel,
  onSaveClick,
  isLoading,
  disabledSaveButton,
}) => {
  const { isEditingItem, setIsEditingItem, isPendingValidation } =
    useContext(SCEPSettingsContext);
  const permissions = usePermissions();

  /* istanbul ignore next */
  const noop = () => {};

  return (
    <FixedBottomLine sidebarDocked={sidebarDocked} data-testid="anchor">
      <>
        {/* ==================== Button "Delete" ==================== */}
        {permissions.canManageBlueprints && profileId && (
          <Left>
            <SimpleIconButton
              name="Delete"
              icon={BinIcon}
              variant="quaternary"
              onClick={onDelete}
              className="simple-icon-button-delete"
            />
          </Left>
        )}

        {/* ==================== Button "Cancel" ==================== */}
        <MarginRight>
          <Button
            name={isEditingItem ? 'Cancel' : 'Close'}
            onClick={
              isEditingItem
                ? onCLickButtonCancel
                : () =>
                    history.push(
                      history.location.state?.previousPage || links.library,
                    )
            }
            kind="outline"
          >
            {isEditingItem ? 'Cancel' : 'Close'}
          </Button>
        </MarginRight>

        {/* ==================== Button "Save" ==================== */}
        {permissions.canManageBlueprints &&
          isEditingItem &&
          (isPendingValidation ? (
            <Button disabled name="Waiting..." onClick={noop}>
              Waiting...
            </Button>
          ) : (
            <Button
              disabled={disabledSaveButton}
              onClick={onSaveClick}
              icon="check"
            >
              {isLoading ? 'Saving...' : 'Save'}
            </Button>
          ))}

        {/* ==================== Button "Edit" ==================== */}
        {permissions.canManageBlueprints && !isEditingItem && (
          <Button onClick={() => setIsEditingItem(true)}>Edit</Button>
        )}
      </>
    </FixedBottomLine>
  );
};

const mapStateToProps = (state) => ({
  sidebarDocked: state.ui.sidebar.docked,
});

export default withRouter(connect(mapStateToProps)(SCEPProfileSettingsFooter));
