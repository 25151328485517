/* istanbul ignore file - legacy code, moved */
import { blueprintTypes, links } from 'app/common/constants';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { SCEPAssignmentBlueprintsDropdown } from './SCEPAssignmentBlueprintsDropdown';
import { SCEPAssignmentBlueprintsInput } from './SCEPAssignmentBlueprintsInput';
import { SCEPSettingsContext } from './SCEPSettingsContext';
import { SHAREDAssignmentBlueprintsModal } from './SHAREDAssignmentBlueprintsModal';

const SCEPAssignmentBlueprints = ({
  selectedClassicBlueprints,
  selectedAssignmentMaps,
  setSelectedBlueprints,
  checkBlueprintConflicts,
}) => {
  const refList = useRef(null);
  const refInput = useRef(null);

  const [initialSelectedBlueprints] = useState(selectedClassicBlueprints);
  const [listOpen, setListOpen] = useState(false);
  const [hasConflictingBlueprints, setHasConflictingBlueprints] =
    useState(false);
  const [modal, setModal] = useState(null);
  const allBlueprints = useSelector((state) => state.data.blueprints);
  const allBlueprintNames = useSelector((state) => state.data.blueprintNames);

  // Only form Blueprints are selectable for enrollment
  /* istanbul ignore next */
  allBlueprints?.forEach((blueprint) => {
    if (blueprint.type !== blueprintTypes.form) {
      delete allBlueprintNames[blueprint.id];
    }
  });

  const { isEditingItem, setIsPendingValidation } =
    useContext(SCEPSettingsContext);

  const showConflictsModal = (conflicts) =>
    setModal(
      <SHAREDAssignmentBlueprintsModal
        conflicts={conflicts}
        itemData={{}} // wasn't used for SCEP
        getItemURL={(itemId) => `${links.library}/scep/${itemId}/settings`}
        selectedBlueprints={selectedClassicBlueprints}
        setSelectedBlueprints={setSelectedBlueprints}
        onCancel={() => {
          setSelectedBlueprints(initialSelectedBlueprints);
          setHasConflictingBlueprints(false);
          setIsPendingValidation(false);
          setModal(null);
        }}
        onResolve={(resolvedBlueprints) => {
          setSelectedBlueprints(resolvedBlueprints);
          setHasConflictingBlueprints(false);
          setIsPendingValidation(false);
          setModal(null);
        }}
      />,
    );

  const close = (e) =>
    refList.current &&
    !refList.current.contains(e.target) &&
    setListOpen(false);

  useEffect(() => {
    if (listOpen) {
      window.addEventListener('click', close);
      return () => {
        window.removeEventListener('click', close);
      };
    }
  });

  useEffect(() => {
    if (isEditingItem) {
      if (listOpen) {
        // block form submit until selections validated
        setIsPendingValidation(true);
      } else if (
        isEmpty(selectedClassicBlueprints) ||
        selectedClassicBlueprints === initialSelectedBlueprints
      ) {
        // no blueprints selected, no need to validate
        setIsPendingValidation(false);
      } else {
        // hit conflicts endpoint to validate blueprints
        checkBlueprintConflicts().then((conflicts) => {
          if (isEmpty(conflicts)) {
            setIsPendingValidation(false);
          } else {
            // must resolve conflicts via modal
            setHasConflictingBlueprints(true);
            showConflictsModal(conflicts);
          }
        });
      }
    }
  }, [listOpen]);

  return (
    <>
      {modal}
      <SCEPAssignmentBlueprintsInput
        refInput={refInput}
        isEditable={isEditingItem}
        listOpen={listOpen}
        setListOpen={setListOpen}
        allBlueprintNames={allBlueprintNames}
        selectedClassicBlueprints={selectedClassicBlueprints}
        selectedAssignmentMaps={selectedAssignmentMaps}
        setSelectedBlueprints={setSelectedBlueprints}
        hasConflictingBlueprints={hasConflictingBlueprints}
      />
      {listOpen && (
        <SCEPAssignmentBlueprintsDropdown
          refList={refList}
          refInput={refInput}
          allBlueprintNames={allBlueprintNames}
          selectedClassicBlueprints={selectedClassicBlueprints}
          selectedAssignmentMaps={selectedAssignmentMaps}
          setSelectedBlueprints={setSelectedBlueprints}
        />
      )}
    </>
  );
};

SCEPAssignmentBlueprints.propTypes = {
  selectedClassicBlueprints: PropTypes.arrayOf(PropTypes.any).isRequired,
  selectedAssignmentMaps: PropTypes.arrayOf(PropTypes.string),
  setSelectedBlueprints: PropTypes.func,
  checkBlueprintConflicts: PropTypes.func,
};

SCEPAssignmentBlueprints.defaultProps = {
  selectedAssignmentMaps: [],
  setSelectedBlueprints: null,
  checkBlueprintConflicts: () => {},
};

export default SCEPAssignmentBlueprints;
