import { Button, TextInput } from '@kandji-inc/bumblebee';

import HubSpotHandler from 'components/common/hubspot-handler';
import deepcopy from 'deepcopy';

import { SelfServiceLogoUploader } from 'components/common/s3-upload';
import LogoUploader from 'features/library-items/items/kandji-setup/v2/logo-uploader/logo-uploader';
import { bool, func, shape, string } from 'prop-types';
import React, { useEffect, useState } from 'react';
import initialState from '../../initial-state';
import { overCharLimit, requiredField } from '../../input-validations';
import { BRANDING_MAX_CHAR_LIMIT } from '../constants';

const CustomizePanel = ({ handlers, branding, setBody, onDone }) => {
  const [shouldSave, setShouldSave] = useState(false);
  const hasUploadedDarkModeLogo = !!branding.darkLogoUrl;
  const [beforeEdit, _] = useState(deepcopy(branding));
  const [showDarkModeUploader, setShowDarkModeUploader] = useState(
    hasUploadedDarkModeLogo,
  );
  const [isSaveDisabled, setIsSaveDisabled] = useState(!branding.header);

  const isHeaderOverCharLimit =
    branding.header?.length > BRANDING_MAX_CHAR_LIMIT.HEADER;
  const isSubheaderOverCharLimit =
    branding.subheader?.length > BRANDING_MAX_CHAR_LIMIT.SUBHEADER;
  const hasOverCharLimitInputs =
    isHeaderOverCharLimit || isSubheaderOverCharLimit;

  const { closePanel, update: u } = handlers;
  const update = u('branding');

  const resetCustomizeState = () =>
    update({
      batchKeyValues: beforeEdit,
    });

  const onCancel = () => {
    closePanel(() => resetCustomizeState());
  };

  // TODO: Check if restore defaults restores/removes logo
  const restoreDefaults = () => {
    update({
      batchKeyValues: {
        header: initialState.branding.header,
        subheader: initialState.branding.subheader,
        logoFile: null,
        logoUrl: null,
        logoS3Key: null,
        isCustomLogo: false,
        darkLogoFile: null,
        darkLogoUrl: null,
        darkLogoS3Key: null,
        isCustomDarkLogo: false,
      },
    });
  };

  const uploadLogos = async () => {
    const logos = [];
    if (branding.isCustomLogo && branding.logoFile) {
      logos.push({ file: branding.logoFile.file, key: 'logoS3Key' });
    }
    if (branding.isCustomDarkLogo && branding.darkLogoFile) {
      logos.push({ file: branding.darkLogoFile.file, key: 'darkLogoS3Key' });
    }
    if (logos.length) {
      const s3UploadData = await SelfServiceLogoUploader.getS3UploadData(
        logos.map((logo) => ({ name: logo.file.name })),
      );
      await Promise.all(
        logos.map((logo, idx) =>
          SelfServiceLogoUploader.upload({
            file: logo.file,
            s3Data: s3UploadData.data.files[idx].s3_post_data,
          }),
        ),
      ).then(() => {
        update({
          batchKeyValues: logos.reduce(
            (a, c, idx) => ({
              ...a,
              [c.key]: s3UploadData.data.files[idx].s3_post_data.fields.key,
            }),
            {},
          ),
        });
      });
    }
  };

  useEffect(() => {
    if (shouldSave && onDone) {
      setShouldSave(false);
      onDone()
        .then(() => closePanel())
        .catch(() => setIsSaveDisabled(false));
    }
  }, [shouldSave, onDone]);

  const onSave = async () => {
    setIsSaveDisabled(true);
    await uploadLogos();
    setShouldSave(true);
  };

  const isRestoreDefaultsDisabled =
    branding.header === initialState.branding.header &&
    branding.subheader === initialState.branding.subheader &&
    branding.logoUrl === initialState.branding.logoUrl;
  return (
    <>
      <HubSpotHandler />
      <div
        className="b-side-panel-layout__body k-self-service-side-panel-customize"
        ref={setBody}
      >
        <div className="b-form-grid">
          <TextInput
            // TODO: Check if we need a placeholder
            value={branding.header}
            label="Header"
            fieldsGrid
            onChange={(e) => update({ key: 'header', value: e.target.value })}
            maxLength={BRANDING_MAX_CHAR_LIMIT.HEADER}
            validator={overCharLimit(BRANDING_MAX_CHAR_LIMIT.HEADER, [
              requiredField,
            ])}
          />
          <TextInput
            textArea
            label="Subheader"
            isOptional
            fieldsGrid
            value={branding.subheader}
            placeholder="Easily install apps and tools provided by your IT department."
            onChange={(e) =>
              update({ key: 'subheader', value: e.target.value })
            }
            maxLength={BRANDING_MAX_CHAR_LIMIT.SUBHEADER}
            validator={overCharLimit(BRANDING_MAX_CHAR_LIMIT.SUBHEADER, [])}
          />
          <div>
            <h3 className="b-txt b-mb-micro">Logo</h3>
            <p className="b-txt-light b-mb1">
              Upload your organization’s logo and optionally add a Dark Mode
              variant. A square 128x128 pixel PNG file with transparent
              background is recommended.
            </p>
            <LogoUploader
              className="b-mb2"
              icon={
                branding.logoUrl ||
                branding.logoFile ||
                branding.defaultLogoFile
              }
              onRemove={() =>
                update({
                  batchKeyValues: {
                    logoFile: null,
                    logoUrl: null,
                    logoS3Key: null,
                    isCustomLogo: false,
                    darkLogoFile: null,
                    darkLogoUrl: null,
                    darkLogoS3Key: null,
                    isCustomDarkLogo: false,
                  },
                })
              }
              onImage={(f) =>
                update({
                  batchKeyValues: {
                    logoFile: f,
                    logoUrl: null,
                    logoS3Key: null,
                    isCustomLogo: true,
                  },
                })
              }
              canRemove={branding.logoUrl || branding.logoFile}
            />
            {branding.logoS3Key || branding.logoFile ? (
              showDarkModeUploader ? (
                <>
                  <h3 className="b-txt b-mb1">Logo (Dark Mode)</h3>
                  <LogoUploader
                    icon={
                      branding.darkLogoUrl ||
                      branding.darkLogoFile ||
                      branding.defaultLogoDarkFile
                    }
                    onRemove={() =>
                      update({
                        batchKeyValues: {
                          darkLogoFile: null,
                          darkLogoUrl: null,
                          darkLogoS3Key: null,
                          isCustomDarkLogo: false,
                        },
                      })
                    }
                    onImage={(f) =>
                      update({
                        batchKeyValues: {
                          darkLogoFile: f,
                          darkLogoUrl: null,
                          darkLogoS3Key: null,
                          isCustomDarkLogo: true,
                        },
                      })
                    }
                    canRemove={branding.darkLogoUrl || branding.darkLogoFile}
                    isDarkMode
                  />
                </>
              ) : (
                <Button
                  icon="circle-plus"
                  kind="link"
                  onClick={() => setShowDarkModeUploader(true)}
                >
                  Add Dark Mode Logo
                </Button>
              )
            ) : null}
          </div>
        </div>
      </div>
      <div className="b-side-panel-layout__footer">
        <div className="b-flex-btw">
          <Button
            kind="link"
            onClick={restoreDefaults}
            isDisabled={isRestoreDefaultsDisabled}
          >
            Restore defaults
          </Button>
          <div className="b-grid-ctas">
            <Button kind="outline" onClick={onCancel}>
              Cancel
            </Button>
            <Button
              onClick={onSave}
              isDisabled={
                !branding.header || isSaveDisabled || hasOverCharLimitInputs
              }
            >
              Done
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

CustomizePanel.propTypes = {
  branding: shape({
    header: string,
    subheader: string,
    logoUrl: string,
    logoS3Key: string,
    darkLogoUrl: string,
    darkLogoS3Key: string,
    isInitialCustomization: bool,
  }),
  handlers: shape({
    closePanel: func,
    update: func,
  }),
};

CustomizePanel.defaultProps = {
  branding: initialState.branding,
  handlers: {
    closePanel: () => {},
    update: () => {},
  },
};

export default CustomizePanel;
