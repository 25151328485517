import { keepPreviousData, useQuery } from '@tanstack/react-query';
/* istanbul ignore file */
import { useShallow } from 'zustand/react/shallow';

import { api } from 'app/api/base';
import { URL_TIMELINE } from 'app/api/urls';

import useBlueprintFlow from '../store';

const useActivities = (keys: Array<any> = [], params: any = {}) => {
  const [isAddingBlueprint, isEditingAssignments] = useBlueprintFlow(
    useShallow((state) => [
      state.isAddingBlueprint,
      state.isEditingAssignments,
    ]),
  );

  let periodFilter;

  if (params.period.enumValue !== 'ALL') {
    if (params.period.enumValue !== 'custom_date_range') {
      periodFilter = params.period.enumValue;
    } else if (params.period.enumValue === 'custom_date_range') {
      if (params.period.dateRange.from && params.period.dateRange.to) {
        periodFilter = `${params.period.dateRange.from.toISOString()}@${params.period.dateRange.to.toISOString()}`;
      }
    }
  }

  const {
    data: apiRes,
    isLoading,
    isError,
    ...rest
  } = useQuery({
    queryKey: ['am-activities', params.id, ...(keys || [])],
    queryFn: () =>
      api(URL_TIMELINE).get({
        obj_type: 'blueprint',
        obj_id: params.id,
        sizePerPage: params.sizePerPage,
        page: params.page,
        ordering: params.ordering,
        type: params.type,
        period: periodFilter,
      }),
    enabled: !isAddingBlueprint && !isEditingAssignments && Boolean(params.id),
    placeholderData: keepPreviousData,
  });

  const apiData = apiRes?.data;
  const count = apiData?.count;
  const activities = apiData?.results;

  return {
    data: apiData,
    queryKey: ['am-activities', params.id, ...(keys || [])],
    queryKeyBase: ['am-activities', params.id],
    isLoading,
    isError,
    count,
    activities,
    ...rest,
  };
};

export default useActivities;
