import { Button, TextInput } from '@kandji-inc/bumblebee';
import React, { useEffect, useState } from 'react';

const DemoteUsers = (props) => {
  const { param, update, isDisabled, validations } = props;
  const { details } = param;
  const [exclusions, setExclusions] = useState(details || []);

  const {
    DemoteUsers: validateParam,
    DemoteUsers_exclusion: validateExclusion,
  } = validations;

  useEffect(() => {
    if (isDisabled) {
      setExclusions(details || []);
    }
  }, [param, isDisabled]);

  useEffect(() => {
    if (!isDisabled) {
      const time = setTimeout(() => {
        if (!exclusions.length) {
          update({ isInvalid: true });
        } else {
          update({
            details: exclusions,
            isInvalid: exclusions.some((d) => !d),
          });
        }
      }, 250);

      return () => clearTimeout(time);
    }
  }, [exclusions]);

  const addExclusion = () => setExclusions((prev) => [...prev, '']);
  const onTrash = (i) =>
    setExclusions((prev) => prev.filter((_, idx) => idx !== i));

  return (
    <div className="" style={{ maxWidth: '65%' }}>
      {exclusions.map((exclusion, idx) => {
        return (
          <div
            key={idx}
            className="b-mb3 b-flex"
            style={{ alignItems: 'flex-end' }}
          >
            <TextInput
              fieldsGrid
              disabled={isDisabled}
              label="Excluded administrator short names (account names)"
              value={exclusion}
              placeholder="Username"
              onChange={(e) => {
                const val = e.target.value;
                setExclusions((prev) => {
                  prev[idx] = val;
                  return [...prev];
                });
              }}
              validator={(v) => [
                { message: 'Required', invalid: () => !v, trigger: ['onBlur'] },
              ]}
              {...validateExclusion.syncInvalid(!exclusion[idx], {
                key: `demote-users-exclusion-${idx}`,
              })}
            />
            {exclusions.length > 1 && (
              <Button
                className="b-ml-tiny"
                onClick={() => onTrash(idx)}
                disabled={isDisabled}
                kind="link"
                icon="trash-can"
                theme="error"
              />
            )}
          </div>
        );
      })}
      <Button onClick={addExclusion} disabled={isDisabled} icon="plus">
        Add Exclusion
      </Button>
      {validateParam.displayInvalid(param.isInvalid && !exclusions.length)}
    </div>
  );
};

export default DemoteUsers;
