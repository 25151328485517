export function buildDeepLink(url: string) {
  const httpRegEx = new RegExp('^(http|https)://', 'i');
  const hasHttpPrefix = httpRegEx.test(url);

  const link = new URL(hasHttpPrefix ? url : `https://${url}`);

  const parsedSubdomain = link.hostname.split('.').slice(0, -2).join('.');
  const parsedDomain = link.hostname.split('.').slice(-2).join('.');
  const deepLinkUrl = `https://${parsedSubdomain}-admin.${parsedDomain}/admin/integration/authorize/kandjiapiservice`;
  return deepLinkUrl;
}
