import * as React from 'react';

export function useHubspotHandler() {
  React.useEffect(() => {
    document.querySelector('body').classList.add('hubspot-handler');

    return () =>
      document.querySelector('body').classList.remove('hubspot-handler');
  }, []);
}
