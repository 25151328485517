import {
  Banner,
  Button,
  modal as Modal,
  Toaster as toaster,
} from '@kandji-inc/bumblebee';
import { bool, func, number, string } from 'prop-types';
import React from 'react';

import SemiBold from 'features/util/components/semi-bold';
import { wAmount } from 'features/util/misc';
import { i18n } from 'i18n';
import useDeleteSlackIntegration from '../../../../slack/hooks/use-delete-slack-integration';
import { CONFIG_SHAPE } from '../../util';

function DeleteIntegrationModal({
  isOpen,
  onClose,
  name,
  id,
  notificationsAmount,
  isLast,
  config,
}) {
  const deleteSlackIntegrationMutation = useDeleteSlackIntegration();

  function onDeleteClick() {
    deleteSlackIntegrationMutation
      .mutateAsync(id)
      .then(() => {
        toaster(
          isLast
            ? i18n.t(
                '{name} {integrationAlias} has been deleted. {title} will no longer display as an active integration.',
                {
                  name: name,
                  integrationAlias: config.integrationAlias,
                  title: config.title,
                },
              )
            : i18n.t('{name} {integrationAlias} has been deleted.', {
                name: name,
                integrationAlias: config.integrationAlias,
              }),
        );

        onClose();
      })
      .catch((e) => {
        console.log(e);
        toaster(i18n.common.error());
      });
  }

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      withoutCloseButton
      parts={{
        header: {
          text: `${i18n.t('Delete')} ${config.title} ${config.integrationAlias}`,
        },
        content: {
          children: (
            <>
              <p className="b-mb">
                {i18n.$t('Are you sure you want to delete {name}?', { name })}
              </p>
              <p className="b-mb">
                {isLast
                  ? i18n.$t(
                      'Deleting this integration will remove any event notifications that have been configured under this {integrationAlias}, and remove the integration with {title} from your Kandji instance. This action cannot be undone.',
                      {
                        integrationAlias: config.integrationAlias,
                        title: config.title,
                      },
                    )
                  : i18n.$t(
                      'Deleting this integration will remove any event notifications that have been configured under this {integrationAlias}. This action cannot be undone.',
                      { integrationAlias: config.integrationAlias },
                    )}
              </p>
              {!!notificationsAmount && (
                <Banner
                  theme="info"
                  kind="block"
                  // TODO: icon='bell-circle-filled' [INT-284]
                  icon="bell"
                  style={{ padding: 'var(--b-gap)' }}
                >
                  <p>
                    <SemiBold>
                      {i18n.t(
                        '{count, plural, one {# event notification} other {# event notifications}}',
                        { count: notificationsAmount },
                      )}
                    </SemiBold>{' '}
                    {i18n.t('will also be removed')}
                  </p>
                </Banner>
              )}
            </>
          ),
        },
        footer: {
          children: (
            <>
              <Button kind="outline" onClick={onClose}>
                {i18n.t('Cancel')}
              </Button>
              <Button
                className="b-ml"
                theme="error"
                onClick={onDeleteClick}
                disabled={deleteSlackIntegrationMutation.isLoading}
                isProgress={deleteSlackIntegrationMutation.isLoading}
              >
                {i18n.t('Delete')}
              </Button>
            </>
          ),
        },
      }}
    />
  );
}

DeleteIntegrationModal.propTypes = {
  isOpen: bool.isRequired,
  onClose: func.isRequired,
  name: string,
  id: number,
  notificationsAmount: number,
  isLast: bool.isRequired,
  config: CONFIG_SHAPE.isRequired,
};

DeleteIntegrationModal.defaultProps = {
  name: null,
  id: null,
  notificationsAmount: null,
};

export default DeleteIntegrationModal;
