import CertificateService from 'features/library-items/items/certificate/service/certificate-service';
import WifiService from 'features/library-items/items/wifi/service/wifi-service';

import { i18n } from 'i18n';
import iconProfileCertificate from './assets/icon-profile-certificate.svg';
import iconProfileWiFi from './assets/icon-profile-wifi.svg';

export default {
  'com.kandji.profile.certificate': {
    label: 'Certificate profile',
    path: 'library/certificate',
    icon: iconProfileCertificate,
    type: CertificateService.certificateTypes.AD_CS,
  },
  'com.kandji.profile.wifi': {
    label: 'Wi-Fi profile',
    path: 'library/wifi',
    icon: iconProfileWiFi,
    type: WifiService.identityCertificates.ADCS,
  },
};

export const i18nAdcsLibraryItemMap = i18n.createMap({
  'Certificate profile': () => i18n.t('Certificate profile'),
  'Wi-Fi profile': () => i18n.t('Wi-Fi profile'),
});
