/* istanbul ignore file */

import React, { createContext, useState, useContext } from 'react';

import { node, object } from 'prop-types';

const PanelContext = createContext({
  panel: {},
  setPanel: () => {},
  openPanel: () => {},
  closePanel: () => {},
});

const PanelProvider = ({ children, value }) => {
  const [panel, setPanel] = useState({});

  // console.log('Panel', panel); // delete

  const openPanel = (name) => setPanel({ [name]: true });

  const closePanel = (closed) => {
    setPanel({});
    if (closed) {
      setTimeout(() => closed(), 500);
    }
  };

  return (
    <PanelContext.Provider
      value={{
        panel,
        setPanel,
        openPanel,
        closePanel,
        ...value,
      }}
    >
      {children}
    </PanelContext.Provider>
  );
};

PanelProvider.propTypes = {
  children: node.isRequired,
  value: object,
};

PanelProvider.defaultProps = {
  value: {},
};

const usePanel = () => useContext(PanelContext);

export default usePanel;
export { PanelContext, PanelProvider };
