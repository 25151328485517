import { useMutation, useQueryClient } from '@tanstack/react-query';
import { newIntegrationsService } from 'src/features/integrations/data-service/new-integrations-service';

const useDeleteSlackIntegration = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (integrationId: number) =>
      newIntegrationsService.deleteIntegration(integrationId),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['integrations'] });
    },
  });
};

export default useDeleteSlackIntegration;
