import {
  Banner,
  Button,
  modal as Modal,
  Toaster as toaster,
} from '@kandji-inc/bumblebee';
import { INT_TYPES, USER_INT_ERRORS } from 'features/integrations/constants';
import { bool, func, oneOf, string } from 'prop-types';
import React, { useState } from 'react';
import { newIntegrationsService } from '../../../../data-service/new-integrations-service';
import configurations from '../../../configurations';
import oops from '../assets/oops.svg';
import timesFilled from '../assets/times-filled.svg';
import './user-int-creation-err-modal.styles.scss';
import { i18n } from 'i18n';

function UserIntCreationErrModal({ type, onClose, errorKind, name, isOpen }) {
  const [isProcessing, setIsProcessing] = useState(false);

  const config = configurations[type];

  const typeName = config?.title;
  let bannerText;
  let title;
  let description;

  switch (errorKind) {
    case USER_INT_ERRORS.emailExistError:
      title = i18n.t('Account is already used');
      bannerText = i18n.t(
        'There is an integration configured with this {typeName} account',
        { typeName },
      );
      description = i18n.t(
        'We could not complete the user integration with {typeName}. Please try again and make sure you are using an account no integration is configured with.',
        { typeName },
      );
      break;
    case USER_INT_ERRORS.authError:
      title = i18n.t('Administrative account required');
      bannerText = i18n.t(
        'Authenticating user must have administrative access of the {typeName} account.',
        { typeName },
      );
      description = i18n.t(
        'We could not complete the user integration with {typeName}. Please try again and make sure you are using an admin account to log in.',
        { typeName },
      );
      break;
    default:
      title = i18n.t('There was an unexpected error');
      bannerText = i18n.t('Unable to add your {typeName} account.', {
        typeName,
      });
      description = i18n.t(
        'We could not connect with {typeName} due to an unknown error. Please try again later.',
        { typeName },
      );
  }

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      withoutCloseButton
      parts={{
        content: {
          children: (
            <>
              <img src={oops} alt="oops-icon" className="b-mb2" />
              <h1 className="b-h1 b-mb1">{title}</h1>
              <p className="b-txt-light b-mb1">{description}</p>
              <Banner
                theme="error"
                src={timesFilled}
                className="int-creation-err-modal_banner"
              >
                <p>{bannerText}</p>
              </Banner>
            </>
          ),
          className: 'cmn-modal-content_size',
        },
        footer: {
          children: (
            <>
              <Button onClick={onClose} kind="outline" disabled={isProcessing}>
                {i18n.t('Cancel setup', {
                  _context:
                    'a button to cancel integration setup in modal window',
                })}
              </Button>
              <Button
                className="b-ml"
                disabled={isProcessing}
                isProgress={isProcessing}
                icon={isProcessing ? 'arrows-rotate' : null}
                onClick={() => {
                  setIsProcessing(true);
                  newIntegrationsService
                    .createIntegration({ type, name })
                    .then(({ data }) => {
                      window.location = data.redirect_url;
                    })
                    .catch((e) => {
                      console.log(e);
                      toaster(i18n.t('Encountered an error while logging in'));
                    })
                    .finally(() => setIsProcessing(false));
                }}
              >
                {i18n.t('Try a different account', {
                  _context:
                    'a button to try a different account in modal window',
                })}
              </Button>
            </>
          ),
        },
      }}
    />
  );
}

UserIntCreationErrModal.propTypes = {
  type: oneOf([INT_TYPES.gsuite, INT_TYPES.office365]),
  errorKind: string,
  onClose: func.isRequired,
  name: string,
  isOpen: bool.isRequired,
};

UserIntCreationErrModal.defaultProps = {
  type: null,
  errorKind: null,
  name: '',
};

export default UserIntCreationErrModal;
