const isNotProduction = process.env.NODE_ENV !== 'production';

/**
 * Throws an error in non-productions environments if the condition is false.
 *
 * @param condition - The condition to check and throw if resolves to false.
 * @param message - The message to throw if the condition is false.
 */
export function invariant(condition: boolean, message?: string): void {
  const canThrow = isNotProduction && !condition;
  if (canThrow) {
    throw new Error(`Invariant failed${message ? `: ${message}` : ''}`);
  }
}

/**
 * Gets object value by prop name or dot-notation path/array of path parts for
 * nested object values. If not found returns default value if given or undefined.
 *
 * @remarks
 * Dep-free version of lodash `get` vendored from just-safe-get@4.0.1 with modifications.
 *
 * @param obj - Any nested or non-nested object with string keys
 * @param propsArg - Path to value represented as string demlimited by dot for
 * accessing nested values or an array of strings for accessing nested values.
 * @param defaultValue - Value to return if `propsArg` is not found in `obj`.
 * @returns The value at the given path or `defaultValue` if not found.
 */
export function get<R, O = Record<string, unknown>>(
  obj: O,
  propsArg: string | string[],
  defaultValue?: R,
): R {
  let outputValue;
  if (!obj) {
    return defaultValue;
  }
  let props;
  let prop;

  if (Array.isArray(propsArg)) {
    props = propsArg.slice(0);
  }
  if (typeof propsArg === 'string') {
    props = propsArg.split('.');
  }
  if (typeof propsArg === 'symbol') {
    props = [propsArg];
  }

  invariant(
    Array.isArray(props),
    `\`propsArg\` must be an array, a dot-notation path string or a symbol${
      typeof propsArg !== 'symbol'
        ? `, received ${typeof propsArg} value of ${propsArg}.`
        : ''
    }`,
  );

  outputValue = obj;
  while (props.length) {
    prop = props.shift();

    if (!outputValue) {
      return defaultValue;
    }

    outputValue = outputValue[prop];

    if (outputValue === undefined) {
      return defaultValue;
    }
  }

  return outputValue as R;
}
