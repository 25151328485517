import { bool, number, string } from 'prop-types';
import { i18n } from 'src/i18n';
import styled from 'styled-components';
import circleBellIcon from '../../../assets/bell-filled-circle-icon.svg';
import msTeamsIcon from '../../../assets/teams-circle-icon.svg';

const StyledModalContent = styled.div`
  padding: 0;
  display: grid;
  width: 496px;
  grid-gap 16px;
`;
const StyledDeleteTeamsBanner = styled.div`
  display: grid-inline;
  padding: 10px 16px;
  border-radius: 4px;
  align-items: center;
  grid-gap: 8px;
  grid-template columns: auto auto;
`;

const Content = (props) => {
  const { notificationCount, name, isLastIntegration } = props;
  const extraDeleteInfo = isLastIntegration
    ? i18n.t(
        'Deleting this integration will remove any event notifications that have been configured under this account, and remove the entire integration with Microsoft Teams from your Kandji instance. This action cannot be undone.',
      )
    : i18n.t(
        'Deleting this integration will remove any event notifications that have been configured under this account. This action cannot be undone.',
      );

  const DeleteWarningBanner = () => (
    <StyledDeleteTeamsBanner
      className="b-kandji-banner__icon banner-warning"
      data-testid="deleteTeamsBanner"
    >
      <i className="bi bi-exclamation-triangle" />
      <p className="b-txt">
        {i18n.t(
          'Deleting the only Teams account will also delete the integration.',
        )}
      </p>
    </StyledDeleteTeamsBanner>
  );

  const TeamsInfoBanner = () => (
    <div className="b-kandji-banner b-kandji-banner--info b-kandji-banner">
      <img src={msTeamsIcon} alt={i18n.t('microsoft teams icon')} />
      <p className="b-txt">
        {i18n.$t('{name} will be deleted.', {
          name: (
            <b className="b-txt-bold">
              {i18n.t('Microsoft Teams integration')}
            </b>
          ),
        })}
      </p>
    </div>
  );

  return (
    <StyledModalContent>
      {isLastIntegration && <DeleteWarningBanner />}

      <p className="b-txt">
        {i18n.t('Are you sure you would like to delete')}{' '}
        <b className="b-txt-bold">{name}</b>?
      </p>
      <p className="b-txt"> {extraDeleteInfo} </p>

      <div className="b-kandji-banner b-kandji-banner--info b-kandji-banner">
        <img src={circleBellIcon} alt="bell icon" />
        <p className="b-txt">
          {i18n.$t('{notification} will be removed.', {
            notification: (
              <b className="b-txt-bold">
                {notificationCount} {i18n.$t('event notification')}
              </b>
            ),
          })}
        </p>
      </div>

      {isLastIntegration && <TeamsInfoBanner />}
    </StyledModalContent>
  );
};

export default Content;

Content.propTypes = {
  name: string.isRequired,
  isLastIntegration: bool.isRequired,
  notificationCount: number.isRequired,
};
