import { array } from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import integrationIcon from '../../../assets/generic-team-icon-round.svg';
import msTeamsIcon from '../../../assets/ms-teams-icon-round.svg';
import notificationIcon from '../../../assets/notification-bell-icon-round.svg';

const ModalContent = styled.div`
  padding: 0;
  display: grid;
  width: 560px;
`;

const ModalItem = styled.div`
  margin-top: var(--b-gap);
  padding: var(--b-gap);
  border-radius: 4px;
  background-color: var(--color-neutral-10);
`;

const Item = ({ icon, text }) => (
  <ModalItem>
    <img src={icon} className="mr-1" alt="integration icon" /> {text}
  </ModalItem>
);

const Content = (props) => {
  const { integrations } = props;

  if (!integrations) {
    return null;
  }

  const eventNotificationCount = integrations.reduce(
    (prev, curr) => prev + curr.notifications.length,
    0,
  );

  return (
    <ModalContent>
      <p className="b-txt mb-3">
        Are you sure you want to delete the integration with{' '}
        <strong>Microsoft Teams?</strong>
      </p>
      <p className="b-txt mb-2">
        Deleting this integration will remove all account connections and any
        event notifications that have been configured. This action cannot be
        undone.
      </p>
      {integrations.map((integration) => (
        <Item
          key={integration.id}
          icon={integrationIcon}
          text={`${integration.integration_name} will be removed.`}
        />
      ))}

      <Item
        icon={notificationIcon}
        text={`${eventNotificationCount} event notifications will be removed.`}
      />
      <Item
        icon={msTeamsIcon}
        text="Microsoft Teams integration will be deleted."
      />
    </ModalContent>
  );
};

export default Content;

Content.propTypes = {
  integrations: array.isRequired,
};
