import { Flex, Hint } from '@kandji-inc/nectar-ui';
import { sortBy } from 'lodash';
import { array, func, string } from 'prop-types';
import React from 'react';
import { i18n } from 'src/i18n';
import SelectDropdown from '../../../../../components/select-dropdown';
import { useMSTeamsChannels } from '../../../hooks';

const ChannelSelect = (props) => {
  const { onChange, value, selectedTeamId, integrationId } = props;

  const {
    data: msTeamsChannelData,
    isLoading,
    error,
  } = useMSTeamsChannels(integrationId, selectedTeamId);

  const errorMessage =
    error?.response?.data?.error?.message ||
    (error?.message ? i18n.t('Failed to load response data.') : '');
  const channelData = msTeamsChannelData?.data?.value;
  const channelsSorted = sortBy(channelData, ['name']);

  return (
    <Flex flow="column" gap="md" className="b-gap1">
      <div>
        <label htmlFor="channel-select" className="b-txt">
          {i18n.t('Channel')}{' '}
        </label>
        <p className="b-txt-light">
          {i18n.t('Specify a channel or multiple channels.')}
        </p>
      </div>

      <SelectDropdown
        inputId="channel-select"
        value={value}
        options={channelsSorted}
        onChange={onChange}
        placeholder={i18n.t('Search or select from list')}
        disabled={isLoading || channelsSorted.length === 0}
        isLoading={isLoading}
        isMulti
        isOptionDisabled={() => value.length >= 10}
        hideSelectedOptions={false}
        closeMenuOnSelect={false}
        hideSearchIcon
        aria-label={i18n.t('select channel')}
      />

      {errorMessage && <Hint label={errorMessage} variant="error" />}
    </Flex>
  );
};

export default ChannelSelect;

ChannelSelect.propTypes = {
  selectedTeamId: string,
  integrationId: string.isRequired,
  onChange: func.isRequired,
  value: array,
};

ChannelSelect.defaultProps = {
  value: null,
  selectedTeamId: null,
};
