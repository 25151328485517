// istanbul ignore file
import { createColumnHelper } from '@tanstack/react-table';
import type { z } from 'zod';

import { getIconName } from '../../components/PrismNav/prismNavUtils';
import {
  AlertStatusCell,
  BytesCell,
  DateCell,
  HighlightedTextCell,
  LostModeStatusCell,
  NaCell,
  TagsCell,
  YES_NO_CELL_FILTER_OPTIONS,
  YesNoCell,
  getBlueprintCell,
  getDeviceCell,
  getDeviceUserCell,
  getSpecificDeviceFamilyCell,
} from '../column-utils';
import { getScharedColumns } from './getSharedColumns';

const GB = 1000000000;

export const getDeviceInfoColumns = ({
  globalSchema,
  deviceInfoSchema,
  ffCrossCategoryViews,
}): {
  columnDefs: any[];
  pinnedColumns: string[];
  alwaysHiddenColumns: string[];
} => {
  const combinedProperties = {
    ...globalSchema.schema.properties,
    ...deviceInfoSchema.schema.properties,
  };
  const deviceInfoColumnHelper =
    createColumnHelper<z.infer<typeof combinedProperties>>();

  return {
    columnDefs: [
      deviceInfoColumnHelper.accessor((row) => row.device__name, {
        id: 'device__name',
        enableHiding: false,
        cell: (info) => getDeviceCell(info, 'device_information'),
        meta: {
          displayName: combinedProperties.device__name.title,
          displayIcon: ffCrossCategoryViews
            ? getIconName('device_information')
            : undefined,
          filterType: 'string',
          filterIcon: 'text-size',
          deviceFamily: ['all'],
        },
      }),

      deviceInfoColumnHelper.accessor(
        (row) => row['device_information.model_name'],
        {
          id: 'device_information.model_name',
          meta: {
            displayName: combinedProperties.model_name.title,
            displayIcon: ffCrossCategoryViews
              ? getIconName('device_information')
              : undefined,
            filterType: 'string',
            filterIcon: 'text-size',
            deviceFamily: ['all'],
          },
        },
      ),

      deviceInfoColumnHelper.accessor(
        (row) => row['device_information.display_os_version'],
        {
          id: 'device_information.display_os_version',
          meta: {
            displayName: combinedProperties.display_os_version.title,
            displayIcon: ffCrossCategoryViews
              ? getIconName('device_information')
              : undefined,
            filterType: 'version',
            filterIcon: 'hashtag',
            deviceFamily: ['all'],
          },
        },
      ),

      deviceInfoColumnHelper.accessor((row) => row.serial_number, {
        id: 'serial_number',
        cell: HighlightedTextCell,
        meta: {
          displayName: combinedProperties.serial_number.title,
          displayIcon: ffCrossCategoryViews
            ? getIconName('device_information')
            : undefined,
          filterType: 'string',
          filterIcon: 'text-size',
          deviceFamily: ['all'],
        },
      }),

      deviceInfoColumnHelper.accessor((row) => row.device__user_name, {
        id: 'device__user_name',
        cell: getDeviceUserCell,
        meta: {
          displayName: combinedProperties.device__user_name.title,
          displayIcon: ffCrossCategoryViews
            ? getIconName('device_information')
            : undefined,
          filterType: 'string',
          filterIcon: 'text-size',
          deviceFamily: ['all'],
        },
      }),

      deviceInfoColumnHelper.accessor((row) => row.blueprint_name, {
        id: 'blueprint_name',
        cell: (info) => getBlueprintCell(info),
        meta: {
          displayName: combinedProperties.blueprint_name.title,
          displayIcon: ffCrossCategoryViews
            ? getIconName('device_information')
            : undefined,
          filterType: 'string',
          filterIcon: 'text-size',
          deviceFamily: ['all'],
        },
      }),

      deviceInfoColumnHelper.accessor((row) => row.asset_tag, {
        id: 'asset_tag',
        cell: HighlightedTextCell,
        meta: {
          displayName: combinedProperties.asset_tag.title,
          displayIcon: ffCrossCategoryViews
            ? getIconName('device_information')
            : undefined,
          filterType: 'string',
          filterIcon: 'text-size',
          deviceFamily: ['all'],
        },
      }),

      deviceInfoColumnHelper.accessor(
        (row) => row['device_information.last_enrollment_date'],
        {
          id: 'device_information.last_enrollment_date',
          cell: DateCell,
          meta: {
            displayName: combinedProperties.last_enrollment_date.title,
            displayIcon: ffCrossCategoryViews
              ? getIconName('device_information')
              : undefined,
            filterType: 'date-time',
            filterIcon: 'calendar',
            deviceFamily: ['all'],
          },
        },
      ),

      deviceInfoColumnHelper.accessor(
        (row) => row['device_information.last_checkin'],
        {
          id: 'device_information.last_checkin',
          cell: DateCell,
          meta: {
            displayName: combinedProperties.last_checkin.title,
            displayIcon: ffCrossCategoryViews
              ? getIconName('device_information')
              : undefined,
            filterType: 'date-time',
            filterIcon: 'calendar',
            deviceFamily: ['all'],
          },
        },
      ),

      deviceInfoColumnHelper.accessor(
        (row) => row['device_information.last_checkin_agent'],
        {
          id: 'device_information.last_checkin_agent',
          cell: DateCell,
          meta: {
            displayName: combinedProperties.last_checkin_agent.title,
            displayIcon: ffCrossCategoryViews
              ? getIconName('device_information')
              : undefined,
            filterType: 'date-time',
            filterIcon: 'calendar',
            deviceFamily: ['Mac'],
          },
        },
      ),

      deviceInfoColumnHelper.accessor(
        (row) => row['device_information.last_checkin_mdm'],
        {
          id: 'device_information.last_checkin_mdm',
          cell: DateCell,
          meta: {
            displayName: combinedProperties.last_checkin_mdm.title,
            displayIcon: ffCrossCategoryViews
              ? getIconName('device_information')
              : undefined,
            filterType: 'date-time',
            filterIcon: 'calendar',
            deviceFamily: ['all'],
          },
        },
      ),

      deviceInfoColumnHelper.accessor(
        (row) => row['device_information.device_id'],
        {
          id: 'device_information.device_id',
          meta: {
            displayName: combinedProperties.device_id.title,
            displayIcon: ffCrossCategoryViews
              ? getIconName('device_information')
              : undefined,
            filterType: 'string',
            filterIcon: 'text-size',
            deviceFamily: ['all'],
          },
        },
      ),

      deviceInfoColumnHelper.accessor((row) => row.device__user_email, {
        id: 'device__user_email',
        cell: HighlightedTextCell,
        meta: {
          displayName: combinedProperties.device__user_email.title,
          displayIcon: ffCrossCategoryViews
            ? getIconName('device_information')
            : undefined,
          filterType: 'string',
          filterIcon: 'text-size',
          deviceFamily: ['all'],
        },
      }),

      deviceInfoColumnHelper.accessor(
        (row) =>
          Number.isNaN(row['device_information.device_capacity'])
            ? null
            : row['device_information.device_capacity'] * GB,
        {
          id: 'device_information.device_capacity',
          cell: BytesCell,
          meta: {
            displayName: combinedProperties.device_capacity.title,
            displayIcon: ffCrossCategoryViews
              ? getIconName('device_information')
              : undefined,
            filterType: 'number',
            filterIcon: 'hashtag',
            deviceFamily: ['all'],
          },
        },
      ),

      deviceInfoColumnHelper.accessor(
        (row) => row['device_information.battery_health'],
        {
          id: 'device_information.battery_health',
          cell: (info) =>
            getSpecificDeviceFamilyCell(
              info,
              ['iPhone', 'Mac', 'iPad', 'AppleTV'],
              'string',
            ),
          meta: {
            displayName: combinedProperties.battery_health.title,
            displayIcon: ffCrossCategoryViews
              ? getIconName('device_information')
              : undefined,
            filterType: 'enum',
            filterIcon: 'list-check',
            filterOptions: [
              { value: 'non-genuine', label: 'Non-genuine' },
              { value: 'normal', label: 'Normal' },
              { value: 'service-recommended', label: 'Service recommended' },
              { value: 'unknown', label: 'Unknown' },
              { value: 'unsupported', label: 'Unsupported' },
            ],
            deviceFamily: ['iPhone', 'Mac'],
          },
        },
      ),

      deviceInfoColumnHelper.accessor(
        (row) => row['device_information.host_name'],
        {
          id: 'device_information.host_name',
          cell: (info) => getSpecificDeviceFamilyCell(info, ['Mac']),
          meta: {
            displayName: combinedProperties.host_name.title,
            displayIcon: ffCrossCategoryViews
              ? getIconName('device_information')
              : undefined,
            filterType: 'string',
            filterIcon: 'text-size',
            deviceFamily: ['Mac'],
          },
        },
      ),

      deviceInfoColumnHelper.accessor(
        (row) => row['device_information.apple_silicon'],
        {
          id: 'device_information.apple_silicon',
          cell: (info) => getSpecificDeviceFamilyCell(info, ['Mac'], 'boolean'),
          meta: {
            displayName: combinedProperties.apple_silicon.title,
            displayIcon: ffCrossCategoryViews
              ? getIconName('device_information')
              : undefined,
            filterType: 'boolean',
            filterOptions: [...YES_NO_CELL_FILTER_OPTIONS],
            filterIcon: 'circle-dot',
            deviceFamily: ['Mac'],
          },
        },
      ),

      deviceInfoColumnHelper.accessor(
        (row) => row['device_information.local_hostname'],
        {
          id: 'device_information.local_hostname',
          cell: (info) => getSpecificDeviceFamilyCell(info, ['Mac']),
          meta: {
            displayName: combinedProperties.local_hostname.title,
            displayIcon: ffCrossCategoryViews
              ? getIconName('device_information')
              : undefined,
            filterIcon: 'text-size',
            filterType: 'string',
            deviceFamily: ['Mac'],
          },
        },
      ),

      deviceInfoColumnHelper.accessor(
        (row) => row['device_information.model'],
        {
          id: 'device_information.model',
          meta: {
            displayName: combinedProperties.model.title,
            displayIcon: ffCrossCategoryViews
              ? getIconName('device_information')
              : undefined,
            filterType: 'string',
            filterIcon: 'text-size',
            deviceFamily: ['all'],
          },
        },
      ),

      deviceInfoColumnHelper.accessor((row) => row.model_id, {
        id: 'model_id',
        meta: {
          displayName: combinedProperties.model_id.title,
          displayIcon: ffCrossCategoryViews
            ? getIconName('device_information')
            : undefined,
          filterType: 'string',
          filterIcon: 'text-size',
          deviceFamily: ['all'],
        },
      }),

      deviceInfoColumnHelper.accessor(
        (row) => row['device_information.os_build'],
        {
          id: 'device_information.os_build',
          meta: {
            displayName: combinedProperties.os_build.title,
            displayIcon: ffCrossCategoryViews
              ? getIconName('device_information')
              : undefined,
            filterType: 'string',
            filterIcon: 'text-size',
            deviceFamily: ['all'],
          },
        },
      ),

      deviceInfoColumnHelper.accessor(
        (row) => row['device_information.marketing_name'],
        {
          id: 'device_information.marketing_name',
          meta: {
            displayName: combinedProperties.marketing_name.title,
            displayIcon: ffCrossCategoryViews
              ? getIconName('device_information')
              : undefined,
            filterType: 'string',
            filterIcon: 'text-size',
            deviceFamily: ['all'],
          },
        },
      ),

      deviceInfoColumnHelper.accessor(
        (row) => row['device_information.supplemental_build_version'],
        {
          id: 'device_information.supplemental_build_version',
          meta: {
            displayName: combinedProperties.supplemental_build_version.title,
            displayIcon: ffCrossCategoryViews
              ? getIconName('device_information')
              : undefined,
            filterType: 'string',
            filterIcon: 'text-size',
            deviceFamily: ['all'],
          },
        },
      ),

      deviceInfoColumnHelper.accessor(
        (row) => row['device_information.supplemental_os_version_extra'],
        {
          id: 'device_information.supplemental_os_version_extra',
          meta: {
            displayName: combinedProperties.supplemental_os_version_extra.title,
            displayIcon: ffCrossCategoryViews
              ? getIconName('device_information')
              : undefined,
            filterType: 'string',
            filterIcon: 'text-size',
            deviceFamily: ['all'],
          },
        },
      ),

      deviceInfoColumnHelper.accessor(
        (row) => row['device_information.os_version'],
        {
          id: 'device_information.os_version',
          meta: {
            displayName: combinedProperties.os_version.title,
            displayIcon: ffCrossCategoryViews
              ? getIconName('device_information')
              : undefined,
            filterType: 'version',
            filterIcon: 'hashtag',
            deviceFamily: ['all'],
          },
        },
      ),

      deviceInfoColumnHelper.accessor(
        (row) => row['device_information.first_enrollment_date'],
        {
          id: 'device_information.first_enrollment_date',
          cell: DateCell,
          meta: {
            displayName: combinedProperties.first_enrollment_date.title,
            displayIcon: ffCrossCategoryViews
              ? getIconName('device_information')
              : undefined,
            filterType: 'date-time',
            filterIcon: 'calendar',
            deviceFamily: ['all'],
          },
        },
      ),

      deviceInfoColumnHelper.accessor(
        (row) => row['device_information.agent_version'],
        {
          id: 'device_information.agent_version',
          cell: (info) => getSpecificDeviceFamilyCell(info, ['Mac']),
          meta: {
            displayName: combinedProperties.agent_version.title,
            displayIcon: ffCrossCategoryViews
              ? getIconName('device_information')
              : undefined,
            filterType: 'version',
            filterIcon: 'hashtag',
            deviceFamily: ['Mac'],
          },
        },
      ),

      deviceInfoColumnHelper.accessor(
        (row) => row['device_information.mdm_enabled'],
        {
          id: 'device_information.mdm_enabled',
          cell: YesNoCell,
          meta: {
            displayName: combinedProperties.mdm_enabled.title,
            displayIcon: ffCrossCategoryViews
              ? getIconName('device_information')
              : undefined,
            filterType: 'boolean',
            filterOptions: [...YES_NO_CELL_FILTER_OPTIONS],
            filterIcon: 'circle-dot',
            deviceFamily: ['all'],
          },
        },
      ),

      deviceInfoColumnHelper.accessor(
        (row) => row['device_information.agent_installed'],
        {
          id: 'device_information.agent_installed',
          cell: (info) => getSpecificDeviceFamilyCell(info, ['Mac'], 'boolean'),
          meta: {
            displayName: combinedProperties.agent_installed.title,
            displayIcon: ffCrossCategoryViews
              ? getIconName('device_information')
              : undefined,
            filterType: 'boolean',
            filterOptions: [...YES_NO_CELL_FILTER_OPTIONS],
            filterIcon: 'circle-dot',
            deviceFamily: ['Mac'],
          },
        },
      ),

      deviceInfoColumnHelper.accessor(
        (row) => row['device_information.shared_ipad'],
        {
          id: 'device_information.shared_ipad',
          cell: (info) =>
            getSpecificDeviceFamilyCell(info, ['iPad'], 'boolean'),
          meta: {
            displayName: combinedProperties.shared_ipad.title,
            displayIcon: ffCrossCategoryViews
              ? getIconName('device_information')
              : undefined,
            filterType: 'boolean',
            filterOptions: [...YES_NO_CELL_FILTER_OPTIONS],
            filterIcon: 'circle-dot',
            deviceFamily: ['iPad'],
          },
        },
      ),

      deviceInfoColumnHelper.accessor(
        (row) => row['device_information.cellular_technology'],
        {
          id: 'device_information.cellular_technology',
          cell: (info) => getSpecificDeviceFamilyCell(info, ['iPad', 'iPhone']),
          meta: {
            displayName: combinedProperties.cellular_technology.title,
            displayIcon: ffCrossCategoryViews
              ? getIconName('device_information')
              : undefined,
            filterType: 'enum',
            filterIcon: 'list-check',
            filterOptions: [
              { value: 'None', label: 'None' },
              { value: 'GSM', label: 'GSM' },
              { value: 'CDMA', label: 'CDMA' },
              { value: 'GSM and CDMA', label: 'Both GSM and CDMA' },
            ],
            deviceFamily: ['iPad', 'iPhone'],
          },
        },
      ),

      deviceInfoColumnHelper.accessor(
        (row) => row['device_information.data_roaming'],
        {
          id: 'device_information.data_roaming',
          cell: (info) => {
            if (
              info.row.original.device__family === 'iPad' &&
              info.row.original['device_information.cellular_technology'] ===
                'None'
            ) {
              return NaCell();
            }
            return getSpecificDeviceFamilyCell(
              info,
              ['iPad', 'iPhone'],
              'boolean',
            );
          },
          meta: {
            displayName: combinedProperties.data_roaming.title,
            displayIcon: ffCrossCategoryViews
              ? getIconName('device_information')
              : undefined,
            filterType: 'boolean',
            filterOptions: [...YES_NO_CELL_FILTER_OPTIONS],
            filterIcon: 'circle-dot',
            deviceFamily: ['iPad', 'iPhone'],
          },
        },
      ),

      deviceInfoColumnHelper.accessor(
        (row) => row['device_information.hotspot'],
        {
          id: 'device_information.hotspot',
          cell: (info) => {
            if (
              info.row.original.device__family === 'iPad' &&
              info.row.original['device_information.cellular_technology'] ===
                'None'
            ) {
              return NaCell();
            }
            return getSpecificDeviceFamilyCell(
              info,
              ['iPad', 'iPhone'],
              'boolean',
            );
          },
          meta: {
            displayName: combinedProperties.hotspot.title,
            displayIcon: ffCrossCategoryViews
              ? getIconName('device_information')
              : undefined,
            filterType: 'boolean',
            filterOptions: [...YES_NO_CELL_FILTER_OPTIONS],
            filterIcon: 'circle-dot',
            deviceFamily: ['iPad', 'iPhone'],
          },
        },
      ),

      deviceInfoColumnHelper.accessor((row) => row.alert_status, {
        id: 'alert_status',
        cell: AlertStatusCell,
        enableSorting: false,
        meta: {
          displayName: combinedProperties.alert_status.title,
          displayIcon: ffCrossCategoryViews
            ? getIconName('device_information')
            : undefined,
          filterType: 'enum',
          filterIcon: 'list-check',
          filterOptions: [
            { value: 'ALL_CLEAR', label: 'All Clear' },
            { value: 'NO_HISTORY', label: 'No History' },
            { value: 'WARNING', label: 'Warning' },
            { value: 'OFFLINE', label: 'Offline' },
          ],
          deviceFamily: ['all'],
        },
      }),

      deviceInfoColumnHelper.accessor(
        (row) => row['device_information.lost_mode_status'],
        {
          id: 'device_information.lost_mode_status',
          cell: LostModeStatusCell,
          meta: {
            displayName: combinedProperties.lost_mode_status.title,
            displayIcon: ffCrossCategoryViews
              ? getIconName('device_information')
              : undefined,
            filterType: 'enum',
            filterIcon: 'list-check',
            filterOptions: [
              { value: 'PENDING', label: 'Pending' },
              { value: 'ENABLED', label: 'Enabled' },
              { value: 'ERRORED', label: 'Errored' },
            ],
            deviceFamily: ['iPhone', 'iPad'],
          },
        },
      ),

      deviceInfoColumnHelper.accessor((row) => row.tags, {
        id: 'tags',
        cell: TagsCell,
        meta: {
          displayName: combinedProperties.tags.title,
          displayIcon: ffCrossCategoryViews
            ? getIconName('device_information')
            : undefined,
          hideCellTitle: true,
          filterType: 'string',
          filterIcon: 'text-size',
          deviceFamily: ['all'],
        },
      }),

      ...getScharedColumns(deviceInfoSchema, ffCrossCategoryViews),

      /* this columns are always hidden but need to be in column defs to enable */
      /* filtering for export purposes */
      deviceInfoColumnHelper.accessor((row) => row.device__user_id, {
        id: 'device__user_id',
        meta: {
          displayName: combinedProperties.device__user_id.title,
          displayIcon: ffCrossCategoryViews
            ? getIconName('device_information')
            : undefined,
          filterType: 'string',
          filterIcon: 'text-size',
          deviceFamily: ['all'],
        },
      }),
      deviceInfoColumnHelper.accessor((row) => row.blueprint_id, {
        id: 'blueprint_id',
        meta: {
          displayName: combinedProperties.blueprint_id.title,
          displayIcon: ffCrossCategoryViews
            ? getIconName('device_information')
            : undefined,
          filterType: 'string',
          filterIcon: 'text-size',
          deviceFamily: ['all'],
        },
      }),

      deviceInfoColumnHelper.accessor((row) => row.device__family, {
        id: 'device__family',
        meta: {
          displayName: combinedProperties.device__family.title,
          displayIcon: ffCrossCategoryViews
            ? getIconName('device_information')
            : undefined,
          filterType: 'string',
          filterIcon: 'text-size',
          deviceFamily: ['all'],
        },
      }),
    ],
    pinnedColumns: ['device__name'],
    alwaysHiddenColumns: ['device__user_id', 'device__family', 'blueprint_id'],
  };
};
