/* istanbul ignore file */

import React, { createContext, useState, useContext } from 'react';

import { node, object } from 'prop-types';

const IsContext = createContext({
  isLoading: false,
  isWorking: false,
  hasChecked: false,

  setIsLoading: () => {},
  setIsWorking: () => {},
  setHasChecked: () => {},
});

const IsProvider = ({ children, value }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isWorking, setIsWorking] = useState(false);
  const [hasChecked, setHasChecked] = useState(false);

  return (
    <IsContext.Provider
      value={{
        isLoading,
        isWorking,
        hasChecked,

        setIsLoading,
        setIsWorking,
        setHasChecked,

        ...value,
      }}
    >
      {children}
    </IsContext.Provider>
  );
};

IsProvider.propTypes = {
  children: node.isRequired,
  value: object,
};

IsProvider.defaultProps = {
  value: {},
};

const useIs = () => useContext(IsContext);

export default useIs;
export { IsContext, IsProvider };
