import React, { createContext, useContext, useState } from 'react';

const ModalContext = createContext({});

const ModalStateProvider = ({ children }) => {
  const [state, setState] = useState({});

  const setModalState = (data) => {
    setState((prevState) => ({
      ...prevState,
      ...data,
    }));
  };
  const value = {
    state,
    setModalState,
  };
  return (
    <ModalContext.Provider value={value}>{children}</ModalContext.Provider>
  );
};
const useModalState = () => {
  const { state, setModalState } = useContext(ModalContext);
  return { state, setModalState };
};

export { ModalStateProvider, useModalState };
