import {
  Box,
  // Button,
  Flex,
  Pagination,
  // Separator,
  Table,
  Tbody,
  Td,
  // Text,
  Th,
  Thead,
  Toaster_UNSTABLE as Toaster,
  Tr,
  // useDialog,
  // useToast_UNSTABLE,
} from '@kandji-inc/nectar-ui';
import deepcopy from 'deepcopy';
import { useContext, useLayoutEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

import { Loader } from 'src/app/components/interface/Loader';
import { InterfaceContext } from 'src/contexts/interface';
import useDebouncedState from 'src/features/compliance/Policy/Devices/useDebouncedState';
import useAdjustSidebarChatBubble from 'src/features/util/hooks/use-adjust-sidebar-chat-bubble';
import { get } from 'src/features/util/lib';
import { usePagination } from 'src/features/visibility/prism/hooks';
import { NULL_VALUE_N_DASH } from 'src/features/visibility/prism/utils/column-utils';

import { noResultsAlt } from '../../assets';
import { Empty } from '../../components';
import { highlightedText } from '../../helpers';
import useActivities from '../../services/use-activities';
import useParameters from '../../services/use-parameters';
import useSort, { getSortParam } from '../devices/use-sort';
import type { Filter } from './components/FilterBar';
import FilterBar, { defaultFilter } from './components/FilterBar';
import { ExpandedContentCell, columns } from './components/table-columns';

const Activity = () => {
  useAdjustSidebarChatBubble();
  const SIDEBAR_DOCKED_OFFSET = 256;
  const SIDEBAR_CLOSE_OFFSET = 78;
  const { sidebarDocked } = useContext(InterfaceContext);
  const { id } = useParams<{ id?: string }>();
  const { paginationState, setPagination } = usePagination();
  const [sort, onSortColumn] = useSort({ id: 'created_at', state: 'desc' });
  const [debouncedFilters, setFilters, filters] = useDebouncedState<Filter>(
    deepcopy(defaultFilter),
  );
  // const { toast } = useToast_UNSTABLE();
  const [expandedRows, setExpandedRows] = useState([]);
  const [tableContainerRef, setTableContainerRef] = useState<HTMLElement>();

  const { data: parametersData } = useParameters();
  const { count, activities, isLoading } = useActivities(
    [paginationState, sort, debouncedFilters],
    {
      id,
      // search: debouncedFilters.term,
      sizePerPage: paginationState.pageSize,
      ordering: getSortParam(sort.id, sort.state),
      page: paginationState.pageIndex + 1,
      type: debouncedFilters.type,
      period: debouncedFilters.period,
    },
  );

  // const displayToast = (title: string, content?: string) =>
  //   toast({
  //     title,
  //     content,
  //     variant: 'success',
  //     duration: 5000,
  //   });

  useLayoutEffect(() => {
    /* istanbul ignore next */
    const addShadowOnScroll = (e) => {
      const thead = tableContainerRef.querySelector('thead');
      if (thead) {
        const { scrollTop } = e.target as HTMLElement;
        if (!scrollTop) {
          thead.style.boxShadow = 'unset';
        } else {
          thead.style.boxShadow = '2px 2px 4px 0px rgba(15, 19, 23, 0.16)';
        }
      }
    };

    if (tableContainerRef) {
      tableContainerRef.addEventListener('scroll', addShadowOnScroll);
    }

    return () =>
      tableContainerRef?.removeEventListener('scroll', addShadowOnScroll);
  }, [tableContainerRef]);

  const isFilterActive = useMemo(
    () => JSON.stringify(filters) !== JSON.stringify(defaultFilter),
    [filters],
  );
  const shouldShowNoFilterResultState = isFilterActive && count === 0;
  const shouldShowTable = !isLoading && count > 0;

  const currentPage = paginationState.pageIndex + 1;
  const totalPages = Math.ceil((count || 0) / paginationState.pageSize);
  const commonProps = {
    filter: debouncedFilters,
  };

  const TableRows = useMemo(
    (): JSX.Element =>
      activities?.map((activity, idx) => {
        const { id } = activity;
        const isExpanded = expandedRows.includes(id);

        return (
          <>
            <Tr
              key={id}
              data-testid={`am-activity-row_${idx}`}
              onClick={() => {
                setExpandedRows(
                  isExpanded
                    ? expandedRows.filter((row) => row !== id)
                    : [...expandedRows, id],
                );
              }}
              css={{ cursor: 'pointer' }}
            >
              {columns.map((column) => {
                const Cell = column.cell;
                const value = get<string | any>(activity, column.dataKey);
                const expandedCss = isExpanded ? { boxShadow: 'none' } : {};
                return (
                  <Td
                    key={column.name}
                    title={null}
                    css={{
                      $$tdActiveBg: 'transparent',
                      ...(column.meta?.row?.css || {}),
                      ...expandedCss,
                    }}
                  >
                    {Cell ? (
                      <Cell
                        {...commonProps}
                        activity={activity}
                        isExpanded={isExpanded}
                      />
                    ) : (
                      highlightedText(
                        value || NULL_VALUE_N_DASH,
                        /* debouncedFilters.term */ '',
                      )
                    )}
                  </Td>
                );
              })}
            </Tr>
            {isExpanded && (
              <Tr
                key={`${id}_expandedContent`}
                data-testid={`am-activity-row-expanded_${idx}`}
                hoverBg="none"
              >
                <Td
                  title="expand toggle"
                  css={{
                    $$tdActiveBg: 'transparent',
                  }}
                >
                  &nbsp;
                </Td>
                <Td
                  title="Expanded content"
                  valign="top"
                  colSpan={4}
                  css={{
                    $$tdActiveBg: 'transparent',
                    color: '$neutral80',
                  }}
                >
                  <ExpandedContentCell
                    activity={activity}
                    parametersMeta={parametersData?.parameters?.parametersById}
                  />
                </Td>
              </Tr>
            )}
          </>
        );
      }),
    [activities, expandedRows],
  );

  return (
    <Box data-testid="am-activity" hFull>
      {isLoading && (
        <Flex hFull alignItems="center" justifyContent="center">
          <Loader />
        </Flex>
      )}
      <Box
        css={{ height: 'calc(100% - 61px)', overflow: 'auto' }}
        ref={setTableContainerRef}
      >
        <FilterBar
          filter={filters}
          onChange={(field, val) =>
            setFilters((prev) => ({ ...prev, [field]: val }))
          }
          onClearAll={() => setFilters(deepcopy(defaultFilter))}
          isFilterActive={isFilterActive}
        />

        {shouldShowNoFilterResultState && (
          <Empty
            image={noResultsAlt}
            title="No results found"
            message="We couldn't find a match. Try changing your filter parameters, or search with different keywords."
            css={{ height: 'inherit' }}
          />
        )}
        {shouldShowTable && (
          <Table
            aria-label="activity-table"
            data-testid={`am-activity-table-sort-${sort.id}-${sort.state}`}
          >
            <Thead
              style={{
                position: 'sticky',
                top: 0,
              }}
            >
              <Tr>
                {columns.map((column) => {
                  const HeaderCell = column.headerCell;
                  const isSortColumn = sort.id === column.dataKey;
                  const state = isSortColumn ? sort.state : 'none';
                  const onSort = /* istanbul ignore next */ () =>
                    isSortColumn
                      ? onSortColumn()
                      : onSortColumn(column.dataKey, 'asc');

                  return (
                    <Th
                      key={column.name}
                      title={null}
                      sort={
                        column.isSortable && {
                          state,
                          onSort,
                        }
                      }
                      showMenu
                      css={column.meta?.header?.css}
                      data-testid={`am-activity-header-${column.name}`}
                    >
                      {HeaderCell ? (
                        <HeaderCell {...commonProps} />
                      ) : (
                        column.name
                      )}
                    </Th>
                  );
                })}
              </Tr>
            </Thead>
            <Tbody data-testid="am-activity-tbody">{TableRows}</Tbody>
          </Table>
        )}
      </Box>
      {shouldShowTable && (
        <Box
          css={{
            position: 'fixed',
            bottom: 0,
            width: sidebarDocked
              ? `calc(100% - ${SIDEBAR_DOCKED_OFFSET}px)`
              : `calc(100% - ${SIDEBAR_CLOSE_OFFSET}px)`,
            padding: '$3 $5',
            borderTop: '1px solid $neutral20',
            backgroundColor: '$neutral0',
          }}
        >
          <Pagination
            menuAbove
            currentPage={currentPage}
            totalPages={/* istanbul ignore next */ totalPages || 1}
            totalItems={count}
            itemsPerPage={paginationState.pageSize}
            onPageChange={
              /* istanbul ignore next */ (page) =>
                setPagination((prev) => ({
                  ...prev,
                  pageIndex: page - 1,
                }))
            }
            data-testid="am-activity-pagination"
          />
        </Box>
      )}
      <Toaster />
    </Box>
  );
};

export default Activity;
