import { Icon } from '@kandji-inc/bumblebee';
import { enterpriseApi } from 'app/api/base';
import { DATE_FORMAT, momentToLuxon } from 'app/components/common/helpers';
import ActivityTable from 'components/common/activity-table';
import { i18n } from 'i18n';
import { DateTime } from 'luxon';
import { oneOf } from 'prop-types';
import React, { memo } from 'react';
import { useParams } from 'react-router';
import ExpandedComponent from './expanded-rows';

/* istanbul ignore next */
const TYPES = {
  get created() {
    return i18n.t('Token created');
  },
  get name_changed() {
    return i18n.t('Token name changed');
  },
  get permissions_edited() {
    return i18n.t('Token permissions edited');
  },
  get accessed() {
    return i18n.t('Token accessed');
  },
};

const ICONS = {
  created: 'key',
  name_changed: 'pencil',
  permissions_edited: 'lock',
  accessed: 'eye',
};

function TokenPermissionsActivity({ dateFormat }) {
  const tokenId = useParams().id;

  function tokenFetcher(requestParams) {
    return enterpriseApi(`/token/${tokenId}/activity`).get(requestParams);
  }

  const luxonFormat = momentToLuxon(dateFormat);

  const columns = [
    {
      fieldName: 'type',
      header: i18n.t('Description'),
      Cell: ({ item }) => (
        <div className="b-flex b-flex-vc">
          <Icon name={ICONS[item.type]} className="b-txt-light2 b-mr1" />
          <span className="b-txt-bold">{TYPES[item.type]}</span>
        </div>
      ),
      isSortable: false,
      ratio: 2,
    },
    {
      fieldName: 'actor_name',
      header: i18n.t('Team Member'),
      isSortable: true,
      Cell: ({ item }) => item.actor_name || '-',
    },
    {
      fieldName: 'created_at',
      header: i18n.t('Date'),
      Cell: ({ item }) =>
        DateTime.fromISO(item.created_at).toFormat(luxonFormat),
      isSortable: true,
    },
  ];

  return (
    <ActivityTable
      title={i18n.t('Activity')}
      columns={columns}
      ExpandedComponent={ExpandedComponent}
      fetcher={tokenFetcher}
      typesObject={TYPES}
    />
  );
}

TokenPermissionsActivity.propTypes = {
  dateFormat: oneOf(DATE_FORMAT.options),
};

TokenPermissionsActivity.defaultProps = {
  dateFormat: DATE_FORMAT.default,
};

export default memo(TokenPermissionsActivity);
