import { useMutation } from '@tanstack/react-query';
import { newIntegrationsService } from 'src/features/integrations/data-service/new-integrations-service';
import uuid from 'uuid/v4';

// NOTE: Notifications don't get associated to an integration until channels have been selected. Notifications are linked to Integrations through channels

const useCreateNotificationDetail = () =>
  useMutation({
    mutationFn: (name?: string) => {
      // We don't ask the user for a name until later in the process. The uuid acts as a temporary placeholder
      const notificationName = name ?? uuid();
      return newIntegrationsService.createNotificationDetail({
        name: notificationName,
      });
    },
  });

export default useCreateNotificationDetail;
