/* istanbul ignore file */
const FeatureRequestIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="60"
    viewBox="0 0 21.8848 21.5723"
    style={{ minWidth: '60px' }}
  >
    <g>
      <rect height="21.5723" opacity="0" width="21.8848" x="0" y="0" />
      <path
        d="M21.5234 5.78125L21.5234 13.2617C21.5234 16.123 19.9609 17.7246 17.0508 17.7246L10.4492 17.7246L6.92383 20.9473C6.46484 21.377 6.18164 21.5723 5.80078 21.5723C5.24414 21.5723 4.93164 21.1719 4.93164 20.5664L4.93164 17.7246L4.47266 17.7246C1.5625 17.7246 0 16.1328 0 13.2617L0 5.78125C0 2.91016 1.5625 1.30859 4.47266 1.30859L17.0508 1.30859C19.9609 1.30859 21.5234 2.91992 21.5234 5.78125ZM9.92188 5.60547L9.92188 8.66211L6.86523 8.66211C6.35742 8.66211 5.99609 9.01367 5.99609 9.52148C5.99609 10.0195 6.35742 10.3516 6.86523 10.3516L9.92188 10.3516L9.92188 13.418C9.92188 13.916 10.2637 14.2773 10.752 14.2773C11.2695 14.2773 11.6113 13.9258 11.6113 13.418L11.6113 10.3516L14.6777 10.3516C15.1758 10.3516 15.5371 10.0195 15.5371 9.52148C15.5371 9.01367 15.1855 8.66211 14.6777 8.66211L11.6113 8.66211L11.6113 5.60547C11.6113 5.09766 11.2695 4.73633 10.752 4.73633C10.2637 4.73633 9.92188 5.09766 9.92188 5.60547Z"
        fill="#98989d"
      />
    </g>
  </svg>
);

export default FeatureRequestIcon;
