import { links } from 'app/common/constants';
import React, { useContext } from 'react';
import { useHistory, useLocation, useRouteMatch } from 'react-router';
import ComputersTableBlueprint from '../../../../app/components/common/ComputersTableBlueprint';
import TableNoDataHelper from '../../../../app/components/common/TableNoDataHelper';
import BlueprintContext from '../blueprint.context';
import './blueprint-devices.css';

const BlueprintDevices = (props) => {
  const { blueprint } = useContext(BlueprintContext);
  const location = useLocation();
  const history = useHistory();
  const match = useRouteMatch();

  const noDataView = (
    <TableNoDataHelper
      key="##table-empty##"
      message="No devices enrolled"
      btnText="Add A Device"
      onBtnClick={() => history.push(links['add-devices'])}
    />
  );

  return (
    <div className="bl-blueprint-devices">
      <ComputersTableBlueprint
        blueprintId={blueprint.id}
        noDataView={noDataView}
        status={blueprint.selectedStatus}
        location={location}
        match={match}
        history={history}
      />
    </div>
  );
};

export default BlueprintDevices;
