import ExpandedActivityRow from 'components/common/activity-table/expanded-activity-row';
import React, { useMemo } from 'react';
import accessed from './accessed';
import created from './created';
import deleted from './deleted';
import name_changed from './name-changed';
import permissions_edited from './permissions-edited';

export const dataGettersMap = {
  accessed,
  created,
  deleted,
  name_changed,
  permissions_edited,
};

const ExpandedComponent = ({ item }) => {
  const data = useMemo(() => dataGettersMap[item.type](item), [item]);

  return <ExpandedActivityRow data={data} />;
};

export default ExpandedComponent;
