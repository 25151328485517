import { Badge, Box, Flex, Heading, Loader } from '@kandji-inc/nectar-ui';
import merge from 'lodash/merge';
import React, { useState } from 'react';

import {
  categories,
  getItemCategory,
  installTypes,
} from 'src/features/library-items/library/common';

import { noResultsAlt } from '../../assets';
import type { LibraryItem as TLibraryItem } from '../../blueprint-flow.types';
import { Empty } from '../../components';
import { getLibraryItemTypesForFilter, sortLibraryItems } from '../../helpers';
import useBlueprintFlow from '../../store';
import EmptyState from './EmptyState';
import FilterBar, { type Filter as TFilter, defaultFilter } from './FilterBar';
import LibraryItem from './LibraryItem';

const LibraryItemList = () => {
  const [blueprint, libraryItems, isLoadingLibraryItems] = useBlueprintFlow(
    (state) => [
      state.blueprint,
      state.libraryItems,
      state.isLoadingLibraryItems,
    ],
  );
  const [filters, setFilters] = useState<TFilter>(defaultFilter);

  const filterItem = (item: TLibraryItem) =>
    // Library item type filter
    (!filters.libraryItemType.length ||
      filters.libraryItemType.includes(item.defaultConfiguration.name)) &&
    // Status filter
    (!filters.status.length ||
      filters.status.some((status) => item[status] > 0)) &&
    // Device filter
    (!filters.device.length || filters.device.some((device) => item[device])) &&
    // Installation filter
    (!filters.installation.length ||
      filters.installation.some((installation) => {
        if (item.isUpdateOnly) {
          return installation === 'update_only';
        }

        return item[installation] || item.installEnforcement === installation;
      })) &&
    // Term search
    (item.name.toLowerCase().includes(filters.term.toLowerCase()) ||
      item.defaultConfiguration.name
        .toLowerCase()
        .includes(filters.term.toLowerCase()) ||
      item.instanceName?.toLowerCase().includes(filters.term.toLowerCase()));

  if (isLoadingLibraryItems) {
    return (
      <Flex
        hFull
        alignItems="center"
        justifyContent="center"
        data-testid="loading-library-items"
      >
        <Loader size="md" />
      </Flex>
    );
  }

  const libraryItemsInBlueprint: Array<TLibraryItem> =
    blueprint.libraryItems.map((item) => {
      const { id } = item;
      const libraryItemData = libraryItems.find((li) => li.id === id);

      // This can be removed, and replace with just `return item` when the BP
      // API returns libraryItems with app_icon consistently filled for items
      // like auto apps, vpp, OS etc.
      return merge({}, item, libraryItemData);
    });

  if (!libraryItems.length || !libraryItemsInBlueprint.length) {
    return (
      <Box hFull data-testid="no-library-items">
        <EmptyState />
      </Box>
    );
  }

  const showCategoricalView =
    filters.sort === 'li_type_az' || filters.sort === 'li_type_za';
  const categoricalLibraryItems = Object.values(categories)
    .map((category) => {
      // Unique items, with statuses aggregated for duplicates.
      const items: Record<string, TLibraryItem> =
        libraryItemsInBlueprint.reduce((a, item) => {
          if (getItemCategory(item.type) === category) {
            if (a[item.id]) {
              return {
                ...a,
                [item.id]: {
                  ...a[item.id],
                  pendingCount: a[item.id].pendingCount + item.pendingCount,
                  successCount: a[item.id].successCount + item.successCount,
                  failingCount: a[item.id].failingCount + item.failingCount,
                },
              };
            }
            return {
              ...a,
              [item.id]: item,
            };
          }

          return { ...a };
        }, {});

      return {
        category,
        items: Object.values(items),
      };
    })
    .map(({ items, ...rest }) => {
      const filteredItems = items.filter(filterItem);
      const isTypeSort =
        filters.sort === 'li_type_az' || filters.sort === 'li_type_za';

      if (isTypeSort) {
        return {
          ...rest,
          items: sortLibraryItems('li_name_az', filteredItems),
        };
      }

      return {
        ...rest,
        items: filteredItems,
      };
    })
    .filter(({ items }) => items.length)
    .sort((a, b) => {
      if (filters.sort === 'li_type_az') {
        return a.category.localeCompare(b.category);
      }
      if (filters.sort === 'li_type_za') {
        return b.category.localeCompare(a.category);
      }

      return 0;
    });

  const flattenedLibraryItems = sortLibraryItems(
    filters.sort,
    categoricalLibraryItems.map(({ items }) => items).flat(),
  );

  const isFilterActive =
    JSON.stringify(filters) !== JSON.stringify(defaultFilter);
  const shouldShowNoFilterResultState =
    isFilterActive && !categoricalLibraryItems.length;

  const libraryItemTypeOptions = getLibraryItemTypesForFilter(
    libraryItemsInBlueprint,
  );

  return (
    <Box hFull css={{ overflow: 'auto' }}>
      <Flex flow="column" gap="md" pb5>
        <FilterBar
          filter={filters}
          libraryItemTypeOptions={libraryItemTypeOptions}
          onChange={(field, value) =>
            setFilters((prev) => ({ ...prev, [field]: value }))
          }
        />

        {shouldShowNoFilterResultState && (
          <Box hFull>
            <Empty
              image={noResultsAlt}
              title="No results found"
              message="We couldn't find a match. Try changing your filter parameters, or search with different keywords."
              css={{ height: 'calc(100vh - 205px)' }}
            />
          </Box>
        )}

        {showCategoricalView &&
          categoricalLibraryItems.map(({ category, items }) => (
            <Flex key={category} flow="column" gap="md" pr5 pl5>
              <Flex gap="sm" alignItems="center">
                <Heading size="4" css={{ fontWeight: 500, color: '#000' }}>
                  {category}
                </Heading>
                <Box>
                  <Badge css={{ padding: '0px 6px' }}>{items.length}</Badge>
                </Box>
              </Flex>
              {items.map((item) => (
                <LibraryItem
                  key={item.id}
                  item={item}
                  searchTerm={filters.term}
                />
              ))}
            </Flex>
          ))}

        {!showCategoricalView && (
          <Flex flow="column" gap="md" pr5 pl5>
            {flattenedLibraryItems.map((item) => (
              <LibraryItem
                key={item.id}
                item={item}
                searchTerm={filters.term}
              />
            ))}
          </Flex>
        )}
      </Flex>
    </Box>
  );
};

export default LibraryItemList;
