/* istanbul ignore file */

import { i18n } from 'src/i18n';

// /////////////////////////////////////////////////////
// Helpers /////////////////////////////////////////////
// /////////////////////////////////////////////////////

const requiredField =
  (message = i18n.t('Required')) =>
  (v) => {
    const r1 = {
      invalid: () => !String(v).length,
      trigger: ['onBlur', 'onInput'],
      message,
    };
    return r1;
  };

const nameExists = (name, values) => values.includes(name);

const duplicateNameServer =
  (message = i18n.t('Name already exists'), serverList) =>
  (serverName, name = '') => ({
    invalid: () => serverName !== name && nameExists(serverName, serverList),
    trigger: ['onBlur', 'onInput'],
    message,
  });

const duplicateNameServerSidePanel =
  (message = i18n.t('Name must be unique'), serverListSidePanel) =>
  (serverName) => ({
    invalid: () => nameExists(serverName, serverListSidePanel),
    trigger: ['onBlur', 'onInput'],
    message,
  });

// /////////////////////////////////////////////////////
// Validators //////////////////////////////////////////
// /////////////////////////////////////////////////////

const validatorAddServerName =
  (serverNames, existingServerNames) => (serverName) => {
    const r1 = requiredField()(serverName);
    const r2 = duplicateNameServer(
      i18n.t('Name already exists'),
      existingServerNames,
    )(serverName);
    const r3 = duplicateNameServerSidePanel(
      i18n.t('Name must be unique'),
      serverNames,
    )(serverName);
    return [r1, r2, r3];
  };

const validatorEditServerName = (existingServerNames, name) => (serverName) => {
  const r1 = requiredField()(serverName);
  const r2 = duplicateNameServer(
    i18n.t('Name already exists'),
    existingServerNames,
  )(serverName, name);
  return [r1, r2];
};

export {
  requiredField,
  nameExists,
  duplicateNameServer,
  duplicateNameServerSidePanel,
  validatorAddServerName,
  validatorEditServerName,
};
