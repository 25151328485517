import { Buffer } from 'buffer';
import {
  TextInput,
  onEmptyBlurValidator,
  useInvalidations,
} from '@kandji-inc/bumblebee';
import { oktaDeviceTrustService } from 'features/integrations/components-for-specific-types/okta/services';
import type { ChangeEvent } from 'react';
import React, { useState } from 'react';
import useEscapeKey from 'src/features/integrations/hooks/use-escapekey';
import { useModalState } from 'src/features/integrations/hooks/use-modal-state';
import styled from 'styled-components';
import FooterButtons from '../../../components/footer-buttons';
import FooterStatus from '../../../components/footer-status/footer-status';
import {
  Container,
  ModalContent,
  StyledHeaderImage,
  StyledSlideHeader,
} from '../../../components/styled-components';
import useModal from '../../../hooks/use-modal';
import type { FooterStatusState } from '../types';
import { validationLimit } from '../utils/validation';
import footerIcon from './assets/okta-small.svg';
import headerIcon from './assets/okta.svg';
import { oktaSlideMap } from './okta-slide-map';

const StyledText = styled.p`
  color: var(--b-primary-marengo-dark);
  margin-bottom: var(--b-gap2);
`;

const StyledTextInput = styled.div`
  width: 360px;
  text-align: left;
  margin-bottom: var(--b-gap);
  label {
    margin-bottom: var(--b-gap-tiny);
  }
`;

const OktaCredentials = () => {
  const [formValues, setFormValues] = useState({
    clientID: '',
    clientSecret: '',
  });
  const { state: modalState, setModalState } = useModalState();
  const [showBackBtn, setShowBackBtn] = useState(true);
  const [isWorking, setIsWorking] = useState(false);
  const [footerStatusState, setFooterStatusState] =
    useState<FooterStatusState>('');
  const { nextModal, previousModal } = useModal();
  const { invalidations, onInvalidate } = useInvalidations({ inputs: 2 });

  const onClose = () => nextModal(oktaSlideMap.cancelConfirm);

  useEscapeKey(onClose);

  const handleNext = async () => {
    const { oktaDomain: oktaUrl } = modalState;

    setShowBackBtn(false);
    setIsWorking(true);
    const token = Buffer.from(
      `${formValues.clientID}:${formValues.clientSecret}`,
    ).toString('base64');

    if (formValues.clientID && formValues.clientSecret) {
      setModalState({
        token,
        clientId: formValues.clientID,
        clientSecret: formValues.clientSecret,
      });
      setFooterStatusState('pending');
      oktaDeviceTrustService
        .verifyOkta(oktaUrl, token)
        .then(() => {
          setFooterStatusState('success');
          setIsWorking(false);
          nextModal(oktaSlideMap.addDevicePlatforms);
        })
        .catch((error) => {
          // if error is oie is not enabled or
          // fp not enabled, go to new modal
          const fastPassOff = error.response.data?.fastpass === 'not enabled';
          const oieOff =
            error.response.data?.oktaIdentityEngine === 'not enabled';

          if (fastPassOff || oieOff) {
            nextModal(
              oktaSlideMap.oktaCredentialsError({
                fastPassOff,
                oieOff,
              }),
            );
          }
          setFooterStatusState('failed');
          setIsWorking(false);
        });
    }
  };

  const handleChange = (key: string) => (e: ChangeEvent<HTMLInputElement>) => {
    e.persist();
    setFormValues({
      ...formValues,
      [key]: e.target.value,
    });
  };

  const handleOnBack = () => previousModal();

  return (
    <Container>
      <ModalContent>
        <StyledHeaderImage src={headerIcon} alt="header-icons" />
        <StyledSlideHeader>
          API Service Integration credentials
        </StyledSlideHeader>
        <StyledText>
          Enter the Client ID and Client Secret that was provided when you
          created the API Service Integration for Kandji in Okta.
        </StyledText>
        <StyledTextInput>
          <TextInput
            value={formValues.clientID}
            onChange={handleChange('clientID')}
            validator={(v) => {
              const v1 = onEmptyBlurValidator(v, { message: 'Required.' });
              const v2 = {
                message: `must be ${validationLimit.clientId} characters or fewer`,
                invalid: () => v.length > validationLimit.clientId,
              };
              return [v1, v2];
            }}
            label="Client ID"
            placeholder="Enter the Okta client ID"
            onInvalidate={onInvalidate(0)}
          />
        </StyledTextInput>
        <StyledTextInput>
          <TextInput
            value={formValues.clientSecret}
            onChange={handleChange('clientSecret')}
            validator={(v) => {
              const v1 = onEmptyBlurValidator(v, { message: 'Required.' });
              const v2 = {
                message: `must be ${validationLimit.clientSecret} characters or fewer`,
                invalid: () => v.length > validationLimit.clientSecret,
              };
              return [v1, v2];
            }}
            label="Client Secret"
            placeholder="Enter the Okta client secret"
            onInvalidate={onInvalidate(1)}
          />
        </StyledTextInput>
      </ModalContent>
      <FooterButtons
        onClose={onClose}
        handleNext={handleNext}
        backOnClick={handleOnBack}
        cancelButtonText="Cancel setup"
        nextButtonText={isWorking ? '' : 'Connect to Okta'}
        nextButtonDisabled={invalidations.some(Boolean)}
        showBackButton={showBackBtn}
        customComponent={
          footerStatusState ? (
            <FooterStatus
              icon={footerIcon}
              status={footerStatusState}
              options={{
                pending: {
                  title: 'Confirming the connection with Okta...',
                  statusText: 'In progress',
                },
                success: {
                  title: 'Okta is successfully connected.',
                  statusText: 'Completed',
                },
                failed: {
                  title: 'Connection to Okta failed.',
                  statusText: 'Failed',
                },
              }}
            />
          ) : undefined
        }
        isWorking={isWorking}
        stepperIdx={2}
        stepsAmount={6}
        showStepper
      />
    </Container>
  );
};

export default OktaCredentials;
