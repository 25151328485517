import { Body, Button, DialogV2 as Dialog, Flex } from '@kandji-inc/nectar-ui';

export function ManualEnrollmentLeaveDialog({
  isOpen,
  toggleDialog,
  handleLeave,
}) {
  return (
    <Dialog
      isOpen={isOpen}
      title="Leave this page?"
      onOpenChange={toggleDialog}
      css={{ width: 500 }}
      content={
        <Flex flow="column">
          <Body css={{ marginBottom: 20 }}>
            Changes you made will not be saved.
          </Body>
          <Flex gap="sm" justifyContent="end" css={{ paddingTop: '$4' }}>
            <Button
              compact
              variant="subtle"
              onClick={/* istanbul ignore next */ () => toggleDialog(false)}
            >
              Stay on page
            </Button>
            <Button variant="primary" compact onClick={handleLeave}>
              Leave
            </Button>
          </Flex>
        </Flex>
      }
    />
  );
}
