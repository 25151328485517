import React from 'react';
import Ticket from 'src/features/integrations/components/ticket';
import useEscapeKey from 'src/features/integrations/hooks/use-escapekey';
import styled from 'styled-components';
import FooterButtons from '../../../components/footer-buttons';
import {
  Container,
  ModalContent,
  StyledSlideHeader,
} from '../../../components/styled-components';
import useModal from '../../../hooks/use-modal';
import oktaIcon from '../assets/okta.svg';
import { oktaSlideMap } from './okta-slide-map';
import { oktaSignInTickets } from './tickets';

const TicketContainer = styled.div`
  text-align: left;
`;

const CompleteTasks = () => {
  const { nextModal, previousModal } = useModal();
  const onClose = () => nextModal(oktaSlideMap.cancelConfirm);
  const handleNext = () => {
    nextModal(oktaSlideMap.oktaCredentials);
  };
  const handleBack = () => previousModal();

  useEscapeKey(onClose);

  return (
    <Container>
      <ModalContent>
        <img src={oktaIcon} className="b-mb2" alt="header-icons" />
        <StyledSlideHeader>
          Complete the following tasks in Okta
        </StyledSlideHeader>
        <p className="b-txt-light">
          You will need to perform the following actions in your Okta portal
          before continuing this integration setup. Learn more about each Okta
          workflow below.
        </p>
        <TicketContainer>
          {oktaSignInTickets.map((ticket) => (
            <Ticket
              key={ticket.title}
              title={ticket.title}
              text={ticket.text}
              link={ticket.link}
              icon={ticket.icon}
            />
          ))}
        </TicketContainer>
      </ModalContent>

      <FooterButtons
        onClose={onClose}
        handleNext={handleNext}
        cancelButtonText="Cancel setup"
        backOnClick={handleBack}
        showBackButton
        showStepper
        stepperIdx={1}
        stepsAmount={6}
      />
    </Container>
  );
};

export default CompleteTasks;
