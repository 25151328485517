import { Button, Toaster as toaster } from '@kandji-inc/bumblebee';
import {
  ContentState,
  EditorState,
  SelectionState,
  convertToRaw,
} from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import React, { useEffect, useState } from 'react';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import './editor.css';

const TOOLBAR_OPTIONS = {
  options: ['inline', 'blockType', 'list', 'link'],
  inline: {
    options: ['bold', 'italic'],
  },
  blockType: {
    options: ['Normal', 'Blockquote', 'Code'],
    inDropdown: true,
  },
  list: {
    options: ['unordered', 'ordered'],
  },
};

const NoteEditor = (props) => {
  const { content, onCancel, onSave } = props;
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [charLen, setCharLen] = useState(0);
  const MAX_CHAR_LEN = 16000;

  const countChars = (editorState) => {
    const charsCount = editorState.getCurrentContent().getPlainText('').length;
    setCharLen(charsCount);
  };

  const moveSelectionToEnd = (editorState) => {
    const content = editorState.getCurrentContent();
    const blockMap = content.getBlockMap();

    const key = blockMap.last().getKey();
    const length = blockMap.last().getLength();

    return new SelectionState({
      anchorKey: key,
      anchorOffset: length,
      focusKey: key,
      focusOffset: length,
    });
  };

  const beforeSave = () => {
    const htmlContent = draftToHtml(
      convertToRaw(editorState.getCurrentContent()),
    );
    const plainText = editorState.getCurrentContent().getPlainText();
    if (!plainText.replace(/\s/g, '').length) {
      toaster('Note cannot be empty.');
      return;
    }
    if (charLen > MAX_CHAR_LEN) {
      toaster('Character limit is exceeded.');
      return;
    }
    onSave(htmlContent);
  };

  useEffect(() => {
    const contentBlock = htmlToDraft(content);
    const contentState = ContentState.createFromBlockArray(contentBlock);
    const editorState = EditorState.createWithContent(contentState);
    const selectionState = moveSelectionToEnd(editorState);
    setEditorState(EditorState.forceSelection(editorState, selectionState));
    countChars(editorState);
  }, []);

  return (
    <div className="b-flex-vg1">
      <Editor
        editorState={editorState}
        editorClassName="bl-note-editor__body"
        onEditorStateChange={(state) => {
          setEditorState(state);
          countChars(state);
        }}
        toolbar={TOOLBAR_OPTIONS}
      />
      <div className="b-flex-btw">
        <p className="b-txt">{`${charLen} / ${MAX_CHAR_LEN} characters`}</p>
        <div className="b-flex-vc b-flex-g1">
          <Button kind="outline" onClick={onCancel}>
            Cancel
          </Button>
          <Button disabled={charLen >= MAX_CHAR_LEN} onClick={beforeSave}>
            Save
          </Button>
        </div>
      </div>
    </div>
  );
};

export default NoteEditor;
