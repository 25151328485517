import {
  Button,
  DialogV2 as Dialog,
  Text,
  styled,
} from '@kandji-inc/nectar-ui';
import * as React from 'react';
import { api } from 'src/app/api/base';
import { URL_DEP_COMPUTER_CHANGE_BLUEPRINT } from 'src/app/api/urls';
import { useGetADEBlueprints } from '../hooks/useGetADEBlueprints';
import { ADEListViewStore } from '../store/ADEListViewStore';
import { addSearchQueryFilters } from '../utils/addSearchQueryFilters';
import { ADECellSelect } from './ADECellSelect/ADECellSelect';
import type { Blueprint } from './ADEListViewTableColumns';
import { EnrollmentStatusFilters } from './ADEStatusFilter';

const Label = styled('div', {
  fontWeight: 500,
  marginBottom: 4,
  color: '$neutral70',
});

const Description = styled(Text, {
  marginBottom: 14,
});

const DialogActions = styled('div', {
  display: 'flex',
  justifyContent: 'flex-end',
  gap: 4,
  padding: '16px 0',
  marginTop: 20,
});

export async function handleUpdateBlueprints(
  blueprint: Blueprint,
  handleClose: () => void,
) {
  const { devices, selectedDevices, selectAllDevices, updateBlueprint } =
    ADEListViewStore.getState();

  const devicesToUpdate = selectAllDevices
    ? devices.map(/*istanbul ignore next */ (device) => device.id)
    : selectedDevices;

  let bulkUpdateBody: Record<string, any> = {
    devices: selectedDevices,
    blueprint_id: blueprint.id,
  };

  if (selectAllDevices) {
    bulkUpdateBody = addSearchQueryFilters(
      bulkUpdateBody,
      false,
      EnrollmentStatusFilters.AWAITING,
    );
  }

  try {
    await api(URL_DEP_COMPUTER_CHANGE_BLUEPRINT).post(bulkUpdateBody);
    updateBlueprint(devicesToUpdate, blueprint);
    handleClose();
  } catch {}
}

export function ADEBlueprintDialog({ isOpen, toggle }) {
  const [blueprint, setBlueprint] = React.useState<Blueprint | null>(null);

  function handleClose() {
    setBlueprint(null);
    toggle();
  }

  return (
    <Dialog
      isOpen={isOpen}
      title="Assign Blueprint"
      onOpenChange={handleClose}
      content={
        <div>
          <Description>
            Selected devices will be assigned to this Blueprint when they
            enroll.
          </Description>
          <Label>Assign devices to Blueprint:</Label>
          <ADECellSelect
            isCompact={false}
            value={blueprint?.id}
            defaultValue="Select Blueprint"
            triggerValue={blueprint?.name ?? 'Select Blueprint'}
            triggerTitle={blueprint?.name ?? 'Select Blueprint'}
            ariaLabel={'Blueprint Select'}
            placeholder="Search Blueprints"
            onValueChange={
              /* istanbul ignore next */
              (newBlueprint) => {
                setBlueprint(newBlueprint);
              }
            }
            useGetData={useGetADEBlueprints}
            triggerIcon={null}
            searchable={false}
          />
          <DialogActions>
            <Button variant="subtle" onClick={handleClose}>
              Cancel
            </Button>
            <Button
              onClick={
                /* istanbul ignore next */ () =>
                  handleUpdateBlueprints(blueprint, handleClose)
              }
              variant="primary"
              disabled={blueprint == null}
            >
              Assign
            </Button>
          </DialogActions>
        </div>
      }
    />
  );
}
