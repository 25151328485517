import {
  Banner,
  Button,
  Dialog,
  Flex,
  Separator,
  Text,
  TextField,
  styled,
} from '@kandji-inc/nectar-ui';
import React, { useEffect, useState } from 'react';
import { pluralizeWord } from '../../helpers';

const List = styled('ul', {
  padding: '0 $5',
  marginBottom: 0,
});

export type DeleteDeviceModalProps = {
  isOpen: boolean;
  isMultiple: boolean;
  toggle: (boolean) => void;
  onConfirm: () => void;
};

const DeleteDeviceModal = (props: DeleteDeviceModalProps) => {
  const { isOpen, isMultiple, toggle, onConfirm } = props;
  const [confirmText, setConfirmText] = useState('');
  const EXPECTED_CONFIRM_TEXT = 'DELETE';
  const pluralCount = isMultiple ? 2 : 1;

  const content = (
    <Flex gap="md" flow="column">
      <Banner
        theme="warning"
        text={`Deleting the device ${pluralizeWord(
          'record',
          pluralCount,
        )} cannot be undone.`}
      />

      <Flex flow="column" gap="xl">
        <Text>
          All history will be lost, and the{' '}
          {pluralizeWord('device', pluralCount)} will need to be re-enrolled
          into Kandji for future use.
        </Text>
        <Text>
          All device secrets that may prevent anyone from using{' '}
          {isMultiple ? 'these' : 'this'} {pluralizeWord('device', pluralCount)}{' '}
          will be lost, including:
        </Text>
        <List>
          <li>
            <Text>Device lock PIN code</Text>
          </li>
          <li>
            <Text>Recovery password</Text>
          </li>
          <li>
            <Text>FileVault Recovery Key</Text>
          </li>
          <li>
            <Text>Activation Lock Bypass Code</Text>
          </li>
        </List>
      </Flex>

      <Separator css={{ width: '100% !important', background: '$neutral20' }} />

      <Text>
        Type <b>DELETE</b> to permanently delete the device{' '}
        {pluralizeWord('record', pluralCount)}.
      </Text>

      <TextField
        placeholder={EXPECTED_CONFIRM_TEXT}
        onChange={(e) => setConfirmText(e.target.value)}
        value={confirmText}
        hint={{
          icon: 'omit',
          label: `I understand that the selected device ${pluralizeWord(
            'record',
            pluralCount,
          )}, all related device record information, and all device secrets will be destroyed. This cannot be undone.`,
        }}
        data-testid="am-delete-device-confirm"
      />
    </Flex>
  );

  const footer = (
    <Flex gap="sm" justifyContent="end" wrap="wrap">
      <Button compact variant="subtle" onClick={() => toggle(false)}>
        Cancel
      </Button>

      <Button
        compact
        variant="danger"
        disabled={confirmText !== EXPECTED_CONFIRM_TEXT}
        onClick={() => {
          onConfirm();
          toggle(false);
        }}
        data-testid="delete-device-record-action"
      >
        Delete device {pluralizeWord('record', pluralCount)}
      </Button>
    </Flex>
  );

  useEffect(() => {
    setConfirmText('');
  }, [isOpen]);

  return (
    <Dialog
      title={`Delete device ${pluralizeWord('record', pluralCount)}`}
      content={content}
      footer={footer}
      isOpen={isOpen}
      onOpenChange={() => {
        toggle(false);
        setConfirmText('');
      }}
      css={{ width: '560px', zIndex: 2000 }}
    />
  );
};

export default DeleteDeviceModal;
