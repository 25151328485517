/* istanbul ignore file */

import { Loader } from '@kandji-inc/nectar-ui';
import useAccount, { usePermissions } from 'contexts/account';
import React, { useEffect } from 'react';
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useRouteMatch,
} from 'react-router-dom';

import BlueprintFlow from 'features/blueprint-flow';
import { UserRoles } from 'src/app/common/constants';

import { paths } from '../blueprints/common';
import { FLOW_PATHS } from './constants';
import useBlueprint from './services/use-blueprint';
import useFlowLibraryItems from './services/use-flow-library-item';

const BlueprintFlowRouter = () => {
  const history = useHistory();
  const { path } = useRouteMatch();
  const { canManageBlueprints } = usePermissions();
  const { userRole } = useAccount();
  const isUserHelpDesk = userRole === UserRoles.helpdesk;

  const { blueprint } = useBlueprint();
  useFlowLibraryItems();

  const blueprintFlowRouteTabs = Object.values(FLOW_PATHS)
    .map(({ path }) => path)
    .join('|');

  useEffect(() => {
    if (blueprint?.type === 'form') {
      history.replace(paths.blueprint(blueprint.id));
    }
  }, [blueprint]);

  if (!blueprint) {
    return (
      <Loader
        css={{
          height: `calc(100vh - var(--k-main-layout-side-padding))`,
          width: '100%',
        }}
        size="md"
      />
    );
  }

  return (
    <Switch>
      <Route
        path={`${path}/:tab(${FLOW_PATHS.parameters.path})/:isEditing(edit)`}
        component={BlueprintFlow}
      >
        {isUserHelpDesk && (
          <Redirect
            to={
              blueprint.id ? paths.flowBlueprint(blueprint.id) : '/blueprints'
            }
          />
        )}
      </Route>
      <Route
        exact
        path={`${path}/:tab(${blueprintFlowRouteTabs})`}
        component={BlueprintFlow}
      />
      {canManageBlueprints && (
        <Route exact path={path} component={BlueprintFlow} />
      )}
      <Redirect
        to={blueprint.id ? paths.flowBlueprint(blueprint.id) : '/blueprints'}
      />
    </Switch>
  );
};

export default BlueprintFlowRouter;
