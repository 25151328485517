import {
  Flex,
  type IconNames,
  Link,
  Tabs,
  Text,
  Tooltip,
  TooltipProvider,
  styled,
} from '@kandji-inc/nectar-ui';

import { i18n } from 'i18n';
import { useEffect, useRef, useState } from 'react';
import EDRCopyButton from 'src/features/edr/common/components/EDRCopyButton';
import { SeverityProgressChips } from '../../components/severity-progress-chips';
import { SeverityTooltip } from '../../components/severity-tooltip';
import { Timeline } from '../../components/timeline';
import type { TimelineItem } from '../../components/timeline/timeline';
import { translatedSeverity } from '../../constants';
import { displayAndTruncateList } from '../../helpers';
import { Severity, type Vulnerability } from '../../vulnerability.types';
import { ApplicationList } from './tabs/applications';
import { DeviceList } from './tabs/device-list';

const DetailRow = styled(Flex, {
  flexDirection: 'row',
});

const DetailKey = styled(Text, {
  color: '$neutral70',
  fontSize: '$1',
  width: '170px',
});

const DetailValue = styled(Text, {
  fontSize: '$1',
  fontWeight: '$medium',
});

const tabsContainerCss = {
  '& > div[data-nectar-styled-name="group"]': {
    position: 'sticky',
    top: 0,
    bg: '$neutral0',
    zIndex: 2,
    marginInline: -20,
    paddingInline: 20,
    width: 'calc(100% + 40px)',
    '& div[data-nectar-styled-name="list"]': {
      position: 'relative',
    },
    '& > div[data-nectar-styled-name="separator"]': {
      width: 'calc(100% - 40px)',
    },
  },
  flex: 1,
};

const Body = (props: { selectedVulnerability: Vulnerability }) => {
  const { selectedVulnerability } = props;
  const {
    application_count,
    cve_modified_at,
    cve_published_at,
    description,
    device_count,
    cvss_score,
    latest_detection_date,
    first_detection_date,
    severity,
  } = selectedVulnerability;

  const [isDescriptionOverflowing, setIsDescriptionOverflowing] =
    useState(false);
  const descriptionTextRef = useRef<HTMLDivElement>(null);

  const recommendations = [
    i18n.t('Install new application update when available from the developer'),
    i18n.t('Block the application using the Application Blocking Parameter'),
    i18n.t('Uninstall the application using a custom script'),
  ];

  const timelineItems: TimelineItem[] = [
    {
      label: i18n.t('Published on'),
      type: 'published',
      date: cve_published_at,
      theme: 'default',
      icon: 'file-plus',
    } as TimelineItem,
    {
      label: i18n.t('First detected'),
      type: 'first-detected',
      date: first_detection_date,
      theme: 'danger',
      icon: 'bell-exclamation',
    } as TimelineItem,
    ...(cve_modified_at
      ? [
          {
            label: i18n.t('Modified on'),
            type: 'modified',
            date: cve_modified_at,
            theme: 'default',
            icon: 'pen-field' as IconNames,
          } as TimelineItem,
        ]
      : []),
    {
      label: i18n.t('Last detected'),
      type: 'last-detected',
      date: latest_detection_date,
      theme: 'danger',
      icon: 'bell-exclamation',
    } as TimelineItem,
  ].sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime());

  useEffect(
    /* istanbul ignore next */ () => {
      if (descriptionTextRef.current) {
        setIsDescriptionOverflowing(
          descriptionTextRef.current.scrollWidth >
            descriptionTextRef.current.clientWidth,
        );
      }
    },
    [],
  );

  return (
    <Flex flow="column" gap="sm" pt2 flex={1} css={{ marginBottom: '80px' }}>
      {description && (
        <DetailRow>
          <DetailKey>{i18n.t('Description')}</DetailKey>
          <Flex flow="row">
            <DetailValue
              ref={descriptionTextRef}
              css={{
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                maxWidth: '490px',
              }}
            >
              {description}
            </DetailValue>

            {/* istanbul ignore next */}
            {isDescriptionOverflowing && (
              <TooltipProvider>
                <Tooltip
                  side="bottom"
                  theme="light"
                  align="end"
                  maxWidth="316px"
                  trigger="click"
                  interactive
                  content={
                    <Flex flow="column" gap="xs">
                      <DetailValue>{i18n.t('Description')}</DetailValue>
                      <Text>
                        {description}{' '}
                        <EDRCopyButton
                          value={description}
                          css={{
                            triggerCss: {
                              padding: '2px 0 0 2px',
                              display: 'inline-block',
                              height: 16,
                              width: 16,
                              svg: { width: 16, height: 16 },
                            },
                          }}
                        />
                      </Text>
                    </Flex>
                  }
                  css={{ zIndex: 100, padding: '$3 $4' }}
                >
                  <Link variant="primary">
                    <DetailValue>{i18n.t('see more')}</DetailValue>
                  </Link>
                </Tooltip>
              </TooltipProvider>
            )}
          </Flex>
        </DetailRow>
      )}

      <DetailRow>
        <DetailKey>
          {i18n.t('Severity')} | {i18n.t('CVSS score')}
        </DetailKey>
        <SeverityTooltip severity={severity}>
          <Flex flow="row">
            <SeverityProgressChips severity={severity} />
            <DetailValue>
              {severity !== Severity.undefined
                ? translatedSeverity[severity]()
                : ''}
              {severity !== Severity.undefined && cvss_score && ' | '}
              {i18n.formatNumber(parseFloat(cvss_score)) ?? ''}
              {severity === Severity.undefined && !cvss_score && (
                <span style={{ fontStyle: 'italic' }}>{i18n.t('Pending')}</span>
              )}
            </DetailValue>
          </Flex>
        </SeverityTooltip>
        <DetailValue>
          {severity === Severity.undefined && !cvss_score && (
            <span style={{ fontStyle: 'italic' }}>{i18n.t('Pending')}</span>
          )}
        </DetailValue>
      </DetailRow>

      <DetailRow>
        <DetailKey>{i18n.t('Recommendations')}</DetailKey>
        <Flex gap="xs">
          <DetailValue>{`${recommendations[0]}`}</DetailValue>
          <Tooltip
            side="bottom"
            theme="light"
            align="end"
            maxWidth="316px"
            trigger="click"
            interactive
            content={
              <ul style={{ paddingInlineStart: '14px', marginBlockEnd: 0 }}>
                {recommendations.slice(1).map((recommendation, index) => (
                  <li key={index}>{recommendation}</li>
                ))}
              </ul>
            }
            css={{ zIndex: 2, padding: '$3 $4' }}
          >
            <Link variant="primary">
              <DetailValue>{i18n.t('see more (2)')}</DetailValue>
            </Link>
          </Tooltip>
        </Flex>
      </DetailRow>

      <DetailRow>
        <Timeline
          items={timelineItems}
          showAge
          ageStartType="first-detected"
          ageEndType="last-detected"
        />
      </DetailRow>

      <Flex flex={1}>
        <Tabs.Container
          compact
          tabs={[
            {
              label: (
                <Text css={{ fontWeight: '$medium' }}>
                  {i18n.t('Devices')}{' '}
                  <span
                    style={{ fontWeight: '400' }}
                  >{`(${i18n.formatNumber(device_count)})`}</span>
                </Text>
              ),
              tabId: 'devices',
            },
            {
              label: (
                <Text css={{ fontWeight: '$medium' }}>
                  {i18n.t('Applications')}{' '}
                  <span
                    style={{ fontWeight: '400' }}
                  >{`(${i18n.formatNumber(application_count)})`}</span>
                </Text>
              ),
              tabId: 'applications',
            },
          ]}
          defaultTabId="devices"
          css={tabsContainerCss}
        >
          <Tabs.Content tabId="devices">
            <DeviceList selectedVulnerability={selectedVulnerability} />
          </Tabs.Content>
          <Tabs.Content tabId="applications">
            <ApplicationList selectedVulnerability={selectedVulnerability} />
          </Tabs.Content>
        </Tabs.Container>
      </Flex>
    </Flex>
  );
};

export { Body };
