/* istanbul ignore file */
import type { Node } from 'reactflow';
import { useReactFlow } from 'reactflow';

import { DESCENDANT_TYPES, NODE_TYPES } from '../constants';

function useAncestry() {
  const { getNodes, getNode, getEdges } = useReactFlow();

  const edges = getEdges();
  const nodes = getNodes();

  /**
   * Gets the unique edge and node descendants of a Conditional or Assignment node, stopping at (but still including) the first Router node.
   * @param id - the ID of a Conditional node
   * @returns a list of descendants in the format { id, descendantType }
   */
  const getUniqueDescendantsUpToRouter = (id: Node['id']) => {
    const allDescendants = getAllDescendantsUpToRouter(id);

    // The Router node gets returned as a descendent multiple times, so we need to filter out duplicates
    const uniqueDescendants = allDescendants.filter(
      (desc, index) =>
        index === allDescendants.findIndex((d) => d.id === desc.id),
    );

    return uniqueDescendants;
  };

  /**
   * Gets all the edge and node descendants of a Conditional or Assignment node (including duplicates),
   * stopping at (but still including) the first Router node.
   * @param id - the ID of a Conditional node
   * @returns a list of descendants in the format { id, descendantType }
   */
  const getAllDescendantsUpToRouter = (id: Node['id']) => {
    const node = getNode(id);

    // If the node is of type Assignment, find its child via the edges
    if (node?.type === NODE_TYPES.assignment) {
      const childEdge = edges.find((edge) => edge.source === node.id);
      if (childEdge) {
        const childNode = getNode(childEdge.target);

        // If the next descendant is a Router node, return the edge that connects the Assignment
        // node to the Router node and the Router node itself but not
        // the Router's descendants (we have reached the end of our mini tree)
        if (childNode?.type === NODE_TYPES.router) {
          return [
            { id: childEdge.id, descendantType: DESCENDANT_TYPES.edge },
            { id: childNode.id, descendantType: childNode.type },
          ];
        }

        // If the next descendant is not a Router node, rescursively find its descendants
        return [
          { id: childEdge.id, descendantType: DESCENDANT_TYPES.edge },
          { id: childNode.id, descendantType: childNode.type },
          ...getAllDescendantsUpToRouter(childEdge.target),
        ];
      }
    }

    // If the node is of type Conditional, find its children in the nodes (since it is not connected to its chidlren via edges)
    if (node?.type === NODE_TYPES.conditional) {
      const children = nodes.filter((n) => n.parentNode === node.id);
      return children.reduce(
        (descendants: string[], child: Node) => [
          ...descendants,
          { id: child.id, descendantType: child.type },
          ...getAllDescendantsUpToRouter(child.id),
        ],
        [],
      );
    }

    return [];
  };

  /**
   * Gets the unique edge and node descendants of a node throughout the entire map
   * @param id - the ID of a node
   * @returns a list of descendants in the format { id, descendantType }
   */
  const getUniqueDescendants = (id: Node['id']) => {
    const allDescendants = getAllDescendants(id);

    // Filter out duplicates
    const uniqueDescendants = allDescendants.filter(
      (desc, index) =>
        index === allDescendants.findIndex((d) => d.id === desc.id),
    );

    return uniqueDescendants;
  };

  /**
   * Gets all the edge and node descendants of a node (including duplicates) throughout the entire map
   * @param id - the ID of a node
   * @returns a list of descendants in the format { id, descendantType }
   */
  const getAllDescendants = (id: Node['id']) => {
    const node = getNode(id);

    // If the node is of type Assignment, Start, or Router, find its child via the edges
    if (
      [NODE_TYPES.assignment, NODE_TYPES.start, NODE_TYPES.router].includes(
        node?.type,
      )
    ) {
      const childEdge = edges.find((edge) => edge.source === node.id);
      if (childEdge) {
        const childNode = getNode(childEdge.target);

        // If the next descendant is the End node, return an empty array and stop recursion. Otherwise, rescursively find its descendants.
        if (childNode?.type === NODE_TYPES.end) {
          return [];
        }

        return [
          { id: childEdge.id, descendantType: DESCENDANT_TYPES.edge },
          { id: childNode.id, descendantType: childNode.type },
          ...getAllDescendants(childEdge.target),
        ];
      }
    }

    // If the node is of type Conditional, find its children in the nodes (since it is not connected to its chidlren via edges)
    if (node?.type === NODE_TYPES.conditional) {
      const children = nodes.filter((n) => n.parentNode === node.id);
      return children.reduce(
        (descendants: string[], child: Node) => [
          ...descendants,
          { id: child.id, descendantType: child.type },
          ...getAllDescendants(child.id),
        ],
        [],
      );
    }

    return [];
  };

  /**
   * Finds all the Conditional node descendants of a given node, stopping at the first Router node.
   * This is used to provide a user-friendly count of child nodes as Assignment nodes are meaningless to the user.
   * @param id - the ID of a Conditional node
   * @returns a list of all Conditional node descendant IDs
   */
  const getConditionalDescendantsUpToRouter = (id: Node['id']) =>
    getUniqueDescendantsUpToRouter(id)
      ?.filter(
        (descendant) =>
          descendant.descendantType === DESCENDANT_TYPES.conditional,
      )
      .map(({ id }) => id);

  return {
    getUniqueDescendantsUpToRouter,
    getConditionalDescendantsUpToRouter,
    getUniqueDescendants,
  };
}

export default useAncestry;
