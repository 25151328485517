import { Select } from '@kandji-inc/bumblebee';
import React, { useEffect } from 'react';

const methodOptions = [
  { label: 'Start screen saver', value: 0 },
  { label: 'Sleep display', value: 1 },
];

const cornerOptions = [
  { label: 'Bottom-right corner', value: 'wvous-br-corner' },
  { label: 'Bottom-left corner', value: 'wvous-bl-corner' },
  { label: 'Top-right corner', value: 'wvous-tr-corner' },
  { label: 'Top-left corner', value: 'wvous-tl-corner' },
];

const HotCorner = (props) => {
  const { param, update, isDisabled } = props;
  const { details } = param;

  useEffect(() => {
    if (!details) {
      update({
        details: {
          corner: cornerOptions[0].value,
          method: methodOptions[0].value,
        },
      });
    }
  }, [details]);

  return (
    <div className="b-flex-vg1" style={{ maxWidth: '65%' }}>
      <p className={`b-txt${isDisabled ? '-light' : ''}`}>Method</p>
      <Select
        disabled={isDisabled}
        options={methodOptions}
        value={
          methodOptions.find(({ value }) => value === details?.method) ||
          methodOptions[0]
        }
        onChange={({ value }) =>
          update({ details: { ...(details || {}), method: value } })
        }
      />
      <p className={`b-txt${isDisabled ? '-light' : ''}`}>
        If no hot corner is set, enable the
      </p>
      <Select
        disabled={isDisabled}
        options={cornerOptions}
        value={
          cornerOptions.find(({ value }) => value === details?.corner) ||
          cornerOptions[1]
        }
        onChange={({ value }) =>
          update({ details: { ...(details || {}), corner: value } })
        }
      />
    </div>
  );
};

export default HotCorner;
