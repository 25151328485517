import { Flex, Select } from '@kandji-inc/nectar-ui';
import { i18n } from 'i18n';
import debounce from 'lodash/debounce';
import React, { useState, useEffect } from 'react';
import { msTeamsService } from '../../../services/teams';

export interface TypeaheadOption {
  label: string;
  value: string;
}

type Channel = {
  id: string;
  name: string;
};

type Props = {
  selectedTeamId: string;
  integrationId: number;
  compact: boolean;
  debounceTime?: number;
  onChange: (args?: any) => Promise<any> | void;
};

const ChannelTypeaheadSelect = (props: Props) => {
  const {
    selectedTeamId,
    integrationId,
    compact,
    debounceTime = 500,
    onChange,
  } = props;

  const [options, setOptions] = useState<Array<TypeaheadOption>>([]);
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    dataReset();
  }, [selectedTeamId]);

  const dataReset = () => {
    setOptions([]);
    setErrorMessage('');
  };

  const handleChange = async (searchTerm: string) => {
    dataReset();

    msTeamsService
      .getChannels(integrationId, selectedTeamId, searchTerm)
      .then(({ data }) => {
        setOptions([
          ...data.value.map((channel: Channel) => ({
            label: channel.name,
            value: { id: channel.id, name: channel.name },
          })),
        ]);
      })
      .catch((data) => {
        const message =
          data?.response?.data?.error?.message ||
          (data?.message ? i18n.t('Failed to load response data.') : '');
        setErrorMessage(message);
      });
  };

  return (
    <Flex flow="column" gap="md">
      {compact ? (
        <label htmlFor="channel-select" className="b-txt">
          {i18n.t('Specify a channel')}
        </label>
      ) : (
        <div>
          <label htmlFor="channel-select" className="b-txt">
            {i18n.t('Channel')}{' '}
          </label>
          <p className="b-txt-light">{i18n.t('Specify a channel')}</p>
        </div>
      )}

      <Select
        data-testid="channel-typeahead-select"
        placeholder={i18n.t('Search or select from list')}
        value=""
        options={options}
        disabled={!selectedTeamId}
        onChange={(v) => onChange([v])}
        searchFn={debounce(handleChange, debounceTime)}
        searchable
        size={compact ? 'md' : 'lg'}
        aria-label={i18n.t('select channel')}
        error={errorMessage}
      />
    </Flex>
  );
};

export default ChannelTypeaheadSelect;
