import * as React from 'react';
import { api } from 'src/app/api/base';
import { URL_DEP_COMPUTER_CHANGE_BLUEPRINT } from 'src/app/api/urls';
import { useGetADEBlueprints } from '../hooks/useGetADEBlueprints';
import { ADEListViewStore } from '../store/ADEListViewStore';
import BlueprintIcon from './ADECellBlueprintIcon';
import { ADECellSelect } from './ADECellSelect/ADECellSelect';
import type { Blueprint } from './ADEListViewTableColumns';

export async function handleUpdateBlueprint(
  deviceIds: string[],
  newBlueprint: Blueprint,
  oldBlueprint: Blueprint,
) {
  const updateBlueprint = ADEListViewStore.getState().updateBlueprint;
  if (oldBlueprint.id === newBlueprint.id) return;
  try {
    updateBlueprint(deviceIds, newBlueprint);
    await api(URL_DEP_COMPUTER_CHANGE_BLUEPRINT).post({
      devices: deviceIds,
      blueprint_id: newBlueprint.id,
    });
  } catch {
    updateBlueprint(deviceIds, oldBlueprint);
  }
}

export const ADECellBlueprintSelect = React.memo(
  function ADECellBlueprintSelect({
    deviceId,
    blueprint,
  }: {
    deviceId: string;
    blueprint: Blueprint;
  }) {
    return (
      <ADECellSelect
        value={blueprint.id}
        defaultValue="Blueprint"
        triggerValue={blueprint.name}
        triggerTitle={blueprint.name}
        ariaLabel={'Blueprint Select'}
        placeholder="Search Blueprints"
        onValueChange={
          /* istanbul ignore next */
          (newBlueprint) =>
            handleUpdateBlueprint([deviceId], newBlueprint, blueprint)
        }
        useGetData={useGetADEBlueprints}
        triggerIcon={
          <BlueprintIcon
            iconSize={6}
            iconName={blueprint.icon}
            color={blueprint.color}
          />
        }
        buildItemIcon={
          /* istanbul ignore next */
          (blueprint) => (
            <BlueprintIcon
              iconSize={6}
              iconName={blueprint.icon}
              color={blueprint.color}
            />
          )
        }
      />
    );
  },
);
