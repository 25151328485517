import { useQuery } from '@tanstack/react-query';
import { msTeamsService } from '../services/teams';

const useMSTeamsChannels = (
  integrationId: number,
  teamId: string,
  term?: string,
) => {
  const query = useQuery({
    queryKey: ['msTeamsChannels', integrationId, teamId],
    queryFn: () => msTeamsService.getChannels(integrationId, teamId, term),
    enabled: !!teamId,
  });

  // We need to return 'isLoading' this way for this query. This page gives a little more context https://tanstack.com/query/v4/docs/guides/queries#fetchstatus - particularily this sentence "So keep in mind that a query can be in loading state without actually fetching data. "
  return {
    ...query,
    isLoading: query.isLoading && query.fetchStatus !== 'idle',
  };
};

export default useMSTeamsChannels;
