const update =
  (setModel) =>
  (setting) =>
  ({ key, value, batchKeyValues }) => {
    const isPlainObject = (v) =>
      v != null && Object.prototype.toString.call(v) === '[object Object]';

    if (batchKeyValues && isPlainObject(batchKeyValues)) {
      setModel((p) => ({
        ...p,
        [setting]: {
          ...p[setting],
          ...batchKeyValues,
        },
      }));
    } else {
      setModel((p) => ({
        ...p,
        [setting]: {
          ...p[setting],
          [key]: typeof value === 'function' ? value(p[setting][key]) : value,
        },
      }));
    }
  };

export default update;
