import React from 'react';
import styled from 'styled-components';

import progressFailed from './assets/progress-failed.svg';
import progressSuccess from './assets/progress-success.svg';
import progressSync from './assets/progress-sync.svg';

const StyledLeftFooter = styled.div`
  display: flex;
`;

const StyledLeftFooterContent = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: var(--b-gap1);
`;

const StyledLeftFooterContentText = styled.span`
  font-size: 12px;
  margin-left: var(--b-gap-tiny);
`;

const StatusContainer = styled.span`
  text-align: left;
`;

const StyledImg = styled.img`
  width: 32px;
`;

type Option = {
  title: string;
  statusText: string;
  statusIcon?: string;
};

export type FooterStatus = 'pending' | 'success' | 'failed';
export type FooterOptions = {
  pending?: Option;
  success?: Option;
  failed?: Option;
};

type Props = {
  icon: string;
  status: FooterStatus;
  options: FooterOptions;
};

function FooterStatus(props: Props) {
  const { icon, status, options: optionProps } = props;

  const options: FooterOptions = {
    pending: optionProps.pending && {
      ...optionProps.pending,
      statusIcon: optionProps.pending?.statusIcon ?? progressSync,
    },
    success: optionProps.success && {
      ...optionProps.success,
      statusIcon: optionProps.success?.statusIcon ?? progressSuccess,
    },
    failed: optionProps.failed && {
      ...optionProps?.failed,
      statusIcon: optionProps?.failed?.statusIcon ?? progressFailed,
    },
  };

  const { title, statusText, statusIcon } = options[status] ?? {};

  return (
    <StyledLeftFooter>
      <StyledImg src={icon} alt="status icon" />
      <StyledLeftFooterContent>
        <span className="b-txt-bold">{title}</span>
        <StatusContainer>
          <img
            className={`${status === 'pending' ? 'fa-spin' : ''}`}
            alt={`${status} status`}
            src={statusIcon}
          />
          <StyledLeftFooterContentText>
            {statusText}
          </StyledLeftFooterContentText>
        </StatusContainer>
      </StyledLeftFooterContent>
    </StyledLeftFooter>
  );
}

export default FooterStatus;
