import React from 'react';
import type { DeviceType, ODTIntegration } from '../../../types';
import DeleteDevicePlatform from '../delete-device-platform';
import IOSDevicePlatform from '../ios-device-platform';
import MacOsDevicePlatform from '../mac-os-device-platform';

interface CommonProps {
  handleCloseModal: () => void;
}

interface ModalContentProps extends CommonProps {
  data: ODTIntegration;
  isEditing?: boolean;
}

interface DeleteDeviceProps extends CommonProps {
  blueprints: string[] | undefined;
  oktaDeviceTrustId: ODTIntegration['id'];
  type: DeviceType;
}

type ModalProps = ModalContentProps | DeleteDeviceProps;

export type DeviceModalType = 'macOS' | 'iOS' | 'deleteDevice';

/**
 * Helper function that returns the proper modal based on provided modal type.
 * @param modal
 * @returns JSX.Element to be used with props passed in
 * @example getModalContent(devicePlatformModal)({ data, handleCloseModal })
 */

export const getModalContent = (modal: DeviceModalType) => {
  const modals = {
    macOS: (props: ModalContentProps) => (
      <MacOsDevicePlatform
        integrationData={props.data}
        onClose={props.handleCloseModal}
        isEditing={props.isEditing}
      />
    ),
    iOS: (props: ModalContentProps) => (
      <IOSDevicePlatform
        integrationData={props.data}
        onClose={props.handleCloseModal}
        isEditing={props.isEditing}
      />
    ),
    deleteDevice: (props: DeleteDeviceProps) => (
      <DeleteDevicePlatform
        oktaDeviceTrustId={props.oktaDeviceTrustId}
        onClose={props.handleCloseModal}
        blueprints={props.blueprints}
        type={props.type}
      />
    ),
  };

  return modals[modal] as (props: ModalProps) => JSX.Element;
};
