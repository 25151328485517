/* istanbul ignore file */

import React from 'react';

import { object } from 'prop-types';

import { i18n } from 'i18n';

import { Button, Icon } from '@kandji-inc/bumblebee';

import { useAuthority, useModal, usePanel } from '../../hooks';

import Menu from '../../menu';

import { i18nAdcsStatusMap } from '../../../../localization';
import { statusClass, statusLabel } from '../../status';

const Server = ({ server }) => {
  const { id, name, domain, status } = server;

  const { setAuthority } = useAuthority();

  const { openModal } = useModal();
  const { openPanel } = usePanel();

  /* istanbul ignore next */
  const openModalCreateLibraryItem = () => {
    setAuthority(id);
    openModal('create-library-item');
  };

  const openPanelEditServer = () => {
    setAuthority(id);
    openPanel('edit-server');
  };

  const openPanelDeleteServer = () => {
    setAuthority(id);
    openPanel('delete-server');
  };

  const menu = (
    <>
      <Button
        theme="action"
        kind="link"
        icon="pencil"
        onClick={openPanelEditServer}
      >
        {i18n.t('Edit server')}
      </Button>

      <Button
        theme="action"
        kind="link"
        icon="folder-plus"
        onClick={openModalCreateLibraryItem}
      >
        {i18n.t('Create Library Item')}
      </Button>

      <Button
        theme="error"
        kind="link"
        icon="trash-can"
        onClick={openPanelDeleteServer}
      >
        {i18n.t('Delete server')}
      </Button>
    </>
  );

  return (
    <div className="k-adcs-server">
      <div className="k-adcs-table-row k-table-row">
        <div className="k-adcs-table-column-toggle k-table-column" />

        <div className="k-adcs-table-column-buffer k-table-column" />

        <div className="k-adcs-table-column-server k-table-column">
          {name || '-'}
        </div>

        <div className="k-adcs-table-column-domain k-table-column">
          {domain || '-'}
        </div>

        <div className="k-adcs-table-column-status k-table-column">
          <Icon
            name="laptop-mobile"
            className={`dcs-integration-card_status_circle adcs-integration-card_status_circle_${statusClass(
              status,
            )}`}
          />
          <span>{i18nAdcsStatusMap(statusLabel(status))}</span>
        </div>

        <div className="k-adcs-table-column-action k-table-column">
          <Menu menu={menu} />
        </div>
      </div>
    </div>
  );
};

Server.propTypes = {
  server: object.isRequired,
};

export default Server;
