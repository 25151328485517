import {
  Button,
  Flex,
  IconButton,
  Tooltip,
  TooltipProvider,
  styled,
  theme,
} from '@kandji-inc/nectar-ui';

export const PROFILE_SUPPORT_ARTICLE =
  'https://support.kandji.io/support/solutions/articles/72000559781-device-enrollment-profile-status';

const ProfileHeaderIconButton = styled(IconButton, {
  width: '14px',
  height: '14px',
  padding: 1,
  color: theme.colors.button_subtle_icon,
  '&:hover': {
    background: theme.colors.button_subtle_background_hover,
  },
  '&[data-state="open"], &[data-state="delayed-open"]': {
    background: theme.colors.button_subtle_background_hover,
  },
});

const ProfileTooltipContainer = styled('div', {
  fontSize: 14,
  position: 'relative',
  width: 246,
  display: 'flex',
  gap: 4,
  alignItems: 'flex-start',
});

export function ProfileTooltipContent({ onClose }): React.ReactNode {
  return (
    <ProfileTooltipContainer>
      <Flex flow="column" alignItems="start">
        Status of the ADE profile assignment with Apple for each device.
        <Button
          variant="link"
          onClick={() => {
            window.open(PROFILE_SUPPORT_ARTICLE, '_blank');
          }}
          style={{ fontSize: 14 }}
        >
          Learn more
        </Button>
      </Flex>
      <Button
        icon={{ name: 'fa-xmark-small' }}
        compact
        css={{
          backgroundColor: 'transparent',
          padding: 0,
        }}
        onClick={onClose}
      />
    </ProfileTooltipContainer>
  );
}

const ProfileColumnHeader = styled('div', {
  display: 'flex',
  alignItems: 'center',
  gap: 2,
});

export function ADETableProfileHeader() {
  return (
    <ProfileColumnHeader>
      Profile
      <TooltipProvider>
        <Tooltip
          side="top"
          // @ts-expect-error TODO typing is wrong
          content={(onClose) => <ProfileTooltipContent onClose={onClose} />}
          shadow="shadow"
          theme="light"
          css={{
            borderRadius: 4,
            zIndex: 5,
            padding: '12px 16px',
            color: '$neutral90',
          }}
          maxWidth="320px"
          align="center"
          interactive
        >
          <ProfileHeaderIconButton icon="circle-info" />
        </Tooltip>
      </TooltipProvider>
    </ProfileColumnHeader>
  );
}
