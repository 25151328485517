import errorIcon from 'features/integrations/components-for-specific-types/okta/modals/assets/error.svg';
import oktaFastpass from 'features/integrations/components-for-specific-types/okta/modals/assets/okta-fastpass.svg';
import oktaIdentityEngine from 'features/integrations/components-for-specific-types/okta/modals/assets/okta-identity-engine.svg';
import Ticket from 'features/integrations/components/ticket';
import React from 'react';
import useEscapeKey from 'src/features/integrations/hooks/use-escapekey';
import styled from 'styled-components';
import FooterButtons from '../../../components/footer-buttons';
import FooterStatus from '../../../components/footer-status/footer-status';
import {
  Container,
  ModalContent,
  StyledHeaderImage,
  StyledSlideHeader,
} from '../../../components/styled-components';
import useModal from '../../../hooks/use-modal';
import type { ErrorProps } from '../types';
import footerIcon from './assets/okta-small.svg';
import { oktaSlideMap } from './okta-slide-map';

const StyledText = styled.p`
  color: var(--b-primary-marengo-dark);
  margin-bottom: var(--b-gap2);
`;

const TicketContainer = styled.div`
  text-align: left;
`;

/* istanbul ignore next */
const OktaCredentialsError = (props: ErrorProps) => {
  const { fastPassOff, oieOff } = props;
  const { nextModal, previousModal } = useModal();

  const onClose = () => {
    nextModal(oktaSlideMap.oktaCredentials);
  };

  useEscapeKey(onClose);

  const handleNext = async () => {
    nextModal(oktaSlideMap.cancelConfirm);
  };

  const handleOnBack = () => previousModal();

  return (
    <Container>
      <ModalContent>
        <StyledHeaderImage src={errorIcon} alt="error-icon" />
        <StyledSlideHeader>Cannot complete integration setup</StyledSlideHeader>
        <StyledText>
          It looks like your Okta account does not have the correct settings to
          finalize the Okta Device Trust integration with Kandji. Please
          reference the following support articles below.
        </StyledText>
        <TicketContainer>
          {oieOff && (
            <Ticket
              key="Okta Identity Engine must be on"
              icon={oktaIdentityEngine}
              text="Sign into the Okta admin console and turn on Okta Identity Engine
              to complete this integration"
              title="Okta Identity Engine must be on"
              link="https://help.okta.com/oie/en-us/Content/Topics/identity-engine/oie-index.htm"
            />
          )}
          {fastPassOff && (
            <Ticket
              key="FastPass must be enabled"
              icon={oktaFastpass}
              text="Enable FastPass for your Okta account"
              title="FastPass must be enabled"
              link="https://help.okta.com/oie/en-us/Content/Topics/identity-engine/devices/fp/fp-main.htm"
            />
          )}
        </TicketContainer>
      </ModalContent>
      <FooterButtons
        onClose={handleOnBack}
        handleNext={handleNext}
        backOnClick={handleOnBack}
        cancelButtonText="Retry"
        nextButtonText="Exit setup"
        showBackButton
        customComponent={
          <FooterStatus
            icon={footerIcon}
            status="failed"
            options={{
              pending: {
                title: 'Confirming the connection with Okta...',
                statusText: 'In progress',
              },
              success: {
                title: 'Okta is successfully connected.',
                statusText: 'Completed',
              },
              failed: {
                title: 'Connection to Okta failed.',
                statusText: 'Failed',
              },
            }}
          />
        }
        isWorking={false}
        stepperIdx={2}
        stepsAmount={6}
        showStepper
      />
    </Container>
  );
};

export default OktaCredentialsError;
