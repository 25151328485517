import debounce from 'lodash/debounce';
import React, { useEffect } from 'react';
import BlueprintParameterItem from './blueprint-parameter-item';
import './blueprint-parameters-list.css';

const BlueprintParameterSubCategoryList = (props) => {
  const {
    blueprint,
    subcategories,
    isEditing,
    onToggle,
    update,
    filter,
    validations,
  } = props;

  return subcategories.map((subcategory) => {
    const subCatId = `${subcategory.name}-${subcategory.id}`;

    return (
      <div id={subCatId} key={subCatId} className="b-flex-col">
        <h3
          className="b-h3 b-mb2 bl-blueprint-parameters-subcategory-header"
          data-testid="bl-blueprint-parameters-subcategory-header"
        >
          {subcategory.name}
        </h3>
        <div className="bl-blueprint-parameters-subcategory">
          {subcategory.parameters.map((param) => {
            const dependencyParam = blueprint.parameterList.find(
              ({ id }) => param.dependency?.id === id,
            );
            const isDependencyEnabled =
              !param.dependency ||
              (dependencyParam && blueprint.params[dependencyParam.id]);

            return (
              <BlueprintParameterItem
                key={param.id}
                blueprint={blueprint}
                parameter={param}
                selectedParameter={blueprint.params[param.id]}
                onToggle={(isEnabled, isMuted) =>
                  onToggle(param, isEnabled, isMuted)
                }
                filter={filter}
                canToggle={isDependencyEnabled && isEditing}
                isEnabled={!!blueprint.params[param.id]}
                isMuted={blueprint.params[param.id]?.mute}
                update={update(param.id)}
                validations={validations}
              />
            );
          })}
        </div>
      </div>
    );
  });
};

const BlueprintParametersList = (props) => {
  const {
    blueprint,
    isEditing,
    onToggle,
    update,
    filter,
    onActiveCategory,
    validations,
  } = props;

  function isPartiallyVisibleInViewport(element, viewport) {
    const bound = element.getBoundingClientRect();
    const bound2 = viewport.getBoundingClientRect();

    return bound.bottom - 80 > bound2.top && bound.top + 80 < bound2.bottom;
  }

  useEffect(() => {
    const scrollCheck = () => {
      for (const { id, subcategories } of blueprint.parameters) {
        for (const { id: subCatId, name: subCatName } of subcategories) {
          const subCategory = document.querySelector(
            `[id='${subCatName}-${subCatId}']`,
          );
          if (subCategory) {
            if (
              isPartiallyVisibleInViewport(
                subCategory,
                document.querySelector('.bl-blueprint-page__main'),
              )
            ) {
              onActiveCategory({
                category: id,
                subCategory: subCatId,
              });
              return;
            }
          }
        }
      }
    };
    const debounced = debounce(scrollCheck, 100);
    document
      .querySelector('.bl-blueprint-page__main')
      ?.addEventListener('scroll', debounced);

    scrollCheck();
    return () =>
      document
        .querySelector('.bl-blueprint-page__main')
        ?.removeEventListener('scroll', debounced);
  }, [blueprint.parameters]);

  return (
    <div
      className="bl-blueprint-parameters-list"
      data-testid="bl-blueprint-parameters-list"
    >
      {blueprint.parameters.map(({ subcategories, name, id }) => {
        const rootCatId = `${name}-${id}`;

        return (
          <div id={rootCatId} key={rootCatId} className="b-flex-col">
            <h2 className="b-h2 b-mb3 ">{name}</h2>
            <div className="bl-blueprint-parameters-rootcategory">
              <BlueprintParameterSubCategoryList
                filter={filter}
                blueprint={blueprint}
                subcategories={subcategories}
                isEditing={isEditing}
                onToggle={onToggle}
                update={update}
                validations={validations}
              />
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default BlueprintParametersList;
