import { Checkbox } from '@kandji-inc/bumblebee';
import React from 'react';
import { components } from 'react-select';
import styled from 'styled-components';

const CheckboxLabelContainer = styled.div`
  display: grid;
  grid-template-columns: ${(props) => (props.isMulti ? 'auto 1fr' : 'auto')};
  gap: var(--b-gap1);
`;
/* istanbul ignore next */
const Option = ({ children, data, isSelected, isMulti, ...props }) => {
  const { name } = data;
  return (
    <components.Option
      {...props}
      className={`cmp-select_option${
        isSelected && !isMulti ? '--selected' : ''
      }`}
    >
      <CheckboxLabelContainer isMulti={isMulti}>
        {isMulti && <Checkbox checked={isSelected} />}
        <span className="b-txt">{name}</span>
      </CheckboxLabelContainer>
    </components.Option>
  );
};

export default Option;
