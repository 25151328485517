/* istanbul ignore file - legacy code, moved */
import cross from 'assets/img/library/tagsCross.svg';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import React from 'react';
import {
  BlueprintInputTitle,
  Button,
  Cross,
  InputGrid,
  Tag,
  Tags,
} from './SCEPAssignment.styles';

export const SCEPAssignmentBlueprintsInput = ({
  refInput,
  isEditable,
  listOpen,
  setListOpen,
  allBlueprintNames,
  selectedClassicBlueprints,
  selectedAssignmentMaps,
  setSelectedBlueprints,
  hasConflictingBlueprints,
}) => {
  const handleClick = (e) => {
    const elements = Array.from(document.getElementsByClassName('cross'));
    if (isEditable && (isEmpty(elements) || !elements.includes(e.target))) {
      setListOpen(!listOpen);
    }
  };

  return (
    <InputGrid
      ref={refInput}
      error={hasConflictingBlueprints}
      opened={listOpen}
      onClick={handleClick}
    >
      <Tags>
        {isEmpty(selectedClassicBlueprints) && (
          <Tag theme="secondary">Not Assigned</Tag>
        )}
        {selectedClassicBlueprints.map((blueprint) => (
          <Tag theme="basic">
            <BlueprintInputTitle title={allBlueprintNames[blueprint]}>
              {allBlueprintNames[blueprint]}
            </BlueprintInputTitle>
            <Cross
              className="cross"
              src={cross}
              onClick={() => {
                if (isEditable) {
                  setSelectedBlueprints([
                    ...selectedClassicBlueprints.filter(
                      (item) => item !== blueprint,
                    ),
                    ...selectedAssignmentMaps,
                  ]);
                }
              }}
            />
          </Tag>
        ))}
      </Tags>
      <Button>
        {listOpen ? (
          <i
            className="fas fa-sort-up dd-icon"
            style={{ marginTop: '12px' }}
            onClick={() => isEditable && setListOpen(false)}
          />
        ) : (
          <i
            className="fas fa-sort-down dd-icon"
            onClick={() => isEditable && setListOpen(true)}
          />
        )}
      </Button>
    </InputGrid>
  );
};

SCEPAssignmentBlueprintsInput.propTypes = {
  refInput: PropTypes.objectOf(PropTypes.any),
  isEditable: PropTypes.bool.isRequired,
  listOpen: PropTypes.bool.isRequired,
  setListOpen: PropTypes.func.isRequired,
  allBlueprintNames: PropTypes.objectOf(PropTypes.any).isRequired,
  selectedClassicBlueprints: PropTypes.arrayOf(PropTypes.any).isRequired,
  selectedAssignmentMaps: PropTypes.arrayOf(PropTypes.string),
  setSelectedBlueprints: PropTypes.func.isRequired,
  hasConflictingBlueprints: PropTypes.bool.isRequired,
};

SCEPAssignmentBlueprintsInput.defaultProps = {
  refInput: null,
  selectedAssignmentMaps: [],
};
