/**
 * @description Filters and returns a list of acceptable device families for Okta
 * @param {string[]} configuredFamily
 * @return {*}  {((string | null | undefined)[])}
 */
export function buildDeviceFamilyList(
  configuredFamily: string[],
): (string | null | undefined)[] {
  type Map = {
    [key: string]: string;
  };
  const OKTA_DEVICE_FAMILY_MAP: Map = {
    mac: 'Mac',
    iphone: 'iPhone',
    ipad: 'iPad',
  };

  const oktaDeviceFamilyKeys = Object.keys(OKTA_DEVICE_FAMILY_MAP);

  const filteredDevices = configuredFamily
    .map((device) => {
      if (oktaDeviceFamilyKeys.includes(device)) {
        return OKTA_DEVICE_FAMILY_MAP[device];
      }
      return null;
    })
    .filter((item) => item !== null);

  return filteredDevices;
}
