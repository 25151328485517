import { Banner } from '@kandji-inc/bumblebee';
import debounce from 'lodash/debounce';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { setBlueprintMessage } from 'src/app/_actions/blueprint';
import { useGetFacets } from 'src/features/rules/use-rule-service';
import BlueprintLibraryItem from './blueprint-library-item';
import './blueprint-library-list.css';

const BlueprintSubCategoryList = (props) => {
  const { data, isEditing, onToggle, blueprint, facetMap } = props;

  return data.map(({ category, defaultConfiguration, data }) => (
    <div id={`${category}-${category}`} key={category} className="b-flex-col">
      <h3 className="b-h3 b-mb2 bl-blueprint-library-subcategory-header">
        {category}
      </h3>
      <div className="bl-blueprint-library-subcategory">
        {!data.length && (
          <div
            id={category}
            className="bl-blueprint-library__empty-category b-flex-vgmicro"
          >
            <h3 className="b-h3">{`There are no ${category} Library Items configured in your library`}</h3>
            <p className="b-txt">
              <Link
                className="b-decorate-off b-alink b-mr-micro"
                to={`/library/add?focus=${defaultConfiguration?.name}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                Click here
              </Link>
              to configure
            </p>
          </div>
        )}
        {data.map((item) => {
          const itemInBlueprint = blueprint.library_items.find(
            ({ id }) => id === item.id,
          );
          return (
            <BlueprintLibraryItem
              key={item.id}
              item={item}
              onToggle={(isEnabled) => onToggle(item, isEnabled)}
              canToggle={isEditing}
              isEnabled={!!itemInBlueprint}
              facetMap={facetMap}
            />
          );
        })}
      </div>
    </div>
  ));
};

const BlueprintLibraryList = (props) => {
  const {
    blueprint,
    blueprintMessages,
    beforeEditBlueprint,
    isEditing,
    onToggle,
    onActiveCategory,
  } = props;
  const dispatch = useDispatch();
  function isPartiallyVisibleInViewport(element, viewport) {
    /* istanbul ignore if */
    if (element && viewport) {
      var bound = element.getBoundingClientRect();
      var bound2 = viewport.getBoundingClientRect();
      return bound.bottom - 80 > bound2.top && bound.top + 80 < bound2.bottom;
    }
    return false;
  }

  const facetMap = useGetFacets();

  const bannerMessageInfo = blueprintMessages?.[blueprint.id]?.info;
  const bannerMessageWarning = blueprintMessages?.[blueprint.id]?.warning;

  useEffect(() => {
    // Permanently remove blueprint message on unmount
    return () => {
      dispatch(setBlueprintMessage(blueprint.id, null));
    };
  }, []);

  useEffect(() => {
    const scrollCheck = () => {
      for (const { category, data } of blueprint.categorical) {
        for (const { category: subCategory, data: subData } of data) {
          const subCatEl = document.querySelector(`[id='${subCategory}']`);
          if (subCatEl && !subData.length) {
            if (
              isPartiallyVisibleInViewport(
                subCatEl,
                document.querySelector('.bl-blueprint-page__main'),
              )
            ) {
              onActiveCategory({
                category,
                subCategory,
              });
              return;
            }
          }
          for (const { id } of subData) {
            const item = document.querySelector(`[id='${id}']`);
            if (item) {
              if (
                isPartiallyVisibleInViewport(
                  item,
                  document.querySelector('.bl-blueprint-page__main'),
                )
              ) {
                onActiveCategory({
                  category,
                  subCategory,
                });
                return;
              }
            }
          }
        }
      }
    };
    const debounced = debounce(scrollCheck, 100);
    document
      .querySelector('.bl-blueprint-page__main')
      ?.addEventListener('scroll', debounced);

    scrollCheck();
    return () =>
      document
        .querySelector('.bl-blueprint-page__main')
        ?.removeEventListener('scroll', debounced);
  }, [blueprint.categorical]);

  return (
    <>
      {(bannerMessageWarning || bannerMessageInfo) && ( // Only render if a message exists
        <div className="b-mb3">
          {bannerMessageWarning && (
            <div className="b-mt1 bl-blueprint-library-message-banner">
              <Banner icon="triangle-exclamation" kind="slim" theme="warning">
                <p>{bannerMessageWarning}</p>
              </Banner>
            </div>
          )}
          {bannerMessageInfo && (
            <div className="b-mt1 bl-blueprint-library-message-banner">
              <Banner icon="circle-info" kind="slim" theme="info-strong">
                <p>{bannerMessageInfo}</p>
              </Banner>
            </div>
          )}
        </div>
      )}
      <div className="bl-blueprint-library-list">
        {blueprint.categorical.map(({ category, data }) => {
          // hide category nav name if it has no items due to
          // hidden config/feature flag
          if (!data.length) {
            return null;
          }

          return (
            <div
              id={`${category}-${category}`}
              key={category}
              className="b-flex-col"
            >
              <h2 className="b-h2 b-mb3 ">{category}</h2>
              <div className="bl-blueprint-library-rootcategory">
                <BlueprintSubCategoryList
                  beforeEditBlueprint={beforeEditBlueprint}
                  blueprint={blueprint}
                  data={data}
                  isEditing={isEditing}
                  onToggle={onToggle}
                  facetMap={facetMap}
                />
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
};

export default BlueprintLibraryList;
