import { get } from 'lodash';
import React, { useContext } from 'react';

import { AccountContext } from 'contexts/account';

import EnterpriseAPI from '../../../../../features/enterprise-api';
import SingleSignOn from '../../../../../features/single-sign-on';
// import Team from './Team';
import CompanyUsersTable from './CompanyUsersTable';

const AccessTab = (match) => {
  const { currentCompany } = useContext(AccountContext);

  const isEnabledEnterpriseAPI = get(currentCompany.feature_configuration, [
    'enterprise_api',
    'enabled',
  ]);
  const isEnabledSingleSignOn = get(currentCompany.feature_configuration, [
    'sso',
    'enabled',
  ]);

  return (
    <>
      <section
        id="integration"
        className="d-flex flex-column company-integration-container"
        style={{ marginBottom: '25px' }}
      >
        <CompanyUsersTable match={match} />
      </section>
      {isEnabledSingleSignOn && <SingleSignOn />}
      {isEnabledEnterpriseAPI && <EnterpriseAPI />}
    </>
  );
};

export default AccessTab;
