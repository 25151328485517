import React from 'react';

import ErrorBanner from './ErrorBanner';
import ProgressBar from './ProgressBar';
import RedownloadButton from './RedownloadButton';
import SuccessBanner from './SuccessBanner';

const FooterButtonsLeft = (props) => {
  const {
    showDownloadError,
    showDownloadProgress,
    downloadProgress,
    currentStepIdx,
    setCurrentStepIdx,
  } = props;
  const showProgress = currentStepIdx === 1 && showDownloadProgress;
  const showError = currentStepIdx === 1 && showDownloadError;
  const showRedownloadButton = currentStepIdx === 2;

  const handleRedownloadButtonClick = () =>
    setCurrentStepIdx((curr) => curr - 1);

  return (
    <div className="b-grid-ctas">
      {downloadProgress > 0 && downloadProgress < 100 && (
        <ProgressBar downloadProgress={downloadProgress} />
      )}
      {downloadProgress === 100 && <SuccessBanner />}
      {showError && <ErrorBanner />}
      {showRedownloadButton && (
        <RedownloadButton onClick={handleRedownloadButtonClick} />
      )}
    </div>
  );
};

export default FooterButtonsLeft;
