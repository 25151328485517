import {
  Banner,
  Button,
  ChipSelect,
  Icon,
  PreLoadImages,
  setClass,
  useChipSelect,
} from '@kandji-inc/bumblebee';
import React, { useState } from 'react';
import './preview-section.css';

import { Setting } from 'features/library-items/template';

import bgDark from '../assets/bg-dark-monterey.png';
import bg from '../assets/bg-light-monterey.png';
import IpadDarkPreview from '../assets/ipad-dark.png';
import IpadLightPreview from '../assets/ipad-light.png';
import IphoneDarkPreview from '../assets/iphone-dark.png';
import IphoneLightPreview from '../assets/iphone-light.png';
import kandjiLogo from '../assets/kandji-icon-logo-preview.png';
import MacDarkPreview from '../assets/mac-dark.png';
import MacLightPreview from '../assets/mac-light.png';
import { BRANDING_MAX_CHAR_LIMIT } from '../side-panel/constants';

const assets = [
  MacLightPreview,
  MacDarkPreview,
  IpadLightPreview,
  IpadDarkPreview,
  IphoneLightPreview,
  IphoneDarkPreview,
  bg,
  bgDark,
  kandjiLogo,
];
const modeToClassName = (m) => `--${m.toLowerCase(m).replace(/\s+/g, '-')}`;
const viewToClassName = (v) => `--${v.toLowerCase(v).replace(/\s+/g, '-')}`;

const modeTypes = {
  LIGHT: { value: 'Light Mode' },
  DARK: { value: 'Dark Mode' },
};

const viewTypes = {
  IPHONE: {
    value: 'iPhone',
    icon: 'mobile-button',
    [modeTypes.LIGHT.value]: IphoneLightPreview,
    [modeTypes.DARK.value]: IphoneDarkPreview,
  },
  IPAD: {
    value: 'iPad',
    icon: 'tablet',
    [modeTypes.LIGHT.value]: IpadLightPreview,
    [modeTypes.DARK.value]: IpadDarkPreview,
  },
  MAC: {
    value: 'mac',
    icon: 'desktop',
    [modeTypes.LIGHT.value]: MacLightPreview,
    [modeTypes.DARK.value]: MacDarkPreview,
  },
};

const getOverCharLimitBannerText = (isHeaderOver, isSubheaderOver) => {
  if (isHeaderOver && isSubheaderOver) {
    return 'header and subheader';
  }
  if (isHeaderOver) {
    return 'header';
  }
  if (isSubheaderOver) {
    return 'subheader';
  }
  return '';
};

const PreviewSection = (props) => {
  const { onCustomize, model } = props;
  const [mode, setMode] = useState(modeTypes.LIGHT.value);
  const [view, setView] = useState(viewTypes.MAC.value);
  const modes = Object.values(modeTypes).map(({ value }) => ({ value }));
  const views = Object.values(viewTypes).map(({ value, icon }) => ({
    value,
    label: <Icon name={icon} />,
  }));

  const { selectOneOrAll: modeSelectOneOrAll, selected: modeSelected } =
    useChipSelect({
      modes,
      defaultSelected: [mode],
      callback: ({ value }) => setMode(value),
    });

  const { selectOneOrAll: viewSelectOneOrAll, selected: viewSelected } =
    useChipSelect({
      views,
      defaultSelected: [view],
      callback: ({ value }) => setView(value),
    });

  const getPreviewImage = (mode, view) => {
    return viewTypes[view.toUpperCase()][mode];
  };

  const isHeaderOverCharLimit =
    model.branding.header?.length > BRANDING_MAX_CHAR_LIMIT.HEADER;
  const isSubheaderOverCharLimit =
    model.branding.subheader?.length > BRANDING_MAX_CHAR_LIMIT.SUBHEADER;
  const hasOverCharLimitText =
    isHeaderOverCharLimit || isSubheaderOverCharLimit;

  const defaultLogoLight = model.branding.defaultLogoFile.dataUrl;
  const defaultLogoDark = model.branding.defaultLogoDarkFile.dataUrl;
  const lightLogo = model.branding.logoUrl || model.branding.logoFile?.dataUrl;
  const darkLogo =
    model.branding.darkLogoUrl || model.branding.darkLogoFile?.dataUrl;
  const isLight = mode === modeTypes.LIGHT.value;
  return (
    <Setting.Card>
      <Setting.Header>
        <h3 className="b-h3 k-self-service__heading">Branding</h3>
      </Setting.Header>
      <Setting.SubHeader>
        <p className="b-txt">
          Preview and customize the Self Service app store with your
          organization’s logo, personalized title and description below.
        </p>
      </Setting.SubHeader>
      <div className="k-self-service__branding">
        <div className="b-flex-btw b-flex-vc b-mb2">
          <Button onClick={onCustomize}>Customize</Button>
          <div className="b-flex-g2">
            <ChipSelect
              selected={modeSelected}
              setSelected={modeSelectOneOrAll}
              options={modes}
            />
            <ChipSelect
              selected={viewSelected}
              setSelected={viewSelectOneOrAll}
              options={views}
              classes={{
                chip: 'k-self-service-preview-view-chip',
                selected: 'k-self-service-preview-view-chip__selected',
              }}
            />
          </div>
        </div>
        {hasOverCharLimitText ? (
          <Banner
            theme="error"
            icon="exclamation-circle"
            kind="block"
            className="b-mb2"
          >
            <p>
              Review branding customization options. The{' '}
              {getOverCharLimitBannerText(
                isHeaderOverCharLimit,
                isSubheaderOverCharLimit,
              )}{' '}
              text exceeds the character limit.
            </p>
          </Banner>
        ) : null}
        {/* <div className="b-flex-hc"> */}
        <div
          className={setClass(
            'k-self-service__branding-preview',
            modeToClassName(mode),
            viewToClassName(view),
          )}
        >
          <div className="k-self-service__preview-inner">
            <img
              draggable="false"
              className={setClass(modeToClassName(mode), viewToClassName(view))}
              src={getPreviewImage(mode, view)}
              alt=""
            />
            <div
              className={setClass(
                'k-self-service__preview-content',
                modeToClassName(mode),
                viewToClassName(view),
              )}
            >
              <div
                className={setClass(
                  'k-self-service__preview-logo',
                  viewToClassName(view),
                )}
              >
                <img
                  draggable="false"
                  src={
                    isLight
                      ? lightLogo
                        ? lightLogo
                        : defaultLogoLight
                      : darkLogo || lightLogo
                        ? darkLogo || lightLogo
                        : defaultLogoDark
                  }
                  alt=""
                />
              </div>
              <div
                className={setClass(
                  'k-self-service__preview-details',
                  viewToClassName(view),
                )}
              >
                <h3
                  className={setClass(
                    'k-self-service__preview-header',
                    modeToClassName(mode),
                    viewToClassName(view),
                  )}
                >
                  {model.branding.header}
                </h3>

                <p
                  className={setClass(
                    'k-self-service__preview-subheader',
                    modeToClassName(mode),
                    viewToClassName(view),
                  )}
                >
                  {model.branding.subheader}
                </p>
              </div>
            </div>
          </div>
        </div>
        {/* </div> */}
        <PreLoadImages paths={assets} />
      </div>
    </Setting.Card>
  );
};

export default PreviewSection;
