import {
  Box,
  Button,
  Dialog,
  Flex,
  Heading,
  Separator,
  Text,
} from '@kandji-inc/nectar-ui';
import indefinite from 'indefinite';
import React, { memo } from 'react';
import type { LibraryItem } from '../blueprint-flow.types';

const getAllDevicesContent = (item: LibraryItem) => {
  const itemConfig = item.defaultConfiguration;

  const title = `${itemConfig?.name} cannot be placed here.`;

  const content = (
    <Text>
      {`${itemConfig?.name} does not support Assignment Rules. Place it in the "All
      Devices" node at the beginning of the map to use it in this Blueprint.`}
    </Text>
  );

  return [title, content];
};

const getSingleSCLIContent = (item: LibraryItem) => {
  const itemConfig = item.defaultConfiguration;
  const itemConfigNameWithArticle = indefinite(itemConfig?.name || '');

  const title = `More than one ${itemConfig?.name} Library Item may not be assigned to the same device.`;

  const content = (
    <Text>
      This rule already contains {itemConfigNameWithArticle} Library Item.
      Remove the existing {itemConfig?.name} Library Item to assign this one
      instead.{' '}
      <a
        target="_blank"
        rel="noreferrer"
        href="https://support.kandji.io/support/solutions/articles/72000599118-assigning-library-items"
      >
        <Text
          variant="primary"
          css={{
            fontWeight: '$medium',
            display: 'inline-block',
            '&:hover': { textDecoration: 'underline' },
          }}
        >
          Learn more
        </Text>
      </a>
    </Text>
  );

  return [title, content];
};

const getMultipleSCLIContent = (
  items: Array<LibraryItem>,
  allDevicesItems: Array<LibraryItem>,
) => {
  const itemsByCategory: Record<string, Array<LibraryItem>> = items.reduce(
    (a, item) => {
      const category = item.defaultConfiguration.name;
      return {
        ...a,
        [category]: [...(a[category] || []), item],
      };
    },
    {},
  );

  const uniqueItemCount = Object.keys(
    [items, allDevicesItems]
      .flat()
      .reduce((a, item) => ({ ...a, [item.id]: item }), {}),
  ).length;

  const title = `${uniqueItemCount} Library Items were not placed.`;

  const content = (
    <Flex flow="column" gap="md" css={{ maxHeight: '400px', overflow: 'auto' }}>
      {Boolean(allDevicesItems.length) && (
        <>
          <Text>
            {`The following ${allDevicesItems.length} Library Items do not support
            Assignment Rules. Place them in the "All devices on this Blueprint" node at the
            beginning of the map to use them in this Blueprint.`}
          </Text>
          <ul style={{ margin: 0, paddingLeft: '28px' }}>
            {allDevicesItems.map((item) => (
              <li key={item.id}>
                <Flex gap="xs" alignItems="center">
                  <Text
                    size="2"
                    css={{
                      lineHeight: '20px',
                      color: '$neutral100',
                      fontWeight: '$medium',
                    }}
                  >
                    {item.name}
                    {item.instanceName && ','}
                  </Text>
                  {item.instanceName && (
                    <Text
                      css={{
                        lineHeight: '20px',
                        color: '$neutral100',
                      }}
                    >
                      {item.instanceName}
                    </Text>
                  )}
                </Flex>
              </li>
            ))}
          </ul>
        </>
      )}
      {Boolean(allDevicesItems.length) && Boolean(items.length) && (
        <Separator dir="horizontal" css={{ background: '$neutral20' }} />
      )}
      {Boolean(items.length) && (
        <>
          <Text>
            More than one of the following Library Item types may not be
            assigned to the same device. This node already contains these types,
            or the selection includes more than one of the same type.{' '}
            <a
              target="_blank"
              rel="noreferrer"
              href="https://support.kandji.io/support/solutions/articles/72000599118-assigning-library-items"
            >
              <Text
                variant="primary"
                css={{
                  fontWeight: '$medium',
                  display: 'inline-block',
                  '&:hover': { textDecoration: 'underline' },
                }}
              >
                Learn more
              </Text>
            </a>
          </Text>
          {Object.keys(itemsByCategory).map((category) => (
            <Box key={category}>
              <Heading
                size="6"
                css={{ color: '$neutral60', fontWeight: '$medium' }}
              >
                {category}
              </Heading>
              <ul style={{ margin: 0, paddingLeft: '28px' }}>
                {itemsByCategory[category].map((item) => (
                  <li key={item.id}>
                    <Flex gap="xs" alignItems="center">
                      <Text
                        size="2"
                        css={{
                          lineHeight: '20px',
                          color: '$neutral100',
                          fontWeight: '$medium',
                        }}
                      >
                        {item.name}
                        {item.instanceName && ','}
                      </Text>
                      {item.instanceName && (
                        <Text
                          css={{
                            lineHeight: '20px',
                            color: '$neutral100',
                          }}
                        >
                          {item.instanceName}
                        </Text>
                      )}
                    </Flex>
                  </li>
                ))}
              </ul>
            </Box>
          ))}
        </>
      )}
    </Flex>
  );

  return [title, content];
};

function ConflictModal(props: {
  isOpen: boolean;
  items: Array<LibraryItem>;
  allDevicesItems: Array<LibraryItem>;
  toggle: (
    items: Array<LibraryItem>,
    allDevicesItems: Array<LibraryItem>,
    override?: boolean,
  ) => void;
}) {
  const { isOpen, items, allDevicesItems, toggle } = props;

  if (!items?.length && !allDevicesItems?.length) {
    return null;
  }

  const isMultipleSCLI =
    items?.length > 1 ||
    allDevicesItems?.length > 1 ||
    (items?.length && allDevicesItems?.length);
  const closeModal = () => toggle(null, null, false);
  const handleCancel = () => closeModal();

  const [title, content] = isMultipleSCLI
    ? getMultipleSCLIContent(items, allDevicesItems)
    : items[0]
      ? getSingleSCLIContent(items[0])
      : getAllDevicesContent(allDevicesItems[0]);

  const footer = (
    <Flex gap="md" justifyContent="end" wrap="wrap">
      <Button variant="primary" onClick={handleCancel}>
        Okay
      </Button>
    </Flex>
  );

  return (
    <Dialog
      title={title}
      content={content}
      footer={footer}
      isOpen={isOpen}
      onOpenChange={handleCancel}
      css={{ zIndex: 2000, width: '560px' }}
    />
  );
}

export default memo(ConflictModal);
