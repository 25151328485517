/* istanbul ignore file */
import {
  type ToastVariants,
  useToast_UNSTABLE as useToast,
} from '@kandji-inc/nectar-ui';
import * as React from 'react';
import { InterfaceContext } from 'src/contexts/interface';

export const SIDEBAR_DOCKED_OFFSET = 256;
export const SIDEBAR_CLOSE_OFFSET = 78;

export function useDisplayToast() {
  const { sidebarDocked } = React.useContext(InterfaceContext);
  const { toast } = useToast();

  return (title: string, variant: ToastVariants, content: string) =>
    toast({
      title,
      variant,
      style: {
        left: /* istanbul ignore next */ sidebarDocked
          ? `${SIDEBAR_DOCKED_OFFSET + 12}px`
          : `${SIDEBAR_CLOSE_OFFSET + 12}px`,
        bottom: '12px',
        position: 'absolute',
      },
      content,
    });
}
