/* istanbul ignore file */
/* eslint-disable no-plusplus */
import { TextField, useToast_UNSTABLE } from '@kandji-inc/nectar-ui';
import React, { useContext, useEffect, useRef } from 'react';
import { type Node, useNodes, useReactFlow, useStoreApi } from 'reactflow';

import useDebouncedState from 'src/features/compliance/Policy/Devices/useDebouncedState';
import { getDisplayValue } from 'src/features/rules/use-rule-service';
import { get } from 'src/features/util/lib';

import { InterfaceContext } from 'src/contexts/interface';
import type { LibraryItem, MapSearchField } from '../../blueprint-flow.types';
import {
  doesTextHaveMatch,
  escapeSpecialCharacters,
  getLibraryItem,
} from '../../helpers';
import { transformJsonLogicToTag } from '../../services';
import useBlueprintFlow from '../../store';

export const MATCH_LIBRARY_ITEM_ID = (
  origin: LibraryItem['origin'],
  flowId: LibraryItem['flowId'],
) => `${origin}-${flowId}`;
export const MATCH_RULE_ID = (ruleId: string) => `rule-${ruleId}`;

const MapSearch = () => {
  const [
    libraryItems,
    facetMap,
    activeMatches,
    setMapSearchTerm,
    setActiveMatches,
  ] = useBlueprintFlow((state) => [
    state.libraryItems,
    state.facetMap,
    state.activeMatches,
    state.setMapSearchTerm,
    state.setActiveMatches,
  ]);
  const store = useStoreApi();
  const { getZoom, setCenter, getNode } = useReactFlow();
  const { sidebarDocked } = useContext(InterfaceContext);
  const { toast } = useToast_UNSTABLE();
  const [dTerm, setTerm, term] = useDebouncedState<string>('', 300);
  const inputRef = useRef<HTMLInputElement>(null);
  const nodes = useNodes();

  const clearActiveMatches = () =>
    setActiveMatches((prev) => ({
      ...prev,
      matches: [],
      currentMatchIndex: 0,
    }));

  const focusNode = (nodeId: string) => {
    const node = getNode(nodeId);
    if (!node) {
      return null;
    }

    const { nodeInternals } = store.getState();
    const _node = Array.from(nodeInternals)
      .map(([, node]) => node)
      .find(({ id }) => id === node.id);

    const x = _node.positionAbsolute.x;
    const y = _node.positionAbsolute.y - node.height / 2;
    const zoom = getZoom();

    setCenter(x, y, { zoom });
  };

  const scrollToMatch = (match) => {
    focusNode(match.nodeId);
    if (match.item) {
      const el: HTMLElement = document.querySelector(
        `#${MATCH_LIBRARY_ITEM_ID(match.item.origin, match.item.flowId)}`,
      );
      if (el) {
        setTimeout(() =>
          el.scrollIntoView({
            behavior: 'smooth',
            block: 'center',
          }),
        );
      }
    } else {
      const el = document.querySelector(`#${MATCH_RULE_ID(match.rule.ruleId)}`);
      if (el) {
        setTimeout(() =>
          el.scrollIntoView({
            behavior: 'smooth',
            block: 'center',
          }),
        );
      }
    }
  };

  /**
   * Resolves the rules to be a list of display values
   * @returns A list of rules evaluated to be their display values.
   */
  const resolveRules = async (rules: { and: Array<any> }, facetMap: any) => {
    if (!rules) {
      return null;
    }

    return (
      await Promise.all(
        rules.and.map(async (rule) => {
          if (rule.or) {
            const orRules = rule.or.map(async (orRule) => {
              const { input, value, jsonLogicOperator, facetData } =
                transformJsonLogicToTag(orRule, facetMap);

              const displayValue = await getDisplayValue(
                value,
                jsonLogicOperator,
                facetData,
                { sidebarDocked, toast },
              );

              return { ruleId: orRule.id, rule: orRule, input, displayValue };
            });

            return await Promise.all(orRules);
          }

          const { input, value, jsonLogicOperator, facetData } =
            transformJsonLogicToTag(rule, facetMap);

          const displayValue = await getDisplayValue(
            value,
            jsonLogicOperator,
            facetData,
            { sidebarDocked, toast },
          );

          return { ruleId: rule.id, rule, input, displayValue };
        }),
      )
    ).flat();
  };

  const getMatches = async (nodes: Array<Node>, term: string) => {
    // The list of nodes that have library items or rules, with each expanded to
    // include their respective data.
    const relevantNodes = (
      await Promise.all(
        nodes.map(async (node) => ({
          nodeId: node.id,
          libraryItems: node.data?.items?.map((item) =>
            getLibraryItem(item.data.id, libraryItems, {
              flowId: item.data.flowId,
              origin: item.data.origin,
            }),
          ),
          rules: await resolveRules(node.data?.rules, facetMap),
        })),
      )
    ).filter(({ libraryItems, rules }) => libraryItems?.length > 0 || rules);

    // Out of the relevant nodes, collapse into a list of items and rules
    // identified by the field they're meant to be matching.
    const collapsedNodes = relevantNodes.reduce((a, c) => {
      const itemFields: Array<MapSearchField> = [
        'name',
        'defaultConfiguration.name',
        'instanceName',
      ];
      const ruleFields: Array<MapSearchField> = ['input', 'displayValue'];
      return [
        ...a,
        ...(c.rules
          ?.map((rule) =>
            ruleFields.map((field) => ({
              nodeId: c.nodeId,
              rule,
              field,
            })),
          )
          .flat() || []),
        ...(c.libraryItems
          ?.map((libraryItem) =>
            itemFields.map((field) => ({
              nodeId: c.nodeId,
              item: libraryItem,
              field,
            })),
          )
          .flat() || []),
      ];
    }, []);

    const matches = collapsedNodes
      .map((node) => {
        const matchingParts = get<string>(node.rule || node.item, node.field)
          ?.split(new RegExp(`(${escapeSpecialCharacters(term)})`, 'gi'))
          .filter((part) => part.toLowerCase() === term.toLowerCase());

        return matchingParts
          ?.map((_, idx) => ({
            ...node,
            ...(node.item && { item: { ...node.item, itemMatchIdx: idx } }),
            ...(node.rule && { rule: { ...node.rule, ruleMatchIdx: idx } }),
          }))
          .filter((node) =>
            doesTextHaveMatch(get(node.rule || node.item, node.field), term),
          );
      })
      .flat()
      .filter(Boolean);

    return matches;
  };

  const onPaginate = (direction: 'next' | 'prev') => () => {
    setActiveMatches((prev) => {
      const idx =
        direction === 'prev'
          ? (prev.currentMatchIndex - 1 + prev.matches.length) %
            prev.matches.length
          : (prev.currentMatchIndex + 1) % prev.matches.length;

      const match = prev.matches[idx];
      scrollToMatch(match);
      inputRef.current?.focus();
      return {
        ...prev,
        currentMatchIndex: idx,
      };
    });
  };

  useEffect(() => {
    if (!dTerm) {
      clearActiveMatches();
      return;
    }

    getMatches(nodes, dTerm).then((matches) => {
      const isSameAsCurrentMatches =
        JSON.stringify(matches) === JSON.stringify(activeMatches.matches);

      if (isSameAsCurrentMatches) {
        return null;
      }

      if (matches.length) {
        scrollToMatch(matches[0]);
      }
      setActiveMatches((prev) => ({
        ...prev,
        matches,
        currentMatchIndex: 0,
      }));
    });
  }, [nodes, dTerm]);

  useEffect(() => {
    setMapSearchTerm(dTerm);
  }, [dTerm]);

  useEffect(() => {
    const listenForFind = (e) => {
      if ((e.metaKey || e.ctrlKey) && e.key === 'f') {
        e.preventDefault();
        setTimeout(() => inputRef.current?.focus(), 0);
      }
    };

    window.addEventListener('keydown', listenForFind);
    return () => {
      window.removeEventListener('keydown', listenForFind);
    };
  }, []);

  const hasActiveMatches = activeMatches.matches.length > 0;

  return (
    <>
      <TextField
        compact
        ref={inputRef}
        placeholder="Search the map"
        icon="magnifying-glass"
        value={term}
        onChange={(e) => setTerm(e.target.value)}
        showClearButton={term.length > 0}
        onClear={() => {
          setTerm('');
          clearActiveMatches();
        }}
        css={{ width: '271px' }}
        pagination={{
          visible: hasActiveMatches,
          min: 1,
          max: activeMatches.matches.length,
          current: activeMatches.currentMatchIndex + 1,
          onNext: onPaginate('next'),
          onPrev: onPaginate('prev'),
        }}
        data-testid="map-search"
      />
    </>
  );
};

export default MapSearch;
