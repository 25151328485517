import { Button, Chip } from '@kandji-inc/bumblebee';
import { wAmount } from 'features/util/misc';
import { i18n } from 'i18n';
import React from 'react';
import OptionsMenu from '../../../../../../util/components/options-menu';

function getNameColumn(ratio) {
  return {
    fieldName: 'name',
    header: i18n.t('Notification name'),
    isSortable: true,
    ratio,
    Cell: ({ item }) => (
      <div className="b-txt-bold integration-card_name-cell">
        <span>{item.name}</span>
      </div>
    ),
  };
}

function getChannelsColumn(ratio, icon) {
  return {
    fieldName: 'channels',
    header: i18n.t('Channels'),
    className: 'integration-card_channels-column',
    ratio,
    Cell: ({ item }) => (
      <div className="integration-card_channels-cell">
        {item.channels.map((channel) => (
          <Chip
            key={channel.channel_id}
            text={channel.channel_name}
            iconLeft={icon}
            kind="primary"
          />
        ))}
      </div>
    ),
  };
}

function getActionsColumn(
  ratio,
  openDeleteNotificationModal,
  openNotificationEditor,
) {
  return {
    fieldName: 'actions',
    header: i18n.t('Event triggers'),
    ratio,
    className: 'integration-card_actions-column',
    Cell: ({ item }) => (
      <>
        <div className="b-txt-light">
          {i18n.t(
            '{count, plural, one {# event trigger} other {# event triggers}}',
            { count: item.actions.length },
          )}
        </div>

        <OptionsMenu
          className="integration-card_options-btn"
          render={(close) => (
            <>
              <Button
                icon="pencil"
                kind="link"
                onClick={() => {
                  close();
                  openNotificationEditor(item);
                }}
              >
                {i18n.t('Edit event notification')}
              </Button>
              <Button
                icon="trash-can"
                kind="link"
                onClick={() => {
                  close();
                  openDeleteNotificationModal(item);
                }}
                theme="error"
              >
                {i18n.t('Delete event notification')}
              </Button>
            </>
          )}
        >
          <Button icon="ellipsis" kind="link" theme="action" />
        </OptionsMenu>
      </>
    ),
  };
}

export { getNameColumn, getChannelsColumn, getActionsColumn };
