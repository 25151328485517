/* istanbul ignore file - legacy code, moved */
import {
  LabelSubInput,
  TextInput,
} from 'app/components/library/common/LibrarySetting.styles';
import PropTypes from 'prop-types';
import React from 'react';
import { Label, SubInfo, SubLabel, TextBlock } from './SCEP.styles';

const SCEPLibraryText = ({
  label,
  subLabel,
  value,
  onChange,
  isEditorMode,
  isRequired,
  disabled,
  isOptional,
  placeholder,
  isError,
  style,
  isAdaptive,
}) => (
  <>
    <TextBlock isAdaptive={isAdaptive}>
      {label && (
        <Label subLabel={subLabel} htmlFor={`key-${label}`}>
          {label}
          {isOptional && <LabelSubInput> (optional) </LabelSubInput>}
          {isRequired && <LabelSubInput> (required) </LabelSubInput>}
        </Label>
      )}
      <TextInput
        type="text"
        value={value || ''}
        placeholder={placeholder}
        disabled={!isEditorMode || disabled}
        onChange={onChange}
        error={isError}
        style={style}
        isAdaptive={isAdaptive}
      />
      <SubInfo isAdaptive={isAdaptive}>
        <SubLabel isAdaptive={isAdaptive}>{subLabel}</SubLabel>
      </SubInfo>
    </TextBlock>
  </>
);

SCEPLibraryText.propTypes = {
  label: PropTypes.string.isRequired,
  subLabel: PropTypes.string.isRequired,
  isOptional: PropTypes.bool.isRequired,
  placeholder: PropTypes.string.isRequired,
  isError: PropTypes.bool.isRequired,
  style: PropTypes.objectOf(PropTypes.any).isRequired,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
    PropTypes.number,
    PropTypes.object,
  ]).isRequired,
  onChange: PropTypes.func.isRequired,
  isEditorMode: PropTypes.bool,
  disabled: PropTypes.bool.isRequired,
  isAdaptive: PropTypes.bool.isRequired,
  isRequired: PropTypes.bool.isRequired,
};

SCEPLibraryText.defaultProps = {
  isEditorMode: false,
};

export default SCEPLibraryText;
