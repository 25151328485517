import styled from 'styled-components';
import { withBorder } from './mixins';

export const CardTitle = styled.div`
  ${withBorder}
  padding: 10px 24px;
  display: flex;
  align-items: center;
`;

export const CardSection = styled.div`
  ${withBorder};
  padding: 18px 16px;

  & > p {
    margin: 6px var(--b-gap-tiny);
  }
`;

export const CardContainer = styled.div`
  background: var(--color-neutral-0);
  border-radius: 4px;
`;
