import * as constants from '../constants';

export const auth0Domain = constants.REACT_APP_AUTH0_DOMAIN;

export const auth0RedirectUri = `${window.location.origin}`;

export const auth0EnrollmentRedirectUri = `${auth0RedirectUri}/enrollment/authentication`;

export const enrollmentAudience = constants.REACT_APP_AUTH0_ENROLLMENT_AUDIENCE;

export const webApiAudience = constants.REACT_APP_AUTH0_WEB_API_AUDIENCE;

export const SAML_CONNECTION_ID = 'samlp';
