/* istanbul ignore file */
import { Buffer } from 'buffer';
import { links } from 'app/common/constants';
import { syncVPPData } from 'app/components/integrations/Apple/api';
import type { FieldValues } from 'react-hook-form';
import {
  LIBRARY_ITEMS,
  libraryItemService,
} from '../../../../data-service/library-item-service';
import { oktaDeviceTrustService } from '../../../okta/services/okta-device-trust-service';
import { buildDeepLink } from '../../../okta/utils/build-deep-link';

import { i18n } from 'i18n';
import type { CallBackFunc } from '../../types/integrator.types';
import type { OktaInputs, OktaState } from '../../types/okta.types';

type OktaCallBack = CallBackFunc<OktaInputs, OktaState>;

export const oktaSignInHandleNext: OktaCallBack = ({
  nextPage,
  formInputs,
}) => {
  if (!formInputs.oktaDomain) {
    throw new Error('domain is not available');
  }
  const link = buildDeepLink(formInputs.oktaDomain);
  window.open(link, '_blank', 'noreferrer');
  nextPage();
};

export const oktaCredentialsHandleNext: OktaCallBack = ({
  nextPage,
  formInputs,
  setState,
}) => {
  if (
    !formInputs.oktaDomain ||
    !formInputs.clientId ||
    !formInputs.clientSecret
  ) {
    throw Error('form inputs are not available');
  }

  const token = generateToken(formInputs);
  setState({ footerStatus: 'pending' });

  return oktaDeviceTrustService
    .verifyOkta(formInputs.oktaDomain, token)
    .then((resp) => {
      setState({
        verifyResult: resp.data,
        footerStatus: 'success',
      });
      nextPage();
    })
    .catch((error) => {
      if (
        error.response.data.oktaIdentityEngine === 'not enabled' ||
        error.response.data.fastpass === 'not enabled'
      ) {
        setState({ verifyResult: error.response.data });
        nextPage();
      } else {
        setState({ footerStatus: 'failed' });
      }
    });
};

export const oktaCredentialsHandleRetry: OktaCallBack = ({ prevPage }) => {
  prevPage();
};

export const oktaAddMacOSHandleNextText = ({
  formInputs,
}: {
  formInputs: OktaInputs;
}) => (formInputs.iosDevices ? i18n.t('Next') : i18n.t('Finish setup'));

export const oktaAddMacOSHandleNext: OktaCallBack = (args) => {
  if (args.formInputs.iosDevices) {
    args.nextPage();
    return;
  }
  oktaCreateIntegration(args);
};

export const oktaAddIOSHandleNext: OktaCallBack = (args) => {
  oktaCreateIntegration(args);
};

export const oktaDeviceSetupValidation = (_: unknown, formInputs: OktaInputs) =>
  !!formInputs.iosDevices || !!formInputs.macOsDevices;

export const isFastpassDisabled = (state: OktaState) =>
  state.verifyResult?.fastpass === 'not enabled';

export const isIdentityEnginesDisabled = (state: OktaState) =>
  state.verifyResult?.oktaIdentityEngine === 'not enabled';

export const oktaHandleSetupLater: OktaCallBack = ({ nextPage, setState }) => {
  setState({ isSetupLater: true });
  nextPage();
};

export const oktaHideCancelButton = ({ state }: { state: OktaState }) =>
  !state.isSetupLater;

export const oktaSetupCompleteDescription = (state: OktaState) =>
  state && state.isSetupLater
    ? i18n.t(`The Okta Device Trust integration was successfully configured. Once you have added Okta Verify in 
  Apple Business Manager or Apple School Manager and Okta Verify syncs to your Library, you can turn on 
  device trust and scope the Library item to your devices`)
    : i18n.t(`The Okta Device Trust integration was successfully configured. You can
  turn on device trust in the Okta Verify Library Item now, or choose to
  set this up later and view the integration details.`);

const integrationArgs = (formInputs: FieldValues) => ({
  domain: formInputs.oktaDomain,
  token: generateToken(formInputs),
  okta_url: formInputs.oktaDomain,
  client_id: formInputs.clientId,
  client_secret: formInputs.clientSecret,
  // Add iOS specific
  ...(formInputs.iosDevices && {
    ios_provider_secret_key: formInputs.secretKey,
  }),
  // Add MacOS specific
  ...(formInputs.macOsDevices && {
    mac_scep_url: formInputs.scepUrl,
    mac_scep_challenge_url: formInputs.challengeUrl,
    mac_scep_challenge_password: formInputs.password,
    mac_scep_challenge_username: formInputs.username,
  }),
});

export const oktaCreateIntegration: OktaCallBack = ({
  formInputs,
  nextPage,
  setState,
}) => {
  setState({ footerStatus: 'pending' });

  return oktaDeviceTrustService
    .createDevicePlatform(integrationArgs(formInputs))
    .then((resp) => {
      if (resp && Object.keys(resp?.data).length > 0) {
        return libraryItemService.getLicenseCountByName(
          LIBRARY_ITEMS.oktaVerify,
        );
      }
      return Promise.reject(resp);
    })
    .then((resp) => {
      if (resp?.licenses_total) {
        setState({
          libraryItemId: resp.id,
          verifyLibrary: resp.licenses_total,
        });
      }
      nextPage();
      // sets initial state on the next page
      setState({ footerStatus: 'success' });
    })
    .catch((_err: Error) => {
      setState({ footerStatus: 'failed' });
    });
};

export const oktaHandleSync: OktaCallBack = (args) => {
  syncVPPData();
  getOktaLicenseCount(args);
};
export const oktaHandleIntegrationDetails: OktaCallBack = ({ history }) => {
  history.push(`${links.integrations.root}/okta_device_trust`, {
    isConfirmed: true,
  });
};

export const oktaHandleLibraryItemView: OktaCallBack = ({ state, history }) => {
  history.push(`${links.vppApps}/${state.libraryItemId}`, {
    isConfirmed: true,
  });
};

const getOktaLicenseCount: OktaCallBack = async ({ nextPage, setState }) => {
  setState({ footerStatus: 'pending' });
  await libraryItemService
    .getLicenseCountByName(LIBRARY_ITEMS.oktaVerify)
    .then((resp) => {
      if (resp?.licenses_total) {
        setState({
          libraryItemId: resp.id,
          verifyLibrary: resp.licenses_total,
        });
        nextPage();
        // sets initial state on the next page
        setState({ footerStatus: 'success' });
      } else {
        setState({ footerStatus: 'failed' });
      }
    })
    .catch((_err: Error) => {
      setState({ footerStatus: 'failed' });
    });
};

const generateToken = (formInputs: FieldValues) =>
  Buffer.from(`${formInputs.clientId}:${formInputs.clientSecret}`).toString(
    'base64',
  );

// Footer Status Options
export const oktaCredentialsStatusOptions = {
  pending: {
    get title() {
      return i18n.t('Confirming the connection with Okta...');
    },
    get statusText() {
      return i18n.t('In progress');
    },
  },
  success: {
    get title() {
      return i18n.t('Okta is successfully connected.');
    },
    get statusText() {
      return i18n.t('Completed');
    },
  },
  failed: {
    get title() {
      return i18n.t('Connection to Okta failed.');
    },
    get statusText() {
      return i18n.t('Failed');
    },
  },
};

export const oktaDeviceSetupStatusOptions = {
  pending: {
    get title() {
      return i18n.t('Finalizing integration setup...');
    },
    get statusText() {
      return i18n.t('In progress');
    },
  },
  failed: {
    get title() {
      return i18n.t('There was an error with one of the fields.');
    },
    get statusText() {
      return i18n.t('Failed');
    },
  },
};

export const oktaVerifyLibraryStatusOptions = {
  success: {
    get title() {
      return i18n.t('Okta Device Trust is successfully connected.');
    },
    get statusText() {
      return i18n.t('Completed');
    },
  },
  pending: {
    get title() {
      return i18n.t('Confirming Okta Verify licensing...');
    },
    get statusText() {
      return i18n.t('In progress');
    },
  },
  failed: {
    get title() {
      return i18n.t('Okta Verify licenses not found');
    },
    get statusText() {
      return i18n.t('Not synced');
    },
  },
};

export const oktaSetupCompleteStatusOptions = {
  success: {
    get title() {
      return i18n.t('Okta Device Trust is successfully connected.');
    },
    get statusText() {
      return i18n.t('Completed');
    },
  },
};
