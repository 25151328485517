import type { DetectionDateFilterFields } from '../common/common.types';

export type UUID = string;
export type ISO8601DateTimeStamp = string;

export type ThreatClassification = 'PUP' | 'MALWARE' | 'BENIGN' | 'UNKNOWN';

export type BehavioralThreatClassification = 'MAL' | 'SUS';

export type ThreatStatus =
  | 'QUARANTINED'
  | 'NOT_QUARANTINED'
  | 'RELEASED'
  | 'RESOLVED';

export type BehavioralThreatStatus =
  | 'DETECTED'
  | 'BLOCKED'
  | 'BLOCKED_PARENT'
  | 'INFORMATIONAL';

export type ThreatSortByParamValue =
  | 'threat_name'
  | '-threat_name'
  | 'classification'
  | '-classification'
  | 'device_name'
  | '-device_name'
  | 'process_name'
  | '-process_name'
  | 'detection_date'
  | '-detection_date'
  | 'status'
  | '-status'
  | 'device_count'
  | '-device_count'
  | 'process'
  | '-process';

export type GetThreatDetailsRequestParams = {
  limit: string;
  offset: string;
  sort_by: ThreatSortByParamValue | '';
  device_id: UUID;
  statuses: string;
  classification: ThreatClassification | '';
  term: string;
  date_range?: string;
  detection_date_from?: string;
  detection_date_to?: string;
};

export type GetThreatExportRequestParams = {
  sort_by: ThreatSortByParamValue | '';
  device_id: UUID;
  statuses: string;
  classification: ThreatClassification | '';
  term: string;
  date_range?: string;
  detection_date_from?: string;
  detection_date_to?: string;
  file_hash?: string;
  file_path?: string;
  export_type?: 'groups';
};

export type ThreatDetail = {
  threat_name: string;
  classification: ThreatClassification;
  status: ThreatStatus;
  process_owner: string;
  process_name: string;
  device_name: string;
  device_id: UUID;
  detection_date: ISO8601DateTimeStamp;
  date_of_quarantine: ISO8601DateTimeStamp;
  date_of_release: ISO8601DateTimeStamp;
  released_by: string;
  release_note: string;
  resolved_at: ISO8601DateTimeStamp;
  resolved_by: string;
  file_path: string;
  file_hash: string;
  device_serial_number?: string;
  blueprint_id?: string;
  blueprint_name?: string;
  library_item_name?: string;
  library_item_id?: string;
  pup_posture?: string;
  malware_posture?: string;
  bundle_path?: string;
};

export type GetThreatDetailsResponse = {
  previous: string;
  next: string;
  count: number;
  malware_count: number;
  pup_count: number;
  results: ThreatDetail[];
};

export type GetBehavioralThreatDetailsRequestParams = {
  limit: string;
  offset: string;
  sort_by: ThreatSortByParamValue | '';
  device_id: UUID;
  statuses?: string;
  classification: ThreatClassification | '';
  term: string;
  date_range?: string;
  detection_date_from?: string;
  detection_date_to?: string;
};

export type BehavioralThreatDetail = {
  threat_id: string;
  rule_version: string;
  process: string;
  classification: BehavioralThreatClassification;
  description: string;
  detection_date: string;
  threat_status: BehavioralThreatStatus;
  responsible_process_name: string;
  responsible_process_id: string;
  responsible_process_owner: string;
  responsible_command_line: string;
  responsible_image_path: string;
  responsible_process_sha256: string;
  parent_process_image_path: string;
  parent_process_command_line: string;
  parent_process_id: string;
  parent_process_name: string;
  parent_process_sha256: string;
  parent_process_owner: string;
  target_process_name: string;
  target_process_id: string;
  target_process_owner: string;
  target_command_line: string;
  target_image_path: string;
  target_process_sha256: string;
  malware_families: string[];
  informational_tags: string[];
  mal_posture_mode: Posture;
  avert_library_item_name?: string;
  avert_library_item_id?: string;
  blueprint_id?: string;
  blueprint_name?: string;
};

export type GetBehavioralThreatDetailsResponse = {
  detections: BehavioralThreatDetail[];
  count: number;
  limit: number;
  offset: number;
};

export type BehavioralThreatGroup = {
  threat_id: string;
  first_detection_date: string;
  last_detection_date: string;
  classification: BehavioralThreatClassification;
  latest_process_name: string;
  target_process_args: string;
  target_process_name: string;
  device_count: number;
  blocked_count?: number | undefined;
  detected_count?: number | undefined;
  informational_count?: number | undefined;
  description: string | null;
  malware_families: string[] | undefined;
  informational_tags: string[] | undefined;
  associated_item: string;
};

export type GetBehavioralThreatGroupRequestParams = {
  limit: string;
  offset: string;
  sort_by: ThreatSortByParamValue | '';
  device_id: UUID;
  statuses?: string;
  classification: ThreatClassification | '';
  term: string;
  date_range?: string;
  detection_date_from?: string;
  detection_date_to?: string;
};

export type GetBehavioralThreatGroupResponse = {
  data: BehavioralThreatGroup[];
  count: number;
  limit: number;
  offset: number;
  mal_count: number;
  sus_count: number;
};

export type ReleaseThreatRequestBody = {
  file_hash: string;
  device_id: UUID;
};

export type ReleaseThreatResponse = {
  device_ids: Array<UUID>; // needs to be sent back in POST
  device_count: number;
  library_item_name: string;
  library_item_is_threat_allowed: boolean; // true if the file hash matches an allow by hash entry, else fals
};

export type CreateReleaseThreatAffectedDeviceDetailsRequestBody = {
  is_adding_to_allow_list: boolean;
  descriptive_name: string;
  file_hash: string;
  device_ids: Array<UUID>;
  release_note?: string;
};

export type CreateReleaseThreatAffectedDeviceDetailsResponse = {
  device_count: number;
  library_item_name: string;
};

export type ThreatPaginatedRequestParams = GetThreatDetailsRequestParams;

export type ThreatPaginatedResponse = GetThreatDetailsResponse;

export type FileStatusRequestBody = {
  device_id: UUID;
  file_hash: string;
  file_path: string;
};

export type FileStatusResponse = {
  threat_incident_response: {
    id: number;
  };
};

type ResponseStatusKey =
  | 'RESOLVED'
  | 'MANUAL_DELETE_FAILED'
  | 'MANUAL_DELETE_PENDING';
type ResponseStatusValue =
  | 'resolved'
  | 'manual_delete_failed'
  | 'manual_delete_pending';

export const IncidentResponseStatuses: Record<
  ResponseStatusKey,
  ResponseStatusValue
> = {
  RESOLVED: 'resolved',
  MANUAL_DELETE_FAILED: 'manual_delete_failed',
  MANUAL_DELETE_PENDING: 'manual_delete_pending',
};

export type IncidentResponse = {
  threat_incident_response: {
    status: ResponseStatusValue;
    action_successful?: boolean;
  };
};

export type FileStatus =
  | 'checking'
  | 'resolved'
  | 'undeleted'
  | 'timeout'
  | 'error';

export type GetAssignedBlueprintRequestParams = {
  blueprint_id?: string;
};

export type GetAssignedBlueprintResponse = {
  avert_enabled: boolean;
};

export type FiltersState = {
  detectionDate?: DetectionDateFilterFields | undefined;
  status?: ThreatStatus[] | BehavioralThreatStatus[] | undefined;
  classification?:
    | ThreatClassification
    | BehavioralThreatClassification
    | undefined;
  detectionType?: DetectionTypeSelectorState | undefined;
  query?: string | undefined;
};

export type FilterFields = keyof FiltersState;

export type ThreatPosture = 'DETECT' | 'PROTECT';

export type UniquePathFilter = {
  path: string;
  devices: number;
};

export type DevicesSort =
  | 'device-name-asc'
  | 'device-name-desc'
  | 'latest-detected'
  | 'first-detected';

export type DevicesFiltersState = {
  detectionDate?: DetectionDateFilterFields | undefined;
  status?: ThreatStatus[] | undefined;
  path?: string[] | undefined;
  query?: string | undefined;
  sort?: DevicesSort;
};

export type DevicesFilterFields = keyof DevicesFiltersState;

export type OnClearAll = () => void;

export type SortColumnName =
  | 'threat_id'
  | 'associated_item'
  | 'threat_name'
  | 'classification'
  | 'process_name'
  | 'process'
  | 'device_name'
  | 'detection_date'
  | 'status'
  | 'device_count';

export type SortColumnDirection = 'none' | 'desc' | 'asc';

export type SortColumnState = {
  sortBy?: SortColumnName | undefined;
  sortOrder?: SortColumnDirection | undefined;
};

export type PageSize = 10 | 25 | 50 | 100 | 300;

export type PaginationState = {
  page?: number;
  pageSize?: PageSize;
};

export type ViewSelectorState = 'by-threats' | 'by-devices';

export type DetectionTypeSelectorState = 'FILE' | 'BEHAVIORAL';

type ToastConfigItem = {
  duration: number;
  variant: 'progress-indeterminate' | 'success' | 'error';
  title: string;
  content: string;
};

export type ToastConfigs = {
  [key: string]: ToastConfigItem;
};

export type ShowToast = (
  message: string,
  override?: Partial<ToastConfigItem>,
) => void;

export type GetStatusCountsRequestBody = {
  detection_date_from?: string;
  detection_date_to?: string;
};

export type TableHeaderColumn = {
  name: string;
  title: string;
  size?: number;
  tooltip?: string;
  tooltipWidth?: number;
  isSortable?: boolean;
  isHidden?: boolean;
};

export type GetStatusCountsResponse = {
  malware: {
    total: number;
    quarantined: number;
    not_quarantined: number;
    released: number;
    resolved: number;
  };
  pup: {
    total: number;
    quarantined: number;
    not_quarantined: number;
    released: number;
    resolved: number;
  };
};

export type ThreatGroup = {
  latest_file_name: string;
  threat_id: string;
  latest_process_name: string;
  classification: ThreatClassification;
  last_detection_date: string;
  first_detection_date: string;
  device_count: number;
  quarantined_count: number;
  not_quarantined_count: number;
  released_count: number;
  resolved_count: number;
};

type Posture = 'Protect' | 'Detect';

export type ThreatGroupDevice = {
  blueprint_id: string;
  blueprint_name: string;
  device_id: string;
  device_name: string;
  latest_action_date: string;
  library_item_id: string;
  library_item_name: string;
  malware_posture: Posture;
  pup_posture: Posture;
  not_quarantined_count: number;
  serial_number: string;
};

export type GetByThreatsRequestParams = {
  classification: ThreatClassification | undefined;
  file_statuses: string | undefined;
  search_term: string | undefined;
  detection_from?: string;
  detection_to?: string;
  limit: string | undefined;
  offset: string | undefined;
  sort_by: string;
};

export type GetByThreatsResponse = {
  data: ThreatGroup[];
  malware_count: number;
  pup_count: number;
  benign_count: number;
  unknown_count: number;
  count: number;
  limit: number;
  offset: number;
};

export type GetDevicesResponse = {
  data: ThreatGroupDevice[];
  total: number;
  limit: number;
  offset: number;
};

export type GetDevicesRequestParams = {
  limit: string | undefined;
  offset: string | undefined;
  sort_by: string;
  file_statuses: string | undefined;
  file_paths: string | undefined;
  detection_from: string;
  detection_to: string;
  search_term: string | undefined;
};

export type GetDevicesBehavioralResponse = {
  data: ThreatGroupDeviceBehavioral[];
  total: number;
  limit: number;
  offset: number;
};

export type GetDevicesBehavioralRequestParams = {
  limit: string | undefined;
  offset: string | undefined;
  sort_by: string;
  statuses: string | undefined;
  detection_from: string;
  detection_to: string;
  search_term: string | undefined;
};

export type ThreatGroupDeviceBehavioral = {
  device_id: string;
  device_name: string;
  serial_number: string;
  detected_count: number;
  blocked_count: number;
  blueprint_id: string;
  blueprint_name: string;
  library_item_id: string;
  library_item_name: string;
  posture: Posture;
};

export type ThreatGroupDeviceEvent = {
  tenant_id: string;
  device_id: UUID;
  file_path: string;
  file_name: string | undefined;
  device_name: string | undefined;
  file_hash: string;
  process_name: string | undefined;
  process_owner: string | undefined;
  file_classification: ThreatClassification | undefined;
  release_initiated_by: string | undefined;
  release_note: string | undefined;
  detection_date: ISO8601DateTimeStamp;
  date_of_release: ISO8601DateTimeStamp;
  date_of_quarantine: ISO8601DateTimeStamp;
  date_of_release_command: ISO8601DateTimeStamp | undefined;
  lastest_action_date: ISO8601DateTimeStamp | undefined;
  file_status: ThreatStatus;
  bundle_path: string | undefined;
  resolved_at: ISO8601DateTimeStamp;
  resolved_by: string | undefined;
};

export type GetDeviceEventsResponse = {
  data: {
    needs_action: ThreatGroupDeviceEvent[];
    remediated: ThreatGroupDeviceEvent[];
  };
  total: number;
};

export type GetDeviceEventsRequestParams = {
  file_statuses: string | undefined;
  search_term: string | undefined;
  detection_from: string | undefined;
  detection_to: string | undefined;
  file_paths: string | undefined;
};

export type ThreatGroupDeviceBehavioralEvent = {
  id: string;
  detection_date: string;
  rule_version: string;
  resp_process_id: number;
  resp_process_name: string;
  resp_command_line: string;
  resp_process_image_path: string;
  resp_process_sha256: string;
  parent_process_sha256: string;
  parent_process_image_path: string;
  parent_command_line: string;
  parent_process_owner: string;
  parent_process_id: number;
  parent_process_name: string;
  target_process_owner: string;
  target_process_id: number;
  target_process_name: string;
  target_command_line: string;
  target_process_image_path: string;
  target_process_sha256: string;
  status: BehavioralThreatStatus;
};

export type GetDeviceBehavioralEventsResponse = {
  data: ThreatGroupDeviceBehavioralEvent[];
  count: number;
  detected_count: number;
  blocked_count: number;
  informational_count: number;
};

export type GetDeviceBehavioralEventsRequestParams = {
  file_statuses: string | undefined;
  search_term: string | undefined;
  detection_from: string | undefined;
  detection_to: string | undefined;
};

export type GetUniquePathsRequestParams = {
  file_statuses: string | undefined;
  search_term: string | undefined;
  detection_from: string | undefined;
  detection_to: string | undefined;
  sort_by: string | undefined;
};

export type UniquePath = {
  file_path: string;
  device_count: number;
};

export type GetUniquePathsResponse = {
  data: UniquePath[];
  total: number;
};

export type GetHistoricalDatesRequestParams = {
  device_id: string | undefined;
  file_path: string | undefined;
  file_hash: string | undefined;
  max_timestamp: string | undefined;
  count?: number | undefined;
};

export type GetHistoricalDatesResponse = {
  detected: string[];
  quarantined: string[];
  released: string[];
  resolved: string[];
};
