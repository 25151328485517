import { z } from 'zod';

const integrationRedirectUrlSchema = z.object({
  redirect_url: z.string().url(),
});

const integrationGroupsResultSchema = z.object({
  external_id: z.string(),
  id: z.string().uuid(),
  integration_id: z.number(),
  integration_name: z.string(),
  integration_type: z.string(),
  name: z.string(),
});

const integrationUserGroupListSchema = z.object({
  results: z.array(integrationGroupsResultSchema),
});

const integrationDetailsSchema = z.object({
  tenant_id: z.string().uuid(),
  integration_id: z.string().uuid(),
  ms_domain_name: z.string().nullable(),
  ms_tenant_name: z.string().nullable(),
  ms_tenant_id: z.string().uuid().nullable(),
  ios_policy_id: z.string().uuid().nullable(),
  ios_entra_group_ids: z.array(z.string().uuid()).nullable(),
  macos_policy_id: z.string().uuid().nullable(),
  macos_entra_group_ids: z.array(z.string().uuid()).nullable(),
});

const compliancePoliciesSchema = z.object({
  mac_os_devices_enabled: z.boolean().optional(),
  ios_devices_enabled: z.boolean().optional(),
  mac_os_group_ids: z.array(z.string().optional()),
  ios_group_ids: z.array(z.string().optional()),
});

const deviceRegistrationSchema = z.object({
  id: z.string().uuid(),
  device_id: z.string().uuid(),
  intune_device_id: z.string(),
  active: z.boolean(),
});

export {
  integrationRedirectUrlSchema,
  integrationGroupsResultSchema,
  integrationUserGroupListSchema,
  integrationDetailsSchema,
  compliancePoliciesSchema,
  deviceRegistrationSchema,
};
