import { setClass } from '@kandji-inc/bumblebee';
import { arrayOf, node, number, object, oneOfType, string } from 'prop-types';
import React, { useEffect, useState } from 'react';
import useReRenderTrigger from '../../hooks/use-re-render-trigger';
import './carousel.styles.scss';

function Carousel({
  slides,
  width: rawWidth,
  currentSlideIdx,
  containerRef,
  className,
  style,
}) {
  const { turnOff, turnOn } = useReRenderTrigger(['resize']);
  const [, setContainerHasUpdated] = useState(Symbol('container'));

  useEffect(
    () => () => {
      turnOff();
    },
    [],
  );

  useEffect(() => {
    setContainerHasUpdated(Symbol('container'));
  }, [containerRef]);

  let width;
  if (typeof rawWidth === 'number') {
    width = rawWidth;
  } else {
    const unit = rawWidth.replace(/\d/g, '');
    const value = rawWidth.replace(/\D/g, '');
    if (unit === 'px') {
      width = +value;
    } else {
      width =
        ((containerRef.current?.getBoundingClientRect().width || 0) * value) /
        100; // percent
      turnOn();
    }
  }

  const carouselShift = width * currentSlideIdx;
  return (
    <div
      className={setClass('cmp-carousel_wrapper', className)}
      style={{ ...style, width }}
    >
      <div
        className="cmp-carousel_conveyor"
        style={{
          transform: `translateX(-${carouselShift}px)`,
          minWidth: slides.length * width,
        }}
      >
        {slides.map((slide, idx) => (
          <div
            key={idx}
            style={{ width, minWidth: width }}
            className="cmp-carousel_one-slide-wrapper"
          >
            {slide}
          </div>
        ))}
      </div>
    </div>
  );
}

Carousel.propTypes = {
  slides: arrayOf(node).isRequired,
  width: oneOfType([number, string]).isRequired, // 576 OR '576px' OR '80%'
  currentSlideIdx: number.isRequired,
  containerRef: object,
  className: string,
  style: object,
};

Carousel.defaultProps = {
  containerRef: null,
  className: '',
  style: {},
};

export default Carousel;
