import { i18n } from 'i18n';
import React from 'react';

import { Button } from '@kandji-inc/bumblebee';

const RedownloadButton = ({ onClick }) => (
  <Button className="redownload" kind="link" onClick={onClick}>
    {i18n.t('Redownload connector')}
  </Button>
);

export default RedownloadButton;
