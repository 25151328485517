import React from 'react';

import { Button, Dialog, Flex } from '@kandji-inc/nectar-ui';

type SaveAssignmentsProps = {
  isOpen: boolean;
  toggle: (boolean) => void;
  onConfirm: () => void;
};

function SaveAssignments(props: SaveAssignmentsProps) {
  const { isOpen, toggle, onConfirm } = props;

  const footer = (
    <Flex gap="md" justifyContent="end" wrap="wrap">
      <Button variant="subtle" onClick={() => toggle(false)}>
        Cancel
      </Button>

      <Button
        variant="primary"
        onClick={() => {
          onConfirm();
          toggle(false);
        }}
      >
        Save
      </Button>
    </Flex>
  );

  return (
    <Dialog
      title="Save changes?"
      content="Scoping changes have been made in this map. This may result in installation or removal of Library Items."
      footer={footer}
      isOpen={isOpen}
      onOpenChange={() => toggle(false)}
      css={{ width: '450px', zIndex: 2000 }}
    />
  );
}

export default SaveAssignments;
