import { node, string } from 'prop-types';
import React from 'react';

function SemiBold({ children, className }) {
  return (
    <span style={{ fontWeight: 500 }} className={className}>
      {children}
    </span>
  );
}

SemiBold.propTypes = {
  children: node,
  className: string,
};

SemiBold.defaultProps = {
  className: null,
  children: null,
};

export default SemiBold;
