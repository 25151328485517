import React from 'react';

import { node } from 'prop-types';

import { AuthorityProvider } from './hooks/use-authority';
import { ConnectorProvider } from './hooks/use-connector';

const Store = ({ children }) => (
  <AuthorityProvider>
    <ConnectorProvider>{children}</ConnectorProvider>
  </AuthorityProvider>
);

Store.propTypes = {
  children: node.isRequired,
};

export default Store;
