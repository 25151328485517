import { Flex, styled } from '@kandji-inc/nectar-ui';
import React from 'react';
import type { CommonProps, InputProps } from '../types/integrator.types';
import ViewHeaderV0 from './partials/view-header-v0';
import ViewInputFields from './partials/view-input-fields';

const LayoutContainer = styled(Flex, {
  alignItems: 'center',
  flexDirection: 'column',
});

export interface OneColumnInputProps<InputType, StateType>
  extends CommonProps<InputType, StateType> {
  layout: 'one-column-input';
  header: {
    title: string;
    description: string;
  };
  inputs: Array<InputProps>;
}

const OneColumnInput = <InputType, StateType>({
  logo,
  header,
  inputs,
}: OneColumnInputProps<InputType, StateType>) => (
  <LayoutContainer>
    <ViewHeaderV0 data={{ logo, ...header }} />
    <ViewInputFields data={inputs} />
  </LayoutContainer>
);
export default OneColumnInput;
