import React from 'react';

import { node, string } from 'prop-types';

import { sidePanel as SidePanel } from '@kandji-inc/bumblebee';

import HubSpotHandler from 'components/common/hubspot-handler';

import { usePanel } from '../hooks';

const Panel = ({ name, children }) => {
  const { panel } = usePanel();

  const isOpen = panel[name];

  return (
    <>
      {isOpen && <HubSpotHandler />}
      <SidePanel size="default" isVisible={isOpen}>
        <div className="b-side-panel-layout" data-testid="k-adcs-panel">
          {children}
        </div>
      </SidePanel>
    </>
  );
};

Panel.propTypes = {
  name: string.isRequired,
  children: node.isRequired,
};

export default Panel;
