import { Toggle } from '@kandji-inc/bumblebee';
import { array, func } from 'prop-types';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import {
  eventTriggers,
  initialNotificationToggleState,
  mapNotificationsToCamelCase,
} from '../../../../../utils';

const StyledToggle = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  gap: 4rem;
`;

const EventTriggers = (props) => {
  const { value, onChange, className } = props;

  const [notifications, setNotifications] = useState(
    initialNotificationToggleState,
  );

  const handleToggle = (toggleName) => {
    setNotifications((prevState) => ({
      ...prevState,
      [toggleName]: !prevState[toggleName],
    }));

    onChange({
      ...notifications,
      [toggleName]: !notifications[toggleName],
    });
  };

  useEffect(() => {
    if (value.length) {
      const existingNotifications = mapNotificationsToCamelCase(value);
      existingNotifications.forEach((notification) => {
        setNotifications((prevState) => ({
          ...prevState,
          [notification]: true,
        }));
      });
    }
  }, [value]);

  return (
    <div className={`b-txt ${className}`}>
      {eventTriggers.map(
        ({ name, toggleName, isDisabled }) =>
          !isDisabled && (
            <StyledToggle key={name}>
              <span>{name}</span>
              <Toggle
                checked={notifications[toggleName]}
                onToggle={() => handleToggle(toggleName)}
              />
            </StyledToggle>
          ),
      )}
    </div>
  );
};

export default styled(EventTriggers)``;

EventTriggers.propTypes = {
  value: array,
  onChange: func.isRequired,
};

EventTriggers.defaultProps = {
  value: [],
};
