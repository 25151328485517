/* istanbul ignore file */
import React from 'react';

import type {
  AddIosProps,
  AddMacOsProps,
  ErrorProps,
  SetupCompleteProps,
} from '../types';
import AddDevicePlatforms from './add-device-platforms';
import AddIOS from './add-ios';
import AddMacOS from './add-mac-os';
import AddOktaVerifyLibrary from './add-okta-verify-library';
import CancelConfirm from './cancel-confirm';
import CompleteTasks from './complete-tasks';
import OktaCredentials from './okta-credentials';
import OktaCredentialsError from './okta-credentials-error';
import OktaDomain from './okta-domain';
import SetupComplete from './setup-complete';
import SignIn from './sign-in';
import Welcome from './welcome';

export const oktaSlideMap = {
  welcome: <Welcome />,
  oktaDomain: <OktaDomain />,
  signIn: <SignIn />,
  oktaCredentials: <OktaCredentials />,
  oktaCredentialsError: (props: ErrorProps) => (
    <OktaCredentialsError {...props} />
  ),
  addDevicePlatforms: <AddDevicePlatforms />,
  addMacOS: (props: AddMacOsProps) => <AddMacOS {...props} />,
  addIOS: (props: AddIosProps) => <AddIOS {...props} />,
  setupComplete: (props: SetupCompleteProps) => <SetupComplete {...props} />,
  addOktaVerifyLibrary: <AddOktaVerifyLibrary />,
  completeTasks: <CompleteTasks />,
  cancelConfirm: <CancelConfirm />,
};
