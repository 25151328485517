import { Icon } from '@kandji-inc/bumblebee';
import React from 'react';
import { components } from 'react-select';

/* Override componets for ReactSelect */
const Control = ({ children, selectProps, ...props }) => {
  const { hideSearchIcon } = selectProps;

  return (
    <components.Control {...props}>
      {!hideSearchIcon && (
        <Icon name="magnifying-glass" className="b-mr-tiny b-ml-tiny" />
      )}
      {children}
    </components.Control>
  );
};

export default Control;
