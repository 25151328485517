import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router';

import { TokenPermissionsWithDate } from 'features/enterprise-api/permissions';
import DEPIntegrationPage from '../../app/components/DEPIntegrationPage';
import MDMIntegrationPage from '../../app/components/MDMIntegrationPage';
import VPPIntegrationPage from '../../app/components/VPPIntegrationPage';
import UserPage from '../../app/components/user-portal/UserPage';

import Main from './main';

export default () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route
        path={`${path}/access/api-token/:id`}
        component={TokenPermissionsWithDate}
      />
      <Route
        path={[
          `${path}/dep-integrations/new`,
          `${path}/dep-integrations/renew`,
        ]}
        component={DEPIntegrationPage}
        exact
      />
      <Route
        path={[`${path}/integrations/new`, `${path}/integrations/renew`]}
        component={MDMIntegrationPage}
        exact
      />
      <Route
        path={`${path}/apps-and-books-token`}
        component={VPPIntegrationPage}
        exact
      />
      <Route path={`${path}/users/:user_id`} component={UserPage} exact />
      <Route path={path} component={Main} />
    </Switch>
  );
};
