import { colors, links, tabsNames } from 'app/common/constants';
import get from 'lodash/get';
import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import GSuiteUsersTable from './GSuiteUsersTable';

const UsersCounter = styled.div`
  margin-left: 5px;
  padding: 0 0.8em;
  font-family: var(--font-family-primary);
  font-weight: 700;
  font-size: 11px;
  width: max-content;
  height: 18px;
  background: ${(props) =>
    props.active ? colors['marengo-630'] : colors['marengo-120']};
  color: ${(props) => (props.active ? colors.white : colors['marengo-380'])};
  border-radius: 9px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  letter-spacing: 0;
`;

export class TabsContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      activeTab: get(props, 'match.params.tab', 'withDevices'),
    };
  }

  UNSAFE_componentWillReceiveProps(next) {
    const { activeTab } = this.state;
    if (next.match.params.tab !== activeTab) {
      this.setState({ activeTab: next.match.params.tab });
    }
  }

  renderNav = () => {
    const { gSuiteUsersCounts } = this.props;
    const { activeTab } = this.state;
    return (
      <div>
        <nav className="nav nav-secondary">
          <Link
            className={
              activeTab === 'withDevices' ? 'nav-link active' : 'nav-link'
            }
            to={links['integration-users'].withDevices}
          >
            {tabsNames.withDevices}
            <UsersCounter active={activeTab === 'withDevices'}>
              {gSuiteUsersCounts &&
                gSuiteUsersCounts.device_g_suite_users &&
                gSuiteUsersCounts.device_g_suite_users.toLocaleString()}
            </UsersCounter>
          </Link>

          <Link
            className={
              activeTab === 'withoutDevices' ? 'nav-link active' : 'nav-link'
            }
            to={links['integration-users'].withoutDevices}
          >
            {tabsNames.withoutDevices}
            <UsersCounter active={activeTab === 'withoutDevices'}>
              {gSuiteUsersCounts &&
                gSuiteUsersCounts.without_devices_g_suite_users &&
                gSuiteUsersCounts.without_devices_g_suite_users.toLocaleString()}
            </UsersCounter>
          </Link>

          <Link
            className={
              activeTab === 'archived' ? 'nav-link active' : 'nav-link'
            }
            to={links['integration-users'].archived}
          >
            {tabsNames.archived}
            <UsersCounter active={activeTab === 'archived'}>
              {gSuiteUsersCounts &&
                gSuiteUsersCounts.archived_g_suite_users &&
                gSuiteUsersCounts.archived_g_suite_users.toLocaleString()}
            </UsersCounter>
          </Link>
        </nav>
      </div>
    );
  };

  renderTab = () => {
    const { location, match, history } = this.props;
    const { activeTab } = this.state;
    const routerProps = () => ({
      location,
      match,
      history,
    });
    switch (activeTab) {
      default:
      case 'withDevices':
        return (
          <GSuiteUsersTable
            key="withDevices"
            activeTab={activeTab}
            {...routerProps()}
          />
        );
      case 'withoutDevices':
        return (
          <GSuiteUsersTable
            key="withoutDevices"
            activeTab={activeTab}
            {...routerProps()}
          />
        );

      case 'archived':
        return (
          <GSuiteUsersTable
            key="archived"
            activeTab={activeTab}
            {...routerProps()}
          />
        );
    }
  };

  render() {
    return (
      <div className="computer-tabs" style={{ justifyContent: 'flex-start' }}>
        {this.renderNav()}
        {this.renderTab()}
      </div>
    );
  }
}

export default TabsContainer;
