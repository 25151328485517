import { Box } from '@kandji-inc/nectar-ui';
import React from 'react';
import { Handle, Position } from 'reactflow';

import useBlueprintFlow from '../store';
import { getDevicePathStyle } from '../theme';
import Event from './event';

const handleStyle = { opacity: 0 };

function PreStartNode() {
  const [isEditingAssignments, { devicePath }] = useBlueprintFlow((state) => [
    state.isEditingAssignments,
    state.selectedDevice,
  ]);

  const isShowingDevicePath = !isEditingAssignments && devicePath;
  return (
    <Box
      css={
        isShowingDevicePath ? getDevicePathStyle(false, false).preStartNode : {}
      }
    >
      <Event kind="start" />
      <Handle
        type="source"
        position={Position.Right}
        isConnectable={false}
        style={handleStyle}
      />
    </Box>
  );
}

export default PreStartNode;
