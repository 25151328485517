import { TextInput } from '@kandji-inc/bumblebee';
import { AccountContext } from 'contexts/account';
import React, { useContext, useEffect, useState } from 'react';

const EnforceCustomMessage = (props) => {
  const { param, update, isDisabled, validations, validationsKey } = props;
  const { details } = param;
  const { currentCompany } = useContext(AccountContext);
  const companyName = currentCompany.name;
  const defaultBanner = `${companyName}\nThis system is reserved for authorized use only, and the use of this system may be monitored.`;
  const [value, setValue] = useState(details || defaultBanner);

  const {
    [validationsKey]: { syncInvalid },
  } = validations;

  useEffect(() => {
    if (isDisabled) {
      setValue(details);
    }
  }, [param, isDisabled]);

  useEffect(() => {
    if (!isDisabled) {
      const time = setTimeout(
        () => update({ details: value, isInvalid: !value }),
        250,
      );

      return () => clearTimeout(time);
    }
  }, [value]);

  return (
    <>
      <TextInput
        wrapperClassName="b-mb1"
        fieldsGrid
        textArea
        disabled={isDisabled}
        label="Lock message text"
        value={value}
        onChange={(e) => setValue(e.target.value)}
        validator={(v) => [
          {
            message: 'Required',
            invalid: () => v === '',
            trigger: ['onBlur', isDisabled],
          },
        ]}
        {...syncInvalid(param.isInvalid)}
      />
    </>
  );
};

export default EnforceCustomMessage;
