import { useMutation } from '@tanstack/react-query';

import { newIntegrationsService } from 'src/features/integrations/data-service/new-integrations-service';

type Channel = {
  channel_id: string;
  channel_name: string;
};

type Data = {
  channels: Channel[];
  integration_id: number;
  type: string;
};

export const useSendTestNotification = () =>
  useMutation({
    mutationFn: (data: Data) =>
      newIntegrationsService.sendTestNotification(data),
  });
