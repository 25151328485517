import { Banner } from '@kandji-inc/bumblebee';
import { i18n } from 'i18n';
import React from 'react';

const ErrorBanner = () => (
  <Banner theme="error" icon="circle-xmark" kind="normal">
    <p>
      <strong>{i18n.t('Download failed.')} </strong>
      {i18n.t('Please try again.')}{' '}
    </p>
  </Banner>
);

export default ErrorBanner;
