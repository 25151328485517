import React from 'react';
import { useModal } from './adcs/hooks';
import MSTeamsWelcomeModal from './ms-teams/modals';
import OktaWelcomeModal from './okta/modals';

// this is responsible for determining which welcome modal should be displayed
const WelcomeModalController = () => {
  const { modal } = useModal();

  // unfortunately some emphasoft components are looking at modal as an object. We need to convert it to a string, which is what the type of modal is when selected on marketplace page
  // todo: refactor useModal once emphasoft code is out of integrations
  const selectedModal = modal as string;

  const welcomeModals = {
    okta_device_trust: <OktaWelcomeModal />,
    ms_teams: <MSTeamsWelcomeModal />,
  };
  return welcomeModals[selectedModal] || null;
};

export default WelcomeModalController;
