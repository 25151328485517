import { modal as Modal } from '@kandji-inc/bumblebee';
import React from 'react';
import styled from 'styled-components';

/**
 * This componenet was made so that we could pass in classNames via styled components. This is just a styled component wrapper around the Modal component
 */

type Props = {
  className?: string;
  children: JSX.Element;
  isModalOpen: boolean;
  setIsModalOpen?: (isModalOpen: boolean) => void;
  withoutCloseButton?: boolean;
  disableCloseOnOutsideClick?: boolean;
};
const ConfigurationModal = (props: Props) => {
  const {
    isModalOpen,
    setIsModalOpen,
    className,
    children,
    withoutCloseButton = true,
    disableCloseOnOutsideClick = false,
  } = props;
  return (
    <Modal
      isOpen={isModalOpen}
      onClose={() => setIsModalOpen && setIsModalOpen(false)}
      withoutCloseButton={withoutCloseButton}
      disableCloseOnOutsideClick={disableCloseOnOutsideClick}
      parts={{
        content: {
          className,
          children,
        },
      }}
    />
  );
};

export default styled(ConfigurationModal)``;
