import uuidv4 from 'uuid/v4';
import KandjiLoginService from './service/kandji-login-service';

const initialState = {
  type: 'kandji-login',
  id: '',
  name: '',
  isActive: true,
  runs_on_mac: true,
  blueprints: [],
  selectedBlueprints: [],
  excludedBlueprints: [],
  isAllBlueprints: false,
  auth: {
    idp: '',
    mode: KandjiLoginService.authenticationMode.MAC_LOGIN,
    redirectUri: '',

    oidcBaseUrl: '',
    publicAppId: '',
    webLoginClientId: '',
    clientSecret: '',
    googleIdpCertFile: null,
    invalidationsMap: {},
  },
  provision: {
    /* String: admin|standard|based_on_user_group */
    userPermissionType: 'admin',

    /* If a user does not belong to a group below */
    /* String: local_admin|standard */
    excludedUserGroupType: 'local_admin',

    /*
    Array<{
      uuid: String,
      group: String,
      permission: String: local_admin|standard
    }>
    */
    permissionsBasedOnGroup: [{ uuid: uuidv4(), group: '', permission: '' }],
    /*
    String: never|local_user_match|always
    */
    mergeOption: 'always',

    /* Boolean: true/false */
    disableLocalUserCreation: false,
    isExcludeLocalUsers: false,
    showHiddenUsers: false,

    /*
    Array<
    {
      uuid: String,
      value: String
    }>
    */
    localUsersExcluded: [
      {
        value: '',
        uuid: uuidv4(),
      },
    ],
    invalidationsMap: {},
  },
  access: {
    idpUserAccess: 'allow_all_idp_users',

    autoIncludeIdpUser: false,

    idpUsers: [
      {
        uuid: uuidv4(),
        value: '',
      },
    ],

    /*
     * String: allow_local_users|allow_local_admins|which_local_users
     */
    localUserAccess: 'allow_local_users',

    /*
    * Array<
      {
        uuid: String,
        value: String
      }
    >
    */
    localUsers: [
      {
        uuid: uuidv4(),
        value: '',
      },
    ],

    /*
     * String: filevault_autologin_allow|filevault_autologin_disallow
     */
    autoLogin: 'filevault_autologin_disallow',
    /*
     * String: secure_store_user_password_allow|secure_store_user_password_disallow
     */
    secureStoreUserPassword: 'secure_store_user_password_allow',
    webLoginPassthrough: true,
    invalidationsMap: {},
  },
  loginScreen: {
    isLogo: false,
    logo: null,
    logoUrl: '',
    logoS3Key: '',
    isDesktopBg: false,
    desktopBg: null,
    desktopBgUrl: '',
    desktopBgS3Key: '',
    lockMessageOption: 'inherit',
    customLockMessage: '',
    policyBannerOption: 'inherit',
    policyBannerText: '',
    policyBannerFile: null,
    policyBannerFileMeta: {
      name: '',
      uploadedAt: '',
      size: 0,
    },
    policyBannerUrl: '',
    policyBannerS3Key: '',
    isNetworkManager: true,
    isShutdownButton: true,
    isRestartButton: true,
    isSleepButton: true,
    isCustomUsername: false,
    customUsername: '',
    isIncludePasswordUrl: false,
    passwordResetUrl: '',
    invalidationsMap: {},
    allowOpenWifiNetworks: false,
  },
  helpScreen: {
    header: 'Contact us',
    subheader:
      'If you are experiencing any issues logging in to your device, please contact your organization’s IT department.',
    isSerialNumber: true,
    isIpAddress: true,
    isHostName: true,
    isMacVersion: true,
    isModelInfo: true,
    invalidationsMap: {},
  },
  supportsRules: true,
};

export default initialState;
