/* istanbul ignore file */

import React, { useState } from 'react';

import { object } from 'prop-types';

import { i18n } from 'i18n';

import { Button, Chip } from '@kandji-inc/bumblebee';
import featureFlags from 'src/config/feature-flags';

import iconConnector from '../../assets/adcs-connector.svg';
import iconCaretDown from '../../assets/caret-down.svg';

import { useAuthority, useConnector, useModal, usePanel } from '../../hooks';

import Menu from '../../menu';

import { STATUS_CONNECTED, statusClass, statusLabel } from '../../status';

import Detail from './detail';
import Server from './server';

import { i18nAdcsStatusMap } from '../../../../localization';
import { configOverview } from '../../../integrator/configs/adcs/config-overview';
import { IntegratorView } from '../../../integrator/integrator-view';

const Connector = ({ connector }) => {
  const {
    id,
    name,
    status,
    bound_domain,
    windows_version,
    ip_address,
    yubico_hsm,
  } = connector;

  const { authorityList } = useAuthority();

  const { setConnector } = useConnector();

  const { openModal } = useModal();
  const { openPanel } = usePanel();

  const [expand, setExpand] = useState(false);

  const adcsIntegratorFlow = featureFlags.getFlag('paint-adcs-integrator-flow');
  const [redownloadDialog, setRedownloadDialog] = useState(false);
  const [instructionsDialog, setInstructionsDialog] = useState(false);

  /* istanbul ignore next */
  const servers = authorityList.filter(({ connector: c }) => c === id);

  const hasServers = servers.length > 0;
  const countServers = servers.length.toString();

  const toggleExpand = () => setExpand(!expand);

  const openPanelAssignServer = () => {
    setConnector(id);
    openPanel('assign-server');
  };

  const openPanelDeleteConnector = () => {
    setConnector(id);
    openPanel('delete-connector');
  };

  const openInstallInstructions = () => {
    if (adcsIntegratorFlow) {
      // need to find out more sophisticated approach to reset the state
      setInstructionsDialog(false);
      setTimeout(() => {
        setInstructionsDialog(true);
      }, 0);
    } else {
      setConnector(id);
      openModal('adcs', 2, false);
    }
  };

  const openRedownloadConnector = () => {
    if (adcsIntegratorFlow) {
      // need to find out more sophisticated approach to reset the state
      setRedownloadDialog(false);
      setTimeout(() => {
        setRedownloadDialog(true);
      }, 0);
    } else {
      setConnector(id);
      openModal('adcs', 1, false);
    }
  };

  const getConfigByAction = (action) => {
    const config = { ...configOverview };
    switch (action) {
      case 'redownload':
        config.pages = config.pages.filter((page) =>
          ['adcsRedownload', 'adcsInstructions'].includes(page.name),
        );
        break;
      case 'instructions':
        config.pages = config.pages.filter((page) =>
          ['adcsInstructions'].includes(page.name),
        );
        break;
      default:
    }

    return config;
  };

  const menu =
    status === STATUS_CONNECTED ? (
      <>
        <Button
          theme="action"
          kind="link"
          icon="circle-plus"
          onClick={openPanelAssignServer}
        >
          {i18n.t('Assign servers')}
        </Button>

        <Button
          theme="action"
          kind="link"
          icon="download"
          onClick={openRedownloadConnector}
        >
          {i18n.t('Redownload connector')}
        </Button>

        <Button
          theme="error"
          kind="link"
          icon="trash-can"
          onClick={openPanelDeleteConnector}
        >
          {i18n.t('Delete connector')}
        </Button>
      </>
    ) : (
      <>
        <Button
          theme="action"
          kind="link"
          icon="eye"
          onClick={openInstallInstructions}
        >
          {i18n.t('View install instructions')}
        </Button>

        <Button
          theme="action"
          kind="link"
          icon="arrow-down-to-line"
          onClick={openRedownloadConnector}
        >
          {i18n.t('Redownload connector')}
        </Button>

        <Button
          theme="error"
          kind="link"
          icon="trash-can"
          onClick={openPanelDeleteConnector}
        >
          {i18n.t('Delete connector')}
        </Button>
      </>
    );

  const loopServer = hasServers && expand && (
    <div className="k-adcs-connector-server-list">
      {servers.map((server) => (
        <Server key={server.id} server={server} />
      ))}
    </div>
  );

  return (
    <div className="k-adcs-connector b-library__setting-card">
      {redownloadDialog && (
        <IntegratorView config={getConfigByAction('redownload')} />
      )}
      {instructionsDialog && (
        <IntegratorView config={getConfigByAction('instructions')} />
      )}

      <header className="k-adcs-card-header k-card-header">
        <div className="k-adcs-card-icon k-card-icon">
          <img src={iconConnector} alt={i18n.t('Connector')} />
        </div>

        <div className="k-adcs-card-content k-card-content">
          <div className="k-adcs-table k-table">
            <div className="k-adcs-table-row k-table-row">
              <div className="k-adcs-table-column-name k-table-column">
                <h3 className="b-h3">
                  {name ||
                    bound_domain ||
                    `${i18n.t('Connector details are pending')}...`}
                </h3>
              </div>

              <div className="k-adcs-table-column-status k-table-column">
                <Chip
                  className={`k-adcs-status-${statusClass(status)}`}
                  text={i18nAdcsStatusMap(statusLabel(status))}
                />
              </div>

              <div className="k-adcs-table-column-action k-table-column">
                <Menu menu={menu} />
              </div>
            </div>

            <div className="k-adcs-table-row k-table-row">
              <div className="k-adcs-table-column-detail k-table-column">
                <Detail
                  name={i18n.t('Bound domain')}
                  value={bound_domain || '-'}
                />
              </div>

              <div className="k-adcs-table-column-detail k-table-column">
                <Detail
                  name={i18n.t('Windows version')}
                  value={windows_version || '-'}
                />
              </div>
            </div>

            <div className="k-adcs-table-row k-table-row">
              <div className="k-adcs-table-column-detail k-table-column">
                <Detail name={i18n.t('IP address')} value={ip_address || '-'} />
              </div>

              <div className="k-adcs-table-column-detail k-table-column">
                {false && (
                  <Detail
                    name={i18n.t('YubiCo HSM')}
                    value={yubico_hsm || '-'}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </header>

      <footer
        className={`k-adcs-card-footer k-card-footer k-card-footer-${
          expand ? 'expanded' : 'collapsed'
        }`}
      >
        <div className="k-adcs-connector-footer">
          <div className="k-adcs-table-row k-table-row">
            <div className="k-adcs-table-column-toggle k-table-column">
              {hasServers && (
                <Button className="k-action" kind="link" onClick={toggleExpand}>
                  <img
                    className={`k-caret k-caret-${expand ? 'down' : 'right'}`}
                    src={iconCaretDown}
                    alt="caret"
                  />
                </Button>
              )}
            </div>

            <div className="k-adcs-table-column-buffer k-table-column" />

            <div className="k-adcs-table-column-server k-table-column">
              <span className="b-txt-bold mr-3">
                {i18n.t('Assigned servers')}
              </span>

              <Chip
                className="adcs-integration-card_server_count"
                size="sm"
                text={countServers}
              />
            </div>
          </div>
        </div>

        {loopServer}
      </footer>
    </div>
  );
};

Connector.propTypes = {
  connector: object.isRequired,
};

export default Connector;
