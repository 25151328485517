function transformChannelsToApi(channelsAsOptions) {
  return channelsAsOptions.map((c) => ({
    channel_id: c.value,
    channel_name: c.label,
  }));
}

function transformNotificationToApi(pageData) {
  return {
    name: pageData.name,
    channels: transformChannelsToApi(pageData.channels),
    integration_id: pageData.integrationId,
    actions: pageData.actions,
  };
}

function transformChannelsToOptions(channels) {
  return channels.map((c) => ({ value: c.channel_id, label: c.channel_name }));
}

export {
  transformNotificationToApi,
  transformChannelsToApi,
  transformChannelsToOptions,
};
