import { Flex, Icon, Paragraph, styled } from '@kandji-inc/nectar-ui';
import React from 'react';
import {
  ParagraphMedium,
  StyledLink,
} from '../../../../components/styled-components/main';
import type { PageTicketProps } from '../../types/integrator.types';
import ViewHeaderH2 from './view-header-h2';

const StyledHeader = styled('div', {
  paddingBottom: '$5',
});

const StyledTicket = styled(Flex, {
  gap: '0 $4',
  paddingBottom: '$5',
});

const StyledImage = styled('img', {
  size: '$8',
});

interface Props {
  data: {
    title?: string;
    description?: string;
    list: Array<PageTicketProps>;
  };
}

const ViewTickets = ({ data }: Props) => (
  <>
    {data.title && (
      <StyledHeader>
        <ViewHeaderH2 title={data.title} description={data.description} />
      </StyledHeader>
    )}

    {data.list.map((ticket) => (
      <StyledTicket key={ticket.title}>
        <StyledImage src={ticket.icon} alt="ticket icon" />
        <div>
          <ParagraphMedium>{ticket.title}</ParagraphMedium>
          {React.isValidElement(ticket.text) ? (
            /* istanbul ignore next */
            ticket.text
          ) : (
            <Paragraph>{ticket.text}</Paragraph>
          )}

          {ticket.link && (
            <StyledLink href={ticket.link.url} rel="noreferrer" target="_blank">
              <Icon name="circle-info" size="sm" />
              &nbsp;{ticket.link.text}
            </StyledLink>
          )}
        </div>
      </StyledTicket>
    ))}
  </>
);

export default ViewTickets;
