import { useState } from 'react';
import { TYPES } from './constants';

const useSidePanel = () => {
  const [type, setType] = useState(TYPES.INACTIVE);
  const [isOpen, setIsOpen] = useState(false);
  const [currArgs, setCurrArgs] = useState();

  const openPanelType = {
    customize: (args) => {
      setType(TYPES.CUSTOMIZE);
      setIsOpen(true);
      setCurrArgs(args);
    },
    add: (args) => {
      setType(TYPES.ADD);
      setIsOpen(true);
      setCurrArgs(args);
    },
    edit: (args) => {
      setType(TYPES.EDIT);
      setIsOpen(true);
      setCurrArgs(args);
    },
    editRecommended: (args) => {
      setType(TYPES.EDIT_RECOMMENDED);
      setIsOpen(true);
      setCurrArgs(args);
    },
    editAll: (args) => {
      setType(TYPES.EDIT_ALL);
      setIsOpen(true);
      setCurrArgs(args);
    },
    delete: (args) => {
      setType(TYPES.DELETE);
      setIsOpen(true);
      setCurrArgs(args);
    },
  };

  const closePanel = (cb) => {
    setIsOpen(false);
    // Used to ensure callback to reset state happens after pane close animation
    setTimeout(() => {
      if (typeof cb === 'function') {
        cb();
      }
      setType(TYPES.INACTIVE);
    }, 0);
  };

  return {
    type,
    isOpen,
    openPanelType,
    closePanel,
    currArgs,
  };
};

export default useSidePanel;
