import { TextInput, useInvalidations } from '@kandji-inc/bumblebee';
import type { ChangeEvent } from 'react';
import React, { useState } from 'react';
import { useModalState } from 'src/features/integrations/hooks/use-modal-state';
import styled from 'styled-components';
import FooterButtons from '../../../components/footer-buttons';
import {
  Container,
  ModalContent,
  StyledHeaderImage,
  StyledSlideHeader,
} from '../../../components/styled-components';
import useModal from '../../../hooks/use-modal';
import headerIcon from '../assets/okta.svg';
import { stripUrlProtocol } from '../utils/strip-url-protocol';
import { handleOktaValidation } from '../utils/validation';
import { oktaSlideMap } from './okta-slide-map';

const StyledTextInputContainer = styled.div`
  margin-top: 24px;
  width: 360px;
  height: 95px;
`;

const OktaDomain = () => {
  const [oktaDomain, setOktaDomain] = useState('');
  const { setModalState } = useModalState();
  const { closeModal, nextModal, previousModal } = useModal();
  const { invalidations, onInvalidate } = useInvalidations({ inputs: 1 });

  const onClose = () => closeModal();
  const handleNext = () => {
    const strippedUrl = stripUrlProtocol(oktaDomain);

    setModalState({ oktaDomain: strippedUrl });
    nextModal(oktaSlideMap.signIn);
  };
  const handleBack = () => previousModal();

  const handleOnChange = (event: ChangeEvent<HTMLInputElement>) => {
    setOktaDomain(event.target.value);
  };

  const isDisabled = invalidations.some(Boolean) || oktaDomain.length > 255;

  return (
    <Container>
      <ModalContent>
        <StyledHeaderImage src={headerIcon} alt="header-icons" />
        <StyledSlideHeader>Specify your Okta Domain</StyledSlideHeader>
        <p className="b-txt-light">
          Enter the Okta domain that will be used to connect the integration.
        </p>
        <StyledTextInputContainer>
          <TextInput
            placeholder="domain.okta.com"
            onChange={handleOnChange}
            value={oktaDomain.trim()}
            onInvalidate={onInvalidate(0)}
            validator={handleOktaValidation}
            maxLength={255}
            hideMaxLength
          />
        </StyledTextInputContainer>
      </ModalContent>
      <FooterButtons
        onClose={onClose}
        handleNext={handleNext}
        backOnClick={handleBack}
        nextButtonDisabled={isDisabled}
        showBackButton
        showStepper
        stepperIdx={0}
        stepsAmount={6}
      />
    </Container>
  );
};

export default OktaDomain;
