import { integrationsBackendAxios } from 'app/api/base';
import featureFlags from 'src/config/feature-flags';
import { integrationsBackendSchema } from './types/integrations-backend.schema';

type Params = {
  page?: string;
  size?: string;
};

/**
 *This Class handles the connection to the new Integrations backend service
 *
 * @class IntegrationsBackendService
 */

class IntegrationsBackendService {
  private service: NonNullable<typeof integrationsBackendAxios>;

  private resource = 'integrations/';

  constructor() {
    // istanbul ignore if
    if (!integrationsBackendAxios) {
      throw new Error('Integrations backend service API not found');
    }

    this.service = integrationsBackendAxios;
  }

  async getIntegrations(params?: Params) {
    // TODO: remove feature flag. This is in place to prevent a breaking call since integrations-backend isn't setup on stage yet.
    const serviceNowFeatureFlag = featureFlags.getFlag('paint-servicenow');

    if (serviceNowFeatureFlag) {
      const response = await this.service(this.resource).get(params);
      return integrationsBackendSchema.parse(response.data);
    }

    return Promise.resolve({
      items: [],
      total: 0,
      page: 0,
      size: 150,
      pages: 0,
    });
  }
}

const integrationsBackendService = new IntegrationsBackendService();

export default IntegrationsBackendService;
export { integrationsBackendService };
