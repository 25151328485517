import { Box, Button, Flex, Heading, Text } from '@kandji-inc/nectar-ui';
import { useHistory } from 'react-router-dom';

import { links } from 'src/app/common/constants';

import { noDevices } from '../../assets';

const EmptyState = () => {
  const history = useHistory();

  return (
    <Flex
      hFull
      flow="column"
      alignItems="center"
      justifyContent="center"
      data-testid="am-device-empty"
    >
      <Box mb4>{noDevices}</Box>
      <Flex flow="column" alignItems="center" mb4 css={{ maxWidth: '532px' }}>
        <Heading size="4">No devices assigned to this Blueprint</Heading>
        <Text>
          Assign existing devices to this Blueprint from the Devices page or
          from individual device records. Enroll new devices to this Blueprint
          from the Enrollment page.
        </Text>
      </Flex>
      <Flex gap="sm">
        <Button
          variant="subtle"
          onClick={() => history.push(links['add-devices'])}
        >
          Go to Enrollment
        </Button>
        <Button onClick={() => history.push(links.devices)}>
          View devices
        </Button>
      </Flex>
    </Flex>
  );
};

export default EmptyState;
