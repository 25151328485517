import { Button, Select } from '@kandji-inc/bumblebee';
import React, { useEffect, useState } from 'react';
import './common.css';

const prefixOptions = [
  { label: 'None', value: 'none' },
  { label: 'Record successful events (+)', value: '+' },
  { label: 'Record failed events (-)', value: '-' },
  { label: 'Record neither successful or failed events (^)', value: '^' },
  { label: 'Do not record successful events (^+)', value: '^+' },
  { label: 'Do not record failed events (^-)', value: '^-' },
];

const flagOptions = [
  { label: 'File read (fr)', value: 'fr' },
  { label: 'File write (fw)', value: 'fw' },
  { label: 'File attribute access (fa)', value: 'fa' },
  { label: 'File attribute modify (fm)', value: 'fm' },
  { label: 'File create (fc)', value: 'fc' },
  { label: 'File delete (fd)', value: 'fd' },
  { label: 'File close (cl)', value: 'cl' },
  { label: 'Process (pc)', value: 'pc' },
  { label: 'Network (nt)', value: 'nt' },
  { label: 'IPC operations (ip)', value: 'ip' },
  { label: 'Non attributable (na)', value: 'na' },
  { label: 'Administrative', value: 'ad' },
  { label: 'Login / Logout (lo)', value: 'lo' },
  { label: 'Authentication and authorization (aa)', value: 'aa' },
  { label: 'Application (ap)', value: 'ap' },
  { label: 'ioctl (io)', value: 'io' },
  { label: 'Program execution (ex)', value: 'ex' },
  { label: 'Miscellaneous (or)', value: 'or' },
  { label: 'All flags set (all)', value: 'all' },
];

const SecurityAuditingFlags = (props) => {
  const { param, update, isDisabled, validations } = props;
  const { details } = param;
  const [value, setValue] = useState(details || []);

  const {
    SecurityAuditingFlags: { displayInvalid },
  } = validations;

  useEffect(() => {
    if (isDisabled) {
      setValue(details);
    }
  }, [param, isDisabled]);

  useEffect(() => {
    if (!isDisabled) {
      update({ details: value, isInvalid: !value.length });
    }
  }, [value]);

  const remainingFlags = flagOptions.filter(
    (f) => !value.find((d) => d.flag === f.value),
  );

  const onAdd = () =>
    setValue((prev) => {
      return [
        ...(prev || []),
        {
          prefix: prefixOptions[0].value,
          flag: remainingFlags[0].value,
        },
      ];
    });

  const onTrash = (id) =>
    setValue((prev) => [...prev.filter((_, idx) => idx !== id)]);

  return (
    <div className="">
      <div className="b-flex-vg1 b-mb1">
        {value.map((data, idx) => {
          return (
            <div
              key={`${data.prefix}_${data.flag}`}
              className="b-flex-vc b-flex-g1"
            >
              <div className="b-flex-vg1 b-flex1">
                <p className={`b-txt${isDisabled ? '-light2' : ''}`}>Prefix:</p>
                <Select
                  options={prefixOptions}
                  disabled={isDisabled}
                  value={prefixOptions.find((p) => p.value === data.prefix)}
                  onChange={(v) =>
                    setValue((prev) => {
                      prev[idx].prefix = v.value;
                      return [...prev];
                    })
                  }
                />
              </div>
              <div className="b-flex-vg1 b-flex1">
                <p className={`b-txt${isDisabled ? '-light2' : ''}`}>Flag:</p>
                <Select
                  options={remainingFlags}
                  disabled={isDisabled}
                  value={flagOptions.find((p) => p.value === data.flag)}
                  onChange={(v) =>
                    setValue((prev) => {
                      prev[idx].flag = v.value;
                      return [...prev];
                    })
                  }
                />
              </div>
              <Button
                className="bl-p-align-self-end"
                disabled={isDisabled}
                kind="link"
                icon="trash-can"
                theme="error"
                onClick={() => onTrash(idx)}
              />
            </div>
          );
        })}
      </div>
      <Button
        disabled={!remainingFlags.length || isDisabled}
        onClick={onAdd}
        icon="plus"
      >
        Add
      </Button>
      {displayInvalid(!value.length)}
    </div>
  );
};

export default SecurityAuditingFlags;
