import {
  Body,
  Button,
  ButtonGroup,
  Flex,
  Heading,
  IconButton,
  Tooltip,
  TooltipProvider,
  styled,
  theme,
} from '@kandji-inc/nectar-ui';
import { useHistory } from 'react-router';
import { links } from 'src/app/common/constants';
import type { DeviceCounts } from './hooks/useGetADEDeviceCount';
import { formatDeviceCount } from './utils/formatDeviceCount';

const ADEListViewHeaderContainer = styled('div', {
  padding: '$4 $5 0 $5',
});

const ADEListViewTitle = styled('div', {});

const ADEListViewTableSwitch = styled('div', {
  'margin-left': 'auto',
});

const ADETableHeaderIconButton = styled(IconButton, {
  width: '36px',
  height: '36px',
  color: theme.colors.button_subtle_icon,
  '&:hover': {
    background: theme.colors.button_subtle_background_hover,
  },
  '&[data-state="open"], &[data-state="delayed-open"]': {
    background: theme.colors.button_subtle_background_hover,
  },
  variants: {
    compact: {
      true: {
        p: '$1',
        width: '28px',
        height: '28px',
      },
    },
  },
});

const DeviceTooltipContainer = styled('div', {
  fontSize: 14,
  position: 'relative',
  width: 246,
  display: 'flex',
  gap: 4,
  alignItems: 'flex-start',
});

export function DeviceTooltipContent({ onClose }): React.ReactNode {
  const history = useHistory();
  return (
    <DeviceTooltipContainer>
      <Body>
        Devices assigned to Apple Business Manager. To see your Automated Device
        Enrollment token details, view your
        <Button
          variant="link"
          onClick={() => {
            history.push(links.myCompany.integrations);
          }}
          style={{ fontSize: 14 }}
        >
          Apple Integration settings
        </Button>
        .
      </Body>
      <Button
        icon={{ name: 'fa-xmark-small' }}
        compact
        css={{
          backgroundColor: 'transparent',
          padding: 0,
        }}
        onClick={onClose}
      />
    </DeviceTooltipContainer>
  );
}

export interface ADEListViewHeaderProps {
  isAllDevices: boolean;
  setIsAllDevices: (isAllDevices: boolean) => void;
  deviceCounts: DeviceCounts;
}

export function ADEListViewHeader({
  isAllDevices,
  setIsAllDevices,
  deviceCounts,
}: ADEListViewHeaderProps) {
  const { all: allDevicesCount, awaitingEnrollment: awaitingEnrollmentCount } =
    deviceCounts ?? {};

  return (
    <ADEListViewHeaderContainer>
      <Flex alignItems="center" css={{ padding: '$1 0' }}>
        <ADEListViewTitle>
          <Flex alignItems="center" gap="xs">
            <Heading as="h3" size="2" css={{ color: '$neutral90' }}>
              Devices
            </Heading>
            <TooltipProvider>
              <Tooltip
                side="bottom"
                content={(onClose) => (
                  <DeviceTooltipContent onClose={onClose} />
                )}
                shadow="shadow"
                theme="light"
                css={{ zIndex: 5, padding: '12px 16px', color: '$neutral90' }}
                maxWidth="320px"
                align="start"
                trigger="click"
                interactive
              >
                <ADETableHeaderIconButton icon="circle-info" />
              </Tooltip>
            </TooltipProvider>
          </Flex>
        </ADEListViewTitle>
        <ADEListViewTableSwitch>
          <ButtonGroup
            compact
            variant="inverse"
            buttons={[
              {
                label: `Awaiting enrollment (${formatDeviceCount(
                  awaitingEnrollmentCount,
                )})`,
                selected: !isAllDevices,
                onClick: () => setIsAllDevices(false),
              },
              {
                label: `All (${formatDeviceCount(allDevicesCount)})`,
                selected: isAllDevices,
                onClick: () => setIsAllDevices(true),
              },
            ]}
          />
        </ADEListViewTableSwitch>
      </Flex>
    </ADEListViewHeaderContainer>
  );
}
