import styled from 'styled-components';

export const LabelInput = styled('div')`
  font-family: var(--font-family-primary);
  font-size: 14px;
  font-weight: 600;
  line-height: 17px;
  color: #1a1d25;
  margin-top: 28px;
  margin-bottom: 10px;
  width: 100%;
`;

export const LabelSubInput = styled('span')`
  font-weight: 400;
  opacity: 0.5;
`;

export const TextInput = styled('input')`
  font-family: var(--font-family-primary);
  appearance: none;
  outline: none;
  background: #ffffff;
  border: 2px solid ${(props) => (props.error ? 'red' : '#e5e5e5')};
  box-sizing: border-box;
  padding: 2px 15px 0 15px;
  border-radius: 4px;
  height: 40px;
  width: ${(props) =>
    props.style && props.style.width ? props.style.width : '400px'};
  &::placeholder {
    opacity: 0.3;
  }
  :focus {
    border: 2px solid #1a1d25;
  }
`;

export const CardHeaderTitle = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;

  font-family: var(--font-family-primary);
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.2em;
  text-transform: uppercase;
  font-weight: bold;
  padding-bottom: 11px;
  margin-bottom: 24px;
  border-bottom: 2px solid rgba(26, 29, 37, 0.05);
`;
