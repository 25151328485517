import { Chip, Icon, Toggle, setClass } from '@kandji-inc/bumblebee';
import { deviceTypes, osPrefixes } from 'features/library-items/library/common';
import React from 'react';
import './blueprint-parameter-item.css';
import { parameterComponents, parametersWithAlerts } from './parameter-details';

const BlueprintParameterItem = (props) => {
  const {
    parameter,
    blueprint,
    selectedParameter,
    onToggle,
    isEnabled,
    isMuted,
    canToggle,
    update,
    filter,
    validations,
  } = props;
  const {
    id,
    name,
    description,
    tags,
    dependency,
    warning,
    compatibility,
    beta,
  } = parameter;

  const hasAlert = parametersWithAlerts[id];
  const ParameterComponent = isEnabled && parameterComponents[id];

  return (
    <div className="bl-blueprint-parameter-item">
      <div className="b-flex-col b-flex-vgmicro b-mr-micro bl-blueprint-fill-width">
        <div className="b-flex-vc">
          <h3
            className="b-h3"
            data-testid="bl-blueprint-parameters-item-header"
          >
            {name}{' '}
          </h3>
          {beta && <Chip className="b-ml1" text="BETA" kind="primary-bright" />}
        </div>
        <p className="b-txt-light">{description}</p>
        <div className="bl-blueprint-parameter-item__info-grid">
          {warning && (
            <>
              <p className="b-txt-light b-txt-bold b-mr-micro">Warning: </p>
              <p className="b-txt-light">{warning}</p>
            </>
          )}
          {dependency && (
            <>
              <p className="b-txt-light b-txt-bold b-mr-micro">Dependency: </p>
              <p className="b-txt-light">{dependency.name}</p>
            </>
          )}
        </div>
        <div className="b-flex-gtiny">
          {compatibility && (
            <Chip
              kind="tertiary-light"
              text={`${osPrefixes[deviceTypes.MAC]} ${compatibility}`}
            />
          )}
          {tags
            .filter((tag) => !tag.hidden)
            .sort((a, b) => a.name.localeCompare(b.name))
            .map((tag) => (
              <Chip
                key={tag.name}
                kind={
                  !filter?.isAllFrameworks &&
                  filter?.frameworks?.find(
                    (frameworkId) => frameworkId === tag.id,
                  )
                    ? 'primary'
                    : 'tertiary-light'
                }
                text={tag.name}
              />
            ))}
        </div>
        {ParameterComponent && (
          <div className="b-mt1">
            <ParameterComponent
              isDisabled={!canToggle}
              param={selectedParameter}
              update={update}
              blueprint={blueprint}
              validations={validations}
            />
          </div>
        )}
      </div>
      <div className="bl-blueprint-parameter-item__toggle">
        <div
          className={setClass(
            'bl-blueprint-parameter-item__alert',
            'b-flex-hc',
            'b-mr-tiny',
            canToggle && '--editable',
            (!hasAlert || !isEnabled) && '--hidden',
          )}
          onClick={() => {
            if (hasAlert && canToggle) {
              onToggle(isEnabled, !isMuted);
            }
          }}
        >
          {isMuted ? <Icon name="bell-slash" /> : <Icon name="bell" />}
        </div>
        <Toggle disabled={!canToggle} checked={isEnabled} onToggle={onToggle} />
      </div>
    </div>
  );
};

export default BlueprintParameterItem;
