/* istanbul ignore file */
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { v4 as uuid } from 'uuid';

import featureFlags from 'src/config/feature-flags';
import { useGetLibrary } from '../../library-items/data-service/library/useGetLibraryItems';
import { ORIGIN_TYPES } from '../constants';
import useBlueprintFlow from '../store';
import { transformLibraryItemsFromApi } from './library-items';

const useFlowLibraryItems = () => {
  const { id } = useParams<{ id?: string }>();
  const setLibraryItems = useBlueprintFlow((state) => state.setLibraryItems);
  const setIsLoadingLibraryItems = useBlueprintFlow(
    (state) => state.setIsLoadingLibraryItems,
  );
  const libraryItemFilter = useBlueprintFlow(
    (state) => state.libraryItemFilter,
  );
  const { data, isLoading, isFetching, isError } = useGetLibrary({
    loc: 'webList',
    keys: ['flow-library', libraryItemFilter, id],
    // Unused apiParams for now, can be re-added later when webList provides
    // support and we decide to opt-in.
    // apiParams: {
    //   limit: 10000,
    //   q: libraryItemFilter.term,
    //   sort_by: sortMap[libraryItemFilter.sort],
    //   ...devicesToRunsOn(libraryItemFilter.devices),
    // },
    options: {
      refetchOnWindowFocus: true,
    },
  });

  const hiddenLibraryItemsFeatureFlag = featureFlags.getFlag(
    'dc_03292024_am-hidden-bank-library-items',
  );
  const hiddenLibraryItemTypes = hiddenLibraryItemsFeatureFlag.types;
  const hiddenLibraryItemNames = hiddenLibraryItemsFeatureFlag.names;

  useEffect(() => {
    if (data) {
      const transformed = transformLibraryItemsFromApi((data as any)?.data)
        ?.map((item) => ({
          ...item,
          flowId: uuid(),
          origin: ORIGIN_TYPES.bank,
        }))
        .filter(
          (item) =>
            // Filter out inactive Library Items
            item.active &&
            // Filter out Library Items that are temporarily hidden behind a feature flag
            !hiddenLibraryItemTypes.includes(item.type) &&
            !hiddenLibraryItemNames.includes(item.name),
        );

      setLibraryItems(transformed, isLoading || isFetching);
    }
  }, [data]);

  useEffect(() => {
    setIsLoadingLibraryItems(isLoading || isFetching);
  }, [isLoading, isFetching]);

  return {
    isLoading: isLoading || isFetching,
    isError,
  };
};

export default useFlowLibraryItems;
