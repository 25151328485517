/* istanbul ignore file */
import React from 'react';

export function useFocusInput(dependencies: any[]) {
  const ref = React.useRef(null);

  React.useEffect(() => {
    const focusTimeout = setTimeout(() => {
      ref?.current?.focus();
    }, 10);

    return () => {
      clearTimeout(focusTimeout);
    };
  }, [dependencies]);

  return ref;
}
