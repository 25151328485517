import { Icon } from '@kandji-inc/nectar-ui';
import type React from 'react';
import styled from 'styled-components';

import { Tooltip } from 'app/components/interface/tooltips/Tooltip';

type IconProps = React.ComponentProps<typeof Icon>;

type Props = {
  label: string;
  tooltipMessage: string;
  placement?: string;
  iconName?: IconProps['name'];
  size?: IconProps['size'];
};

const StyledLabel = styled.label`
  display: flex;
  gap: var(--b-gap-tiny);
`;

const StyledFontIcon = styled(Icon)`
  color: var(--b-icon-blue);
  display: inline;
`;

const LabelTooltip = (props: Props) => {
  const {
    label,
    placement = 'right',
    tooltipMessage,
    iconName = 'circle-exclamation',
    size = 'sm',
  } = props;
  return (
    <StyledLabel>
      {label}
      <Tooltip placement={placement} tooltipMessage={tooltipMessage}>
        <StyledFontIcon name={iconName} size={size} />
      </Tooltip>
    </StyledLabel>
  );
};

export default LabelTooltip;
