import { useQuery } from '@tanstack/react-query';
import { msTeamsService } from '../services/teams';

const useMSTeamsTeams = (integrationId: number) =>
  useQuery({
    queryKey: ['msTeamsTeams', integrationId],
    queryFn: () => msTeamsService.getTeams(integrationId),
    enabled: !!integrationId,
  });
export default useMSTeamsTeams;
