import { Toaster as toaster } from '@kandji-inc/bumblebee';
import { Paragraph, Text } from '@kandji-inc/nectar-ui';
import useOktaDeviceTrustPlatform from 'features/integrations/components-for-specific-types/okta/hooks/use-okta-device-trust-platform';
import { oktaDeviceTrustService } from 'features/integrations/components-for-specific-types/okta/services';
import FooterButtons from 'features/integrations/components/footer-buttons';
import { i18n } from 'i18n';
/* istanbul ignore file */
import React from 'react';
import { useHistory } from 'react-router-dom';
import type { DeleteIntegrationProps } from 'src/features/integrations/components-for-specific-types/okta/types';
import styled from 'styled-components';
import { useGetOktaDeviceTrust } from '../../hooks';
import exclamationTriangle from '../assets/exclamation-triangle.svg';

const StyledTextInputContainer = styled.div`
  text-align: left;
`;

const StyledHeader = styled.h1`
  text-align: left;
  padding-left: 32px;
  padding-top: 48px;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  margin-bottom: 16px;
`;

export const StyledContainer = styled.div`
  width: 560px;
  height: 268px;
`;

const StyledWarning = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  width: 399px;
  margin-left: 8px;
`;

const StyledImage = styled.img`
  margin-left: 12px;
`;

const StyledWarningContainer = styled.div`
  width: 496px;
  background-color: #fffae6;
  display: flex;
  margin-left: 32px;
  margin-top: 24px;
`;

const DeleteIntegration = (props: DeleteIntegrationProps) => {
  const { oktaDeviceTrustId, onClose } = props;
  const { isLoading } = useOktaDeviceTrustPlatform();
  const { getOktaDeviceTrustDetails } = useGetOktaDeviceTrust();
  const history = useHistory();

  const handleNext = async () => {
    if (oktaDeviceTrustId) {
      try {
        await oktaDeviceTrustService.deleteOktaDeviceTrust(oktaDeviceTrustId);
        toaster(i18n.t('Integration successfully deleted'));
        await getOktaDeviceTrustDetails();
        onClose();
        history.push('/integrations');
      } catch (e) {
        if (e instanceof Error) {
          toaster(i18n.t('Unable to delete integration'));
          onClose();
        }
      }
    }
  };

  const closeModal = async () => {
    onClose();
  };

  return (
    <>
      <StyledContainer>
        <StyledTextInputContainer>
          <StyledHeader>{i18n.t('Delete Okta Device Trust')}</StyledHeader>
          <Paragraph css={{ paddingLeft: '32px' }}>
            {i18n.t(
              'The integration with {odt} will be permanently removed. This action cannot be undone.',
              {
                odt: (
                  <Text
                    css={{ fontWeight: '$medium', display: 'inline-block' }}
                  >
                    {i18n.t('Okta Device Trust')}
                  </Text>
                ),
              },
            )}
          </Paragraph>
          <StyledWarningContainer>
            <StyledImage
              src={exclamationTriangle}
              alt={i18n.t('exclamation triangle icon')}
            />
            <StyledWarning>
              {i18n.t(
                'This integration is a requirement to issue Okta Device Trust certificates to your Apple fleet.',
              )}
            </StyledWarning>
          </StyledWarningContainer>
        </StyledTextInputContainer>
      </StyledContainer>
      <FooterButtons
        onClose={closeModal}
        handleNext={handleNext}
        cancelButtonText={i18n.t('Cancel')}
        nextButtonText={i18n.t('Delete integration')}
        showBackButton={false}
        showStepper={false}
        stepperIdx={4}
        stepsAmount={6}
        theme="danger"
        isWorking={isLoading}
      />
    </>
  );
};

export default DeleteIntegration;
