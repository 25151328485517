import { Banner, Button, Select } from '@kandji-inc/bumblebee';
import React, { useState } from 'react';

import HubSpotHandler from 'components/common/hubspot-handler';

const DeletePanel = (props) => {
  const {
    setBody,
    handlers,
    model,
    currArgs: { conflicts, category },
  } = props;
  const { closePanel, openPanelType, onDelete } = handlers;
  const [value, setValue] = useState();
  const [isLoading, setIsLoading] = useState();
  const options = model.categories
    .filter((cat) => cat.id !== category?.id)
    .map((cat) => ({
      label: cat.name,
      value: cat.id,
    }));

  const onDone = () => {
    setIsLoading(true);
    onDelete(category.id, value.value)
      .then(closePanel)
      .finally(() => setIsLoading(false));
  };

  return (
    <>
      <HubSpotHandler />
      <div className="b-side-panel-layout__body" ref={setBody}>
        <p className="b-txt b-mb3">
          This category is assigned to Library Items available in Self Service.
          Reassign them to a new category below before deleting.
        </p>
        <p className="b-txt b-mb1">Reassign Library Items to</p>
        <Select
          className="b-mb2"
          options={options}
          value={value}
          onChange={setValue}
        />
        <Banner theme="info" kind="block">
          <p>
            <b className="b-txt-bold">{conflicts?.length} Library Items</b> will
            be re-assigned
          </p>
        </Banner>
      </div>
      <div className="b-side-panel-layout__footer">
        <div className="b-flex-btw">
          <Button
            kind="link"
            onClick={() => openPanelType.edit(model.addEditCategory)}
          >
            Back to edit
          </Button>
          <div className="b-grid-ctas">
            <Button
              disabled={!value}
              isProgress={isLoading}
              icon={isLoading ? 'arrows-rotate' : ''}
              theme="error"
              onClick={onDone}
            >
              Delete
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default DeletePanel;
