import moment from 'moment-timezone';

const relativeFormat = (preferredFormat = null) => ({
  lastDay: '[Yesterday]',
  sameDay(now) {
    const diff = moment.duration(now.diff(this));
    if (parseInt(diff.asHours(), 10) > 1) {
      return `[${parseInt(diff.asHours(), 10)} hours ago]`;
    }
    if (parseInt(diff.asHours(), 10) === 1) {
      return `[${parseInt(diff.asHours(), 10)} hour ago]`;
    }
    if (parseInt(diff.asMinutes(), 10) > 1) {
      return `[${parseInt(diff.asMinutes(), 10)} minutes ago]`;
    }
    if (parseInt(diff.asMinutes(), 10) === 1) {
      return '[1 minute ago]';
    }
    return '[less than 1 minute ago]';
  },
  nextDay: '[Tomorrow]',
  lastWeek: preferredFormat || 'L',
  nextWeek: preferredFormat || 'L',
  sameElse: preferredFormat || 'L',
});

const formatTime = (
  timestamp,
  userSettings,
  hideSeconds,
  errorMessage,
  dateOnly = false,
  pass_relativity = false,
  format = 'MM/DD/YYYY',
) => {
  let momentObject;
  if (typeof timestamp === 'number') {
    momentObject = moment.unix(Number(timestamp.toString().substring(0, 10)));
  } else {
    momentObject = moment(timestamp);
  }

  const preferredFormat = userSettings?.preferred_date_format || format;
  const userTimezone =
    userSettings?.timezone || Intl.DateTimeFormat().resolvedOptions().timeZone;
  const disable_relativity = userSettings?.disable_relative_dates === 'disable';

  if (disable_relativity || pass_relativity) {
    return momentObject
      .tz(userTimezone)
      .format(hideSeconds ? preferredFormat : `${preferredFormat} [at] LTS`);
  }

  if (!momentObject.isValid()) {
    return errorMessage || 'Invalid timestamp format';
  }
  if (dateOnly) {
    return momentObject.tz(userTimezone).format(preferredFormat);
  }
  return momentObject
    .tz(userTimezone)
    .calendar(null, relativeFormat(preferredFormat));
};

export { relativeFormat, formatTime };
