/* istanbul ignore file */
import { useParams } from 'react-router-dom';
import { useReactFlow } from 'reactflow';
import { useShallow } from 'zustand/react/shallow';

import { useToast_UNSTABLE as useToast } from '@kandji-inc/nectar-ui';

import type { ValidationErrors } from '../blueprint-flow.types';

import {
  ASSIGNMENT_TYPES,
  NODE_TYPES,
  NOTIFICATION_POSITIONS,
} from '../constants';
import { getAssignmentTypeAndPeers } from '../helpers';
import useBlueprintFlow from '../store';

const useValidation = () => {
  const { getNode, getNodes } = useReactFlow();
  const [setNotificationPosition, setValidationErrors] = useBlueprintFlow(
    useShallow((state) => [
      state.setNotificationPosition,
      state.setValidationErrors,
    ]),
  );
  const { toast } = useToast();
  const { id } = useParams<{ id?: string }>();

  /**
   * Displays a Nectar Toast in the bottom-left corner unless otherwise specified
   * @param toastProps the props handed directly to Nectar's Toast (see Nectar's `Toast Props` for reference)
   * @param position where the toast should be placed
   */
  const displayToast = (toastProps: object, position?: string) => {
    const defaultPosition = NOTIFICATION_POSITIONS['bottom-left'];

    setNotificationPosition(position || defaultPosition);
    toast(toastProps);
  };

  /**
   * Displays appropriate error message toasts using validation errors
   * @param updatedValidationErrors the newest validation errors to use when displaying error messages
   */
  const showErrorMessages = (updatedValidationErrors: ValidationErrors) => {
    const isUpdating = !!id;
    const numAssignmentNodesWithoutRules =
      updatedValidationErrors.assignmentNodesWithoutRules.length;

    if (numAssignmentNodesWithoutRules > 0) {
      displayToast(
        {
          title: `You have ${numAssignmentNodesWithoutRules} assignment node${
            numAssignmentNodesWithoutRules > 1 ? 's' : ''
          } without rules.`,
          content:
            'Please enter rules for the assignment nodes or delete them in order to save.',
          variant: 'error',
        },
        NOTIFICATION_POSITIONS.top,
      );
    } else {
      displayToast({
        title: `Failed to ${isUpdating ? 'update' : 'create'} Blueprint.`,
        variant: 'error',
      });
    }
  };

  /**
   * Retrieves the ids of 'if' and 'else if' Assignment nodes missing assignment rules
   * @param flow - a React Flow object with `nodes`
   * @returns - the ids of 'if' Assignment nodes missing assignment rules
   */
  const getAssignmentNodesWithoutRules = () => {
    const nodes = getNodes();
    const missingAssignmentNodes = [];

    nodes.forEach((node) => {
      // Check for rules on Assignment nodes
      if (node.type === NODE_TYPES.assignment) {
        const { assignmentType } = getAssignmentTypeAndPeers(
          nodes,
          getNode(node.id),
        );

        // Only check 'if' and 'else if' Assignment nodes
        if (
          assignmentType !== ASSIGNMENT_TYPES.else &&
          node.data?.rules === null
        ) {
          missingAssignmentNodes.push(node.id);
        }
      }
    });

    return missingAssignmentNodes;
  };

  /**
   * Determines whether or not a Blueprint is valid and updates internal state
   * @param shouldShowErrorMessages whether or not toasts with error messages should appear
   * @returns boolean - whether or not the provided Blueprint is valid
   */
  const runValidation = (shouldShowErrorMessages = false) => {
    const assignmentNodesWithoutRules = getAssignmentNodesWithoutRules();

    const newValidationErrors = {
      assignmentNodesWithoutRules,
    };

    setValidationErrors(newValidationErrors);

    const isValid = assignmentNodesWithoutRules.length === 0;

    if (!isValid && shouldShowErrorMessages) {
      showErrorMessages(newValidationErrors);
    }

    return isValid;
  };

  return { runValidation };
};

export default useValidation;
