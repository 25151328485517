import { i18n } from 'i18n';
/* istanbul ignore file */
import React, { useContext, memo } from 'react';
import { useOldField, useOldForm } from 'src/old-honey-form';
import { Button } from 'theme/components/molecules/Action';
import useIsFormChanged from '../../hooks/useIsFormChanged';
import { Context } from './context';

export default memo(() => {
  const isChanged = useIsFormChanged();
  const { isWorking } = useContext(Context);
  const { handleSubmit } = useOldForm();
  const isSubmittedField = useOldField('__isSubmitted');
  const onClick = () => {
    handleSubmit();
    isSubmittedField.setValue(true);
  };

  return (
    <Button
      tier="one"
      label={i18n.t('Save')}
      disabled={isWorking || !isChanged}
      onClick={onClick}
      loader={isChanged}
    />
  );
});
