import { Flex, styled } from '@kandji-inc/nectar-ui';
import { i18n } from 'i18n';
import React from 'react';
import { StyledContainer as container } from '../../../../components/styled-components/main';
import ViewHeaderH2 from './view-header-h2';

const StyledRow = styled(Flex, {
  width: '100%',
  gap: '$3',
});

const StyledCol = styled('div', {
  flex: '1',
  '&.fixed': {
    flex: '0 0 150px',
  },
});

const StyledContainer = styled(container, {
  gap: '$3',
  width: '100%',
});

interface List {
  name: string;
  value: string;
}
interface Props {
  title?: string;
  list: Array<List>;
}

const ViewRows = ({ title = i18n.t('Account Information'), list }: Props) => (
  <StyledContainer>
    <ViewHeaderH2 title={title} />

    {list.map((v) => (
      <StyledRow key={v.name}>
        <StyledCol className="fixed">{v.name}</StyledCol>
        <StyledCol>{v.value}</StyledCol>
      </StyledRow>
    ))}
  </StyledContainer>
);

export default ViewRows;
