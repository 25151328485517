/* istanbul ignore file - legacy code, moved */
import styled from 'styled-components';

export const Wrapper = styled('section')`
  margin: 20px 0;
`;

export const WhiteCard = styled('div')`
  background: white;
  border-radius: 4px;
  position: ${(props) => (props.sticky ? 'sticky' : 'relative')};
  bottom: ${(props) => (props.sticky ? '0' : 'after')};
  z-index: ${(props) => (props.sticky ? 2 : 'auto')};
  padding: 20px 30px 30px 30px;
  margin-bottom: 20px;
`;

export const FixedBottomLine = styled('div')`
  z-index: 150;
  background: white;
  box-shadow: 0px -4px 5px rgba(0, 0, 0, 0.04);
  position: fixed;
  bottom: 0;
  width: ${(props) =>
    props.sidebarDocked ? 'calc(100vw - 256px)' : 'calc(100vw - 77px)'};
  margin-left: ${(props) => (props.sidebarDocked ? '-50px' : '-46px')};
  padding: 0 50px;

  height: 64px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

export const WhiteCardHeader = styled(WhiteCard)`
  padding: 0;
  display: flex;
  flex-direction: column;
  margin-bottom: 37px;
`;

export const WhiteCardHeaderTop = styled('div')`
  height: 76px;
  padding: 13px 30px;
  display: grid;
  grid-template-areas: 'icon name status toggle';
  grid-template-rows: 50px;
  grid-template-columns: 50px 400px auto 33px;
  grid-gap: 10px;
  align-items: center;
  border-bottom: 1px solid #dee1ea;
`;

export const WhiteCardHeaderBottom = styled('div')`
  padding: 30px 33px 30px 30px;
  display: grid;
  row-gap: 20px;
  grid-template-areas: ${(props) =>
    props.banner
      ? '"description info" "description banner" "blueprints blueprints"'
      : '"description info" "bannerwide bannerwide" "blueprints blueprints"'};
  grid-column-gap: 20px;
  grid-template-rows: auto;
  grid-template-columns: 490px auto;

  @media only screen and (min-width: 1024px) {
    grid-template-columns: ${(props) =>
      props.sidebarOpen ? '490px auto' : '570px auto'};
  }

  @media only screen and (min-width: 1366px) {
    grid-template-columns: ${(props) =>
      props.sidebarOpen ? '570px auto' : '716px auto'};
  }

  @media only screen and (min-width: 1920px) {
    grid-template-columns: 802px auto;
  }
`;

export const ApplicationName = styled('div')`
  grid-area: name;
  font-family: var(--font-family-primary);
  font-size: 30px;
  font-weight: bold;
`;

export const ApplicationNameInput = styled('input')`
  grid-area: name;
  appearance: none;
  outline: none;
  border: none;
  border-bottom: 2px solid ${(props) => (props.error ? 'red' : '#1A1D25')};
  font-family: var(--font-family-primary);
  color: #1a1d25;
  font-size: 24px;
  line-height: 32px;
  font-weight: 600;
  width: 400px;
  &::placeholder {
    opacity: 0.15;
  }
`;

export const ApplicationDescription = styled('div')`
  grid-area: description;
  font-family: var(--font-family-primary);
  font-size: 14px;
  line-height: 22px;
  color: #76777c;
  margin-left: 63px;
  width: 350px;
  @media only screen and (min-width: 1366px) {
    width: ${(props) => (props.sidebarOpen ? '350px auto' : '400px auto')};
  }
  @media only screen and (min-width: 1920px) {
    width: 600px;
  }
`;

export const ApplicationInfo = styled('div')`
  grid-area: info;
  display: grid;
  grid-template-rows: repeat(5, max-content);
  grid-template-columns: max-content max-content;
  column-gap: 39px;
`;

export const ApplicationBanner = styled('div')`
  grid-area: bannerwide;
  display: grid;
  p {
    font-family: var(--font-family-primary);
    font-weight: 400;
    font-size: 14px;
    line-height: 25px;
    color: #1a1d2599;
  }
`;

const InfoKey = styled('div')`
  font-family: var(--font-family-primary);
  font-size: 14px;
  line-height: 30px;
  color: rgba(26, 29, 37, 0.6);
`;

const ApplicationInfoValue = styled('div')`
  font-family: var(--font-family-primary);
  font-size: 14px;
  line-height: 30px;
  color: #1a1d25;
  font-weight: 400;
`;

ApplicationInfo.Key = InfoKey;
ApplicationInfo.Value = ApplicationInfoValue;

export const Nav = styled('nav')`
  text-transform: capitalize;
  border-bottom: 1px solid #b8bdca;
  .nav-link {
    color: rgba(26, 29, 37, 0.65);
    text-transform: capitalize;
    font-size: 14px;
    line-height: 18px;
    font-weight: 500;
    &.active,
    &:hover {
      font-weight: 600;
      box-shadow: 0 2px 0 0 black;
      color: #1d212a;
    }
  }
`;

export const SmallName = styled('div')`
  font-family: var(--font-family-primary);
  font-size: 14px;
  line-height: 30px;
  font-weight: 500;
  color: #1a1d25;
`;

export const Wrapper2 = styled('div')`
  display: grid;
  grid-template-areas: 'checkbox text';
  grid-template-columns: 35px auto;
  margin-top: ${(props) => (props.withSmallTopMargin ? '18px' : '28px')};
`;

export const CheckBox = styled('input')`
  grid-area: checkbox;
  cursor: pointer;
  position: relative;
  width: 21px;
  height: 21px;
  appearance: none;
  background: #ffffff;
  border: 2px solid ${(props) => (props.error ? 'red' : '#e5e5e5')};
  border-radius: 1px;
  outline: none;
  transition:
    background 0.3s,
    border 0.3s;
  opacity: ${(props) => (props.isDependenceDisable ? '.5' : '1')};
  &:disabled {
    cursor: default;
    opacity: 0.6;
  }
  &:checked {
    background: #1a1d25;
    border: 2px solid #1a1d25;
    &:after {
      content: '\f00c';
      font-family: 'Font Awesome\ 5 Pro', sans-serif;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: white;
      font-size: 11px;
    }
  }
`;

export const TextInputLabel = styled('div')`
  font-family: var(--font-family-primary);
  font-size: 14px;
  font-style: normal;
  font-weight: normal;
  line-height: 24px;
  padding-left: 10px;
  color: #1a1d25;
`;

export const TextInputLabelBefore = styled(TextInputLabel)`
  padding-right: 10px;
  padding-left: 0;
`;

export const TagsWithLabel = styled('div')`
  grid-area: label;
  align-items: center;
  display: flex;
  opacity: ${(props) => (props.isDependenceDisable ? '.5' : '1')};
  ${(props) =>
    !props.withSidebar &&
    `@media only screen and (min-width: 800px) {
      flex-direction: row;
    }
    @media only screen and (min-width: 1440px) {
      flex-direction: column;
      align-items: flex-start;
    }`};
  ${(props) =>
    props.withSidebar &&
    `flex-direction: row;
     @media only screen and (min-width: 1600px) {
       flex-direction: column;
       align-items: flex-start;
    }`};
`;

export const OptionalSubLabel = styled('div')`
  margin-top: 1px;
  font-family: var(--font-family-primary);
  font-size: 14px;
  line-height: 20px;
  color: #76777c;
  opacity: ${(props) => (props.isDependenceDisable ? '.5' : '.6')};
`;

export const DependenceInfo = styled('div')`
  font-weight: 400;
  font-family: var(--font-family-primary);
  font-size: 14px;
  line-height: 20px;
  color: #76777c;
  margin-top: 10px;
`;

const TextBlockAdaptive = `
  @media only screen and (min-width: 1440px) {
     grid-template-areas: "label info"
                          "field info";
     grid-template-columns: 410px auto;
     grid-template-rows: auto;
     grid-column-gap: 100px;
     margin-right: 185px;
  }
  @media only screen and (min-width: 2560px) {
     grid-template-areas: "label info";
     grid-template-columns: 500px 750px;
     grid-template-rows: auto;
     grid-column-gap: 140px;
     margin-right: 185px;
  }
  `;

const TextBlockWithSidebar = `
  @media only screen and (min-width: 1600px) {
     grid-template-areas: "label info"
                          "field info";
     grid-template-columns: 410px auto;
     grid-template-rows: auto;
     grid-column-gap: 100px;
     margin-right: 185px;
  }
  @media only screen and (min-width: 2560px) {
     grid-template-areas: "label info";
     grid-template-columns: 500px 750px;
     grid-template-rows: auto;
     grid-column-gap: 140px;
     margin-right: 185px;
  }
  `;

export const TextBlock = styled('div')`
  grid-area: text;
  justify-content: flex-start;
  display: grid;
  grid-gap: 10px;
  grid-template-areas: "label"
                       "info"
                       "field";
  grid-template-columns: 100%;
  grid-template-rows: 22px auto;
  ${(props) => props.isAdaptive && !props.withSidebar && TextBlockAdaptive};
  ${(props) => props.withSidebar && TextBlockWithSidebar};
}
`;

export const Label = styled('label')`
  font-weight: 400;
  font-family: var(--font-family-primary);
  font-size: 14px;
  line-height: 20px;
  cursor: pointer;
  color: ${(props) => (props.isDependenceDisable ? '#76777C' : '#1A1D25')};
  padding-top: ${(props) => (props.subLabel ? null : '10px')};
  @media only screen and (min-width: 800px) {
    padding-right: 12px;
    margin-bottom: 0;
  }
  ${(props) =>
    !props.withSidebar &&
    `@media only screen and (min-width: 1440px) {
      padding-right: 25px;
      margin: 0.1em 0 10px 0;
    }`};
  ${(props) =>
    props.withSidebar &&
    `margin: 0;
    @media only screen and (min-width: 1600px) {
      margin: 0.1em 0 10px 0;
    }`};
`;

export const SubLabel = styled('div')`
  margin-top: 1px;
  font-family: var(--font-family-primary);
  font-size: 14px;
  line-height: 20px;
  color: #76777c;
  opacity: ${(props) => (props.isDependenceDisable ? '.5' : '.6')};
  margin-left: 0;
  ${(props) =>
    props.isAdaptive &&
    `
    @media only screen and (min-width: 1440px) {
      margin-left: 35px;
    }`}
`;

export const SubInfo = styled('div')`
  grid-area: info;
  ${(props) =>
    props.isAdaptive &&
    !props.withSidebar &&
    `
    @media only screen and (min-width: 1440px) {
      width: 360px;
    }
    @media only screen and (min-width: 2560px) {
      width: 750px;
    }
  `};
  ${(props) =>
    props.withSidebar &&
    `
    @media only screen and (min-width: 1600px) {
      width: 350px;
    }
    @media only screen and (min-width: 2560px) {
      width: 750px;
    }
    `};
`;
