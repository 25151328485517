import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { api } from 'src/app/api/base';
import { URL_DEP_COMPUTER_STATUS_COUNTS } from 'src/app/api/urls';

export interface DeviceCounts {
  all: number;
  awaitingEnrollment: number;
}

export interface GetADEDeviceCountParams {
  filters?: Array<{ name: string; operator: string; value: string | string[] }>;
  search?: string;
  search_columns?: string;
}

export async function getADEDeviceCount(
  params: GetADEDeviceCountParams,
): Promise<DeviceCounts> {
  try {
    const { filters } = params;
    const res = await api(URL_DEP_COMPUTER_STATUS_COUNTS).get({
      ...params,
      filters: JSON.stringify(filters),
    });
    const { data } = res;

    return {
      all: data.all,
      awaitingEnrollment: data.awaiting_enrollment,
    };
  } catch (error) {
    return {
      all: 0,
      awaitingEnrollment: 0,
    };
  }
}

export function useGetADEDeviceCount(params: GetADEDeviceCountParams) {
  return useQuery({
    queryKey: ['get-ade-device-count', params],
    queryFn: () => getADEDeviceCount(params),
    placeholderData: keepPreviousData,
  });
}
