import { Breadcrumbs, BreadcrumbsItem } from '@kandji-inc/nectar-ui';
import type {
  BreadcrumbItem,
  BreadcrumbItemRender,
} from '@kandji-inc/nectar-ui';
import React from 'react';
import { Link } from 'react-router-dom';

type propsType = {
  items: BreadcrumbItem[];
};

const RenderBreadcrumbs = ({ items }: propsType) => {
  const breadcrumbItemRender = ({ title, url }: BreadcrumbItemRender) => (
    <Link to={url}>{title}</Link>
  );

  return (
    <Breadcrumbs>
      {items.map((item) => (
        <React.Fragment key={item.title}>
          {item.url && (
            <BreadcrumbsItem item={item} itemRender={breadcrumbItemRender} />
          )}

          {!item.url && <BreadcrumbsItem item={item} />}
        </React.Fragment>
      ))}
    </Breadcrumbs>
  );
};
export default RenderBreadcrumbs;
