// TODO: remove this when the feature flag is gone
// istanbul ignore file
import { useQuery } from '@tanstack/react-query';
import { integrationsBackendService } from '../data-service/integrations-backend-service';
import { integrationsBackendKeys } from './query-key-factory';

/* This hook is used for listing the integrations for the integrations-backend. There is a similiar hook for getting integrations from the existing company backend integrations endpoint. */
const useListIntegrationsBackend = () =>
  useQuery({
    queryKey: integrationsBackendKeys.all,
    queryFn: () => integrationsBackendService.getIntegrations(),
  });

export default useListIntegrationsBackend;
