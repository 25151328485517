/* istanbul ignore file - legacy code, moved */
import camelCase from 'lodash/camelCase';
import PropTypes from 'prop-types';
import React from 'react';
import { withRouter } from 'react-router';
import styled from 'styled-components';

const Wrapper = styled('div')`
  margin-top: ${(props) => (props.sticky ? '30px' : '41px')};
  padding-bottom: ${(props) => (props.sticky ? '30px' : null)};
  display: flex;
  flex-flow: row nowrap;
`;

const ActiveCrumb = styled('div')`
  cursor: pointer;
  font-family: var(--font-family-primary);
  font-size: 10px;
  font-weight: bold;
  line-height: 13px;
  white-space: pre-wrap;
  letter-spacing: 0.15em;
  text-transform: uppercase;
  color: #848a99;
`;

const InactiveCrumb = styled(ActiveCrumb)`
  cursor: default;
  color: #1a1d25;
  max-width: 15rem;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const SCEPBreadcrumbs = ({ crumbs, sticky, history }) => (
  <Wrapper sticky={sticky}>
    {crumbs.map((crumb, index) => {
      if (index === crumbs.length - 1) {
        return (
          <InactiveCrumb key={camelCase(crumb.name)}>
            {crumb.name}
          </InactiveCrumb>
        );
      }
      return (
        <ActiveCrumb
          key={camelCase(crumb.name)}
          onClick={() => crumb.link && history.push(crumb.link)}
        >
          {`${crumb.name}  /  `}
        </ActiveCrumb>
      );
    })}
  </Wrapper>
);

SCEPBreadcrumbs.propTypes = {
  crumbs: PropTypes.arrayOf(PropTypes.shape),
  sticky: PropTypes.bool,
  history: PropTypes.object.isRequired,
};

SCEPBreadcrumbs.defaultProps = {
  crumbs: [],
  sticky: false,
};

export default withRouter(SCEPBreadcrumbs);
