import { useMutation, useQueryClient } from '@tanstack/react-query';
import { integrationsBackendKeys } from 'src/features/integrations/hooks/query-key-factory';
import { servicenowService } from '../services';

export const useDeleteServicenowIntegraton = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (integrationId: string) =>
      servicenowService.deleteIntegration(integrationId),
    onSuccess: () => {
      // refetch the integrations from integrations-backend whenever we delete this integration
      queryClient.invalidateQueries({ queryKey: integrationsBackendKeys.all });
    },
  });
};
