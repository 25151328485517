import slug from './slug';

export default {
  [slug.EMAIL_PASSWORD]: {
    label: 'Kandji',
    icon: 'kandji-circle',
    slug: slug.EMAIL_PASSWORD,
  },
  [slug.GOOGLE_APPS]: {
    label: 'Google Workspace',
    icon: 'google-workspace',
    slug: slug.GOOGLE_APPS,
  },
  [slug.SAMLP]: {
    label: 'Custom SAML',
    icon: 'custom-saml',
    slug: slug.SAMLP,
  },
  [slug.WAAD]: {
    label: 'Microsoft Entra ID',
    icon: 'azure-active-directory',
    slug: slug.WAAD,
  },
};
