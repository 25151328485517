import { keepPreviousData, useQuery } from '@tanstack/react-query';
/* istanbul ignore file */
import { useParams } from 'react-router-dom';

import { api } from 'app/api/base';
import { URL_COMPUTERS } from 'app/api/urls';

const useComputers = (
  keys: Array<any> = [],
  params: any = {},
  isEnabled = true,
) => {
  const { id } = useParams<{ id?: string }>();

  const {
    data: apiRes,
    isLoading,
    isError,
    ...rest
  } = useQuery({
    queryKey: ['am-devices', id, ...(keys || [])],
    queryFn: () =>
      api(URL_COMPUTERS).get({
        filters: JSON.stringify([
          {
            name: 'blueprint',
            operator: 'equals',
            value: [id],
          },
        ]),
        ...params,
      }),
    enabled: isEnabled,
    placeholderData: keepPreviousData,
  });

  const apiData = apiRes?.data;
  const count = apiData?.count;
  const prev = apiData?.prev;
  const next = apiData?.next;
  const computers = apiData?.results;

  return {
    data: apiData,
    queryKey: ['am-devices', id, ...(keys || [])],
    queryKeyBase: ['am-devices', id],
    isLoading,
    isError,
    count,
    prev,
    next,
    computers,
    ...rest,
  };
};

export default useComputers;
