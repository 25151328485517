import { object } from 'prop-types';
import React, { useState } from 'react';

import RuleTagList from '../library/tags/rule-tag-list';

const AssignmentRulesDropdown = ({ rules, facetMap }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const closedArrow = 'fa-caret-right';
  const openArrow = 'fa-caret-down';

  const handleClick = (e) => {
    e.preventDefault();
    setIsExpanded(!isExpanded);
  };

  return (
    <div className="b-flex-col rules-dropdown" onClick={handleClick}>
      <div className="b-flex-row">
        <div className="b-mr rules-dropdown-caret">
          <i
            className={`fas ${isExpanded ? openArrow : closedArrow} c-grey-999`}
          />
        </div>
        <p className="b-txt rules-dropdown-header b-mr-tiny">
          Assignment Rules
        </p>
        <p className="b-txt rules-count">{rules.and.length}</p>
      </div>
      {isExpanded && (
        <div className="b-flex-row b-ml2 b-mt rules-list">
          <RuleTagList facetMap={facetMap} rules={rules} />
        </div>
      )}
    </div>
  );
};

AssignmentRulesDropdown.propTypes = {
  rules: object.isRequired,
  facetMap: object.isRequired,
};

export default AssignmentRulesDropdown;
