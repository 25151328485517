import React, { useContext, useState } from 'react';

import {
  Button,
  modal as Modal,
  Select,
  TextInput,
  Toaster as toaster,
} from '@kandji-inc/bumblebee';
import { UserRoleOptions, UserRoles } from 'src/app/common/constants';

import { AccountContext } from 'contexts/account';

import { sendInviteToUser } from 'app/_actions/company';

import { i18n } from 'i18n';
import {
  emailValidator,
  matchingValidator,
  requiredValidator,
} from './validation';

const defaultModel = {
  first_name: '',
  last_name: '',
  email: '',
  confirmEmail: '',
  role: UserRoleOptions.find((o) => o.value === UserRoles.auditor),
};

const NewUserModal = (props) => {
  const { isOpen, onClose } = props;
  const account = useContext(AccountContext);
  const companyName = account?.currentCompany?.name;
  const [model, setModel] = useState({ ...defaultModel });
  const [isInviting, setIsInviting] = useState(false);
  const [invalids, setInvalids] = useState(Array(4).fill(false));

  const onInvite = (data) => {
    const d = { ...data, role: data.role.value };
    delete d.confirmEmail;

    setIsInviting(true);
    return sendInviteToUser(d)
      .then(() => {
        toaster('Invite was sent.');
        setModel({ ...defaultModel });
      })
      .catch(() => toaster(i18n.common.error()))
      .finally(() => setIsInviting(false));
  };

  const setInvalid = (idx) => (v) =>
    setInvalids((prev) => {
      prev[idx] = v;
      return [...prev];
    });

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      animationDuration={150}
      disableCloseOnOutsideClick
      parts={{
        header: {
          text: i18n.t('Invite User to {companyName}', { companyName }),
        },
        content: {
          children: (
            <div className="b-flex-vg3">
              <TextInput
                label={i18n.t('First Name')}
                value={model.first_name}
                onChange={(e) => {
                  const val = e.target.value;
                  setModel((prev) => ({ ...prev, first_name: val }));
                }}
                validator={(val) => [requiredValidator(val)]}
                onInvalidate={setInvalid(0)}
                placeholder={i18n.t('First Name')}
                fieldsGrid
              />
              <TextInput
                label={i18n.t('Last Name')}
                value={model.last_name}
                onChange={(e) => {
                  const val = e.target.value;
                  setModel((prev) => ({ ...prev, last_name: val }));
                }}
                validator={(val) => [requiredValidator(val)]}
                placeholder={i18n.t('Last Name')}
                onInvalidate={setInvalid(1)}
                fieldsGrid
              />
              <TextInput
                label={i18n.t('Email')}
                value={model.email}
                onChange={(e) => {
                  const val = e.target.value;
                  setModel((prev) => ({ ...prev, email: val }));
                }}
                validator={(val) => [
                  requiredValidator(val),
                  emailValidator(val),
                ]}
                onInvalidate={setInvalid(2)}
                placeholder={i18n.t('Email')}
                fieldsGrid
              />
              <TextInput
                label={i18n.t('Confirm Email')}
                value={model.confirmEmail}
                onChange={(e) => {
                  const val = e.target.value;
                  setModel((prev) => ({
                    ...prev,
                    confirmEmail: val,
                  }));
                }}
                validator={(val) => [
                  requiredValidator(val),
                  matchingValidator(val, model.email),
                  emailValidator(val),
                ]}
                runValidationOn={[model.email]}
                onInvalidate={setInvalid(3)}
                placeholder={i18n.t('Confirm Email')}
                fieldsGrid
              />
              <div>
                <p className="b-txt b-mb1">{i18n.t('Access Level')}</p>
                <Select
                  options={UserRoleOptions}
                  value={model.role}
                  onChange={(v) => setModel((prev) => ({ ...prev, role: v }))}
                  menuPortalTarget={document.body}
                  styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                />
              </div>
            </div>
          ),
        },
        footer: {
          children: (
            <div className="b-flex-g">
              <Button
                disabled={isInviting}
                onClick={() => {
                  setModel({ ...defaultModel });
                  onClose();
                }}
                kind="outline"
              >
                {i18n.t('Cancel')}
              </Button>
              <Button
                disabled={
                  !Object.values(model).every(Boolean) || invalids.some(Boolean)
                }
                isProgress={isInviting}
                icon={isInviting ? 'arrows-rotate' : ''}
                onClick={() => onInvite(model)}
              >
                {i18n.t('Submit')}
              </Button>
            </div>
          ),
        },
      }}
    />
  );
};

export default NewUserModal;
