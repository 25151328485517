import { Button, setClass } from '@kandji-inc/bumblebee';
import React from 'react';
import RtfImage from './assets/rtf.png';
import { bytesToSize } from './common';

/**
 * used by:
 *  passport/kandji login RTF policy banners
 *  blueprint policy banner RTF files
 */
const RTFFileViewer = (props) => {
  const { file, canDownload = true, onTrash, size, isDisabled } = props;

  const download = () => {
    const a = document.createElement('a');
    a.style.display = 'none';
    document.body.appendChild(a);
    a.href = file.url
      ? file.url
      : window.URL.createObjectURL(new Blob([file], { type: 'rtf' }));
    a.setAttribute('download', file.name);
    a.click();
    window.URL.revokeObjectURL(a.href);
    document.body.removeChild(a);
  };

  return (
    <div
      className={setClass('k-file-view__container', size === 'sm' && '--sm')}
    >
      <div className="k-file-view">
        <div className="b-flex-btw">
          <div className="b-flex-vc">
            <img
              className="k-file-view__img b-mr-tiny"
              src={RtfImage}
              alt="icon"
            />
            <div className="b-flex-col">
              {file?.name && <p className="b-txt-bold">{file.name}</p>}
              {file?.size && (
                <p className="b-txt-light">{bytesToSize(file.size)}</p>
              )}
            </div>
          </div>
          <div className="b-flex-vc">
            {canDownload && (
              <Button
                kind="link"
                icon="arrow-down-to-line"
                onClick={download}
              />
            )}
            <Button
              kind="link"
              icon="trash-can"
              theme="error"
              onClick={onTrash}
              isDisabled={isDisabled}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

RTFFileViewer.defaultProps = {
  onDownload: () => {},
  onTrash: () => {},
};

export default RTFFileViewer;
