import {
  Badge,
  Banner,
  Checkbox,
  Flex,
  Heading,
  Paragraph,
  Text,
  styled,
} from '@kandji-inc/nectar-ui';

import { Toaster as toaster } from '@kandji-inc/bumblebee';

import { DataContext } from 'contexts/data';
import { i18n } from 'i18n';
import React, { useContext, useState } from 'react';
import type colors from '../../../../../../blueprints/colors';
import FooterButtons from '../../../../../components/footer-buttons';
import { Container } from '../../../../../components/styled-components';
import BlueprintIcon from '../../../components/blueprint-icon';
import {
  useGetOktaDeviceTrust,
  useUpdateOktaDeviceTrust,
} from '../../../hooks';
import useMapBlueprints from '../../../hooks/use-map-blueprints';
import type { DeviceType } from '../../../types';
import { getDevicePlatformDataToDelete } from '../utils/get-device-platform-data-to-delete';

type Props = {
  onClose: () => void;
  blueprints: string[] | undefined;
  oktaDeviceTrustId: string;
  type: DeviceType;
};

type DataContext = {
  appData?: AppData;
  appDataReady: boolean;
  setAppDataReady: () => void;
  getBlueprints: () => void;
  getParamMeta: () => void;
};

type AppData = {
  blueprints: Blueprint[];
};

export type Blueprint = {
  id: string;
  color: keyof typeof colors;
  computersCount: number;
  icon: string;
  name: string;
};

const StyledContainer = styled(Container, {
  display: 'grid',
  gap: '$4',
  padding: '48px 32px',
  textAlign: 'left',
});

const BadgeWrapper = styled('div', {
  marginLeft: 'auto',
});

const BlueprintContainer = styled('div', {
  display: 'grid',
  gap: '$4',
  maxHeight: '200px',
  overflowY: 'auto',
});

const BlueprintRow = styled('div', {
  display: 'grid',
  gridTemplateColumns: '24px 200px auto',
  gap: '$2',
  alignItems: 'center',
});
const BlueprintRowBadgeContainer = styled('div', {});

const DeleteDevicePlatform = (props: Props) => {
  const { onClose, blueprints: odtBlueprints, oktaDeviceTrustId, type } = props;

  const [isConfirmedChecked, setIsConfirmedChecked] = useState(false);

  const { getOktaDeviceTrustDetails } = useGetOktaDeviceTrust();

  // get blueprints from redux store since these are already loaded
  const { appData } = useContext(DataContext) as DataContext;

  const blueprintsList = appData?.blueprints;

  const { odtBlueprintsWithMetaData } = useMapBlueprints(
    odtBlueprints,
    blueprintsList,
  );

  const blueprintCount = odtBlueprintsWithMetaData?.length;

  const { updateOktaDeviceTrust } = useUpdateOktaDeviceTrust();

  const bannerText = odtBlueprints
    ? i18n.t(
        'By removing this device platform from the integration, Okta Device Trust certificates will no longer be deployed to {type} devices.',
        { type },
      )
    : i18n.t(
        'By removing this device platform from the integration, Okta Device Trust cannot be deployed to {type} devices.',
        { type },
      );

  const handleSubmit = async () => {
    const deviceTypeDeleteData = getDevicePlatformDataToDelete(type);

    if (deviceTypeDeleteData) {
      try {
        await updateOktaDeviceTrust(deviceTypeDeleteData, oktaDeviceTrustId);
        toaster(i18n.t('{type} device platform settings deleted.', { type }));
        await getOktaDeviceTrustDetails();
        onClose();
      } catch (e) {
        toaster(i18n.common.error());
      }
    }
  };

  const handleSetIsConfirmedChecked = () => {
    setIsConfirmedChecked((prevState) => !prevState);
  };

  return (
    <>
      <StyledContainer>
        <Heading as="h2" size="2" data-testid="delete-device:header">
          {i18n.t('Are you sure you want to delete {type}?', { type })}
        </Heading>
        {!odtBlueprints?.length && (
          <Text>
            {i18n.$t(
              'The {typePlatformText} configuration will be permanently removed. This action cannot be undone.',
              {
                typePlatformText: (
                  <Text
                    css={{ fontWeight: '$semibold', display: 'inline-block' }}
                  >
                    {type} {i18n.t('device platform')}
                  </Text>
                ),
              },
            )}
          </Text>
        )}

        <Banner
          icon="triangle-exclamation"
          theme="warning"
          text={
            <Text>
              {bannerText}{' '}
              <a
                href="https://support.kandji.io/support/solutions/articles/72000580342"
                target="_blank"
                rel="noreferrer"
              >
                {i18n.t('Learn more')}
              </a>
            </Text>
          }
        />

        {!!odtBlueprints?.length && (
          <>
            <Paragraph>
              {i18n.$t(
                '{oktaVerify} has the Okta Device Trust integration turned on. By removing this device platform configuration, we will remove all settings for the platform type on the Library item and the integration. ',
                {
                  oktaVerify: (
                    <Text
                      css={{ fontWeight: '$semibold', display: 'inline-block' }}
                    >
                      {i18n.t('Okta Verify')}
                    </Text>
                  ),
                },
              )}
            </Paragraph>
            <Paragraph>
              {i18n.t(
                'Okta Verify will remain installed on {type} devices, but device trust will be removed upon next check-in.',
                { type },
              )}{' '}
            </Paragraph>

            <div>
              <Flex alignItems="center" css={{ paddingBottom: '$3' }}>
                <Heading as="h5" size="5">
                  {i18n.t('The following blueprints include {type} devices:', {
                    type,
                  })}
                </Heading>
                <BadgeWrapper>
                  <Badge color="neutral">
                    {blueprintCount} {i18n.t('Blueprints')}
                  </Badge>
                </BadgeWrapper>
              </Flex>
              <BlueprintContainer>
                {odtBlueprintsWithMetaData?.map((blueprint) => (
                  <BlueprintRow key={blueprint.id}>
                    <BlueprintIcon
                      color={blueprint.color}
                      iconName={blueprint.icon}
                    />
                    <Text
                      css={{ fontWeight: '$medium', display: 'inline-block' }}
                    >
                      {blueprint.name}
                    </Text>
                    <BlueprintRowBadgeContainer>
                      <Badge color="neutral">
                        {blueprint.computersCount} {i18n.t('Devices')}
                      </Badge>
                    </BlueprintRowBadgeContainer>
                  </BlueprintRow>
                ))}
              </BlueprintContainer>
            </div>
            <Heading as="h5" size="5">
              {i18n.t(
                'Are you sure you want to delete {type} as a device platform?',
                { type },
              )}
            </Heading>
            <Checkbox
              data-testid="confirm-checkbox"
              checked={isConfirmedChecked}
              onClick={handleSetIsConfirmedChecked}
              label={i18n.t('I understand, and would like to proceed')}
            />
          </>
        )}
      </StyledContainer>
      <FooterButtons
        onClose={onClose}
        handleNext={handleSubmit}
        nextButtonDisabled={!!odtBlueprints?.length && !isConfirmedChecked}
        cancelButtonText={i18n.t('Cancel')}
        nextButtonText={i18n.t('Delete device platform')}
        theme="danger"
        showBackButton={false}
      />
    </>
  );
};

export default DeleteDevicePlatform;
