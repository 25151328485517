import { Button, modal as Modal } from '@kandji-inc/bumblebee';
import { func, shape, string } from 'prop-types';
import React from 'react';
import { formatTime } from 'src/app/components/common/helpers';
import configurations from '../../../configurations';
import { TYPE_PROP_TYPE } from '../../communication-integrations/util';
import './view-user-integration-modal.styles.scss';
import { i18n } from 'i18n';

function InfoRow({ title, info }) {
  return (
    <div className="view-user-int-modal_info-row">
      <span className="b-txt-light view-user-int-modal_info-row__title">
        {title}
      </span>
      <span className="b-txt">{info}</span>
    </div>
  );
}

InfoRow.propTypes = {
  title: string,
  info: string,
};

InfoRow.defaultProps = {
  info: '-',
  title: '',
};

function ViewUserIntegrationModal({
  stopViewingIntegration,
  viewedIntegration,
}) {
  const fullConfig = configurations[viewedIntegration?.type];
  const modalConfig = fullConfig?.viewIntegration || {};
  const { text, extra, emailRow } = modalConfig;
  const lastImport = viewedIntegration?.synced_at
    ? formatTime(viewedIntegration.synced_at, null, null, null, true)
    : '';

  return (
    <Modal
      isOpen={!!viewedIntegration}
      onClose={stopViewingIntegration}
      withoutCloseButton
      parts={{
        header: { text: i18n.t('View user integration details') },
        content: {
          children: (
            <>
              <p className="b-txt b-mb2">{text}</p>
              <div>
                <InfoRow title={emailRow} info={viewedIntegration?.email} />
                {lastImport && (
                  <InfoRow title="Last import" info={lastImport} />
                )}
              </div>
              {extra}
            </>
          ),
          className: 'cmn-modal_content--b-spacing',
        },
        footer: {
          children: (
            <>
              <Button kind="outline" onClick={stopViewingIntegration}>
                {i18n.t('Close')}
              </Button>
            </>
          ),
        },
      }}
    />
  );
}

ViewUserIntegrationModal.propTypes = {
  stopViewingIntegration: func.isRequired,
  viewedIntegration: shape({
    email: string,
    synced_at: string,
    type: TYPE_PROP_TYPE.isRequired,
  }),
};

ViewUserIntegrationModal.defaultProps = {
  viewedIntegration: null,
};

export default ViewUserIntegrationModal;
