import { Button, modal as Modal, TextInput } from '@kandji-inc/bumblebee';
import { i18n } from 'i18n';
/* istanbul ignore file */
import React, { useEffect, useRef, useState } from 'react';
import { colors } from 'src/app/common/constants';
import styled from 'styled-components';
import BlueprintImage from '../../assets/blueprint_happy.png';
import { IconDisplay, IconSelector } from '../icon-select';

const RatingLine = styled.section`
  height: 3px;
  border-radius: 2px;
  width: 110px;
  background: ${(props) =>
    `linear-gradient(to right, ${colors['marengo-700']} ${props.percent}%, ${colors['grey-400']} ${props.percent}%)`};
`;
const AddBlueprint = (props) => {
  const { onIconSelect, blueprint, onChange, onCreate } = props;

  return (
    <div className="bl-blueprints-new-modal__configure">
      <div className="bl-blueprints-new-modal__display-template">
        <div className="b-flex bl-blueprints-new-modal__display-top b-mb2">
          <IconDisplay
            className="b-mr"
            onEdit={onIconSelect}
            icon={blueprint.icon}
            color={blueprint.color}
            size="md"
          />
          <div className="b-flex-col b-flex-hc">
            <h1 className="b-h2 bl-blueprints-new-modal__display-template-header --no-edit">
              {blueprint.name || i18n.t('Create Blueprint')}
            </h1>
            <p className="b-txt bl-blueprint__txt-overflow-description">
              {blueprint.description || i18n.t('Blueprint description')}
            </p>
          </div>
        </div>
      </div>
      <div className="b-flex-vg">
        <TextInput
          fieldsGrid
          label={i18n.t('Blueprint name')}
          placeholder={i18n.t('Blueprint name')}
          value={blueprint.name}
          onChange={(e) => {
            if (e.target.value.length <= 100) {
              onChange({ name: e.target.value });
            }
          }}
          validator={(v) => [
            {
              message: i18n.t('Required'),
              invalid: () => !String(v).length,
              trigger: ['onBlur', 'onInput'],
            },
          ]}
        />
        <TextInput
          textArea
          fieldsGrid
          label={i18n.t('Blueprint description')}
          placeholder={i18n.t('Blueprint description')}
          value={blueprint.description}
          onChange={(e) => onChange({ description: e.target.value })}
        />
      </div>

      <div className="b-flex-justify-end bl-mt-auto">
        <Button onClick={onCreate}>{i18n.t('Create Blueprint')}</Button>
      </div>
    </div>
  );
};

const DisplayTemplate = (props) => {
  const { template, onIconSelect, onCreate, onChange } = props;
  const [isEditing, setIsEditing] = useState(false);

  return (
    <div className="bl-blueprints-new-modal__configure">
      <div className="bl-blueprints-new-modal__display-template">
        <div className="b-flex bl-blueprints-new-modal__display-top">
          <IconDisplay
            className="b-mr"
            onEdit={onIconSelect}
            icon={template.icon}
            color={template.color}
            size="md"
          />
          <div className="b-flex-col b-flex-hc">
            <div className="b-flex-vc">
              {isEditing ? (
                <>
                  <TextInput
                    wrapperClassName="bl-blueprints-new-modal__display-template-header"
                    value={template.name}
                    onChange={(e) => onChange({ name: e.target.value })}
                  />
                  <div className="b-flex-g1 b-ml1">
                    <Button
                      kind="link"
                      icon="check"
                      onClick={() => setIsEditing(false)}
                    />
                  </div>
                </>
              ) : (
                <>
                  <h1 className="b-h1 bl-blueprints-new-modal__display-template-header">
                    {template.name}
                  </h1>
                  <Button
                    className="b-ml1"
                    kind="link"
                    icon="pencil"
                    onClick={() => setIsEditing(true)}
                  />
                </>
              )}
            </div>
            <div className="bl-blueprints-new-modal__display-template-meta">
              <p className="b-txt-light">{i18n.t('Items enabled')}</p>
              <div className="bl-blueprint-new-modal__display-template-box b-txt-light">
                {Object.keys(template.params || {}).length}
              </div>
              <p className="b-txt-light">{i18n.t('Restriction rating')}</p>
              <RatingLine percent={template.rating} />
            </div>
          </div>
        </div>
      </div>
      <div className="b-flex1 b-flex-col bl-blueprints-new-modal__display-template-info">
        <h4 className="b-h4 b-mb">{i18n.t('Description')}</h4>
        <p className="b-txt-light">
          {template.description || 'No description'}
        </p>

        <div className="b-flex-justify-end b-mt1">
          <Button className="pendo-create-blueprint" onClick={() => onCreate()}>
            {i18n.t('Create Blueprint')}
          </Button>
        </div>
      </div>
    </div>
  );
};

const TemplateSection = (props) => {
  const { template, onClick, selectedTemplate } = props;

  return (
    <div className="bl-blueprints-new-modal__templates-list-section">
      <span className="b-txt-bold">{template.name}</span>
      <div className="bl-blueprints-new-modal__templates-list-section-items">
        {template.templates.map((temp) => {
          const isSelected =
            temp.category === selectedTemplate?.category.id &&
            temp.id === selectedTemplate?.template.id;
          return (
            <Button
              className="pendo-blueprints"
              key={`${temp.id}_${temp.category}`}
              kind="link"
              style={
                !isSelected
                  ? {
                      color: '#606673',
                    }
                  : {}
              }
              onClick={() => onClick(template, temp)}
            >
              {temp.name}
            </Button>
          );
        })}
      </div>
    </div>
  );
};

const defaultNewBlueprint = {
  name: '',
  description: '',
  icon: 'ss-attach',
  color: 'orange-300',
};

const NewBlueprint = (props) => {
  const { onClose, onCreate, templates } = props;
  const [blueprint, setBlueprint] = useState({ ...defaultNewBlueprint });
  const [selectedTemplate, setSelectedTemplate] = useState();
  const [isSelectingIcon, setIsSelectingIcon] = useState(false);
  const [isAddingBlueprint, setIsAddingBlueprint] = useState(false);
  const animationDuration = useRef(150);

  useEffect(() => {
    animationDuration.current = 0;
  }, []);

  if (isSelectingIcon) {
    if (selectedTemplate) {
      const selectedTempCatIdx = templates.findIndex(
        (cat) => cat.id === selectedTemplate.category.id,
      );
      const selectedTempIdx = templates[selectedTempCatIdx].templates.findIndex(
        (temp) => temp.id === selectedTemplate.template.id,
      );
      return (
        <IconSelector
          onClose={onClose}
          blueprint={templates[selectedTempCatIdx].templates[selectedTempIdx]}
          icon={selectedTemplate.template.icon}
          color={selectedTemplate.template.color}
          onBack={(icon, color) => {
            setSelectedTemplate((prev) => ({
              ...prev,
              template: { ...prev.template, icon, color },
            }));
            setIsSelectingIcon(false);
          }}
        />
      );
    }
    return (
      <IconSelector
        onClose={onClose}
        blueprint={defaultNewBlueprint}
        icon={blueprint.icon}
        color={blueprint.color}
        onBack={(icon, color) => {
          setBlueprint((prev) => ({ ...prev, icon, color }));
          setIsSelectingIcon(false);
        }}
      />
    );
  }

  const orderedTemplates = templates
    .sort((a, b) => a.weight - b.weight)
    .map((template) => ({
      ...template,
      templates: template.templates.sort((a, b) => a.rating - b.rating),
    }));
  return (
    <Modal
      isOpen
      customBody
      disableCloseOnOutsideClick
      animationDuration={animationDuration.current}
      onClose={onClose}
      classes={{ content: 'bl-blueprints-new-modal' }}
      parts={{
        content: {
          children: (
            <>
              <div className="bl-blueprints-new-modal__templates">
                <Button
                  icon="plus"
                  className={`bl-blueprint__new-action ${
                    isAddingBlueprint ? '--is-creating' : ''
                  }`}
                  theme="dark"
                  onClick={() => {
                    setBlueprint({ ...defaultNewBlueprint });
                    setIsAddingBlueprint(true);
                    setSelectedTemplate();
                  }}
                >
                  {i18n.t('New Blueprint')}
                </Button>
                <div className="bl-blueprints-new-modal__templates-list">
                  {orderedTemplates.map((template) => {
                    return (
                      <TemplateSection
                        key={template.id}
                        template={template}
                        onClick={(category, template) => {
                          setSelectedTemplate({ category, template });
                          setIsAddingBlueprint(false);
                        }}
                        selectedTemplate={selectedTemplate}
                      />
                    );
                  })}
                </div>
              </div>
              <div className="bl-blueprints-new-modal__main">
                {isAddingBlueprint ? (
                  <AddBlueprint
                    blueprint={blueprint}
                    onIconSelect={() => setIsSelectingIcon(true)}
                    onChange={(d) =>
                      setBlueprint((prev) => ({ ...prev, ...d }))
                    }
                    onCreate={() => onCreate(blueprint).then(onClose)}
                  />
                ) : selectedTemplate ? (
                  <DisplayTemplate
                    onIconSelect={() => setIsSelectingIcon(true)}
                    template={selectedTemplate.template}
                    onChange={(updates) =>
                      setSelectedTemplate((prev) => ({
                        ...prev,
                        template: { ...prev.template, ...updates },
                      }))
                    }
                    onCreate={() =>
                      onCreate(selectedTemplate.template, {
                        type: 'template',
                        id: String(selectedTemplate.template.id),
                      }).then(onClose)
                    }
                  />
                ) : (
                  <div className="b-flex-vc b-flex-hc b-flex-col bl-fill-height">
                    <img
                      className="bl-blueprint-new-img"
                      src={BlueprintImage}
                      alt="blueprint image"
                    />
                    <h1 className="b-h1 b-mb">{i18n.t('Create Blueprint')}</h1>
                    <p className="b-txt-light b-flex-vc">
                      {i18n.$t(
                        'Select a template on the left, or {start_from_scratch}',
                        {
                          start_from_scratch: (
                            <a
                              href=""
                              className="decorate-off b-ml-micro b-alink"
                              onClick={(e) => {
                                e.preventDefault();
                                setIsAddingBlueprint(true);
                              }}
                            >
                              {i18n.t('start from scratch')}
                            </a>
                          ),
                        },
                      )}
                    </p>
                  </div>
                )}
              </div>
            </>
          ),
        },
      }}
    />
  );
};

export default NewBlueprint;
