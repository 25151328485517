/* istanbul ignore file */

import React, { useState, useEffect } from 'react';

import { Button, Icon } from '@kandji-inc/bumblebee';

import { i18n } from 'i18n';

import Loader from 'theme/components/atoms/Loader';

import { Card, Header, SubHeader } from 'features/library-items/template';

import { useAuthority, useConnector, useIs, usePanel } from '../../hooks';

import Table from './table';

const Content = () => {
  const {
    authorityList,
    authorityLimit,
    fetchAuthorityList,
    fetchAuthorityLimit,
    clearAuthorityList,
    fetchAuthorityListLibraryItemList,
    clearAuthorityListLibraryItemList,
    clearAuthorityListLibraryItemMap,
  } = useAuthority();

  const { fetchConnectorList, clearConnectorList } = useConnector();

  const { isLoading, isWorking } = useIs();
  const { openPanel, closePanel } = usePanel();

  const [checked, setChecked] = useState(false);

  const servers = authorityList;

  const hasServers = servers.length > 0;

  const hasReachedAuthorityLimit = authorityList.length >= authorityLimit;

  /* istanbul ignore next */
  const openPanelAddServer = () => {
    openPanel('add-server');
  };

  /* istanbul ignore next */
  const check = async () => {
    setChecked(false);
    await Promise.all([
      fetchAuthorityLimit(),
      fetchAuthorityList(),
      fetchAuthorityListLibraryItemList(),
      fetchConnectorList(),
    ]);
    setChecked(true);
  };

  /* istanbul ignore next */
  const reset = () => {
    clearAuthorityList();
    clearAuthorityListLibraryItemList();
    clearAuthorityListLibraryItemMap();
    clearConnectorList();
    setChecked(false);
  };

  useEffect(() => {
    check();

    return () => {
      reset();
    };
  }, []);

  useEffect(() => {
    if (checked && !hasServers) {
      openPanelAddServer();
    }
  }, [checked, hasServers]);

  const MaxAuthorityLimit = () => {
    if (!hasReachedAuthorityLimit) {
      return null;
    }
    return (
      <p className="k-adcs-limit b-txt b-txt--error">
        <Icon name="octagon-exclamation" className="b-mr-tiny" />
        {i18n.t('Max number of servers allowed')} = {authorityLimit}
      </p>
    );
  };

  return (
    <Card className="k-adcs-server">
      <Header>
        <h3 className="b-h3">{i18n.t('AD CS servers')}</h3>
      </Header>

      <SubHeader>
        <p className="b-txt">
          {i18n.t(
            'Provide a list of AD CS servers to manage for the integration. Assign a server to a connector to create Certificate profile Library Items.',
          )}{' '}
          <a
            href="https://support.kandji.io/support/solutions/articles/72000569107-active-directory-certificate-services-ad-cs-integration-setup-and-configure"
            className="b-alink"
            target="_blank"
            rel="noreferrer"
          >
            {i18n.t('Learn more')}...
          </a>
        </p>
      </SubHeader>

      {
        /* istanbul ignore next */
        checked ? <Table servers={servers} /> : <Loader type="simple" />
      }

      <div className="k-adcs-server-footer">
        <Button
          kind="link"
          icon="circle-plus"
          onClick={openPanelAddServer}
          isDisabled={isLoading || isWorking || hasReachedAuthorityLimit}
        >
          {i18n.t('Add server')}
        </Button>
        <MaxAuthorityLimit />
      </div>
    </Card>
  );
};

export default Content;
