import { setSnackbar } from 'app/_actions/ui';
import get from 'lodash/get';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { bindActionCreators } from 'redux';
import history from '../../../router/history';
import MdmIntegration from '../../integrations/Apple/MdmIntegration';
/* istanbul ignore file */
// The only change is a feature flag addition

class CompanyIntegrationsTab extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mdmLoaded: false,
      depLoaded: false,
      slackLoaded: false,
      slack: false,
    };
  }

  componentDidUpdate() {
    const { mdmLoaded, slackLoaded, depLoaded } = this.state;
    const { location } = this.props;
    if (
      get(location, 'state.anchor') &&
      mdmLoaded &&
      slackLoaded &&
      depLoaded
    ) {
      const { anchor } = location.state;
      history.replace({
        pathname: '/my-company/integrations',
        state: {
          anchor: null,
        },
      });
      const elem = document.getElementById(anchor);
      if (elem) {
        elem.scrollIntoView({
          behavior: 'smooth',
          block: 'end',
        });
      }
    }
  }

  expand = () => {
    this.setState((prevState) => ({ slack: !prevState.slack }));
  };

  render() {
    return (
      <div className="d-flex flex-column company-integration-container">
        <MdmIntegration
          setMdmLoaded={() => this.setState({ mdmLoaded: true })}
          setDepLoaded={() => this.setState({ depLoaded: true })}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  currentCompany: state.company,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setSnackbar,
    },
    dispatch,
  );

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(CompanyIntegrationsTab),
);
