import { DragOverlay } from '@dnd-kit/core';
import { Badge, Box } from '@kandji-inc/nectar-ui';
/* istanbul ignore file */
import React from 'react';
import { createPortal } from 'react-dom';

import { getLibraryItem } from 'src/features/blueprint-flow/helpers';

import LibraryListItem from '../../components/library-item';
import useBlueprintFlow from '../../store';

const useMousePosition = (isActive: boolean) => {
  const [mousePosition, setMousePosition] = React.useState({
    x: null,
    y: null,
  });
  React.useEffect(() => {
    const updateMousePosition = (ev) => {
      setMousePosition({ x: ev.clientX, y: ev.clientY });
    };

    if (isActive) {
      window.addEventListener('mousemove', updateMousePosition);
    }
    return () => {
      window.removeEventListener('mousemove', updateMousePosition);
    };
  }, [isActive]);
  return mousePosition;
};

const LibraryOverlay = () => {
  const draggingLibraryItems = useBlueprintFlow(
    (state) => state.draggingLibraryItems,
  );
  const libraryItems = useBlueprintFlow((state) => state.libraryItems);
  const showOverlay = draggingLibraryItems?.items.length;
  const showCount = draggingLibraryItems?.items.length > 1;
  const mousePosition = useMousePosition(Boolean(showCount));

  if (!showOverlay) {
    return null;
  }

  const isMultiple = draggingLibraryItems.items.length > 1;

  const CountBadge =
    isMultiple &&
    createPortal(
      <Box
        css={{
          alignSelf: 'end',
          width: 'fit-content',
          position: 'absolute',
          top: `${mousePosition.y}px`,
          left: `${mousePosition.x + 8}px`,
          zIndex: 99999,
        }}
      >
        <Badge
          css={{
            background: '$blue50',
            color: '$neutral0',
            whiteSpace: 'nowrap',
          }}
        >
          {draggingLibraryItems.items.length} items
        </Badge>
      </Box>,
      document.body,
    );

  return (
    <DragOverlay dropAnimation={null}>
      <Box css={{ gap: '6px' }}>
        {draggingLibraryItems.items.slice(0, 3).map((activeItem, idx) => (
          <Box
            key={activeItem.id}
            wFull
            css={{
              transform: idx
                ? `translate(0, ${idx * -40}px) rotate(${idx * -2}deg)`
                : 'unset',
              width: '281px',
            }}
          >
            <LibraryListItem
              item={{
                ...getLibraryItem(activeItem.data.id, libraryItems),
                flowId: activeItem.data.flowId,
                origin: activeItem.data.origin,
              }}
              isDragging
            />
          </Box>
        ))}
      </Box>
      {CountBadge}
    </DragOverlay>
  );
};

export default LibraryOverlay;
