import { useMutation } from '@tanstack/react-query';

import { api } from 'app/api/base';
import { URL_COMPUTERS, URL_COMPUTER_CHANGE_BLUEPRINT } from 'app/api/urls';

interface MutationArg {
  selectedDevices: any;
  blueprintId?: string;
}

interface UseBulkActionsArg {
  onAfterMutation: () => void;
}

const useBulkActions = ({
  onAfterMutation,
}: UseBulkActionsArg): {
  deleteDevices: (args: MutationArg) => Promise<any>;
  changeBlueprint: (args: MutationArg) => Promise<any>;
  isPendingDelete: boolean;
  isPendingChangeBlueprint: boolean;
} => {
  const { mutateAsync: deleteDevices, isPending: isPendingDelete } =
    useMutation({
      mutationKey: ['am-devices-devices-delete'],
      mutationFn: (args: MutationArg) => {
        const { selectedDevices } = args;
        return api(URL_COMPUTERS).delete(
          Object.keys(selectedDevices).filter(
            (selectedId) => selectedDevices[selectedId],
          ),
        );
      },
      onSuccess: onAfterMutation,
    });

  const { mutateAsync: changeBlueprint, isPending: isPendingChangeBlueprint } =
    useMutation({
      mutationKey: ['am-devices-blueprint-change'],
      mutationFn: (args: MutationArg) => {
        const { selectedDevices, blueprintId } = args;
        return api(URL_COMPUTER_CHANGE_BLUEPRINT).put({
          computers: Object.keys(selectedDevices).filter(
            (selectedId) => selectedDevices[selectedId],
          ),
          blueprintId,
        });
      },
      onSuccess: onAfterMutation,
    });

  return {
    deleteDevices,
    changeBlueprint,
    isPendingDelete,
    isPendingChangeBlueprint,
  };
};

export default useBulkActions;
