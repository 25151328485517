import { type IconNames, SelectV2 } from '@kandji-inc/nectar-ui';

interface ManualEnrollmentAuthSelectOption {
  label: string;
  value: string;
  icon: IconNames;
}

interface ManualEnrollmentAuthSelectProps {
  value: string;
  onValueChange: (value: string) => void;
  options: ManualEnrollmentAuthSelectOption[];
  disabled: boolean;
}

export function ManualEnrollmentAuthSelect({
  value,
  onValueChange,
  options,
  disabled = false,
}: ManualEnrollmentAuthSelectProps) {
  const triggerValue =
    options.find((o) => o.value === value)?.label || options[0].label;

  return (
    <SelectV2.Default
      value={value}
      onValueChange={onValueChange}
      options={options}
      triggerProps={{
        'data-testid': 'manual-enrollment-auth-select',
        readOnly: disabled,
        compact: true,
        disabled: disabled,
        variant: 'input',
        value: triggerValue,
        css: {
          minWidth: 320,
        },
      }}
    />
  );
}
