/* istanbul ignore file */
import { i18n } from 'i18n';
import React from 'react';
import addDevice from '../assets/add-device.svg';
import oathInt from '../assets/oath-int.svg';
import oktaVerifyOutline from '../assets/okta-verify-outline.svg';
import oktaIdentityEngine from './assets/oie.svg';
import oktaFastpass from './assets/okta-fastpass.svg';
import oktaIcon from './assets/okta-icon-outline.svg';

// Helpers
import {
  isFastpassDisabled,
  isIdentityEnginesDisabled,
} from '../../integrator/configs/okta/helper';

export default [
  {
    get text() {
      return i18n.t(
        'Learn how to set up, manage and deploy this integration with Okta to your Apple fleet.',
      );
    },
    get title() {
      return i18n.t('Okta Device Trust overview');
    },
    link: 'https://support.kandji.io/support/solutions/articles/72000580342',
    icon: oktaIcon,
  },
  {
    get text() {
      return i18n.t(
        'Before you get started, make sure that your Okta account includes Okta Identity Engine and FastPass is enabled.',
      );
    },
    get title() {
      return i18n.t('Okta Identity Engine and FastPass');
    },
    link: 'https://help.okta.com/oie/en-us/Content/Topics/identity-engine/oie-index.htm',
    icon: oktaIdentityEngine,
  },
];

export const oktaSignInTickets = [
  {
    get text() {
      return i18n.t(
        'In the Okta console, you will create an API Service Integration to establish a secure connection between Kandji and Okta API access.',
      );
    },
    get title() {
      return i18n.t('Create an API Service Integration');
    },
    link: 'https://support.kandji.io/support/solutions/articles/72000580346',
    icon: oathInt,
  },
  {
    get text() {
      return i18n.t(
        'Set up the device management providers your organization will require for device trust. Choose to set this up for macOS, iOS or both. ',
      );
    },
    get title() {
      return i18n.t('Add device platforms');
    },
    link: 'https://support.kandji.io/support/solutions/articles/72000580346#Configure-device-platforms-in-Kandji',
    icon: addDevice,
  },
];

export const oktaVerifyAppTicket = {
  get paragraphs() {
    return [
      i18n.t(
        'Sign in to Apple administrative account to obtain licenses for Okta Verify by clicking on your portal link below. Assign the licenses to the Location assigned to Kandji.',
      ),
      i18n.t(
        'Once that is complete, you can sync the Okta Verify app to your Library below or manually sync them later on the Library page.',
      ),
    ];
  },
  get title() {
    return i18n.t('Add Okta Verify to your Library');
  },
  icon: oktaVerifyOutline,
};

export const VerifyAppTicket = () => (
  <>
    <p className="b-txt-light b-mt-tiny b-mb-tiny">
      {i18n.t(
        'Sign in to Apple administrative account to obtain licenses for Okta Verify by clicking on your portal link below. Assign the licenses to the Location assigned to Kandji.',
      )}
    </p>
    <p className="b-txt-light b-mt-tiny b-mb-tiny">
      {i18n.t(
        'Once that is complete, you can sync the Okta Verify app to your Library below or manually sync them later on the Library page.',
      )}
    </p>
  </>
);
export const oktaVerifyAppTickets = [
  {
    text: <VerifyAppTicket />,
    get title() {
      return i18n.t('Add Okta Verify to your Library');
    },
    icon: oktaVerifyOutline,
    link: '',
  },
];

export const oktaSignInErrorTickets = [
  {
    get title() {
      return i18n.t('Okta Identity Engine must be on');
    },
    get text() {
      return i18n.t(
        'Sign into the Okta admin console and turn on Okta Identity Engine to complete this integration',
      );
    },
    link: 'https://help.okta.com/oie/en-us/Content/Topics/identity-engine/oie-index.htm',
    icon: oktaIdentityEngine,
    condition: isIdentityEnginesDisabled,
  },
  {
    get title() {
      return i18n.t('FastPass must be enabled');
    },
    get text() {
      return i18n.t('Enable FastPass for your Okta account');
    },
    link: 'https://help.okta.com/oie/en-us/Content/Topics/identity-engine/devices/fp/fp-main.htm',
    icon: oktaFastpass,
    condition: isFastpassDisabled,
  },
];

export const oktaSetupCompleteTickets = [
  {
    get title() {
      return i18n.t('Deploy device trust with Okta Verify');
    },
    get text() {
      return i18n.t(
        'Navigate to the Okta Verify Library Item and turn on the Okta Device Trust integration under settings. Assigning Okta Verify to the correct Blueprints will allow you to deploy device trust to your fleet.',
      );
    },
    link: 'https://support.kandji.io/support/solutions/articles/72000580418-okta-device-trust-configuring-the-okta-verify-library-item',
    icon: oktaVerifyOutline,
  },
];
