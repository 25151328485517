import { Flex } from '@kandji-inc/nectar-ui';
import { memo } from 'react';
import { constants } from '../../common';
import type { GetStatusCountsResponse } from '../../threat.types';
import ThreatListTileCard from './ThreatListTileCard';

type ThreatListTilesProps = {
  data: GetStatusCountsResponse | undefined;
  isLoading: boolean;
  isError: boolean;
  dateValue: string | undefined;
  displayDetectionPeriod: string;
};

const ThreatListTiles = (props: ThreatListTilesProps) => {
  const { data, isLoading, isError, dateValue, displayDetectionPeriod } = props;

  if (isError) {
    throw new Error('Data fetching error.');
  }

  const { malware, pup } = data || {};
  const dateRangeLabel = dateValue
    ? constants.THREAT_DETECTION_DATE_SELECT_CUSTOM_RANGE_OPTIONS.find(
        (item) => dateValue === item.value,
      )?.getLabel()
    : constants.THREAT_DETECTION_DATE_SELECT_CUSTOM_RANGE_OPTIONS[0]?.getLabel();
  const malwareTotalCount = malware?.total || 0;
  const pupTotalCount = pup?.total || 0;
  const malwareStatusCounts = {
    NOT_QUARANTINED: malware?.not_quarantined || 0,
    QUARANTINED: malware?.quarantined || 0,
    RELEASED: malware?.released || 0,
    RESOLVED: malware?.resolved || 0,
  };
  const pupStatusCounts = {
    NOT_QUARANTINED: pup?.not_quarantined || 0,
    QUARANTINED: pup?.quarantined || 0,
    RELEASED: pup?.released || 0,
    RESOLVED: pup?.resolved || 0,
  };

  return (
    <Flex
      gap="xl"
      py4
      px5
      css={{
        boxShadow:
          'var(--shadows-inset_border_bottom_1) var(--colors-neutral30)',
      }}
    >
      <ThreatListTileCard
        title="Malware"
        dateRangeLabel={dateRangeLabel}
        totalCount={malwareTotalCount}
        counts={malwareStatusCounts}
        isLoading={isLoading}
        displayDetectionPeriod={`${displayDetectionPeriod} any malware detections.`}
      />
      <ThreatListTileCard
        title="Potentially Unwanted Program (PUP)"
        dateRangeLabel={dateRangeLabel}
        totalCount={pupTotalCount}
        counts={pupStatusCounts}
        isLoading={isLoading}
        displayDetectionPeriod={`${displayDetectionPeriod} any PUP detections.`}
      />
    </Flex>
  );
};

export default memo(ThreatListTiles);
