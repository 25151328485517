import { Icon, setClass } from '@kandji-inc/bumblebee';
import React from 'react';
import { useHistory, useRouteMatch } from 'react-router';
import './nav-list.css';

export const onScroll = (id) => {
  const getOffsetTop = (element, container, paddingOffsetTop) => {
    const getElOffset = (el) => el?.offsetTop || 0;
    const elementOffsetTop = getElOffset(element);
    const containerOffsetTop = getElOffset(container);

    const zeroIfNegative = (num) => (num < 0 ? 0 : num);
    const getTopDiff = (elOffsetTop, extraOffsetTop) =>
      zeroIfNegative(elOffsetTop - extraOffsetTop);

    return getTopDiff(elementOffsetTop, containerOffsetTop + paddingOffsetTop);
  };

  const scrollTo = (node, scrollOpts) => node?.scrollTo(scrollOpts);

  const el = document.querySelector(`[id='${id}']`);

  if (el) {
    const bpPageMain = document.querySelector('.bl-blueprint-page__main');

    scrollTo(bpPageMain, {
      top: getOffsetTop(el, bpPageMain, 80),
      behavior: 'smooth',
    });
  }
};

const NavList = (props) => {
  const { options } = props;
  const history = useHistory();
  const { url } = useRouteMatch();

  return (
    <div className="bl-nav-list">
      {options.map((option, idx) => {
        if (option.type === 'line') {
          return <div key={idx} className="bl-nav-list__item-line" />;
        }
        const isActive = url === option.route;
        return (
          <div key={option.name} className="bl-nav-list__item">
            <a
              href=""
              onClick={(e) => {
                e.preventDefault();
                if (option.onClick) {
                  option.onClick();
                }
                if (option.route) {
                  history.push(option.route);
                }
              }}
              className={setClass(
                'pendo-blueprint-list',
                'bl-nav-list__item-header',
                'b-decorate-off',
                isActive && '--active-url',
              )}
            >
              <Icon
                name={option.icon}
                className="b-flex-hc b-flex-vc"
                size="md"
              />
              <p className={setClass(isActive ? 'b-txt-bold' : 'b-txt-light')}>
                {option.name}
              </p>
              {'count' in option && (
                <div
                  className={setClass(
                    'bl-nav-list__item-header-count',
                    !option.count &&
                      option.isPrimaryCount &&
                      '--is-empty-orange',
                  )}
                >
                  {option.count?.toLocaleString()}
                </div>
              )}
            </a>
            {isActive && option.items && (
              <div className="bl-nav-list__item-list">
                {option.items.map((item) => {
                  // hide category nav name if it has no items due to
                  // hidden config/feature flag
                  if (!item.items?.length) {
                    return null;
                  }

                  const isActiveCategory =
                    option.activeCategory?.category === item.id;
                  return (
                    <div
                      key={item.name}
                      className={setClass(
                        'bl-nav-list__item-list-header',
                        isActiveCategory && '--active-category',
                      )}
                      onClick={() => onScroll(`${item.name}-${item.id}`)}
                    >
                      <p
                        className={
                          isActiveCategory ? 'b-txt-bold' : 'b-txt-light'
                        }
                      >
                        {item.name}
                      </p>
                      {item.items && isActiveCategory && (
                        <div className="bl-nav-list__item-list-items">
                          {item.items.map((nestedItem) => {
                            const isActiveSubCategory =
                              option.activeCategory?.subCategory ===
                              nestedItem.id;
                            return (
                              <div
                                key={nestedItem.name}
                                className={setClass(
                                  'bl-nav-list__item-list-items-nested-item',
                                  isActiveSubCategory && '--active-subcategory',
                                )}
                                onClick={(e) => {
                                  e.stopPropagation();
                                  onScroll(
                                    `${nestedItem.name}-${nestedItem.id}`,
                                  );
                                }}
                              >
                                <p
                                  className={setClass(
                                    isActiveSubCategory
                                      ? 'b-txt-bold'
                                      : 'b-txt-light',
                                  )}
                                >
                                  {nestedItem.name}
                                </p>
                              </div>
                            );
                          })}
                        </div>
                      )}
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
};

export default NavList;
