import React from 'react';

import { Button } from '@kandji-inc/nectar-ui';
import { i18n } from 'i18n';
import ErrorBanner from './error-banner';
import ProgressBar from './progress-bar';
import RedownloadButton from './redownload-button';
import SuccessBanner from './success-banner';

type Props = Readonly<{
  showDownloadError?: boolean;
  showRedownloadButton?: boolean;
  downloadProgress?: number;
  onRedownloadClick?: () => void;
  downloadSuccessText?: string;
  progressText?: string;
  redownloadButtonText?: string;
  backOnClick?: () => void;
  showBackButton?: boolean;
  backButtonText?: string;
  customComponent: JSX.Element;
}>;

const FooterButtonsLeft = (props: Props) => {
  const {
    showDownloadError,
    downloadProgress,
    showRedownloadButton,
    downloadSuccessText,
    progressText,
    redownloadButtonText,
    showBackButton,
    backOnClick,
    onRedownloadClick,
    backButtonText = i18n.t('Back'),
    customComponent,
  } = props;

  const BackBtnOrCustomComponent = () => {
    if (customComponent) {
      return customComponent;
    }
    if (showBackButton) {
      return (
        <Button variant="subtle" onClick={backOnClick}>
          {backButtonText}
        </Button>
      );
    }
    return null;
  };

  return (
    <div className="b-grid-ctas">
      <BackBtnOrCustomComponent />
      {downloadProgress !== undefined &&
        downloadProgress > 0 &&
        downloadProgress < 100 && (
          <ProgressBar
            downloadProgress={downloadProgress}
            progressText={progressText}
          />
        )}
      {downloadProgress === 100 && (
        <SuccessBanner integrationDownloadSuccessText={downloadSuccessText} />
      )}
      {showDownloadError && <ErrorBanner />}
      {showRedownloadButton && (
        <RedownloadButton
          onClick={onRedownloadClick}
          buttonText={redownloadButtonText}
        />
      )}
    </div>
  );
};

export default FooterButtonsLeft;
