/* istanbul ignore file - legacy code, moved */
import { InfoBanner } from 'app/components/good/banners';
import { Row } from 'app/components/good/layout.styles';
import get from 'lodash/get';
import has from 'lodash/has';
import isEmpty from 'lodash/isEmpty';
import React from 'react';
import styled from 'styled-components';
import {
  CheckBox,
  DependenceInfo,
  Label,
  OptionalSubLabel,
  SubInfo,
  TagsWithLabel,
  TextBlock,
  TextInputLabel,
  TextInputLabelBefore,
  Wrapper2,
} from './SCEP.styles';
import { Tag } from './SCEPShortenedTags';

const TextInputBlock = styled('div')`
  display: flex;
  align-items: center;
  padding-top: 14px;
`;

const TextInput = styled('input')`
  font-family: var(--font-family-primary);
  appearance: none;
  outline: none;
  background: #ffffff;
  border: 2px solid ${(props) => (props.error ? 'red' : '#e5e5e5')};
  box-sizing: border-box;
  padding: 0 15px;
  border-radius: 4px;
  height: 40px;
  width: 50%;
  ${(props) => props.style && props.style.screenFull};
  @media (max-width: 768px) {
    width: 100%;
    ${(props) => props.style && props.style.screen768};
  }
  &::placeholder {
    opacity: 0.3;
  }
  width: ${(props) =>
    props.styles && props.styles.width ? props.styles.width : '65px'};
  :focus {
    border: 2px solid #1a1d25;
  }
`;

const SCEPLibraryOptionalNumber = ({
  id,
  isEditorMode,
  isDependenceDisable,
  disabled,
  value,
  onChange,
  label,
  tags,
  customSortTags,
  subLabel,
  subLabelLink,
  info,
  style,
  placeholder,
  onChangeSubField,
  isError,
  subFieldTextBefore,
  subFieldText,
  dependenceInfo,
  isAdaptive,
  withSidebar,
  onBlur,
}) => (
  <Wrapper2>
    <CheckBox
      id={`key-${id}`}
      type="checkbox"
      disabled={!isEditorMode || disabled}
      checked={has(value, 'checked') ? value.checked : false}
      onChange={onChange}
      isDependenceDisable={isDependenceDisable}
    />
    <TextBlock withSidebar={withSidebar} isAdaptive={isAdaptive}>
      <TagsWithLabel
        withSidebar={withSidebar}
        isDependenceDisable={isDependenceDisable}
      >
        <Label
          withSidebar={withSidebar}
          subLabel={subLabel}
          htmlFor={`key-${id}`}
          isDependenceDisable={isDependenceDisable}
        >
          {label}
        </Label>
        <Row spacing={0}>
          {!isEmpty(tags) &&
            tags
              .sort((a, b) => {
                if (customSortTags[a] > customSortTags[b]) {
                  return 1;
                }
                if (customSortTags[a] < customSortTags[b]) {
                  return -1;
                }
                return 0;
              })
              .map((tag, idx) => (
                <Tag light fontDark key={idx}>
                  {tag}
                </Tag>
              ))}
        </Row>
      </TagsWithLabel>
      <SubInfo withSidebar={withSidebar} isAdaptive={isAdaptive}>
        <OptionalSubLabel isDependenceDisable={isDependenceDisable}>
          {subLabel}
          {subLabelLink && (
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={subLabelLink.link}
            >
              {subLabelLink.text}
            </a>
          )}
        </OptionalSubLabel>
        {info && <InfoBanner margin="5px 0" text={info} />}
        {isDependenceDisable && dependenceInfo && (
          <DependenceInfo>{dependenceInfo}</DependenceInfo>
        )}
      </SubInfo>
      {get(value, 'checked') && (
        <TextInputBlock isAdaptive={isAdaptive}>
          {subFieldTextBefore && (
            <TextInputLabelBefore>{subFieldTextBefore}</TextInputLabelBefore>
          )}
          <TextInput
            styles={style}
            type="number"
            value={has(value, 'value') ? value.value : ''}
            placeholder={placeholder}
            disabled={!isEditorMode || isDependenceDisable || disabled}
            onChange={onChangeSubField}
            error={isError}
            style={style}
            onBlur={onBlur}
          />
          <TextInputLabel> {subFieldText}</TextInputLabel>
        </TextInputBlock>
      )}
    </TextBlock>
  </Wrapper2>
);

export default SCEPLibraryOptionalNumber;
