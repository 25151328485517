import { Select, TextInput, Toggle } from '@kandji-inc/bumblebee';
import deepcopy from 'deepcopy';
import React, { useEffect, useState } from 'react';

const enabledOptions = [
  { label: 'Disabled', value: '0' },
  { label: 'Enabled', value: '1' },
];

const rootLoginOptions = [
  { label: 'Disabled', value: '0' },
  { label: 'Enabled -- Using key', value: '1' },
];

const defaultData = {
  challenge_response_authentication: '0',
  client_timeout: 900,
  keep_alive_messages: '0',
  login_grace_period: 30,
  max_auth_tries: 4,
  pubkey_authentication: '0',
  remove_non_fips_ciphers: false,
  remove_non_fips_macs: false,
  root_login: '0',
};

const ManageSSHConfig = (props) => {
  const { param, update, isDisabled, validations } = props;
  const { details } = param;
  const [value, setValue] = useState(details || deepcopy(defaultData));
  const [isInvalid, setIsInvalid] = useState([false, false, false]);

  const {
    ManageSSHConfig_loginGracePeriod: validateLoginGracePeriod,
    ManageSSHConfig_maxAuthTries: validateMaxAuthTries,
    ManageSSHConfig_clientTimeout: validateClientTimeout,
  } = validations;

  useEffect(() => {
    if (isDisabled) {
      setValue(details);
      setIsInvalid([false, false, false]);
    }
  }, [param, isDisabled]);

  useEffect(() => {
    if (!isDisabled) {
      const time = setTimeout(() => {
        update({
          details: {
            ...value,
            login_grace_period: parseInt(value.login_grace_period),
            client_timeout: parseInt(value.client_timeout),
            max_auth_tries: parseInt(value.max_auth_tries),
          },
          isInvalid: isInvalid.some(Boolean),
        });
      }, 250);

      return () => {
        clearTimeout(time);
      };
    }
  }, [value]);

  return (
    <div
      className="b-mb"
      style={{
        display: 'grid',
        gridTemplateColumns: '1fr 1fr',
        gridGap: 'var(--b-gap1)',
        rowGap: 'var(--b-gap2)',
      }}
    >
      <div className="b-flex-vg1">
        <p className={`b-txt${isDisabled ? '-light2' : ''}`}>
          Challenge response authentication
        </p>
        <Select
          disabled={isDisabled}
          options={enabledOptions}
          value={enabledOptions.find(
            (v) => v.value === value.challenge_response_authentication,
          )}
          onChange={({ value }) =>
            setValue((prev) => ({
              ...prev,
              challenge_response_authentication: value,
            }))
          }
        />
      </div>
      <div className="b-flex-vg1">
        <p className={`b-txt${isDisabled ? '-light2' : ''}`}>
          Pubkey authentication
        </p>
        <Select
          disabled={isDisabled}
          options={enabledOptions}
          value={enabledOptions.find(
            (v) => v.value === value.pubkey_authentication,
          )}
          onChange={({ value }) =>
            setValue((prev) => ({
              ...prev,
              pubkey_authentication: value,
            }))
          }
        />
      </div>
      <div className="b-flex-vg1">
        <p className={`b-txt${isDisabled ? '-light2' : ''}`}>Root login</p>
        <Select
          disabled={isDisabled}
          options={rootLoginOptions}
          value={rootLoginOptions.find((v) => v.value === value.root_login)}
          onChange={({ value }) =>
            setValue((prev) => ({
              ...prev,
              root_login: value,
            }))
          }
        />
      </div>
      <div className="b-flex-vg1">
        <p className={`b-txt${isDisabled ? '-light2' : ''}`}>
          Keep alive messages
        </p>
        <Select
          disabled={isDisabled}
          options={enabledOptions}
          value={enabledOptions.find(
            (v) => v.value === value.keep_alive_messages,
          )}
          onChange={({ value }) =>
            setValue((prev) => ({
              ...prev,
              keep_alive_messages: value,
            }))
          }
        />
      </div>
      <div className="b-mb-tiny">
        <TextInput
          fieldsGrid
          type="number"
          disabled={isDisabled}
          label="Login grace period"
          value={value.login_grace_period}
          onChange={(e) => {
            const val = e.target.value;
            setValue((prev) => ({
              ...prev,
              login_grace_period: val,
            }));
          }}
          validator={(v) => [
            {
              message: 'Required',
              invalid: () => !v,
              trigger: ['onBlur', 'onInput'],
            },
            {
              message: 'Value not in range. Min: 1 - Max: 99999',
              invalid: () => v < 1 || v > 99999,
              trigger: ['onBlur', 'onInput'],
            },
          ]}
          onInvalidate={(isInvalid) =>
            setIsInvalid((prev) => {
              prev[0] = isInvalid;
              return [...prev];
            })
          }
          {...validateLoginGracePeriod.syncInvalid(isInvalid[0])}
        />
      </div>
      <div className="b-mb-tiny">
        <TextInput
          fieldsGrid
          type="number"
          disabled={isDisabled}
          label="Max auth tries"
          value={value.max_auth_tries}
          onChange={(e) => {
            const val = e.target.value;
            setValue((prev) => ({
              ...prev,
              max_auth_tries: val,
            }));
          }}
          validator={(v) => [
            {
              message: 'Required',
              invalid: () => !v,
              trigger: ['onBlur', 'onInput'],
            },
            {
              message: 'Value not in range. Min: 1 - Max: 99999',
              invalid: () => v < 1 || v > 99999,
              trigger: ['onBlur', 'onInput'],
            },
          ]}
          onInvalidate={(isInvalid) =>
            setIsInvalid((prev) => {
              prev[1] = isInvalid;
              return [...prev];
            })
          }
          {...validateMaxAuthTries.syncInvalid(isInvalid[1])}
        />
      </div>
      <div className="b-flex-vg1">
        <div className="b-flex-vc b-flex-g1">
          <Toggle
            checked={value.remove_non_fips_ciphers}
            disabled={isDisabled}
            onToggle={(v) =>
              setValue((prev) => ({ ...prev, remove_non_fips_ciphers: v }))
            }
          />
          <p className={`b-txt${isDisabled ? '-light2' : ''}`}>
            Remove non-fips Ciphers
          </p>
        </div>
        <div className="b-flex-vc b-flex-g1">
          <Toggle
            checked={value.remove_non_fips_macs}
            disabled={isDisabled}
            onToggle={(v) =>
              setValue((prev) => ({ ...prev, remove_non_fips_macs: v }))
            }
          />
          <p className={`b-txt${isDisabled ? '-light2' : ''}`}>
            Remove non-fips MACs
          </p>
        </div>
      </div>
      <div />
      <div>
        <TextInput
          fieldsGrid
          type="number"
          disabled={isDisabled}
          label="Client timeout"
          value={value.client_timeout}
          onChange={(e) => {
            const val = e.target.value;
            setValue((prev) => ({
              ...prev,
              client_timeout: val,
            }));
          }}
          validator={(v) => [
            {
              message: 'Required',
              invalid: () => !v,
              trigger: ['onBlur', 'onInput'],
            },
            {
              message: 'Value not in range. Min: 1 - Max: 99999',
              invalid: () => v < 1 || v > 99999,
              trigger: ['onBlur', 'onInput'],
            },
          ]}
          onInvalidate={(isInvalid) =>
            setIsInvalid((prev) => {
              prev[2] = isInvalid;
              return [...prev];
            })
          }
          {...validateClientTimeout.syncInvalid(isInvalid[2])}
        />
      </div>
    </div>
  );
};

export default ManageSSHConfig;
