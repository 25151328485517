import React from 'react';
import { components } from 'react-select';

const ValueContainer = (props) => {
  const { children } = props;
  return (
    <components.ValueContainer {...props} className="b-txt">
      {children}
    </components.ValueContainer>
  );
};

export default ValueContainer;
