import { arrayOf, elementType, object, string } from 'prop-types';
import React from 'react';
import './one-category-block.styles.scss';

function OneCategoryBlock({ title, items, extraPropsForCard, CardComponent }) {
  return (
    <>
      <h3 className="b-h3 b-mt3 b-mb">{title}</h3>
      <div className="int-category_cards-container">
        {items.map((item, idx) => (
          <CardComponent
            key={idx}
            integrationData={item}
            {...extraPropsForCard}
          />
        ))}
      </div>
    </>
  );
}

OneCategoryBlock.propTypes = {
  title: string.isRequired,
  items: arrayOf(object).isRequired,
  extraPropsForCard: object,
  CardComponent: elementType.isRequired,
};

OneCategoryBlock.defaultProps = {
  extraPropsForCard: {},
};

export default OneCategoryBlock;
