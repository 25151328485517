/* istanbul ignore file */

import { i18n } from 'i18n';
import React from 'react';

import { formatTime } from '../../common/helpers';

const entry = ({ details }) => {
  const { 'certificate subject': certificate_subject } = details;
  return {
    icon: 'triangle-exclamation',
    iconText: i18n.t('AD CS certificate failed'),
    text: i18n.t('AD CS certificate failed'),
    description: certificate_subject,
  };
};

const detail = ({ action_type, details }) => {
  const {
    time,
    library_item,
    library_item_name,
    'certificate subject': certificate_subject,
  } = details;

  return (
    <div className={`activity-entry-details ${action_type}`}>
      <p>Failed on: {formatTime(time, false, null, null, true)}</p>
      <p>Library Item: {library_item}</p>
      <p>Library Item name: {library_item_name}</p>
      <p>Subject: {certificate_subject}</p>
    </div>
  );
};

export default { entry, detail };
