/* istanbul ignore file - legacy code, moved */
import { Icon } from '@kandji-inc/bumblebee';
import { formatTime } from 'app/components/common/helpers';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import React, { useCallback, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { UncontrolledTooltip } from 'reactstrap';
import styled from 'styled-components';
import { Label, SubInfo, SubLabel, TextBlock } from './SCEP.styles';
import closePasswordEye from './SCEPPasswordEyeClosed.svg';
import openPasswordEye from './SCEPPasswordEyeOpen.svg';

const LabelSubInput = styled('span')`
  font-weight: 400;
  opacity: 0.5;
`;

const TextInput = styled('input')`
  font-family: var(--font-family-primary);
  appearance: none;
  outline: none;
  background: #ffffff;
  border: 2px solid ${(props) => (props.error ? 'red' : '#e5e5e5')};
  box-sizing: border-box;
  padding: 0 15px;
  border-radius: 4px;
  height: 40px;
  width: 400px;
  &::placeholder {
    opacity: 0.3;
  }
  :focus {
    border: 2px solid #1a1d25;
  }
`;

const LastPassUpdate = styled('div')`
  display: flex;
  align-items: center;

  background: #eef0f6;
  border-radius: 4px;
  height: 40px;
  margin-left: 10px;
  padding: 10px 15px;

  font-family: var(--font-family-primary);
  font-size: 14px;
  line-height: 20px;
  color: #4d5a79;
  i {
    color: #4d5a79;
    font-size: 20px;
    margin-right: 9px;
  }
  div {
    margin-bottom: -2px;
  }
`;

const PasswordImage = styled('img')`
  margin-left: -35px;
`;

const SCEPPasswordValidator = ({
  index,
  text,
  verifyText,
  isEditorMode,
  isOptional,
  value,
  verifiedValue,
  isNeedValidation,
  isShowHideIcon,
  subLabel,
  setVerifiedValue,
  onChangeInput,
  valueUpdatedAt,
  saveButtonClicked,
  isHideEmpty,
  isDisplay,
  isAdaptive,
  placeholder,
}) => {
  const [isHide, setIsHide] = useState(true);
  const onChangeVerifyPassword = useCallback((idx, value, compareValue) => {
    setVerifiedValue((prev) =>
      prev.map((p, i) =>
        idx === i ? { error: compareValue !== value, value } : p,
      ),
    );
  }, []);

  if (
    !isDisplay ||
    (isHideEmpty && !isEditorMode && (value === '' || value === null))
  ) {
    return null;
  }

  return (
    <>
      <TextBlock isAdaptive={isAdaptive}>
        <Label subLabel={subLabel} htmlFor={`key-${index}`}>
          {text}
          {isOptional && <LabelSubInput> (optional) </LabelSubInput>}
        </Label>
        <div className="d-flex align-items-center">
          <TextInput
            isAdaptive={isAdaptive}
            type={isHide ? 'password' : 'text'}
            autocomplete="off"
            disabled={!isEditorMode}
            placeholder={placeholder}
            value={value}
            error={!isOptional && isEmpty(value) && saveButtonClicked}
            onChange={(e) => {
              onChangeInput(e.target.value);
              if (isNeedValidation) {
                onChangeVerifyPassword(
                  index,
                  verifiedValue[index].value,
                  e.target.value,
                );
              }
            }}
          />
          {isShowHideIcon && (
            <PasswordImage
              src={isHide ? closePasswordEye : openPasswordEye}
              onClick={() => setIsHide(!isHide)}
            />
          )}
          {valueUpdatedAt && (
            <LastPassUpdate>
              <Icon name="circle-info" id={`lastPassUpdated${index}`} />
              <UncontrolledTooltip
                placement="top"
                delay={{ show: 400, hide: 0 }}
                innerClassName="custom-helper"
                target={`lastPassUpdated${index}`}
              >
                {formatTime(valueUpdatedAt, null, null, null, true)}
              </UncontrolledTooltip>
              <div>
                {`Set on ${formatTime(
                  valueUpdatedAt,
                  true,
                  null,
                  true,
                  false,
                  'MMMM DD, YYYY',
                )}`}
              </div>
            </LastPassUpdate>
          )}
        </div>
        <SubInfo isAdaptive={isAdaptive}>
          <SubLabel isAdaptive={isAdaptive}>{subLabel}</SubLabel>
        </SubInfo>
      </TextBlock>
      {isEditorMode && isNeedValidation && (
        <>
          <TextBlock isAdaptive={isAdaptive}>
            <Label subLabel={subLabel} htmlFor={`key-${index}`}>
              {verifyText}
            </Label>
            <TextInput
              isAdaptive={isAdaptive}
              value={verifiedValue[index].value}
              type="password"
              autocomplete="off"
              disabled={!isEditorMode}
              onChange={(e) =>
                onChangeVerifyPassword(index, e.target.value, value)
              }
              error={verifiedValue[index].error && saveButtonClicked}
            />
            <SubInfo isAdaptive={isAdaptive}>
              <SubLabel isAdaptive={isAdaptive}>{subLabel}</SubLabel>
            </SubInfo>
          </TextBlock>
        </>
      )}
    </>
  );
};

SCEPPasswordValidator.propTypes = {
  index: PropTypes.number.isRequired,
  text: PropTypes.string.isRequired,
  verifyText: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  subLabel: PropTypes.string,
  isEditorMode: PropTypes.bool,
  isOptional: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  verifiedValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  setVerifiedValue: PropTypes.func.isRequired,
  onChangeInput: PropTypes.func.isRequired,
  valueUpdatedAt: PropTypes.number,
  saveButtonClicked: PropTypes.func.isRequired,
  isHideEmpty: PropTypes.bool,
  isDisplay: PropTypes.bool,
  isNeedValidation: PropTypes.bool,
  isAdaptive: PropTypes.bool,
  isShowHideIcon: PropTypes.bool.isRequired,
};

SCEPPasswordValidator.defaultProps = {
  isOptional: false,
  valueUpdatedAt: null,
  isHideEmpty: false,
  isDisplay: true,
  isNeedValidation: true,
  isAdaptive: false,
  placeholder: '',
  subLabel: '',
  isEditorMode: false,
};

const mapStateToProps = (state) => ({
  sidebarDocked: state.ui.sidebar.docked,
});

export default withRouter(connect(mapStateToProps)(SCEPPasswordValidator));
