import { createColumnHelper } from '@tanstack/react-table';
/* istanbul ignore file */
import type { z } from 'zod';

import {
  YES_NO_CELL_FILTER_OPTIONS,
  YesNoCell,
  extractFilterableColumns,
  getFilteredDisabled as filterDisabled,
  getCodeSnippetCell,
} from '../column-utils';
import { getGlobalColumns } from './globalColumns';

export const getSystemExtensionsColumns = (schema) => {
  if (!schema) {
    return { columnDefs: [], filterableColumns: [] };
  }

  const systemExtensionsColumnHelper =
    createColumnHelper<z.infer<typeof schema>>();
  const globalColumns = getGlobalColumns(schema, systemExtensionsColumnHelper);

  const columnDefs = [
    ...globalColumns.startColumnDefs,

    systemExtensionsColumnHelper.accessor((row) => row.name, {
      id: 'name',
      meta: {
        displayName: 'Name',
        filterType: 'string',
        filterIcon: 'text-size',
        filterDisabled,
      },
    }),

    systemExtensionsColumnHelper.accessor((row) => row.identifier, {
      id: 'identifier',
      meta: {
        displayName: 'ID',
        filterType: 'string',
        filterIcon: 'text-size',
        filterDisabled,
      },
    }),

    systemExtensionsColumnHelper.accessor((row) => row.short_version, {
      id: 'short_version',
      meta: {
        displayName: 'Short Version',
        filterType: 'version',
        filterIcon: 'hashtag',
        filterDisabled,
      },
    }),

    systemExtensionsColumnHelper.accessor((row) => row.is_mdm_managed, {
      id: 'is_mdm_managed',
      cell: YesNoCell,
      meta: {
        displayName: 'MDM Managed',
        filterType: 'boolean',
        filterOptions: [...YES_NO_CELL_FILTER_OPTIONS],
        filterIcon: 'circle-dot',
        filterDisabled,
      },
    }),

    systemExtensionsColumnHelper.accessor((row) => row.state, {
      id: 'state',
      meta: {
        displayName: 'State',
        filterType: 'string',
        filterIcon: 'text-size',
        filterDisabled,
      },
    }),

    systemExtensionsColumnHelper.accessor((row) => row.path, {
      id: 'path',
      cell: getCodeSnippetCell,
      meta: {
        displayName: 'Path',
        filterType: 'string',
        filterIcon: 'text-size',
        filterDisabled,
      },
    }),

    systemExtensionsColumnHelper.accessor((row) => row.bundle_version, {
      id: 'bundle_version',
      meta: {
        defaultHidden: true,
        displayName: 'Bundle Version',
        filterType: 'version',
        filterIcon: 'hashtag',
        filterDisabled,
      },
    }),

    systemExtensionsColumnHelper.accessor((row) => row.categories, {
      id: 'categories',
      meta: {
        defaultHidden: true,
        displayName: 'Categories',
        filterType: 'string',
        filterIcon: 'text-size',
        filterDisabled,
      },
    }),

    systemExtensionsColumnHelper.accessor((row) => row.bundle_path, {
      id: 'bundle_path',
      cell: getCodeSnippetCell,
      meta: {
        defaultHidden: true,
        displayName: 'Bundle Path',
        filterType: 'string',
        filterIcon: 'text-size',
        filterDisabled,
      },
    }),

    systemExtensionsColumnHelper.accessor((row) => row.team_id, {
      id: 'team_id',
      meta: {
        defaultHidden: true,
        displayName: 'Team ID',
        filterType: 'string',
        filterIcon: 'text-size',
        filterDisabled,
      },
    }),

    ...globalColumns.endColumnDefs,
  ];

  const filterableColumns = extractFilterableColumns(columnDefs);

  return { columnDefs, filterableColumns };
};
