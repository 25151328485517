import { useMutation } from '@tanstack/react-query';
import { msTeamsService } from '../services/teams';

type Channels = string[];
type Events = string[];

type Data = {
  channels: Channels;
  events: Events;
  name: string;
};

type Notification = {
  id: string;
  data: Data;
};

const useUpdateNotification = () =>
  useMutation({
    mutationFn: (notification: Notification) =>
      msTeamsService.updateNotification(notification.id, notification.data),
  });

export default useUpdateNotification;
