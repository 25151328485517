import styled from 'styled-components';

export const StyledImg = styled.img`
  width: 72px;
`;

export const DataContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
`;

export const CardSubheading = styled.div`
  display: flex;
  width: 100%;
`;
