import {
  Badge,
  Box,
  Button,
  Card,
  Chip,
  Flex,
  Icon,
  Link,
  Text,
  Tooltip,
  styled,
} from '@kandji-inc/nectar-ui';
import { useEffect, useRef, useState } from 'react';
import { links } from 'src/app/common/constants';
import type { Application } from 'src/features/edr/vulnerability/vulnerability.types';
import { i18n } from 'src/i18n';
import { ApplicationVersionItem } from './application-version-item';

const DetailText = styled(Text, {
  fontSize: '$1',
  color: '$neutral70',
});

type ApplicationCardProps = {
  application: Application;
  isExpanded: boolean;
  setIsExpanded: (value: boolean) => void;
};

const ApplicationCard = (props: ApplicationCardProps) => {
  const { application, isExpanded, setIsExpanded } = props;
  const {
    name,
    num_versions: numVersions,
    type,
    blueprints,
    num_devices: numDevices,
    application_versions: applicationVersions,
  } = application;

  const applicationVersionListLimit = 5;
  const [isShowingMore, setIsShowingMore] = useState(false);
  const [showArrow, setShowArrow] = useState(false);
  const scrollToRef = useRef(null);
  const showLessButtonRef = useRef(null);
  const translatedTotalApplicationVersions = i18n.formatNumber(
    applicationVersions.length,
  );

  useEffect(
    /* istanbul ignore next */ () => {
      const observer = new IntersectionObserver(
        ([entry]) => {
          // Set showArrow to true if the "Show less" button is not intersecting
          if (showLessButtonRef.current) {
            setShowArrow(
              // Checking that `top` is less than 150 prevents the arrow from appearing
              // when the "Show less" button is hidden at the bottom of the page
              showLessButtonRef.current.getBoundingClientRect().top < 150 &&
                !entry.isIntersecting,
            );
          }
        },
        { root: null, threshold: 1 }, // Trigger when the button is completely out of view
      );

      if (showLessButtonRef.current) {
        observer.observe(showLessButtonRef.current);
      }

      return () => observer.disconnect();
    },
    [isShowingMore],
  );

  return (
    <Card
      variant={isExpanded ? 'elevate-2' : 'flat'}
      hoverVariant="elevate-2"
      css={{ padding: '$0' }}
    >
      <Flex
        flow="column"
        css={{ gap: 6, scrollMarginTop: '34px' }}
        ref={scrollToRef}
      >
        <Flex
          alignItems="center"
          gap="xs"
          p4
          onClick={() => {
            setIsExpanded(!isExpanded);
            setIsShowingMore(false);
          }}
          css={{
            paddingBottom: isExpanded ? '$1' : '$4',
            '&:hover': { cursor: 'pointer' },
          }}
        >
          <Box
            css={{
              width: 16,
              height: 16,
              svg: { width: 16, height: 16, verticalAlign: 'top' },
            }}
          >
            <Icon
              name={isExpanded ? 'fa-angle-up-small' : 'fa-angle-down-small'}
              color="var(--color-neutral-70)"
            />
          </Box>

          <Flex flow="column" gap="sm">
            <Flex alignItems="center" gap="sm">
              <Text size="3" css={{ fontWeight: '$medium' }}>
                {name}
              </Text>
              {numVersions > 1 && (
                <Badge
                  compact
                >{`${i18n.formatNumber(numVersions)} ${i18n.$t('versions')}`}</Badge>
              )}
            </Flex>

            <Flex alignItems="center" css={{ gap: '6px' }}>
              {type && <DetailText>{type}</DetailText>}
              <Flex alignItems="center">
                <Icon
                  name="kandji-blueprint"
                  size="xs"
                  color="var(--colors-neutral70)"
                />
                <Link
                  href={`${links.blueprints}/${blueprints[0].id}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  variant="subtle"
                  css={{
                    '&:hover': {
                      textDecorationColor: '$neutral70',
                    },
                  }}
                >
                  <DetailText
                    css={{ fontWeight: '$medium', paddingLeft: '2px' }}
                  >
                    {blueprints[0].name}
                  </DetailText>
                </Link>
                {blueprints.length > 1 && (
                  <>
                    <DetailText>,</DetailText>
                    <Tooltip
                      side="bottom"
                      theme="light"
                      interactive
                      css={{ zIndex: 2, padding: '$3 $4', maxHeight: '190px' }}
                      content={
                        <Flex
                          flow="column"
                          css={{
                            width: '156px',
                            gap: '6px',
                          }}
                        >
                          {blueprints.slice(1).map((bp) => (
                            <Flex alignItems="center">
                              <Icon
                                name="kandji-blueprint"
                                size="xs"
                                color="var(--colors-neutral80)"
                              />
                              <Link
                                href={`${links.blueprints}/${bp.id}`}
                                target="_blank"
                                rel="noopener noreferrer"
                                variant="subtle"
                              >
                                <Text
                                  css={{
                                    fontWeight: '$medium',
                                    paddingLeft: '2px',
                                  }}
                                >
                                  {bp.name}
                                </Text>
                              </Link>
                            </Flex>
                          ))}
                        </Flex>
                      }
                    >
                      <DetailText
                        css={{ paddingLeft: '$1' }}
                      >{`+${i18n.formatNumber(blueprints.length - 1)}`}</DetailText>
                    </Tooltip>
                  </>
                )}
              </Flex>
              <Chip
                color="unfilled"
                iconLeft={{ icon: 'sf-desktop-computer', size: 'sm' }}
                label={numDevices}
                css={{ fontSize: '$1', height: '$4' }}
              />
            </Flex>
          </Flex>
        </Flex>

        {isExpanded && (
          <Flex flow="column" wFull css={{ padding: '0 $4 $3' }}>
            {/* First set of application versions shown by default*/}
            {applicationVersions
              .slice(0, applicationVersionListLimit)
              .map((applicationVersion, idx) => {
                const isLastItem =
                  idx ===
                  Math.min(
                    applicationVersions.length,
                    applicationVersionListLimit,
                  ) -
                    1;
                return (
                  <Flex wFull flow="column">
                    {idx === 0 && (
                      <Box
                        wFull
                        css={{ height: '1px', backgroundColor: '$neutral20' }}
                      />
                    )}
                    <ApplicationVersionItem
                      applicationVersion={applicationVersion}
                      css={{
                        paddingTop: '$3',
                        paddingBottom: isLastItem ? '$2' : '$3',
                      }}
                    />
                    {!isLastItem && (
                      <Box
                        wFull
                        css={{ height: '1px', backgroundColor: '$neutral20' }}
                      />
                    )}
                  </Flex>
                );
              })}

            {/* Show more/less button */}
            {applicationVersions.length > applicationVersionListLimit && (
              <Flex
                flow="row"
                alignItems="center"
                css={{ gap: '20px' }}
                ref={showLessButtonRef}
              >
                <Box
                  wFull
                  css={{ height: '1px', backgroundColor: '$neutral20' }}
                />
                <Button
                  variant="link"
                  compact
                  icon={{ name: 'arrows-to-line' }}
                  onClick={() => setIsShowingMore(!isShowingMore)}
                >
                  {isShowingMore
                    ? i18n.$t('Show less')
                    : i18n.$t('Show all ({total})', {
                        total: translatedTotalApplicationVersions,
                      })}
                </Button>
                <Box
                  wFull
                  css={{ height: '1px', backgroundColor: '$neutral20' }}
                />
              </Flex>
            )}

            {/* Items after the Show more/less button */}
            {applicationVersions.length > applicationVersionListLimit &&
              isShowingMore &&
              applicationVersions
                .slice(applicationVersionListLimit)
                .map((applicationVersion, idx) => {
                  const isFirstItem = idx === 0;
                  const isLastItem =
                    idx + applicationVersionListLimit ===
                    applicationVersions.length - 1;
                  return (
                    <Flex wFull flow="column">
                      <ApplicationVersionItem
                        applicationVersion={applicationVersion}
                        css={{
                          paddingTop: isFirstItem ? '$2' : '$3',
                          paddingBottom: isLastItem ? '$1' : '$3',
                        }}
                      />
                      {!isLastItem && (
                        <Box
                          wFull
                          css={{ height: '1px', backgroundColor: '$neutral20' }}
                        />
                      )}
                    </Flex>
                  );
                })}
          </Flex>
        )}
      </Flex>

      {showArrow && (
        <Flex
          justifyContent="end"
          css={{ position: 'sticky', bottom: 60, height: 0, paddingRight: 53 }}
        >
          <Button
            icon={{ name: 'arrow-up-to-line' }}
            onClick={
              /* istanbul ignore next */ () => {
                if (scrollToRef.current) {
                  scrollToRef.current.scrollIntoView({
                    behavior: 'smooth',
                    block: 'start',
                  });
                }
              }
            }
            css={{ height: 36, marginTop: -50 }}
          />
        </Flex>
      )}
    </Card>
  );
};

export { ApplicationCard };
