import { useEffect } from 'react';

const onWindowUnload = (
  callback: (e: BeforeUnloadEvent) => void,
  deps = [],
) => {
  useEffect(() => {
    const onWindowUnload = (e: BeforeUnloadEvent) => callback(e);

    window.addEventListener('beforeunload', onWindowUnload);
    return () => {
      window.removeEventListener('beforeunload', onWindowUnload);
    };
  }, deps);
};

export default onWindowUnload;
