import React, { useState } from 'react';

import Ticket from 'src/features/integrations/components/ticket';
import styled from 'styled-components';

import useOktaLicense from 'features/integrations/components-for-specific-types/okta/hooks/use-okta-license';
import { syncVPPData } from 'src/app/components/integrations/Apple/api';
import FooterButtons from '../../../components/footer-buttons';
import FooterStatus from '../../../components/footer-status/footer-status';
import {
  Container,
  ModalContent,
  StyledSlideHeader,
} from '../../../components/styled-components';
import useModal from '../../../hooks/use-modal';
import odtCompleteIcon from '../assets/odt-complete.svg';
import type { FooterStatusState, LibraryItem } from '../types';
import arrowUpRightSquareIcon from './assets/arrow-up-right-from-square.svg';
import footerIcon from './assets/okta-small.svg';
import { oktaSlideMap } from './okta-slide-map';
import { oktaVerifyAppTicket } from './tickets';

const TicketContainer = styled.div`
  text-align: left;
`;

const LinkContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: var(--b-gap3);
  margin-top: var(--b-gap3);
`;

const AddOktaVerifyLibrary = () => {
  const [footerStatusState, setFooterStatusState] =
    useState<FooterStatusState>('success');

  const [isWorking, setIsWorking] = useState(false);
  const { nextModal } = useModal();
  const { getLicenseCount } = useOktaLicense({ disableAutoFetch: true });

  const handleSync = async () => {
    setIsWorking(true);
    setFooterStatusState('pending');
    await syncVPPData();
    const licenseData = (await getLicenseCount()) as LibraryItem;
    setIsWorking(false);

    if (licenseData) {
      nextModal(
        oktaSlideMap.setupComplete({
          libraryItemId: licenseData.id,
          footerOptions: {
            success: {
              title: 'Okta Verify licenses synced.',
              statusText: 'Completed',
            },
          },
        }),
      );
    } else {
      setFooterStatusState('failed');
    }
  };

  const handleSetupLater = () => {
    nextModal(
      oktaSlideMap.setupComplete({ libraryItemId: null, isSetupLater: true }),
    );
  };

  return (
    <Container>
      <ModalContent>
        <img src={odtCompleteIcon} className="b-mb2" alt="header-icons" />
        <StyledSlideHeader>
          The Okta Verify app is not in your Library
        </StyledSlideHeader>
        <p className="b-txt-light">
          You can use Kandji&apos;s integration with Apps and Books in Apple
          Business Manager to install Okta Verify on your devices.{' '}
          <a
            href="https://support.kandji.io/support/solutions/articles/72000580418-okta-device-trust-configuring-the-okta-verify-library-item"
            rel="noopener noreferrer"
            target="_blank"
            className="b-alink"
          >
            Learn More
          </a>
        </p>
        <TicketContainer>
          <Ticket
            icon={oktaVerifyAppTicket.icon}
            text={
              <>
                {oktaVerifyAppTicket.paragraphs.map((text, i) => (
                  <p key={i} className="b-txt-light b-mt-tiny b-mb-tiny">
                    {text}
                  </p>
                ))}
              </>
            }
            title={oktaVerifyAppTicket.title}
          />
        </TicketContainer>
        <LinkContainer>
          <a
            href="https://business.apple.com/"
            rel="noopener noreferrer"
            target="_blank"
            className="b-alink b-text"
          >
            <img src={arrowUpRightSquareIcon} alt="up-arrow-right" />
            Apple Business Manager
          </a>

          <a
            href="https://school.apple.com/"
            rel="noopener noreferrer"
            target="_blank"
            className="b-alink b-text"
          >
            <img src={arrowUpRightSquareIcon} alt="up-arrow-right" />
            Apple School Manager
          </a>
        </LinkContainer>
      </ModalContent>
      <FooterButtons
        handleNext={handleSync}
        nextButtonText="Sync now"
        cancelButtonText="Set this up later"
        onClose={handleSetupLater}
        isWorking={isWorking}
        showStepper
        stepperIdx={5}
        stepsAmount={6}
        customComponent={
          footerStatusState ? (
            <FooterStatus
              icon={footerIcon}
              status={footerStatusState}
              options={{
                success: {
                  title: 'Okta Device Trust is successfully connected.',
                  statusText: 'Completed',
                },
                pending: {
                  title: 'Confirming Okta Verify licensing...',
                  statusText: 'In progress',
                },
                failed: {
                  title: 'Okta Verify licenses not found',
                  statusText: 'Not synced',
                },
              }}
            />
          ) : undefined
        }
      />
    </Container>
  );
};

export default AddOktaVerifyLibrary;
