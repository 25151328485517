import { Icon } from '@kandji-inc/bumblebee';
import React from 'react';
import { components } from 'react-select';

const DropdownIndicator = ({ selectProps, ...props }) => {
  const { menuIsOpen } = selectProps;

  return (
    <components.DropdownIndicator {...props}>
      <Icon
        name="angle-down"
        className={`cmp-select_dropdown-arrow ${
          menuIsOpen && 'cmp-select_dropdown-arrow--open'
        }`}
      />
    </components.DropdownIndicator>
  );
};

export default DropdownIndicator;
