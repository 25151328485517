import { createColumnHelper } from '@tanstack/react-table';
/* istanbul ignore file */
import type { z } from 'zod';

import {
  NULL_VALUE_N_DASH,
  YES_NO_CELL_FILTER_OPTIONS,
  YesNoCell,
  extractFilterableColumns,
  getFilteredDisabled as filterDisabled,
  getCodeSnippetCell,
} from '../column-utils';
import { getGlobalColumns } from './globalColumns';

export const getDesktopAndScreensaverColumns = (schema) => {
  if (!schema) {
    return { columnDefs: [], filterableColumns: [] };
  }

  const desktopAndScreensaverColumnHelper =
    createColumnHelper<z.infer<typeof schema>>();
  const globalColumns = getGlobalColumns(
    schema,
    desktopAndScreensaverColumnHelper,
  );

  const hotCornerOptionFilterOptions = [
    { value: 'Mission Control', label: 'Mission Control' },
    { value: 'Application Windows', label: 'Application Windows' },
    { value: 'Desktop', label: 'Desktop' },
    { value: 'Notification Center', label: 'Notification Center' },
    { value: 'Launchpad', label: 'Launchpad' },
    { value: 'Quick Note', label: 'Quick Note' },
    { value: 'Start Screen Saver', label: 'Start Screen Saver' },
    { value: 'Put Display to Sleep', label: 'Put Display to Sleep' },
    { value: 'Lock Screen', label: 'Lock Screen' },
    { value: 'Off', label: 'Off' },
  ];

  const columnDefs = [
    ...globalColumns.startColumnDefs,

    desktopAndScreensaverColumnHelper.accessor((row) => row.user_account, {
      id: 'user_account',
      meta: {
        displayName: 'User Account',
        filterType: 'string',
        filterIcon: 'text-size',
        filterDisabled,
      },
    }),

    desktopAndScreensaverColumnHelper.accessor(
      (row) => row.screensaver_interval,
      {
        id: 'screensaver_interval',
        cell: (info) => {
          const value = info.getValue();
          if (value === null) {
            return NULL_VALUE_N_DASH;
          }
          const num = Number(value);
          if (!Number.isNaN(num)) {
            const minutes = Math.floor(num / 60);
            if (minutes === 0) {
              return `${num} seconds`;
            }
            return `${minutes} minutes`;
          }
          return '';
        },
        meta: {
          displayName: 'Screen Saver Interval',
          filterType: 'time',
          filterIcon: 'clock',
          filterDisabled,
        },
      },
    ),

    desktopAndScreensaverColumnHelper.accessor((row) => row.screensaver_path, {
      id: 'screensaver_path',
      cell: getCodeSnippetCell,
      meta: {
        displayName: 'Screen Saver Path',
        filterType: 'string',
        filterIcon: 'text-size',
        filterDisabled,
      },
    }),

    desktopAndScreensaverColumnHelper.accessor((row) => row.show_with_clock, {
      id: 'show_with_clock',
      cell: YesNoCell,
      meta: {
        displayName: 'Show Clock',
        filterType: 'boolean',
        filterOptions: [...YES_NO_CELL_FILTER_OPTIONS],
        filterIcon: 'circle-dot',
        filterDisabled,
      },
    }),

    desktopAndScreensaverColumnHelper.accessor(
      (row) => row.uses_random_screensaver,
      {
        id: 'uses_random_screensaver',
        cell: YesNoCell,
        meta: {
          displayName: 'Uses Random Screen Saver',
          filterType: 'boolean',
          filterOptions: [...YES_NO_CELL_FILTER_OPTIONS],
          filterIcon: 'circle-dot',
          filterDisabled,
        },
      },
    ),

    desktopAndScreensaverColumnHelper.accessor(
      (row) => row.top_left_hot_corner_option,
      {
        id: 'top_left_hot_corner_option',
        meta: {
          displayName: 'Hot Corner - Upper Left',
          filterType: 'enum',
          filterIcon: 'list-check',
          filterDisabled,
          defaultHidden: true,
          filterOptions: hotCornerOptionFilterOptions,
        },
      },
    ),

    desktopAndScreensaverColumnHelper.accessor(
      (row) => row.top_left_hot_corner_modifier,
      {
        id: 'top_left_hot_corner_modifier',
        meta: {
          displayName: 'Hot Corner - Upper Left Modifiers',
          defaultHidden: true,
        },
      },
    ),

    desktopAndScreensaverColumnHelper.accessor(
      (row) => row.top_right_hot_corner_option,
      {
        id: 'top_right_hot_corner_option',
        meta: {
          displayName: 'Hot Corner - Upper Right',
          filterType: 'enum',
          filterIcon: 'list-check',
          filterDisabled,
          defaultHidden: true,
          filterOptions: hotCornerOptionFilterOptions,
        },
      },
    ),

    desktopAndScreensaverColumnHelper.accessor(
      (row) => row.top_right_hot_corner_modifier,
      {
        id: 'top_right_hot_corner_modifier',
        meta: {
          displayName: 'Hot Corner - Upper Right Modifiers',
          defaultHidden: true,
        },
      },
    ),

    desktopAndScreensaverColumnHelper.accessor(
      (row) => row.bottom_left_hot_corner_option,
      {
        id: 'bottom_left_hot_corner_option',
        meta: {
          displayName: 'Hot Corner - Lower Left',
          filterType: 'enum',
          filterIcon: 'list-check',
          filterDisabled,
          defaultHidden: true,
          filterOptions: hotCornerOptionFilterOptions,
        },
      },
    ),

    desktopAndScreensaverColumnHelper.accessor(
      (row) => row.bottom_left_hot_corner_modifier,
      {
        id: 'bottom_left_hot_corner_modifier',
        meta: {
          displayName: 'Hot Corner - Lower Left Modifiers',
          defaultHidden: true,
        },
      },
    ),

    desktopAndScreensaverColumnHelper.accessor(
      (row) => row.bottom_right_hot_corner_option,
      {
        id: 'bottom_right_hot_corner_option',
        meta: {
          displayName: 'Hot Corner - Lower Right',
          filterType: 'enum',
          filterIcon: 'list-check',
          filterDisabled,
          defaultHidden: true,
          filterOptions: hotCornerOptionFilterOptions,
        },
      },
    ),

    desktopAndScreensaverColumnHelper.accessor(
      (row) => row.bottom_right_hot_corner_modifier,
      {
        id: 'bottom_right_hot_corner_modifier',
        meta: {
          displayName: 'Hot Corner - Lower Right Modifiers',
          defaultHidden: true,
        },
      },
    ),

    ...globalColumns.endColumnDefs,
  ];

  const filterableColumns = extractFilterableColumns(columnDefs);

  return { columnDefs, filterableColumns };
};
