import { NoContent, TextInput, setClass } from '@kandji-inc/bumblebee';
import { func, string } from 'prop-types';
import React, { useState, useRef, useEffect } from 'react';
import { ReactSVG } from 'react-svg';

import { elementScrollIntoView } from 'seamless-scroll-polyfill';
import { sfSymbolsRegularIcons } from '../../icons';

const IconGridList = ({ searchVal, selectedIcon, update }) => {
  const selectedIconRef = useRef(null);
  const [loadSlice, setLoadSlice] = useState(3);
  const [filtered, setFiltered] = useState(sfSymbolsRegularIcons);
  const loader = useRef();
  const scrollContainer = useRef();
  const splitBy = 20;
  const iconGroupCount = Math.ceil(sfSymbolsRegularIcons.length / splitBy);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setFiltered(() =>
        sfSymbolsRegularIcons.filter(({ icon }) =>
          icon.toLowerCase().includes(searchVal.toLowerCase()),
        ),
      );
    }, 300);

    return () => clearTimeout(timeout);
  }, [searchVal]);

  const handleObserver = (entities) => {
    const target = entities[0];
    if (target.isIntersecting) {
      setLoadSlice((prev) => {
        if (prev < iconGroupCount) {
          return prev + 1;
        }
        return prev;
      });
    }
  };

  useEffect(() => {
    if (!scrollContainer?.current || !loader?.current) {
      return;
    }
    const options = {
      root: scrollContainer.current,
      rootMargin: '250px 0px 0px 0px',
      threshold: 1.0,
    };
    const observer = new IntersectionObserver(handleObserver, options);
    observer.observe(loader.current);

    return () => observer.disconnect();
  }, [filtered, scrollContainer, loader]);

  useEffect(() => {
    if (selectedIconRef?.current) {
      elementScrollIntoView(selectedIconRef.current, {
        behavior: 'smooth',
        block: 'center',
      });
    }
  }, [selectedIcon]);

  const selectIcon = (icon, iconImg) => {
    update({
      batchKeyValues: {
        icon,
        iconImg,
      },
    });
  };

  if (filtered?.filter(Boolean)?.length <= 0) {
    return (
      <div className="k-self-service-side-panel-icon-search-select__icon-grid k-self-service-side-panel-icon-search-select__icon-grid--no-content">
        <NoContent
          header="No results found"
          text="Sorry, but nothing matched your search request. Please try using different keywords from SF symbols."
        />
      </div>
    );
  }

  return (
    <div
      ref={scrollContainer}
      className="k-self-service-side-panel__icon-container"
    >
      <div className="k-self-service-side-panel-icon-search-select__icon-grid">
        {filtered.slice(0, loadSlice * splitBy).map(({ icon, src }) => {
          const isSelectedIconShown =
            icon.toLowerCase() === selectedIcon.toLowerCase();
          return (
            <div key={icon} ref={isSelectedIconShown ? selectedIconRef : null}>
              <ReactSVG
                className={setClass(
                  'k-self-service-category__img',
                  isSelectedIconShown &&
                    'k-self-service-side-panel__icon-grid-item--selected',
                )}
                src={src}
                onClick={() => selectIcon(icon, src)}
              />
            </div>
          );
        })}
        <div className="loading" ref={loader} />
      </div>
    </div>
  );
};

IconGridList.propTypes = {
  searchVal: string,
  selectedIcon: string,
  update: func,
};

IconGridList.defaultProps = {
  searchVal: '',
  selectedIcon: '',
  update: () => {},
};

const IconSearchSelect = ({ update, selectedIcon }) => {
  const [searchVal, setSearchVal] = useState('');
  const icon = searchVal.length > 0 ? 'circle-xmark' : 'magnifying-glass';
  const onIconClick = () => {
    /* istanbul ignore next */
    if (icon === 'circle-xmark') {
      setSearchVal('');
    }
  };

  return (
    <>
      <TextInput
        icon={icon}
        onChange={(e) => setSearchVal(e.target.value)}
        onIconClick={onIconClick}
        value={searchVal}
      />
      <IconGridList
        searchVal={searchVal}
        selectedIcon={selectedIcon}
        update={update}
      />
    </>
  );
};

IconSearchSelect.propTypes = {
  selectedIcon: string,
  update: func,
};

IconSearchSelect.defaultProps = {
  selectedIcon: '',
  update: () => {},
};

export default IconSearchSelect;
