import { TextField, styled } from '@kandji-inc/nectar-ui';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import type { InputProps } from '../../types/integrator.types';

const StyledInputGroup = styled('div', {
  width: '100%',
  '&.horizontal': {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gap: '0 $3',
  },
  '&.vertical': {
    maxWidth: '$15',
  },
});

const StyledVertically = styled('div', {
  maxWidth: '$15',
  marginTop: '$4',
});

const StyledHorizontally = styled('div', {
  marginTop: '$4',
});

interface Props {
  data: Array<InputProps>;
  isProcessing?: boolean;
  horizontal?: boolean;
}

const ViewInputFields = ({ data, isProcessing, horizontal = false }: Props) => {
  const {
    register,
    formState: { errors },
  } = useFormContext();

  const StyledField = horizontal ? StyledHorizontally : StyledVertically;

  return (
    <StyledInputGroup className={horizontal ? 'horizontal' : 'vertical'}>
      {data.map((v) => (
        <StyledField key={v.name}>
          <TextField
            {...register(v.name, v.validations)}
            placeholder={v.placeholder}
            label={v.label}
            type={v.type}
            disabled={isProcessing}
            isOptional={v.isOptional}
            state={errors[v.name] ? 'error' : 'default'}
            hint={{
              label: errors[v.name]?.message
                ? (errors[v.name]?.message as string)
                : v.hint,
            }}
          />
        </StyledField>
      ))}
    </StyledInputGroup>
  );
};
export default ViewInputFields;
