import React from 'react';
import { Handle, Position } from 'reactflow';

import useBlueprintFlow from '../store';
import Event from './event';

const handleStyle = { opacity: 0 };

function EndNode() {
  const { device, devicePath } = useBlueprintFlow(
    (state) => state.selectedDevice,
  );
  const selectedDeviceEventArgs = {
    deviceSerial: device && devicePath && device.serialNumber,
    deviceId: device && devicePath && device.id,
  };
  const eventKind = device ? 'endWithDevice' : 'end';

  return (
    <>
      <Event kind={eventKind} args={selectedDeviceEventArgs} />
      <Handle
        type="target"
        position={Position.Left}
        isConnectable={false}
        style={handleStyle}
      />
    </>
  );
}

export default EndNode;
