import { getAllBlueprintOptions } from '../../../data-service/blueprint/use-blueprint-service';
import { setBlueprintsToFormData } from '../../../data-service/library-item/transformers';

export const transformFromApi = async (apiData) => {
  try {
    const selectedBlueprints = await getAllBlueprintOptions(apiData.blueprints);
    const excludedBlueprints = await getAllBlueprintOptions(
      apiData.excluded_blueprints,
    );

    const data = apiData.data_with_file_urls || apiData.data;

    const result = {
      _data: apiData,
      id: apiData.id,
      name: apiData.name,
      iconSrc: apiData.app_icon,
      isActive: apiData.active,
      selectedBlueprints,
      excludedBlueprints,
      isAllBlueprints: apiData.is_all_blueprints,
    };

    result.installation = {
      type: data.install_enforcement,
    };

    result.selfService = {
      invalidationsMap: {},
      isEnabled: data.show_in_self_service,
      category: data.self_service_data?.category,
      isRecommended: !!data.self_service_data?.isRecommended,
      displayName: data.self_service_data?.displayName,
      shortDescription: data.self_service_data?.description,
      longDescription: data.self_service_data?.bodyText,
      isRequireRead: data.self_service_data?.mustViewAgreement,
      iconUrl: data.self_service_data?.iconUrl,
      iconFile: null,
      version: '-',
      size: apiData.file_size,
      installBtnText: data.self_service_data?.installBtnText, // ??
      displayInfoBtn: data.self_service_data?.displayInfoBtn, // ??
      infoBtnText: data.self_service_data?.infoBtnText, // ??
      infoBtnUrl: data.self_service_data?.infoBtnUrl, // ??
    };

    result.general = {
      invalidationsMap: {},
      printerName: data.printer_name,
      displayName: data.description,
      location: data.location,
      uri: data.address,
      ppdType: data.ppd_type,
      ppdPath: data.ppd_path,
      ppdFile: data.ppd_file_sha256 && {
        name: data.ppd_file_name,
        size: data.ppd_file_size,
        sha256: data.ppd_file_sha256,
        downloadLink: data.ppd_file_url,
        s3key: data.ppd_file_s3key,
        uploadedAt: data.ppd_file_uploaded,
      },
      customPpdPath: '', // ui-only field
    };
    result.printerFile = {
      hasFile: data.has_file,
      file: data.has_file && {
        name: data.file_name,
        size: data.file_size,
        sha256: data.file_sha256,
        downloadLink: data.file_url,
        s3key: data.file_s3key,
        uploadedAt: data.file_uploaded,
      },
      preInstallScript: data.preinstall_script,
      postInstallScript: data.postinstall_script,
    };

    result.rules = apiData.rules;

    return {
      ...apiData,
      data: result,
    };
  } catch (e) {
    console.error(e);
    throw e.message;
  }
};

export const transformToApi = (model) => {
  const toSend = new FormData();
  toSend.set('name', model.name);
  toSend.set('active', model.isActive);
  toSend.set('runs_on_mac', true);
  toSend.set('type', 'custom-printer');
  setBlueprintsToFormData(toSend, model.selectedBlueprints);
  toSend.set('is_all_blueprints', model.isAllBlueprints);

  if (model.iconFile) {
    toSend.set('app_icon', model.iconFile);
  }
  if (!model.iconSrc) {
    toSend.set('app_icon', '');
  }

  toSend.set('rules', JSON.stringify(model.rules) || null);

  const data = {
    install_enforcement: model.installation.type,

    printer_name: model.general.printerName,
    description: model.general.displayName,
    location: model.general.location,
    address: model.general.uri,
    ppd_type: model.general.ppdType,
    ppd_path: model.general.ppdPath,
    ppd_file_name: model.general.ppdFile?.name,
    ppd_file_size: model.general.ppdFile?.size,
    ppd_file_sha256: model.general.ppdFile?.sha256,
    ppd_file_url: model.general.ppdFile?.downloadLink,
    ppd_file_s3key: model.general.ppdFile?.s3key,
    ppd_file_uploaded: model.general.ppdFile?.uploadedAt,

    has_file: model.printerFile.hasFile,
    preinstall_script: model.printerFile.preInstallScript,
    postinstall_script: model.printerFile.postInstallScript,
    file_name: model.printerFile.file?.name,
    file_size: model.printerFile.file?.size,
    file_sha256: model.printerFile.file?.sha256,
    file_url: model.printerFile.file?.downloadLink,
    file_s3key: model.printerFile.file?.s3key,
    file_uploaded: model.printerFile.file?.uploadedAt,
  };
  if (model.selfService.isEnabled) {
    data.show_in_self_service = true;
    data.self_service_data = {
      isRecommended: model.selfService.isRecommended,
      iconS3Key: model.selfService.iconS3Key,
      displayName: model.selfService.displayName,
      description: model.selfService.shortDescription,
      bodyText: model.selfService.longDescription,
      mustViewAgreement: model.selfService.isRequireRead,
      category: model.selfService.category,
      installBtnText: model.selfService.installBtnText,
      displayInfoBtn: model.selfService.displayInfoBtn,
      infoBtnText: model.selfService.infoBtnText,
      infoBtnUrl: model.selfService.infoBtnUrl,
    };
  } else {
    data.show_in_self_service = false;
    delete data.self_service_data;
  }
  toSend.set('data', JSON.stringify(data));

  return toSend;
};
