import {
  Box,
  Button,
  Flex,
  type IconNames,
  styled,
} from '@kandji-inc/nectar-ui';
import { useEffect, useState } from 'react';
import EDRPopover from 'src/features/edr/common/components/EDRPopover';
import EDRTooltip from 'src/features/edr/common/components/EDRTooltip';

const TinyButton = styled(Button, {
  '&:not(empty)': {
    svg: { width: '$3', height: '$3' },
    padding: 1,
  },
});

const ThreatListButtonPopover = (props: {
  children: React.ReactNode;
  icon?: IconNames;
  tooltip?: string;
  tooltipSide?: 'top' | 'bottom' | 'left' | 'right';
  onOpen?: () => void;
  trigger?: React.ReactNode;
  testId?: string;
}) => {
  const {
    children,
    icon = 'clock-rotate-left',
    onOpen,
    tooltip,
    tooltipSide = 'bottom',
    trigger,
    testId,
  } = props;
  const [isOpen, setIsOpen] = useState<boolean>(false);

  useEffect(() => {
    if (typeof onOpen === 'function' && isOpen) {
      onOpen();
    }
  }, [onOpen, isOpen]);

  const handleButtonClick = () => {
    setIsOpen(!isOpen);
  };

  const handleInteractOutside = () => {
    setIsOpen(false);
  };

  return (
    <EDRPopover
      isOpen={isOpen}
      onInteractOutside={handleInteractOutside}
      trigger={
        <EDRTooltip side={tooltipSide} label={tooltip}>
          <Box
            css={{ cursor: 'pointer' }}
            onClick={handleButtonClick}
            data-testid={testId}
          >
            {trigger || <TinyButton variant="subtle" icon={{ name: icon }} />}
          </Box>
        </EDRTooltip>
      }
    >
      <Flex flow="column" gap="xs">
        {children}
      </Flex>
    </EDRPopover>
  );
};

export default ThreatListButtonPopover;
