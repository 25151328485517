import { Toggle } from '@kandji-inc/bumblebee';
import React, { useEffect } from 'react';

const MultiToggle = (props) => {
  const { param, update, isDisabled, label, toggles } = props;
  const { details } = param;

  useEffect(() => {
    if (!details) {
      const toggleDefaults = toggles.flat().reduce(
        (a, { key, defaultValue }) => ({
          ...a,
          [key]: defaultValue || false,
        }),
        {},
      );
      update({ details: toggleDefaults });
    }
  }, [details]);

  return (
    <div>
      {label && (
        <p className={`b-txt${isDisabled ? '-light' : ''} b-mb1`}>{label}</p>
      )}
      <div className="b-flex-vgmicro">
        {toggles.map((toggleRow, idx) => (
          <div
            key={idx}
            className="b-flex-g b-flex-vc"
            style={{ display: 'grid', gridTemplateColumns: '1fr 1fr' }}
          >
            {toggleRow.map((toggle) => (
              <div key={toggle.key} className="b-flex-vc b-flex-g1 b-mb1">
                <Toggle
                  onToggle={(v) =>
                    update({ details: { ...details, [toggle.key]: v } })
                  }
                  disabled={isDisabled}
                  checked={details && details[toggle.key]}
                />
                {toggle.label && (
                  <p className={`b-txt${isDisabled ? '-light' : ''}`}>
                    {toggle.label}
                  </p>
                )}
              </div>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
};

export default MultiToggle;
