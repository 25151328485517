import { Button, Toaster as toaster } from '@kandji-inc/bumblebee';
import { getEnterpriseApi } from 'app/api/domains';
import React from 'react';
import './view-integration.styles.scss';
import { i18n } from 'i18n';

function ViewIntegrationExtra() {
  const apiUrl = new URL('api/v1/scim', getEnterpriseApi()).href;

  return (
    <div className="view-scim-int-extra_wrapper">
      <p className="b-txt b-mb1">{i18n.t('The SCIM API URL is:')}</p>
      <div className="b-flex b-flex-align-center">
        <div className="view-scim-int-extra_api-url">{apiUrl}</div>

        <Button
          kind="link"
          icon="copy"
          className="view-scim-int-extra_copy-btn"
          onClick={() =>
            navigator.clipboard
              .writeText(apiUrl)
              .then(() => toaster(i18n.t('Copied to clipboard.')))
              .catch((e) => {
                console.log(e);
                toaster(i18n.t('Could not copy to clipboard.'));
              })
          }
        />
      </div>
    </div>
  );
}

export default ViewIntegrationExtra;
