import { Button } from '@kandji-inc/bumblebee';
import { Icon } from '@kandji-inc/nectar-ui';
import { i18n } from 'i18n';
import React from 'react';
import { Tooltip } from 'src/app/components/interface/tooltips/Tooltip';
import { StyledOptionsMenu } from 'src/features/integrations/components/styled-components';
import styled from 'styled-components';
import type { DeviceType } from '../../types';
import { CardSubheading, DataContainer } from '../styled-components';

type Close = () => void;

type Props = {
  hasDeleteMenuOption: boolean;
  onEdit: (value: 'iOS') => void;
  onDelete: (value: DeviceType) => void;
};

const StyledTextTooltip = styled.span`
  display: flex;
  gap: var(--b-gap-tiny);
`;

const IosSettings = (props: Props) => {
  const { onEdit, onDelete, hasDeleteMenuOption } = props;
  const iOS = 'iOS';
  return (
    <>
      <CardSubheading data-testid="device-platforms:ios-settings">
        <h4 className="b-h4 b-mr">{i18n.t('iOS settings')}</h4>

        <StyledOptionsMenu
          render={(close: Close) => (
            <>
              <Button
                icon="pencil"
                kind="link"
                onClick={() => {
                  onEdit(iOS);
                  close();
                }}
              >
                {i18n.t('Edit settings')}
              </Button>
              {hasDeleteMenuOption && (
                <Button
                  icon="trash-can"
                  kind="link"
                  theme="error"
                  onClick={() => {
                    onDelete(iOS);
                    close();
                  }}
                >
                  {i18n.t('Delete device platform')}
                </Button>
              )}
            </>
          )}
        >
          <Button size="small" icon="ellipsis" kind="link" />
        </StyledOptionsMenu>
      </CardSubheading>
      <DataContainer>
        <div>
          <StyledTextTooltip className="b-txt-light">
            {i18n.t('Secret key')}{' '}
            <Tooltip
              tooltipMessage={i18n.t(
                'The secret key that was generated in Okta when adding iOS as a device management provider.',
              )}
              placement="right"
            >
              <Icon name="circle-exclamation" size="sm" />
            </Tooltip>
          </StyledTextTooltip>
          <p>•••••••••</p>
        </div>
      </DataContainer>
    </>
  );
};

export default IosSettings;
