import { Button, modal as Modal, TextInput } from '@kandji-inc/bumblebee';
import { func, string } from 'prop-types';
import React, { useEffect, useState } from 'react';
import useListIntegrations from '../../../hooks/use-list-integrations';

function RenameUserIntegrationModal({
  stopEditingIntegration,
  nameValue,
  onChange,
  saveEditedIntegration,
}) {
  const [isProcessing, setIsProcessing] = useState(false);
  const [isRequired, setIsRequired] = useState(false);
  const [isDuplicate, setIsDuplicate] = useState(false);

  const [userHasTyped, setUserHasTyped] = useState(false);

  const { data } = useListIntegrations();

  const [configuredIntegrationsNames, setConfiguredIntegrationsNames] =
    useState([]);

  useEffect(() => {
    if (data) {
      const integrationNames = data?.data?.results.map((elem) => elem.name);
      setConfiguredIntegrationsNames(integrationNames);
    }
  }, [data]);

  return (
    <Modal
      isOpen={nameValue != null}
      onClose={stopEditingIntegration}
      withoutCloseButton
      parts={{
        header: { text: 'Rename user integration' },
        content: {
          children: (
            <>
              <p className="b-txt b-mb3">
                Provide a new name for this user integration that is unique and
                easy for you to remember.
              </p>
              <TextInput
                onChange={(e) => {
                  setUserHasTyped(true);
                  e.persist();
                  onChange(e.target.value);
                  setIsRequired(e.target.value.length === 0);
                  setIsDuplicate(
                    configuredIntegrationsNames.includes(e.target.value),
                  );
                }}
                value={nameValue || ''}
                validator={(name) => [
                  {
                    message: 'Required',
                    invalid: () => name.length === 0 && userHasTyped,
                  },
                  {
                    message: 'Duplicate name found. Enter a different name.',
                    invalid: () =>
                      configuredIntegrationsNames.includes(name) &&
                      userHasTyped,
                  },
                ]}
                maxLength={32}
              />
            </>
          ),
          className: 'cmn-modal_content--b-spacing',
        },
        footer: {
          children: (
            <>
              <Button
                kind="outline"
                onClick={stopEditingIntegration}
                className="b-mr"
                disabled={isProcessing}
              >
                Cancel
              </Button>
              <Button
                disabled={
                  userHasTyped && (isProcessing || isRequired || isDuplicate)
                }
                isProgress={isProcessing}
                icon={isProcessing ? 'arrows-rotate' : null}
                onClick={() =>
                  saveEditedIntegration(
                    () => setIsProcessing(true),
                    () => setIsProcessing(false),
                  )
                }
              >
                Save
              </Button>
            </>
          ),
        },
      }}
    />
  );
}

RenameUserIntegrationModal.propTypes = {
  stopEditingIntegration: func.isRequired,
  saveEditedIntegration: func.isRequired,
  nameValue: string,
  onChange: func.isRequired,
};

RenameUserIntegrationModal.defaultProps = {
  nameValue: null,
};

export default RenameUserIntegrationModal;
