import { Checkbox, Chip, Icon, setClass } from '@kandji-inc/bumblebee';
import { bool, func, number, object, string } from 'prop-types';
import React from 'react';
import { i18n } from 'src/i18n';

const GroupHeader = ({
  enabled,
  total,
  title,
  isCollapsed,
  readOnly,
  onCollapse,
  onToggle,
}) => {
  const allEnabled = enabled === total;
  const someEnabled = enabled > 0 && enabled <= total;
  return (
    <div className="permission-row__collapsable" onClick={onCollapse}>
      <div
        className="token-permissions-group"
        onClick={(e) => {
          if (!readOnly) {
            e.stopPropagation();

            if (!enabled && isCollapsed) {
              // If no options are enabled and the header is collapsed,
              // expand.
              onCollapse();
            }
            onToggle();
          }
        }}
      >
        {!readOnly && (
          <div
            className={setClass(
              'permissions-group-header_pseudo-checkbox',
              someEnabled &&
                'permissions-group-header_pseudo-checkbox--checked',
            )}
          >
            {allEnabled && <Icon name="check" />}
            {someEnabled && !allEnabled && <Icon name="horizontal-rule" />}
          </div>
        )}

        <h3 className="b-h3">{title}</h3>
        <span
          className="b-txt-light2 b-ml"
          style={{ visibility: enabled ? 'visible' : 'hidden' }}
        >
          {i18n.t('{enabled} of {total} enabled', { enabled, total })}
        </span>
      </div>

      <Icon
        className={`permission-row__header-icon ${
          isCollapsed ? '--flipped' : ''
        }`}
        name="arrow-up"
      />
    </div>
  );
};

GroupHeader.propTypes = {
  enabled: number.isRequired,
  total: number.isRequired,
  title: string.isRequired,
  isCollapsed: bool.isRequired,
  readOnly: bool.isRequired,
  onCollapse: func.isRequired,
  onToggle: func.isRequired,
};

const GroupDescription = ({ description, linkHref, isCollapsed }) => (
  <div hidden={isCollapsed} className="b-txt permission-row">
    {description}{' '}
    {linkHref && (
      <a href={linkHref} target="_blank" rel="noreferrer">
        {i18n.t('Learn More')}
      </a>
    )}
  </div>
);

GroupDescription.propTypes = {
  description: string.isRequired,
  linkHref: string,
  isCollapsed: bool,
};

GroupDescription.defaultProps = {
  linkHref: '',
  isCollapsed: false,
};

const CHIP_KIND_PROPS = {
  GET: { kind: 'active' },
  POST: { kind: 'active', className: 'chip-orange' },
  PATCH: { kind: 'active', className: 'chip-orange' },
  PUT: { kind: 'active', className: 'chip-orange' },
  DELETE: { kind: 'active', className: 'chip-red' },
};

const PermissionRow = ({
  isEnabled,
  title,
  subtitle,
  method,
  description,
  readOnly,
  onEnable,
  isCollapsed,
}) => {
  const chipKind = CHIP_KIND_PROPS[method.toUpperCase()];

  return (
    <>
      {!isCollapsed && (
        <div className="permission-row permission-row-grid">
          <Checkbox
            disabled={readOnly}
            checked={isEnabled}
            onChange={onEnable}
            style={{ width: 'fit-content' }}
          />
          <div className="token-permission-sub-row">
            <span className="b-txt-bold">{title}</span>
            <span className="b-txt-light permissions-description">
              {subtitle}
            </span>
          </div>
          {method && (
            <Chip
              text={method}
              kind={chipKind.kind}
              className={setClass([
                'b-txt-ctrl8',
                'token-row-chip',
                chipKind.className,
              ])}
            />
          )}
          {description && (
            <p className="b-txt-light permissions-description token-perm-row-desc">
              {description}
            </p>
          )}
        </div>
      )}
    </>
  );
};

PermissionRow.propTypes = {
  isEnabled: bool.isRequired,
  title: string.isRequired,
  subtitle: string.isRequired,
  method: string.isRequired,
  description: string.isRequired,
  readOnly: bool.isRequired,
  onEnable: func.isRequired,
  isCollapsed: bool,
};

PermissionRow.defaultProps = {
  isCollapsed: false,
};

/**
 * Defines the ordering of groups and subgroups.
 */
const groupOrder = ['Devices'];
const subgroupOrder = [
  'Device Information',
  'Device Actions',
  'Device Secrets',
];

const sortOrder = (order) => (a, b) =>
  order.findIndex((o) => o === a) < order.findIndex((o) => o === b) ? -1 : 1;

/**
 * Renders a data object representing groups/subgroups/apis.
 *  ex: {
 *     Devices: {
 *        Device Information: [
 *          {
              name: 'Device Details',
              http_method: 'GET',
              allowed: false,
              url_name: '',
              path: '/devices/{device_id}/details',
              category: 'Device Information',
              description: 'Get the full details for a specific device',
              id: 'GET::/devices/{device_id}/details',
              subgroup: 'Device Information',
              learn_more: 'https://kandji.io',
              group: 'devices',
              category_description:
                'Permissions related to collecting details about enrolled devices.',
            },
            ...
 *        ]
 *        ...
 *     },
 *    ...
 *  }
 */
const PermissionGroup = ({
  isReadOnly,
  data,
  onCollapse,
  onToggle,
  totalLengths,
}) => (
  <>
    {Object.keys(data)
      .sort(sortOrder(groupOrder))
      .map((group) => {
        const subgroups = data[group];
        return (
          <div key={group} className="token-permission-main-group">
            <h3 className="b-h3">
              {
                i18n.t('{group}', { group }) // ADJUST_SECTION_NAME_MAPPING key
              }
            </h3>
            {Object.keys(subgroups)
              .sort(sortOrder(subgroupOrder))
              .map((subgroup) => {
                const apis = subgroups[subgroup];
                if (!apis.length) {
                  return null;
                }

                const isCollapsed = apis.isCollapsed || false;
                const { id, category_description } = apis[0];
                const enabled = apis.filter((api) => api.allowed);
                return (
                  <div key={`${subgroup}_${id}`} className="l-card">
                    <GroupHeader
                      enabled={enabled.length}
                      total={totalLengths[group][subgroup]}
                      title={subgroup ? i18n.t('{subgroup}', { subgroup }) : ''} // ADJUST_CAT_DESC_MAPPING key
                      isCollapsed={isCollapsed}
                      readOnly={isReadOnly}
                      onCollapse={() => onCollapse(group, subgroup)}
                      onToggle={() =>
                        onToggle(group, subgroup, null, !enabled.length)
                      }
                    />
                    <GroupDescription
                      isCollapsed={isCollapsed}
                      description={
                        category_description
                          ? i18n.t('{category_description}', {
                              category_description,
                            })
                          : ''
                      } // ADJUST_CAT_DESC_MAPPING[subgroup]
                    />
                    {apis.map((perm) => (
                      <PermissionRow
                        key={`${subgroup}_${perm.id}`}
                        isEnabled={perm.allowed}
                        title={perm.name}
                        subtitle={perm.path}
                        method={perm.http_method}
                        description={perm.description}
                        readOnly={isReadOnly}
                        onEnable={() => onToggle(group, subgroup, perm.id)}
                        isCollapsed={isCollapsed}
                      />
                    ))}
                  </div>
                );
              })}
          </div>
        );
      })}
  </>
);

PermissionGroup.propTypes = {
  isReadOnly: bool.isRequired,
  data: object.isRequired,
  totalLengths: object.isRequired,
  onCollapse: func.isRequired,
  onToggle: func.isRequired,
};

export default PermissionGroup;
