import {
  Box,
  Button,
  DropdownMenuPrimitives as DropdownMenu,
  FilterButton,
  Flex,
  Icon,
  Loader,
  Separator,
  Text,
  TextField,
  styled,
} from '@kandji-inc/nectar-ui';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { useEdges, useNodes } from 'reactflow';

import deviceImagesMap from 'src/components/common/image-device/map';
import { ASSIGNMENT_MAP_DEVICE_LOOKUP_PARAM } from 'src/features/blueprints/common';
import useDebouncedState from 'src/features/compliance/Policy/Devices/useDebouncedState';
import { getDeviceIconByFamily } from 'src/features/visibility/prism/utils/column-utils';
import { DeviceSearchList } from 'src/features/visibility/pulse/views/CreatePulseCheck/components/DeviceSearchList';

import useComputers from '../../services/use-computers';
import useDevicePath from '../../services/use-device-path';
import useBlueprintFlow from '../../store';

const VScrollArea = styled(Flex, {
  flexDirection: 'column',
  maxHeight: '432px',
  overflowY: 'auto',
  '&::-webkit-scrollbar': {
    width: '$1',
  },
  '&:hover': {
    '&::-webkit-scrollbar-track': {
      background: 'rgba(243, 247, 250)',
      borderRadius: '$rounded',
    },
    '&::-webkit-scrollbar-thumb': {
      background: 'rgba(80, 94, 113, 0.24)',
      borderRadius: '$rounded',
      height: '50px',
    },
  },
});

const DeviceSearchDropdown = () => {
  const { search } = useLocation();
  const { id } = useParams<{ id?: string }>();
  const history = useHistory();
  const [
    model,
    selectedDevice,
    setSelectedDevice,
    isEditingAssignments,
    isAddingBlueprint,
  ] = useBlueprintFlow((state) => [
    state.model,
    state.selectedDevice,
    state.setSelectedDevice,
    state.isEditingAssignments,
    state.isAddingBlueprint,
  ]);
  const [nodes, edges] = [useNodes(), useEdges()];
  const graph = useMemo(() => ({ nodes, edges }), [nodes, edges]);

  const [isOpen, setIsOpen] = useState(false);
  const [debouncedTerm, setTerm, term] = useDebouncedState<string>('', 300);
  const searchRef = useRef<HTMLInputElement>(null);
  const params = React.useMemo(() => new URLSearchParams(search), [search]);
  const deviceLookupParam = params.get(ASSIGNMENT_MAP_DEVICE_LOOKUP_PARAM);

  const isTermLongEnough = debouncedTerm.length > 1;
  const { count: totalDeviceCount } = useComputers();
  const { computers, isFetching } = useComputers(
    [debouncedTerm],
    {
      filters: JSON.stringify([
        {
          name: 'blueprint',
          operator: 'equals',
          value: [id],
        },
        ...(deviceLookupParam && !isTermLongEnough
          ? [
              {
                name: 'serial',
                operator: 'equals',
                value: [deviceLookupParam],
              },
            ]
          : []),
      ]),
      search: debouncedTerm,
    },
    Boolean(
      (!isAddingBlueprint &&
        !isEditingAssignments &&
        Boolean(id) &&
        isTermLongEnough) ||
        deviceLookupParam,
    ),
  );

  const { data: selectedDevicePath } = useDevicePath(
    selectedDevice.device?.id,
    graph,
    [selectedDevice.device],
    Boolean(selectedDevice.device),
    model.library_item_exclusions,
  );

  const clearDeviceLookupParam = () => {
    params.delete(ASSIGNMENT_MAP_DEVICE_LOOKUP_PARAM);
    history.replace({
      search: params.toString(),
    });
  };

  const handleDropdownOpenChange = (open: boolean) => {
    setIsOpen(open);
    if (open) {
      setTimeout(() => searchRef.current?.focus(), 0);
    } else {
      setTerm('');
    }
  };

  const getDeviceRenderInfo = (computer) => ({
    id: computer.id,
    deviceId: computer.id,
    serialNumber: computer.serial_number,
    deviceName: computer.name,
    deviceFamily: computer.device_family,
    model: computer.model,
    modelId: computer.model,
    selected: selectedDevice.device?.deviceId === computer.id,
    userName: computer.user?.name,
    userEmail: computer.user?.email,
    assetTag: computer.asset_tag,
  });

  useEffect(() => {
    setSelectedDevice((prev) => ({ ...prev, devicePath: selectedDevicePath }));
  }, [selectedDevicePath]);

  useEffect(() => {
    if (deviceLookupParam && graph) {
      const deviceLookupItem = computers?.find(
        (computer) => computer.serial_number === deviceLookupParam,
      );

      if (deviceLookupItem) {
        setSelectedDevice((prev) => ({
          ...prev,
          device: getDeviceRenderInfo(deviceLookupItem),
        }));
        clearDeviceLookupParam();
      }
    }
  }, [deviceLookupParam, computers, graph]);

  const devicesMeta = (computers || []).map(getDeviceRenderInfo);

  const blueprintHasDevices = totalDeviceCount > 0;
  const hasDevicesMatchingFilter =
    !isFetching && isTermLongEnough && devicesMeta.length > 0;
  const shouldDisplayJustSelected = selectedDevice.device && !isTermLongEnough;

  const selectedDeviceIcon =
    deviceImagesMap[selectedDevice.device?.model] ??
    getDeviceIconByFamily(selectedDevice.device?.model);

  return (
    <Flex css={{ gap: '$2' }}>
      <DropdownMenu.Root
        open={isOpen}
        onOpenChange={handleDropdownOpenChange}
        modal={false}
      >
        <DropdownMenu.Trigger asChild>
          <FilterButton
            filtersSelected={Boolean(selectedDevice.device)}
            showRemove={false}
            aria-label="device-search"
            icon="fa-angle-down-small"
            data-testid="device-search-trigger"
          >
            <Flex alignItems="center" gap="xs">
              <Icon
                name="magnifying-glass"
                style={{ transform: 'unset', width: '20px', height: '20px' }}
              />
              {selectedDevice.device ? (
                <Flex alignItems="center" gap="xs">
                  <Text>Device:</Text>
                  <Flex alignItems="center" css={{ gap: '1px' }}>
                    <img
                      height="14"
                      width="14"
                      src={selectedDeviceIcon}
                      alt={selectedDevice.device?.device_family}
                    />
                    <Text
                      variant="primary"
                      css={{
                        fontWeight: '$medium',
                      }}
                    >
                      {selectedDevice.device?.serialNumber}
                    </Text>
                  </Flex>
                </Flex>
              ) : (
                <Text>Device lookup</Text>
              )}
            </Flex>
          </FilterButton>
        </DropdownMenu.Trigger>
        <DropdownMenu.Content
          css={{
            zIndex: 3,
            width: '544px',
          }}
          align="start"
          side="bottom"
        >
          {!blueprintHasDevices && (
            <Flex
              flow="column"
              alignItems="center"
              justifyContent="center"
              css={{ padding: '20px 0' }}
            >
              <Text css={{ color: '$neutral90', lineHeight: '$2' }}>
                No devices in this Blueprint.
              </Text>
              <Text css={{ color: '$neutral70', lineHeight: '$2' }}>
                Add devices to view their assignments in the map.
              </Text>
            </Flex>
          )}

          {blueprintHasDevices && (
            <>
              <Box css={{ padding: '6px 12px' }}>
                <TextField
                  ref={searchRef}
                  showClearButton={Boolean(term.length)}
                  onClear={/*istanbul ignore next */ () => setTerm('')}
                  iconLeft
                  icon="magnifying-glass"
                  placeholder="Search by device name, serial number, asset tag, user name, or e-mail"
                  value={term}
                  onChange={(e) => setTerm(e.target.value)}
                  data-testid="device-lookup-search"
                />
              </Box>
              <Flex
                alignItems="start"
                css={{ height: '8px', padding: '4px 0' }}
              >
                <Separator
                  dir="horizontal"
                  css={{ backgroundColor: '$neutral20' }}
                />
              </Flex>

              {!isFetching && !isTermLongEnough && !selectedDevice.device && (
                <Flex
                  alignItems="center"
                  justifyContent="center"
                  css={{ padding: '20px 0' }}
                >
                  <Text css={{ color: '$neutral50', lineHeight: '$2' }}>
                    Start typing to view results
                  </Text>
                </Flex>
              )}

              {isFetching && !shouldDisplayJustSelected && (
                <Flex
                  flow="column"
                  alignItems="center"
                  justifyContent="center"
                  css={{ padding: '20px 0' }}
                >
                  <Loader size="sm" />
                  <Text css={{ color: '$neutral50', lineHeight: '$2' }}>
                    Loading device matches now...
                  </Text>
                </Flex>
              )}

              {!isFetching && isTermLongEnough && devicesMeta.length === 0 && (
                <Flex
                  alignItems="center"
                  justifyContent="center"
                  css={{ padding: '20px 0' }}
                >
                  <Text css={{ color: '$neutral70', lineHeight: '$2' }}>
                    No matching devices found in this Blueprint.
                  </Text>
                </Flex>
              )}

              {(hasDevicesMatchingFilter || shouldDisplayJustSelected) && (
                <>
                  <VScrollArea>
                    <DeviceSearchList
                      devices={
                        selectedDevice.device && !isTermLongEnough
                          ? [{ ...selectedDevice.device, selected: true }]
                          : devicesMeta
                      }
                      searchTerm={debouncedTerm}
                      searchFields={[
                        'deviceName',
                        'serialNumber',
                        'assetTag',
                        'userName',
                        'userEmail',
                      ]}
                      onSelect={(device) => {
                        setSelectedDevice((prev) => ({ ...prev, device }));
                        handleDropdownOpenChange(false);
                      }}
                      options={{
                        displayEmptyUser: false,
                      }}
                    />
                  </VScrollArea>
                  {selectedDevice.device && (
                    <Flex css={{ padding: '$2 $3' }}>
                      <Button
                        compact
                        variant="subtle"
                        onClick={() => {
                          setSelectedDevice({ device: null, devicePath: null });
                          clearDeviceLookupParam();
                        }}
                        data-testid="device-search-dropdown-clear"
                      >
                        Clear
                      </Button>
                    </Flex>
                  )}
                </>
              )}
            </>
          )}
        </DropdownMenu.Content>
      </DropdownMenu.Root>
      {selectedDevice.device && (
        <Button
          compact
          variant="subtle"
          onClick={() => {
            setSelectedDevice({ device: null, devicePath: null });
            clearDeviceLookupParam();
          }}
          data-testid="device-search-clear"
        >
          Clear
        </Button>
      )}
    </Flex>
  );
};

export default DeviceSearchDropdown;
