/* istanbul ignore file */
import type { z } from 'zod';

import {
  DateCell,
  getFilteredDisabled as filterDisabled,
  getBlueprintCell,
  getDeviceCell,
  getDeviceUserCell,
} from '../column-utils';

export const getGlobalColumns = (
  schema,
  columnHelper: z.infer<typeof schema>,
) => {
  const startColumnDefs = [
    columnHelper.accessor((row) => row.device__name, {
      id: 'device__name',
      cell: (info) => getDeviceCell(info),
      enableHiding: false,
      meta: {
        displayName: 'Device Name',
        filterType: 'string',
        filterIcon: 'text-size',
        filterDisabled,
      },
    }),
  ];

  const endColumnDefs = [
    columnHelper.accessor((row) => row.device__user_name, {
      id: 'device__user_name',
      cell: getDeviceUserCell,
      meta: {
        displayName: 'Device User',
        filterType: 'string',
        filterIcon: 'text-size',
        filterDisabled,
      },
    }),

    columnHelper.accessor((row) => row.blueprint_name, {
      id: 'blueprint_name',
      cell: getBlueprintCell,
      meta: {
        displayName: 'Blueprint',
        filterType: 'string',
        filterIcon: 'text-size',
        filterDisabled,
      },
    }),

    columnHelper.accessor((row) => row.last_changed_at, {
      id: 'last_changed_at',
      cell: DateCell,
      meta: {
        displayName: 'Last Changed',
        filterType: 'date-time',
        filterIcon: 'calendar',
        filterDisabled,
      },
    }),

    columnHelper.accessor((row) => row.last_collected_at, {
      id: 'last_collected_at',
      cell: DateCell,
      meta: {
        displayName: 'Last Collected',
        filterType: 'date-time',
        filterIcon: 'calendar',
        filterDisabled,
      },
    }),

    columnHelper.accessor((row) => row.device__user_email, {
      id: 'device__user_email',
      meta: {
        displayName: 'User Email',
        defaultHidden: true,
        filterType: 'string',
        filterIcon: 'text-size',
        filterDisabled,
      },
    }),

    columnHelper.accessor((row) => row.serial_number, {
      id: 'serial_number',
      meta: {
        displayName: 'Serial Number',
        defaultHidden: true,
        filterType: 'string',
        filterIcon: 'text-size',
        filterDisabled,
      },
    }),

    columnHelper.accessor((row) => row.device_id, {
      id: 'device_id',
      meta: {
        displayName: 'Device ID',
        defaultHidden: true,
        filterType: 'string',
        filterIcon: 'text-size',
        filterDisabled,
      },
    }),

    /*
     * This columns is always hidden but needs to be in column defs to enable
     * filtering for export purposes
     */
    columnHelper.accessor((row) => row.device__user_id, {
      id: 'device__user_id',
      meta: {
        displayName: 'Device User ID',
        alwaysHidden: true,
        filterDisabled,
      },
    }),
  ];

  return { startColumnDefs, endColumnDefs };
};
