import { integrationsBackendKeys } from 'src/features/integrations/hooks/query-key-factory';

/* This query key factory is used to provide query keys to our custom reactQuery hooks  */

export const servicenowKeys = {
  list: () => [...integrationsBackendKeys.all, 'servicenow'] as const,
  details: (integrationId: string) =>
    [...servicenowKeys.list(), integrationId] as const,
  configurationDetails: (integrationId: string, configId: string) =>
    [...servicenowKeys.details(integrationId), 'config', configId] as const,
  versionDetails: (integrationId: string) =>
    [...servicenowKeys.details(integrationId), 'version'] as const,
};
