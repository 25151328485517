/* istanbul ignore file - legacy code, moved */
import { SeparateLine } from 'app/components/library/common/LibraryAdaptive.styles';
import { CardHeaderTitle } from 'app/components/library/common/LibrarySetting.styles';
import React, { useEffect, useState, useReducer, useContext } from 'react';
import styled from 'styled-components';
import { WhiteCard } from './SCEP.styles';
import { LibraryFiledTypes, SCEPMapping } from './SCEPConfig';
import {
  filteredAndSortedConfig,
  formatDataForRequest,
  hideByFieldType,
  isValid,
  labelByFieldType,
  onChangeByFieldType,
  onChangeSubDropdown,
  onChangeSubField,
} from './SCEPHelpers';
import SCEPLibraryField from './SCEPLibraryField';
import SCEPProfileSettingsFooter from './SCEPProfileSettingsFooter';
import { SCEPSettingsContext } from './SCEPSettingsContext';

const CardHeaderTitleStyled = styled(CardHeaderTitle)`
  justify-content: flex-start;
`;

const HeaderTitle = styled('div')`
  margin-top: 2px;
  margin-right: 15px;
`;

const Settings = styled('section')`
  margin-bottom: 180px;
`;

const Form = styled('div')``;

const SCEPProfileSettingsTab = ({
  profileId,
  onClickCancel,
  onSaveClick,
  settings,
  history,
  profileName,
  onDelete,
  onNameError,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [saveButtonClicked, setSaveButtonClicked] = useState(false);
  const [config, dispatch] = useReducer(reducer, settings);
  const { isEditingItem, setIsEditingItem } = useContext(SCEPSettingsContext);

  function reducer(state, action) {
    const { key, value, maximum } = action;
    let val;
    let checked;
    switch (action.type) {
      case 'init':
        return settings;
      case LibraryFiledTypes.CHECKBOX:
        return { ...state, [key]: !state[key] };
      case LibraryFiledTypes.TEXT:
      case LibraryFiledTypes.SELECT:
      case LibraryFiledTypes.TEXTAREA:
      case LibraryFiledTypes.PASSWORD_VALIDATOR:
        return {
          ...state,
          [key]: value,
        };
      case LibraryFiledTypes.OPTIONAL_NUMBER:
        val = value !== null ? parseInt(value, 10) || null : state[key].value;
        val = val <= maximum ? val : state[key].value;
        checked = value ? val !== null : !state[key].checked;
        return {
          ...state,
          [key]: {
            ...state[key],
            value: val,
            checked,
          },
        };
      default:
        throw new Error();
    }
  }

  useEffect(() => {
    dispatch({ type: 'init' });
  }, [!profileId && isEditingItem]);

  const onCLickButtonCancel = () => {
    dispatch({ type: 'init' });
    onClickCancel();
  };
  const onSaveButtonClick = () => {
    setSaveButtonClicked(true);
    if (!profileName) {
      if (!profileName) {
        onNameError();
      }
      return null;
    }

    if (Object.keys(config).find((option) => !isValid(config, option))) {
      return null;
    }

    const formatedConfig = formatDataForRequest(config, settings);

    setIsLoading(true);
    return onSaveClick(formatedConfig)
      .then(() => setIsEditingItem(false))
      .finally(() => setIsLoading(false));
  };

  return (
    <>
      <Settings>
        {Object.keys(SCEPMapping).map((category) => (
          <WhiteCard data-application-scroll={category} key={category}>
            <CardHeaderTitleStyled>
              <HeaderTitle>{SCEPMapping[category].name}</HeaderTitle>
            </CardHeaderTitleStyled>
            <Form>
              {filteredAndSortedConfig(config, category).map((key) => (
                <React.Fragment key={`${category}-${key}`}>
                  <SCEPLibraryField
                    id={`${category}-${key}`}
                    value={config[key]}
                    type={SCEPMapping[category].fields[key].type}
                    text={SCEPMapping[category].fields[key].name}
                    subLabel={SCEPMapping[category].fields[key].description}
                    placeholder={SCEPMapping[category].fields[key].placeholder}
                    isOptional={SCEPMapping[category].fields[key].optional}
                    isRequired={SCEPMapping[category].fields[key].required}
                    info={SCEPMapping[category].fields[key].info}
                    list={SCEPMapping[category].fields[key].fieldValues}
                    subFieldText={SCEPMapping[category].fields[key].fieldText}
                    label={labelByFieldType(config, category, key)}
                    onChange={(e) =>
                      onChangeByFieldType(e, category, key, dispatch)
                    }
                    onChangeSubField={(e) =>
                      onChangeSubField(e, category, key, dispatch)
                    }
                    onChangeSubDropdown={(value) =>
                      onChangeSubDropdown(value, category, key, dispatch)
                    }
                    hide={hideByFieldType(config)}
                    isError={saveButtonClicked && !isValid(config, key)}
                    isNeedValidation={false}
                    isAdaptive
                    isShowHideIcon
                  />
                  <SeparateLine />
                </React.Fragment>
              ))}
            </Form>
          </WhiteCard>
        ))}
      </Settings>
      <SCEPProfileSettingsFooter
        profileId={profileId}
        history={history}
        onDelete={onDelete}
        disabledSaveButton={isLoading}
        onCLickButtonCancel={onCLickButtonCancel}
        onSaveClick={onSaveButtonClick}
        isLoading={isLoading}
      />
    </>
  );
};

export default SCEPProfileSettingsTab;
