export default [
  'ss-unstar',
  'ss-anchor',
  'ss-thumbnails',
  'ss-twodie',
  'ss-quote',
  'ss-send',
  'ss-umbrella',
  'ss-fax',
  'ss-bone',
  'ss-trash',
  'ss-link',
  'ss-paint',
  'ss-lowvolume',
  'ss-key',
  'ss-searchfile',
  'ss-car',
  'ss-code',
  'ss-addressbook',
  'ss-uploadfolder',
  'ss-tooth',
  'ss-puzzle',
  'ss-keyboardup',
  'ss-ban',
  'ss-lock',
  'ss-bookmark',
  'ss-down',
  'ss-refresh',
  'ss-activity',
  'ss-disc',
  'ss-fastforward',
  'ss-store',
  'ss-print',
  'ss-surprise',
  'ss-creditcard',
  'ss-check',
  'ss-mug',
  'ss-downloadcloud',
  'ss-users',
  'ss-writingdisabled',
  'ss-lodging',
  'ss-airplay',
  'ss-smile',
  'ss-picture',
  'ss-skipback',
  'ss-femaleuser',
  'ss-settings',
  'ss-piechart',
  'ss-alert',
  'ss-cart',
  'ss-toolbox',
  'ss-outbox',
  'ss-newspaper',
  'ss-viewdisabled',
  'ss-camera',
  'ss-partlycloudy',
  'ss-unheart',
  'ss-plane',
  'ss-chat',
  'ss-utensils',
  'ss-alarmclock',
  'ss-burger',
  'ss-write',
  'ss-navigate',
  'ss-sync',
  'ss-notificationsdisabled',
  'ss-rewind',
  'ss-compose',
  'ss-dashboard',
  'ss-checkitem',
  'ss-battery',
  'ss-search',
  'ss-star',
  'ss-calculator',
  'ss-picnictable',
  'ss-magnet',
  'ss-fourdie',
  'ss-calendar',
  'ss-shoppingbag',
  'ss-buildings',
  'ss-calculate',
  'ss-phone',
  'ss-mutevolume',
  'ss-flashoff',
  'ss-film',
  'ss-pointright',
  'ss-downright',
  'ss-flashlight',
  'ss-loading',
  'ss-pixels',
  'ss-user',
  'ss-globe',
  'ss-window',
  'ss-chickenleg',
  'ss-record',
  'ss-up',
  'ss-coffee',
  'ss-emptybattery',
  'ss-crescentmoon',
  'ss-mic',
  'ss-bus',
  'ss-paintedit',
  'ss-tv',
  'ss-list',
  'ss-home',
  'ss-counterclockwise',
  'ss-files',
  'ss-rows',
  'ss-lamp',
  'ss-info',
  'ss-desktop',
  'ss-lowbattery',
  'ss-theatre',
  'ss-upload',
  'ss-grid',
  'ss-upleft',
  'ss-clipboard',
  'ss-headphones',
  'ss-redirect',
  'ss-radio',
  'ss-typewriter',
  'ss-move',
  'ss-inbox',
  'ss-right',
  'ss-crop',
  'ss-backspace',
  'ss-mediumbattery',
  'ss-expand',
  'ss-scaleup',
  'ss-tag',
  'ss-pictures',
  'ss-laptop',
  'ss-ghost',
  'ss-compass',
  'ss-threedie',
  'ss-volume',
  'ss-floppydisk',
  'ss-toggles',
  'ss-pencilbrushpen',
  'ss-lifepreserver',
  'ss-additem',
  'ss-highvolume',
  'ss-attach',
  'ss-transfer',
  'ss-tablet',
  'ss-location',
  'ss-navigateup',
  'ss-ruler',
  'ss-phonedisabled',
  'ss-barchart',
  'ss-filecabinet',
  'ss-downloadfolder',
  'ss-unlock',
  'ss-stopwatch',
  'ss-tree',
  'ss-paperbag',
  'ss-plus',
  'ss-train',
  'ss-dislike',
  'ss-highbattery',
  'ss-upright',
  'ss-pushpin',
  'ss-clock',
  'ss-navigatedown',
  'ss-presentation',
  'ss-mailbox',
  'ss-flatscreen',
  'ss-voicemail',
  'ss-target',
  'ss-lightbulb',
  'ss-heart',
  'ss-tea',
  'ss-cloud',
  'ss-plug',
  'ss-flashlighton',
  'ss-like',
  'ss-play',
  'ss-fish',
  'ss-dial',
  'ss-wineglass',
  'ss-share',
  'ss-gridlines',
  'ss-box',
  'ss-clapboard',
  'ss-pause',
  'ss-fivedie',
  'ss-caution',
  'ss-contacts',
  'ss-notebook',
  'ss-cell',
  'ss-folder',
  'ss-fence',
  'ss-action',
  'ss-robot',
  'ss-bird',
  'ss-help',
  'ss-left',
  'ss-binoculars',
  'ss-inboxes',
  'ss-ellipsis',
  'ss-map',
  'ss-batterydisabled',
  'ss-textchat',
  'ss-droplet',
  'ss-mail',
  'ss-keyboard',
  'ss-pointdown',
  'ss-books',
  'ss-filter',
  'ss-birdhouse',
  'ss-uploadcloud',
  'ss-shredder',
  'ss-sixdie',
  'ss-contract',
  'ss-signpost',
  'ss-notifications',
  'ss-outlet',
  'ss-cut',
  'ss-hyphen',
  'ss-replay',
  'ss-wifi',
  'ss-flask',
  'ss-file',
  'ss-paintdisabled',
  'ss-wrench',
  'ss-sun',
  'ss-bike',
  'ss-downleft',
  'ss-eject',
  'ss-navigateright',
  'ss-bank',
  'ss-banknote',
  'ss-crosshair',
  'ss-view',
  'ss-pointleft',
  'ss-ellipsischat',
  'ss-paperairplane',
  'ss-pin',
  'ss-stop',
  'ss-skipforward',
  'ss-onedie',
  'ss-taxi',
  'ss-rocket',
  'ss-briefcase',
  'ss-video',
  'ss-scaledown',
  'ss-pointup',
  'ss-columns',
  'ss-sample',
  'ss-zoomin',
  'ss-download',
  'ss-flash',
  'ss-zoomout',
  'ss-flag',
  'ss-navigateleft',
  'ss-cursor',
  'ss-delete',
  'ss-music',
  'ss-truck',
  'ss-keyboarddown',
  'ss-menu',
  'ss-paintroller',
];
