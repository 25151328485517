/* istanbul ignore file */

import { newIntegrationsAxios } from 'app/api/base';

class AuthorityService {
  constructor() {
    this.resource = 'v1/adcs/authority/';
  }

  // CREATE ------------------------------ //

  async createAuthority(data) {
    return newIntegrationsAxios(this.resource)
      .post(data)
      .then((r) => r.data);
  }

  async bulkCreateAuthorities(authorities) {
    return Promise.all(
      authorities.map((authority) => this.createAuthority(authority)),
    );
  }

  // READ -------------------------------- //

  async getAuthority(id) {
    return newIntegrationsAxios(`${this.resource}${id}/`)
      .get()
      .then((r) => r.data);
  }

  async getAuthorityLimit() {
    return newIntegrationsAxios(`${this.resource}limit`)
      .get()
      .then((r) => r.data.limit);
  }

  async listAuthorities() {
    return newIntegrationsAxios(this.resource)
      .get()
      .then((r) => r.data.results);
  }

  async listLibraryItems(id) {
    return newIntegrationsAxios(`${this.resource}${id}/library-items/`)
      .get()
      .then((r) => r.data.results);
  }

  async bulkListLibraryItems() {
    const authorities = await this.listAuthorities();

    const items = await Promise.all(
      authorities.map((authority) => this.listLibraryItems(authority.id)),
    );

    const list = items.flat();

    const map = authorities.reduce(
      (prev, curr, idx) => ({ ...prev, [curr.id]: items[idx] }),
      {},
    );

    return { list, map };
  }

  // UPDATE ------------------------------ //

  async updateAuthority(id, data) {
    return newIntegrationsAxios(`${this.resource}${id}/`)
      .patch(data)
      .then((r) => r.data);
  }

  // DELETE ------------------------------ //

  async deleteAuthority(id) {
    return newIntegrationsAxios(`${this.resource}${id}/`)
      .delete()
      .then((r) => r.data);
  }
}

const authorityService = new AuthorityService();

export default AuthorityService;
export { authorityService };
