import { integrationsBackendAxios } from 'app/api/base';
import {
  servicenowConfigSchema,
  servicenowIntegrationSchema,
  servicenowVersionSchema,
} from '../types/schema';

export type ServicenowDataParams = {
  tenant: string;
  client_id: string;
  client_secret: string;
  username: string;
  password: string;
};

export type ServicenowUpdateParams = {
  integrationId: string;
  configId: string;
  data: ServicenowDataParams;
};

/**
 *This class handles the connection to the new Integrations backend ServiceNow integration
 *
 * @class ServiceNowService
 */

class ServicenowService {
  private service: NonNullable<typeof integrationsBackendAxios>;

  private resource = 'integrations/servicenow/';

  constructor() {
    // istanbul ignore if
    if (!integrationsBackendAxios) {
      throw new Error('Integrations backend service API not found');
    }

    this.service = integrationsBackendAxios;
  }

  async getIntegration(integrationId: string) {
    const response = await this.service(
      `${this.resource}${integrationId}/`,
    ).get();

    return servicenowIntegrationSchema.parse(response.data);
  }

  async createIntegration(data: ServicenowDataParams) {
    const response = await this.service(`${this.resource}`).post({ ...data });
    return servicenowIntegrationSchema.safeParse(response.data);
  }

  async updateIntegration({
    integrationId,
    configId,
    data,
  }: ServicenowUpdateParams) {
    const response = await this.service(
      `${this.resource}${integrationId}/config/${configId}/`,
    ).patch({ ...data });

    return servicenowConfigSchema.parse(response.data);
  }

  async deleteIntegration(integrationId: string) {
    return this.service(`${this.resource}${integrationId}/`).delete();
  }

  async getVersion(integrationId: string) {
    const response = await this.service(
      `${this.resource}${integrationId}/version/`,
    ).get();

    return servicenowVersionSchema.parse(response.data);
  }
}

const servicenowService = new ServicenowService();

export default ServicenowService;
export { servicenowService };
