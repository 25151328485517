import { Card, Flex, Heading, Paragraph, styled } from '@kandji-inc/nectar-ui';

export const SpaceContainer = styled('div', {
  paddingTop: '$4',
  paddingBottom: '$4',
});

export const StyledContainer = styled(Flex, {
  alignItems: 'flex-start',
  flexDirection: 'column',
});

// size:20, weight:500, height:32, spacing:-0.8
export const StyledHeaderH1 = styled(Heading, {
  fontSize: '$4 !important',
  fontWeight: '$medium !important',
  lineHeight: '$4 !important',
  letterSpacing: '-$3',
});

// size:16, weight:500, height:24, spacing:-0.4
export const StyledHeaderH2 = styled(Heading, {
  fontSize: '$3',
  fontWeight: '$medium !important',
  lineHeight: '$3 !important',
  letterSpacing: '-$2',
});

// size:14, weight:500, height:20, spacing:normal
export const ParagraphMedium = styled(Paragraph, {
  fontWeight: '$medium !important',
});

export const StyledParagraphLight = styled(Paragraph, {
  color: '$neutral60',
});

export const StyledParagraphSemiLight = styled(Paragraph, {
  color: '$neutral70',
});

export const StyledLink = styled('a', {
  color: '$blue50',
  textDecoration: 'none',
  cursor: 'pointer',
  '&:hover': {
    textDecoration: 'underline',
  },
});

// size:12, weight:500, height:16, spacing:normal
export const StyledLinkSmall = styled(StyledLink, {
  fontSize: '$1',
  fontWeight: '$medium',
  lineHeight: '$1',
});

export const StyledHeaderImage = styled('img', {
  size: '$9',
});

export const StyledNav = styled('div', {
  padding: '$4 0',
});

export const StyledDescription = styled(Paragraph, {
  color: '$neutral70',
  fontWeight: '$medium',
  fontSize: '$1',
  lineHeight: '$1',
});

export const StyledCard = styled(Card, {
  padding: 0,
  variants: {
    variant: {
      borderless: {
        border: 'none',
      },
    },
  },
  defaultVariants: {
    variant: 'borderless',
  },
});

export const StyledCardSectionHeader = styled(Flex, {
  borderBottom: '1px solid $neutral10',
  py: '$4',
  px: '$5',
});
export const StyledCardSectionFooter = styled(Flex, {
  borderTop: '1px solid $neutral10',
  py: '10px',
  px: '$5',
});
