import styled from 'styled-components';

const Block = styled('span')`
  background: #ffffff;
  border-radius: 4px;
  padding: 20px 28px 20px 32px;

  display: flex;
  flex-direction: column;
`;

const GreyBlock = styled(Block)`
  background: #f6f7f9;
  padding: 0;
`;

Block.GreyBlock = GreyBlock;
export default Block;
