import { links } from 'app/common/constants';
import isEmpty from 'lodash/isEmpty';
import React from 'react';
import styled from 'styled-components';
import history from '../../../router/history';
import TableNoDataHelper from '../../common/TableNoDataHelper';
import GSuiteUserComputerCard from './GSuiteUserComputerCard';

const Wrapper = styled.section`
  margin-top: 2rem;
  display: grid; /* 1 */
  grid-template-columns: repeat(auto-fill, minmax(230px, 1fr));
  grid-gap: 2rem; /* 3 */
`;

const GSuiteUserComputers = ({ computers }) => (
  <>
    <Wrapper>
      {(computers || []).map((computer) => {
        const clickable = computer.id;
        return (
          <GSuiteUserComputerCard
            computer={computer}
            clickable={clickable}
            onComputerClick={() =>
              clickable ? history.push(`${links.devices}/${computer.id}`) : null
            }
          />
        );
      })}
    </Wrapper>
    {isEmpty(computers) && (
      <TableNoDataHelper
        key="##table-empty##"
        message="User has no computers"
      />
    )}
  </>
);

export default GSuiteUserComputers;
