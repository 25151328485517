import { Button } from '@kandji-inc/bumblebee';
import { AccountContext } from 'contexts/account';
import Menu from 'features/self-service-new/menu';
import parse from 'html-react-parser';
import React, { useContext } from 'react';
import { formatTime } from './date-time';
import './single-note.css';

const SingleNote = (props) => {
  const { note, onDelete, onEdit } = props;
  const { author, updated_at, content } = note;
  const account = useContext(AccountContext);

  const name =
    author.first_name && author.last_name
      ? `${author.first_name} ${author.last_name}`
      : 'Unknown user';

  return (
    <div className="b-flex-vgmicro bl-single-note">
      <div className="b-flex-vc b-flex-btw">
        <h3 className="b-h3">{`${name} - ${formatTime(
          updated_at,
          account,
        )}`}</h3>
        <Menu
          options={[
            { name: 'Edit', icon: 'pencil', onClick: onEdit },
            {
              name: 'Delete',
              icon: 'trash-can',
              theme: 'error',
              onClick: () => onDelete(note),
            },
          ]}
        >
          <div>
            <Button kind="link" icon="ellipsis" />
          </div>
        </Menu>
      </div>
      <div className="b-txt bl-single-note__content">{parse(content)}</div>
    </div>
  );
};

export default SingleNote;
