/* istanbul ignore file */
import { i18n } from 'i18n';
import { INT_TYPES } from '../../../../constants';
import welcomTickets, {
  oktaSignInTickets,
  oktaSignInErrorTickets,
  oktaVerifyAppTickets,
  oktaSetupCompleteTickets,
} from '../../../okta/modals/tickets';
import type { IntegratorViewConfig } from '../../types/integrator.types';
import type { OktaInputs, OktaState } from '../../types/okta.types';

// Helpers
import {
  oktaAddIOSHandleNext,
  oktaAddMacOSHandleNext,
  oktaAddMacOSHandleNextText,
  oktaCredentialsHandleNext,
  oktaCredentialsHandleRetry,
  // FooterStatus Options
  oktaCredentialsStatusOptions,
  oktaDeviceSetupStatusOptions,
  oktaDeviceSetupValidation,
  oktaHandleIntegrationDetails,
  oktaHandleLibraryItemView,
  oktaHandleSetupLater,
  oktaHandleSync,
  oktaHideCancelButton,
  oktaSetupCompleteDescription,
  oktaSetupCompleteStatusOptions,
  oktaSignInHandleNext,
  oktaVerifyLibraryStatusOptions,
} from './helper';

import iosIcon from '../../../okta/assets/ios-icon.svg';
import macOsIcon from '../../../okta/assets/mac-os-icon.svg';
import odtComplete from '../../../okta/assets/odt-complete.svg';
// Icons
import oktaLogo from '../../../okta/assets/okta.svg';
import errorIcon from '../../../okta/modals/assets/error.svg';
import iosOdt from '../../../okta/modals/assets/ios-odt.svg';
import macOsOdt from '../../../okta/modals/assets/macos-odt.svg';
import oktaBtnSm from '../../../okta/modals/assets/okta-button-sm.svg';

// Config
const config: IntegratorViewConfig<OktaInputs, OktaState, object> = {
  type: INT_TYPES.okta,
  name: 'Okta',
  logo: oktaLogo,
  openDialog: true,
  pages: [
    {
      name: 'oktaWelcome',
      layout: 'one-column-info',
      header: {
        get title() {
          return i18n.t('Welcome to Okta Device Trust');
        },
        get description() {
          return i18n.t(
            'The Okta Device Trust integration enables organizations to protect their corporate resources and ensures that devices are compliant before allowing access to applications through Okta.',
          );
        },
      },
      tickets: welcomTickets,
      // footer
      get nextButtonText() {
        return i18n.t('Get Started');
      },
      get cancelButtonText() {
        return i18n.t('Cancel');
      },
    },
    {
      name: 'oktaDomain',
      layout: 'one-column-input',
      header: {
        get title() {
          return i18n.t('Specify your Okta Domain');
        },
        get description() {
          return i18n.t(
            'Enter the Okta domain that will be used to connect the integration.',
          );
        },
      },
      inputs: [
        {
          name: 'oktaDomain',
          type: 'text',
          placeholder: 'domain.okta.com',
          validations: {
            get required() {
              return i18n.t('Okta domain is required');
            },
            maxLength: 255,
          },
        },
      ],
      get cancelButtonText() {
        return i18n.t('Cancel');
      },
    },
    {
      name: 'oktaSignIn',
      layout: 'one-column-info',
      header: {
        get title() {
          return i18n.t('Sign in with Okta');
        },
        get description() {
          return i18n.t(
            'Sign in to your Okta account to establish the connection between your Kandji and Okta administrative accounts.',
          );
        },
      },
      tickets: oktaSignInTickets,
      get nextButtonText() {
        return i18n.t('Sign in with Okta');
      },
      nextButtonIcon: oktaBtnSm,
      handleNext: oktaSignInHandleNext,
    },
    {
      name: 'oktaCompleteTasks',
      layout: 'one-column-info',
      header: {
        get title() {
          return i18n.t('Complete the following tasks in Okta');
        },
        get description() {
          return i18n.t(
            'You will need to perform the following actions in your Okta portal before continuing this integration setup. Learn more about each Okta workflow below.',
          );
        },
      },
      tickets: oktaSignInTickets,
    },
    {
      name: 'oktaCredentials',
      layout: 'one-column-input',
      header: {
        get title() {
          return i18n.t('API Service Integration credentials');
        },
        get description() {
          return i18n.t(
            'Enter the Client ID and Client Secret that was provided when you created the API Service Integration for Kandji in Okta.',
          );
        },
      },
      inputs: [
        {
          name: 'clientId',
          type: 'text',
          get label() {
            return i18n.t('Client ID');
          },
          get placeholder() {
            return i18n.t('Enter the Okta client ID');
          },
          validations: {
            get required() {
              return i18n.t('Required');
            },
            maxLength: {
              value: 25,
              get message() {
                return i18n.t('Must be 25 characters or fewer');
              },
            },
          },
        },
        {
          name: 'clientSecret',
          type: 'text',
          get label() {
            return i18n.t('Client Secret');
          },
          get placeholder() {
            return i18n.t('Enter the Okta client secret');
          },
          validations: {
            get required() {
              return i18n.t('Required');
            },
            maxLength: {
              value: 64,
              get message() {
                return i18n.t('Must be 64 characters or fewer');
              },
            },
          },
        },
      ],
      footerStatusOptions: oktaCredentialsStatusOptions,
      get nextButtonText() {
        return i18n.t('Connect to Okta');
      },
      handleNext: oktaCredentialsHandleNext,
    },
    {
      name: 'oktaCredentialsError',
      layout: 'one-column-info',
      logo: errorIcon,
      header: {
        get title() {
          return i18n.t('Cannot complete integration setup');
        },
        get description() {
          return i18n.t(
            'It looks like your Okta account does not have the correct settings to finalize the Okta Device Trust integration with Kandji. Please reference the following support articles below.',
          );
        },
      },
      condition: ({ state }) => !!state.verifyResult,
      tickets: oktaSignInErrorTickets,
      get nextButtonText() {
        return i18n.t('Retry');
      },
      handleNext: oktaCredentialsHandleRetry,
    },
    {
      name: 'deviceSetup',
      layout: 'two-column-checkbox',
      header: {
        get title() {
          return i18n.t('Configure device platforms');
        },
        get description() {
          return i18n.t(
            'Choose to configure Okta Device Trust for both macOS and iOS devices, or select a single device platform. This selection should reflect which device management providers you added in the Okta console in previous steps',
          );
        },
        link: {
          url: 'https://support.kandji.io/support/solutions/articles/72000580346#Configure-device-platforms-in-Okta',
          get text() {
            return i18n.t('Learn More');
          },
        },
      },
      validate: oktaDeviceSetupValidation,
      options: [
        {
          name: 'macOsDevices',
          get label() {
            return i18n.t('macOS devices');
          },
          icon: macOsIcon,
          get altText() {
            return i18n.t('mac os icon');
          },
        },
        {
          name: 'iosDevices',
          get label() {
            return i18n.t('iOS devices');
          },
          icon: iosIcon,
          get altText() {
            return i18n.t('ios icon');
          },
        },
      ],
    },
    {
      name: 'oktaAddMacOS',
      layout: 'two-column-input',
      logo: macOsOdt,
      header: {
        get title() {
          return i18n.t('Add macOS as a device platform');
        },
        get description() {
          return i18n.t(
            'Provide the following information for macOS devices. Each field was generated in Okta when adding macOS as a device management provider.',
          );
        },
      },
      condition: ({ formInputs }) => !!formInputs.macOsDevices,
      inputs: [
        {
          name: 'scepUrl',
          type: 'text',
          get label() {
            return i18n.t('SCEP URL');
          },
          get hint() {
            return i18n.t(
              'The Simple Certificate Enrollment Protocol (SCEP) URL that was generated in Okta when adding macOS a device management provider.',
            );
          },
          get placeholder() {
            return i18n.t('Enter the base URL for the SCEP server');
          },
          validations: {
            get required() {
              return i18n.t('Required');
            },
            maxLength: {
              value: 250,
              get message() {
                return i18n.t('Must be 250 characters or fewer');
              },
            },
          },
        },
        {
          name: 'username',
          type: 'text',
          get label() {
            return i18n.t('Username');
          },
          get placeholder() {
            return i18n.t('Enter the Okta username');
          },
          validations: {
            get required() {
              return i18n.t('Required');
            },
            maxLength: {
              value: 25,
              get message() {
                return i18n.t('Must be 25 characters or fewer');
              },
            },
          },
        },
        {
          name: 'challengeUrl',
          type: 'text',
          get label() {
            return i18n.t('Challenge URL');
          },
          get hint() {
            return i18n.t(
              'The Challenge URL that was generated in Okta when adding macOS a device management provider.',
            );
          },
          get placeholder() {
            return i18n.t('Enter the URL to retrieve the SCEP challenge');
          },
          validations: {
            get required() {
              return i18n.t('Required');
            },
            maxLength: {
              value: 250,
              get message() {
                return i18n.t('Must be 250 characters or fewer');
              },
            },
          },
        },
        {
          name: 'password',
          type: 'password',
          get label() {
            return i18n.t('Password');
          },
          get placeholder() {
            return i18n.t('Enter the password');
          },
          validations: {
            get required() {
              return i18n.t('Required');
            },
            maxLength: {
              value: 50,
              get message() {
                return i18n.t('Must be 50 characters or fewer');
              },
            },
          },
        },
      ],
      footerStatusOptions: oktaDeviceSetupStatusOptions,
      nextButtonText: oktaAddMacOSHandleNextText,
      handleNext: oktaAddMacOSHandleNext,
    },
    {
      name: 'oktaAddIOS',
      layout: 'one-column-input',
      logo: iosOdt,
      header: {
        get title() {
          return i18n.t('Add iOS as a device platform');
        },
        get description() {
          return i18n.t(
            'Provide the following information for iOS devices. The secret key was generated in Okta when adding iOS as a device management provider.',
          );
        },
      },
      condition: ({ formInputs }) => !!formInputs.iosDevices,
      inputs: [
        {
          name: 'secretKey',
          type: 'password',
          get label() {
            return i18n.t('Secret key');
          },
          get hint() {
            return i18n.t(
              'The secret key that was generated in Okta when adding iOS as a device management provider.',
            );
          },
          get placeholder() {
            return i18n.t('Enter the secret key');
          },
          validations: {
            get required() {
              return i18n.t('Required');
            },
            maxLength: {
              value: 50,
              get message() {
                return i18n.t('Must be 50 characters or fewer');
              },
            },
          },
        },
      ],
      footerStatusOptions: oktaDeviceSetupStatusOptions,
      get nextButtonText() {
        return i18n.t('Finish setup');
      },
      handleNext: oktaAddIOSHandleNext,
    },
    {
      name: 'oktaVerifyLibrary',
      layout: 'one-column-info',
      header: {
        get title() {
          return i18n.t(' The Okta Verify app is not in your Library');
        },
        get description() {
          return i18n.t(
            'You can use Kandji&apos;s integration with Apps and Books in Apple Business Manager to install Okta Verify on your devices. ',
          );
        },
      },
      condition: ({ state }) => !state.verifyLibrary,
      tickets: oktaVerifyAppTickets,
      links: [
        {
          url: 'https://business.apple.com/',
          get title() {
            return i18n.t('Apple Business Manager');
          },
        },
        {
          url: 'https://school.apple.com/',
          get title() {
            return i18n.t('Apple School Manager');
          },
        },
      ],
      footerStatusOptions: oktaVerifyLibraryStatusOptions,
      get cancelButtonText() {
        return i18n.t('Set this up later');
      },
      handleClose: oktaHandleSetupLater,
      get nextButtonText() {
        return i18n.t('Sync now');
      },
      handleNext: oktaHandleSync,
    },
    {
      name: 'oktaSetupComplete',
      layout: 'one-column-info',
      logo: odtComplete,
      header: {
        get title() {
          return i18n.t('Okta Device Trust setup complete');
        },
        description: oktaSetupCompleteDescription,
      },
      tickets: oktaSetupCompleteTickets,
      footerStatusOptions: oktaSetupCompleteStatusOptions,
      showCancelButton: oktaHideCancelButton,
      handleClose: oktaHandleLibraryItemView,
      get nextButtonText() {
        return i18n.t('View integration settings');
      },
      handleNext: oktaHandleIntegrationDetails,
    },
  ],
};

export default config;
