import React from 'react';
import history from '../../../router/history';
import UniversalAlert from './UniversalAlert';

const IntegrationIsBrokenBanner = ({ service }) => (
  <UniversalAlert
    type="warning"
    text={`Unable to sync users from ${service}.`}
    button={{
      text: `Reconnect ${service}`,
      onClick: () =>
        history.push({
          pathname: '/my-company/integrations',
          state: {
            anchor: 'integration',
          },
        }),
    }}
  />
);

export default IntegrationIsBrokenBanner;
