import {
  getActionsColumn,
  getChannelsColumn,
  getNameColumn,
} from '../generic/communication-integrations/event-notifications/integration-card/standard-columns';

function getTableColumns(...triggers) {
  return [
    getNameColumn(286),
    getChannelsColumn(454, 'bell'),
    getActionsColumn(320, ...triggers),
  ];
}

export default getTableColumns;
