import { styled } from '@kandji-inc/nectar-ui';
import React from 'react';
import colors from '../../../../../blueprints/colors';

type Colors = keyof typeof colors;

type Props = {
  iconName: string;
  color: Colors;
};

const IconContainer = styled('div', {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: '50%',
  width: '24px',
  height: '24px',

  '& i': {
    color: '$neutral0',
    fontSize: '$1',
    height: '1.6em',
  },
});

const BlueprintIcon = (props: Props) => {
  const { iconName, color } = props;
  return (
    <IconContainer css={{ backgroundColor: colors[color] }}>
      <i className={iconName} />
    </IconContainer>
  );
};

export default BlueprintIcon;
