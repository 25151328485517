import { Button, DropdownMenu, Flex, styled } from '@kandji-inc/nectar-ui';
import React, { useEffect } from 'react';
import { useReactFlow } from 'reactflow';
import { useShallow } from 'zustand/react/shallow';

import { UserRoles } from 'src/app/common/constants';
import useAccount from 'src/contexts/account';

import Overlay from '../../components/Overlay';
import { PENDO_IDS, libraryItemSortOptions } from '../../constants';
import useBlueprintFlow from '../../store';
import DeviceSearchDropdown from './DeviceSearch';
import MapSearch from './map-search';

const ActionBarContainer = styled(Flex, {
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  zIndex: 1,
  padding: '$2 $5',
  backgroundColor: '$neutral0',
  borderBottom: '1px solid rgba(53, 65, 78, 0.24)',

  variants: {
    isEditingAssignments: {
      true: {
        left: '310px',
        width: 'calc(100% - 310px)',
        padding: '8px 10px',
        boxShadow: '$elevation1',
      },
    },
  },
});

const ActionBar = () => {
  const { userRole } = useAccount();
  const isUserHelpDesk = userRole === UserRoles.helpdesk;
  const isAuditor = userRole === UserRoles.auditor;

  const [
    isEditingAssignments,
    assignmentsLibrarySort,
    model,
    setAssignmentsLibrarySort,
    setIsEditingAssignments,
    isDeletingNode,
    setSelectedDevice,
  ] = useBlueprintFlow(
    useShallow((state) => [
      state.isEditingAssignments,
      state.assignmentsLibrarySort,
      state.model,
      state.setAssignmentsLibrarySort,
      state.setIsEditingAssignments,
      state.isDeletingNode,
      state.setSelectedDevice,
    ]),
  );

  const { fitView } = useReactFlow();

  useEffect(
    () => () => {
      if (!isEditingAssignments) {
        // If changing from !editing -> editing, reset selected device
        setSelectedDevice({ device: null, devicePath: null });
      }
    },
    [isEditingAssignments],
  );

  return (
    <ActionBarContainer
      justifyContent="space-between"
      isEditingAssignments={isEditingAssignments}
      css={{
        .../* istanbul ignore next */ (isDeletingNode
          ? {
              borderBottom: 'none',
            }
          : {}),
      }}
    >
      <Flex gap="sm" alignItems="center">
        {!isEditingAssignments && <DeviceSearchDropdown />}
        <MapSearch />
        <DropdownMenu
          contentProps={{ align: 'start' }}
          css={{
            zIndex: 10,
            "& [role='menuitem']:has(svg)": {
              backgroundColor: '$dropdown_surface_selected_enabled',
              color: '$blue50',
            },
          }}
          options={libraryItemSortOptions.map((opt) => ({
            ...opt,
            icon:
              assignmentsLibrarySort === opt.value
                ? 'fa-check-14px'
                : undefined,
            iconPosition: 'right',
            onClick: () => setAssignmentsLibrarySort(opt.value),
          }))}
        >
          <Button
            compact
            data-testid="sort-library-items"
            variant="subtle"
            icon={{ name: 'arrow-down-arrow-up' }}
          />
        </DropdownMenu>
      </Flex>
      {!isUserHelpDesk && !isEditingAssignments && (
        <Button
          variant="primary"
          icon={{ name: 'pen' }}
          onClick={() => {
            setIsEditingAssignments(true);
            setTimeout(() => fitView({ nodes: model.nodes, duration: 500 }));
          }}
          id={PENDO_IDS.editMapBtn}
          disabled={isAuditor}
        >
          Edit assignments
        </Button>
      )}
      <Overlay isHidden={!isDeletingNode} />
    </ActionBarContainer>
  );
};

export default ActionBar;
