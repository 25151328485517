import { useQuery } from '@tanstack/react-query';
import { newIntegrationsService } from '../data-service/new-integrations-service';
import { integrationsKeys } from './query-key-factory';

/* This hook gets data from the existing integrations endpoint on company backend. Not to be confused with integrations-backend. Eventually this should be removed once all integrations are moved to integrations-backend. */

const useListIntegrations = () =>
  useQuery({
    queryKey: integrationsKeys.all,
    queryFn: () => newIntegrationsService.listIntegrations(),
  });

export default useListIntegrations;
