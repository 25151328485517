import { z } from 'zod';
import { servicenowConfigSchema } from '../../components-for-specific-types/servicenow/types/schema';

const baseShape = z.object({
  id: z.string().uuid(),
  created_at: z.string(),
  updated_at: z.string(),
});

/*
  The discriminatedUnion allows us to check the integration 'type' and provide differen't data objects based on the type that is being returned. For instance, servicenow will probably have a different configs array than the next integration we implement.
*/
const integrationItems = z
  .discriminatedUnion('type', [
    z.object({
      type: z.literal('servicenow'),
      servicenow_configs: z.array(servicenowConfigSchema).optional(),
    }),
  ])
  .and(baseShape);

export const integrationsBackendSchema = z.object({
  total: z.number(),
  page: z.number(),
  size: z.number(),
  pages: z.number(),
  items: z.array(integrationItems),
});
