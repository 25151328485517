import {
  TextInput,
  onEmptyBlurValidator,
  useInvalidations,
} from '@kandji-inc/bumblebee';
import type { ChangeEvent } from 'react';
import React, { useState } from 'react';
import { useModalState } from 'src/features/integrations/hooks/use-modal-state';
import styled from 'styled-components';
import FooterButtons from '../../../components/footer-buttons';
import FooterStatus from '../../../components/footer-status/footer-status';
import {
  Container,
  StyledSlideHeader,
} from '../../../components/styled-components';
import useModal from '../../../hooks/use-modal';
import AddMacOSErrorBanner from '../components/add-mac-os-error-banner';
import LabelTooltip from '../components/label-tooltip';
import useOktaDeviceTrustPlatform from '../hooks/use-okta-device-trust-platform';
import useOktaLicense from '../hooks/use-okta-license';
import type { AddMacOsProps, FooterStatusState, LibraryItem } from '../types';
import macOsOdt from './assets/macos-odt.svg';
import footerIcon from './assets/okta-small.svg';
import { oktaSlideMap } from './okta-slide-map';

const InputContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: var(--b-gap3);
`;

const StyledTextInputContainer = styled.div`
  width: 360px;
  height: 95px;
  text-align: left;
`;
const StyledHeaderContent = styled.div`
  max-width: 640px;
  justify-self: center;
`;

const ModalContent = styled.div`
  display: grid;
  margin: auto;
  text-align: center;
  justify-content: center;
  & > ${InputContainer} {
    margin-top: var(--b-gap2);
  }
`;

const StyledInputGroup = styled.div`
  ${StyledTextInputContainer}:nth-child(2) {
    margin-top: var(--b-gap2);
  }
`;

const AddMacOS = (props: AddMacOsProps) => {
  const { skipIos } = props;
  const [macDeviceState, setMacDeviceState] = useState({
    scepUrl: '',
    challengeUrl: '',
    username: '',
    password: '',
  });

  const [footerStatusState, setFooterStatusState] =
    useState<FooterStatusState>('');

  const [showErrorBanner, setShowErrorBanner] = useState(false);

  const { nextModal, previousModal } = useModal();
  const { state: modalState, setModalState } = useModalState();
  const { isLoading, createDevicePlatform } = useOktaDeviceTrustPlatform();
  const { invalidations, onInvalidate } = useInvalidations({
    inputs: 4,
  });
  const { getLicenseCount, error } = useOktaLicense({ disableAutoFetch: true });

  const onClose = () => nextModal(oktaSlideMap.cancelConfirm);

  const handleNext = async () => {
    if (skipIos) {
      setFooterStatusState('pending');

      const { oktaDomain, token, clientId, clientSecret } = modalState;

      try {
        const devicePlatformResponse = await createDevicePlatform({
          domain: oktaDomain,
          token,
          okta_url: oktaDomain,
          mac_scep_url: scepUrl,
          mac_scep_challenge_url: challengeUrl,
          mac_scep_challenge_password: password,
          mac_scep_challenge_username: username,
          client_id: clientId,
          client_secret: clientSecret,
        });

        if (
          devicePlatformResponse &&
          Object.keys(devicePlatformResponse).length
        ) {
          const licenseData = (await getLicenseCount()) as LibraryItem;

          if (licenseData?.licenses_total && error == null) {
            nextModal(
              oktaSlideMap.setupComplete({ libraryItemId: licenseData.id }),
            );
          } else {
            nextModal(oktaSlideMap.addOktaVerifyLibrary);
          }
        }
      } catch (e) {
        setFooterStatusState('');
        setShowErrorBanner(true);
      }
    } else {
      setModalState({
        scepUrl,
        challengeUrl,
        username,
        password,
      });
      nextModal(oktaSlideMap.addIOS);
    }
  };
  const onBack = () => previousModal();

  const handleChange =
    (name: string) => (event: ChangeEvent<HTMLInputElement>) => {
      setMacDeviceState({
        ...macDeviceState,
        [name]: event.target.value,
      });
    };

  const handleValidation = (value: string) => [
    onEmptyBlurValidator(value, {
      message: 'Required',
      trigger: ['onBlur'],
    }),
  ];

  const { scepUrl, challengeUrl, username, password } = macDeviceState;

  const isNextDisabled =
    invalidations.some(Boolean) ||
    scepUrl.length > 250 ||
    challengeUrl.length > 250 ||
    username.length > 25 ||
    password.length > 50;

  return (
    <Container>
      <ModalContent>
        <StyledHeaderContent>
          <img src={macOsOdt} className="b-mb2" alt="header-icons" />
          <StyledSlideHeader>Add macOS as a device platform</StyledSlideHeader>
          <p className="b-txt-light">
            Provide the following information for macOS devices. Each field was
            generated in Okta when adding macOS as a device management provider.
          </p>
          <AddMacOSErrorBanner showErrorBanner={showErrorBanner} />
        </StyledHeaderContent>

        <InputContainer>
          <StyledInputGroup>
            <StyledTextInputContainer>
              <LabelTooltip
                label="SCEP URL"
                tooltipMessage="The Simple Certificate Enrollment Protocol (SCEP) URL that was generated in Okta when adding macOS a device management provider."
              />
              <TextInput
                value={scepUrl}
                onChange={handleChange('scepUrl')}
                placeholder="Enter the base URL for the SCEP server"
                maxLength={250}
                onInvalidate={onInvalidate(0)}
                validator={handleValidation}
                hideMaxLength
              />
            </StyledTextInputContainer>
            <StyledTextInputContainer>
              <LabelTooltip
                label="Challenge URL"
                tooltipMessage="The Challenge URL that was generated in Okta when adding macOS a device management provider."
              />
              <TextInput
                value={challengeUrl}
                onChange={handleChange('challengeUrl')}
                placeholder="Enter the URL to retrieve the SCEP challenge"
                maxLength={250}
                onInvalidate={onInvalidate(1)}
                validator={handleValidation}
                hideMaxLength
              />
            </StyledTextInputContainer>
          </StyledInputGroup>
          <StyledInputGroup>
            <StyledTextInputContainer>
              <label>Username</label>
              <TextInput
                value={username}
                onChange={handleChange('username')}
                placeholder="Enter the Okta username"
                maxLength={25}
                onInvalidate={onInvalidate(2)}
                validator={handleValidation}
                hideMaxLength
              />
            </StyledTextInputContainer>
            <StyledTextInputContainer>
              <label>Password</label>
              <TextInput
                value={password}
                onChange={handleChange('password')}
                placeholder="Enter the password"
                maxLength={50}
                onInvalidate={onInvalidate(3)}
                validator={handleValidation}
                type="password"
                hideMaxLength
              />
            </StyledTextInputContainer>
          </StyledInputGroup>
        </InputContainer>
      </ModalContent>
      <FooterButtons
        onClose={onClose}
        handleNext={handleNext}
        cancelButtonText="Cancel setup"
        backOnClick={onBack}
        showBackButton
        nextButtonText={skipIos ? 'Finish setup' : 'Next'}
        nextButtonDisabled={isNextDisabled}
        showStepper
        stepperIdx={4}
        stepsAmount={6}
        isWorking={isLoading}
        customComponent={
          skipIos && footerStatusState ? (
            <FooterStatus
              icon={footerIcon}
              status={footerStatusState}
              options={{
                pending: {
                  title: 'Finalizing integration setup...',
                  statusText: 'In progress',
                },
              }}
            />
          ) : undefined
        }
      />
    </Container>
  );
};

export default AddMacOS;
