import { styled } from '@kandji-inc/nectar-ui';
import NoResults from './assets/no_results.png';

const ADETableNoResultsContainer = styled('div', {
  width: '100%',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '$10',
});

const Image = styled('img', {
  marginBottom: '16px',
});

const Header = styled('span', {
  color: '$text_primary',
  fontWeight: '$medium',
});

const Description = styled('span', {
  marginBottom: '14px',
});

export function ADETableNoResults() {
  return (
    <ADETableNoResultsContainer>
      <Image src={NoResults} />
      <Header>No results found</Header>
      <Description>
        We couldn’t find a match. Try changing your filter parameters, or search
        with different keywords.
      </Description>
    </ADETableNoResultsContainer>
  );
}
