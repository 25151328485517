import { Button } from '@kandji-inc/bumblebee';
import { theme } from 'app/common/constants';
import { withPermissions } from 'contexts/account';
import { i18n } from 'i18n';
import debounce from 'lodash/debounce';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
/* istanbul ignore file */
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import styled from 'styled-components';
import NoDataToDisplay from '../../../assets/img/no_data_to_display.png';
import history from '../../router/history';
import BootstrapTable from '../common/BootstrapTable/BootstrapTable';
import TableHeaderColumn from '../common/BootstrapTable/TableHeaderColumn';
import Table from '../common/Table';
import TableNoDataHelper from '../common/TableNoDataHelper';
import AwesomeTableToolBar from '../interface/AwesomeTableToolBar';

import {
  setForceUpdate as callSetForceUpdate,
  getUserIntegration,
  getUsers,
} from '../../_actions/gSuite';
import { setModal as callSetModal } from '../../_actions/ui';

const integrationTypes = {
  SCIM: 'SCIM Protocol',
  GOOGLE: 'Google Workspace',
  AZURE: 'Microsoft Entra ID',
};

export const apiToIntegrationTypes = {
  scim: integrationTypes.SCIM,
  gsuite: integrationTypes.GOOGLE,
  office365: integrationTypes.AZURE,
};

const queryString = require('query-string');

const UserElement = styled.div`
  display: flex;
  flex-direction: row;
  width: 95%;
  border-radius: 5px;
  font-family: var(--font-family-primary);
  font-weight: 400;
  min-height: 40px;
  margin: 4px 0;
`;

const PhotoWrapper = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 20px;
  overflow: hidden;
  margin-right: 20px;
`;

const Photo = styled.img`
  width: 100%;
  height: 100%;
`;

const NoPhotoWrapper = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 20px;
  border: 1px solid ${(props) => props.theme.colors['grey-150']};
  overflow: hidden;
  margin-right: 20px;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const NoPhotoIconWrapper = styled.div`
  width: 75%;
  height: 75%;
  text-align: center;
`;

const NoPhotoIcon = styled.i`
  font-size: 20px;
`;

const UserInfoWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const Name = styled.div``;

export class GSuiteUsersTable extends Table {
  constructor(props) {
    super(props);
    this.selectRowId = 'id';
    this.onArchiveOrRestore = this.onArchiveOrRestore.bind(this);
    this.hasIntegrations = false;

    // Don't remove! It's used on parent
    this.serverSidePagination = true;
    this.processAfterDataFetched = this.table ? this.table.cleanSelected : null;
    this.UNSAFE_componentWillReceivePropsAdditionalCondition =
      this.componentWillReceivePropsAdditionalCondition;
    this.UNSAFE_afterComponentWillReceiveProps =
      this.afterComponentWillReceiveProps;
    this.fetchFunc = (queryParams) => props.getUsers(queryParams);

    this.state = {
      ...this.state,
      selectedRows: new Set(),
      hasIntegrations: false,
    };
  }

  componentWillMount() {
    const { permissions } = this.props;
    if (!permissions.canManageSettings) {
      return;
    }
    Promise.all([this.fetchData(true), this.props.getUserIntegration()]).then(
      (r) => {
        const integrations = r[1];
        if (
          (integrations && integrations.count) ||
          integrations.results.length
        ) {
          this.setState({ hasIntegrations: true });
        }
      },
    );
  }

  componentWillReceivePropsAdditionalCondition = () => {
    const { forceUpdate } = this.props;
    return forceUpdate;
  };

  afterComponentWillReceiveProps = () => {
    const { forceUpdate, setForceUpdate } = this.props;
    if (forceUpdate) {
      setForceUpdate(false);
    }
  };

  // Don't remove! It's used on parent
  makeQueryParams = () => {
    const { location, activeTab } = this.props;
    let queryParams = queryString.parse(location.search, {
      arrayFormat: 'bracket',
    });
    if (isEmpty(queryParams) && activeTab === 'withDevices') {
      queryParams = { is_archived: false, num_computers__gt: 0 };
    }
    return queryParams;
  };

  formatUserRow = (cell, row) => (
    <UserElement key={row.id} theme={theme}>
      {row.photo && (
        <PhotoWrapper theme={theme}>
          <Photo src={row.photo} alt="" />
        </PhotoWrapper>
      )}
      {!row.photo && (
        <NoPhotoWrapper theme={theme}>
          <NoPhotoIconWrapper theme={theme}>
            <NoPhotoIcon className="ss-icon ss-user" />
          </NoPhotoIconWrapper>
        </NoPhotoWrapper>
      )}
      <UserInfoWrapper>
        <Name theme={theme}>{row.name}</Name>
      </UserInfoWrapper>
    </UserElement>
  );

  onArchiveOrRestore = () => this.setState({ selectedRows: new Set() });

  renderActionButtons = (activeTab) => {
    const { setModal } = this.props;
    const { selectedRows } = this.state;
    return (
      <div className="d-flex" style={{ gap: 10 }}>
        <Button
          theme="dark"
          disabled={isEmpty(selectedRows)}
          onClick={
            activeTab === 'archived'
              ? () =>
                  setModal('RESTORE_G_SUITE_USERS', {
                    selectedRows,
                    onRestore: this.onArchiveOrRestore,
                  })
              : () =>
                  setModal('ARCHIVE_G_SUITE_USERS', {
                    selectedRows,
                    onArchive: this.onArchiveOrRestore,
                  })
          }
        >
          {activeTab === 'archived' ? 'Restore users' : 'Archive users'}
        </Button>
        {activeTab === 'archived' && (
          <Button
            theme="dark"
            disabled={isEmpty(selectedRows)}
            onClick={() =>
              setModal('DELETE_G_SUITE_USERS', {
                selectedRows,
                onDelete: this.onArchiveOrRestore,
              })
            }
          >
            {i18n.t('Delete Users')}
          </Button>
        )}
      </div>
    );
  };

  changeFilterInUrl(filters) {
    const { location, match } = this.props;
    const queryParams = queryString.parse(location.search, {
      arrayFormat: 'bracket',
    });
    if (isEqual(filters, queryParams)) {
      return null;
    }
    const newFilters = queryString.stringify(
      { ...queryParams, ...filters },
      { arrayFormat: 'bracket' },
    );
    history.push(`${match.url}?${newFilters}`);
    return null;
  }

  onSortChange = (sortName, sortOrder) =>
    this.changeFilterInUrl({ ordering: this.getOrdering(sortName, sortOrder) });

  getOrdering = (sortName, sortOrder) =>
    sortOrder === 'desc' ? `-${sortName}` : sortName;

  renderNoDataView = () => {
    const { gSuiteAccountType, permissions } = this.props;
    const { isLoading, hasIntegrations } = this.state;
    if (isLoading) {
      return <span />;
    }
    if (gSuiteAccountType) {
      return (
        <TableNoDataHelper
          borderless
          key="##table-empty##"
          message="No data to display"
          image={NoDataToDisplay}
          onFullWidth
        />
      );
    }

    return (
      <TableNoDataHelper
        borderless
        key="##table-empty##"
        message="No User Data"
        submessage={
          hasIntegrations
            ? ''
            : 'A user directory integration is not yet configured. An integration is needed for syncing user data and assigning computers.'
        }
        image={NoDataToDisplay}
        btnText={
          permissions.canManageSettings && !hasIntegrations
            ? 'Go To Integrations'
            : null
        }
        onBtnClick={
          permissions.canManageSettings && !hasIntegrations
            ? () => {
                /* istanbul ignore next */
                history.push('/integrations');
              }
            : null
        }
        onFullWidth
      />
    );
  };

  render() {
    const { gSuiteUsers, activeTab, permissions } = this.props;
    const { isLoading, sizePerPage } = this.state;

    const usersWithIntegrations = gSuiteUsers.map((user) => {
      let integrationName;
      if (typeof user.integration === 'string') {
        integrationName = user.integration;
      }
      integrationName =
        integrationName ||
        user.integration?.name ||
        apiToIntegrationTypes[user.integration?.type] ||
        '-';
      integrationName = integrationName.replace(/\s*\(archived .*\)$/, '');

      return {
        ...user,
        integration: integrationName,
      };
    });
    const options = {
      ...this.defaultOptions,
      defaultSortName: 'name',
      defaultSortOrder: 'asc',
      onSortChange: this.onSortChange,
      doNotSortingData: true,
      sizePerPage,
      onRowClick: (row) => history.push(`/users/all/${row.id}`),
      noDataView: this.renderNoDataView(),
    };
    delete options.toolBar;
    delete options.btnGroup;
    return (
      <div data-loading={isLoading}>
        <AwesomeTableToolBar
          btnGroup={
            permissions.canManageSettings && this.renderActionButtons(activeTab)
          }
          searchLabel="Search Users"
          searchFunc={debounce(
            (searchString) => this.changeFilterInUrl({ search: searchString }),
            400,
          )}
        />
        <div className="tab-parameters" id="bootstrap-table-parameter-tab">
          <BootstrapTable
            ref={(node) => {
              this.table = node;
            }}
            data={usersWithIntegrations}
            version="4"
            pagination
            remote={() => ({
              pagination: true,
            })}
            selectRow={permissions.canManageSettings && this.selectRowProp}
            options={options}
            tableContainerClass="mini"
            containerClass="bst-borderless old-table"
            containerStyle={{
              marginBottom: '82px',
            }}
          >
            <TableHeaderColumn dataField="id" hidden isKey />
            <TableHeaderColumn
              dataField="name"
              dataFormat={this.formatUserRow}
              columnClassName="column-border-right"
              className="header-column-border-right"
              dataSort
            >
              User
            </TableHeaderColumn>
            <TableHeaderColumn
              dataField="email"
              dataSort
              columnClassName="table-border-right"
              className="table-border-right"
            >
              Email
            </TableHeaderColumn>
            <TableHeaderColumn dataField="integration" dataSort>
              User Directory
            </TableHeaderColumn>
          </BootstrapTable>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  gSuiteAccountType: state.gSuite.type,
  gSuiteUsers: state.gSuite.gSuiteUsers,
  forceUpdate: state.gSuite.forceUpdate,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getUsers,
      getUserIntegration,
      setForceUpdate: callSetForceUpdate,
      setModal: callSetModal,
    },
    dispatch,
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withPermissions(GSuiteUsersTable));
