/* istanbul ignore file */

import { i18n } from 'i18n';
import React from 'react';

import { formatTime } from '../../common/helpers';

const entry = ({ details }) => {
  const { server_name } = details;

  return {
    icon: 'trash-can',
    iconText: i18n.t('AD CS server deleted'),
    text: i18n.t('AD CS server deleted'),
    description: server_name,
  };
};

const detail = ({ action_type, details }, { connectorList }) => {
  const { connector_assignment, deleted_by, deleted_on, server_name } = details;

  const { name, bound_domain } =
    connectorList?.find(({ id }) => id === connector_assignment) || {};

  return (
    <div className={`activity-entry-details ${action_type}`}>
      <p>Deleted on: {formatTime(deleted_on)}</p>
      <p>Deleted by: {deleted_by}</p>
      <p>Server name: {server_name}</p>
      <p>
        Connector assignment:{' '}
        {name || bound_domain || 'Connector details are pending...'}
      </p>
    </div>
  );
};

export default { entry, detail };
