import { ENTERPRISE_API_HOST } from 'app/api/domains';
import React, { useContext } from 'react';

import { Banner, Button } from '@kandji-inc/bumblebee';

import { InterfaceContext } from 'contexts/interface';
import Link from '../../theme/components/atoms/Link';
import Sticky from '../../theme/components/atoms/Sticky';
import Basic from '../../theme/components/molecules/Basic';

import { Context, Provider } from './context';
import Modals from './modals';
import TableAPIToken from './table';

import EmptyContent from '../../theme/components/organisms/EmptyContent';
import './EnterpriseApi.css';
import { i18n } from 'i18n';

function Module() {
  const { data } = useContext(Context);
  const { onModalOpen } = useContext(InterfaceContext);
  const panelContent = data.length ? (
    <>
      <div className="b-pad2 light-top-border">
        <p className="b-txt">
          {i18n.t(
            'Your organization’s API tokens at a glance. You can create or revoke tokens below.',
          )}{' '}
          <Link href="https://support.kandji.io/support/solutions/articles/72000560412" />
        </p>
        <Banner
          theme="info"
          kind="normal"
          className="b-mt1 enterprise-api--banner"
        >
          <div className="b-flex b-flex-align-center b-flex-justify-start">
            <span>{i18n.t('Your organization’s API URL is:')} </span>
            <span className="b-pad-micro">{ENTERPRISE_API_HOST}</span>
          </div>
        </Banner>
      </div>
      <div className="light-top-border">
        <TableAPIToken />
      </div>
    </>
  ) : (
    <EmptyContent
      text={i18n.t(`
          Enable and configure access to the API for your instance. 
          Add a new token and specify permissions below.`)}
      title=""
      classes={{
        wrapper: {
          className: 'light-top-border',
        },
      }}
      extra={
        <Button
          kind="link"
          icon="circle-plus"
          onClick={() => onModalOpen('add')}
          theme="action"
          className="enterprise-api--add-token-button"
        >
          {i18n.t('Add API Token')}
        </Button>
      }
    />
  );

  return (
    <>
      <Basic section="enterprise-api">
        <div className="enterprise-api--card">
          <Sticky
            label="paper-header"
            origin="paper"
            edge="border"
            sticky={false}
          >
            <div className="b-pad2">
              <h3 className="b-h3">
                <span>{i18n.t('API Token')}</span>
              </h3>
            </div>
          </Sticky>
          {panelContent}
        </div>
      </Basic>
      <Modals />
    </>
  );
}

export default () => (
  <Provider>
    <Module />
  </Provider>
);
