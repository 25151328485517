import { links } from 'app/common/constants';
import { useFeature } from 'features/integrations/hooks';
import { node } from 'prop-types';
import React from 'react';
import { Redirect, Route, Switch } from 'react-router';
import '../common-styles.scss';
import ADCSPage from '../components-for-specific-types/adcs';
import CommunicationIntegrationPage from '../components-for-specific-types/generic/communication-integrations/integration-page';
import {
  INT_CATEGORIES,
  INT_TYPES,
  INT_TYPE_CATEGORY_MAPPING,
} from '../constants';
import { IsProvider } from '../hooks/use-is';
import { ModalProvider } from '../hooks/use-modal';
import { PanelProvider } from '../hooks/use-panel';
import IntegrationsMarketplace from '../marketplace';
import IntegrationsOverview from '../overview';

import IntegratorViewWrapper from '../components-for-specific-types/integrator/integrator-view';
import { OktaDeviceTrustProvider } from '../components-for-specific-types/okta/hooks/use-get-okta-device-trust';

import MSDeviceComplianceDetails from '../components-for-specific-types/ms-device-compliance/components/details-integration/index';
// Details
import MSTeamsDetails from '../components-for-specific-types/ms-teams/ms-teams-details';
import OktaDetails from '../components-for-specific-types/okta/okta-details';
import DetailsIntegration from '../components-for-specific-types/servicenow/components/details-integration';

// provide all the things to Integrations at the top level
const Providers = ({ children }) => (
  <IsProvider>
    <PanelProvider>
      <OktaDeviceTrustProvider>
        <ModalProvider>{children}</ModalProvider>
      </OktaDeviceTrustProvider>
    </PanelProvider>
  </IsProvider>
);

const IntegrationRoutes = ({ testing }) => {
  const { isServicenowEnabled } = useFeature();

  const communicationPattern =
    INT_TYPE_CATEGORY_MAPPING[INT_CATEGORIES.communication].join('|');

  const communicationIntegrationsPath = `${links.integrations.integrationPage}(${communicationPattern})`;

  const securityPattern =
    INT_TYPE_CATEGORY_MAPPING[INT_CATEGORIES.security].join('|');

  const securityIntegrationsPath = `${links.integrations.integrationPage}(${securityPattern})`;

  return (
    <Switch>
      {/* Overview integrations */}
      <Route
        path={links.integrations.root}
        exact
        component={() => <IntegrationsOverview testing={testing} />}
      />

      {/* Marketplace integration */}
      <Route
        path={links.integrations.marketplace}
        component={IntegrationsMarketplace}
      />

      {/* Integration configuration (IntegratorView) */}
      <Route
        path={`${links.integrations.root}/new`}
        component={IntegratorViewWrapper}
      />

      {/* Integration details */}
      <Route
        path={`${links.integrations.root}/${INT_TYPES.msTeams}`}
        component={MSTeamsDetails}
      />
      <Route
        path={`${links.integrations.root}/${INT_TYPES.okta}`}
        component={OktaDetails}
      />

      {isServicenowEnabled && (
        /* istanbul ignore next */
        <Route
          path={`${links.integrations.root}/${INT_TYPES.servicenow}/:integrationId`}
          component={DetailsIntegration}
        />
      )}
      <Route
        path={`${links.integrations.root}/${INT_TYPES.msDeviceCompliance}`}
        component={MSDeviceComplianceDetails}
      />

      <Route path={securityIntegrationsPath} component={ADCSPage} />

      <Route
        path={communicationIntegrationsPath}
        component={CommunicationIntegrationPage}
      />
      <Redirect to={links.integrations.root} />
    </Switch>
  );
};

const Integrations = ({ testing }) => (
  <Providers>
    <IntegrationRoutes testing={testing} />
  </Providers>
);

Providers.propTypes = {
  children: node.isRequired,
};

export default Integrations;
