import {
  Button,
  type ButtonProps,
  type CSS,
  Dialog,
  Flex,
} from '@kandji-inc/nectar-ui';
import { i18n } from 'i18n';

type Props = {
  size?: 'sm' | 'md' | 'lg';
  isOpen?: boolean;
  title: string;
  content?: JSX.Element;
  nextText?: string;
  nextClick: () => void;
  backText?: string;
  backClick: () => void;
  isProcessing?: boolean;
  variant?: ButtonProps['variant'];
  css?: CSS;
};

const dialogSize = {
  sm: { width: '480px' },
  md: { width: '640px' },
  lg: { width: '800px' },
};

const ActionDialog = ({
  size = 'sm',
  isOpen = true,
  title,
  content,
  nextText,
  nextClick,
  backText,
  backClick,
  isProcessing,
  variant = 'danger',
  css = {},
}: Props) => (
  <Dialog
    isOpen={isOpen}
    onOpenChange={backClick}
    title={title}
    content={
      <Flex flow="column" gap="sm">
        {content}
      </Flex>
    }
    footer={
      <Flex justifyContent="end" gap="sm">
        <Button variant="subtle" onClick={backClick} data-testid="btn:back">
          {backText || i18n.t('Back')}
        </Button>
        <Button
          variant={variant}
          onClick={nextClick}
          data-testid="btn:next"
          disabled={isProcessing}
        >
          {nextText || i18n.t('Exit')}
        </Button>
      </Flex>
    }
    css={{ ...dialogSize[size], ...css }}
    closeOnOutsideClick
    closeOnEscape
  />
);

export default ActionDialog;
