import { Icon, setClass, useAfterMount } from '@kandji-inc/bumblebee';
import React, { useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import FileUpload from './assets/file-upload.svg';
import { bytesToSize, validateExtension } from './common';
import './rtf-file-select.css';

const MB = 1e6;

const validateFile =
  ({ allowedFileTypes, hideFileTypesFromView, maxSize, validators }) =>
  (file) => {
    const isFileExtAllowed = validateExtension(allowedFileTypes, file);
    if (!isFileExtAllowed) {
      return {
        code: 'file-extension-error',
        message: `This doesn't appear to be a valid file type. Allowed extensions: ${allowedFileTypes
          .filter((allowed) => !hideFileTypesFromView.includes(allowed))
          .map((ext) => `.${ext}`)
          .join(', ')}`,
      };
    }
    if (file.size > maxSize) {
      return {
        code: 'file-too-large',
        message: `This file appears to be too large. Max size allowed: ${bytesToSize(
          maxSize,
        )}`,
      };
    }

    if (validators) {
      const errors = validators.map((vdator) => vdator(file)).filter(Boolean);
      if (errors.length) {
        return {
          type: 'custom',
          message: errors[0],
        };
      }
    }

    return null;
  };

/**
 * used by:
 *  passport/kandji login RTF policy banners
 *  blueprint policy banner RTF files
 */
const RTFFileSelect = (props) => {
  const {
    disabled,
    maxSize,
    allowedFileTypes,
    hideFileTypesFromView,
    onFileSelect,
    validators,
    toggleValidationOn,
    size,
    children,
    testId,
    onlyShowAcceptedFileTypes,
  } = props;
  const isAfterMount = useAfterMount();
  const [error, setError] = useState([]);

  const dropzoneProps = {
    onDropAccepted: onFileSelect,
    validator: validateFile({
      allowedFileTypes,
      hideFileTypesFromView,
      maxSize,
      validators,
    }),
    disabled,
  };
  if (onlyShowAcceptedFileTypes) {
    dropzoneProps.accept = allowedFileTypes.map((ext) => `.${ext}`);
  }

  const { fileRejections, getRootProps, getInputProps, isDragActive } =
    useDropzone(dropzoneProps);

  useEffect(() => {
    setError(fileRejections);
  }, [fileRejections]);

  useEffect(() => {
    if (validators && isAfterMount) {
      const errors = validators.map((vdator) => vdator()).filter(Boolean);
      if (errors.length) {
        setError([
          {
            type: 'custom',
            errors: [{ message: errors[0] }],
          },
        ]);
      }
    }
  }, toggleValidationOn);

  return (
    <div
      className={setClass([
        'k-file-select__container',
        disabled && '--disabled',
        size === 'sm' && '--sm',
      ])}
    >
      <div
        className={setClass([
          'k-file-select',
          isDragActive && '--is-hovered',
          error.length && '--has-error',
        ])}
        {...getRootProps()}
      >
        <input {...getInputProps()} data-testid={testId} />
        {(children &&
          children({
            disabled,
            isDragActive,
            error,
            allowedFileTypes,
            icon: FileUpload,
          })) || (
          <div className="k-file-select__detail">
            <div className="b-flex-vc b-flex-hc ">
              <img src={FileUpload} alt="upload-logo" />
            </div>
            <div className="b-flex-vgmicro b-flex-vc b-flex-hc">
              <p className="b-txt">
                Drag file here or{' '}
                <a
                  href=""
                  onClick={(e) => e.preventDefault()}
                  className="b-alink"
                >
                  click to upload
                </a>
              </p>
              <p className="b-txt-bold">
                {`${allowedFileTypes
                  .filter((allowed) => !hideFileTypesFromView.includes(allowed))
                  .map((ext) => `.${ext}`)
                  .join(', ')} file`}
              </p>
            </div>
          </div>
        )}
      </div>
      <div className="k-file-select__error-info b-txt b-txt--error b-flex-gmicro b-flex-vc">
        {!!error.length && (
          <>
            <Icon name="circle-info" />
            <span>{error[0].errors[0].message}</span>
          </>
        )}
      </div>
    </div>
  );
};

RTFFileSelect.defaultProps = {
  disabled: false,
  maxSize: 100 * MB,
  allowedFileTypes: ['png', 'svg', 'jpg', 'jpeg', 'pkg', 'zip', 'dmg', 'pdf'],
  hideFileTypesFromView: [],
  onFileSelect: () => {},
  toggleValidationOn: [],
  onlyShowAcceptedFileTypes: true,
};

export default RTFFileSelect;
