import { colors } from 'app/common/constants';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.section`
  display: flex;
  flex-direction: column;
  font-family: var(--font-family-primary);
  color: ${colors['grey-500']};
`;

const Label = styled.div`
  font-family: var(--font-family-primary);
  text-transform: uppercase;
  letter-spacing: 0.2em;
  font-weight: 700;
  font-size: 10px;
  margin-bottom: ${(props) => (props.margin ? props.margin : '10px')};
  text-align: right;
`;

const ButtonsWrapper = styled.section`
  display: flex;
  flex-flow: nowrap row;
  justify-content: flex-end;
`;

const Button = styled.button.attrs(({ isSelected }) => ({
  color: isSelected ? colors.white : colors['grey-500'],
  backgroundColor: isSelected ? colors['marengo-700'] : colors.white,
  borderColor: isSelected
    ? (props) => (props.colorBorder ? props.colorBorder : colors['grey-130'])
    : colors['grey-130'],
}))`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 0;
  height: ${(props) => (props.height ? props.height : '35px')};
  border: 1px solid ${(props) => props.borderColor};
  outline: none;
  cursor: pointer;
  color: ${(props) => props.color};
  background-color: ${(props) => props.backgroundColor};
  font-size: 14px;
  line-height: 14px;
  font-weight: 400;
  transition: all 0.2s;
  min-width: 45px;
  padding: ${(props) => (props.padding ? props.padding : '5px 10px')};

  &:not(:last-child) {
    border-right: none;
  }
  &:focus {
    outline: none;
  }
  &:first-of-type {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
  }
  &:last-of-type {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
  }
`;

const AwesomeButtonGroup = ({
  onChange,
  options,
  selected,
  disabled,
  label,
  numerableOptions,
  style,
}) => (
  <Wrapper>
    <Label margin={style && style.margin}>{label}</Label>
    <ButtonsWrapper>
      {options.map((option, index) => {
        let value;
        let label;

        if (typeof option === 'object') {
          value = option.value;
          label = option.label;
        } else {
          value = option;
          label = option;
        }

        return (
          <Button
            key={`AwesomeButtonGroup-${index}`}
            type="button"
            disabled={disabled}
            height={style ? style.height : null}
            padding={style ? style.padding : null}
            colorBorder={style ? style.colorBorder : null}
            isSelected={
              numerableOptions
                ? Number(value) === Number(selected)
                : value === selected
            }
            onClick={() => onChange(value)}
          >
            {label}
          </Button>
        );
      })}
    </ButtonsWrapper>
  </Wrapper>
);

AwesomeButtonGroup.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.array, PropTypes.number]),
  ).isRequired,
  selected: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  label: PropTypes.string,
  numerableOptions: PropTypes.bool,
  style: PropTypes.object,
};

AwesomeButtonGroup.defaultProps = {
  disabled: false,
  label: null,
  numerableOptions: false,
  style: null,
};

export default AwesomeButtonGroup;
