import { Button } from '@kandji-inc/bumblebee';
import { forgotPassword } from 'app/_actions/app';
import { uppercaseStyle } from 'app/common/style-utils';
import { withPermissions } from 'contexts/account';
import { i18n } from 'i18n';
import isEqual from 'lodash/isEqual';
import React from 'react';
import { connect } from 'react-redux';
import { Col, Container, Input, Row } from 'reactstrap';
import { bindActionCreators } from 'redux';
import { Field, reduxForm } from 'redux-form';
import styled from 'styled-components';
import {
  setModal as callSetModal,
  setSnackbar as callSetSnackbar,
} from '../../_actions/ui';
import { deleteUser as callDeleteUser } from '../../_actions/users';
import history from '../../router/history';

const Block = styled.section`
  display: flex;
  flex-direction: column;
  max-width: 800px;
  background: #fff;
  border-radius: 5px;
  padding: 20px;
  margin-bottom: 20px;
  margin-top: 30px;
`;

const BlockHeader = styled.h2`
  ${uppercaseStyle}
`;

const renderField = ({
  input,
  label,
  type,
  disabled,
  meta: { touched, error, warning },
}) => (
  <Row className="col-lg-6 col-sm-12 my-2">
    <Col className="font-weight-bold">{label}</Col>
    <Col>
      <Input
        bsSize="sm"
        {...input}
        placeholder={label}
        type={type}
        disabled={disabled}
      />
      {touched &&
        ((error && (
          <span style={{ color: 'red', paddingLeft: '5px' }}>{error}</span>
        )) ||
          (warning && <span>{warning}</span>))}
    </Col>
  </Row>
);

class UserForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      error: '',
      showPassword: false,
      modalOpened: false,
      helper: false,
    };

    this.onResetPassword = this.onResetPassword.bind(this);
  }

  renderUserSettings() {
    return (
      <div>
        <Block>
          <BlockHeader>Edit</BlockHeader>
          <div className="px-3 py-3 my-2">
            <Row noGutters>
              <Field
                name="first_name"
                type="text"
                label={i18n.t('First Name')}
                component={renderField}
              />
            </Row>
            <Row noGutters>
              <Field
                name="last_name"
                type="text"
                label={i18n.t('Last Name')}
                component={renderField}
              />
            </Row>
            <Row noGutters>
              <Field
                name="email"
                type="email"
                label={i18n.t('Email')}
                disabled
                component={renderField}
              />
            </Row>
            <Row>
              <Button name="Reset Password" onClick={this.onResetPassword}>
                {i18n.t('Reset Password')}
              </Button>
            </Row>
          </div>
        </Block>
      </div>
    );
  }
  // istanbul ignore next
  onDelete = (userId) => {
    const { deleteUser } = this.props;
    return deleteUser(userId).then((res) => {
      history.goBack();
      return Promise.resolve(res);
    });
  };
  // istanbul ignore next
  onResetPassword() {
    const { setSnackbar, currentUser } = this.props;
    const { email } = currentUser;
    forgotPassword({ email })
      .then(() => setSnackbar(i18n.t('Email was sent')))
      .catch(() => setSnackbar(i18n.common.error()));
  }

  render() {
    const {
      handleSubmit,
      setModal,
      pristine,
      submitting,
      user,
      currentUser,
      reset,
      ownerId,
      permissions,
    } = this.props;
    const currentUserIsNotOwner = !isEqual(currentUser.id, ownerId);
    const fullName =
      currentUser.first_name && currentUser.last_name
        ? `${currentUser.first_name} ${currentUser.last_name}`
        : i18n.t('No Name');

    return (
      <form onSubmit={handleSubmit}>
        <Container className="user-portal-form">
          <Row className="admin-portal-header">
            <Col md={10}>
              <Row>
                <span className="h1">{fullName}</span>
              </Row>
            </Col>
          </Row>
          <Row className="admin-portal-header">
            <Col md={10}>
              <Row>
                <span>{currentUser.email}</span>
              </Row>
            </Col>
          </Row>
          <div style={{ justifyContent: 'flex-start', minHeight: '100px' }}>
            {this.renderUserSettings()}
          </div>
          <Row noGutters>
            <div className="d-flex flex-dir-row" style={{ paddingTop: 30 }}>
              <Button
                icon="check"
                disabled={pristine || submitting}
                type="submit"
              >
                {submitting || submitting ? i18n.t('Saving') : i18n.t('Save')}
              </Button>
              <div style={{ width: 15 }} />
              <Button
                icon="arrow-rotate-right"
                type="button"
                disabled={pristine}
                onClick={reset}
              >
                {i18n.t('Revert')}
              </Button>
              <div style={{ width: 15 }} />
              {currentUserIsNotOwner &&
                permissions.canManageSettings &&
                currentUser.id !== user.id && (
                  <Button
                    theme="error"
                    onClick={() =>
                      setModal('USER_DELETE', {
                        delete: this.onDelete,
                        idsToDelete: [currentUser.id],
                      })
                    }
                  >
                    {i18n.t('Delete User')}
                  </Button>
                )}
            </div>
          </Row>
        </Container>
      </form>
    );
  }
}
// istanbul ignore next
const validate = (values) => {
  const errors = {};
  if (!values.first_name) {
    errors.first_name = i18n.t('Required');
  } else if (values.first_name.length > 25) {
    errors.first_name = i18n.t('Must be 25 characters or less');
  }
  if (!values.last_name) {
    errors.last_name = i18n.t('Required');
  } else if (values.last_name.length > 25) {
    errors.last_name = i18n.t('Must be 25 characters or less');
  }

  return errors;
};

UserForm = reduxForm({
  form: 'userInfoForm',
  enableReinitialize: true,
  validate,
})(UserForm);

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setModal: callSetModal,
      setSnackbar: callSetSnackbar,
      deleteUser: callDeleteUser,
    },
    dispatch,
  );

UserForm = connect(
  (state) => ({
    user: state.account.user,
    ownerId: state.account.company.owner,
    currentUser: state.users.currentUser,
    initialValues: state.users.currentUser,
  }),
  mapDispatchToProps,
)(withPermissions(UserForm));

export default UserForm;
