import { Toaster as toaster } from '@kandji-inc/bumblebee';
import { AccountContext } from 'contexts/account';
import { EnvironmentContext } from 'contexts/environment';
import { Whoops404 } from 'pages/404';
import React, { useContext, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useParams,
  useRouteMatch,
} from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { getBlueprints as ReduxGetBlueprints } from 'src/app/_actions/blueprint';
import { newLibraryItemService } from 'src/features/library-items/data-service/library-item/new-library-item-service';
import Loader from '../../../theme/components/atoms/Loader';
import { blueprintService } from '../../library-items/data-service/blueprint/blueprint-service';
import EditBlueprint from '../blueprints-list/modals/edit';
import { paths } from '../common';
import { transformFromAPI } from './api/transformers';
import BlueprintActivity from './blueprint-activity';
import BlueprintDevices from './blueprint-devices/blueprint-devices';
import BlueprintLibrary from './blueprint-library';
import BlueprintNotes from './blueprint-notes';
import BlueprintParameters from './blueprint-parameters/blueprint-parameters';
import BlueprintContext from './blueprint.context';
/* istanbul ignore file */
import './blueprint.css';
import Sidebar from './sidebar';

const Blueprint = ({ getBlueprints, blueprints, blueprintMessages }) => {
  const { id } = useParams();
  const environment = useContext(EnvironmentContext);
  const account = useContext(AccountContext);
  const { path } = useRouteMatch();
  const history = useHistory();
  const [alertBannerHeight, setAlertBannerHeight] = useState(0);

  const [blueprint, setBlueprint] = useState();
  const [err, setErr] = useState();

  const [activeCategory, setActiveCategory] = useState();
  const [currentFilteredItems, setCurrentFilteredItems] = useState();

  const [isEditingSetting, setIsEditingSetting] = useState(false);

  const getBlueprintData = (id) =>
    Promise.all([
      blueprintService.get(id),
      newLibraryItemService.v2List().then((r) => r.data),
      blueprintService.getParameters(),
      blueprintService.getTags(),
    ])
      .then(([blueprintRes, libraryRes, parametersRes, parametersTagsRes]) => {
        if (blueprintRes.type === 'flow') {
          history.push(paths.flowBlueprint(id));
          return;
        }

        setBlueprint(
          transformFromAPI(
            blueprintRes,
            libraryRes,
            parametersRes.data,
            parametersTagsRes.data,
            {
              account,
              environment,
            },
          ),
        );
      })
      .catch((e) => {
        console.error(e);
        setErr(true);
      });

  useEffect(() => {
    if (blueprint && blueprint.id !== id) {
      setBlueprint();
    }

    if (id) {
      getBlueprintData(id);
    }
  }, [id]);

  useEffect(() => {
    const oldStyle = document.body.style.overflow;
    document.body.style.overflow = 'hidden';

    const isAlert = document.querySelector('#UniversalAlertsLayer');
    if (isAlert) {
      setAlertBannerHeight(isAlert?.offsetHeight || 0);
    }
    return () => {
      document.body.style.overflow = oldStyle;
    };
  }, []);

  const changeBlueprintOnSameTab = (id) => {
    const isFn = (f) => typeof f === 'function';
    if (id !== blueprint?.id) {
      const which = Object.values(paths).find(
        (v) =>
          (isFn(v) && v(blueprint?.id) === history?.location?.pathname) ||
          v === history?.location?.pathname,
      );
      if (which) {
        history.push(isFn(which) ? which(id) : which);
      } else {
        history.push(paths.library_items(id));
      }
    }
  };

  if (err) {
    return <Whoops404 />;
  }

  if (!blueprint) {
    return <Loader type={Loader.types.LINE} />;
  }

  const sidebarOptions = [
    {
      icon: 'grid-2',
      name: 'Library Items',
      route: `${paths.root}/${blueprint.id}/library`,
      items: currentFilteredItems?.categorical.map((cat) => ({
        name: cat.category,
        id: cat.category,
        items: cat.data.map((subCat) => ({
          name: subCat.category,
          id: subCat.category,
        })),
      })),
      count: blueprint.library_items.length,
      isPrimaryCount: true, // If count is zero, this option illuminates the count.
      activeCategory: activeCategory,
    },
    {
      icon: 'sliders',
      name: 'Parameters',
      route: `${paths.root}/${blueprint.id}/parameters`,
      items: currentFilteredItems?.parameters.map((cat) => ({
        name: cat.name,
        id: cat.id,
        items: cat.subcategories.map((subcat) => ({
          name: subcat.name,
          id: subcat.id,
        })),
      })),
      count: Object.keys(blueprint.params).length,
      isPrimaryCount: true,
      activeCategory: activeCategory,
    },
    {
      icon: 'desktop',
      name: 'Devices',
      route: `${paths.root}/${blueprint.id}/devices`,
      count: blueprint.computers_count,
    },
    {
      icon: 'wave-pulse',
      name: 'Activity stream',
      route: `${paths.root}/${blueprint.id}/activity`,
    },
    {
      icon: 'note-sticky',
      name: 'Notes',
      route: `${paths.root}/${blueprint.id}/notes`,
    },
    { type: 'line' },
    ,
    {
      icon: 'gear',
      name: 'Blueprint settings',
      onClick: () => setIsEditingSetting(true),
    },
  ];

  return (
    <BlueprintContext.Provider
      value={{
        blueprint,
        blueprints,
        blueprintMessages,
        getBlueprintData,
        setActiveCategory,
        setCurrentFilteredItems,
      }}
    >
      <div
        className="bl-blueprint-page"
        style={{ '--top-offset': `${alertBannerHeight}px` }}
        data-testid="blueprint-page"
      >
        <Sidebar
          onBlueprintChange={changeBlueprintOnSameTab}
          options={sidebarOptions}
        />
        <div className="bl-blueprint-page__main">
          <Switch>
            <Route
              exact
              path={`${path}/library`}
              component={BlueprintLibrary}
            />
            <Route
              exact
              path={`${path}/parameters`}
              component={BlueprintParameters}
            />
            <Route exact path={`${path}/notes`} component={BlueprintNotes} />
            <Route
              exact
              path={`${path}/activity`}
              component={BlueprintActivity}
            />
            <Route
              exact
              path={`${path}/devices`}
              component={BlueprintDevices}
            />
            <Redirect to={`/blueprints/${id}/library`} />
          </Switch>
          {isEditingSetting && (
            <EditBlueprint
              blueprint={blueprint}
              onClose={() => setIsEditingSetting(false)}
              onEdit={(name, description, icon, color) =>
                blueprintService
                  .patch(blueprint.id, {
                    name,
                    description,
                    icon,
                    color,
                  })
                  .then(() => {
                    getBlueprintData(blueprint.id);
                    getBlueprints(); // Updates redux state
                  })
                  .catch(() => toaster('Failed to update Blueprint settings.'))
              }
            />
          )}
        </div>
      </div>
    </BlueprintContext.Provider>
  );
};

const mapStateToProps = (state) => {
  return {
    blueprints: state.data.blueprints,
    blueprintMessages: state.data.blueprintMessages,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ getBlueprints: ReduxGetBlueprints }, dispatch);

// @todo Remove the redux connection here once we migrate all the Library Items to Bumblebee (Epic LIT-954)
export default connect(mapStateToProps, mapDispatchToProps)(Blueprint);
