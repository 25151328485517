import { i18n } from 'i18n';
import React, { useState, useContext } from 'react';

import { Button } from 'theme/components/molecules/Action';
import useScroll from '../../hooks/useScroll';
import Icon from '../../theme/components/atoms/Icon';
import Link from '../../theme/components/atoms/Link';

import { Context } from './context';
import slug from './slug';
import strategy from './strategy';

const { GOOGLE_APPS, SAMLP, WAAD } = slug;

const strategies = [strategy[WAAD], strategy[GOOGLE_APPS], strategy[SAMLP]];

export default () => {
  const { onWizardOpen, onWizardClose } = useContext(Context);

  const [next, setNext] = useState(undefined);
  const [bodyScroll, bodyRef] = useScroll();

  const onCancel = () => {
    setNext(undefined);
    onWizardClose();
  };
  const onNext = () => onWizardOpen(null, next, false);

  const loopStrategy = strategies.map(({ label, icon, slug }) => (
    <button
      key={slug}
      type="button"
      className={`btn btn-identity identity ${
        next === slug ? 'active' : 'passive'
      }`}
      onClick={() => setNext(slug)}
    >
      <div className="strategy">
        <Icon icon={icon} />
        <span className="label">{label}</span>
      </div>
    </button>
  ));

  return (
    <div className="paper">
      <div className="theme-form-aside">
        <div className="node">
          <Button kind="close" tier="two" icon="xmark" onClick={onCancel} />
        </div>
      </div>
      <div className={`theme-form-header ${bodyScroll ? 'is-sticky' : ''}`}>
        <div className="node">
          <h3 className="theme-form-title paper-title">
            {i18n.t('Add SSO Connection')}
          </h3>
          <p className="theme-form-description paper-description">
            {i18n.t(
              'Select an identity provider to configure a SSO connection.',
            )}{' '}
            <Link href="https://support.kandji.io/sso" />
          </p>
        </div>
      </div>
      <div ref={bodyRef} className="theme-form-body">
        <div className="node hubspot-buffer-bottom">
          <div className="theme-form-block">{loopStrategy}</div>
        </div>
      </div>
      <div className="theme-form-footer">
        <div className="node">
          <Button tier="two" label={i18n.t('Cancel')} onClick={onCancel} />
          <Button
            tier="one"
            label={i18n.t('Next')}
            disabled={!next}
            onClick={onNext}
          />
        </div>
      </div>
    </div>
  );
};
