import { useMutation } from '@tanstack/react-query';
import { newIntegrationsService } from 'src/features/integrations/data-service/new-integrations-service';

const useDeleteNotification = () =>
  useMutation({
    mutationFn: (notificationId: string) =>
      newIntegrationsService.deleteNotification(notificationId),
  });

export default useDeleteNotification;
